import React from 'react'
import {useRouteNode} from 'react-router5'
import {startsWithSegment} from 'router5-helpers'

import GameFilterSubMenu from './GameFilterSubMenu.js'
import InstallSubMenu from './InstallSubMenu.js'
import OptionsSubMenu from './OptionsSubMenu.js'
import PromotionSubMenu from './PromotionSubMenu.js'
import SignOutSubMenu from './SignOutSubMenu.js'
import UpdateSubMenu from './UpdateSubMenu.js'
import {GAME_FORMAT} from '../../game-list/constants.js'
import {useGameFormat} from '../../game-list/react-hooks.js'
import {useIsAvailable as useIsInstallAvailable} from '../../installation/react-hooks.js'
import {SELECTOR_LIST} from '../../routes.js'
import {useStatus} from '../../updating/react-hooks.js'
import {STATUS_UP_TO_DATE} from '../../updating/service.js'

const {RING_GAME} = GAME_FORMAT

export default function DesktopMenu () {
  const {route} = useRouteNode('')
  const testRoute = startsWithSegment(route)
  const isInstallAvailable = useIsInstallAvailable()
  const updateStatus = useStatus()
  const isUpdateAvailable = updateStatus !== STATUS_UP_TO_DATE
  const gameFormat = useGameFormat()

  const isRingGame = testRoute(SELECTOR_LIST) && gameFormat === RING_GAME

  return <div className='sideMenu'>
    {isUpdateAvailable && <UpdateSubMenu status={updateStatus} />}
    <OptionsSubMenu lobbyLabel='Selectors' lobbyRoute={SELECTOR_LIST} />
    {isInstallAvailable && <InstallSubMenu />}
    {isRingGame && <GameFilterSubMenu />}
    <PromotionSubMenu />
    <SignOutSubMenu />
  </div>
}

import {createRouter as createRouter5} from 'router5'
import browserPlugin from 'router5-plugin-browser'
import loggerPlugin from 'router5-plugin-logger'

import routes, {DEFAULT_LOBBY_ROUTE, HOME, LOBBY, POP_UP} from './routes.js'

export function createBareRouter () {
  const router = createRouter5([], {allowNotFound: true, queryParamsMode: 'loose'})
  router.add(routes)

  return router
}

export function createRouter (casinoSettings, logger, redirectRoute, routeHooks) {
  const router = createBareRouter()
  const lobbyRoutes = routes.filter(({isLobby}) => isLobby).map(({name}) => name)

  router.usePlugin(browserPlugin())
  if (logger.topics.routing) router.usePlugin(loggerPlugin)

  router.subscribe(({route}) => {
    if (process.env.NODE_ENV !== 'production') {
      Object.entries(route.params).forEach(([name, value]) => {
        if (typeof value !== 'string') {
          // eslint-disable-next-line no-console
          console.error(
            `Warning: The route parameter ${JSON.stringify(name)} is not a string. ` +
            'All route parameters should be strings.',
          )
        }
      })
    }

    const redirect = redirectRoute(route)

    if (redirect) {
      router.navigate(redirect.name, redirect.params, {replace: true})

      return
    }

    // store the last-used lobby
    if (lobbyRoutes.includes(route.name)) casinoSettings.set('lobbyRoute', route)
  })

  applyRouteHooks(redirectRoute, router, routeHooks)

  return router
}

export function createRouteRedirector (casinoSettings, format, routesByName) {
  return function redirectRoute (route) {
    const {name} = route

    // redirect to the last-used lobby
    if (name === HOME || name === LOBBY) return casinoSettings.get('lobbyRoute') || DEFAULT_LOBBY_ROUTE

    // redirect any format-specific routes according to the route config
    return routesByName[name]?.formatRedirect?.[format]
  }
}

export function findPopupParam (location) {
  const router = createBareRouter()
  const {name, params: {instanceId} = {}} = router.matchPath(location.pathname) || {}

  return name === POP_UP ? instanceId : null
}

export function mapRoutesByName (routes) {
  const byName = {}
  for (const route of routes) byName[route.name] = route

  return byName
}

function applyRouteHooks (redirectRoute, router, hooks) {
  let cleanup

  router.subscribe(({route}) => {
    if (redirectRoute(route)) return

    if (cleanup) {
      cleanup()
      cleanup = undefined
    }

    const hook = hooks[route.name]

    if (hook) cleanup = hook()
  })
}

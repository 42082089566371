import classnames from 'classnames'
import React, {useCallback, useEffect, useState} from 'react'

import styles from './Checkbox.module.css'
import SvgCheckbox from './Checkbox.module.svg'

export default function Checkbox ({checked, className, isDisabled, onChange, onClick}) {
  const [isChecked, setIsChecked] = useState(() => !!checked)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    setIsChecked(!!checked)
  }, [checked, setIsChecked])

  const handleOnClick = useCallback(event => {
    setIsChecked(event.target.checked)
    if (onClick) onClick(event)
  }, [onClick, setIsChecked])

  const handleOnBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  const handleOnFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const checkboxClassName = classnames(className, styles.checkbox, {
    [styles.disabled]: isDisabled,
    [styles.focused]: isFocused,
  })
  const svgClassName = classnames(styles.svgCheckbox, {[styles.checked]: isChecked})

  return <label className={checkboxClassName}>
    <input
      type='checkbox'
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      disabled={isDisabled}
      defaultChecked={checked}
      onChange={onChange}
      onClick={handleOnClick}
      className={styles.nativeCheckbox}
    />
    <SvgCheckbox className={svgClassName} />
  </label>
}

import classnames from 'classnames'
import React, {useCallback} from 'react'

import PresetButton from './PresetButton.js'
import styles from '../Bank.module.css'

export default function PresetButtonsPanel (props) {
  const {defaultAmount, max, min, setAmount} = props

  const handleClickMin = useCallback(() => { setAmount(min) }, [min, setAmount])
  const handleClickMax = useCallback(() => { setAmount(max) }, [max, setAmount])
  const handleClickDefault = useCallback(() => { setAmount(defaultAmount) }, [defaultAmount, setAmount])

  return <div className={classnames('inline-buttons', styles.presetButtonsPanel)}>
    <PresetButton label='Min' onClick={handleClickMin} />
    <PresetButton label='Default' onClick={handleClickDefault} />
    <PresetButton label='Max' onClick={handleClickMax} />
  </div>
}

export const getLatency = ({signal}) => signal

export function getStrength (latency) {
  if (latency < 50) return 5
  if (latency < 100) return 4
  if (latency < 150) return 3
  if (latency < 250) return 2

  return 1
}

import React from 'react'

import PrizeCoupon from './PrizeCoupon.js'
import Money from '../../money/components/Money.js'

export default function Prize (props) {
  const {value: {cash, coupons}} = props

  const components = []
  if (cash) components.push(<Money key='cash' value={cash} />)

  for (const coupon of coupons) {
    if (components.length > 0) components.push(' + ')
    components.push(<PrizeCoupon key={`coupon-${coupon.name}`} coupon={coupon} />)
  }

  return <>{components.length > 0 ? components : '$0'}</>
}

# WinPercentageData
#   int seat
#   BigDecimal percent
#   boolean exactValue
import Mixmaster from '../../../mixmaster.coffee'

export default class WinPercentageData extends Mixmaster
  mixin: (object) ->
    object.allInWinPct = (signal, name, values) ->
      signal.updates.allInWinPct = []
      pcts = values.winPctList.split('|')
      max = Math.max.apply null, pcts
      for id, index in values.playerIdList.split('|') then do (id, index) ->
        playerPct = parseInt pcts[index]
        winning = playerPct is max
        signal.updates.allInWinPct.push
          id: parseInt(id,10)
          pct: playerPct
          winning: winning

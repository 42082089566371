import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

export default class AddToWaitlistTranslator extends Mixmaster
  mixins = [
    DefaultMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal
    signal.updates.addToWaitlist = {}

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  waitListPosition: (signal, name, values) ->
    signal.updates.waitlistPosition =
      length: parseInt values.waitListLength
      position: parseInt values.waitListPosition

  gameName: (signal, name, values) ->
    signal.updates.addToWaitlist.gameName = values # *FAST* HU Niagara Falls

  gameTypeId: (signal, name, values) ->
    signal.updates.addToWaitlist.gameTypeId = values # 2

  gameLimitType: (signal, name, values) ->
    signal.updates.addToWaitlist.gameLimitType = values # N

  speed: (signal, name, values) ->
    signal.updates.addToWaitlist.speed = values # FAST

  realMoney: (signal, name, values) ->
    signal.updates.addToWaitlist.realMoney = values # true

  minStake: (signal, name, values) ->
    signal.updates.addToWaitlist.minStake = values # 0.50

  maxPlayers: (signal, name, values) ->
    signal.updates.addToWaitlist.maxPlayers = values # 2

import {QUEUED, RESERVED, SEATED} from '@pipehat/chronicle/constants/seating-status'

export function renderSeatingStatus (seatingStatus) {
  switch (seatingStatus) {
    case QUEUED: return 'Waiting'
    case RESERVED: return 'Reserved'
    case SEATED: return 'Playing'
  }

  return '???'
}

import {
  TOURNAMENT_SCHEDULED_REGULAR,
  TOURNAMENT_SCHEDULED_SATELLITE,
  TOURNAMENT_SCHEDULED_SPECIAL,
} from '@pipehat/chronicle/constants/game-list-type'

import {OMAHA_HOLDEM, OMAHA_HOLDEM_HILO, TEXAS_HOLDEM} from '@pipehat/chronicle/constants/game-variant'
import {POT_LIMIT, NO_LIMIT} from '@pipehat/chronicle/constants/limit-type'

import listTypeEnum from '../../legacy/enum/enums/listTypeEnum.coffee'
import {TOURNAMENTS_SUB_ROUTE} from '../../routes.js'
import {ALL, REGULAR} from '../constants.js'

export const TOURNAMENT_TYPE = {
  ALL,
  REGULAR,
  SATELLITE: 'SATELLITE',
  SPECIAL: 'SPECIAL',
}

export const SCHEDULED_TOURNAMENT_FILTER = {
  HOLDEM: 'HOLDEM',
  OMAHA: 'OMAHA',
  SATELLITES: 'SATELLITES',
}

export const SCHEDULED_TOURNAMENT_FILTER_MAP = {
  [TOURNAMENTS_SUB_ROUTE.HOLDEM]: SCHEDULED_TOURNAMENT_FILTER.HOLDEM,
  [TOURNAMENTS_SUB_ROUTE.OMAHA]: SCHEDULED_TOURNAMENT_FILTER.OMAHA,
  [TOURNAMENTS_SUB_ROUTE.SATELLITES]: SCHEDULED_TOURNAMENT_FILTER.SATELLITES,
}

export const SCHEDULED_TOURNAMENT_FILTER_CRITERIA = {
  [SCHEDULED_TOURNAMENT_FILTER.HOLDEM]: {
    limitType: NO_LIMIT,
    variant: TEXAS_HOLDEM,
    tournamentType: ALL,
  },
  [SCHEDULED_TOURNAMENT_FILTER.OMAHA]: {
    limitType: POT_LIMIT,
    variant: OMAHA_HOLDEM,
    tournamentType: ALL,
  },
  [SCHEDULED_TOURNAMENT_FILTER.SATELLITES]: {
    limitType: NO_LIMIT,
    variant: TEXAS_HOLDEM,
    tournamentType: TOURNAMENT_TYPE.SATELLITE,
  },
}

// the first variant is the default, so order is important here
export const VARIANT_MAP = {
  [ALL]: [ALL, TEXAS_HOLDEM, OMAHA_HOLDEM, OMAHA_HOLDEM_HILO],
  [REGULAR]: [ALL, TEXAS_HOLDEM, OMAHA_HOLDEM, OMAHA_HOLDEM_HILO],
  [TOURNAMENT_TYPE.SATELLITE]: [TEXAS_HOLDEM],
  [TOURNAMENT_TYPE.SPECIAL]: [TEXAS_HOLDEM],
}

const LIST_TYPE_REGULAR = [
  TOURNAMENT_SCHEDULED_REGULAR,
  listTypeEnum.TOURNAMENT_SCHEDULED_REGULAR.description,
]

const LIST_TYPE_SATELLITE = [
  TOURNAMENT_SCHEDULED_SATELLITE,
  listTypeEnum.TOURNAMENT_SCHEDULED_SATELLITE.description,
]

const LIST_TYPE_SPECIAL = [
  TOURNAMENT_SCHEDULED_SPECIAL,
  listTypeEnum.TOURNAMENT_SCHEDULED_SPECIAL.description,
]

export const LIST_TYPE_MAP = {
  [ALL]: [LIST_TYPE_REGULAR, LIST_TYPE_SATELLITE, LIST_TYPE_SPECIAL],
  [REGULAR]: [LIST_TYPE_REGULAR],
  [TOURNAMENT_TYPE.SATELLITE]: [LIST_TYPE_SATELLITE],
  [TOURNAMENT_TYPE.SPECIAL]: [LIST_TYPE_SPECIAL],
}

// the first limit type is the default, so order is important here
export const LIMIT_TYPE_MAP = {
  [ALL]: [ALL, NO_LIMIT, POT_LIMIT],
  [OMAHA_HOLDEM]: [POT_LIMIT],
  [OMAHA_HOLDEM_HILO]: [POT_LIMIT],
  [TEXAS_HOLDEM]: [NO_LIMIT],
}

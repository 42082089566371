import React, {createContext, useEffect, useMemo, useState} from 'react'

export const RadioGroupContext = createContext()

export default function RadioGroup (props) {
  const {children, onChange, value} = props
  const [currentValue, setValue] = useState(value)
  const context = useMemo(() => ({currentValue, setValue}), [currentValue, setValue])

  useEffect(() => {
    setValue(value)
  }, [value, setValue])

  useEffect(() => {
    if (onChange) onChange(currentValue)
  }, [currentValue, onChange])

  return <RadioGroupContext.Provider value={context}>{children}</RadioGroupContext.Provider>
}

import {isZero} from '@pipehat/money/plain-object'
import React from 'react'

import Money from '../../../money/components/Money.js'

export default function LevelBlinds (props) {
  const {isFixedLimit, level} = props
  const {ante, bigBlind, maximumBet, minimumBet, smallBlind} = level

  return <>
    {isFixedLimit && minimumBet && maximumBet && <><Money value={minimumBet} /> / <Money value={maximumBet} /></>}
    {!isFixedLimit && smallBlind && bigBlind && <><Money value={smallBlind} /> / <Money value={bigBlind} /></>}
    {ante && !isZero(ante) && <> Ante <Money value={ante} /></>}
  </>
}

import classnames from 'classnames'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'

import {useTableId} from '../../context.js'
import {collapseChat, expandChat} from '../chat-slice.js'
import {useIsAvailable, useIsExpanded, useSendChatMessage, useShowStickers} from '../react-hooks.js'

const KEY_ENTER = 13
const KEY_ESC = 27

export default function Input (props) {
  const {maximizedChat} = props

  const dispatch = useDispatch()
  const inputRef = useRef()
  const tableId = useTableId()
  const sendChatMessage = useSendChatMessage()
  const showStickers = useShowStickers()
  const isAvailable = useIsAvailable()
  const isExpanded = useIsExpanded()

  const [isTyping, setIsTyping] = useState(false)

  const clearMessage = useCallback(() => {
    inputRef.current.value = ''
    setIsTyping(false)
  }, [])

  const sendMessage = useCallback(() => {
    const message = inputRef.current.value

    if (message.length < 1 || !isAvailable) return

    sendChatMessage(message)
    clearMessage()
  }, [clearMessage, isAvailable, sendChatMessage])

  const handleClickClear = useCallback(() => { clearMessage() }, [clearMessage])
  const handleClickSend = useCallback(() => { sendMessage() }, [sendMessage])

  const handleClickStickers = useCallback(() => {
    if (isAvailable) showStickers()
  }, [isAvailable, showStickers])

  const handleClickCollapse = useCallback(() => {
    dispatch(collapseChat({tableId}))
  }, [dispatch, tableId])

  const handleFocusInput = useCallback(() => {
    dispatch(expandChat({tableId}))
  }, [dispatch, tableId])

  const handleBlurInput = useCallback(() => {
    // only blur on the desktop version
    if (!maximizedChat) dispatch(collapseChat({tableId}))
  }, [maximizedChat, dispatch, tableId])

  const handleKeyUpInput = useCallback(({keyCode}) => {
    if (keyCode === KEY_ENTER) {
      sendMessage()
    } else {
      setIsTyping(inputRef.current.value !== '')
    }
  }, [sendMessage])

  useEffect(() => {
    if (!isExpanded) {
      inputRef.current.blur()

      return
    }

    function handleKeyUpDocument ({keyCode}) {
      if (keyCode === KEY_ESC) dispatch(collapseChat({tableId}))
    }

    document.addEventListener('keyup', handleKeyUpDocument)

    return () => {
      document.removeEventListener('keyup', handleKeyUpDocument)
    }
  }, [dispatch, isExpanded, tableId])

  const chatClassName = classnames('chat-input', {
    'chat-unavailable': !isAvailable,
    'chat-expanded': isExpanded,
    'maximized-chat': maximizedChat,
  })

  return <span className={chatClassName}>
    <span className='wrapper'>
      {maximizedChat && isExpanded && <div className='collapse fa fa-arrow-left' onClick={handleClickCollapse} />}

      <input
        ref={inputRef}
        onKeyUp={handleKeyUpInput}
        onFocus={handleFocusInput}
        onBlur={handleBlurInput}
        className='message'
        type='text'
        placeholder='Chat…'
        spellCheck='false'
        autoComplete='off'
        autoCorrect='off'
        autoCapitalize='off'
      />

      {isTyping && <span onClick={handleClickClear} className='clear fa fa-close' />}
      <span onClick={handleClickStickers} className='stickers fa fa-smile-o' />
      {isTyping && <span onClick={handleClickSend} className='send fa fa-paper-plane-o' />}
    </span>
  </span>
}

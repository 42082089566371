import {ALL_IN, BET, CALL, CHECK, FOLD, RAISE} from '@pipehat/chronicle/constants/action'
import {USD} from '@pipehat/chronicle/constants/currency'
import {NEXT_BLIND} from '@pipehat/chronicle/constants/sit-out-strategy'
import {fromNumber, negate, sum, toNumber} from '@pipehat/money/plain-object'

import {AUTO_REBUY_POINT_OFF, AUTO_REBUY_POINT_OUT_OF_CHIPS, AUTO_REBUY_POINT_TABLE_MIN} from './constants.js'

export function createServiceFactory (casinoSettings, clientList) {
  const services = {}

  return function createService (tableId) {
    if (!services[tableId]) {
      services[tableId] = createServiceForTable(casinoSettings, clientList, tableId)
    }

    return services[tableId]
  }
}

function createServiceForTable (casinoSettings, clientList, tableId) {
  const buyInAmountSubscribers = new Set()
  const intendedActionSubscribers = new Set()
  const isReadyToContinueSubscribers = new Set()
  const previousBalanceSubscribers = new Set()
  const optionsSubscribers = new Set()
  const ringGameRebuyAmountSubscribers = new Set()
  let client
  let options
  let autoRebuyPoint

  clientList.on('add', addedClient => {
    if (addedClient.instanceId === tableId) setClient(addedClient)
  })
  clientList.on('rem', removedClient => {
    if (removedClient.instanceId === tableId) setClient(undefined)
  })

  setClient(clientList.find({instanceId: tableId}))

  return {
    get options () { return options },

    abandonSitOutStrategy () {
      client.action('sitOutClear')
    },

    bet (amount) {
      client.processAction('bet', {amount: toNumber(amount)})
    },

    buyInAmount () {
      return client?.buyInAmount ? fromNumber(USD, client.buyInAmount) : undefined
    },

    callBet () {
      client.processAction('call')
    },

    check () {
      client.processAction('check')
    },

    declareAllIn () {
      client.processAction('allIn')
    },

    disableAutoRebuy () {
      client.autoRebuy = {
        point: AUTO_REBUY_POINT_OFF,
        amount: toNumber(options.autoRebuyAmount),
      }
    },

    disableTimeBank () {
      client.action('timebank', {selected: false})
    },

    enableAutoRebuyWhenBelowTableMinimum () {
      autoRebuyPoint = AUTO_REBUY_POINT_TABLE_MIN
      client.autoRebuy = {
        point: autoRebuyPoint,
        amount: toNumber(options.autoRebuyAmount),
      }
    },

    enableAutoRebuyWhenOutOfChips () {
      autoRebuyPoint = AUTO_REBUY_POINT_OUT_OF_CHIPS
      client.autoRebuy = {
        point: autoRebuyPoint,
        amount: toNumber(options.autoRebuyAmount),
      }
    },

    enableTimeBank () {
      client.action('timebank', {selected: true})
    },

    fold () {
      client.fold()
    },

    foldToAnyBet () {
      client.processAction('foldToAnyBet')
    },

    forgoBreak () {
      client.action('imReady')
    },

    intendedActionName () {
      return convertLegacyActionName(client.intendedAction?.actionName)
    },

    isReadyToContinue () {
      return client.imReady
    },

    leaveGame () {
      client.leaveGame({force: true, lobby: true})
    },

    muckCards () {
      client.processAction('muck')
    },

    postBigBlind () {
      client.processAction('postBb')
    },

    postInitialBlind () {
      client.processAction('postIb')
    },

    postReturnBlind () {
      client.processAction('postRb')
    },

    postSmallBlind () {
      client.processAction('postSb')
    },

    previousBalance () {
      return client?.previousBalance ? fromNumber(USD, client.previousBalance) : undefined
    },

    raise (amount, currentBet) {
      const raiseAmount = sum([amount, negate(currentBet)])
      client.processAction('raise', {amount: toNumber(raiseAmount)})
    },

    ringGameRebuyAmount () {
      return client.ringGameRebuyAmount !== undefined ? fromNumber(USD, Number(client.ringGameRebuyAmount)) : undefined
    },

    removePreaction () {
      client.removePreaction()
    },

    selectSitOutStrategy (strategy) {
      client.action(strategy === NEXT_BLIND ? 'sitOutNextBlind' : 'sitOutNextHand')
    },

    sendChatMessage (text) {
      client.sendChatMessage({chatText: text})
    },

    setAutoRebuyAmount (amount) {
      client.autoRebuy = {
        point: options.autoRebuyPoint,
        amount: toNumber(amount),
      }
    },

    setBuyInAmount (amount) {
      client.buyInAmount = toNumber(amount)
    },

    setRingGameRebuyAmount (amount) {
      client.ringGameRebuyAmount = amount !== undefined ? toNumber(amount) : undefined
    },

    showAddChips () {
      client.addChips()
    },

    showCards () {
      const cards = client.player.cards.filter(({facedown}) => facedown === false)
      client.processAction('show', {cards})
    },

    showStickers () {
      client.emit('showEmoji')
    },

    showHandHistory () {
      client.emit('showHandHistory')
    },

    showInsufficientFundsForAutoRebuy (ownBalance, minimumBuyIn) {
      client.showInsufficientFundsForAutoRebuy(ownBalance, minimumBuyIn)
    },

    showInsufficientFundsForBuyIn (ownBalance, minimumBuyIn) {
      client.showInsufficientFundsForBuyIn(ownBalance, minimumBuyIn)
    },

    showInsufficientFundsForRingGameRebuy (ownBalance, minimumBuyIn) {
      client.showInsufficientFundsForRingGameRebuy(ownBalance, minimumBuyIn)
    },

    showInvalidAmount (minimum, maximum) {
      client.showInvalidAmount(minimum, maximum)
    },

    showLeaveConfirmation () {
      client.leaveGame({lobby: true})
    },

    sitIn () {
      client.processAction('sitIn')
    },

    sitInOnRebuy () {
      client.sitInOnRebuy = true
    },

    sitOut () {
      client.processAction('sitOutNextHand')
    },

    startRabbitHunt () {
      client.processAction('rabbit')
    },

    subscribeToBuyInAmount (subscriber) {
      buyInAmountSubscribers.add(subscriber)

      return function unsubscribe () {
        buyInAmountSubscribers.delete(subscriber)
      }
    },

    subscribeToChatMessages (subscriber) {
      return client?.subscribeToChatMessages(subscriber)
    },

    subscribeToIntendedAction (subscriber) {
      intendedActionSubscribers.add(subscriber)

      return function unsubscribe () {
        intendedActionSubscribers.delete(subscriber)
      }
    },

    subscribeToIsReadyToContinue (subscriber) {
      isReadyToContinueSubscribers.add(subscriber)

      return function unsubscribe () {
        isReadyToContinueSubscribers.delete(subscriber)
      }
    },

    subscribeToOptions (subscriber) {
      optionsSubscribers.add(subscriber)

      return function unsubscribe () {
        optionsSubscribers.delete(subscriber)
      }
    },

    subscribeToPreviousBalance (subscriber) {
      previousBalanceSubscribers.add(subscriber)

      return function unsubscribe () {
        previousBalanceSubscribers.delete(subscriber)
      }
    },

    subscribeToRingGameRebuyAmount (subscriber) {
      ringGameRebuyAmountSubscribers.add(subscriber)

      return function unsubscribe () {
        ringGameRebuyAmountSubscribers.delete(subscriber)
      }
    },

    toggleAutoMuck () {
      client.autoMuck = !options.isAutoMuck
    },

    toggleAutoPostBlinds () {
      client.autoBlinds = !options.isAutoPostBlinds
    },

    toggleAutoRebuy () {
      client.autoRebuy = {
        point: options.isAutoRebuy ? AUTO_REBUY_POINT_OFF : options.autoRebuyPoint,
        amount: toNumber(options.autoRebuyAmount),
      }
    },

    toggleOptions () {
      client.toggleGameOptions()
    },

    updateRealMoneyBalance () {
      client?.updateRealMoneyBalance()
    },

    waitForInitialBlind () {
      client.waitingForBigBlind = true
      client.processAction('waitIb')
    },

    waitForReturnBlind () {
      client.waitingForBigBlind = true
      client.processAction('waitRb')
    },
  }

  function buildOptions () {
    const {autoBlinds, autoMuck, autoRebuy} = client
    const isAutoRebuy = autoRebuy.point !== AUTO_REBUY_POINT_OFF

    return {
      autoRebuyAmount: fromNumber(USD, autoRebuy.amount),
      autoRebuyPoint: previousAutoRebuyPoint(),
      isAutoMuck: autoMuck,
      isAutoPostBlinds: autoBlinds,
      isAutoRebuy,
    }
  }

  function convertLegacyActionName (actionName) {
    switch (actionName) {
      case 'allIn': return ALL_IN
      case 'bet': return BET
      case 'call': return CALL
      case 'check': return CHECK
      case 'fold': return FOLD
      case 'foldToAnyBet': return FOLD
      case 'raise': return RAISE
      case 'raiseAny': return RAISE
    }

    return undefined
  }

  function dispatchBuyInAmount () {
    const value = client.buyInAmount ? fromNumber(USD, client.buyInAmount) : undefined
    for (const subscriber of buyInAmountSubscribers) subscriber(value)
  }

  function dispatchOptions () {
    options = buildOptions()
    for (const subscriber of optionsSubscribers) subscriber(options)
  }

  function dispatchIntendedAction () {
    const actionName = convertLegacyActionName(client.intendedAction?.actionName)
    for (const subscriber of intendedActionSubscribers) subscriber(actionName)
  }

  function dispatchIsReadyToContinue () {
    for (const subscriber of isReadyToContinueSubscribers) subscriber(client.imReady)
  }

  function dispatchPreviousBalance () {
    const value = client.previousBalance ? fromNumber(USD, client.previousBalance) : undefined
    for (const subscriber of previousBalanceSubscribers) subscriber(value)
  }

  function dispatchRingGameRebuyAmount () {
    const value = client.ringGameRebuyAmount !== undefined ? fromNumber(USD, client.ringGameRebuyAmount) : undefined
    for (const subscriber of ringGameRebuyAmountSubscribers) subscriber(value)
  }

  function previousAutoRebuyPoint () {
    if (autoRebuyPoint) return autoRebuyPoint

    const defaultPoint = casinoSettings.get('autoRebuy').point

    return defaultPoint === AUTO_REBUY_POINT_OFF ? AUTO_REBUY_POINT_OUT_OF_CHIPS : defaultPoint
  }

  function setClient (next) {
    if (client) {
      client.off('change:autoBlinds', dispatchOptions)
      client.off('change:autoMuck', dispatchOptions)
      client.off('change:autoRebuy', dispatchOptions)
      client.off('change:buyInAmount', dispatchBuyInAmount)
      client.off('change:imReady', dispatchIsReadyToContinue)
      client.off('change:intendedAction', dispatchIntendedAction)
      client.off('change:previousBalance', dispatchPreviousBalance)
      client.off('change:ringGameRebuyAmount', dispatchRingGameRebuyAmount)
    }

    client = next

    if (client) {
      client.on('change:autoBlinds', dispatchOptions)
      client.on('change:autoMuck', dispatchOptions)
      client.on('change:autoRebuy', dispatchOptions)
      client.on('change:buyInAmount', dispatchBuyInAmount)
      client.on('change:imReady', dispatchIsReadyToContinue)
      client.on('change:intendedAction', dispatchIntendedAction)
      client.on('change:previousBalance', dispatchPreviousBalance)
      client.on('change:ringGameRebuyAmount', dispatchRingGameRebuyAmount)

      dispatchBuyInAmount()
      dispatchOptions()
      dispatchIntendedAction()
      dispatchIsReadyToContinue()
      dispatchPreviousBalance()
      dispatchRingGameRebuyAmount()
    }
  }
}

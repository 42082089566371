import React, {useCallback, useEffect, useRef} from 'react'

export default function Numeric (props) {
  const {className, digits, max, min, onInvalid, setValue, value} = props
  const numberRef = useRef()

  useEffect(() => {
    if (numberRef.current) numberRef.current.value = format(value, digits)
  }, [digits, value])

  const handleBlur = useCallback(event => {
    const input = Number(event.target.value)

    if (onInvalid && (isNaN(input) || input < min || input > max)) onInvalid(event.target.value)

    const validatedValue = Math.max(Math.min(input, max), min)
    setValue(validatedValue)
    event.target.value = format(validatedValue, digits)
  }, [digits, max, min, onInvalid, setValue])

  return <input
    ref={numberRef}
    type='number'
    className={className}
    min={min}
    max={max}
    step='any'
    onBlur={handleBlur}
  />
}

function format (value, digits) {
  return digits === undefined ? value : Number(value).toFixed(digits)
}

import React, {useCallback} from 'react'

import MenuItem from './MenuItem.js'
import {useOpen} from '../../named-url/react-hooks.js'

export default function NamedUrlMenuItem (props) {
  const {extraClassName, icon, label, urlName} = props
  const open = useOpen(urlName)

  const handleClick = useCallback(event => {
    event.preventDefault()
    open()
  }, [open])

  return <MenuItem label={label} icon={icon} onClick={handleClick} extraClassName={extraClassName} />
}

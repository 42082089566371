import React, {useEffect, useRef} from 'react'

import SvgIcon from './Icon.module.svg'

export default function Icon (props) {
  const {start, end, max} = props
  const startMs = convertToMilliseconds(start)
  const endMs = convertToMilliseconds(end)
  const maxMs = Math.max(convertToMilliseconds(max), startMs, endMs)
  const ref = useRef()

  useEffect(() => {
    const {style} = ref.current?.querySelector('.face')
    const units = 94
    const unitsPerMS = units / maxMs

    style.transitionDuration = '0ms'
    style.strokeDasharray = `${unitsPerMS * startMs} ${units}`

    if (typeof end === 'undefined') return

    setTimeout(() => {
      style.transitionDuration = `${startMs > endMs ? startMs - endMs : endMs - startMs}ms`
      style.strokeDasharray = `${unitsPerMS * endMs} ${units}`
    }, 20)
  }, [startMs, endMs, end, maxMs])

  return <span ref={ref}>
    <SvgIcon />
  </span>
}

function convertToMilliseconds (duration) {
  const [, whole, remainder, suffix] = `${duration}`.match(/^(\d+)?(?:\.?(\d+))?(s|ms)?$/) || []
  const amount = `${whole || 0}.${remainder || 0}`

  if (suffix === 's') return Math.floor(amount * 1000)
  if (suffix === 'ms' || !suffix) return Math.floor(amount)

  return 0
}

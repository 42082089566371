import React, {useCallback} from 'react'

import Indicators from './Indicators.js'
import Participating from './Participating.js'
import Pinned from './Pinned.js'
import Priority from './Priority.js'
import {usePinnedTournaments, useTogglePinnedTournament} from '../react-hooks.js'

export default function TournamentIndicators (props) {
  const {tournament: {isOwn, isPriority, tournamentId}} = props

  const pinned = usePinnedTournaments()
  const isPinned = pinned[tournamentId]

  const toggle = useTogglePinnedTournament(tournamentId)
  const handleClick = useCallback(event => {
    event.preventDefault()
    event.stopPropagation()

    toggle()
  }, [toggle])

  return <Indicators onClick={handleClick}>
    {isOwn && <Participating />}
    {isPriority && <Priority />}
    <Pinned isPinned={isPinned} />
  </Indicators>
}

import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

export default class ChatTranslator extends Mixmaster
  mixins = [DefaultMixin]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal
    signal.updates.chat = {}

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  chatText: (signal, name, values) ->
    shortcuts =
      gg:  'Good Game'
      gl:  'Good Luck'
      nh:  'Nice Hand'
      ty:  'Thank You'
      vnh: 'Very Nice Hand'
      gtg: 'Got To Go'
      brb: 'Be Right Back'
      np:  'No Problem'
      gh:  'Good Hand'
      ul:  'Unlucky'
      gc:  'good call'
      bc:  'bad call'
      gf:  'good fold'
      bf:  'bad fold'
      gb:  'good bet'
      wp:  'well played'
      n1:  'nice one'

    if shortcuts[values]
      values = shortcuts[values]
    signal.updates.chat.message = values

  nickName: (signal, name, values) ->
    signal.updates.chat.nickname = values

  playerId: (signal, name, values) ->
    signal.updates.chat.playerPosition = parseInt values, 10

import Card from '../../../models/card.coffee'

export default ({signal, details, app, game, client, listing}) ->
  return unless game

  for allInPlayer in details
    player = game.players.find {position: allInPlayer.id}
    continue unless player

    pCards = []
    for card in allInPlayer.cards
      pCards.push new Card card
    player.cards = pCards

import React from 'react'

import DesktopGameFormatSelection from './DesktopGameFormatSelection.js'
import MobileGameFormatSelection from './MobileGameFormatSelection.js'
import styles from './Selection.module.css'
import {MOBILE} from '../../device/constants.js'
import {useFormat as useDeviceFormat} from '../../device/react-hooks.js'
import {GAME_FORMAT} from '../constants.js'
import {useGameFormat} from '../react-hooks.js'
import DesktopRingGameSelection from '../ring-game/components/DesktopSelection.js'
import DesktopScheduledTournamentSelection from '../scheduled-tournament/components/DesktopSelection.js'
import DesktopSitngoTournamentSelection from '../sitngo-tournament/components/DesktopSelection.js'

const {RING_GAME, SCHEDULED_TOURNAMENT, SITNGO_TOURNAMENT} = GAME_FORMAT

export default function Selection () {
  const deviceFormat = useDeviceFormat()
  const gameFormat = useGameFormat()

  if (deviceFormat === MOBILE) {
    return <MobileGameFormatSelection format={gameFormat} />
  }

  return <div className={styles.selection}>
    <DesktopGameFormatSelection />
    {gameFormat === RING_GAME && <DesktopRingGameSelection />}
    {gameFormat === SITNGO_TOURNAMENT && <DesktopSitngoTournamentSelection />}
    {gameFormat === SCHEDULED_TOURNAMENT && <DesktopScheduledTournamentSelection />}
  </div>
}

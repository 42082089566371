import React from 'react'

import SngStakeButton from './SngStakeButton.js'

const stake = {currencyCode: 'USD', units: '100', nanos: 0}

export default function SngStakeButtonGroupSkeleton () {
  return <>
    <SngStakeButton stake={stake} />
    <SngStakeButton stake={stake} />
    <SngStakeButton stake={stake} />
    <SngStakeButton stake={stake} />
  </>
}

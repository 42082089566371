import React from 'react'

import DesktopSelectionButton from '../../components/DesktopSelectionButton.js'
import {useSetStakesRange, useStakesRange} from '../react-hooks.js'

export default function StakesRangeButton (props) {
  const {label, stakesRange} = props
  const isSelected = useStakesRange() === stakesRange

  return <DesktopSelectionButton label={label} isSelected={isSelected} onClick={useSetStakesRange(stakesRange)} />
}

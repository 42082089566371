import {createContext, useMemo} from 'react'

import {isDeepEqual} from './comparison.js'

export const context = createContext()
export const Provider = context.Provider

export function useDeepEqual (a, b) {
  return useMemo(() => isDeepEqual(a, b), [a, b])
}

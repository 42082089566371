export function createWindowTitleManager (name, window) {
  const {document} = window
  const suffix = ` - ${name}`

  return {
    set (title) {
      document.title = title ? `${title}${suffix}` : name
    },
  }
}

export function domready (fn) {
  if (document.readyState !== 'loading') {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn, {once: true})
  }
}

import Model from './model.coffee'

export default class Player extends Model

  constructor: ({details}) ->
    super()

    @properties =
      alias: details.alias ?= 'unnamed player'
      allInWinPct: details.allInWinPct ?= {}
      balance: details.balance ?= 0
      bet: details.bet ?= 0
      cards: details.cards ?= []
      cssClass: details.cssClass ?= ''
      isClient: details.isClient ?= false
      isTurn: details.isTurn ?= false
      label: details.label ?= ''
      location: details.location ?= ''
      city: details.city ?= ''
      position: details.position ?= -1
      status: details.status ?= 'in'
      usingTimebank: details.usingTimebank ?= false
      preferredSeat: null
      currency: details.currency ?= "$"
      precision: details.precision ?= 2
      accoladeId: details.accoladeId ?= null

    Object.defineProperty this, 'viewPosition',
        get: =>
          if @preferredSeat?
            return @preferredSeat
          else
            return @position

    # forces an event to be emitted
    # because the timer needs to be updated
    # even when the values are the same
    Object.defineProperty this, 'timer',
      get: => return @properties.timer
      set: (value) =>
        @properties.timer = value
        @emit 'change:timer', value

    @initializeModel arguments...

  betToPot: =>
    @emit 'betToPot'

  betToWinner: (winnerPosition) =>
    @emit 'betToWinner', winnerPosition

  chatMessage: (message) =>
    @emit 'chatMessage', message

  playSound: =>
    @emit 'playSound', arguments...

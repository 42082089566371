import React, {useCallback} from 'react'

import LabeledRadio from '../../../../components/LabeledRadio.js'
import RadioGroup from '../../../../components/RadioGroup.js'
import {AUTO_REBUY_POINT_OUT_OF_CHIPS, AUTO_REBUY_POINT_TABLE_MIN} from '../../../constants.js'
import styles from '../../AdvancedOptions.module.css'

import {
  useAutoRebuyPoint,
  useEnableAutoRebuyWhenBelowTableMinimum,
  useEnableAutoRebuyWhenOutOfChips,
} from '../../react-hooks.js'

export default function When () {
  const autoRebuyPoint = useAutoRebuyPoint()
  const enableAutoRebuyWhenOutOfChips = useEnableAutoRebuyWhenOutOfChips()
  const enableAutoRebuyWhenBelowTableMinimum = useEnableAutoRebuyWhenBelowTableMinimum()

  const handleChange = useCallback(value => {
    switch (value) {
      case AUTO_REBUY_POINT_OUT_OF_CHIPS: return enableAutoRebuyWhenOutOfChips()
      case AUTO_REBUY_POINT_TABLE_MIN: return enableAutoRebuyWhenBelowTableMinimum()
    }
  }, [enableAutoRebuyWhenOutOfChips, enableAutoRebuyWhenBelowTableMinimum])

  return <div className={styles.autoRebuyPoint}>
    <RadioGroup onChange={handleChange} value={autoRebuyPoint}>
      <LabeledRadio className={styles.autoRebuyPointRadio} value={AUTO_REBUY_POINT_OUT_OF_CHIPS} size='small'>
        When out of chips
      </LabeledRadio>
      <LabeledRadio className={styles.autoRebuyPointRadio} value={AUTO_REBUY_POINT_TABLE_MIN} size='small'>
        When below table minimum buy-in
      </LabeledRadio>
    </RadioGroup>
  </div>
}

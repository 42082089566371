import {EventEmitter} from 'events'

export default class Communicator extends EventEmitter
  constructor: (@casinoId, @socket, @signalFactory, @functionNameMapper, @dispatcher) ->
    super()

    @requests = {}

    @socket.on 'message', @receive

  message: (command, args = {}) =>
    args.casinoId = @casinoId
    @send command, args

  # Arguments typically in the form
  # fnName, {includes}
  send: (fnName, args = {}) =>
    outgoing = @dispatcher.command fnName, args
    @setNoResponseCb(outgoing)
    @requests[outgoing.attributes.id] = outgoing

  # Expects already parsed JSON messages.
  receive: (message) =>
    signal = @signalFactory.createSignal message, @requests[message.id]
    fnName = @functionNameMapper.mapName signal
    @emit 'signal', fnName, signal
    @runCallbacks(signal)

    # cleanup request
    delete @requests[message.id]
    return

  runCallbacks: (signal) =>
    if signal.parent
      if signal.parent.cb
        clearTimeout(signal.parent.noResponseCb)
        e = if signal.success is false then true else null
        signal.parent.cb(e, signal)

  # Run the message callbacks with errors if no response is ever returned.
  setNoResponseCb: (outgoing) =>
    unless outgoing.cb then return false
    outgoing.cbTime ?= 10000
    outgoing.noResponseCb = setTimeout =>
      # callback with error
      outgoing.cb(true)
      # If we do eventually receive a response we still process the message but we don't want to fire the callback again
      outgoing.cb = null
      # cleanup request
      outgoingId = outgoing?.attributes?.id
      if typeof outgoingId isnt 'undefined'
        delete @requests[outgoingId]

      return
    , outgoing.cbTime

  disconnect: =>
    @socket.removeListener 'message', @receive

  setMessageDetails: (details) =>
    @dispatcher.messageDetails = details

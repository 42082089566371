import React from 'react'

import {useWindowTitle} from '../../global/react-hooks.js'
import Listing from '../components/Listing.js'
import Selection from '../components/Selection.js'

const windowTitle = 'Game List'

export default function GameList () {
  useWindowTitle(windowTitle)

  return <div>
    <Selection />
    <Listing />
  </div>
}

import {NO_LIMIT, POT_LIMIT} from '@pipehat/chronicle/constants/limit-type'
import React from 'react'

import LimitTypeButton from './LimitTypeButton.js'
import {ALL} from '../../constants.js'
import {LIMIT_TYPE_MAP} from '../constants.js'
import {useVariant} from '../react-hooks.js'

export default function LimitTypeSelection () {
  const variant = useVariant()
  const variantLimitTypes = LIMIT_TYPE_MAP[variant]

  const shouldShowAll = variantLimitTypes.includes(ALL)
  const shouldShowNoLimit = variantLimitTypes.includes(NO_LIMIT)
  const shouldShowPotLimit = variantLimitTypes.includes(POT_LIMIT)

  return <ul>
    {shouldShowAll && <li><LimitTypeButton label='All' limitType={ALL} /></li>}
    {shouldShowNoLimit && <li><LimitTypeButton label='No Limit' limitType={NO_LIMIT} /></li>}
    {shouldShowPotLimit && <li><LimitTypeButton label='Pot Limit' limitType={POT_LIMIT} /></li>}
  </ul>
}

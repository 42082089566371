import invokeMap from 'lodash/invokeMap'

import Card from '../../../models/card.coffee'

export default ({signal, details, app, game, client, listing}) ->
  return unless client.player

  pCards = []
  for card in details
    pCards.push new Card card
  client.player.cards = pCards

  cardString = invokeMap(pCards, 'toJavaString').join(',')
  client.displayChatMessage message: 'You were dealt: ' + cardString
  game.updateHandHistory message: client.player.alias + ' Hole Cards: ' + cardString
  client.statistics.dealt++

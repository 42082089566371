import {DEREGISTER_FROM_TOURNAMENT, REGISTER_FOR_TOURNAMENT} from '@pipehat/chronicle/constants/command-type'

import {
  BALANCE_LOADED,
  OWN_TOURNAMENTS_LOADED,
  SIGNED_IN,
  TOURNAMENT_DEREGISTRATION_COMPLETED,
  TOURNAMENT_LEVEL_LOADED,
  TOURNAMENT_LOADED,
  TOURNAMENT_PARTICIPANTS_LOADED,
  TOURNAMENT_REGISTRATION_COMPLETED,
  TOURNAMENT_SHUT_DOWN,
} from '@pipehat/chronicle/constants/event-type'

import {FIXED_LIMIT} from '@pipehat/chronicle/constants/limit-type'

import {
  ANNOUNCED,
  CANCELED,
  FINISHED,
  IN_PROGRESS,
  LATE_REGISTRATION,
  REGISTERING,
  SHUT_DOWN,
  SHUT_DOWN_REFUNDED,
} from '@pipehat/chronicle/constants/tournament-status'

import {failureType, unpackTabularRow} from '@pipehat/chronicle/translation'
import {createSelector} from '@reduxjs/toolkit'
import {produce} from 'immer'
import {isTypeSequenceSupported} from '../game-type.js'

export {getRoot} from './ns.js'

const DEREGISTER_FROM_TOURNAMENT_FAILED = failureType(DEREGISTER_FROM_TOURNAMENT)
const REGISTER_FOR_TOURNAMENT_FAILED = failureType(REGISTER_FOR_TOURNAMENT)

const completedStatus = [CANCELED, FINISHED, SHUT_DOWN, SHUT_DOWN_REFUNDED]

// reducer =====================================================================

const SELF_REGISTRATION_STATUS_INIT = ''
const SELF_REGISTRATION_STATUS_REGISTERING = 'REGISTERING'
const SELF_REGISTRATION_STATUS_REGISTERED = 'REGISTERED'
const SELF_REGISTRATION_STATUS_DEREGISTERING = 'DEREGISTERING'

const init = {
  ownBalance: {},
  ownNickname: '',
  ownTournaments: [],
  tournaments: {},
}

const initTournament = {
  addOns: {
    areEnabled: false,
    chips: undefined,
    cost: undefined,
    perSeatLimit: 0,
    totalUsed: 0,
  },
  breakEndTime: undefined,
  contenderCount: 0,
  currency: '',
  currentLevel: undefined,
  endTime: undefined,
  description: '',
  entry: {
    bounty: undefined,
    buyIn: undefined,
    cost: undefined,
    fee: undefined,
    hasEligibilityProfile: undefined,
    isPasswordProtected: undefined,
    isReEntryEnabled: undefined,
    paymentOptions: undefined,
  },
  gameTypes: [],
  id: 0,
  isOnBreak: false,
  isSng: undefined,
  levelInterval: {
    amount: undefined,
    unit: undefined,
  },
  limitType: '',
  nextBreakTime: undefined,
  nextLevel: undefined,
  nextLevelTime: undefined,
  name: '',
  ownRegistrationStatus: SELF_REGISTRATION_STATUS_INIT,
  participants: [],
  placePrizes: [],
  prizeAccoladeId: 0,
  prizeOverlay: undefined,
  prizePool: undefined,
  rebuys: {
    areEnabled: false,
    chips: undefined,
    cost: undefined,
    perSeatLimit: 0,
    totalUsed: 0,
  },
  registration: {
    closeTime: '',
    isDeregistrationAllowed: undefined,
    late: {
      isEnabled: false,
      isStopOnElimination: false,
      ttl: 0,
    },
    maximumRegistrants: 0,
    minimumRegistrants: 0,
    openTime: undefined,
    registrantCount: 0,
  },
  satelliteCount: 0,
  satelliteFollowOnCount: 0,
  seatCount: 0,
  stackStats: {
    averageStack: undefined,
    biggestStack: undefined,
    smallestStack: undefined,
    totalStack: undefined,
  },
  startTime: undefined,
  startingStack: undefined,
  status: '',
  tables: [],
}

export function reducer (state = init, action) {
  const {type, payload} = action

  if (!payload) return state

  switch (type) {
    case BALANCE_LOADED:
      return produce(state, state => {
        state.ownBalance[payload.currencyCode] = payload
      })

    case OWN_TOURNAMENTS_LOADED:
      return produce(state, state => {
        state.ownTournaments = []

        const {keys, tournaments} = payload

        for (const tournament of tournaments) {
          const {tournamentId} = unpackTabularRow(keys, tournament)

          state.ownTournaments.push(tournamentId)
        }
      })

    case SIGNED_IN:
      return produce(state, state => {
        // needed to identify customer's own participant entry
        state.ownNickname = payload.nickname
      })
  }

  const tournamentId = payload.tournamentId || payload.cause?.tournamentId

  if (tournamentId) {
    state = produce(state, state => {
      const {tournaments} = state

      // tournaments are never deleted at the moment
      tournaments[tournamentId] = tournamentReducer(tournaments[tournamentId], action)
    })
  }

  return state
}

function tournamentReducer (tournament = initTournament, action) {
  const {type, payload} = action

  switch (type) {
    case DEREGISTER_FROM_TOURNAMENT:
      return produce(tournament, tournament => {
        tournament.ownRegistrationStatus = SELF_REGISTRATION_STATUS_DEREGISTERING
      })

    case DEREGISTER_FROM_TOURNAMENT_FAILED:
      return produce(tournament, tournament => {
        tournament.ownRegistrationStatus = SELF_REGISTRATION_STATUS_REGISTERED
      })

    case TOURNAMENT_DEREGISTRATION_COMPLETED:
      return produce(tournament, tournament => {
        tournament.ownRegistrationStatus = SELF_REGISTRATION_STATUS_INIT
      })

    case REGISTER_FOR_TOURNAMENT:
      return produce(tournament, tournament => {
        tournament.ownRegistrationStatus = SELF_REGISTRATION_STATUS_REGISTERING
      })

    case REGISTER_FOR_TOURNAMENT_FAILED:
      return produce(tournament, tournament => {
        tournament.ownRegistrationStatus = SELF_REGISTRATION_STATUS_INIT
      })

    case TOURNAMENT_REGISTRATION_COMPLETED:
      return produce(tournament, tournament => {
        tournament.ownRegistrationStatus = SELF_REGISTRATION_STATUS_REGISTERED
      })

    case TOURNAMENT_LEVEL_LOADED:
      return produce(tournament, tournament => {
        Object.assign(tournament, {
          currentLevel: payload.current,
          nextLevel: payload.next,
        })
      })

    case TOURNAMENT_LOADED:
      return produce(tournament, tournament => {
        const {
          addOns,
          breakEndTime,
          contenderCount,
          description,
          endTime,
          entry,
          gameTypes,
          isOnBreak,
          levelInterval,
          limitType,
          name,
          nextBreakTime,
          nextLevelTime,
          rebuys,
          registration,
          seatCount,
          startTime,
          startingStack,
          status,
          tournamentCurrency,
          tournamentId,
        } = payload

        const {accoladeId, currentPool, overlay} = payload.prizes
        const {satelliteCount, satelliteFollowOnTournamentCount} = payload.relatedTournaments

        Object.assign(tournament, {
          breakEndTime,
          contenderCount,
          currency: tournamentCurrency,
          description,
          endTime,
          gameTypes,
          id: tournamentId,
          isOnBreak,
          isSng: payload.isSng,
          limitType,
          name,
          nextBreakTime,
          nextLevelTime,
          placePrizes: [],
          prizeAccoladeId: accoladeId,
          prizeOverlay: overlay,
          prizePool: currentPool,
          satelliteCount,
          satelliteFollowOnCount: satelliteFollowOnTournamentCount,
          seatCount,
          startTime,
          startingStack,
          status,
          tables: [],
        })

        Object.assign(tournament.addOns, {
          areEnabled: addOns.areEnabled,
          chips: addOns.chips,
          cost: addOns.cost,
          perSeatLimit: addOns.perSeatLimit,
          totalUsed: addOns.totalUsed,
        })

        Object.assign(tournament.entry, {
          bounty: entry.bounty,
          buyIn: entry.buyIn,
          cost: entry.cost,
          fee: entry.fee,
          hasEligibilityProfile: entry.hasEligibilityProfile,
          isPasswordProtected: entry.isPasswordProtected,
          isReEntryEnabled: entry.isReEntryEnabled,
          paymentOptions: entry.paymentOptions,
        })

        Object.assign(tournament.levelInterval, {
          amount: levelInterval.amount,
          unit: levelInterval.unit,
        })

        Object.assign(tournament.registration, {
          closeTime: registration.closeTime,
          isDeregistrationAllowed: registration.isDeregistrationAllowed,
          late: {
            isEnabled: registration.late.isEnabled,
            isStopOnElimination: registration.late.isStopOnElimination,
            ttl: registration.late.ttl,
          },
          maximumRegistrants: registration.maximumRegistrants,
          minimumRegistrants: registration.minimumRegistrants,
          openTime: registration.openTime,
          registrantCount: registration.registrantCount,
        })

        Object.assign(tournament.rebuys, {
          areEnabled: rebuys.areEnabled,
          chips: rebuys.chips,
          cost: rebuys.cost,
          perSeatLimit: rebuys.perSeatLimit,
          totalUsed: rebuys.totalUsed,
        })

        let place = 0

        for (const {cash, coupons} of payload.prizes.places) {
          tournament.placePrizes.push({
            place: ++place,
            prize: {
              cash,
              coupons: coupons.map(({name, value}) => ({name, value})),
            },
          })
        }

        for (const table of payload.tables) {
          const {biggestStack, contenderCount, number, smallestStack, tableId} = table

          tournament.tables.push({biggestStack, contenderCount, number, smallestStack, tableId})
        }
      })

    case TOURNAMENT_PARTICIPANTS_LOADED:
      return produce(tournament, tournament => {
        const {averageStack, biggestStack, smallestStack, totalStack} = payload

        Object.assign(tournament, {
          participants: [],
          stackStats: {averageStack, biggestStack, smallestStack, totalStack},
        })

        const {keys, participants} = payload

        for (const data of participants) {
          const participant = unpackTabularRow(keys, data)

          const {
            bounty,
            eliminationOrder,
            nickname,
            place,
            stack,
            tableNumber = 0,
          } = participant

          let winnings

          if (participant.winnings) {
            const {cash, coupons} = participant.winnings
            winnings = {cash, coupons}
          }

          tournament.participants.push({
            bounty,
            eliminationOrder,
            nickname,
            place,
            stack,
            tableNumber,
            winnings,
          })
        }
      })

    case TOURNAMENT_SHUT_DOWN:
      return produce(tournament, tournament => {
        tournament.status = SHUT_DOWN
      })
  }

  return tournament
}

// selectors ===================================================================

export function createGetAddOns () {
  return createSelector([getTournament], tournament => tournament?.addOns)
}

export function createGetBreakEndTime () {
  return createSelector([getTournament], tournament => tournament?.breakEndTime)
}

export function createGetContenderCount () {
  return createSelector([getTournament], tournament => tournament?.contenderCount)
}

export function createGetCurrentLevel () {
  return createSelector([getTournament], tournament => tournament?.currentLevel)
}

export function createGetDescription () {
  return createSelector([getTournament], tournament => tournament?.description)
}

export function createGetEndTime () {
  return createSelector([getTournament], tournament => tournament?.endTime)
}

export function createGetEntryBounty () {
  return createSelector([getTournament], tournament => tournament?.entry.bounty)
}

export function createGetEntryCost () {
  return createSelector([getTournament], tournament => tournament?.entry.cost)
}

export function createGetEntryPaymentOptions () {
  return createSelector([getTournament], tournament => tournament?.entry.paymentOptions)
}

export function createGetLateRegistration () {
  return createSelector([getTournament], tournament => tournament?.registration.late)
}

export function createGetLevelInterval () {
  return createSelector([getTournament], tournament => tournament?.levelInterval)
}

export function createGetLimitType () {
  return createSelector([getTournament], tournament => tournament?.limitType)
}

export function createGetMaximumRegistrants () {
  return createSelector([getTournament], tournament => tournament?.registration.maximumRegistrants)
}

export function createGetMinimumRegistrants () {
  return createSelector([getTournament], tournament => tournament?.registration.minimumRegistrants)
}

export function createGetName () {
  return createSelector([getTournament], tournament => tournament?.name)
}

export function createGetNextBreakTime () {
  return createSelector([getTournament], tournament => tournament?.nextBreakTime)
}

export function createGetNextLevel () {
  return createSelector([getTournament], tournament => tournament?.nextLevel)
}

export function createGetNextLevelTime () {
  return createSelector([getTournament], tournament => tournament?.nextLevelTime)
}

export function createGetParticipantBounties () {
  return createSelector(
    [getTournament],
    ({participants}) => {
      const entries = []

      for (const {bounty, nickname} of participants) {
        if (!bounty) continue

        const {cash, claimedBy} = bounty

        entries.push({cash, claimedBy, nickname})
      }

      return entries
    },
  )
}

export function createGetParticipants () {
  return createSelector(
    [getTournament],
    ({participants, tables}) => {
      const tableIdMap = {}
      for (const {number, tableId} of tables) tableIdMap[number] = tableId

      const entries = []

      for (const {bounty, nickname, place, stack, tableNumber, winnings} of participants) {
        const tableId = tableIdMap[tableNumber]

        entries.push({bounty, nickname, place, stack, tableId, winnings})
      }

      return entries
    },
  )
}

export function createGetPrizeAccoladeId () {
  return createSelector([getTournament], tournament => tournament?.prizeAccoladeId)
}

export function createGetPrizeOverlay () {
  return createSelector([getTournament], tournament => tournament?.prizeOverlay)
}

export function createGetPlacePrizes () {
  return createSelector([getTournament], tournament => tournament?.placePrizes)
}

export function createGetPlacesPaidCount () {
  return createSelector([getTournament], tournament => tournament?.placePrizes.length)
}

export function createGetPrizePool () {
  return createSelector([getTournament], tournament => tournament?.prizePool)
}

export function createGetRebuys () {
  return createSelector([getTournament], tournament => tournament?.rebuys)
}

export function createGetRegistrationCount () {
  return createSelector([getTournament], tournament => tournament?.registration.registrantCount)
}

export function createGetRegistrationCloseTime () {
  return createSelector([getTournament], tournament => tournament?.registration.closeTime)
}

export function createGetRegistrationOpenTime () {
  return createSelector([getTournament], tournament => tournament?.registration.openTime)
}

export function createGetSatelliteCount () {
  return createSelector([getTournament], tournament => tournament?.satelliteCount)
}

export function createGetSatelliteFollowOnCount () {
  return createSelector([getTournament], tournament => tournament?.satelliteFollowOnCount)
}

export function createGetSeatCount () {
  return createSelector([getTournament], tournament => tournament?.seatCount)
}

export function createGetSelfBalance () {
  return createSelector(
    [
      getTournament,
      state => state.ownBalance,
    ],
    (tournament, ownBalance) => {
      return ownBalance && ownBalance[tournament?.currency]
    },
  )
}

export function createGetSelfPlace () {
  return createSelector(
    [
      state => state.ownNickname,
      getTournament,
    ],
    (ownNickname, {participants}) => {
      for (const {nickname, place} of participants) {
        if (nickname === ownNickname) return place
      }

      return undefined
    },
  )
}

export function createGetSelfTableId () {
  return createSelector(
    [
      state => state.ownNickname,
      getTournament,
    ],
    (ownNickname, {participants, tables}) => {
      const tableIdMap = {}
      for (const {number, tableId} of tables) tableIdMap[number] = tableId

      for (const {nickname, tableNumber} of participants) {
        if (nickname === ownNickname) return tableIdMap[tableNumber]
      }

      return undefined
    },
  )
}

export function createGetStartTime () {
  return createSelector([getTournament], tournament => tournament?.startTime)
}

export function createGetStackStats () {
  return createSelector([getTournament], tournament => tournament?.stackStats)
}

export function createGetStartingStack () {
  return createSelector([getTournament], tournament => tournament?.startingStack)
}

export function createGetStatus () {
  return createSelector([getTournament], tournament => tournament?.status)
}

export function createGetTableCount () {
  return createSelector([getTournament], tournament => tournament?.tables.length)
}

export function createGetTables () {
  return createSelector([getTournament], tournament => tournament?.tables)
}

export function createGetTotalUsedAddOns () {
  return createSelector([getTournament], tournament => tournament?.addOns.totalUsed)
}

export function createGetTotalUsedRebuys () {
  return createSelector([getTournament], tournament => tournament?.rebuys.totalUsed)
}

export function createGetWinner () {
  return createSelector(
    [getTournament],
    ({participants, status}) => status === FINISHED && participants?.length ? participants[0].nickname : undefined,
  )
}

export function createHasCompleted () {
  return createSelector([getTournament], tournament => completedStatus.includes(tournament?.status))
}

export function createHasEntryEligibilityProfile () {
  return createSelector([getTournament], tournament => tournament?.entry.hasEligibilityProfile)
}

export function createHasStarted () {
  return createSelector(
    [getTournament],
    tournament => tournament?.status === IN_PROGRESS || tournament?.status === LATE_REGISTRATION,
  )
}

export function createIsAnnounced () {
  return createSelector([getTournament], tournament => tournament?.status === ANNOUNCED)
}

export function createIsDeregistrationAllowed () {
  return createSelector([getTournament], tournament => tournament?.registration.isDeregistrationAllowed)
}

export function createIsFinished () {
  return createSelector([getTournament], tournament => tournament?.status === FINISHED)
}

export function createIsFixedLimit () {
  return createSelector([getTournament], tournament => tournament?.limitType === FIXED_LIMIT)
}

export function createIsInProgress () {
  return createSelector([getTournament], tournament => tournament?.status === IN_PROGRESS)
}

export function createIsLateRegistration () {
  return createSelector([getTournament], tournament => tournament?.status === LATE_REGISTRATION)
}

export function createIsOnBreak () {
  return createSelector([getTournament], tournament => tournament?.isOnBreak)
}

export function createIsPasswordProtected () {
  return createSelector([getTournament], tournament => tournament?.entry.isPasswordProtected)
}

export function createIsReEntryEnabled () {
  return createSelector([getTournament], tournament => tournament?.entry.isReEntryEnabled)
}

export function createIsRegistering () {
  return createSelector([getTournament], tournament => tournament?.status === REGISTERING)
}

export function createIsRegistrationOpen () {
  return createSelector(
    [getTournament],
    tournament => tournament?.status === REGISTERING || tournament?.status === LATE_REGISTRATION,
  )
}

export function createIsSelfContending () {
  return createSelector(
    [
      state => state.ownTournaments,
      getTournament,
    ],
    (ownTournaments, tournament) => {
      if (!tournament) return false

      const {id} = tournament

      return ownTournaments.includes(id)
    },
  )
}

export function createIsSelfDeregistering () {
  return createSelector(
    [getTournament],
    tournament => tournament?.ownRegistrationStatus === SELF_REGISTRATION_STATUS_DEREGISTERING,
  )
}

export function createIsSelfParticipant () {
  return createSelector(
    [
      state => state.ownNickname,
      getTournament,
    ],
    (ownNickname, tournament) => {
      if (!tournament) return false

      const {participants} = tournament

      for (const {nickname} of participants) {
        if (nickname === ownNickname) return true
      }

      return false
    },
  )
}

export function createIsSelfRegistering () {
  return createSelector(
    [getTournament],
    tournament => tournament?.ownRegistrationStatus === SELF_REGISTRATION_STATUS_REGISTERING,
  )
}

export function createIsSelfRegistrationAllowed () {
  return createSelector(
    [
      state => state.ownNickname,
      state => state.ownTournaments,
      getTournament,
    ],
    (ownNickname, ownTournaments, tournament) => {
      if (!tournament) return false

      const {entry: {isReEntryEnabled}, gameTypes, id, participants, status} = tournament
      let contendingCount = 0
      let knockedOutCount = 0

      for (const {eliminationOrder, nickname} of participants) {
        if (nickname === ownNickname) eliminationOrder ? ++knockedOutCount : ++contendingCount
      }

      const isKnockedOut = contendingCount > 1 && contendingCount === knockedOutCount
      const isRegistrationOpen = status === REGISTERING || status === LATE_REGISTRATION

      if (!isTypeSequenceSupported(gameTypes)) return false
      if (!isRegistrationOpen) return false
      if (ownTournaments.includes(id)) return false
      if (isKnockedOut && !isReEntryEnabled) return false

      return true
    },
  )
}

export function createIsSng () {
  return createSelector([getTournament], tournament => tournament?.isSng)
}

export function createGetTournament () {
  return createSelector([getTournament], tournament => tournament)
}

export function getSelfNickname (state) {
  return state.ownNickname
}

function getTournament (state, {tournamentId}) {
  return state.tournaments[tournamentId]
}

import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

export default class RegisterTranslator extends Mixmaster
  mixins = [
    DefaultMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal
    signal.tournamentRegistration = {}

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  gameName: (signal, name, values) ->
    signal.tournamentRegistration.gameName = values

  gameNumber: (signal, name, values) ->
    signal.tournamentRegistration.gameNumber = parseInt(values,10)

  numberPlayers: (signal, name, values) ->
    signal.tournamentRegistration.numberPlayers = parseInt(values,10)

  lateRegistration: (signal, name, values) ->
    signal.tournamentRegistration.lateRegistration = values

  startTime: (signal, name, values) ->
    signal.tournamentRegistration.startTime = @parseDate values

  tournamentRematch: (signal, name, values) ->
    signal.tournamentRegistration.tournamentRematch = values

  notificationType: (signal, name, values) ->
    signal.tournamentRegistration.notificationType = values

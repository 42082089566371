import {useContext} from 'react'

import {context} from '../react.js'

export function useShowModal () {
  return useModalService().showModal
}

function useModalService () {
  return useContext(context).modalService
}

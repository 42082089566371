import React from 'react'

import LabelRow from './LabelRow.js'
import {useAuthorizeNotifications} from '../react-hooks.js'

export default function AuthorizeNotifications () {
  return <LabelRow
    name='Enable notifications'
    help='Modern browsers allow pop up notifications. Use these settings to be shown reminders for tournaments and other notifications.'
  >
    <button type='button' className='button' onClick={useAuthorizeNotifications()}>Authorize</button>
  </LabelRow>
}

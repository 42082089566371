import classnames from 'classnames'
import React from 'react'

import styles from './Selection.module.css'
import {GAME_FORMAT} from '../constants.js'
import MobileRingGameSelection from '../ring-game/components/MobileSelection.js'
import MobileScheduledTournamentSelection from '../scheduled-tournament/components/MobileSelection.js'
import MobileSitngoTournamentSelection from '../sitngo-tournament/components/MobileSelection.js'

const {RING_GAME, SCHEDULED_TOURNAMENT, SITNGO_TOURNAMENT} = GAME_FORMAT

export default function MobileGameFormatSelection ({format}) {
  const isRingGame = format === RING_GAME

  return <div className={classnames(styles.lobbyTabBar, {[styles.ringGames]: isRingGame})}>
    <div className={styles.mobileSelection}>
      {isRingGame && <MobileRingGameSelection />}
      {format === SITNGO_TOURNAMENT && <MobileSitngoTournamentSelection />}
      {format === SCHEDULED_TOURNAMENT && <MobileScheduledTournamentSelection />}
    </div>
  </div>
}

export default ({signal, details, app, game, client, listing}) ->
  return unless game

  for eliminated in details
    message = "Admin: Player #{eliminated.name} finished in #{eliminated.positionFinished} position"
    if eliminated.winnings
      if eliminated.winnings.cash
        message += " and won $#{eliminated.winnings.cash}"
      if eliminated.winnings.coupon
        message += "#{eliminated.winnings.coupon}"
    message += '.'
    client?.displayChatMessage {message}

    if eliminated.name is app.user.nickname and app.settings.showTournamentPositionNotice
      app.tournamentPosition eliminated.positionFinished, eliminated.winnings, game.tournamentId

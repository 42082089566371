# LOGIN VIEW
# Model: USER

import StatusMessageView from './statusMessageView.coffee'
import View from './view.coffee'

export default class LoginView extends View

  constructor: (user) ->
    super()

    @model = user

    @dom = $ '#auth'

    # ### Components ###
    @components =
      form:             @dom.find('#loginForm')
      username:         @dom.find('input#username')
      password:         @dom.find('input#password')
      submitBtn:        @dom.find('#loginSubmit')
      rememberUsername: @dom.find('#rememberUsername')
      logo:             @dom.find('.dynamic-logo')
      statusContainer:  @dom.find('.status-message')
      status:           new StatusMessageView(@model)
      problemSigningIn: @dom.find('.problem-signing-in')
      reportProblem:    @dom.find('.report-a-problem')
      switchFormat:     @dom.find('.switch-format')

    @components.form.hide()

    # ### Events ###

    # @components.submitBtn.on 'click', @submit
    @components.form.on 'submit', @submit
    @components.username.on 'change', @updateCredentials
    @components.password.on 'change', @updateCredentials
    @components.rememberUsername.on 'change', @changeRememberUsername
    @components.problemSigningIn.on 'click', @problemSigningIn
    @components.reportProblem.on 'click', @reportProblem
    @components.switchFormat.on 'click', (e) =>
      e.preventDefault()
      @model.emit 'switchFormat'

    unless Webclient.Format in ['desktop', 'tablet']
      @components.switchFormat.hide()

    # ### Listeners ###
    @listeners =
      'auth:success': @authSuccess
      'auth:fail': @authFail
      'connecting': @connecting
      'connected': @connected
      'auth': @auth

    @updateRememberUsername()

    # ### Binding ###
    @initializeView()
    return this

  # overwrite default with no-op. use existing dom sent by server for native save-password
  render: =>
    @components.statusContainer.replaceWith(@components.status.render())
    @dom

  connecting: => @setStatus null, 'Connecting...'

  connected: =>
    # Show sockets are connected and ready for login
    @components.form.show()
    @components.submitBtn.attr 'disabled', false
    @components.submitBtn.removeClass 'disabled'
    @setStatus()

    if @model.settings.rememberUsername
      @components.password.focus()
    else
      @components.username.focus()

  # ### Login Methods
  updateCredentials: =>
    @model.username = @components.username.val()
    @model.password = @components.password.val()

  updateRememberUsername: =>
    @components.rememberUsername.prop 'checked', @model.settings.rememberUsername
    if @model.settings.rememberUsername
      @components.username.val @model.settings.rememberUsername

  changeRememberUsername: =>
    if @components.rememberUsername.prop 'checked'
      @model.settings.rememberUsername = @components.username.val()
    else
      @model.settings.rememberUsername = false

  submit: (e) =>
    # e.preventDefault()
    # hide keyboard so user can see login error if any
    @components.username.trigger('blur')
    @components.password.trigger('blur')
    # stop annoying double-submit caused by native save pass necessities
    if @inProgress
      return

    @auth()
    if @model.username and @model.password
      @model.auth()
      @loginTimeout = setTimeout (=> @authFail 'timeout'), 10000
    else
      @authFail 'detailsRequired'
    return false # prevent form submit

  auth: =>
    @components.submitBtn.addClass 'disabled'
    @setStatus "Logging in..."
    @updateCredentials()
    @changeRememberUsername()
    @inProgress = true

  authSuccess: =>
    @dom.remove()

  authFail: (reason) =>
    # This stops authFail from being called twice
    clearTimeout @loginTimeout
    # Stops the user from submitting the wrong password twice
    @components.password.val ''
    @inProgress = false
    @components.submitBtn.removeClass 'disabled'
    switch reason
      when 'detailsRequired', 'requiredFieldMissing'
        @setStatus 'Username and password required'
      when 'invalidUserId', 'incorrectPassword'
        @setStatus 'Invalid username or password'
      when 'tooManyFailedLogins'
        @setStatus \
          'Your account has been suspended due to too many failed logins. Please contact customer support.',
          'Too many failed logins'
      when 'inactiveUserAccount'
        @setStatus \
          'Your account has been marked as inactive.',
          'Inactive user account'
      when 'externalUserFirstLogin'
        @setStatus \
          'Please enter your username and password. You should only see this message once.',
          'Automated Login Failed'
      when 'timeout'
        @setStatus 'Login Request Timed Out'
      else
        @setStatus 'Login Fail'

  setStatus: (reason, title) =>
    @components.status.title(title)
    @components.status.reason(reason)

  checkEnter: (e) =>
    if e.keyCode is 13
      @submit()

  problemSigningIn: (e) =>
    e.preventDefault()
    @model.emit 'problemSigningIn'

  reportProblem: (e) =>
    e.preventDefault()
    @model.emit 'reportProblem'

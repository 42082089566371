import React, {useCallback, useEffect, useRef, useState} from 'react'

import Numeric from './Numeric.js'
import Slider from './Slider.js'
import styles from './Stepper.module.css'
import {clamp} from '../number.js'

const MINUS_SIGN = '\u2212' // this is `−`, which is like keyboard hypen `-` ('\u002d') but wider

export default function Stepper (props) {
  const {className, digits, min, max, onChange, onInvalid, slider, step, value: initialValue} = props
  const rangeRef = useRef()
  const [value, setValue] = useState(clamp(initialValue, min, max))
  const rangeMax = max - ((max - min) % step)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleChange = useCallback(newValue => {
    setValue(newValue)
    onChange(newValue)
  }, [onChange, setValue])

  const handleIncrease = useCallback(() => {
    if (rangeRef.current) {
      if (rangeRef.current.valueAsNumber + step > rangeMax) {
        handleChange(max)
      } else {
        rangeRef.current.stepUp()
        handleChange(rangeRef.current.valueAsNumber)
      }
    }
  }, [handleChange, max, rangeMax, step])

  const handleDecrease = useCallback(() => {
    if (rangeRef.current) {
      if (value > rangeMax) {
        handleChange(rangeMax)
      } else {
        rangeRef.current.stepDown()
        handleChange(rangeRef.current.valueAsNumber)
      }
    }
  }, [handleChange, rangeMax, value])

  return <div className={className}>
    <div className={styles.stepper}>
      <button type='button' className={styles.less} onClick={handleDecrease}>{MINUS_SIGN}</button>
      <Numeric
        className={styles.value}
        digits={digits}
        min={min}
        max={max}
        value={value}
        setValue={handleChange}
        onInvalid={onInvalid}
      />
      <button type='button' className={styles.more} onClick={handleIncrease}>+</button>
    </div>

    <Slider
      rangeRef={rangeRef}
      className={{[styles.hiddenSlider]: !slider}}
      min={min}
      max={max}
      step={step}
      value={value}
      setValue={handleChange}
    />
  </div>
}

import {createElement} from 'react'
import {createRoot} from 'react-dom/client'
import {Provider as ReduxProvider} from 'react-redux'
import {RouterProvider} from 'react-router5'

import {Provider as ServiceProvider} from '../../react.js'
import {ClockProvider, systemClock} from '../../time/clock.js'

export function createReactViewFactory (
  router,
  store,
  windowTitleManager,
  createTableService,
  createTournamentService,
  authService,
  bonusService,
  deviceService,
  gameListService,
  globalService,
  installationService,
  lobbyService,
  menuService,
  modalService,
  multiTableService,
  namedUrlService,
  quickSeatService,
  responsibleGamingService,
  settingsService,
  updatingService,
) {
  return function createReactView (component, props = {}, attributes = {}, tagName = 'span') {
    const services = {
      authService,
      bonusService,
      createTableService,
      createTournamentService,
      deviceService,
      gameListService,
      globalService,
      installationService,
      lobbyService,
      menuService,
      modalService,
      multiTableService,
      namedUrlService,
      quickSeatService,
      responsibleGamingService,
      settingsService,
      updatingService,
    }

    return new ReactView(
      router,
      store,
      services,
      component,
      props,
      attributes,
      tagName,
      windowTitleManager,
    )
  }
}

export function createPopupReactViewFactory (
  router,
  store,
  windowTitleManager,
  createTableService,
  modalService,
  multiTableService,
  namedUrlService,
  settingsService,
) {
  return function createReactView (component, props = {}, attributes = {}, tagName = 'span') {
    const services = {
      createTableService,
      modalService,
      multiTableService,
      namedUrlService,
      settingsService,
    }

    return new ReactView(
      router,
      store,
      services,
      component,
      props,
      attributes,
      tagName,
      windowTitleManager,
    )
  }
}

function ReactView (
  router,
  store,
  services,
  component,
  props,
  attributes,
  tagName,
  windowTitleManager,
) {
  const {windowTitle} = component

  let containerRoot
  let container
  let displayType = ''
  let isVisible

  this.render = function () {
    const content = createElement(component, props)
    const clockProvider = createElement(ClockProvider, {value: systemClock}, content)
    const routeProvider = createElement(RouterProvider, {router}, clockProvider)
    const serviceProvider = createElement(ServiceProvider, {value: services}, routeProvider)
    const reduxProvider = createElement(ReduxProvider, {store}, serviceProvider)

    container = createContainer(tagName, attributes)
    containerRoot = createRoot(container)

    if (typeof isVisible === 'undefined') isVisible = container.style.display !== 'none'
    applyVisibility()

    containerRoot.render(reduxProvider)

    return container
  }

  this.destroy = function () {
    containerRoot.unmount()
  }

  this.show = function () {
    if (windowTitle) windowTitleManager.set(windowTitle)

    isVisible = true
    if (container) applyVisibility()
  }

  this.hide = function () {
    isVisible = false
    if (container) applyVisibility()
  }

  this.container = function () {
    if (container) return container

    throw new Error('Container not defined')
  }

  function applyVisibility () {
    const {style} = container

    if (style.display !== 'none') displayType = style.display
    style.display = isVisible ? displayType : 'none'
  }
}

function createContainer (tagName, attributes) {
  const container = document.createElement(tagName)

  for (const attributeName in attributes) {
    container.setAttribute(attributeName, attributes[attributeName])
  }

  return container
}

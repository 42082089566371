import ViewManager from '../views/viewManager.coffee'
import PopupArcadeView from '../views/popupArcadeView.coffee'

export default class PopupController

  constructor: (navigator, instanceId, format, createReactView, @windowTitleManager) ->
    window.AVATAR_URL   = window.opener.AVATAR_URL
    window.Navigator    = window.opener.Navigator
    window.Webclient.ViewManager = new ViewManager format
    window.Webclient.Format      = window.opener.Webclient.Format

    @master = window.opener.popupMaster

    @client = @getClient instanceId
    if @client
      $('#auth').hide()
      @client.hasFocus = ->
        return !document.hasFocus or document.hasFocus()
      @changeTitle()
      @client.on "change:isTurn", @changeTitle
      new PopupArcadeView navigator, @client, createReactView, format is 'desktop', @windowTitleManager, @

      @client.once 'destroy', @_clientDestroyHandler

      $(window).on 'unload', @unload
    else
      window.close()

  _clientDestroyHandler: =>
    $(window).off 'unload', @unload # remove unload handler
    @master.removePopup @client # remove from list of popups

  unload: =>
    @client.removeListener "change:isTurn", @changeTitle
    @client.removeListener 'destroy', @_clientDestroyHandler
    @master.popIn @client

  changeTitle: =>
    if not @client.isTurn
      clearInterval @interval
      @setTitle ''
    else
      @setTitle "It's your turn"

      @blink = false
      @interval = setInterval =>
        @blink = !@blink

        if @blink
          actions = @client.currentActions.map(@_renderActionLabel).join(" | ")

          if actions
            @setTitle actions
            return

        @setTitle "It's your turn"
      , 1000

  setTitle: (prefix) =>
    game = @client.game
    tournament = game.tournament

    if tournament?
      gameDetails = "#{tournament.gameName} - #{game.gameName}"

      if game.levelDetails?
        {smallblind, bigblind} = game.levelDetails
        gameDetails = "#{gameDetails} - #{smallblind}/#{bigblind} - #{tournament.limitTypeDesc.short}"
    else
      gameDetails = game.gameName

    if prefix
      @windowTitleManager.set "#{prefix} - #{gameDetails}"
    else
      @windowTitleManager.set gameDetails

  getClient: (instanceId) =>
    client = @master.getClient(instanceId)
    return client

  _renderActionLabel: (action) ->
    switch action
      when 'fold'   then return 'Fold'
      when 'check'  then return 'Check'
      when 'call'   then return 'Call'
      when 'bet'    then return 'Bet'
      when 'raise'  then return 'Raise'
      when 'show'   then return 'Show'
      when 'muck'   then return 'Muck'
      when 'rabbit' then return 'Rabbit'
      when 'allIn'  then return 'All In'
      when 'postBb' then return 'Post BB'
      when 'postSb' then return 'Post SB'
      when 'postIb' then return 'Post IB'
      when 'postRb' then return 'Post RB'
      when 'waitRb' then return 'Wait for BB'
      when 'waitIb' then return 'Wait for BB'
      when 'sitOut' then return 'Sit Out'
      when 'rebuy'  then return 'Rebuy'
    return ''

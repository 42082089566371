import {
  RING_GAME_TABLE_LOADED,
  SELF_REMOVED_FROM_TABLE,
  TABLE_LEFT,
  TABLE_SHUT_DOWN,
  TOURNAMENT_TABLE_LOADED,
} from '@pipehat/chronicle/constants/event-type'

import {produce} from 'immer'
import {combineReducers} from 'redux'

import actions from './actions/actions-slice.js'
import addChips from './add-chips/add-chips-slice.js'
import advancedOptions from './advanced-options/advanced-options-slice.js'
import bank from './bank/bank-slice.js'
import chat from './chat/chat-slice.js'
import controls from './controls/controls-slice.js'
import gameDetails from './game-details/game-details-slice.js'
import quickOptions from './quick-options/quick-options-slice.js'
import seats from './seats/seats-slice.js'
import timeBank from './time-bank/time-bank-slice.js'

const reducers = combineReducers({
  tableId: idReducer,
  tournamentId: tournamentIdReducer,
  actions,
  addChips,
  advancedOptions,
  bank,
  chat,
  controls,
  gameDetails,
  quickOptions,
  seats,
  timeBank,
})

export function reducer (state = {}, action) {
  const {type, payload = {}} = action
  let tableId = payload.tableId

  // Handle table merge messages
  if (!tableId && payload.fromTableId) {
    tableId = payload.fromTableId
  }

  // Handle table error/failure messages
  if (!tableId && payload.cause?.tableId) {
    tableId = payload.cause.tableId
  }

  if (!tableId) {
    if (payload.tournamentId) {
      // Handle tournament messages to no specific table
      for (const table of Object.values(state)) {
        if (table.tournamentId === payload.tournamentId) {
          state = produce(state, state => {
            state[table.tableId] = reducers(state[table.tableId], action)
          })
        }
      }
    }

    return state
  }

  // Handle single table only messages below ...

  state = produce(state, state => {
    state[tableId] = reducers(state[tableId], action)
  })

  switch (type) {
    case SELF_REMOVED_FROM_TABLE:
    case TABLE_LEFT:
    case TABLE_SHUT_DOWN:
      return produce(state, state => {
        delete state[tableId]
      })
  }

  return state
}

function idReducer (state = 0, action) {
  const {type, payload = {}} = action

  switch (type) {
    case RING_GAME_TABLE_LOADED:
    case TOURNAMENT_TABLE_LOADED:
      state = payload.tableId

      break
  }

  return state
}

function tournamentIdReducer (state = 0, action) {
  const {type, payload = {}} = action

  switch (type) {
    case TOURNAMENT_TABLE_LOADED:
      state = payload.tournamentId

      break
  }

  return state
}

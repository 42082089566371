import {
  createZero,
  isGreaterThan,
  isLessThan,
  maximum,
  minimum,
  multiply,
  negate,
  sum,
} from '@pipehat/money/plain-object'

import React, {useEffect, useMemo} from 'react'

import DetailsCell from './components/Cell.js'
import DetailsColumn from './components/Column.js'
import DetailsMoney from './components/Money.js'
import DetailsPanel from './components/Panel.js'
import PresetButtonsPanel from './components/PresetButtonsPanel.js'
import DetailsValue from './components/Value.js'
import VisitCashier from './components/VisitCashier.js'

import {
  useBalance,
  useIsFixedLimit,
  useMaximumBuyIn,
  useMinimumBuyIn,
  useRingGameRebuyAmount,
  useSetRingGameRebuyAmount,
  useShowInsufficientFundsForRingGameRebuy,
  useStakes,
} from './react-hooks.js'

import {roundStepAmount} from './rendering.js'
import MoneyStepper from '../../money/components/MoneyStepper.js'
import {useDefaultBuyInBigBlindMultiplier} from '../../settings/react-hooks.js'
import {useStack} from '../seats/react-hooks.js'

export default function RingGameRebuy () {
  const showInsufficientFundsForRingGameRebuy = useShowInsufficientFundsForRingGameRebuy()
  const defaultBuyInBigBlindMultiplier = useDefaultBuyInBigBlindMultiplier()
  const rebuyAmount = useRingGameRebuyAmount()
  const setRebuyAmount = useSetRingGameRebuyAmount()
  const isFixedLimit = useIsFixedLimit()
  const balance = useBalance()
  const ownStack = useStack()
  const minimumBuyIn = useMinimumBuyIn()
  const maximumBuyIn = useMaximumBuyIn()
  const stakes = useStakes()

  const hasTableData = balance && ownStack && minimumBuyIn && stakes && true
  const {maximumStake: bigBlind} = hasTableData ? stakes : {}

  const remainingTableLimit = useMemo(() => {
    if (!hasTableData) return undefined

    return isFixedLimit ? balance : sum([maximumBuyIn, negate(ownStack)])
  }, [balance, hasTableData, isFixedLimit, maximumBuyIn, ownStack])

  const effectiveMaximum = useMemo(() => {
    if (!hasTableData) return undefined

    return isFixedLimit ? balance : minimum([balance, maximumBuyIn])
  }, [balance, hasTableData, isFixedLimit, maximumBuyIn])

  const minimumAmount = useMemo(() => {
    if (!hasTableData) return undefined

    return createZero(minimumBuyIn.currencyCode)
  }, [hasTableData, minimumBuyIn])

  const maximumAmount = useMemo(() => {
    if (!hasTableData) return undefined
    if (isFixedLimit) return balance // fixed limit games have no limit

    const isLessThanRemainingTableLimit = isLessThan(remainingTableLimit)
    if (isLessThanRemainingTableLimit(balance)) {
      return balance
    }

    return maximum([
      minimumAmount,
      minimum([minimumBuyIn, remainingTableLimit]),
      minimum([effectiveMaximum, remainingTableLimit]),
    ])
  }, [balance, effectiveMaximum, hasTableData, isFixedLimit, minimumAmount, minimumBuyIn, remainingTableLimit])

  const defaultAmount = useMemo(() => {
    if (!hasTableData) return undefined

    const defaultBuyIn = multiply(Number(defaultBuyInBigBlindMultiplier), bigBlind)

    return maximum([minimumAmount, minimum([defaultBuyIn, effectiveMaximum, remainingTableLimit])])
  }, [bigBlind, defaultBuyInBigBlindMultiplier, effectiveMaximum, hasTableData, minimumAmount, remainingTableLimit])

  const stepAmount = useMemo(() => {
    if (!hasTableData) return undefined

    return roundStepAmount(multiply(10, bigBlind))
  }, [hasTableData, bigBlind])

  useEffect(() => {
    if (!defaultAmount || !maximumAmount) return

    const isGreaterThanMaximumAmount = isGreaterThan(maximumAmount)
    if (rebuyAmount === undefined || isGreaterThanMaximumAmount(rebuyAmount)) {
      setRebuyAmount(defaultAmount)
    }
  }, [defaultAmount, maximumAmount, rebuyAmount, setRebuyAmount])

  if (!hasTableData || rebuyAmount === undefined) return null // still loading

  const isLessThanMinimumAmount = isLessThan(minimumAmount)
  if (isLessThanMinimumAmount(balance)) {
    showInsufficientFundsForRingGameRebuy(balance, minimumAmount)

    return null
  }

  return <div>
    <MoneyStepper
      min={minimumAmount}
      max={maximumAmount}
      step={stepAmount}
      value={rebuyAmount}
      onChange={setRebuyAmount}
    />

    <PresetButtonsPanel
      min={minimumAmount}
      max={maximumAmount}
      defaultAmount={defaultAmount}
      setAmount={setRebuyAmount}
    />

    <DetailsPanel>
      <DetailsColumn>
        <DetailsCell>My Balance: <DetailsMoney value={balance} /> <VisitCashier /></DetailsCell>
        <DetailsCell>Table Balance: <DetailsMoney value={ownStack} /></DetailsCell>
      </DetailsColumn>

      <DetailsColumn>
        <DetailsCell>Table Min Buy In: <DetailsMoney value={minimumBuyIn} /></DetailsCell>
        <DetailsCell>
          Table Max Buy In:
          {isFixedLimit ? <DetailsValue value='Unlimited' /> : <DetailsMoney value={maximumBuyIn} />}
        </DetailsCell>
      </DetailsColumn>
    </DetailsPanel>
  </div>
}

import Mixmaster from '../../../mixmaster.coffee'
import WclEnum from '../../../../enum/index.coffee'

import PokerEventData from './pokerEventData.coffee'
import PlayerDetailsData from './playerDetailsData.coffee'
import PlayerActionData from './playerActionData.coffee'
import PlayerBalanceData from './playerBalanceData.coffee'
import TimebankData from './timebankData.coffee'
import CurrentBetData from './currentBetData.coffee'
import PotData from './potData.coffee'
import JackpotData from './jackpotData.coffee'
import RebuyData from './rebuyData.coffee'

export default class PokerStatusData extends Mixmaster
  mixins = [
    PokerEventData
    PlayerDetailsData
    TimebankData
    PlayerActionData
    PlayerBalanceData
    CurrentBetData
    PotData
    JackpotData
    RebuyData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  mixin: (object) ->
    mixins.map((mixin) => (new mixin).mixin(object))

    object.currentPlayer = (signal, name, values) ->
      currentPlayers = values.value1.split '|'
      if currentPlayers.length > 1
        signal.updates.currentPlayers = {}
        for position, _ in currentPlayers
          signal.updates.currentPlayers[position] = {}
          signal.updates.currentPlayers[position].position = parseInt(position)
          signal.updates.currentPlayers[position].timer = parseInt(values.playerTimer)
      else
        signal.updates.currentPlayer ?= {}
        signal.updates.currentPlayer.position = parseInt(values.value1)
        signal.updates.currentPlayer.timer = parseInt(values.playerTimer)

    object.communityCards = (signal, name, values) ->
      signal.updates.communityCards =
        cards: WclEnum.getCards(values.cards)
        description: WclEnum.getHandDescription(values.handDescription)

    object.dealer = (signal, name, values) ->
      signal.updates.dealer = parseInt(values,10)

    object.sitOutType = (signal, name, values) ->
      signal.sitOut ?= {}
      signal.sitOut.type = values
      signal.sitOut.success = signal.success

    object.currentState = (signal, name, values) ->
      if values.code1?
        if values.code1 is values.code2
          state =
            current: values.code1
        else
          state =
            current: values.code1
            previous: values.code2
            cards: values.code3
      else if typeof values is 'string'
        state = {current: values}
      # Sometimes we are passed CURRENT_STATE with no values
      if state?
        signal.updates.currentState = state

    object.numberPlayers = (signal, name, values) ->
      #e.g. { watching: '1', waitListLength: '0', playing: '8' }
      signal.updates.numberPlayers = {
        watching: parseInt(values.watching, 10)
        waitListLength: parseInt(values.waitListLength, 10)
        playing: parseInt(values.playing, 10)
      }

    object.playerCards = (signal, name, values) ->
      # { dealt: '0|2', cards: '9c,4h', handDescription: '1,nine' }
      # signal.updates.playerCards = {
      if values.dealt
        # fake and gay, can't just use map because of radix
        signal.updates.dealtPlayers = values.dealt?.split('|').map((num) -> parseInt(num, 10))

      if values.cards
        signal.updates.playerCards = WclEnum.getCards(values.cards)

      if values.handDescription
        signal.updates.handDescription = WclEnum.getHandDescription(values.handDescription)

    object.allBlinds = (signal, name, values) ->
      signal.updates.allBlinds ?= {}
      # sample attributes: { smallBlind: '5,7.70,0.25,false,',  bigBlind: '6,100.13,0.50,false,' }
      for blindType, fields of values
        [position, balance, amount, returnBlind] = fields.split ','

        signal.updates.allBlinds[blindType] = {
          position: parseInt(position,10)
          balance: parseFloat(balance)
          amount: parseFloat(amount)
          returnBlind: returnBlind is 'true'
        }

        # 40.00,45.00
        if blindType is 'antePots'
          signal.updates.allBlinds.antePots = []
          for amount, number in fields.split ','
            number = (number + 1).toString()
            signal.updates.allBlinds.antePots.push {amount, number}

    object.rake = (signal, name, values) ->
      signal.updates.rake = parseFloat values

    object.previousBalance = (signal, name, values) ->
      if typeof values is 'object'
        signal.updates.previousBalance = parseFloat values.true
      else
        signal.updates.previousBalance = false

    object.accolade = (signal, name, values) ->
      players = {}
      values.split(',').forEach (tokens) ->
        [position, accolades] = tokens.split '|'
        accolades = accolades?.split ';'
        accolades ?= []
        players[position] = accolades.map (accolade) ->
          [id, value, isPreferred] = accolade.split '^'
          return {
            id: parseInt id, 10
            value: value
            isPreferred: isPreferred is '1'
          }
      signal.updates.accolades = players

    object.adminWatching = (signal, name, values) ->
      signal.updates.adminWatching = values

    object.gameName = (signal, name, values) ->
      signal.updates.gameName = values

    object.smallBlind = (signal, name, values) ->
      signal.updates.smallBlind = parseFloat values

    object.minbet = (signal, name, values) ->
      signal.updates.minBet = parseFloat values

    object.maxbet = (signal, name, values) ->
      signal.updates.maxBet = parseFloat values

    object.minBuyIn = (signal, name, values) ->
      signal.updates.minBuyIn = parseFloat values

    object.maxBuyIn = (signal, name, values) ->
      signal.updates.maxBuyIn = parseFloat values

    object.minChipValue = (signal, name, values) ->
      signal.updates.minChipValue = parseFloat values

    object.gameLimitType = (signal, name, values) ->
      signal.updates.gameLimitType = values
      map =
        'N': 'NO_LIMIT'
        'F': 'FIXED_LIMIT'
        'P': 'POT_LIMIT'
      signal.updates.limitType = WclEnum.getLimitType map[values]
      signal.updates.limitTypeDesc = WclEnum.getLimitTypeDesc map[values]

    object.maxPlayers = (signal, name, values) ->
      signal.updates.maxPlayers = parseInt values, 10

    object.averagePot = (signal, name, values) ->
      signal.updates.averagePot = values

    object.stakesDescription = (signal, name, values) ->
      signal.updates.stakesDescription = values

    object.gameTypeId = (signal, name, values) ->
      signal.updates.gameTypeId = values

    object.dealItTwiceEnabled = (signal, name, values) ->
      signal.updates.dealItTwiceEnabled = values

    object.realMoney = (signal, name, values) ->
      signal.updates.realMoney = values

    object.speed = (signal, name, values) ->
      signal.updates.speed = values.toLowerCase()

    object.autoRebuy = (signal, name, values) ->
      signal.updates.autoRebuy = values

    object.languageId = (signal, name, values) ->
      signal.updates.languageId = values

    object.blindsOwing = (signal, name, values) ->
      signal.updates.blindsOwing = values

    object.vipPointsEarned = (signal, name, values) ->
      # deprecated, but still need this method or we get a console warning
      # signal.updates.vipPointsEarned = values

    object.initialBuyIn = (signal, name, values) ->
      signal.updates.initialBuyIn = parseFloat(values)

    object.chatAllowed = (signal, name, values) ->
      signal.updates.chatAllowed = values

    # # don't know what this does
    object.timerValue = (signal, name, values) ->
      # timerValue: { gameTimer: '5' }
      signal.updates.gameTimer = parseFloat values.gameTimer

    object.playerRank = (signal, name, values) ->
      signal.updates.playerRank = parseInt values, 10

    # value 1 = gameId, value 2 = tournamentGameId,
    # value 3 = numberOfHandsRemaining,
    # value 4 = numberOfHandsPlayedAtGameType/maxHandsToPlayAtGameType
    # when maxHandsToPlayAtGameType > 0
    object.gameId = (signal, name, values) ->
      if typeof values is 'object'
        signal.updates.gameId = {
          gameId: values.value1
          tournamentGameId: values.value2
          numberOfHandsRemaining: parseInt(values.value3,10)
          numberOfHandsPlayedAtGameType: parseInt(values.value4,10)
        }
      else # 7526857-8
        signal.updates.gameId = values


    # WARNING these need better parsing
    object.lastTable = (signal, name, values) ->
      signal.updates.lastTable = values

    object.tournamentId = (signal, name, values) ->
      signal.updates.tournamentId = parseInt(values,10)

    object.tournamentName = (signal, name, values) ->
      signal.updates.tournamentName = values

    object.tournamentGameNumber = (signal, name, values) ->
      signal.updates.tournamentGameNumber = values

    object.buyIn = (signal, name, values) ->
      signal.updates.buyIn = parseInt(values,10)

    object.handForHandMode = (signal, name, values) ->
      signal.updates.handForHandMode = values

    object.waitingForBreak = (signal, name, values) ->
      # {code1: "true", code2: "false"}
      signal.updates.waitingForBreak =
        waiting: values.code1  # redundant?
        addonAllowed: values.code2

    object.level = (signal, name, values) ->
      detailsParts = values.levelDetails.split('|')
      hasDetails = detailsParts.some((entry) => entry)

      if hasDetails
        [ante, bringin, smallblind, bigblind, minbet, maxbet] = detailsParts
          .map((value) => if value then parseFloat(value) else undefined)

      signal.updates.level =
        details: if hasDetails then {ante, bringin, smallblind, bigblind, minbet, maxbet} else null
        nextLevelDescription: values.nextLevelDesc
        nextLevelStartTime: parseInt values.nextLevelStartTime, 10
        currentLevelDescription: values.value1

    object.playerOutOfGame = (signal, name, values) ->
      signal.updates.playerOutOfGame ?= []
      signal.updates.playerOutOfGame.push parseInt(values.playerId, 10)

    object.registrationRequired = (signal, name, values) ->
      signal.updates.registrationRequired = values

    object.tournamentRematch = (signal, name, values) ->
      paymentOptions = @parseEntryPaymentOptions values.value2
      signal.updates.tournamentRematch =
        timer: values['true']  # seriously?
        paymentOptions: paymentOptions

    object.shootoutRoundNum = (signal, name, values) ->
      signal.updates.shootoutRoundNum = values

    object.eliminatedPlayer = (signal, name, values) ->
      signal.updates.eliminatedPlayer ?= []

      if values.value3
        [cash, coupon] = values.value3.split('^')
        winnings = {cash, coupon}

      signal.updates.eliminatedPlayer.push {
        name: values.name
        positionFinished: values.positionFinished + WclEnum.getPositionSuffix(values.positionFinished)
        winnings: winnings
      }

    object.nextBreak = (signal, name, values) ->
      signal.updates.nextBreak = parseInt values.breakStartTime, 10

    object.waitListPosition = (signal, name, values) ->
      signal.updates.waitlistPosition =
        length: parseInt values.waitListLength
        position: parseInt values.waitListPosition

    object.tournamentStats = (signal, name, values) ->
      signal.updates.tournamentStats =
        minimum: parseFloat values.value1
        average: parseFloat values.value2
        maximum: parseFloat values.value3
        sum:     parseFloat values.value4
        addons:  parseInt values.value5, 10
        rebuys:  parseInt values.value6, 10

    object.parseEntryPaymentOptions = (str) ->
      parseCoupon = @parseCoupon

      str?.split('#').map (row) ->
        row = row.split('^')
        [id, buyinCash, buyinCoupon, buyinPoints, entryFeeCash, entryFeeCoupon, entryFeePoints, bountyCash, bountyCoupon, bountyPoints] = row
        return {
          id: parseInt id, 10
          buyIn:
            cashComponent: parseFloat buyinCash or 0
            coupon: parseCoupon buyinCoupon
            pointsComponent: parseFloat buyinPoints or 0
          entryFee:
            cashComponent: parseFloat entryFeeCash or 0
            coupon: parseCoupon entryFeeCoupon
            pointsComponent: parseFloat entryFeePoints or 0
          bounty:
            cashComponent: parseFloat bountyCash or 0
            coupon: parseCoupon bountyCoupon
            pointsComponent: parseFloat bountyPoints or 0
        }


# PokerStatusData
#   boolean realMoney
#   String gameName
#   int maxPlayers
#   int numPlaying
#   int numWatching
#   String currentState
#   String currentRound
#   int currentPlayer
#   int currentPlayerTimeout
#   int[] currentPlayerList
#   SeatReservedData[] reservedSeats
#   int serverId
#   int seat
#   int gameTypeId
#   String limitType
#   BigDecimal minStakes
#   String gameId
#   PlayerBalanceData[] playerBalances
#   boolean blindsOwing
#   boolean chatAllowed
#   boolean maxPlayersReached
#   int startDelay
#   int dealer
#   int deadSmallBlind
#   BigDecimal currentBet
#   BigDecimal currentPotValue
#   BigDecimal totalOfAllPots
#   int currentPotNumber
#   BigDecimal mainPot
#   BigDecimal[] sidePots
#   BigDecimal rake
#   PlayerActionData actions
#   TimeExtensionData timeExtension
#   int timeBankBalance
#   boolean usingTimeBank
#   int timeBankAvailableSeconds
#   WinPercentageData[] allInWinPercentages
#   int[] dealtInPlayers
#   String[] cards
#   String handDescription
#   boolean folded
#   OpponentCardData[] opponentCards
#   boolean canDiscard
#   String[] communityCards
#   String communityCardsDesc
#   boolean communityCardsFolded
#   AllInShowdownPlayerData[] allInShowdownPlayers
#   boolean pendingDeposit
#   boolean sittingOut
#   BigDecimal buyIn
#   PlayerDetailsData[] playerDetails
#   BigDecimal ante
#   BigDecimal smallBlind
#   BigDecimal minBet
#   BigDecimal minBuyIn
#   BigDecimal minChipValue
#   String stakesDescription
#   boolean timeBankEnabled
#   BigDecimal maxBet
#   BigDecimal maxBuyIn
#   boolean passwordRequired
#   boolean previousBalanceSet
#   BigDecimal previousBalance
#   PotData[] winners
#   EliminatedPlayerData[] eliminatedPlayers
#   PokerEvent event
#   boolean pendingRebuy
#   BigDecimal initialBuyIn

import React from 'react'

import OwnTournamentsRow from './OwnTournamentsRow.js'
import Caption from '../../components/game-listing/table/Caption.js'
import Header from '../../components/game-listing/table/Header.js'
import HeaderGroup from '../../components/game-listing/table/HeaderGroup.js'
import Row from '../../components/game-listing/table/Row.js'
import RowGroup from '../../components/game-listing/table/RowGroup.js'
import Table from '../../components/game-listing/table/Table.js'
import styles from '../../components/game-listing/table/Table.module.css'

const EMPTY_ID = 'own-games-empty-tournaments'

export default function OwnTournamentsList (props) {
  const {tournaments} = props
  const hasEntries = tournaments.length > 0
  const captionId = !hasEntries ? EMPTY_ID : undefined

  return <span>
    <Table ariaLabelledby={captionId}>
      <HeaderGroup>
        <Row>
          <Header label='Starts' />
          <Header label='Name' className={styles.primary} />
          <Header label='ID' />
          <Header label='Buy-in' />
          <Header label='Status' />
          <Header label='Chips' />
          <Header label='Position' />
        </Row>
      </HeaderGroup>

      {hasEntries && <RowGroup>
        {tournaments.map(tournament => {
          return <OwnTournamentsRow key={tournament.tournamentId} tournament={tournament} />
        })}
      </RowGroup>}

      {!hasEntries && <Caption id={captionId}>No tournaments to show</Caption>}
    </Table>
  </span>
}

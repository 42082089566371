import React from 'react'

import {useRootedSelector} from '../../redux.js'
import Time from '../../time/components/Time.js'
import {getExclusions, getRoot, isLoading as isLoadingSelector, isLoadError as isLoadErrorSelector} from '../index.js'

export default function Exclusions () {
  const {USD} = useRootedSelector(getRoot, getExclusions)
  const isLoadError = useRootedSelector(getRoot, isLoadErrorSelector)
  const isLoading = useRootedSelector(getRoot, isLoadingSelector)

  if (isLoading) return <p>Loading...</p>
  if (isLoadError) return <p>Unable to load your current exclusions.</p>
  if (!USD) return <p>You have not opted to be excluded from any games.</p>

  return <p>
    You are currently excluded from all real money games until <Time value={USD} />.
  </p>
}

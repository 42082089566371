import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# Data Types
import PokerStatusData from './dataTypes/pokerStatusData.coffee'

# handlePendingDeposit(long gameInstanceId, PokerStatusData gameStatus)
# handlePendingDepositResponseFailure(long gameInstanceId, String reasonCode)

export default class PendingDepositTranslator extends Mixmaster
  mixins = [
    DefaultMixin
    PokerStatusData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

import React from 'react'

import LabelRow from './LabelRow.js'
import {useResetCasinoSettings} from '../react-hooks.js'

export default function ResetSettings () {
  return <LabelRow name='Reset Settings'>
    <button type='button' className='button' onClick={useResetCasinoSettings()}>
      <span className='fa fa-warning button-icon' /> Reset Settings
    </button>
  </LabelRow>
}

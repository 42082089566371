import {multiply} from '@pipehat/money/plain-object'
import React, {useCallback} from 'react'

import MoneyStepper from '../../../../money/components/MoneyStepper.js'
import styles from '../../AdvancedOptions.module.css'

import {
  useAutoRebuyAmount,
  useMaximumBuyIn,
  useMinimumBuyIn,
  useShowInvalidAmount,
  useStakes,
} from '../../react-hooks.js'

export default function Amount (props) {
  const {onChange} = props
  const showInvalidAmount = useShowInvalidAmount()
  const autoRebuyAmount = useAutoRebuyAmount()
  const maximumBuyIn = useMaximumBuyIn()
  const minimumBuyIn = useMinimumBuyIn()
  const stakes = useStakes()
  const step = stakes ? multiply(20, stakes.smallBlind) : undefined // 10 * big blind

  const handleChange = useCallback(amount => {
    if (onChange && amount !== autoRebuyAmount) onChange(amount)
  }, [autoRebuyAmount, onChange])

  const handleInvalid = useCallback(() => {
    if (showInvalidAmount) showInvalidAmount(minimumBuyIn, maximumBuyIn)
  }, [maximumBuyIn, minimumBuyIn, showInvalidAmount])

  // prevent error if leaving table with modal open (should be able to remove when legacy code is gone)
  if (!autoRebuyAmount || !maximumBuyIn || !minimumBuyIn || !step) return null

  return <MoneyStepper
    className={styles.autoRebuyAmount}
    min={minimumBuyIn}
    max={maximumBuyIn}
    step={step}
    value={autoRebuyAmount}
    onChange={handleChange}
    onInvalid={handleInvalid}
  />
}

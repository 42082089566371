# PreviewBarView
# Collection: ClientList

import PreviewView from './previewView.coffee'
import View from './view.coffee'

export default class PreviewBarView extends View

  constructor: (clientList) ->
    super()

    @collection = clientList

    @dom = $ '<div class="preview-bar"></div>'


    # ### Components - eg. DOM elements & views
    @components =
      backToLobby: $ "<div class='preview-bar-tab lobby'><i class='fa fa-home'></i><div class='label'>Lobby</div></div>"
      leftIndicator: $ "<div class='preview-bar-tab indicator previous'><i class='fa fa-angle-up'></i></div>"
      carousel:    $ "<div class='preview-bar-carousel'>"
      rightIndicator: $ "<div class='preview-bar-tab indicator next'><i class='fa fa-angle-down'></i></div>"

    @components.carousel[0].addEventListener 'scroll', @scrollCarousel
    window.addEventListener 'resize', @scrollCarousel

    # ### Events - eg. MouseClicks ###
    @components.leftIndicator.on 'click', @scrollPrevious
    @components.rightIndicator.on 'click', @scrollNext
    @components.backToLobby.on 'click', @navLobby

    # ### Listeners - eg. auth:success
    @listeners =
      'add': @addClient

    # ### Changes - eg. Model.property = true

    # ### Binding ###
    @initializeView()
    return this

  scrollCarousel: () =>
    carousel = @components.carousel[0]
    if carousel.scrollTop or carousel.scrollLeft
      @components.leftIndicator[0].classList.add 'scrolled'
    else
      @components.leftIndicator[0].classList.remove 'scrolled'

    scrolledBottom = carousel.scrollHeight - carousel.scrollTop is carousel.offsetHeight
    scrolledRight = carousel.scrollWidth - carousel.scrollLeft is carousel.offsetWidth
    if scrolledBottom and scrolledRight
      @components.rightIndicator[0].classList.remove 'scrolled'
    else
      @components.rightIndicator[0].classList.add 'scrolled'

  # If you return to the lobby before sitting down that client is removed.
  removeUnseated: (client) =>
    if !client.seated
      client.leaveGame {force: true}
      @collection.rem(client)

  addClient: (client) =>
    previewView = new PreviewView client
    @components.carousel.append previewView.render()
    @scrollCarousel()

  navLobby: =>
    client = @collection.find {current:true}
    if client?.game.tournamentId
      Navigator.openTournamentLink client.game.tournamentId
    else
      Navigator.navLobby()
    @collection.each @removeUnseated

  render: =>
    @components.backToLobby.appendTo @dom
    @components.leftIndicator.appendTo @dom
    @components.carousel.appendTo @dom
    @components.rightIndicator.appendTo @dom

    return @dom

  scrollPrevious: (e) =>
    e.preventDefault()
    previewTable = @components.carousel.find('.preview-table:eq(0)')
    if previewTable.length
      {offsetHeight, offsetWidth} = previewTable[0]
      @components.carousel[0].scrollBy?(-offsetWidth, -offsetHeight)

  scrollNext: (e) =>
    e.preventDefault()
    previewTable = @components.carousel.find('.preview-table:eq(0)')
    if previewTable.length
      {offsetHeight, offsetWidth} = previewTable[0]
      @components.carousel[0].scrollBy?(offsetWidth, offsetHeight)

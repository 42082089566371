export default
  # This code sometimes does not work on IOS simulator
  select: (e) ->
    e.preventDefault()
    this.select()
    if window.iOS
      this.setSelectionRange(0, 9999)

  restrictInput: (e) ->
    unless String.fromCharCode(e.keyCode or e.which).match(/[0-9\.]/)
      return false

  validate: (input) =>
    min = parseFloat input.attr('min')
    max = parseFloat input.attr('max')
    val = parseFloat input.val()
    return min <= val <= max

  snapToStep: (e) ->
    if $(this).attr('step') is '1'
      v = $(this).val()
      $(this).val Math.round v

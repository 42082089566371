# PreferredSeatsView
# Model: settings

import range from 'lodash/range'

import Felt from '../../../../rooms/felt/Felt.js'
import View from '../../view.coffee'

export default class PreferredSeatsView extends View

  constructor: (settings, @createReactView) ->
    super()

    @model = settings
    @numOfSeats = null
    @currentPref = null

    @dom = $ "<div class='preferred-seating-container'>"
    @components =
      background: @createReactView Felt, {}, {class: 'preferred-seating-table-felt'}, 'div'
      seats: $ '<div class="miniSeats">'

    @listeners =
      'seatSelected': @seatSelected

    @initializeView()
    return this

  seatConfiguration: (numOfSeats) =>
    @numOfSeats = numOfSeats
    @currentPref = @model.preferredSeating[numOfSeats]
    @render()

  render: =>
    # this view is reused for different seat configurations
    @emit 'removeSeats'
    @dom.empty()
    @components.seats.empty()
    @dom.text "Select a seating position for #{@numOfSeats} seat tables"
    @setPlayers(@numOfSeats)
    @populateSeats()
    @components.background.render()
    @dom.append @components.background.container()
    @dom.append @components.seats
    return @dom

  reset: =>
    @numOfSeats = null
    @currentPref = null
    # we want to destroy the seats listening on @model
    @emit 'removeSeats'

  addSeat: (position) =>
    view = $ '<span>'
    view.toggleClass 'currentPref', position is @currentPref
    view.on 'click', =>
      @seatSelected(position)
    @components.seats.append view

  setPlayers: (number) =>
    @components.seats.removeClass()
    @components.seats.addClass 'miniSeats'
    @components.seats.addClass 'seats_' + number

  populateSeats: (emptySeats) =>
    emptySeats ?= range(@numOfSeats)
    for position in emptySeats
      @addSeat(position)

  seatSelected: (position) =>
    preferredSeating = Object.assign({}, @model.preferredSeating)
    if preferredSeating[@numOfSeats] is position
      preferredSeating[@numOfSeats] = null
    else
      preferredSeating[@numOfSeats] = position
    @currentPref = preferredSeating[@numOfSeats]
    @model.preferredSeating = preferredSeating
    @render()

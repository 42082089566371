# ClientData
#  int playersOnline
import Mixmaster from '../../../mixmaster.coffee'

export default class ClientData extends Mixmaster
  mixin: (object) ->
    object.client = (signal, client, node) ->
      details =
        playersOnline: parseInt(client.playersOnline, 10)
        jackpots: {
          'CARIBBEAN $1': parseFloat client.caribbeanOneDollarJackpot if client.caribbeanOneDollarJackpot
          'CARIBBEAN $5': parseFloat client.caribbeanFiveDollarJackpot if client.caribbeanFiveDollarJackpot
          'BADBEAT J': parseFloat client.badBeatJackpot if client.badBeatJackpot
        }
        cashRebatePercentage: parseFloat client.cashRebatePercentage if client.cashRebatePercentage

      signal.updates.clientDataFeed = details

import {
  DEFERRED_TOP_UP_COMPLETED,
  DEFERRED_TOP_UP_AMOUNT_TOO_HIGH_DETECTED,
  OWN_SEAT_LOADED,
  REBOUGHT,
  REBUY_DECLINED,
  REBUY_DEFERRED,
  TOP_UP_DEFERRED,
  TOURNAMENT_ADD_ON_AVAILABILITY_LOADED,
  TOURNAMENT_REBUY_AVAILABILITY_LOADED,
  TOURNAMENT_TABLE_LOADED,
} from '@pipehat/chronicle/constants/event-type'

import {createSlice} from '@reduxjs/toolkit'

import {TOP_UP_FAILED} from '../constants.js'

const initialState = {
  isTopUpPending: false,
  ownSeat: 0,
  tournament: undefined,
}

const initTournament = {
  areAddOnsAvailable: false,
  rebuys: {
    areAvailable: false,
    isPending: false,
    remaining: 0,
  },
}

const addChipsSlice = createSlice({
  name: 'addChips',
  initialState,
  extraReducers: {
    [TOP_UP_DEFERRED]: state => {
      state.isTopUpPending = true
    },

    [DEFERRED_TOP_UP_COMPLETED]: state => {
      state.isTopUpPending = false
    },

    [TOP_UP_FAILED]: state => {
      state.isTopUpPending = false
    },

    [DEFERRED_TOP_UP_AMOUNT_TOO_HIGH_DETECTED]: state => {
      state.isTopUpPending = false
    },

    [TOURNAMENT_ADD_ON_AVAILABILITY_LOADED]: (state, {payload}) => {
      state.tournament.areAddOnsAvailable = payload.areAvailable
    },

    [TOURNAMENT_REBUY_AVAILABILITY_LOADED]: (state, {payload}) => {
      const {areAvailable, remainingCount} = payload

      state.tournament.rebuys.areAvailable = areAvailable
      state.tournament.rebuys.remaining = remainingCount
    },

    [TOURNAMENT_TABLE_LOADED]: state => {
      if (!state.tournament) state.tournament = {...initTournament}
    },

    [REBUY_DEFERRED]: state => {
      state.tournament.rebuys.isPending = true
    },

    [REBUY_DECLINED]: disablePendingRebuy,

    [REBOUGHT]: disablePendingRebuy,

    [OWN_SEAT_LOADED]: (state, {payload}) => {
      state.ownSeat = payload.seatNumber
    },
  },
})

export default addChipsSlice.reducer

function disablePendingRebuy (state, {payload}) {
  const {seatNumber} = payload

  if (state.ownSeat === seatNumber) state.tournament.rebuys.isPending = false

  return state
}

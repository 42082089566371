import {setUser} from '@sentry/browser/esm/index.js'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import difference from 'lodash/difference'
import intersection from 'lodash/intersection'
import {each} from 'async-es'
import {messageSW} from 'workbox-window'

import Enum from '../enum/index.coffee'
import Listing from './listing.coffee'
import ListTypeEnum from '../enum/enums/listTypeEnum.coffee'
import Model from './model.coffee'
import {GAME_FORMAT} from '../../game-list/constants.js'
import {THREE_SECONDS} from '../../time/time-amount.js'

{RING_GAME, SCHEDULED_TOURNAMENT, SITNGO_TOURNAMENT} = GAME_FORMAT

export default class App extends Model
  constructor: (
    @communicator,
    @format,
    @gameFilter,
    @globalSettings,
    @listings,
    @log,
    @name,
    @navigator
    @obUrl
    @receiver,
    @settings,
    @socket,
    @sounds
    @user,
    clientList,
    popupMaster,
    @responsibleGamingService,
    @autoDetectedFormat,
    @apiClient
  ) ->
    super()

    @popupMaster = window.popupMaster = popupMaster if @format is 'desktop'
    @clients = clientList
    @properties = lobbySidebarUrl: ''
    @instances = [] # list of tables not to rejoin because the player will have left them.
    @myInProgress = [] # list of sng ids that should be shown even though they're in progress
    @ownTournamentListings = {} # stores customer's own tournaments for reloading details, contenders, and results

    @navigator.on 'nav:table', @navTable #ensure the game we are navigating to is open
    @navigator.on 'nav:gameList', @navGameList
    @navigator.on 'nav:responsibleGaming', @navResponsibleGaming

    if DEBUG
      window.devApp = this
      window.devfilter = @gameFilter

    @user.on 'auth', @loginUser
    @user.on 'reportProblem', @reportProblem
    @user.on 'problemSigningIn', @problemSigningIn

    @user.on 'switchFormat', => @emit 'switchFormat', arguments...
    @settings.on 'switchFormat', => @emit 'switchFormat', arguments...

    @settings.on 'showPreferredSeating', (seats) => @emit 'showPreferredSeating', seats

    @gameFilter.on 'change:listTypes', @requestGameLists

    @listings.on 'action', @listingAction
    @listings.on 'requestGameLists', @requestGameLists
    @listings.on 'allRequestedGamesListsReturned', debounce @getMyGames, 500
    @listings.on 'getMyGames', @getMyGames

    @clients.on 'clientAction', @clientAction
    @clients.on 'reportProblem', @reportProblem
    @clients.on 'addToInstances', (instanceId) =>
      @instances.push instanceId

    @clients.on 'updateRealMoneyBalance', debounce(
      @getRealMoneyBalance, THREE_SECONDS, {leading: true, maxWait: THREE_SECONDS}
    )

    @clients.on 'visitCashier', @visitCashier

    @settings.on 'getPlayMoney', @getPlayMoney
    @settings.on 'getToken', @getToken
    @settings.on 'visitCashier', @visitCashier
    @settings.on 'resetFilter', @resetFilter

    # If time before changes requeue notifications
    # We only want to requeue them once the user has finsihed changing the setting.
    @settings.on 'change:notificationBeforeGameStart', => debounce @tournamentPreStartNotifications, 500

    setInterval @tick, 1000

    @communicator.on 'signal', @receiver.receive

    @initializeModel arguments...

  start: =>
    @navigator.start()

    @socket.on 'connect', =>
      clearTimeout @socketTimeout
      @connected()
    @socket.on 'disconnect', @communicator.disconnect
    @user.emit 'connecting'
    @socketTimeout = setTimeout =>
      @emit 'connectionTimeout'
    , 15 * 1000
    @socket.connect()

  connected: =>
    # console.log 'connected'
    # WEB-1280. previously we had @user.auth() here, however since we delete @user.password on authSuccess
    #  (to mitigate password stealing in event of XSS) it attempts to reauth without password, resulting in
    #  users sending multiple failed login attempts, and getting their account deactivated
    unless @user.authenticated
      @user.connected()

    @ping()

  # ### INCOMING ###
  authSuccess: (signal) =>
    # set the details of what servers, routers, etc.
    # that the messages are meant for.
    messageDetails =
      classId: signal.login.classId
      clientId: signal.message.clientId
      nickname: signal.login.nickname
      routerId: signal.message.routerId
      securityToken: signal.login.securityToken
      sessionId: signal.message.sessionId
      tagList: signal.login.tagList
      userId: signal.login.userId

    @communicator.setMessageDetails messageDetails

    # hopefully write hashes over user's pw before deleting the reference.
    # have to call deleted directly against property
    if typeof @user.password is 'string'
      @user.password = new Array(parseInt(@user.password.length + (Math.random() * 10))).join('#')
      delete @user.properties.password

    @user.activeTablesLimit = signal.login.userOptions?.activeTablesLimit or 18
    @user.nickname = signal.login.nickname
    @user.setTags signal.login.tagList

    @user.authenticated = true
    @emit 'auth:success'
    @listings.emit 'auth:success'

    setUser({id: messageDetails.userId, username: messageDetails.nickname})

    @getMyGames()

    @getAdbarToken()

    @getRealMoneyBalance()
    @message 'getClientDataFeed'

  joinGames: (games) =>
    # We break once we find a game that has an instance id and join it

    # Remove extra instances from the instances list
    int = games.map ({instanceId}) -> instanceId
    @instances = intersection @instances, int

    for gameAttributes in games
      # note registering tourneys appear in MyGames but no instanceId is supplied
      # so we can not join

      unless gameAttributes.instanceId in @instances
        if gameAttributes.serverId and gameAttributes.instanceId and gameAttributes.playerStatus isnt 'Waiting'
          @joinGame
            serverId: gameAttributes.serverId
            instanceId: gameAttributes.instanceId
          @instances.push gameAttributes.instanceId
          # break #only join first game we find

  processMyGames: (games) =>
    @myGames = games
    games.forEach (gameAttributes) =>
      if gameAttributes.instanceId
        if !gameAttributes.tournamentInstanceId
          # for ring games, create a listing if it doesn't already exist (playing)
          unless listing = @listings.find(instanceId: gameAttributes.instanceId)
            details = gameAttributes
            details.instanceId = details.instanceId
            details.isTournament = false
            listing = new Listing {details}
            listing.serverId = details.serverId
            listing.gameTypeId = details.gameTypeId
            listing.gameTypeDesc = Enum.getGameTypeDesc(parseInt(details.gameTypeId, 10))
            @listings.add listing

        if gameAttributes.playerStatus is 'Waiting'
          listing?.waiting = true
        else
          listing?.playing = true
          listing?.pinned = true

      if gameAttributes.tournamentInstanceId
        unless gameAttributes.tournamentInstanceId in @myInProgress
          @myInProgress.push gameAttributes.tournamentInstanceId

        unless listing = @listings.find(instanceId: gameAttributes.tournamentInstanceId)
          # Make a new listing if we haven't already got one
          # e.g. you log in to ringGames list, but you're registered for an SNG and an MTT
          # These need to be automatically pinned to the top of the gamelist
          details = gameAttributes
          details.instanceId = details.tournamentInstanceId
          details.isTournament = true
          listing = new Listing {details}
          listing.serverId = details.serverId
          listing.registered = true
          listing.gameTypeId = details.gameTypeId
          listing.gameTypeDesc = Enum.getGameTypeDesc(parseInt(details.gameTypeId, 10))
          listing.isMtt = !details.isSitNGo
          listing.isSng = details.isSitNGo
          @listings.add listing

        @ownTournamentListings[gameAttributes.tournamentInstanceId] = listing

        # minor hack to ensure myGames are automatically pinned ONCE only on session start (or on registering)
        # they can be manually unpinned by the user, but since pins aren't persisting, they'll just reset next session
        @alreadyAutoPinned ?= []
        unless gameAttributes.tournamentInstanceId in @alreadyAutoPinned
          listing.pinned = true
          @alreadyAutoPinned.push gameAttributes.tournamentInstanceId

        if gameAttributes.playerStatus is 'Registering'
          listing.registered = true
        else
          listing.playing = true

    for tournamentId, ownTournamentListing of @ownTournamentListings
      # always reload details and players for the sake of the "My Tournaments" screen
      ownTournamentListing.getDetails()
      ownTournamentListing.getPlayers()

    @emit 'myGamesProcessed'

  authFail: =>
    @emit 'auth:fail', arguments...
    @user.emit 'auth:fail', arguments...

  tournamentPreStartNotifications: =>
    if @settings.notifications
      for listing in @listings.where {registered:true}
        listing.qGameStartNotification(@settings.notificationBeforeGameStart)

  clientAction: (command, args = {}) =>
    args.instanceId = args.client.instanceId
    args.serverId = args.client.serverId

    switch command
      when 'findSimilarForWaitlists'
        listing = @listings.find instanceId: args.client.instanceId
        criteria =
          minBet: listing.minBet # CHECK THIS
          limitType: listing.limitType
          gameTypeId: listing.gameTypeId
          maxPlayers: listing.maxPlayers
          waiting: false
          playing: false
        listings = @listings.where criteria
        for l in listings
          l.addToWaitlist()
      when 'leaveWaitlists'
        listing = @listings.find instanceId: args.client.instanceId
        criteria =
          minBet: listing.minBet # CHECK THIS
          limitType: listing.limitType
          gameTypeId: listing.gameTypeId
          maxPlayers: listing.maxPlayers
        if args.matchSpeed
          criteria.speed = listing.speed
        @leaveWaitlists(criteria)
      when 'leaveAllWaitlists'
        @leaveWaitlists()
      when 'joinGame'
        @joinGame args.client
      when 'reserve'
        if @getActiveTablesCount() < @user.activeTablesLimit
          @message command, args
        else
          @activeTableLimitWarning()
      else
        @message command, args

  spectate: (game, client, inBackground) =>
    @instances.push client.instanceId
    @emit 'spectateGame', game, client, inBackground

  tournamentDetails: (tournament, details) =>
    @emit 'tournamentDetails', tournament, details

  tournamentRegistration: =>
    @emit 'tournamentRegistration', arguments...

  tournamentDeregistration: =>
    @emit 'tournamentDeregistration', arguments...

  getRealMoneyBalance: =>
    @message 'getBalance',
      realMoney: true
      cb: (err) =>
        if err
          @getRealMoneyBalance()
      cbTime: 2000

  getPlayMoney: =>
    @message 'getPlayMoney'

  tournamentStart: (tournamentData) =>
    @emit 'tournamentStart', tournamentData

  tournamentPosition: (positionFinished, winnings, tournamentId) =>
    @myInProgress = @myInProgress.filter (entry) -> entry isnt tournamentId
    # WEB-1324: if SNG, offer to re-register
    sngListTypes = [17, 18, 28, 29]
    @listings.once 'allRequestedGamesListsReturned', =>
      tournament = @listings.find {instanceId: tournamentId}
      if tournament and parseInt(tournament.listType) in sngListTypes and tournament.maxPlayers isnt 2 # double check not HU
        {entry, maxPlayersPerTable, maxPlayers, gameName} = tournament
        reRegisterListing = @listings.find {entry, maxPlayersPerTable, maxPlayers, gameName, gameState: 'Registering'}
        if reRegisterListing?.instanceId
          # need entry payments
          reRegisterListing.getDetails =>
            @emit 'tournamentPosition', positionFinished, winnings, reRegisterListing
        else
          @emit 'tournamentPosition', positionFinished, winnings
      else
        @emit 'tournamentPosition', positionFinished, winnings
    @requestGameLists sngListTypes

  tournamentShutdown: =>
    @emit 'tournamentShutdown', arguments...

  showLevelStructure: =>
    @emit 'showLevelStructure', arguments...

  notice: =>
    @emit 'notice', arguments...

  pushNotification: ({title, message}) =>
    if @settings.notifications and @settings.notificationPush
      @emit 'notification', { title, message }
    # else if @settings.notificationPush
    #   app.notice { title, content: message }

  logout: =>
    @emit 'logout'

  restart: =>
    @emit 'restart'

  restartRequired: =>
    @emit 'restartRequired'

  invalidSession: =>
    @socket.end()
    @emit 'invalidSession'

  invalidVersion: =>
    @emit 'invalidVersion'

  maintenanceCheck: =>
    checkForMaintenance = =>
      @apiClient.maintenance()
        .then ({isActive, endTime}) =>
          return unless isActive

          @emit 'maintenance', new Date endTime
          @navigator.navMaintenance()
        .catch (error) =>
          @log 'api', 'Unable to check maintenance status:', error
          setTimeout(checkForMaintenance, 10 * 60 * 1000) # retry in 10 minutes

    checkForMaintenance()

  # Latency check
  ping: =>
    @message 'heartbeat'
    @startPingTime = Date.now()

  pong: (serverTime) =>
    latency = Date.now() - @startPingTime
    @clients.each (client) -> client.game.latency = latency
    setTimeout @ping, @pingFrequency or 2000
    Webclient.Time ?= serverTime
    # @emit 'time', Webclient.Time

  # Update nickname prompt and command
  updateNicknamePrompt: (invalid) =>
    @emit 'updateNicknamePrompt', invalid

  navTable: (table) =>
    @joinGame table

  navArcade: =>
    client = @clients.find {current:true}
    client ?= @clients.get(0)
    if client?
      @navigator.navTable client.game

  # TODO WEB-1570 wcl-enum
  # use filterEnum for all this stuff

  navGameList: (slug1, slug2) =>
    defaultGameList = @settings.defaultGameList
    defaultGameList[slug1] = slug2
    @settings.defaultGameList = defaultGameList

    if slug1 is 'ringGames'
      @gameFilter.gameListGameFormat = RING_GAME
      switch slug2
        when 'nlHoldem'
          @gameFilter.limitTypes = ['noLimit']
          @gameFilter.listTypes = [ListTypeEnum.TEXAS_HOLDEM_NO_LIMIT.id]
        when 'flHoldem'
          @gameFilter.limitTypes = ['fixedLimit']
          @gameFilter.listTypes = [ListTypeEnum.TEXAS_HOLDEM_FIXED_LIMIT.id]
        when 'plOmaha'
          @gameFilter.limitTypes = ['potLimit']
          @gameFilter.listTypes = [ListTypeEnum.OMAHA.id]
        when 'nlOmahaHiLo'
          @gameFilter.limitTypes = ['noLimit']
          @gameFilter.listTypes = [ListTypeEnum.OMAHA_HILO.id]
        when 'flOmahaHiLo'
          @gameFilter.limitTypes = ['fixedLimit']
          @gameFilter.listTypes = [ListTypeEnum.OMAHA_HILO.id]
        when 'plOmahaHiLo'
          @gameFilter.limitTypes = ['potLimit']
          @gameFilter.listTypes = [ListTypeEnum.OMAHA_HILO.id]

    else if slug1 is 'sitngo'
      @gameFilter.gameListGameFormat = SITNGO_TOURNAMENT
      @gameFilter.listTypes = [
        ListTypeEnum.TOURNAMENT_SITNGO_REGULAR.id
        ListTypeEnum.TOURNAMENT_SITNGO_HEADSUP.id
        ListTypeEnum.PROMOTIONAL_TOURNAMENT.id
        # ListTypeEnum.TOURNAMENT_SITNGO_FOUR_MAX.id
        ListTypeEnum.TOURNAMENT_SITNGO_SIX_MAX.id
        ListTypeEnum.TOURNAMENT_SITNGO_FULL_RING.id
      ]
      switch slug2
        when 'hyper'
          @gameFilter.speeds = ['hyperTurbo']
        when 'turbo'
          @gameFilter.speeds = ['turbo']
        when 'all'
          @gameFilter.speeds = ['regular', 'fast', 'turbo', 'hyperTurbo'] #, 'supersonic'

    else if slug1 is 'tournaments'
      @gameFilter.gameListGameFormat = SCHEDULED_TOURNAMENT
      @gameFilter.listTypes = [
        ListTypeEnum.TOURNAMENT_SCHEDULED_REGULAR.id
        ListTypeEnum.TOURNAMENT_SCHEDULED_SATELLITE.id
        ListTypeEnum.TOURNAMENT_SCHEDULED_SPECIAL.id
        ListTypeEnum.PROMOTIONAL_TOURNAMENT.id
      ]
      switch slug2
        when 'holdem'
          @gameFilter.gameTypeIds = [23]
        when 'omaha'
          @gameFilter.gameTypeIds = [25]
        when 'satellites'
          @gameFilter.gameTypeIds = [23, 25, 35]
          @gameFilter.listTypes = [
            ListTypeEnum.TOURNAMENT_SCHEDULED_SATELLITE.id
          ]

  navResponsibleGaming: =>
    if @user.authenticated
      @responsibleGamingService.loadExclusions()
    else
      @once 'auth:success', =>
        @responsibleGamingService.loadExclusions()
        return

  leaveClients: =>
    @clients.leave()

  leaveGame: (game, client) =>
    if client?
      @clients.rem client
    # @tables.rem game

  playSound: (name) =>
    @sounds.play name

  # ###
  # OUTGOING
  # ###
  loginUser: (credentials) =>
    @globalSettings.validateUuid()
    @message 'loginUser', credentials:
      username: credentials.username
      password: credentials.password
      uuid: @globalSettings.uuid
      fingerprint: credentials.fingerprint

  getAdbarToken: =>
    @apiClient.sidebar()
      .then (hasSidebar) => @getToken {keyId: 'lobby.sidebar'} if hasSidebar
      .catch () -> # ignore failure

  listingAction: (actionName, args = {}) =>
    args.instanceId ?= args.listing?.instanceId
    args.serverId ?= args.listing?.serverId

    switch actionName
      when 'joinGame'
        @joinGame args
      when 'showLobby'
        @navigator.navTournament args.listing
      when 'showNotification'
        listing = args.listing
        @emit 'notification', { title: 'Tournament ' + listing.gameName, message: 'Is starting ' + dayjs(listing.startTime).fromNow() }
      when 'visitCashier'
        @visitCashier args # untested (method expects a string)
      when 'register'
        if @getActiveTablesCount() < @user.activeTablesLimit
          @message actionName, args
        else
          @activeTableLimitWarning()
      when 'registered'
        listing = args.listing
        if @settings.notifications and @settings.notificationBeforeGameStart > 0
          listing.qGameStartNotification(@settings.notificationBeforeGameStart)
      else
        @message actionName, args

  getToken: ({keyId}) =>
    @message 'getToken', {keyId}

  getMyGames: =>
    @message 'getMyGames'

  joinGame: ({serverId, instanceId, autoSeat}, force, inBackground) =>
    client = @clients.find {instanceId}
    if not client or force
      @message 'joinGame', {serverId, instanceId, autoSeat, inBackground}
    else if client and not inBackground
      client.current = true
      client.emit 'action', 'joinGame'

  updateNickname: (nickname) =>
    @message 'updateNickname', {nickname}

  requestGameLists: (listTypes, isRetry) =>
    complete = []
    unless @user.authenticated
      # console.warn 'user not authenticated. aborting requestGameLists'
      return
    unless listTypes?.length
      # console.warn 'listTypes array empty. aborting requestGameLists'
      @listings.emit 'gameListReturned'
      @listings.emit "allRequestedGamesListsReturned"
      return
    each listTypes, (id, callback) =>
      listType = Object.values(ListTypeEnum).find (e) -> e.id is id
      if listType
        # console.log "requesting list type", listType.description
        if /Texas|Omaha/.test(listType.description)
          command = 'getRingGames'
        else
          command = 'getTournaments'
        @message command,
          realMoney: @gameFilter.realMoney
          listType: listType.description
          tagList: @user.tagList.playerTagsString
          cb: (err) =>
            if err
              callback err
            else
              complete.push id
              callback null
    , (err) =>

      # Hides loading bars after a single game list comes back
      if complete.length
        @listings.emit 'gameListReturned'

      if err and not isRetry
        @requestGameLists difference(listTypes, complete), true
        # console.error "Retry unretrieved listTypes", difference(listTypes, complete)
      else
        @listings.emit "allRequestedGamesListsReturned"

    # @startPollingGames()

  startPollingGames: =>
    @stopPollingGames() # push the timeout back by 15s
    @gamePolling = setInterval =>
      @requestGameLists @gameFilter.listTypes
    , 15000

  stopPollingGames: =>
    clearInterval @gamePolling
    @gamePolling = false

  resetFilter: =>
    @gameFilter.resetAll()

  bountyMessage: =>
    @emit 'bountyMessage', arguments...

  visitCashier: (location) =>
    # only two valid cashier locations: deposit & overview (defalut)
    if location is 'deposit'
      location = 'deposit'
    else
      location = 'overview'
    keyId = if @format is 'desktop' then 'cashier.'+location else 'cashier.mobile.'+location
    @getToken {keyId}

  leaveWaitlists: (args = {}) =>
    criteria = Object.assign args, {waiting:true}
    @listings.where(criteria).forEach (listing) ->
      listing.removeFromWaitlist()

  reportProblem: =>
    if @user.authenticated
      @getToken {keyId: 'playerServices.support'}
    else
      url = @obUrl
      url = url.replace '%TOKEN%', 'unauthenticated'
      url = url.replace '%URL%', 'playerServices.support'
      window.open url

  problemSigningIn: =>
    url = @user.loginSupportLink
    url = url.replace '%TOKEN%', 'unauthenticated'
    url = url.replace '%URL%', 'playerServices.support'
    window.open url

  gameInProgress: =>
    client = @clients.find({current: true})
    if client and client.seated and client.game.gameInProgress
      return true
    else
      return false

  tick: =>
    # don't update if we haven't received the time from server yet
    if Webclient.Time
      Webclient.Time.setTime Webclient.Time.getTime() + 1000

  promptClaimCoupon: =>
    @emit 'promptClaimCoupon', arguments...

  getActiveBonuses: =>
    @emit 'getActiveBonuses'
    @message 'activeBonuses'

  getActiveTablesCount: =>
    activeClients = Object.keys(@clients.members).filter ({seated}) -> seated # c.game.playerCount > 1
    registeredListings = @listings.where {registered: true, playing: false}
    current = activeClients.length + registeredListings.length
    return current

  activeTableLimitWarning: =>
    @emit 'activeTableLimitWarning', arguments...

  visitExternalSportsBet: =>
    keyId = 'lobby.externalSportsBet'
    @getToken {keyId}

  visitExternalCasinoGames: =>
    keyId = 'lobby.externalCasinoGames'
    @getToken {keyId}

  message: (...args) => @communicator.message ...args

  showSatellites: (listing) =>
    @emit 'showSatellites', listing

  showSatelliteFollowOnTournaments: (listing) =>
    @emit 'showSatelliteFollowOnTournaments', listing

  showSwitchTableNotice: =>
    @emit 'showSwitchTableNotice', arguments...

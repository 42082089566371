import classnames from 'classnames'
import React from 'react'

import styles from './QuickSeat.module.css'
import skeleton from '../../skeleton.module.css'

export default function ButtonGroup (props) {
  const {children, isSkeleton, label, number} = props

  const labelClassName = classnames(styles.header, styles.label)

  return <div className={classnames(styles.buttonGroup, {[skeleton.skeletonize]: isSkeleton})} >
    <label className={labelClassName}>
      <span>{number}.</span> {label}
    </label>
    {children}
  </div>
}

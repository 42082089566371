# CardView
# Model: card

import View from '../view.coffee'

export default class CardView extends View

  constructor: (card) ->
    super()

    @model = card

    @dom = $ "<div class='card'>"

    # ### Components - eg. DOM elements & views
    @components =
      back:  $ "<div class='back'>"
      front: $ "<div class='front'>"

    # ### Events - eg. MouseClicks ###
    @dom.on 'click', @click

    # ### Listeners - eg. auth:success

    # ### Changes - eg. Model.property = true
    @changes =
      suit: @render
      rank: @render
      facedown: @flip

    # ### Binding ###
    @initializeView()
    return this

  render: =>
    @appendComponents()
    @dom.toggleClass 'facedown', @model.facedown
    @components.front.removeClass()
    @components.front.addClass 'front'
    @components.front.addClass @model.suit.code + @model.rank.code
    return @dom

  click: =>
    if @model.flippable and not @lockFlip
      @model.facedown = not @model.facedown
      @model.show = not @model.show
    else
      @emit 'cardNotFlibbable' # for unit tests

  flip: (facedown) =>
    unless @lockFlip
      @dom.toggleClass 'facedown', facedown

import Mixmaster from '../../../mixmaster.coffee'
import DefaultMixin from './defaultMixin.coffee'
import HighCardMixin from './highCardMixin.coffee'

# Data Types
import PokerStatusData from '../dataTypes/pokerStatusData.coffee'
import SeatReservedData from '../dataTypes/seatReservedData.coffee'
import TimebankData from '../dataTypes/timebankData.coffee'
import PlayerDetailsData from '../dataTypes/playerDetailsData.coffee'
import BreakData from '../dataTypes/breakData.coffee'

# handleJoinGameResponse(long gameInstanceId, PokerStatusData gameStatus)

export default class JoinGameMixin extends Mixmaster
  mixins = [
    DefaultMixin
    PokerStatusData
    SeatReservedData
    TimebankData
    PlayerDetailsData
    BreakData
    HighCardMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  mixin: (object) ->
    mixins.map((mixin) => (new mixin).mixin(object))

    object.reason = (signal, name, values) ->
      signal.failReason = values

    object.customBg = (signal, name, values) ->
      signal.updates.customBg = values

import React from 'react'

import Value from './Value.js'
import RenderMoney from '../../../money/components/Money.js'

export default function Money ({value, fallback = null}) {
  return <Value>
    {value ? <RenderMoney value={value} /> : fallback}
  </Value>
}

import {
  SCHEDULED_TOURNAMENT_FILTER,
  SCHEDULED_TOURNAMENT_FILTER_CRITERIA,
  SCHEDULED_TOURNAMENT_FILTER_MAP,
} from './constants.js'

import {ASC, DESC} from '../constants.js'

import {
  buildUseSortStartTimeAsc,
  buildUseSortDirection,
  buildUseEntryCostSortAsc,
  buildUseEntryCostSortDesc,
  buildUseToggleSort,
  useCallbackWithArg,
  useService,
  useStore,
  useStoreKey,
} from '../react-hooks.js'

import {KEY_ENTRY_COST, KEY_START_TIME} from '../sorting.js'

export function useCriteria () {
  return useStore(useServiceCriteria())
}

export function useLimitType () {
  return useStoreKey(useServiceCriteria(), 'limitType')
}

export function useSort () {
  return useStore(useServiceSort())
}

export function useIsDirectionEmpty () {
  const {direction} = useSort()

  return direction === undefined
}

export function useIsStartTimeAsc () {
  const {direction, key} = useSort()

  return key === KEY_START_TIME && direction === ASC
}

export function useIsEntryCostSortAsc () {
  const {direction, key} = useSort()

  return key === KEY_ENTRY_COST && direction === DESC
}

export function useIsEntryCostSortDesc () {
  const {direction, key} = useSort()

  return key === KEY_ENTRY_COST && direction === ASC
}

export const useSortDirection = buildUseSortDirection(useServiceSort)

export const useSortStartTimeAsc = buildUseSortStartTimeAsc(useServiceSort)

export const useEntryCostSortAsc = buildUseEntryCostSortAsc(useServiceSort)

export const useEntryCostSortDesc = buildUseEntryCostSortDesc(useServiceSort)

export function useSetLimitType (limitType) {
  return useCallbackWithArg(useServiceCriteria().setLimitType, limitType)
}

export function useSetTournamentType (tournamentType) {
  return useCallbackWithArg(useServiceCriteria().setTournamentType, tournamentType)
}

export function useSetVariant (variant) {
  return useCallbackWithArg(useServiceCriteria().setVariant, variant)
}

export const useToggleSort = buildUseToggleSort(useServiceSort)

export function useTournamentType () {
  return useStoreKey(useServiceCriteria(), 'tournamentType')
}

export function useVariant () {
  return useStoreKey(useServiceCriteria(), 'variant')
}

export function useScheduledTournamentFilter (s2) {
  const urlMatchedFilter = SCHEDULED_TOURNAMENT_FILTER_MAP[s2]

  const limitType = useStoreKey(useServiceCriteria(), 'limitType')
  const tournamentType = useStoreKey(useServiceCriteria(), 'tournamentType')
  const variant = useStoreKey(useServiceCriteria(), 'variant')

  if (urlMatchedFilter) return urlMatchedFilter

  return getMatchedFilter(limitType, variant, tournamentType)
}

function getMatchedFilter (limitType, variant, tournamentType) {
  const holdemCriteria = SCHEDULED_TOURNAMENT_FILTER_CRITERIA[SCHEDULED_TOURNAMENT_FILTER.HOLDEM]

  if (limitType === holdemCriteria.limitType &&
    variant === holdemCriteria.variant &&
    tournamentType === holdemCriteria.tournamentType
  ) {
    return SCHEDULED_TOURNAMENT_FILTER.HOLDEM
  }

  const omahaCriteria = SCHEDULED_TOURNAMENT_FILTER_CRITERIA[SCHEDULED_TOURNAMENT_FILTER.OMAHA]

  if (limitType === omahaCriteria.limitType &&
    variant === omahaCriteria.variant &&
    tournamentType === omahaCriteria.tournamentType
  ) {
    return SCHEDULED_TOURNAMENT_FILTER.OMAHA
  }

  const satellitesCriteria = SCHEDULED_TOURNAMENT_FILTER_CRITERIA[SCHEDULED_TOURNAMENT_FILTER.SATELLITES]

  if (limitType === satellitesCriteria.limitType &&
    variant === satellitesCriteria.variant &&
    tournamentType === satellitesCriteria.tournamentType
  ) {
    return SCHEDULED_TOURNAMENT_FILTER.SATELLITES
  }
}

function useServiceCriteria () {
  return useService().scheduledTournamentCriteria
}

function useServiceSort () {
  return useService().scheduledTournamentSort
}

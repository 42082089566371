import React, {useCallback} from 'react'
import {useRouteNode, useRouter} from 'react-router5'
import {startsWithSegment} from 'router5-helpers'

import MobileLobbyMenu from './MobileLobbyMenu.js'
import TabButton from './TabButton.js'
import {QUICK_SEAT} from '../../routes.js'

export default function MobileTabButtons () {
  const router = useRouter()
  const {route} = useRouteNode('')
  const testRoute = startsWithSegment(route)

  const handleClick = useCallback(() => {
    router.navigate(QUICK_SEAT)
  }, [router])

  return <>
    <TabButton icon='seat' label='Quick Seat' isActive={testRoute(QUICK_SEAT)} onClick={handleClick} />
    <MobileLobbyMenu />
  </>
}

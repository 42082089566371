import React, {useMemo} from 'react'

import {useRootedSelector} from '../../../redux.js'
import {createGetTotalUsedAddOns, createGetTotalUsedRebuys, getRoot} from '../../index.js'

export default function TotalUsedRebuysAddOns (props) {
  const usedAddOns = useRootedSelector(getRoot, useMemo(createGetTotalUsedAddOns, []), props)
  const usedRebuys = useRootedSelector(getRoot, useMemo(createGetTotalUsedRebuys, []), props)

  return <div className='kvpair selfclear'>
    <label>Rebuys / Addons:</label><span>{usedRebuys} / {usedAddOns}</span>
  </div>
}

import Mixmaster from '../../../mixmaster.coffee'

export default class JackpotData extends Mixmaster
  mixins = [
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  mixin: (object) ->
    mixins.map((mixin) => (new mixin).mixin(object))

    object.jackpotBalance = (signal, name, values) ->
      signal.updates.jackpotBalance = parseFloat(values)

    object.jackpotIsActive = (signal, name, values) ->
      signal.updates.jackpotIsActive = values

    object.jackpotStartDate = (signal, name, values) ->
      signal.updates.jackpotStartDate = @parseDate values

    object.jackpotEndDate = (signal, name, values) ->
      signal.updates.jackpotEndDate = @parseDate values

import React from 'react'
import {Link} from 'react-router5'

import {useWindowTitle} from '../../global/react-hooks.js'

const windowTitle = 'FAQ'

export default function Faq (props) {
  const {site} = props

  useWindowTitle(windowTitle)

  return <div className='faq'>
    <div className='format-message'>FAQ</div>

    <div className='container'>
      <div className='breadcrumb'>
        <Link className='link' routeName='support'>Support</Link> &gt; FAQ
      </div>

      <h3>Q. Which mobile devices are supported?</h3>
      <p>A: The {site} mobile version supports the following devices:</p>

      <h4>iOS Devices:</h4>
      <ul className='classic-list'>
        <li>iPhone, iPad and iPod touch with version 10.3 of iOS or above are supported.</li>
      </ul>

      <h4>Android Devices:</h4>
      <ul className='classic-list'>
        <li>Android Phones and Tablets are supported by {site} mobile.</li>
        <li>Android devices with version 4.4 of the Android operating system or above are supported. We recommend installing Google Chrome for Android from the Google Play store if available, for the best {site} mobile player experience.</li>
      </ul>

      <h4>Older Android Versions:</h4>
      <ul className='classic-list'>
        <li>Devices with Android version 4.3 or older must use Google Chrome for Android when using {site} mobile. Google Chrome for Android is available from the Google Play for supported devices. Version 58.0 of Google Chrome or higher is required when using {site} mobile.</li>
      </ul>

      <h4>Desktop Browsers</h4>
      <ul className='classic-list'>
        <li>Google Chrome 58+, Firefox 54+, Safari 10.1+ and Microsoft Edge 12+</li>
      </ul>

      <h3>Q. What can I do on the {site} mobile version?</h3>
      <p>A. You can experience the same Texas Hold'em Cash Games and Sit &amp; Go's that you've grown to love all on the go! You can also make deposits, play in our Casino and make Sports bets in the palm of your hand.</p>

      <h3>Q: Can I deposit funds in the {site} mobile version?</h3>
      <p>A: Yes, our mobile cashier makes depositing fast, safe and secure.</p>

      <h3>Q. What is the best browser to use, to play {site} on my mobile, tablet or web browser?</h3>
      <p>A. Google Chrome for Android and Safari for iOS are the current recommended and supported web browsers. If you are experiencing issues when using your device default browser, please install Google Chrome for Android from the Google Play store or the Safari browser from the Apple App store.</p>

      <h3>Q. Can I view my hand history in the {site} mobile version?</h3>
      <p>A. Hand histories are not available for mobile play. We will add this in the near future.</p>

      <h3>Q. Can you register into a mobile Sit &amp; Go with an Entry Coupon?</h3>
      <p>A. Yes. When you go to register for a Sit &amp; Go simply select this buy-in option you desire and click "Register Now".</p>

      <h3>Q. How do I deregister from a Sit &amp; Go?</h3>
      <p>A. Open the lobby for the tournament you are registered for, scroll down until you see "deregister" and click it. You will be deregistered from the Sit &amp; Go. This button is located in the same area as the "Register Now" button.</p>

      <h3>Q. Can I play multiple tables at once on a mobile device?</h3>
      <p>A. Currently, our mobile version only allows users to play on one table. You will be shown multiple warning messages stating that multiple table play is not supported by {site} mobile.</p>

      <h3>Q. What happens if I'm registered for a single or multiple Sit &amp; Go on the desktop version and then I switch to the {site} mobile version?</h3>
      <p>A. You will not be deregistered from any of your Sit &amp; Go's or tournaments.</p>
      <p>The first Sit &amp; Go or tournament to be seated will be available to play. Should a second Sit &amp; Go or tournament start while playing, you will continue to pay blinds at that table.</p>
      <p>We suggest you go back to the desktop version to continue playing multiple Sit &amp; Go's and Tournaments to deregister from them before attempting to pay on a mobile device.</p>

      <h3>Q. What happens if I get disconnected while playing a Cash Game or Sit &amp; Go?</h3>
      <p>A. For Cash Games, if disconnected while involved in a hand, a player will have an additional 30 seconds to reconnect and make his action - with the exception that when a pot is larger than 100 times the big bind, this reconnection time is increased to 60 seconds.</p>
      <p>Disconnection protection is not available for Sit &amp; Go's. Players will be folded if they lose connection and blinds will be automatically paid until they return.</p>

      <h3>Q. I try to login to the poker mobile and it states the "connection timed out".</h3>
      <p>A. If you are on an Android device, download Chrome and use that to access our mobile product. If you are on iOS device then please try again.</p>

      <h3>Q. I just received the "Your browser is unsupported" message, what does this mean?</h3>
      <p>{site} mobile does not support the web browser you are attempting to use.</p>
      <p>Please make sure that you have installed all of the latest software and firmware updates for your device, then visit the Google Play store or Apple App store to download Google Chrome for Android or Safari for iOS.</p>

      <h3>Q. What is Quick Seat?</h3>
      <p>A. Our Quick Seat lobby view is a fast and easy way to get in the games you want immediately! Try it next time you are on the go.</p>

      <h3>Q. Is there sound available in the mobile version?</h3>
      <p>A. We are currently experimenting with support for sounds. This feature is in Beta and can be enable on some devices through the settings menu. Simply go into the "General Settings" menu and select "Sound Enabled".</p>

      <h3>Q. Can I play in the Casino or bet on my favorite Sports?</h3>
      <p>A. Yes, you can take your favorite Casino games and bet on your favorite Sports teams on the go.</p>
    </div>
  </div>
}

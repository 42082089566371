export function createService () {
  let isOpen = false
  const subscribers = new Set()

  return {
    get isOpen () { return isOpen },

    subscribe (subscriber) {
      subscribers.add(subscriber)

      return function unsubscribe () {
        subscribers.delete(subscriber)
      }
    },

    toggle () {
      isOpen = !isOpen

      dispatchIsOpen(isOpen)
    },

    hide () {
      isOpen = false

      dispatchIsOpen(isOpen)
    },
  }

  function dispatchIsOpen (isOpen) {
    for (const subscriber of subscribers) subscriber(isOpen)
  }
}

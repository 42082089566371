import {
  AUTO_REBUY_POINT_OFF,
  AUTO_REBUY_POINT_OUT_OF_CHIPS,
  AUTO_REBUY_POINT_TABLE_MIN,
} from '../rooms/constants.js'

import {SETTINGS} from '../routes.js'

export function createService (
  casinoSettings,
  gameFilterSettings,
  globalSettings,
  legacySettings,
  modalService,
  router,
) {
  const {showModal} = modalService

  return {
    casinoSettings,
    gameFilterSettings,
    globalSettings,

    async init () {
      listenForDesktopOpenSettings()
    },

    authorizeNotifications () {
      const closeModal = showModal({
        title: 'Notifications Permission',
        content: 'Please click Allow in the browser prompt above to enable notifications.',
        choices: [],
      })

      Notification.requestPermission().then(perm => {
        if (perm === 'granted') {
          legacySettings.notifications = true
          closeModal()
        } else if (perm === 'denied') {
          // this can happen if they click deny or they have previously denied permission
          closeModal()

          showModal({
            title: 'Notifications Permission',
            content: 'You have denied Notifications permission. To Grant access please reset your browsers Notification Permissions.',
            choices: [
              {value: 'OK'},
            ],
          })
        }
      })
    },

    configurePopouts () {
      showModal({
        title: 'Popup Settings',
        content: `Your current game tile popup setting is: ${legacySettings.popup}<br><br>Game tile popups can be opened in tabs or windows.`,
        choices: [
          {
            value: 'New Window',
            action: () => {
              openPopout('window')
            },
          },
          {
            value: 'New Tab',
            action: () => {
              openPopout('tab')
            },
          },
          {
            value: 'Disable',
            action: () => {
              legacySettings.popup = 'off'
            },
          },
        ],
      })
    },

    disableAutoRebuy () {
      const {amount, initialBuyIn} = legacySettings.autoRebuy

      legacySettings.autoRebuy = {
        point: AUTO_REBUY_POINT_OFF,
        amount,
        initialBuyIn,
      }
    },

    disablePopouts () {
      legacySettings.popup = 'off'
    },

    enableAutoRebuyWhenBelowTableMinimum () {
      const {amount, initialBuyIn} = legacySettings.autoRebuy

      legacySettings.autoRebuy = {
        point: AUTO_REBUY_POINT_TABLE_MIN,
        amount,
        initialBuyIn,
      }
    },

    enableAutoRebuyWhenOutOfChips () {
      const {amount, initialBuyIn} = legacySettings.autoRebuy

      legacySettings.autoRebuy = {
        point: AUTO_REBUY_POINT_OUT_OF_CHIPS,
        amount,
        initialBuyIn,
      }
    },

    resetCasinoSettings () {
      showModal({
        title: 'Reset All Settings?',
        icon: 'warning',
        content: 'Are you sure? This will revert all settings and filters to their default value.',
        choices: [
          {
            value: 'Cancel',
            key: 'escape',
            icon: 'times',
          },
          {
            value: 'Reset Settings',
            key: 'enter',
            icon: 'check',
            showLoading: true,
            action: () => {
              legacySettings.resetAll()
              legacySettings.emit('resetFilter')
            },
          },
        ],
      })
    },

    setAutoRebuyAtInitialBuyIn () {
      const {amount, point} = legacySettings.autoRebuy

      legacySettings.autoRebuy = {
        point,
        amount,
        initialBuyIn: true,
      }
    },

    setAutoRebuyPercentage (value) {
      const {point} = legacySettings.autoRebuy
      const amount = parseInt(value, 10) || 0

      legacySettings.autoRebuy = {
        point,
        amount,
        initialBuyIn: false,
      }
    },

    setCheckFoldPreference (value) {
      legacySettings.checkFoldPreference = value
    },

    setDefaultBuyInBigBlindMultiplier (value) {
      legacySettings.defaultBuyin = value
    },

    setNotificationMinutesBeforeTournamentStarts (value) {
      legacySettings.notificationBeforeGameStart = value
    },

    showPreferredSeating (seats) {
      legacySettings.emit('showPreferredSeating', seats)
    },

    switchFormat () {
      legacySettings.emit('switchFormat')
    },

    toggleAutoMuck () {
      toggleCasinoSetting('autoMuck')
    },

    toggleAutoPostBlinds () {
      toggleCasinoSetting('autoBlinds')
    },

    toggleAutoTimebank () {
      toggleCasinoSetting('autoTimebank')
    },

    toggleConfirmAllIn () {
      toggleCasinoSetting('confirmAllIn')
    },

    toggleConfirmLeaveGame () {
      toggleCasinoSetting('confirmLeaveGame')
    },

    toggleFourColorDeck () {
      toggleCasinoSetting('fourColorDeck')
    },

    toggleOtherNotifications () {
      toggleCasinoSetting('notificationPush')
    },

    toggleShowFastNotice () {
      toggleCasinoSetting('showFastNotice')
    },

    toggleSoundsEnabled () {
      toggleCasinoSetting('soundsEnabled')
    },

    toggleShowTournamentPositionNotice () {
      toggleCasinoSetting('showTournamentPositionNotice')
    },

    toggleTournamentStartingNotifications () {
      toggleCasinoSetting('notificationAtGameStart')
    },
  }

  function listenForDesktopOpenSettings () {
    window.addEventListener('message', event => {
      if (event.data?.type === 'OPEN_SETTINGS') router.navigate(SETTINGS)
    })
  }

  function openPopout (setting) {
    const windowOptions = [
      'height=450',
      'width=650',
      'left=100',
      'top=300',
      'resizable=yes',
      'scrollbars=yes',
      'toolbar=yes',
      'menubar=no',
      'location=no',
      'directories=no',
      'status=yes',
    ].join(',')

    const popout = window.open(
      `popUp/${setting}`,
      'Popout Settings',
      setting === 'window' ? windowOptions : '',
    )

    if (!popout) {
      legacySettings.popup = 'off'

      showModal({
        title: 'Popout Settings',
        content: 'It looks like you might be using a popup blocker, you must allow popups in your browser settings, or disable your popup blocker',
        choices: [
          {value: 'OK'},
        ],
      })
    }
  }

  function toggleCasinoSetting (key) {
    legacySettings[key] = !legacySettings[key]
  }
}

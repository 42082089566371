import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# Data Types
import GameListData from './dataTypes/gameListData.coffee'

export default class GetTournamentParentsTranslator extends Mixmaster
  mixins = [
    DefaultMixin
    GameListData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal
    signal.games = []

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

import React, {useCallback} from 'react'

export default function PresetButton (props) {
  const {label, onClick, value} = props

  const handleClick = useCallback(() => {
    onClick(value)
  }, [onClick, value])

  return <button type='button' className='button' onClick={handleClick}>{label}</button>
}

import classnames from 'classnames'
import React from 'react'

import ImReadyButton from './components/ImReadyButton.js'
import styles from '../Actions.module.css'
import {useIsOnBreak} from '../react-hooks.js'

export default function BreakTime () {
  const isOnBreak = useIsOnBreak()

  if (!isOnBreak) return null

  return <div className={classnames(styles.breakTime, styles.preactions)}>
    <ImReadyButton />
  </div>
}

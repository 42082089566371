import {isGreaterThan, maximum, minimum, multiply} from '@pipehat/money/plain-object'
import React, {useCallback, useMemo} from 'react'

import Amount from './components/Amount.js'
import PresetButton from './components/PresetButton.js'
import When from './components/When.js'
import {useDefaultBuyInBigBlindMultiplier} from '../../../settings/react-hooks.js'
import styles from '../AdvancedOptions.module.css'

import {
  useBalance,
  useDisableAutoRebuy,
  useInitialBuyIn,
  useMaximumBuyIn,
  useMinimumBuyIn,
  useSetAutoRebuyAmount,
  useShowInsufficientFundsForAutoRebuy,
  useStakes,
} from '../react-hooks.js'

export default function AutoRebuy () {
  const defaultBuyInBigBlindMultiplier = useDefaultBuyInBigBlindMultiplier()
  const showInsufficientFundsForAutoRebuy = useShowInsufficientFundsForAutoRebuy()
  const disableAutoRebuy = useDisableAutoRebuy()
  const setAutoRebuyAmount = useSetAutoRebuyAmount()
  const balance = useBalance()
  const initialBuyIn = useInitialBuyIn()
  const maximumBuyIn = useMaximumBuyIn()
  const minimumBuyIn = useMinimumBuyIn()
  const stakes = useStakes()

  const hasTableData = balance && initialBuyIn && maximumBuyIn && minimumBuyIn && stakes && true
  const {smallBlind} = hasTableData ? stakes : {}

  const defaultAmount = useMemo(() => {
    if (!hasTableData) return undefined

    const bigBlind = multiply(2, smallBlind)
    const defaultBuyIn = multiply(Number(defaultBuyInBigBlindMultiplier), bigBlind)

    return maximum([minimumBuyIn, minimum([balance, maximumBuyIn, defaultBuyIn])])
  }, [defaultBuyInBigBlindMultiplier, hasTableData, maximumBuyIn, minimumBuyIn, balance, smallBlind])

  const setRebuyAmount = useCallback(value => {
    const isGreaterThanOwnBalance = isGreaterThan(balance)
    if (isGreaterThanOwnBalance(minimumBuyIn)) {
      showInsufficientFundsForAutoRebuy(balance, minimumBuyIn)
      disableAutoRebuy()

      return
    }

    setAutoRebuyAmount(value)
  }, [disableAutoRebuy, minimumBuyIn, balance, setAutoRebuyAmount, showInsufficientFundsForAutoRebuy])

  return <div className={styles.autoRebuy}>
    <When />
    <Amount onChange={setRebuyAmount} />
    <div className={styles.autoRebuyPresets}>
      <PresetButton label='Min' onClick={setRebuyAmount} value={minimumBuyIn} />
      <PresetButton label='Default' onClick={setRebuyAmount} value={defaultAmount} />
      <PresetButton label='Initial Buy-In' onClick={setRebuyAmount} value={initialBuyIn} />
      <PresetButton label='Max' onClick={setRebuyAmount} value={maximumBuyIn} />
    </div>
  </div>
}

import {COUPON_ENTRY} from '@pipehat/chronicle/constants/game-category'
import classnames from 'classnames'
import React, {useMemo} from 'react'

import TournamentIndicators from './TournamentIndicators.js'
import Cell from '../../components/game-listing/table/Cell.js'
import LinkRow from '../../components/game-listing/table/LinkRow.js'
import styles from '../../components/game-listing/table/Table.module.css'
import Money from '../../money/components/Money.js'
import {renderGameLimitAndVariantInitialism, renderTournamentStatus} from '../../rendering.js'
import {TOURNAMENT} from '../../routes.js'
import DescribedTime from '../../time/components/DescribedTime.js'
import {renderStartsWhen} from '../sitngo-tournament/rendering.js'

export default function DesktopTournamentListingRow (props) {
  const {tournament} = props

  const {
    categories,
    entryCost,
    highlightColor,
    isSng,
    maximumRegistrants,
    name,
    registrantCount,
    startTime,
    status,
    tournamentId,
  } = tournament

  const routeParams = useMemo(() => ({tournamentId: String(tournamentId)}), [tournamentId])

  const starts = isSng
    ? <Cell>{renderStartsWhen(maximumRegistrants - registrantCount)}</Cell>
    : <Cell>{startTime ? <DescribedTime value={startTime} /> : '-'}</Cell>

  const isCouponEntry = categories.includes(COUPON_ENTRY)
  const entry = entryCost
    ? <Money value={entryCost} />
    : isCouponEntry
      ? <span title='Restricted'>Free*</span>
      : 'Free'

  return <LinkRow highlightColor={highlightColor} routeName={TOURNAMENT} routeParams={routeParams}>
    {starts}
    <Cell className={styles.primary}>{name}</Cell>
    <Cell>{renderGameLimitAndVariantInitialism(tournament)}</Cell>
    <Cell>{entry}</Cell>
    <Cell>{registrantCount} / {maximumRegistrants}</Cell>
    <Cell>{renderTournamentStatus(status)}</Cell>
    <Cell className={classnames(styles.noPadding, styles.alignRight)}>
      <TournamentIndicators tournament={tournament} />
    </Cell>
  </LinkRow>
}

import {
  ABANDON_SIT_OUT_STRATEGY,
  SELECT_SIT_OUT_STRATEGY,
  SIT_IN,
} from '@pipehat/chronicle/constants/command-type'

import {
  RING_GAME_TABLE_LOADED,
  SAT_IN,
  SIT_OUT_STRATEGY_ABANDONED,
  SIT_OUT_STRATEGY_SELECTED,
  TOURNAMENT_TABLE_LOADED,
} from '@pipehat/chronicle/constants/event-type'

import {NEXT_HAND} from '@pipehat/chronicle/constants/sit-out-strategy'
import {REGULAR} from '@pipehat/chronicle/constants/table-speed'
import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  isAwaitingSitOut: false,
  sitOutStrategy: NEXT_HAND,
  isTournament: false,
  speed: REGULAR,
}

const quickOptionsSlice = createSlice({
  name: 'quickOptions',
  initialState,
  extraReducers: {
    [TOURNAMENT_TABLE_LOADED]: state => {
      state.isTournament = true
    },
    [RING_GAME_TABLE_LOADED]: (state, {payload}) => {
      state.speed = payload.speed
    },
    [ABANDON_SIT_OUT_STRATEGY]: state => {
      state.isAwaitingSitOut = false
    },

    [SIT_OUT_STRATEGY_ABANDONED]: state => {
      state.isAwaitingSitOut = false
    },

    [SELECT_SIT_OUT_STRATEGY]: (state, {payload}) => {
      state.isAwaitingSitOut = true
      state.sitOutStrategy = payload.strategy
    },

    [SIT_OUT_STRATEGY_SELECTED]: (state, {payload}) => {
      state.isAwaitingSitOut = true
      state.sitOutStrategy = payload.strategy
    },

    [SAT_IN]: state => {
      state.isAwaitingSitOut = false
    },

    [SIT_IN]: state => {
      state.isAwaitingSitOut = false
    },
  },
})

export default quickOptionsSlice.reducer

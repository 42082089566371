import classnames from 'classnames'
import React from 'react'
import {useRouteNode} from 'react-router5'
import {startsWithSegment} from 'router5-helpers'

import InstallSubMenu from './InstallSubMenu.js'
import MobileMenuGameListItems from './MobileMenuGameListItems.js'
import OptionsSubMenu from './OptionsSubMenu.js'
import PromotionSubMenu from './PromotionSubMenu.js'
import SignOutSubMenu from './SignOutSubMenu.js'
import UpdateSubMenu from './UpdateSubMenu.js'
import {useGameFormat} from '../../game-list/react-hooks.js'
import {useIsAvailable as useIsInstallAvailable} from '../../installation/react-hooks.js'
import {GAME_LIST} from '../../routes.js'
import {useStatus} from '../../updating/react-hooks.js'
import {STATUS_UP_TO_DATE} from '../../updating/service.js'
import {useHide, useIsOpen} from '../react-hooks.js'

export default function MobileMenu () {
  const {route} = useRouteNode('')
  const testRoute = startsWithSegment(route)
  const isInstallAvailable = useIsInstallAvailable()
  const gameFormat = useGameFormat()
  const updateStatus = useStatus()
  const isOpen = useIsOpen()

  const isUpdateAvailable = updateStatus !== STATUS_UP_TO_DATE

  return <div className={classnames('sideMenu', {hidden: !isOpen})} onClick={useHide()}>
    {isUpdateAvailable && <UpdateSubMenu status={updateStatus} />}
    <OptionsSubMenu lobbyLabel='Game List' lobbyRoute={GAME_LIST} />
    {isInstallAvailable && <InstallSubMenu />}
    {testRoute(GAME_LIST) && <MobileMenuGameListItems gameFormat={gameFormat} />}
    <PromotionSubMenu />
    <SignOutSubMenu />
  </div>
}

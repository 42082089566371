import React from 'react'

import SeatingPreview from '../../components/game-listing/SeatingPreview.js'
import ButtonRow from '../../components/game-listing/table/ButtonRow.js'
import Cell from '../../components/game-listing/table/Cell.js'
import styles from '../../components/game-listing/table/Table.module.css'
import Money from '../../money/components/Money.js'
import Stakes from '../../money/components/Stakes.js'
import {useOpen} from '../../multi-table/react-hooks.js'
import {renderGameLimitAndVariantInitialism} from '../../rendering.js'
import {renderSeatingStatus} from '../rendering.js'

export default function OwnRingGamesRow (props) {
  const {ringGame} = props
  const {maximumStake, minimumStake, name, occupiedSeats, seatCount, seatingStatus, stack, tableId} = ringGame

  return <ButtonRow onClick={useOpen(tableId)}>
    <Cell className={styles.seatingPreview}>
      <SeatingPreview seatCount={seatCount} occupiedSeats={occupiedSeats} />
    </Cell>
    <Cell className={styles.primary}>{name}</Cell>
    <Cell>{renderGameLimitAndVariantInitialism(ringGame)}</Cell>
    <Cell><Stakes maximumStake={maximumStake} minimumStake={minimumStake} /></Cell>
    <Cell>{renderSeatingStatus(seatingStatus)}</Cell>
    <Cell>{stack ? <Money value={stack} /> : '-'}</Cell>
    <Cell>{occupiedSeats.length} / {seatCount}</Cell>
  </ButtonRow>
}

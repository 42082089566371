# GamePlayersView
# Model: players

import PlayerView from './playerView.coffee'
import View from '../../view.coffee'

export default class GamePlayersView extends View

  constructor: (game, @createReactView) ->
    super()

    @model = game

    @dom = $ "<div id='players'>"

    # ### Components - eg. DOM elements & views
    @components =
      dealer: $ '<div class="dealer sprite sprite-dealer">'

    # ### Events - eg. MouseClicks ###

    # ### Listeners - eg. auth:success
    @listeners =
      'breakStarted': @breakStarted

    # ### Changes - eg. Model.property = true
    @changes =
      dealer: @updateDealer

    @model.players.on 'add', @addPlayer

    # ### Binding ###
    @initializeView()
    return this

  addPlayer: (player) =>
    view = new PlayerView player, @createReactView
    @dom.append view.render()

  updateDealer: =>
    @components.dealer.removeClass().addClass "dealer sprite sprite-dealer dealer#{@model.dealerPosition}"

  render: =>
    @appendComponents()
    @model.players.each @addPlayer

    for _, change of @changes
      change()

    return @dom

  breakStarted: =>
    @components.dealer.removeClass()

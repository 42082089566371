import React from 'react'

import {
  useShouldShowEmpty,
  useShouldShowFull,
  useToggleShouldShowEmpty,
  useToggleShouldShowFull,
} from '../../game-list/ring-game/react-hooks.js'

export default function GameFilterSubMenu () {
  return <>
    <div className='heading'>Show</div>
    <label className='menu-checkbox-wrapper'>
      <input
        id='showEmptyTablesMenuChk'
        type='checkbox'
        className='css-checkbox'
        checked={useShouldShowEmpty()}
        onChange={useToggleShouldShowEmpty()}
      />
      <label htmlFor='showEmptyTablesMenuChk' className='css-checkbox-label'>Show Empty Tables</label>
    </label>
    <label className='menu-checkbox-wrapper'>
      <input
        id='showFullTablesMenuChk'
        type='checkbox'
        className='css-checkbox'
        checked={useShouldShowFull()}
        onChange={useToggleShouldShowFull()}
      />
      <label htmlFor='showFullTablesMenuChk' className='css-checkbox-label'>Show Full Tables</label>
    </label>
  </>
}

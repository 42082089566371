export default ({signal, details, app, game, client, listing}) ->
  return unless game

  game.playersOutOfGame ?= [] # HACK. for use in rematch modal
  details.forEach (position) ->
    player = game.getPlayerByPosition(position)
    return unless player
    game.playersOutOfGame.push player.alias
    client.displayChatMessage message: player.alias + ' was knocked out'
    if player.alias is app.user.nickname
      client?.seated = false
      listing?.playing = false
      listing?.getDetails ->
        if listing.multipleEntry and listing.gameState is 'Late Registration' and not listing.lateRegistrationOptions?.stopAfterElimination
          client?.promptReEnter {listing}
    game.players.rem player

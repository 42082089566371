import classnames from 'classnames'
import React from 'react'

import Checkbox from './Checkbox.js'
import styles from './LabeledCheckbox.module.css'

export default function LabeledCheckbox (props) {
  const {checked, children, className, isDisabled, onChange, onClick, size} = props
  const checkboxClassName = classnames(styles.labeledCheckbox, {
    [className]: className,
    [styles.disabled]: isDisabled,
    [styles[size]]: ['small', 'medium', 'large'].includes(size),
  })

  return <label className={checkboxClassName}>
    <Checkbox checked={checked} isDisabled={isDisabled} onChange={onChange} onClick={onClick} />
    <span className={styles.text}>{children}</span>
  </label>
}

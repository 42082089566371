import {messageSW, Workbox} from 'workbox-window'

export const STATUS_RELOAD_REQUIRED = 'RELOAD_REQUIRED'
export const STATUS_RESTART_REQUIRED = 'RESTART_REQUIRED'
export const STATUS_UP_TO_DATE = 'UP_TO_DATE'
export const STATUS_UPDATING = 'UPDATING'

export function createService (casinoSettings, log, logger, notifier, shortName, updateCheckInterval, user, window) {
  const subscribers = new Set()
  let status = STATUS_UP_TO_DATE
  let isNotificationShown = false
  let serviceWorkerRegistration, waitingServiceWorker

  return {
    async init () {
      try {
        await registerServiceWorker()
        listenForDesktopUpdates()
      } catch (error) {
        handleError(error)
      }
    },

    get status () {
      return status
    },

    subscribe (subscriber) {
      subscribers.add(subscriber)

      return function unsubscribe () {
        subscribers.delete(subscriber)
      }
    },

    update () {
      if (status === STATUS_RESTART_REQUIRED) {
        setStatus(STATUS_UPDATING)
        window.postMessage({type: 'QUIT_AND_INSTALL_UPDATE'}, '*')

        return
      }

      if (status === STATUS_RELOAD_REQUIRED && waitingServiceWorker) {
        setStatus(STATUS_UPDATING)
        messageSW(waitingServiceWorker, {type: 'SKIP_WAITING'})

        return
      }

      if (status !== STATUS_UPDATING) setStatus(STATUS_UP_TO_DATE)
    },
  }

  function handleError (error) {
    log('update', 'Update error:', error)
  }

  function listenForDesktopUpdates () {
    window.addEventListener('message', event => {
      if (event.data?.type !== 'DESKTOP_UPDATE_READY') return
      if (status !== STATUS_UPDATING) setStatus(STATUS_RESTART_REQUIRED)
    })

    // notify desktop app that we are now listening for update notifications
    window.postMessage({type: 'NOTIFY_OF_UPDATES'}, '*')
  }

  function onServiceWorkerStateChange ({target: {state}}) {
    if (state === 'activated') window.location.reload()
  }

  function onServiceWorkerWaiting () {
    try {
      waitingServiceWorker?.removeEventListener('statechange', onServiceWorkerStateChange)
      waitingServiceWorker = serviceWorkerRegistration?.waiting
      waitingServiceWorker?.addEventListener('statechange', onServiceWorkerStateChange)

      if (status === STATUS_UP_TO_DATE) setStatus(STATUS_RELOAD_REQUIRED)
    } catch (error) {
      handleError(error)
    }
  }

  async function registerServiceWorker () {
    if (!('serviceWorker' in window.navigator)) return // not supported

    try {
      if (process.env.NODE_ENV !== 'production') {
        // prevent excessive Workbox logging in dev
        const nativeSw = window.navigator.serviceWorker

        const updateWorkboxLogging = () => {
          nativeSw.controller?.postMessage({
            type: logger.topics.workbox ? 'ENABLE_WORKBOX_LOGGING' : 'DISABLE_WORKBOX_LOGGING',
          })
        }

        nativeSw.addEventListener('controllerchange', updateWorkboxLogging)
        logger.events.on('topic.workbox', updateWorkboxLogging)
        updateWorkboxLogging()
      }

      const serviceWorker = new Workbox('app.sw.js')
      let isWaitingDuringRegister = false

      // we need to defer "real" handling of this event until after we have access to the service worker registration
      serviceWorker.addEventListener('waiting', () => { isWaitingDuringRegister = true }, {once: true})

      serviceWorkerRegistration = await serviceWorker.register()
      if (isWaitingDuringRegister) onServiceWorkerWaiting()

      serviceWorker.addEventListener('waiting', onServiceWorkerWaiting)

      setInterval(
        async () => {
          try {
            await serviceWorker.update()
          } catch (error) {
            handleError(error)
          }
        },
        updateCheckInterval,
      )
    } catch (error) {
      handleError(error)
    }
  }

  function setStatus (nextStatus) {
    status = nextStatus

    for (const subscriber of subscribers) subscriber(nextStatus)

    if (status === STATUS_RELOAD_REQUIRED || status === STATUS_RESTART_REQUIRED) {
      showNotification()
    } else {
      isNotificationShown = false
    }
  }

  async function showNotification () {
    if (isNotificationShown) return

    if (!user.authenticated) {
      user.on('change:authenticated', showNotification)

      return
    }

    isNotificationShown = true
    if (!casinoSettings.get('notificationPush')) return

    try {
      await notifier.notify('Update available', {body: 'An update is available.'})
    } catch (error) {
      handleError(error)
    }
  }
}

import React, {useEffect} from 'react'

import RadioOptionMenuItem from './RadioOptionMenuItem.js'

import {
  useSortStartTimeAsc,
  useIsStartTimeAsc,
  useIsEntryCostSortAsc,
  useIsEntryCostSortDesc,
  useEntryCostSortAsc,
  useEntryCostSortDesc,
  useIsDirectionEmpty,
} from '../../game-list/scheduled-tournament/react-hooks.js'

export default function MobileTournamentSortSubMenu () {
  const isStartTimeAsc = useIsStartTimeAsc()
  const isEntryCostSortAsc = useIsEntryCostSortAsc()
  const isEntryCostSortDesc = useIsEntryCostSortDesc()
  const isDirectionEmpty = useIsDirectionEmpty()

  const isCustom = !isStartTimeAsc && !isEntryCostSortAsc && !isEntryCostSortDesc && !isDirectionEmpty
  const sortStartTimeAsc = useSortStartTimeAsc()

  useEffect(() => {
    if (isDirectionEmpty) sortStartTimeAsc()
  }, [isDirectionEmpty, sortStartTimeAsc])

  return <>
    <RadioOptionMenuItem
      isChecked={isStartTimeAsc}
      label='Starting Soonest'
      onChange={useSortStartTimeAsc()}
    />
    <RadioOptionMenuItem
      isChecked={isEntryCostSortAsc}
      label='Stakes: High to Low'
      onChange={useEntryCostSortAsc()}
    />
    <RadioOptionMenuItem
      isChecked={isEntryCostSortDesc}
      label='Stakes: Low to High'
      onChange={useEntryCostSortDesc()}
    />
    {isCustom && <RadioOptionMenuItem isChecked label='Custom' readOnly />}
  </>
}

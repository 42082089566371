import Request from './request.coffee'

export default class GetClientDataFeedRequest extends Request
  constructor: ({client, message, amount}) ->
    super arguments...

    @function = 'GET_CLIENT_DATA_FEED'

    @attributes =
      type: 'REQUEST'
      service: 'GAMELIST'
      routerId: message.routerId
      serverId: 0
      instanceId: 0
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

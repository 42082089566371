import {ALL_IN} from '@pipehat/chronicle/constants/action'
import React, {useCallback, useEffect, useState} from 'react'

import {useIsConfirmAllIn} from '../../../../settings/react-hooks.js'
import styles from '../../Actions.module.css'
import ActionButton from '../../components/ActionButton.js'
import {useDeclareAllIn, useIsTurn, useIntendedActionName, useStatus} from '../../react-hooks.js'

export default function AllInButton () {
  const status = useStatus()
  const isTurn = useIsTurn()
  const isIntendedAction = useIntendedActionName() === ALL_IN
  const declareAllIn = useDeclareAllIn()
  const shouldConfirmAllIn = useIsConfirmAllIn() && isTurn
  const [isShowConfirm, setShowConfirm] = useState(false)

  useEffect(() => {
    setShowConfirm(false)
  }, [setShowConfirm, status])

  useEffect(() => {
    if (!isTurn) setShowConfirm(false)
  }, [isTurn, setShowConfirm])

  const handleClick = useCallback(() => {
    if (shouldConfirmAllIn && !isShowConfirm) {
      setShowConfirm(true)

      return
    }

    setShowConfirm(false)
    declareAllIn()
  }, [declareAllIn, isShowConfirm, setShowConfirm, shouldConfirmAllIn])

  return <ActionButton className={styles.allIn} onClick={handleClick} isIntendedAction={isIntendedAction}>
    {shouldConfirmAllIn && isShowConfirm ? 'Confirm?' : 'All In'}
  </ActionButton>
}

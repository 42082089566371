import Mixmaster from '../../mixmaster.coffee'
import WclEnum from '../../../enum/index.coffee'

import DefaultMixin from './mixins/defaultMixin.coffee'

export default class GetTournamentDetailsTranslator extends Mixmaster
  mixins = [
    DefaultMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal
    signal.tournamentDetails = {
      gameDetails: {}
      entryPayments: []
      prizes: []
      breaks: []
      tables: []
    }

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  game: (signal, attributes) ->

    if attributes.lateRegistrationOptions
      opts = attributes.lateRegistrationOptions.split '~'
      attributes.lateRegistrationOptions =
        stopAfterElimination: opts[0].toLowerCase() is 'true'
        stopAfterMinutes: parseInt(opts[1], 10)

    signal.tournamentDetails.gameDetails =
      description: attributes.description # "Holdem Fixed Limit Tournament"
      gameName: attributes.gameName # "Mouse Room"
      gameState: attributes.gameState
      gameType: parseInt(attributes.gameType,10) # "23"
      gameTypeId: parseInt(attributes.gameTypeId,10) # "6"
      gameTypeDesc: WclEnum.getGameTypeDesc(parseInt(attributes.gameType, 10)) # note note gameTypeId
      instanceId: parseInt(attributes.instanceId,10) # "7871595"
      limitType: WclEnum.getLimitType(attributes.limitType) # "FIXED_LIMIT"
      limitTypeDesc: WclEnum.getLimitTypeDesc(attributes.limitType)
      maxPlayers: parseInt(attributes.maxPlayers,10) # "10"
      maxPlayersPerTable: parseInt(attributes.maxPlayersPerTable,10) # "10"
      minPlayers: parseInt(attributes.minPlayers,10) # "10"
      minPrizePool: parseFloat(attributes.minPrizePool) # "0.00"
      multipleEntry: if parseInt(attributes.multipleEntry,10) then true else false # "0"
      numPlayers: parseInt(attributes.numPlayers,10) # "0"
      numRegistered: parseInt(attributes.numRegistered,10) # "10"
      passwordRequired: attributes.passwordRequired
      prizePool: parseFloat(attributes.prizePool) # "20.00"
      serverId: parseInt(attributes.serverId,10) # "161"

      accoladeId: parseInt(attributes.accoladeId, 10)
      addonAmount: parseFloat(attributes.addonAmount) # "100.00"
      addonChips: parseFloat(attributes.addonChips) # "1500.00"
      addPrizePoolToBuyins: attributes.addPrizePoolToBuyins # false
      addonCount: parseInt(attributes.addonCount, 10) # "0"
      addons: parseInt(attributes.addons, 10) # "0"
      advancingPlayers: parseInt(attributes.advancingPlayers, 10) # "0"
      bountyAmount: parseFloat(attributes.bountyAmount) # "0.00"
      bountyPrizePool: parseFloat(attributes.bountyPrizePool) # "0.00"
      buyIn: parseFloat(attributes.buyIn) # "100.00"
      currentBreakStopTime: @parseDate(attributes.currentBreakStopTime) # "null"
      eligibilityRequired: attributes.eligibilityRequired
      endTime: @parseDate(attributes.endTime)
      entryFee: parseFloat(attributes.entryFee) # "4.50"
      gameNumber: attributes.gameNumber # "1"
      handsUntilNextLevel: attributes.handsUntilNextLevel # "null"
      lateRegistrationOptions: attributes.lateRegistrationOptions # "null"
      levelId: parseInt(attributes.levelId, 10) # "2"
      levelIncrement: attributes.levelIncrement # "10 Hands"
      listType: parseInt(attributes.listType, 10) # "18"
      nextBreakStartTime: @parseDate(attributes.nextBreakStartTime) # "null"
      nextLevelStartTime: @parseDate(attributes.nextLevelStartTime) # "null"
      noDeregister: attributes.noDeregister
      privateGame: attributes.privateGame
      rebuyAmount: parseFloat(attributes.rebuyAmount) # "100.00"
      rebuyChips: parseFloat(attributes.rebuyChips) # "1500.00"
      rebuyCount: parseInt(attributes.rebuyCount, 10) # "0"
      rebuys: parseInt(attributes.rebuys, 10) # "0"
      registerDelay: parseFloat(attributes.registerDelay) # "0"
      registrationRequired: attributes.registrationRequired
      registrationStartTime: @parseDate(attributes.registrationStartTime) # "null"
      registrationStopTime: @parseDate(attributes.registrationStopTime) # "null"
      satelliteData: attributes.satelliteData # "0~~~0~~"
      shootoutRoundNumber: parseInt(attributes.shootoutRoundNumber, 10) # "0"
      shootoutTypeId: parseInt(attributes.shootoutTypeId, 10) # "0"
      startingChips: parseFloat(attributes.startingChips) # "1500.00"
      startTime: @parseDate(attributes.startTime)
      tournamentStatus: attributes.tournamentStatus # "null"

      isSng: !Boolean(attributes.registrationStartTime)
      isMtt: Boolean(attributes.registrationStartTime)

    signal.tournamentDetails.entryPayments = attributes.entryPayments
    signal.tournamentDetails.prizes = attributes.prizeList
    signal.tournamentDetails.satelliteInfo = attributes.tournamentSatelliteInfo

    signal.tournamentDetails.tables = []
    for table in attributes.tables
      signal.tournamentDetails.tables.push
        number: table.tableNumber
        instanceId: table.gameInstanceID
        serverId: table.gameServerID
        gameTypeId: table.gameTypeID
        averageStack: table.averageStack
        highStack: table.highStack
        lowStack: table.lowStack
        numPlayers: table.numPlayers
        tournamentId: table.tournamentGameInstanceID

    # todo: these are not nearly as good as they used to be (see below)
    signal.tournamentDetails.currentLevel = attributes.currentLevel
    signal.tournamentDetails.nextLevel = attributes.nextLevel

  tournamentLevel: (signal, attributes)->
    key = attributes.type + 'Level' # currentLevel / nextLevel
    signal.tournamentDetails[key] =
     level: parseInt(attributes.level, 10) # "1"
     levelFormat: attributes.levelFormat # "BLIND_NLPL"
     ante: parseFloat(attributes.ante) # "-1.00"
     smallBlind: parseFloat(attributes.smallBlind) # "10.00"
     bigBlind: parseFloat(attributes.bigBlind) # "15.00"
     minBet: parseFloat attributes.minBet # ""
     maxBet: parseFloat attributes.maxBet # ""
     studAnte: attributes.studAnte # ""
     studBringIn: attributes.studBringIn # ""

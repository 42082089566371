import React, {useMemo} from 'react'

import TableListingRow from './TableListingRow.js'
import {useRootedSelector} from '../../redux.js'
import {createGetTables, getRoot} from '../index.js'

export default function TableListing (props) {
  const tables = useRootedSelector(getRoot, useMemo(createGetTables, []), props)

  const sortedTables = useMemo(() => {
    return [...tables].sort((left, right) => left.number - right.number)
  }, [tables])

  return <table className="plainTable scrollable">
    <thead>
      <tr>
        <th>Table</th>
        <th>Players</th>
        <th>High</th>
        <th>Low</th>
      </tr>
    </thead>
    <tbody>
      {sortedTables.map(table => <TableListingRow key={table.tableId} table={table} />)}
    </tbody>
  </table>
}

import Model from './model.coffee'

export default class Timebank extends Model

  constructor: ({details}) ->
    super()

    @properties =
      balance: details.balance ?= 0
      selected: details.selected ?= false # should use timebank when possible
      active: details.active ?= false     # timebank is currently being used (counting down)
      minimum: details.minimum ?= 2
      enabled: details.enabled ?= true    # possible to use timebank (can be disabled entirely for a game)
      # available: ?

    @initializeModel arguments...

import Request from './request.coffee'

export default class TimeBankRequest extends Request
  constructor: ({client, message, selected}) ->
    super arguments...

    @function = 'TIME_BANK_SELECTED'

    @attributes =
      type: 'REQUEST'
      service: 'GAME'
      routerId: message.routerId
      serverId: client.serverId
      instanceId: client.instanceId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @rawArguments = [
      {
        name: 'TIME_BANK_SELECTED'
        'code1': selected.toString().toUpperCase()
      }
    ]

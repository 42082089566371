import React from 'react'

import Group from './Group.js'
import PreferredSeating from './PreferredSeating.js'

export default function PreferredSeatingGroup () {
  return <Group heading='Preferred Seating'>
    <PreferredSeating label='Heads Up' seats={2} />
    <PreferredSeating label='4-Max' seats={4} />
    <PreferredSeating label='6-Max' seats={6} />
    <PreferredSeating label='8-Max' seats={8} />
    <PreferredSeating label='Full Ring' seats={9} />
    <PreferredSeating label='10-Max' seats={10} />
  </Group>
}

import View from './view.coffee'

export default class StatusMessageView extends View
  constructor: (@model) ->
    super()

    @dom = $ '<div class="status-message">'

    @components =
      wrapper: $ '<span>'

    @initializeView()
    return this

  render: ->
    @dom.append @components.wrapper
    @dom

  title: (title) =>
    @components.wrapper.find('.title').remove()
    @components.wrapper.prepend("<div class='title'>#{title}</div>") if title
    @hideWhenEmpty()

  reason: (reason) =>
    @components.wrapper.find('.reason').remove()
    @components.wrapper.append("<div class='reason'>#{reason}</div>") if reason
    @hideWhenEmpty()

  message: (message) =>
    @components.wrapper.find('.message').remove()
    @components.wrapper.append("<div class='message'>#{message}</div>") if message
    @hideWhenEmpty()

  hideWhenEmpty: => !!@components.wrapper.text() and @dom.show() or @dom.hide()

import classnames from 'classnames'
import React, {useCallback, useState} from 'react'

import styles from './DesktopClock.module.css'
import {getRoot, getUpcomingTournament} from '../../own-games/index.js'
import {useRootedSelector} from '../../redux.js'
import {useClock} from '../../time/clock.js'
import {describeTime, format24HourTime} from '../../time/index.js'
import {TEN_SECONDS, THREE_SECONDS} from '../../time/time-amount.js'
import {useInterval} from '../../time/timer.js'

export default function DesktopClock () {
  const clock = useClock()
  const [now, setNow] = useState(clock.now())
  const [isShowingTooltip, setIsShowingTooltip] = useState(false)
  const upcomingTournament = useRootedSelector(getRoot, getUpcomingTournament)

  const upcomingDetails = (() => {
    if (!upcomingTournament || !upcomingTournament.startTime) return ''

    return `${upcomingTournament.name} starts ${describeTime(clock, new Date(upcomingTournament.startTime))}`
  })()

  const handleClick = useCallback(() => {
    if (!upcomingDetails) return

    setIsShowingTooltip(true)

    setTimeout(() => {
      setIsShowingTooltip(false)
    }, THREE_SECONDS)
  }, [setIsShowingTooltip, upcomingDetails])

  useInterval(TEN_SECONDS, () => {
    setNow(clock.now())
  })

  const className = classnames(styles.clock, {
    [styles.showTooltip]: isShowingTooltip,
    [styles.tooltip]: upcomingDetails,
  })

  return <div className={className} onClick={handleClick} title={upcomingDetails}>
    <span className={classnames(styles.icon, 'fa', upcomingTournament ? 'fa-bell-o' : 'fa-clock-o')} />
    {' '}
    <time dateTime={now} title={now}>{format24HourTime(now)}</time>
  </div>
}

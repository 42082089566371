import React from 'react'

import DesktopSelectionButton from './DesktopSelectionButton.js'
import {useGameFormat, useSetGameFormat} from '../react-hooks.js'

export default function DesktopGameFormatButton (props) {
  const {label, gameFormat} = props
  const isSelected = useGameFormat() === gameFormat

  return <DesktopSelectionButton label={label} isSelected={isSelected} onClick={useSetGameFormat(gameFormat)} />
}

import React from 'react'

import LabelRow from './LabelRow.js'
import Checkbox from '../../components/Checkbox.js'
import {useIsConfirmAllIn, useToggleConfirmAllIn} from '../react-hooks.js'

export default function ConfirmAllIn () {
  return <LabelRow name='Confirm "All In"'>
    <Checkbox checked={useIsConfirmAllIn()} onChange={useToggleConfirmAllIn()} size='small' />
  </LabelRow>
}

# SeatsView
# Model: game

import range from 'lodash/range'

import EmptySeatView from './emptySeatView.coffee'
import View from '../../view.coffee'

export default class SeatsView extends View

  constructor: (game, client) ->
    super()

    @model = game
    @client = client

    @seatViews = {}

    @reserved = []

    @dom = Webclient.ViewManager.returnFormatElement
      desktop: '<div id="seats" class="seats seats-desktop">'
      tablet:  '<div id="seats" class="seats">'
      mobile:  '<div id="seats" class="seats">'

    # ### Components - eg. DOM elements & views

    # ### Events - eg. MouseClicks ###

    # ### Listeners - eg. auth:success
    @listeners =
      'reservation'   : @addReservation
      'unreservation' : @removeReservation

    @model.players.on 'add', @addPlayer
    @model.players.on 'rem', @remPlayer

    @clientChanges =
      seated: =>
        @redrawSeats()
        # @hideIfSeated()

    # ### Changes - eg. Model.property = true
    # @changes =

    # ### Binding ###
    @initializeView()
    return this


  render: =>
    @appendComponents()
    @setPlayers(@model.maxPlayers)
    @populateSeats()
    @model.players.each @addPlayer
    return @dom

  redrawSeats: =>
    @reserved = [] # empty each time
    for position, seat of @seatViews
      if seat.reserved
        @reserved.push(position)
      @remSeat(position)
    @populateSeats()
    for position in @reserved
      @seatViews[position].reserve()
    @model.players.each (player) =>
      @remSeat player.position

  hideIfSeated: =>
    for _, seat of @seatViews
      if @client?.seated
        seat.hide()
      else
        seat.show()

  addPlayer: (player) =>
    @remSeat player.position

  remPlayer: (player) =>
    @addSeat player.position

  addSeat: (position) =>
    @seatViews[position] = view = new EmptySeatView @client, position, @getViewPosition(position)
    @dom.append view.render()
    @hideIfSeated()

  remSeat: (position) =>
    @seatViews[position]?.destroy()
    delete @seatViews[position]

  hideSeat: (position) =>
    @seatViews[position].hide()

  showSeat: (position) =>
    @seatViews[position].show()

  setPlayers: (number) =>
    @dom.addClass 'seats'+number

  populateSeats: (emptySeats) =>
    emptySeats ?= range(@model.maxPlayers)
    for position in emptySeats
      @addSeat(position)

  # These use seat.position because posStr is a string
  addReservation: (position) =>
    @seatViews[position].show()
    @seatViews[position].reserve()

  removeReservation: (position) =>
    @seatViews[position].unreserve()
    @hideIfSeated()

  getViewPosition: (position) =>
    viewPosition = @client.calcPrefSeat(position) # this returns null if client is not seated or pref seating is off
    if viewPosition?
      return viewPosition
    else
      return position

import React, {useMemo} from 'react'

import {useRootedSelector} from '../../../redux.js'
import {createGetSeatCount, getRoot} from '../../index.js'

export default function SeatsPerTable (props) {
  const count = useRootedSelector(getRoot, useMemo(createGetSeatCount, []), props)

  return <div className='kvpair selfclear break'>
    <label>Seats Per Table:</label><span>{count}</span>
  </div>
}

import Mixmaster from '../../../mixmaster.coffee'

export default class BreakData extends Mixmaster
  mixin: (object) ->
    object.breakLength = (signal, name, value, node) ->
      signal.updates.startBreak ?= {}
      signal.updates.startBreak.endTime = parseInt(value.breakEndTime, 10)
      signal.updates.startBreak.minutes = parseInt(value.breakMinutes, 10)

    object.addOnAllowed = (signal, name, value, node) ->
      signal.updates.startBreak ?= {}
      signal.updates.startBreak.addOnAllowed = value

    object.imReadyAllowed = (signal, name, value, node) ->
      signal.updates.startBreak ?= {}
      signal.updates.startBreak.imReadyAllowed = value

    object.timerValue = (signal, name, value, node) ->
      signal.updates.stopBreak ?= {}
      signal.updates.stopBreak.timerValue = parseFloat value.gameTimer

import React, {useMemo} from 'react'

import Money from '../../../money/components/Money.js'
import {useRootedSelector} from '../../../redux.js'
import {createGetStackStats, getRoot} from '../../index.js'

export default function StackStats (props) {
  const {
    averageStack,
    biggestStack,
    smallestStack,
    totalStack,
  } = useRootedSelector(getRoot, useMemo(createGetStackStats, []), props)

  return <>
    <div className='kvpair selfclear'>
      <label>Total Chips in Play:</label><span>{totalStack ? <Money value={totalStack} /> : '0'}</span>
    </div>
    <div className='kvpair selfclear'>
      <label>Largest Stack:</label><span>{biggestStack ? <Money value={biggestStack} /> : '0'}</span>
    </div>
    <div className='kvpair selfclear'>
      <label>Average Stack:</label><span>{averageStack ? <Money value={averageStack} /> : '0'}</span>
    </div>
    <div className='kvpair selfclear'>
      <label>Smallest Stack:</label><span>{smallestStack ? <Money value={smallestStack} /> : '0'}</span>
    </div>
  </>
}

import React from 'react'

import ButtonGroup from './ButtonGroup.js'
import SngFilterButton from './SngFilterButton.js'
import {DESKTOP} from '../../device/constants.js'
import {useFormat} from '../../device/react-hooks.js'
import {SNG_FILTER} from '../service.js'

const {
  NO_LIMIT_TEXAS_HOLDEM_6_MAX_HYPER_TURBO,
  NO_LIMIT_TEXAS_HOLDEM_6_MAX_TURBO,
  NO_LIMIT_TEXAS_HOLDEM_DOUBLE_UP,
  NO_LIMIT_TEXAS_HOLDEM_HEADS_UP_HYPER_TURBO,
} = SNG_FILTER

export default function SngFilterSelection () {
  const isDesktop = useFormat() === DESKTOP

  return <ButtonGroup label='Game' number={isDesktop ? '2' : '1'}>
    <SngFilterButton label='NLHE 6-Max Turbo' filter={NO_LIMIT_TEXAS_HOLDEM_6_MAX_TURBO} />
    <SngFilterButton label='NLHE Double or Nothing' filter={NO_LIMIT_TEXAS_HOLDEM_DOUBLE_UP} />
    <SngFilterButton label='NLHE Heads Up Hyper Turbo' filter={NO_LIMIT_TEXAS_HOLDEM_HEADS_UP_HYPER_TURBO} />
    <SngFilterButton label='NLHE 6-Max Hyper Turbo' filter={NO_LIMIT_TEXAS_HOLDEM_6_MAX_HYPER_TURBO} />
  </ButtonGroup>
}

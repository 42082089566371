import React, {useMemo} from 'react'

import PrizeListingRow from './PrizeListingRow.js'
import {useRootedSelector} from '../../redux.js'
import {createGetPlacePrizes, getRoot} from '../index.js'

export default function PrizeListing (props) {
  const placePrizes = useRootedSelector(getRoot, useMemo(createGetPlacePrizes, []), props)

  return <table className="plainTable scrollable">
    <thead>
      <tr>
        <th>Position</th>
        <th>Prize</th>
      </tr>
    </thead>
    <tbody>
      {placePrizes.map(({place, prize}) => <PrizeListingRow key={place} place={place} prize={prize} />)}
    </tbody>
  </table>
}

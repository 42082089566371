import React, {useCallback} from 'react'

import ActionButton from '../../components/ActionButton.js'
import {useCanSitIn, useShowAddChips, useSitIn, useSitInOnRebuy} from '../../react-hooks.js'

export default function SitInButton () {
  const addChips = useShowAddChips()
  const sitIn = useSitIn()
  const sitInOnRebuy = useSitInOnRebuy()
  const canSitIn = useCanSitIn()

  const handleClick = useCallback(() => {
    if (canSitIn) {
      sitIn()

      return
    }

    sitInOnRebuy()
    addChips()
  }, [addChips, canSitIn, sitIn, sitInOnRebuy])

  return <ActionButton onClick={handleClick}>Sit In</ActionButton>
}

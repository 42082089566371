import React from 'react'

import LabelRow from './LabelRow.js'
import Checkbox from '../../components/Checkbox.js'
import {useIsFourColorDeck, useToggleFourColorDeck} from '../react-hooks.js'

export default function FourColorDeck () {
  return <LabelRow name='4 Color Deck'>
    <Checkbox checked={useIsFourColorDeck()} onChange={useToggleFourColorDeck()} size='small' />
  </LabelRow>
}

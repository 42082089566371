import React from 'react'

import styles from './Felt.module.css'

export default function Felt () {
  return <div className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.background} />
    </div>
  </div>
}

# ## PreviewView (what a horrible name)

import SimpleCardView from './game/simpleCardView.coffee'
import View from './view.coffee'

export default class PreviewView extends View
  constructor: (client) ->
    super()

    @model = client

    @dom = $ '<div class="preview-table">'

    @components =
      felt:          $ '<div class="felt">'
      cards:         $ '<div class="cards">'
      timer:         $ '<div class="timer">'

    @dom.on 'click', @join

    @changes =
      cssClass: @updateCssClass
      current: @updateCurrent
      cards: @updateCards
      timer: @updateTimer
      seated: (seated) =>
        if not seated
          @updateCards()

    @initializeView()
    return this

  render: =>
    @appendComponents()
    @components.timer.appendTo @components.felt
    return @dom

  updateCards: (cards) =>
    @components.cards.empty()
    unless cards?.length in [2, 4]
      # console.warn 'previewView unsupported number of cards. ignoring.', cards
      return
    @components.cards.removeClass 'cards-2 cards-4'
    @components.cards.addClass 'cards-' + cards.length
    for card in cards then do (card) =>
      cardView = new SimpleCardView card
      cardView.lockFlip = true
      @components.cards.append cardView.render()
      @model.player.once 'change:cards', cardView.destroy

  updateTimer: =>
    # Reset any previous animations, quirk note: the setTimeout allows the animation to clear
    @components.timer.removeAttr 'style'
    value = @model.player.timer # seconds
    if value
      @resetStatus()
    else
      return
    # Client player only sees their own timer count down in the final 5 seconds
    # Future: They also receive the "timeout" noise during the final 5 seconds
    # if @model.isClient and value >= 6
    if value >= 6
      # do client player blink for 15secs or part thereof, then 5 second timer bar
      blink = value - 5
      @animationTimeout = setTimeout =>
        template = (engine) -> "#{ engine }animation: 1s linear 0s normal none #{ blink } blink, timer 5s linear #{ blink }s 1 normal; "
        animation = template('-webkit-') + template('-moz-') + template('')
        @components.timer.attr 'style', animation
      , 10
      # @timeoutSound = setTimeout (=> @model.playSound('timeout')), blink * 1000
    else
      # Other players' timers count down continually
      @animationTimeout = setTimeout =>
        template = (engine) -> "#{ engine }animation: timer #{ value }s linear 0s 1 normal; "
        animation = template('-webkit-') + template('-moz-') + template('')
        @components.timer.attr 'style', animation
      , 10

  join: (e) =>
    e?.stopPropagation()
    Navigator.navTable @model.game

  updateCurrent: =>
    @dom.toggleClass 'current', @model.current

  resetStatus: =>
    clearTimeout(@animationTimeout)
    @dom.removeClass 'fold bet call check raise allIn show'
    @components.timer.removeAttr 'style'

  updateCssClass: =>
    @resetStatus()
    @dom.addClass @model.cssClass

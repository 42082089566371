import {useContext, useEffect, useState} from 'react'

import {context} from '../react.js'

export function useHide () {
  return useService().hide
}

export function useToggle () {
  return useService().toggle
}

export function useIsOpen () {
  const {isOpen, subscribe} = useService()
  const [reactValue, setReactValue] = useState(isOpen)

  useEffect(() => subscribe(setReactValue), [subscribe])

  return reactValue
}

function useService () {
  return useContext(context).menuService
}

import Model from './model.coffee'

export default class Card extends Model

  constructor: (details = {}) ->
    super()

    @properties =
      rank: details.rank ?= {handle: 'back', index: 13, code: 'b'}
      suit: details.suit ?= {handle: 'back', index: 4, code: 'b'}
      facedown: details.facedown ?= true
      show: details.show ?= false  # i.e. whether to "show" at end of hand
      flippable: details.flippable ?= false

    @initializeModel arguments...

  toJavaString: =>
    return "#{@rank.code.toUpperCase()}#{@suit.code}"

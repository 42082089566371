# MyGameListData
#   long instanceId
#   int serverId
#   int gameTypeId
#   long gameNumber
#   String status
#   String gameName

# { instanceId: '7528856',
#   serverId: '162',
#   gameTypeId: '2',
#   gameName: 'Statue of Liberty III',
#   gameNumber: '0',
#   status: 'P' }
import Mixmaster from '../../../mixmaster.coffee'
import {isTypeIdSupported} from '../../../../../game-type.js'

export default class MyGameListData extends Mixmaster
  mixin: (object) ->
    object.game = (signal, game, node) ->
      return unless isTypeIdSupported(game.gameTypeId)

      statusMap =
        'P': 'Playing'
        'W': 'Watching'
        'A': 'Waiting'
        'R': 'Reserved'
      playerStatus = statusMap[game.status] or game.status # Tourneys set this to "Registering"

      signal.games.push {
        instanceId: parseInt(game.instanceId, 10)
        serverId: parseInt(game.serverId, 10)
        gameTypeId: parseInt(game.gameTypeId, 10)
        gameName: game.gameName
        gameNumber: parseInt(game.gameNumber, 10)
        playerStatus: playerStatus
        tournamentInstanceId: parseInt(game.tournamentInstanceId, 10)
        isSitNGo: game.isSitNGo
        mttTable: game.tournamentInstanceId isnt 0 and game.isSitNGo is false
      }

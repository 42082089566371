import {EventEmitter} from 'events'
import {v4 as uuidv4} from 'uuid'

export default class Model extends EventEmitter
  initializeModel: (args = {}) ->
    @properties ?= args # shortcut for loading data: new Model { name: 'Fry' }

    id = args.id or uuidv4()
    Object.defineProperty this, 'id', value: id

    for key, _ of @properties then do (key) =>
      Object.defineProperty this, key,
        enumerable: yes
        get: => @properties[key]
        set: (value) =>
          return if value is @properties[key]
          @properties[key] = value
          @emit 'change', key, value
          @emit 'change:' + key, value  # backbone style

  # returns plain old hash of properties (current values). good for debugging
  toJSON: =>
    object = {}
    for key, _ of @properties
      object[key] = @[key]  # use getter
    return object

import {fromNumber, toNumber} from '@pipehat/money/plain-object'
import React, {useCallback} from 'react'

import Stepper from '../../components/Stepper.js'
import {fractionDigits} from '../rendering.js'

export default function MoneyStepper (props) {
  const {className, min, max, onChange, onInvalid, slider, step, value} = props
  const currency = value.currencyCode

  const handleChange = useCallback(number => {
    onChange(fromNumber(currency, number))
  }, [currency, onChange])

  return <Stepper
    className={className}
    slider={slider}
    digits={fractionDigits(currency)}
    min={toNumber(min)}
    max={toNumber(max)}
    step={toNumber(step)}
    value={toNumber(value)}
    onChange={handleChange}
    onInvalid={onInvalid}
  />
}

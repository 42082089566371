export function createService (app, filter) {
  const mobileSortCriteriaSubscribers = new Set()
  const lobbySidebarUrlSubscribers = new Set()

  filter.on('change:menuSort', dispatchMobileSortCriteria)
  app.on('change:lobbySidebarUrl', dispatchLobbySidebarUrl)

  return {
    get mobileSortCriteria () { return filter.menuSort }, // yes, this is leaky - should be refactored with the lobby rewrite

    get lobbySidebarUrl () { return app.lobbySidebarUrl },

    setMobileSortCriteria (criteria) {
      filter.menuSort = criteria
      filter.filter()
    },

    setLobbySidebarUrl (url) {
      app.lobbySidebarUrl = url
    },

    subscribeToMobileSortCriteria (subscriber) {
      mobileSortCriteriaSubscribers.add(subscriber)

      return function unsubscribe () {
        mobileSortCriteriaSubscribers.delete(subscriber)
      }
    },

    subscribeToLobbySidebarUrl (subscriber) {
      lobbySidebarUrlSubscribers.add(subscriber)

      return function unsubscribe () {
        lobbySidebarUrlSubscribers.delete(subscriber)
      }
    },
  }

  function dispatchMobileSortCriteria () {
    for (const subscriber of mobileSortCriteriaSubscribers) subscriber(filter.menuSort)
  }

  function dispatchLobbySidebarUrl () {
    for (const subscriber of lobbySidebarUrlSubscribers) subscriber(app.lobbySidebarUrl)
  }
}

import {EventEmitter} from 'events'
import key from 'hotkeys-js'

import ModalView from './modalView.coffee'

# All views -SHOULD- inherit from this superclass.
export default class View extends EventEmitter
  constructor: () ->
    super ...arguments

  # append: whether to append the components to the dom
  initializeView: ->
    @source = @collection or @model

    unless @source
      throw new Error 'view does not have a source'

    # If you want the view to execute a function on an event emitted from
    # the source, create a @listeners object on the view and give it properties
    # in the form of
    # `@listeners =
    #   eventName: (whatYouWant) -> ...`
    @listeners ?= {}
    for eventName, fn of @listeners
      @source.on eventName, fn

    # If the source has a property that emits a `change` event when it is set
    # then you can simply add a property to the view in the form of
    # `key: (key, value, source, view) -> ...`
    #
    # As a style guide, and to help separate what should and shouldn't be done
    # based on source changes; changes should only affect the display of
    # component elements.
    @changes ?= {}
    for k, changeFn of @changes then do (k, changeFn) =>
      @source.on "change:#{k}", changeFn

    # If the client model has a property that emits a `change` event when it is set
    # then you can simply add a property to the view in the form of
    # `key: (key, value, source, view) -> ...`
    #
    # As a style guide, and to help separate what should and shouldn't be done
    # based on client model changes; changes should only affect the display of
    # component elements.
    @clientChanges ?= {}
    if @client?
      for k, changeFn of @clientChanges then do (k, changeFn) =>
        @client.on "change:#{k}", changeFn

    # If the game model has a property that emits a `change` event when it is set
    # then you can simply add a property to the view in the form of
    # `key: (key, value, source, view) -> ...`
    #
    # As a style guide, and to help separate what should and shouldn't be done
    # based on game model changes; changes should only affect the display of
    # component elements.
    @gameChanges ?= {}
    if @game?
      for k, changeFn of @gameChanges then do (k, changeFn) =>
        @game.on "change:#{k}", changeFn

    # Keyboard shortcuts
    # usage:
    #  @keyScope = 'game'
    #  @keys = 'enter': @submit, 'escape': @cancel, ...
    @keyScope ?= ''
    @keys ?= []
    for code, action of @keys then do (code, action) =>
      key code, @keyScope, =>
        action()
        return

    # Simple bindings maps component[k] to model[k] value. Works for div|span (text) or input (val)
    # Reduce the need for stupidly repetitive handlers
    # Usage: @simpleBindings = ['name', 'height']
    if @simpleBindings?.length
      for k in @simpleBindings then do (k) =>
        if @components[k]
          if @components[k].is 'input[type="checkbox"]'
            @components[k].prop 'checked', @model[k]  # default value
            @components[k].on 'change', => @model[k] = @components[k].prop('checked')  # input -> model
            @model.on 'change:' + k, => @components[k].prop 'checked', @model[k]  # model -> input
          else if @components[k].is 'input,select'
            @components[k].val @model[k]  # default value
            @components[k].on 'change', => @model[k] = @components[k].val()  # input -> model
            @model.on 'change:' + k, => @components[k].val @model[k]  # model -> input
          else if @components[k].is 'div,span,h1'
            @components[k].text @model[k]  # default value
            @model.on 'change:' + k, => @components[k].text @model[k]  # model -> div

    @intervals ?= []

    @source.once 'destroy', @destroy

  appendComponents: =>
    appendComponents @components, @dom

  # default render. overwrite as required
  render: =>
    @dom.empty()
    @appendComponents()
    @visible = true
    return @dom

  destroy: =>
    @emit 'destroy'
    @dom.remove()

    toDestroy = if isPojo @components then Object.values @components else []
    while toDestroy.length > 0
      el = toDestroy.pop()
      continue unless el
      if typeof el.destroy is 'function'
        el.destroy()
      else if isPojo el
        toDestroy.push ...Object.values el

    for eventName, fn of @listeners
      @source.removeListener eventName, fn
    for k, changeFn of @changes then do (k, changeFn) =>
      @source.removeListener "change:#{k}", changeFn
    if @client?
      for k, changeFn of @clientChanges then do (k, changeFn) =>
        @client.removeListener "change:#{k}", changeFn
    if @game?
      for k, changeFn of @gameChanges then do (k, changeFn) =>
        @game.removeListener "change:#{k}", changeFn
    for code, _ of @keys
      key.unbind code, @keyScope
    for interval in @intervals
      clearInterval interval
    @source.removeListener 'destroy', @destroy
    # todo: if simpleBindings are ever actually used (for non-dev purposes), we need to unbind here
    delete this

  clearIntervals: =>
    for interval in @intervals
      clearInterval interval
    @intervals = []

  # default show/hide/toggle dom. overwrite as required
  show: =>
    @visible = true
    @dom.show()
  hide: =>
    @visible = false
    @dom.hide()
  toggle: =>
    @dom.toggle arguments...
    @visible = @dom.is(":visible")

  # helper for any view to quickly show a modal
  modal: ->
    modalView = new ModalView arguments...
    modalView.attachToBody()
    return modalView

  addClass: (className) =>
    @dom.addClass className

# helper function to recursively append nested components
appendComponents = (components, dom) ->
  for name, el of components
    if el.render?
      # view
      dom.append el.render()
    else if el instanceof jQuery
      # regular jQuery component
      dom.append el
    else if isPojo el
      # nested components
      dom.append appendComponents el, $ "<div class='pane pane__#{name}'>"
    else
      # just a string (hopefully)
      dom.append el
  return dom

isPojo = (value) ->
  return false unless value
  return false unless typeof value is 'object'

  return Object.prototype is Object.getPrototypeOf value

import React from 'react'

import {useWindowTitle} from '../../global/react-hooks.js'
import Selection from '../components/Selection.js'

const windowTitle = 'Quick Seat'

export default function QuickSeat () {
  useWindowTitle(windowTitle)

  return <Selection />
}

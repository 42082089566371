import classnames from 'classnames'
import React, {useCallback, useContext, useEffect, useState} from 'react'

import styles from './Radio.module.css'
import SvgRadio from './Radio.module.svg'
import {RadioGroupContext} from './RadioGroup.js'

export default function Radio (props) {
  const {className, isDisabled, value} = props
  const {currentValue, setValue} = useContext(RadioGroupContext)
  const [isChecked, setIsChecked] = useState(currentValue === value)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    setIsChecked(currentValue === value)
  }, [currentValue, value])

  const handleChange = useCallback(() => { setValue(value) }, [setValue, value])
  const handleOnBlur = useCallback(() => { setIsFocused(false) }, [])
  const handleOnFocus = useCallback(() => { setIsFocused(true) }, [])

  const radioClassName = classnames(className, styles.radio, {
    [styles.disabled]: isDisabled,
    [styles.focused]: isFocused,
  })

  return <label className={radioClassName}>
    <input
      type='radio'
      value={value}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      checked={isChecked}
      disabled={isDisabled}
      onChange={handleChange}
      className={styles.nativeRadio}
    />
    <SvgRadio className={classnames(styles.svgRadio, {[styles.checked]: isChecked})} />
  </label>
}

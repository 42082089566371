import React from 'react'

import ActionsComponent from './components/Actions.js'
import {useIsSeated} from '../seats/react-hooks.js'

export default function Actions () {
  const isSeated = useIsSeated()

  if (!isSeated) return null

  return <ActionsComponent />
}

import React from 'react'

import Stakes from '../../../../money/components/Stakes.js'
import {renderLimitType} from '../../../../rendering.js'
import Row from '../../components/Row.js'

export default function StakesDescription (props) {
  const {stakes} = props
  const {limitType, maximumStake, minimumStake} = stakes

  return <Row>
    {renderLimitType(limitType)}{' '}
    <Stakes maximumStake={maximumStake} minimumStake={minimumStake} />
  </Row>
}

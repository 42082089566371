import {useContext, useEffect, useState} from 'react'

import {context} from '../react.js'
import {AUTO_REBUY_POINT_OFF} from '../rooms/constants.js'

export function useAuthorizeNotifications () {
  return useSettingsService().authorizeNotifications
}

export function useAutoRebuyPercentage () {
  return useCasinoSetting('autoRebuy').amount
}

export function useAutoRebuyPoint () {
  return useCasinoSetting('autoRebuy').point
}

export function useConfigurePopouts () {
  return useSettingsService().configurePopouts
}

export function useCheckFoldPreference () {
  return useCasinoSetting('checkFoldPreference')
}

export function useDefaultBuyInBigBlindMultiplier () {
  return useCasinoSetting('defaultBuyin')
}

export function useDisableAutoRebuy () {
  return useSettingsService().disableAutoRebuy
}

export function useDisablePopouts () {
  return useSettingsService().disablePopouts
}

export function useEnableAutoRebuyWhenBelowTableMinimum () {
  return useSettingsService().enableAutoRebuyWhenBelowTableMinimum
}

export function useEnableAutoRebuyWhenOutOfChips () {
  return useSettingsService().enableAutoRebuyWhenOutOfChips
}

export function useGameFilterSetting (key) {
  const {gameFilterSettings} = useContext(context).settingsService

  return useSetting(gameFilterSettings, key)
}

export function useNotificationMinutesBeforeTournamentStarts () {
  return useCasinoSetting('notificationBeforeGameStart')
}

export function useIsAuthorizedToShowNotifications () {
  return useCasinoSetting('notifications')
}

export function useIsAutoMuck () {
  return useCasinoSetting('autoMuck')
}

export function useIsAutoPostBlinds () {
  return useCasinoSetting('autoBlinds')
}

export function useIsAutoRebuy () {
  return useCasinoSetting('autoRebuy').point !== AUTO_REBUY_POINT_OFF
}

export function useIsAutoRebuyAtInitialBuyIn () {
  return useCasinoSetting('autoRebuy').initialBuyIn
}

export function useIsAutoTimebank () {
  return useCasinoSetting('autoTimebank')
}

export function useIsConfirmAllIn () {
  return useCasinoSetting('confirmAllIn')
}

export function useIsConfirmLeaveGame () {
  return useCasinoSetting('confirmLeaveGame')
}

export function useIsFourColorDeck () {
  return useCasinoSetting('fourColorDeck')
}

export function useIsOtherNotifications () {
  return useCasinoSetting('notificationPush')
}

export function useIsShowFastTableNotice () {
  return useCasinoSetting('showFastNotice')
}

export function useIsShowTournamentPositionNotice () {
  return useCasinoSetting('showTournamentPositionNotice')
}

export function useIsSoundsEnabled () {
  return useCasinoSetting('soundsEnabled')
}

export function useIsTournamentStartingNotifications () {
  return useCasinoSetting('notificationAtGameStart')
}

export function usePopoutConfiguration () {
  return useCasinoSetting('popup')
}

export function useResetCasinoSettings () {
  return useSettingsService().resetCasinoSettings
}

export function useSetAutoRebuyAtInitialBuyIn () {
  return useSettingsService().setAutoRebuyAtInitialBuyIn
}

export function useSetAutoRebuyPercentage () {
  return useSettingsService().setAutoRebuyPercentage
}

export function useSetCheckFoldPreference () {
  return useSettingsService().setCheckFoldPreference
}

export function useSetDefaultBuyInBigBlindMultiplier () {
  return useSettingsService().setDefaultBuyInBigBlindMultiplier
}

export function useSetNotificationMinutesBeforeTournamentStarts () {
  return useSettingsService().setNotificationMinutesBeforeTournamentStarts
}

export function useShowPreferredSeating () {
  return useSettingsService().showPreferredSeating
}

export function useSwitchFormat () {
  return useSettingsService().switchFormat
}

export function useToggleAutoMuck () {
  return useSettingsService().toggleAutoMuck
}

export function useToggleAutoPostBlinds () {
  return useSettingsService().toggleAutoPostBlinds
}

export function useToggleAutoTimebank () {
  return useSettingsService().toggleAutoTimebank
}

export function useToggleConfirmAllIn () {
  return useSettingsService().toggleConfirmAllIn
}

export function useToggleConfirmLeaveGame () {
  return useSettingsService().toggleConfirmLeaveGame
}

export function useToggleFourColorDeck () {
  return useSettingsService().toggleFourColorDeck
}

export function useToggleOtherNotifications () {
  return useSettingsService().toggleOtherNotifications
}

export function useToggleShowFastTableNotice () {
  return useSettingsService().toggleShowFastNotice
}

export function useToggleShowTournamentPositionNotice () {
  return useSettingsService().toggleShowTournamentPositionNotice
}

export function useToggleSoundsEnabled () {
  return useSettingsService().toggleSoundsEnabled
}

export function useToggleTournamentStartingNotifications () {
  return useSettingsService().toggleTournamentStartingNotifications
}

function useCasinoSetting (key) {
  const [value] = useSetting(useSettingsService().casinoSettings, key)

  return value
}

function useSettingsService () {
  return useContext(context).settingsService
}

function useSetting (settings, key) {
  const {get, set, subscribe} = settings
  const [reactValue, setReactValue] = useState(() => get(key))

  const setValue = value => { set(key, value) }

  useEffect(() => {
    return subscribe(key, value => {
      setReactValue(value)
    })
  }, [key, subscribe])

  return [reactValue, setValue]
}

export default ({signal, details, app, game, client, listing}) ->
  # todo: might be able to remove this check
  if client?

    if details.available?
      client.timebank.available = details.available # not used yet

    if details.balance?
      client.timebank.balance = details.balance
      client.timebank.active  = false

    if details.enabled?
      client.timebank.enabled = details.enabled

    if details.minimum?
      client.timebank.minimum = details.minimum

import React from 'react'

import MenuItem from './MenuItem.js'
import {useSignOut} from '../../auth/react-hooks.js'

export default function SignOutSubMenu () {
  return <>
    <div className='heading'>Logout</div>
    <MenuItem icon='logout' label='Logout' onClick={useSignOut()} />
  </>
}

import React from 'react'

import MobileGameFormatButton from './MobileGameFormatButton.js'
import styles from './QuickSeat.module.css'
import {GAME_FORMAT} from '../service.js'

const {RING_GAME, SNG} = GAME_FORMAT

export default function MobileGameFormatSelection () {
  return <div className={styles.mobileTabBar}>
    <MobileGameFormatButton gameFormat={RING_GAME} label='Cash Games' />
    <MobileGameFormatButton gameFormat={SNG} label='Sit & Go' />
  </div>
}

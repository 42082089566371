import Listing from '../../../models/listing.coffee'

export default ({signal, app}) ->
  tournament = app.listings.find instanceId: signal.parent.arguments.game_instance
  for details in signal.games
    listing = app.listings.find {instanceId: details.instanceId}

    unless listing?
      listing = new Listing {details}
      app.listings.add listing

    existing = tournament.parents.find instanceId: details.instanceId
    unless existing?
      tournament.parents.add listing

  app.showSatelliteFollowOnTournaments(tournament)

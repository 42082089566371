import classnames from 'classnames'
import React, {useCallback, useMemo} from 'react'

import Button from './Button.js'
import styles from './QuickSeat.module.css'
import Money from '../../money/components/Money.js'
import {useRootedSelector} from '../../redux.js'
import skeleton from '../../skeleton.module.css'
import {createGetSngFilterStakeCandidates, createGetSngFilterStakeCount, getRoot} from '../index.js'
import {useJoinSng, useSngFilter} from '../react-hooks.js'

export default function SngStakeButton (props) {
  const {stake} = props
  const [filter] = useSngFilter()

  const criteria = {filter, stake}
  const candidates = useRootedSelector(getRoot, useMemo(createGetSngFilterStakeCandidates, []), criteria)
  const count = useRootedSelector(getRoot, useMemo(createGetSngFilterStakeCount, []), criteria)

  const joinSng = useJoinSng()
  const handleClick = useCallback(() => {
    joinSng(candidates)
  }, [candidates, joinSng])

  return <Button type='button' onClick={handleClick} isStake>
    <span className={skeleton.light}><Money value={stake} /></span>
    <span className={classnames(styles.tableCount, skeleton.light)}>({count} active)</span>
  </Button>
}

import React from 'react'

import ButtonGroup from './ButtonGroup.js'
import GameFormatButton from './GameFormatButton.js'
import {GAME_FORMAT} from '../service.js'

const {RING_GAME, SNG} = GAME_FORMAT

export default function DesktopGameFormatSelection () {
  return <ButtonGroup number='1' label='Format'>
    <GameFormatButton label='Cash Games' gameFormat={RING_GAME} />
    <GameFormatButton label='Sit & Go' gameFormat={SNG} />
  </ButtonGroup>
}

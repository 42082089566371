import Request from './request.coffee'

export default class ReturnBlindRequest extends Request
  constructor: ({client, message}) ->
    super arguments...

    @function = 'RETURN_BLIND'

    @attributes =
      type: 'REQUEST'
      service: 'GAME'
      routerId: message.routerId
      serverId: client.serverId
      instanceId: client.instanceId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @rawArguments = [
      {
        name: 'RETURN_BLIND'
        'code1': 'TRUE'
      }
    ]

# PlayerBalanceData
#   String nickname
#   int position
#   BigDecimal balance
import Mixmaster from '../../../mixmaster.coffee'

export default class PlayerBalanceData extends Mixmaster
  mixin: (object) ->
    object.playerBalance = (signal, name, values) ->
      signal.updates.playerBalanceList = {}
      for balanceString in values.playerBalanceList.split '|'
        [position, alias, balance] = balanceString.split '^'
        signal.updates.playerBalanceList[position] = {
          position
          alias
          balance
        }

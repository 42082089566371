import React from 'react'

import RingGameStakesButton from './RingGameStakesButton.js'

const minimumStake = {currencyCode: 'USD', units: '5', nanos: 0}
const maximumStake = {currencyCode: 'USD', units: '100', nanos: 0}

export default function RingGameStakesButtonGroupSkeleton () {
  return <>
    <RingGameStakesButton minimumStake={minimumStake} maximumStake={maximumStake} />
    <RingGameStakesButton minimumStake={minimumStake} maximumStake={maximumStake} />
    <RingGameStakesButton minimumStake={minimumStake} maximumStake={maximumStake} />
    <RingGameStakesButton minimumStake={minimumStake} maximumStake={maximumStake} />
  </>
}

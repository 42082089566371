import {useContext, useEffect, useState} from 'react'

import {context} from '../react.js'

export function useMobileSortCriteria () {
  const {mobileSortCriteria, subscribeToMobileSortCriteria} = useService()
  const [reactValue, setReactValue] = useState(mobileSortCriteria)

  useEffect(() => {
    return subscribeToMobileSortCriteria(mobileSortCriteria => {
      setReactValue(mobileSortCriteria)
    })
  }, [subscribeToMobileSortCriteria])

  return reactValue
}

export function useLobbySidebarUrl () {
  const {lobbySidebarUrl, subscribeToLobbySidebarUrl} = useService()
  const [reactValue, setReactValue] = useState(lobbySidebarUrl)

  useEffect(() => {
    return subscribeToLobbySidebarUrl(lobbySidebarUrl => {
      setReactValue(lobbySidebarUrl)
    })
  }, [subscribeToLobbySidebarUrl])

  return reactValue
}

export function useSetLobbySidebarUrl () {
  return useService().setLobbySidebarUrl
}

export function useSetMobileSortCriteria () {
  return useService().setMobileSortCriteria
}

function useService () {
  return useContext(context).lobbyService
}

import {CHIPS, USD} from '@pipehat/chronicle/constants/currency'
import {fromPlainObject, toNumber} from '@pipehat/money'

export function fractionDigits (currency) {
  switch (currency) {
    case CHIPS: return 0
    case USD: return 2
  }

  return undefined
}

export function renderMoney (moneyObject) {
  const money = fromPlainObject(moneyObject)
  const {currency} = money
  const number = toNumber(money)

  switch (currency) {
    case CHIPS: return number.toLocaleString('en-US', {style: 'decimal', maximumFractionDigits: 0})

    case USD:
      // trim the cents only when they are 0
      return number.toLocaleString(
        'en-US',
        {style: 'currency', currency, minimumFractionDigits: Number.isInteger(number) ? 0 : undefined},
      )
  }

  return number.toLocaleString('en-US', {style: 'currency', currency})
}

// shortCurrency() and round() are both from legacy and stripped bare here
export function shortCurrency (amount, symbol = '$', precision = 2) {
  if (amount >= 1000000) return round(symbol, amount / 1000000, 'M', precision)
  if (amount >= 1000) return round(symbol, amount / 1000, 'K', precision)

  return round(symbol, amount, '', precision)
}

function round (symbol, amount, unit, precision) {
  const value = Math.round(amount * 100) / 100
  if (unit === '' && value.toString().split('.').length > 1) {
    return `${symbol}${value.toFixed(precision)}${unit}`
  }

  return `${symbol}${value}${unit}`
}

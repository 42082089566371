import classnames from 'classnames'
import React, {useMemo} from 'react'

import Avatar from '../../../avatar/components/Avatar.js'
import {useOpen} from '../../../multi-table/react-hooks.js'
import {useRootedSelector} from '../../../redux.js'
import {renderOrdinal} from '../../../rendering.js'

import {
  createGetSelfPlace,
  createGetSelfTableId,
  createGetWinner,
  createIsFinished,
  getRoot,
} from '../../index.js'

export default function Position (props) {
  const place = useRootedSelector(getRoot, useMemo(createGetSelfPlace, []), props)
  const tableId = useRootedSelector(getRoot, useMemo(createGetSelfTableId, []), props)
  const winner = useRootedSelector(getRoot, useMemo(createGetWinner, []), props)
  const isFinished = useRootedSelector(getRoot, useMemo(createIsFinished, []), props)
  const handleClick = useOpen(tableId)

  return <div className='pane pane__positions'>
    <div className={classnames('pane', 'pane__winner', {notVisible: !isFinished})}>
      <div className='small'>Tournament Winner</div>
      <Avatar nickname={winner} />
      <div>{winner}</div>
    </div>

    <div className='kvline rightAlign'>
      <label>My Position</label>
      <span className='large'>{place ? `${renderOrdinal(place)} Place` : 'N/A'}</span>
    </div>

    {!isFinished && tableId && <div className='kvpair float rightAlign' onClick={handleClick}>
      <button type='button' className='button goToMyTableButton'>
        <span className='fa fa-sign-in button-icon' /> Go To My Table
      </button>
    </div>}
  </div>
}

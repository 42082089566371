export default ({signal, app, game, client, listing}) ->

  # NOTE: client.sittingOut should not be set here because this is just acknowledgement
  # of a request to sit out next hand/blind. client.sittingOut is set in playerSittingOut

  if signal.success and signal.sitOut? and client?
    switch signal.sitOut.type
      when 'nextHand'
        client.displayChatMessage message: 'You will be sat out next hand'
      when 'nextBlind'
        client.displayChatMessage message: 'You will be sat out next blind'

# ## Currency Module

# Extend number and string prototypes with convinience methods for formatting money
# More of a collection of helpers than a true helper

export default do ->

  # WEB-1350. Mobile Safari Math.abs implementation is causing woes. Use this instead
  abs = (arg) -> if (arg < 0 or arg == -0) then (-arg) else arg

  # http://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-money-in-javascript
  Number.prototype.toMoney = String.prototype.toMoney = (symbol = '$', decimals = 2, decimalSeparator = ".", thousandsSeparator = ",") ->
    n = parseFloat this, 10
    c = if isNaN(decimals) then 2 else abs decimals
    if n < 0
      sign = "-"
    else
      sign = ""
    i = parseInt(n = abs(n).toFixed(c)) + ''
    j = if (j = i.length) > 3 then j % 3 else 0
    x = if j then i.substr(0, j) + thousandsSeparator else ''
    y = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator)
    z = if c then decimalSeparator + abs(n - i).toFixed(c).slice(2) else ''
    return sign + symbol + x + y + z

  # # shortCurrency
  # rewrites large values 1000 to 1K, 1536900 to 1.54M ...
  Number.prototype.shortCurrency = (symbol = '$', precision = 2) ->
    round = (value = 0.00, unit = '') ->
      value = Math.round(value*100)/100
      # if no K or M suffix, and has decimal places, ensure there are exactly two deicmal places
      if unit is '' and value.toString().split('.').length > 1
        value = value.toMoney(symbol, precision)
      else
        value = symbol + value + unit

    number = this

    if number >= 1000 && number < 1000000
      round number/1000, 'K'
    else if number >= 1000000
      round number/1000000, 'M'
    else
      round number

  # Truncate trailing zeros. i.e. $5 instead of $5.00
  Number.prototype.toBuyinFormat = String.prototype.toBuyinFormat = ->
    str = this.toMoney()
    if str.substring(str.length - 3) is ".00"
      str = str.substring(0, str.length - 3)
    return str

  String.prototype.shortCurrency = (symbol = '$') ->
    parseFloat(this).shortCurrency(symbol)

  # WARNING: this does not work with single letters like: "I am a ninja".toCamelCase() === "iAmA ninja"
  String.prototype.toCamelCase = ->
    return this.toLowerCase().replace(/[^a-z_ ]/g, '').replace /([a-z]+)[\s|_]+([a-z])/g, (match, $1, $2) -> $1 + $2.toUpperCase()

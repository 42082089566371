import React, {useCallback} from 'react'

import {useRouteNode, useRouter} from 'react-router5'
import {GAME_LIST, TOURNAMENTS, TOURNAMENTS_SUB_ROUTE} from '../../../routes.js'
import MobileSelectionButton from '../../components/MobileSelectionButton.js'
import {SCHEDULED_TOURNAMENT_FILTER} from '../constants.js'
import {useScheduledTournamentFilter} from '../react-hooks.js'

const {HOLDEM, OMAHA, SATELLITES} = TOURNAMENTS_SUB_ROUTE
const {HOLDEM: HOLDEM_FILTER, OMAHA: OMAHA_FILTER, SATELLITES: SATELLITES_FILTER} = SCHEDULED_TOURNAMENT_FILTER

export default function MobileSelection () {
  const {route} = useRouteNode('')
  const router = useRouter()
  const {params: {s2: selectedSubRoute}} = route
  const scheduledTournamentFilter = useScheduledTournamentFilter(selectedSubRoute)

  const handleClickHoldem = useCallback(() => {
    router.navigate(GAME_LIST, {s1: TOURNAMENTS, s2: HOLDEM})
  }, [router])

  const handleClickOmaha = useCallback(() => {
    router.navigate(GAME_LIST, {s1: TOURNAMENTS, s2: OMAHA})
  }, [router])

  const handleClickSatellites = useCallback(() => {
    router.navigate(GAME_LIST, {s1: TOURNAMENTS, s2: SATELLITES})
  }, [router])

  return <>
    <MobileSelectionButton isSelected={scheduledTournamentFilter === HOLDEM_FILTER} onClick={handleClickHoldem}>
      <div>Tournaments</div><div>Hold'em</div>
    </MobileSelectionButton>
    <MobileSelectionButton isSelected={scheduledTournamentFilter === OMAHA_FILTER} onClick={handleClickOmaha}>
      <div>Tournaments</div><div>Omaha</div>
    </MobileSelectionButton>
    <MobileSelectionButton isSelected={scheduledTournamentFilter === SATELLITES_FILTER} onClick={handleClickSatellites}>
      <div>Tournaments</div><div>Satellites</div>
    </MobileSelectionButton>
  </>
}

import React from 'react'

import styles from '../Bank.module.css'

export default function Value ({children, value}) {
  return <span className={styles.detailsValue}>
    {value}
    {children}
  </span>
}

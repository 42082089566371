import React from 'react'

import LabelRow from './LabelRow.js'
import Checkbox from '../../components/Checkbox.js'
import {useIsSoundsEnabled, useToggleSoundsEnabled} from '../react-hooks.js'

export default function SoundsEnabled () {
  return <LabelRow name='Sounds Enabled'>
    <Checkbox checked={useIsSoundsEnabled()} onChange={useToggleSoundsEnabled()} size='small' />
  </LabelRow>
}

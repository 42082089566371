import Mixmaster from '../../../mixmaster.coffee'

export default class RebuyData extends Mixmaster
  mixins = []
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  # THIS ARGUMENTS NOT GETTING PARSED PROPERLY!
  # code1: "TRUE"
  # code3: "AMOUNT"
  # code4: "CHIPS"
  # code5: "DATA"
  # code8: "REBUYS_REMAINING"
  # name: "REBUY_ALLOWED"
  # value3: "5.00"
  # value4: "500.00"
  # value5: "2^2.00^^0.00"
  # value8: "3"

  mixin: (object) ->
    mixins.map((mixin) => (new mixin).mixin(object))

    # see WEB-1220 for docs
    object.rebuyAllowed = (signal, name, values) ->
      paymentOptions = @parseRebuyPaymentData values.value5
      details = {
        allowed: values.code1 in ['true', 'TRUE', true] # sorry for this, suspect it is a weird value when re-enter
      }
      if details.allowed
        Object.assign details,
          gameTimer: parseInt(values.gameTimer,10)
          amount: parseFloat(values.amount)
          chips: parseFloat(values.chips)
          paymentOptions: paymentOptions
          rebuysRemaining: parseInt(values.rebuysRemaining,10)
          showPrompt: values.code6 ?= false
          finalRebuy: values.code7 ?= false
      signal.updates.rebuyAllowed = details

    object.addonAllowed = (signal, name, values) ->
      paymentOptions = @parseRebuyPaymentData values.value4 # NOTE DIFFERENT TO REBUY
      details = {
        allowed: values.code1 in ['true', 'TRUE', true] # sorry for this, suspect it is a weird value when re-enter
      }
      if details.allowed
        Object.assign details,
          gameTimer: parseInt(values.gameTimer,10)
          amount: parseFloat(values.amount)
          chips: parseFloat(values.chips)
          paymentOptions: paymentOptions
          # rebuysRemaining: parseInt(values.rebuysRemaining,10)  # this is not provided
      signal.updates.addonAllowed = details

    object.pendingRebuy = (signal, name, values) ->
      signal.updates.pendingRebuy = values

    object.parseRebuyPaymentData = (str) ->
      parseCoupon = @parseCoupon

      paymentOptions = str?.split('#').map (row) ->
        [id, cash, coupon, points] = row.split '^'
        coupon = parseCoupon coupon
        [id, cash, points] = [id, cash, points].map (item) -> parseFloat item
        return {id, cash, coupon, points, type: 'rebuy'}
      paymentOptions ?= []
      return paymentOptions

    # TODO:
    # name could contain special characters....which are "encoded"
    # <PI> <CO> <CT> <HH> <TI> <SC> = | , ^ # ~ ;
    # and type is one of C, E, S, ES = coupon, eligibility, shared, shared eligibility
    object.parseCoupon = (v) ->
      c = v?.split(';')
      if c?.length is 2
        return {name: c[0], couponType: c[1]}
      else
        return {}

import {COUPON_ENTRY} from '@pipehat/chronicle/constants/game-category'
import {REGISTERING} from '@pipehat/chronicle/constants/tournament-status'
import classnames from 'classnames'
import React, {useMemo} from 'react'

import TournamentIndicators from './TournamentIndicators.js'
import LinkRow from '../../components/game-listing/list/LinkRow.js'
import styles from '../../components/game-listing/list/List.module.css'
import Money from '../../money/components/Money.js'

import {
  renderGameLimitAndVariant,
  renderGameLimitAndVariantInitialism,
  renderTournamentStatus,
} from '../../rendering.js'

import {TOURNAMENT} from '../../routes.js'
import skeleton from '../../skeleton.module.css'
import DescribedTime from '../../time/components/DescribedTime.js'
import {renderStartsWhen} from '../sitngo-tournament/rendering.js'

export default function MobileTournamentListingRow (props) {
  const {tournament} = props

  const {
    categories,
    entryCost,
    highlightColor,
    isSng,
    maximumRegistrants,
    name,
    registrantCount,
    startTime,
    status,
    tournamentId,
  } = tournament

  const routeParams = useMemo(() => ({tournamentId: String(tournamentId)}), [tournamentId])

  const starts = isSng
    ? renderStartsWhen(maximumRegistrants - registrantCount)
    : (startTime ? <DescribedTime value={startTime} /> : '-')

  const isCouponEntry = categories.includes(COUPON_ENTRY)

  const entry = entryCost
    ? <Money value={entryCost} />
    : isCouponEntry
      ? <span title='Restricted'>Free*</span>
      : 'Free'

  const tournamentStatus = status === REGISTERING
    ? <span className={styles.registering}> - <div className="fa fa-pencil-square-o" /> Registering</span>
    : <span> - {renderTournamentStatus(status)}</span>

  return <LinkRow routeName={TOURNAMENT} routeParams={routeParams} highlightColor={highlightColor}>
    <div className={styles.startTime}>
      <div className={skeleton.light}>{starts}</div>
    </div>
    <div className={styles.gameDetailsSection}>
      <div className={styles.name}>
        <span className={skeleton.light}>{name}</span>
      </div>
      <div className={styles.stakes}>
        <span className={skeleton.light}>{entry}{tournamentStatus}</span>
      </div>
    </div>
    <div className={styles.gameTypeSection}>
      <div className={styles.gameType}>
        <span className={skeleton.light}>{renderGameLimitAndVariant(tournament)}</span>
      </div>
      <div className={classnames(styles.gameType, styles.short)}>
        <span className={skeleton.light}>
          {renderGameLimitAndVariantInitialism(tournament)}
        </span>
      </div>
      <div className={styles.seatCount}>
        <span className={skeleton.light}>
          Plrs: {registrantCount} / {maximumRegistrants}
        </span>
      </div>
    </div>
    <div className={styles.indicators}>
      <span className={skeleton.light}>
        <TournamentIndicators tournament={tournament} />
      </span>
    </div>
  </LinkRow>
}

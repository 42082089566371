import React from 'react'

import {useWindowTitle} from '../../global/react-hooks.js'
import {useRootedSelector} from '../../redux.js'
import OwnRingGamesList from '../components/OwnRingGamesList.js'
import {getRoot, getOwnRingGames} from '../index.js'

const windowTitle = 'My Games'

export default function OwnRingGames () {
  useWindowTitle(windowTitle)

  const ringGames = useRootedSelector(getRoot, getOwnRingGames)

  return <OwnRingGamesList ringGames={ringGames} />
}

import React from 'react'

import {GAME_FORMAT} from '../constants.js'
import {useGameFormat} from '../react-hooks.js'
import MobileRingGameListing from '../ring-game/components/MobileListing.js'
import MobileScheduledTournamentListing from '../scheduled-tournament/components/MobileListing.js'
import MobileSitngoTournament from '../sitngo-tournament/components/MobileListing.js'

const {RING_GAME, SCHEDULED_TOURNAMENT, SITNGO_TOURNAMENT} = GAME_FORMAT

export default function MobileListing () {
  const gameFormat = useGameFormat()

  return <>
    {gameFormat === RING_GAME && <MobileRingGameListing />}
    {gameFormat === SITNGO_TOURNAMENT && <MobileSitngoTournament />}
    {gameFormat === SCHEDULED_TOURNAMENT && <MobileScheduledTournamentListing />}
  </>
}

# This is always fired whenever a join game fails, it essentially repeats the
# client and table creation process

import Card from '../../../models/card.coffee'
import Client from '../../../models/client.coffee'
import Game from '../../../models/game.coffee'
import Player from '../../../models/player.coffee'

export default ({signal, app, game, client, listing}) ->
  if signal.failReason is 'gameNotExist'
    # this is handled in joinGameResponse
    return

  unless client and game
    details = Object.assign({}, signal.updates)

    # If the listing has been created by app.processMyGames, it is lacking a lot of
    # important listing properties. We can update them here.
    if listing
      details.limitTypeDesc = listing.limitTypeDesc
      details.gameTypeDesc = listing.gameTypeDesc
      for k, v of signal.updates
        if listing[k]?
          listing[k] = v

    if details.tournamentId
        details.currency = ''
        details.precision = 0

    Object.assign(details, signal.message) # makes a copy of signal.updates so the defaults don't affect scraping

    game   = new Game {details}
    client = new Client {game, user: app.user}
    # app.tables.add game
    app.clients.add client

    app.spectate game, client

    client.listType = listing?.listType

    # hax for setting the client player on a tournament join
    if signal.updates.event?
      event = signal.updates.event
      if event.eventName is 'joinGame' and clientPlayer = game.players.find {position: event.playerId}
        client.setPlayer clientPlayer

    # # ### Populating game players.
    for playerDetails in signal.playerDetails
      playerDetails.currency = game.currency
      playerDetails.precision = if game.minChipValue is 1 then 0 else 2
      existingPlayer = game.players.find {position: playerDetails.position}
      if playerDetails.cards
          cards = []
          for card in playerDetails.cards
            cards.push new Card card
          playerDetails.cards = cards
      if existingPlayer?
        # console.log 'todo, modifyPlayer'
      else
        game.players.add new Player {details: playerDetails}

import React, {useCallback} from 'react'

import styles from './Settings.module.css'
import {useShowModal} from '../../modal/react-hooks.js'

export default function HelpIcon (props) {
  const {message, title} = props
  const showModal = useShowModal()

  const handleClick = useCallback(() => {
    showModal({
      title,
      content: message,
    })
  }, [message, showModal, title])

  return <button type='button' className={styles.help} onClick={handleClick}>?</button>
}

import classnames from 'classnames'
import React, {useCallback, useMemo} from 'react'

import Button from './Button.js'
import styles from './QuickSeat.module.css'
import {useRootedSelector} from '../../redux.js'
import skeleton from '../../skeleton.module.css'
import {createGetSngFilterCount, getRoot} from '../index.js'
import {useSngFilter} from '../react-hooks.js'

export default function SngFilterButton (props) {
  const {filter, label} = props
  const count = useRootedSelector(getRoot, useMemo(createGetSngFilterCount, []), {filter})
  const [currentFilter, setFilter] = useSngFilter()

  const handleClick = useCallback(() => {
    setFilter(filter)
  }, [filter, setFilter])

  return <Button type='button' isSelected={filter === currentFilter} onClick={handleClick}>
    <div className={classnames(styles.buttonLabel, skeleton.light)} title={label}>{label}</div>
    <div className={classnames(styles.tableCount, skeleton.light)}>({count} active)</div>
  </Button>
}

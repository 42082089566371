import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

export default class GetTournamentPlayersTranslator extends Mixmaster
  mixins = [
    DefaultMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal
    signal.tournamentPlayers ?= []

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  tournamentPlayers: (signal, attributes) ->
    if attributes.players.length
      signal.tournamentPlayers = signal.tournamentPlayers.concat attributes.players
    if attributes.results.length
      signal.tournamentPlayers = signal.tournamentPlayers.concat attributes.results

export default ({signal, details, app, game, client, listing}) ->

  # todo: refactor. this hacks a "chips" property onto each paymentOption
  if details.paymentOptions
    for item in details.paymentOptions
      item.chips = details.chips

  client.addonAllowed = details.allowed

  # note, if not allowed this overwrites the rebuyAllowed details which is annoying
  if client.addonAllowed and signal.function in ['startBreak', 'joinGame']
    client.promptAddon details

import React from 'react'

import {useWindowTitle} from '../../global/react-hooks.js'
import {FAQ, POKER_RULES} from '../../routes.js'
import NamedUrlMenuItem from '../components/NamedUrlMenuItem.js'
import RouteMenuItem from '../components/RouteMenuItem.js'

const windowTitle = 'Support & Legal'

export default function Support () {
  useWindowTitle(windowTitle)

  return <div className='support'>
    <div className='format-message'>Support and Legal</div>

    <div className='fancyList'>
      <NamedUrlMenuItem urlName='playerServices.support'>Contact Support</NamedUrlMenuItem>
      <RouteMenuItem routeName={FAQ} icon='folder'>FAQ</RouteMenuItem>
      <NamedUrlMenuItem urlName='content.legal.eula'>EULA</NamedUrlMenuItem>
      <NamedUrlMenuItem urlName='content.legal.termsAndConditions'>T&C</NamedUrlMenuItem>
      <NamedUrlMenuItem urlName='content.legal.privacyPolicy'>Privacy Policy</NamedUrlMenuItem>
      <NamedUrlMenuItem urlName='content.legal.prohibitedSoftwarePolicy'>Prohibited Software Policy</NamedUrlMenuItem>
      <NamedUrlMenuItem urlName='content.legal.licensingAndCertification'>Licensing</NamedUrlMenuItem>
      <RouteMenuItem routeName={POKER_RULES}>Poker Rules</RouteMenuItem>
    </div>
  </div>
}

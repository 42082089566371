export default
  NOT_LISTED:
    description: 'Not Listed'
    id: 0
    name: 'NOT_LISTED'
  TEXAS_HOLDEM_NO_LIMIT:
    description: 'Texas Holdem No Limit'
    id: 1
    name: 'TEXAS_HOLDEM_NO_LIMIT'
  TEXAS_HOLDEM_POT_LIMIT:
    description: 'Texas Holdem Pot Limit'
    id: 2
    name: 'TEXAS_HOLDEM_POT_LIMIT'
  TEXAS_HOLDEM_FIXED_LIMIT:
    description: 'Texas Holdem Fixed Limit'
    id: 3
    name: 'TEXAS_HOLDEM_FIXED_LIMIT'
  OMAHA:
    description: 'Omaha'
    id: 4
    name: 'OMAHA'
  OMAHA_HILO:
    description: 'Omaha HiLo'
    id: 5
    name: 'OMAHA_HILO'
  SEVEN_CARD_STUD:
    description: 'Seven Card Stud'
    id: 6
    name: 'SEVEN_CARD_STUD'
  SEVEN_CARD_STUD_HILO:
    description: 'Seven Card Stud HiLo'
    id: 7
    name: 'SEVEN_CARD_STUD_HILO'
  RAZZ:
    description: 'Razz'
    id: 8
    name: 'RAZZ'
  FIVE_CARD_STUD:
    description: 'Five Card Stud'
    id: 9
    name: 'FIVE_CARD_STUD'
  FIVE_CARD_DRAW:
    description: 'Five Card Draw'
    id: 10
    name: 'FIVE_CARD_DRAW'
  TRIPLE_DRAW:
    description: 'Triple Draw'
    id: 11
    name: 'TRIPLE_DRAW'
  BADUGI:
    description: 'Badugi'
    id: 12
    name: 'BADUGI'
  CUSTOM:
    description: 'Custom'
    id: 13
    name: 'CUSTOM'
  TOURNAMENT_SCHEDULED_REGULAR:
    description: 'Tournament Scheduled Regular'
    id: 14
    name: 'TOURNAMENT_SCHEDULED_REGULAR'
  TOURNAMENT_SCHEDULED_SATELLITE:
    description: 'Tournament Scheduled Satellite'
    id: 15
    name: 'TOURNAMENT_SCHEDULED_SATELLITE'
  TOURNAMENT_SCHEDULED_SPECIAL:
    description: 'Tournament Scheduled Special'
    id: 16
    name: 'TOURNAMENT_SCHEDULED_SPECIAL'
  TOURNAMENT_SITNGO_REGULAR:
    description: 'Tournament SitNGo Regular'
    id: 17
    name: 'TOURNAMENT_SITNGO_REGULAR'
  TOURNAMENT_SITNGO_HEADSUP:
    description: 'Tournament SitNGo HeadsUp'
    id: 18
    name: 'TOURNAMENT_SITNGO_HEADSUP'
  # # THESE TWO LIST TYPES ARE OBSOLETE
  # TOURNAMENT_SITNGO_SATELLITE:
  #   description: 'Tournament SitNGo Satellite'
  #   id: # 19
  #   name: # 'TOURNAMENT_SITNGO_SATELLITE'
  # TOURNAMENT_SITNGO_CUSTOM:
  #   description: 'Tournament SitNGo Custom'
  #   id: # 20
  #   name: # 'TOURNAMENT_SITNGO_CUSTOM'
  TOURNAMENT_PRIVATE_SCHEDULED:
    description: 'Tournament Private Scheduled'
    id: 21
    name: 'TOURNAMENT_PRIVATE_SCHEDULED'
  TOURNAMENT_PRIVATE_LEAGUE:
    description: 'Tournament Private League'
    id: 22
    name: 'TOURNAMENT_PRIVATE_LEAGUE'
  TOURNAMENT_PRIVATE_SATELLITE:
    description: 'Tournament Private Satellite'
    id: 23
    name: 'TOURNAMENT_PRIVATE_SATELLITE'
  BACKGAMMON:
    description: 'Backgammon'
    id: 24
    name: 'BACKGAMMON'
  MIXED:
    description: 'Mixed'
    id: 25
    name: 'MIXED'
  PROMOTIONAL_TOURNAMENT:
    description: 'Promotional Tournament'
    id: 26
    name: 'PROMOTIONAL_TOURNAMENT'
  PROMOTIONAL_SATELLITE:
    description: 'Promotional Satellite'
    id: 27
    name: 'PROMOTIONAL_SATELLITE'
  TOURNAMENT_SITNGO_SIX_MAX:
    description: 'Tournament SitNGo 6 Max'
    id: 28
    name: 'TOURNAMENT_SITNGO_SIX_MAX'
  TOURNAMENT_SITNGO_FULL_RING:
    description: 'Tournament SitNGo Full Ring'
    id: 29
    name: 'TOURNAMENT_SITNGO_FULL_RING'
  TOURNAMENT_SITNGO_FOUR_MAX:
    description: 'Tournament SitNGo 4 Max'
    id: 30
    name: 'TOURNAMENT_SITNGO_FOUR_MAX'

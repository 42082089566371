import React from 'react'

import StakesRangeButton from './StakesRangeButton.js'
import {ALL, STAKES_RANGE} from '../../constants.js'
import {STAKES_RANGE_MAP} from '../constants.js'
import {useSeatCountRange, useSpeed} from '../react-hooks.js'

const {HIGH, LOW, MEDIUM, MICRO} = STAKES_RANGE

export default function StakesRangeSelection () {
  const seatCountRange = useSeatCountRange()
  const speed = useSpeed()
  const stakesRanges = STAKES_RANGE_MAP[seatCountRange]?.[speed]

  const shouldShowAll = stakesRanges.includes(ALL)
  const shouldShowHigh = stakesRanges.includes(HIGH)
  const shouldShowLow = stakesRanges.includes(LOW)
  const shouldShowMedium = stakesRanges.includes(MEDIUM)
  const shouldShowMicro = stakesRanges.includes(MICRO)

  return <ul>
    {shouldShowAll && <li><StakesRangeButton label='All' stakesRange={ALL} /></li>}
    {shouldShowMicro && <li><StakesRangeButton label='Micro' stakesRange={MICRO} /></li>}
    {shouldShowLow && <li><StakesRangeButton label='Low' stakesRange={LOW} /></li>}
    {shouldShowMedium && <li><StakesRangeButton label='Medium' stakesRange={MEDIUM} /></li>}
    {shouldShowHigh && <li><StakesRangeButton label='High' stakesRange={HIGH} /></li>}
  </ul>
}

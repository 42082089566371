import React, {useCallback} from 'react'

import Button from './Button.js'
import {useGameFormat} from '../react-hooks.js'

export default function GameFormatButton (props) {
  const {gameFormat, label} = props
  const [currentGameFormat, setGameFormat] = useGameFormat()

  const handleClick = useCallback(() => {
    setGameFormat(gameFormat)
  }, [gameFormat, setGameFormat])

  return <Button type='button' isSelected={gameFormat === currentGameFormat} onClick={handleClick}>{label}</Button>
}

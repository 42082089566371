import React from 'react'

export default function RadioOptionMenuItem (props) {
  const {isChecked, label, onChange, readOnly} = props

  return <label>
    <input type='radio' checked={isChecked} onChange={onChange} readOnly={readOnly} />
    {label}
  </label>
}

export default ({signal, app, game, client, listing}) ->
  if signal.success
    listing?.waiting = true
    client?.waiting = true
    {length, position} = signal.updates.waitlistPosition
    client?.description = "Waiting in position #{position} of #{length}"
    # console.log "Added to waitlist for #{listing?.gameName}. Position #{position} of #{length}"
    # app.notice
    #   title: 'Added to Waitlist'
    #   content: "Position #{position} of #{length}"
  else
    reason = signal.failReason
    switch signal.failReason
      when 'alreadyPlaying'
        reason = 'Already Playing'
      when 'alreadyWaiting'
        reason = 'Already Waiting'
    app.notice
      title: 'Failed to join Waitlist'
      content: reason

import {COUPON_ENTRY} from '@pipehat/chronicle/constants/game-category'

import {
  ANNOUNCED,
  CREATED,
  IN_PROGRESS,
  LATE_REGISTRATION,
  REGISTERING,
} from '@pipehat/chronicle/constants/tournament-status'

import {createCompare} from '@pipehat/money/plain-object'

import {ASC, DESC} from './constants.js'
import {compareNumber, compareString, createCompareIsoDateTime} from '../comparator.js'
import {isObject} from '../object.js'
import {renderGameLimitAndVariantInitialism} from '../rendering.js'

export const KEY_ACTIVE = 'ACTIVE'
export const KEY_AVERAGE_POT = 'AVERAGE_POT'
export const KEY_ENTRY_COST = 'ENTRY_COST'
export const KEY_FLOP_RATIO = 'FLOP_RATIO'
export const KEY_FULLNESS = 'FULLNESS'
export const KEY_GAME = 'GAME'
export const KEY_HANDS_PER_HOUR = 'HANDS_PER_HOUR'
export const KEY_HEADS_UP_SNG = 'HEADS_UP_SNG'
export const KEY_MAXIMUM_REGISTRANTS = 'MAXIMUM_REGISTRANTS'
export const KEY_NAME = 'NAME'
export const KEY_OWN = 'OWN'
export const KEY_PINNED = 'PINNED'
export const KEY_PRIORITY = 'PRIORITY'
export const KEY_REGISTRANTS = 'REGISTRANTS'
export const KEY_RELEVANCE = 'RELEVANCE'
export const KEY_SEATED = 'SEATED'
export const KEY_SEATS = 'SEATS'
export const KEY_STAKES = 'STAKES'
export const KEY_START_TIME = 'START_TIME'
export const KEY_STATUS = 'STATUS'

export function createStore (createGenericStore, setting, normalize) {
  const store = createGenericStore(setting, normalize)

  return {
    ...store,

    toggle (sortKey) {
      store.set(customerSort => { toggleSort(sortKey, customerSort) })
    },
  }
}

export function flipDirection (direction) {
  return -direction
}

export function isValidDirection (direction) {
  return direction === ASC || direction === DESC
}

export function normalizeRingGameSort (sort) {
  if (!isObject(sort)) return {}

  const {key, direction} = sort

  if (isCustomerSortableRingGameKey(key) && isValidDirection(direction)) return {key, direction}

  return {}
}

export function normalizeTournamentSort (sort) {
  if (!isObject(sort)) return {}

  const {key, direction} = sort

  if (isCustomerSortableTournamentKey(key) && isValidDirection(direction)) return {key, direction}

  return {}
}

export function sortRingGames (customerSort, ringGames) {
  return apply(createRingGameComparators(), createRingGameOrder(customerSort), ringGames)
}

export function sortTournaments (customerSort, tournaments) {
  return apply(createTournamentComparators(), createTournamentOrder(customerSort), tournaments)
}

function apply (comparators, order, entries) {
  return Object.values(entries).sort((original, other) => {
    for (const {key, direction} of order) {
      if (!key) continue // undefined key means an "empty" sort - i.e. do nothing

      const comparator = comparators[key]
      const result = comparator(direction, original, other)

      if (result !== 0) return result
    }

    return 0
  })
}

function createComparator (comparator, mapFn) {
  return function compare (direction, original, other) {
    const mappedOriginal = mapFn ? mapFn(original) : original
    const mappedOther = mapFn ? mapFn(other) : other

    const hasOriginal = mappedOriginal != null
    const hasOther = mappedOther != null

    if (hasOriginal && hasOther) {
      const result = comparator(mappedOriginal, mappedOther)

      return direction === ASC ? result : -result
    }

    if (!hasOriginal && !hasOther) return 0

    return hasOriginal ? -1 : 1
  }
}

function createGetProperty (property) {
  return function getProperty (object) {
    return object[property]
  }
}

const TOURNAMENT_STATUS_ORDER = [
  LATE_REGISTRATION,
  REGISTERING,
  ANNOUNCED,
  CREATED,
  IN_PROGRESS,
]

function compareTournamentStatus (original, other) {
  const originalWeight = TOURNAMENT_STATUS_ORDER.indexOf(original)
  const otherWeight = TOURNAMENT_STATUS_ORDER.indexOf(other)

  if (originalWeight >= 0 && otherWeight >= 0) return originalWeight - otherWeight

  return original.localeCompare(other)
}

function compareTournamentFullness (original, other) {
  // sort tournaments by their ratio of fullness
  return (
    (original.registrantCount / original.maximumRegistrants) -
    (other.registrantCount / other.maximumRegistrants)
  )
}

function compareTournamentHeadsUpSng (original, other) {
  return (original.isSng && original.maximumRegistrants === 2) - (other.isSng && other.maximumRegistrants === 2)
}

const RING_GAME_COMPARATORS = {
  [KEY_FLOP_RATIO]: createComparator(compareNumber, createGetProperty('flopRatio')),
  [KEY_GAME]: createComparator(compareString, renderGameLimitAndVariantInitialism),
  [KEY_HANDS_PER_HOUR]: createComparator(compareNumber, createGetProperty('handsPerHour')),
  [KEY_NAME]: createComparator(compareString, createGetProperty('name')),
  [KEY_OWN]: createComparator(compareNumber, createGetProperty('isOwn')),
  [KEY_PINNED]: createComparator(compareNumber, createGetProperty('isPinned')),
  [KEY_PRIORITY]: createComparator(compareNumber, createGetProperty('isPriority')),
  [KEY_SEATED]: createComparator(compareNumber, createGetProperty('seatedCount')),
  [KEY_SEATS]: createComparator(compareNumber, createGetProperty('seatCount')),
}

function createRingGameComparators () {
  const compareMoney = createCompare()

  function compareStakes (original, other) {
    return (
      compareMoney(original.minimumStake, other.minimumStake) ||
      compareMoney(original.maximumStake, other.maximumStake)
    )
  }

  return {
    ...RING_GAME_COMPARATORS,
    [KEY_AVERAGE_POT]: createComparator(compareMoney, createGetProperty('averagePot')),
    [KEY_STAKES]: createComparator(compareStakes),
  }
}

// only customer-sortable keys need a default direction defined
const DEFAULT_DIRECTION = {
  [KEY_AVERAGE_POT]: DESC,
  [KEY_ENTRY_COST]: ASC,
  [KEY_FLOP_RATIO]: ASC,
  [KEY_FULLNESS]: DESC,
  [KEY_GAME]: ASC,
  [KEY_HANDS_PER_HOUR]: DESC,
  [KEY_NAME]: ASC,
  [KEY_REGISTRANTS]: DESC,
  [KEY_SEATED]: DESC,
  [KEY_SEATS]: DESC,
  [KEY_STAKES]: DESC,
  [KEY_START_TIME]: ASC,
  [KEY_STATUS]: ASC,
}

const RING_GAME_PRE_ORDER = [
  {key: KEY_OWN, direction: DESC},
  {key: KEY_PINNED, direction: DESC},
  {key: KEY_PRIORITY, direction: DESC},
]

const RING_GAME_POST_ORDER = [
  {key: KEY_GAME, direction: DEFAULT_DIRECTION[KEY_GAME]},
  {key: KEY_STAKES, direction: DEFAULT_DIRECTION[KEY_STAKES]},
  {key: KEY_NAME, direction: DEFAULT_DIRECTION[KEY_NAME]},
]

function createRingGameOrder (customerSort) {
  return [...RING_GAME_PRE_ORDER, customerSort, ...RING_GAME_POST_ORDER]
}

const TOURNAMENT_COMPARATORS = {
  [KEY_ACTIVE]: createComparator(compareNumber, tournamentIsActive),
  [KEY_FULLNESS]: createComparator(compareTournamentFullness),
  [KEY_GAME]: createComparator(compareString, renderGameLimitAndVariantInitialism),
  [KEY_HEADS_UP_SNG]: createComparator(compareTournamentHeadsUpSng),
  [KEY_MAXIMUM_REGISTRANTS]: createComparator(compareNumber, createGetProperty('maximumRegistrants')),
  [KEY_NAME]: createComparator(compareString, createGetProperty('name')),
  [KEY_OWN]: createComparator(compareNumber, createGetProperty('isOwn')),
  [KEY_PINNED]: createComparator(compareNumber, createGetProperty('isPinned')),
  [KEY_PRIORITY]: createComparator(compareNumber, createGetProperty('isPriority')),
  [KEY_REGISTRANTS]: createComparator(compareNumber, createGetProperty('registrantCount')),
  [KEY_STATUS]: createComparator(compareTournamentStatus, createGetProperty('status')),
}

function createTournamentComparators () {
  const compareMoney = createCompare()
  const compareIsoDateTime = createCompareIsoDateTime()

  function compareEntryCost (original, other) {
    // both have an entry cost, use normal money comparator
    if (original.entryCost && other.entryCost) return compareMoney(original.entryCost, other.entryCost)

    // sort free entry tourneys first
    if (original.entryCost) return 1
    if (other.entryCost) return -1

    // the COUPON_ENTRY category (on a free tourney) indicates that you can ONLY enter with a coupon, so sort tourneys
    // without coupon entry first
    return original.categories.includes(COUPON_ENTRY) - other.categories.includes(COUPON_ENTRY)
  }

  function compareTournamentRelevance (original, other) {
    // sort sng tournaments by fullness
    if (original.isSng && other.isSng) return compareTournamentFullness(original, other)

    if (!original.isSng && !other.isSng) {
      // sort scheduled tournaments by "upcoming" status, then start time
      return (
        ((other.status === IN_PROGRESS) - (original.status === IN_PROGRESS)) ||
        compareIsoDateTime(other.startTime, original.startTime)
      )
    }

    // sort scheduled tournaments before sng tournaments
    return original.isSng - other.isSng
  }

  return {
    ...TOURNAMENT_COMPARATORS,
    [KEY_ENTRY_COST]: createComparator(compareEntryCost),
    [KEY_RELEVANCE]: createComparator(compareTournamentRelevance),
    [KEY_START_TIME]: createComparator(compareIsoDateTime, createGetProperty('startTime')),
  }
}

const TOURNAMENT_PRE_ORDER = [
  {key: KEY_ACTIVE, direction: DESC},
  {key: KEY_OWN, direction: DESC},
  {key: KEY_PINNED, direction: DESC},
  {key: KEY_PRIORITY, direction: DESC},
]

const TOURNAMENT_POST_ORDER = [
  {key: KEY_HEADS_UP_SNG, direction: ASC}, // this is fucking stupid, but okay
  {key: KEY_RELEVANCE, direction: DESC},
  {key: KEY_STATUS, direction: DEFAULT_DIRECTION[KEY_STATUS]},
  {key: KEY_ENTRY_COST, direction: DEFAULT_DIRECTION[KEY_ENTRY_COST]},
  {key: KEY_MAXIMUM_REGISTRANTS, direction: ASC},
  {key: KEY_NAME, direction: DEFAULT_DIRECTION[KEY_NAME]},
]

function createTournamentOrder (customerSort) {
  return [...TOURNAMENT_PRE_ORDER, customerSort, ...TOURNAMENT_POST_ORDER]
}

function defaultDirection (key) {
  return DEFAULT_DIRECTION[key] || ASC
}

const RING_GAME_CUSTOMER_SORTABLE = {
  [KEY_AVERAGE_POT]: true,
  [KEY_FLOP_RATIO]: true,
  [KEY_GAME]: true,
  [KEY_HANDS_PER_HOUR]: true,
  [KEY_NAME]: true,
  [KEY_SEATED]: true,
  [KEY_SEATS]: true,
  [KEY_STAKES]: true,
}

function isCustomerSortableRingGameKey (key) {
  return RING_GAME_CUSTOMER_SORTABLE[key] || false
}

const TOURNAMENT_CUSTOMER_SORTABLE = {
  [KEY_ENTRY_COST]: true,
  [KEY_FULLNESS]: true,
  [KEY_GAME]: true,
  [KEY_NAME]: true,
  [KEY_REGISTRANTS]: true,
  [KEY_START_TIME]: true,
  [KEY_STATUS]: true,
}

function isCustomerSortableTournamentKey (key) {
  return TOURNAMENT_CUSTOMER_SORTABLE[key] || false
}

function toggleSort (sortKey, customerSort) {
  let {key, direction} = customerSort
  const defaultKeyDirection = defaultDirection(sortKey)

  if (sortKey !== key) {
    key = sortKey
    direction = defaultKeyDirection
  } else if (direction === defaultKeyDirection) {
    direction = flipDirection(direction)
  } else {
    key = undefined
    direction = undefined
  }

  customerSort.key = key
  customerSort.direction = direction
}

function tournamentIsActive ({isPinned, status}) {
  return isPinned || TOURNAMENT_STATUS_ORDER.includes(status)
}

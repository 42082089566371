import React, {useEffect, useState} from 'react'

export default function Avatar (props) {
  const {nickname} = props
  const [loadedUrl, setLoadedUrl] = useState('')

  useEffect(() => {
    if (!nickname) return

    setLoadedUrl('')
    const url = window.AVATAR_URL.replace('%NICK_NAME%', nickname)

    function onLoad () {
      setLoadedUrl(url)
    }

    const img = document.createElement('img')
    img.addEventListener('load', onLoad, {once: true})
    img.setAttribute('src', url)

    return () => {
      img.removeEventListener('load', onLoad)
    }
  }, [nickname])

  return <div className='avatar'>
    {loadedUrl && <img src={loadedUrl} />}
  </div>
}

import React from 'react'

import Spinner from '../../components/Loading.js'
import Caption from '../../components/game-listing/table/Caption.js'

export default function Loading ({id}) {
  return <Caption id={id}>
    <Spinner />
  </Caption>
}

import {TOURNAMENT_TABLE_LOADED} from '@pipehat/chronicle/constants/event-type'
import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  isTournament: false,
}

const controlsSlice = createSlice({
  name: 'controls',
  initialState,
  extraReducers: {
    [TOURNAMENT_TABLE_LOADED]: state => {
      state.isTournament = true
    },
  },
})

export default controlsSlice.reducer

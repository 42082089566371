import ArgumentCodes from './argumentCodeEnum.coffee'
import ArgumentNames from './argumentNameEnum.coffee'
import EventNames from './eventNameEnum.coffee'
import EventValues from './eventValueEnum.coffee'
import FunctionNames from './functionNameEnum.coffee'
import GameStateId from './gameStateIdEnum.coffee'
import GameType from './gameTypeEnum.coffee'
import LimitTypeDescs from './limitTypeDescEnum.coffee'
import LimitTypes from './limitTypeEnum.coffee'
import ListTypeEnum from './listTypeEnum.coffee'
import PositionSuffixes from './positionSuffixes.coffee'
import Reasons from './reasonEnum.coffee'

import CardParser from './utils/cardParser.coffee'
import HandParser from './utils/handParser.coffee'

export default class JavaEnums
  getCards: (cardString) ->
    return CardParser.parse(cardString)

  getHandDescription: (descriptionString) ->
    return HandParser.parse(descriptionString)

  getFunctionName: (name) ->
    if FunctionNames[name]
      return FunctionNames[name]
    else
      throw new Error("Non-enumerated function name: #{name}")

  getArgumentName: (name) ->
    if ArgumentNames[name]
      return ArgumentNames[name]
    else
      throw new Error("Non-enumerated argument name: #{name}")

  getArgumentCode: (name) ->
    if ArgumentCodes[name]?
      return ArgumentCodes[name]
    else
      throw new Error("Non-enumerated argument code: #{name}")

  getListType: (id) ->
    if ListTypeEnum[id]?
      return ListTypeEnum[id]
    else
      throw new Error("Non-enumerated list type id: #{id}")

  buildListTypeString: (ids) =>
    str = ''
    for id, i in ids
      str += @getListType(id).description
      if i+1 isnt ids.length
        str += ','
    return str

  getReason: (reason) ->
    if Reasons[reason]
      return Reasons[reason]
    else
      throw new Error("Non-enumerated reason: #{reason}")

  getLimitType: (limitType) ->
    if LimitTypes[limitType]
      return LimitTypes[limitType]
    else
      throw new Error("Non-enumerated limitType: #{limitType}")

  getLimitTypeDesc: (limitType) ->
    if LimitTypeDescs[limitType]
      return LimitTypeDescs[limitType]
    else
      throw new Error("Non-enumerated limitType: #{limitType}")

  getPositionSuffix: (number) ->
    number += '' # toString
    if PositionSuffixes[number.slice(-2)]
      return PositionSuffixes[number.slice(-2)]
    else if PositionSuffixes[number.slice(-1)]
      return PositionSuffixes[number.slice(-1)]
    else
      throw new Error("Non-enumerated position: #{number}")

  getEventName: (arg) ->
    for key, value of arg
      if EventNames[key]
        return EventNames[key](value)
    console.warn 'Missing EventName:', arg
    return 'unnamedEvent'

  getEventValue: (key, value, eventName) ->
    if EventValues.overrides[eventName]?[key]?
      enumObj = {
        key: EventValues.overrides[eventName][key].key
        value: EventValues.overrides[eventName][key].value(value) #arghghg
      }
    else if EventValues[key]
      enumObj = {
        key: EventValues[key].key
        value: EventValues[key].value(value)
      }
      return enumObj

  getGameTypeDesc: (id) ->
    if GameType[id]
      return GameType[id]
    else
      # if DEBUG
      #   console.warn "GameTypeId is not in enum: ", id
      #Currently we get returned a lot of gameTypes that we do not support and filter them out later on...
      return {short: '', long: ''}

  getGameStateId: (str) ->
    if GameStateId[str]
      return GameStateId[str]
    else
      console.warn "Non-enumerated GameStateId", str
      return 0

import {BLUE, GREEN, YELLOW} from '@pipehat/chronicle/constants/color-name'
import classnames from 'classnames'
import React from 'react'
import {Link} from 'react-router5'

import styles from './List.module.css'

export default function LinkRow ({children, highlightColor, routeName, routeParams}) {
  const className = (() => {
    switch (highlightColor) {
      case BLUE: return styles.blue
      case GREEN: return styles.green
      case YELLOW: return styles.yellow
    }

    return undefined
  })()

  return <Link role='row' className={classnames(styles.row, className)} routeName={routeName} routeParams={routeParams}>
    {children}
  </Link>
}

import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'


export default class GetCouponsTranslator extends Mixmaster
  mixins = [
    DefaultMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  userEntryCoupons: (signal, values, node) ->
    parseDate = @parseDate

    signal.entryCoupons = values.map (value) ->
      id: parseInt value.id, 10 #"26079"
      couponId: parseInt value.couponId, 10 #"245"
      name: value.name #"$11 Satellite ($11 value)"
      value: parseFloat value.value #"11.00"
      issued: parseDate value.issued #"20130901"
      expiration: if value.expiration then parseDate(value.expiration) else '' #"20131231 00:00:00"

  # this is actually activeBonuses. Uses the same getCoupons function name as entry coupons (different service)
  # no translation getCoupons status 30634|TESTMITCH2|Free cash test|5.00|0.00|2014/10/22 05:50 PM||active|not_met|vip_points|5|2||0.00;30633|TESTMITCH|Free tourney test|0.00|0.00|2014/10/22 02:33 AM||active|not_met|vip_points|5|1||3.00 defaultMixin.coffee:33
  status: (signal, name, values) ->
    parseDate = @parseDate

    signal.activeBonuses = values.split(';').map (value) ->
      value = value.split '|'
      return {
        id: value[0] # "30634"
        code: value[1] # "TESTMITCH2"
        name: value[2] # "Free cash test"
        value: parseFloat value[3] # "5.00"
        awarded: parseFloat value[4] # "0.00"
        issuedDate: value[5] # "2014/10/22 05:50 PM"
        expiresDate: value[6] # "" same as issuedDate
        status: value[7] # "active"
        playthroughStatus: value[8] # "not_met"
        playthroughType: value[9] # "vip_points"
        playthroughValue: value[10] # "5"
        playthroughOrder: value[11] # "2"
        completionDate: parseDate value[12] # ""
        playthroughPercent: parseFloat value[13] # "0.00"
      }

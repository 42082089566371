import emoticons from '../../enum/enums/utils/emoticons.coffee'
import View from '../view.coffee'

export default class EmotModalView extends View
  constructor: (client, target) ->
    super()

    @model = client
    @target = target

    @dom = $ '<div>'
    @render()

    @initializeView()
    return this

  render: =>
    emots = Object.values(emoticons).filter ({files}) -> files?
    emots = emots.slice 0, 24
    for id, emot of emots then do (id, emot) =>
      wrap = $ "<div class='select-emoticon-wrap'></div>"
      link = $ "<div class='emoticon select-emoticon-emot'></div>"
      wrap.addClass 'select-emoticon-wrap-' + emot.files[0]
      link.addClass emot.files[0]
      link.on 'click', =>
        @model.sendChatMessage {chatText: emot.text}
        @emotModal.teardown()
        @throttle = true
        @emit 'throttle', true
        @emotCooldown = setTimeout (=>
          @throttle = false
          @emit 'throttle', false
        ), 2000
      wrap.append link
      @dom.append wrap
    return @dom

  show: =>
    if @throttle
      return
    @emotModal = @modal
      title: 'Select an Emoticon'
      content: @dom
      detach: true
      className: 'select-emoticon-box'
      target: @target

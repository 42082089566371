import key from 'hotkeys-js'
import React, {useEffect, useMemo, useState} from 'react'
import {useRoute, useRouter} from 'react-router5'

import Details from './Details.js'
import HeaderPanel from './HeaderPanel.js'
import Overview from './Overview.js'
import ParticipantListing from './ParticipantListing.js'
import PrizeListing from './PrizeListing.js'
import PrizeSummary from './PrizeSummary.js'
import Satellites from './Satellites.js'
import TableListing from './TableListing.js'
import Loading from '../../components/Loading.js'
import {useWindowTitle} from '../../global/react-hooks.js'
import {useRootedSelector} from '../../redux.js'
import {LOBBY} from '../../routes.js'
import {FIFTEEN_SECONDS} from '../../time/time-amount.js'
import {useInterval} from '../../time/timer.js'
import {createGetName, createHasCompleted, createGetTournament, getRoot} from '../index.js'
import {useLoad, useLoadParticipants} from '../react-hooks.js'

export default function TournamentLobby (props) {
  const {tournamentId} = props

  const keyScope = `tournament-lobby-${tournamentId}`
  const load = useLoad(tournamentId)
  const loadParticipants = useLoadParticipants(tournamentId)
  const name = useRootedSelector(getRoot, useMemo(createGetName, []), props)
  const tournament = useRootedSelector(getRoot, useMemo(createGetTournament, []), props)
  const hasCompleted = useRootedSelector(getRoot, useMemo(createHasCompleted, []), props)
  const router = useRouter()
  const {route: {params: {closeTo = LOBBY}}} = useRoute()
  const [hasSeenCompleted, setHasSeenCompleted] = useState(false)

  useWindowTitle(`${name} (${tournamentId})`)

  const currentKey = key.getScope()

  useEffect(() => {
    key('escape', keyScope, event => {
      event.preventDefault()
      router.navigate(closeTo)
    })

    return () => {
      key.deleteScope(keyScope)
    }
  }, [closeTo, keyScope, router])

  useEffect(() => {
    if (currentKey !== keyScope) {
      key.setScope(keyScope)
    }
  }, [currentKey, keyScope])

  useInterval(FIFTEEN_SECONDS, () => {
    if (hasCompleted) {
      if (hasSeenCompleted) return

      // perform one more fetch
      setHasSeenCompleted(true)
    }

    load()
    loadParticipants()
  })

  if (!name || !tournament) {
    return <div className='tournament'>
      <div className='pane pane__header'>
        <HeaderPanel tournamentId={tournamentId} />
      </div>

      <Loading />
    </div>
  }

  return <div className='tournament'>
    <div className='pane pane__header'>
      <HeaderPanel tournamentId={tournamentId} />
    </div>

    <div className='pane pane__left'>
      <Overview tournamentId={tournamentId} />
      <Details tournamentId={tournamentId} />
    </div>

    <div className='pane pane__right'>
      <div className='pane pane__prizes'>
        <div className='pane pane__left'>
          <PrizeSummary tournamentId={tournamentId} />
          <Satellites tournamentId={tournamentId} />
        </div>
        <div className='pane pane__right'>
          <PrizeListing tournamentId={tournamentId} />
        </div>
      </div>

      <div className='pane pane__lists'>
        <div className='pane pane__players'>
          <ParticipantListing tournamentId={tournamentId} />
        </div>
        <div className='pane pane__tables'>
          <TableListing tournamentId={tournamentId} />
        </div>
      </div>
    </div>
  </div>
}

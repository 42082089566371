import React from 'react'
import {Link} from 'react-router5'

import {useWindowTitle} from '../../global/react-hooks.js'
import PokerRulesItem from '../components/PokerRulesItem.js'

const windowTitle = 'Poker Rules'

export default function PokerRules () {
  useWindowTitle(windowTitle)

  return <div className='poker-rules fancyList'>
    <div className='format-message'>Poker Rules</div>

    <div className='container'>
      <div className='breadcrumb'>
        <Link className='link' routeName='support'>Support</Link> &gt; Poker Rules
      </div>

      <PokerRulesItem heading='Texas Hold&apos;em'>
        <h2>Objective:</h2>
        <p>Form the highest ranked 5 card poker hand using any combination of 2 cards in hand (hole cards or pocket cards) and 5 community cards on the table.<strong><span><br /></span></strong></p>

        <h2>Alternative win:</h2>
        <p>All<strong><span>&nbsp;</span></strong>other players fold.<strong><span><br /></span></strong></p>

        <h2>Betting Structure:<span>&nbsp;</span></h2>
        <p><span>Some</span> games may have a starting ante imposed on all players. An ante is a set amount of money that must be paid by all players that wish to be dealt into the hand before any cards are dealt.</p>
        <p><strong><span>Dealer </span></strong>- The player starting the hand, indicated by a small dealer icon next to his nickname. The dealer icon (button) moves clockwise around the table after each hand is played.</p>
        <p><strong><span>Small </span>blind</strong> - A forced bet imposed on the player left of the dealer. This player will be the dealer for the next round.</p>
        <p><strong><span>Big </span>blind</strong> - The player left of the small blind pays a forced bet twice that of the small blind. Players joining the table will need to start with a forced blind bet. The big blind is equal to the minimum bet for the pre-flop betting round.</p>

        <h2>Betting options:</h2>
        <p><strong><span>Bet </span></strong>- Place a bet that other players must call to stay in the game.</p>
        <p><strong>Raise</strong> - Increase the current bet, other players must call your raise to stay in the game.</p>
        <p><strong>Call</strong> - Match another player&apos;s bet or raise.</p>
        <p><strong>Check</strong> - Remain in the hand without betting, only available if no other player has a placed a higher bet that round.</p>
        <p><strong>Fold</strong> - Withdraw from calling further bets on the hand; forfeit your chance at the pot.</p>

        <h2>Betting Limits:</h2>
        <p><strong>Fixed Limit</strong> - Each game has a specified betting limit imposed, and a player cannot raise beyond this.</p>
        <p><strong>Pot Limit</strong> - A player may bet up to the current amount of money in the pot.</p>
        <p><strong>No Limit</strong> - There is no maximum, and you are allowed to bet your entire game wallet.</p>

        <h2>Game Progression:</h2>
        <ul>
          <li>The dealer is decided by drawing for a high card and the blind bets are paid.</li>
          <li>Each player is dealt 2 cards (the &quot;hole&quot; or &quot;pocket&quot; cards), and then the first betting round begins. As the blinds are considered to be a live bet, betting starts from the player left of the big blind. Each player in turn must call the big blind, make a raise which must be called by the other players or fold the hand.</li>
          <li><strong>The flop</strong>, the first 3 community cards are dealt on the table, followed by a new betting round.</li>
          <li><strong>The turn</strong>, the fourth community card is dealt, followed by a new betting round.</li>
          <li><strong>The river</strong>, last community card is dealt, followed by the last betting round.</li>
          <li>After all bets are called in the last betting round, the remaining players in the hand must show their cards to determine the winner.</li>
        </ul>
      </PokerRulesItem>

      <PokerRulesItem heading='Badugi'>
        <h2>Objective:</h2>
        <p>Obtain the lowest ranked 4 card hand, with 4 different suits in hand. Aces are played as low cards and straights are not ranked, thus the lowest hand is 4-3-2-A of 4 different suits.</p>

        <h2>Alternative win condition:<strong><span>&nbsp;</span></strong></h2>
        <p>All other players fold.</p>

        <h2>Betting Structure:</h2>
        <p>Some games may have a starting ante imposed on all players.<strong><span>&nbsp;</span></strong></p>
        <ul>
          <li><strong>Dealer</strong> - The player starting the hand, indicated by a small dealer icon next to his nickname.</li>
          <li><strong>Small blind</strong> - A forced bet imposed on the player left of the dealer. This player will be the dealer for the next round.</li>
          <li><strong>Big blind</strong> - The player left of the small blind pays a forced bet twice that of the small blind. Players joining the table must start by posting a forced blind bet.</li>
        </ul>

        <h2>Betting options:<strong><span>&nbsp;</span></strong></h2>
        <ul>
          <li><strong><span>Bet</span></strong> - Place a bet that other players must call to stay in the game.</li>
          <li><strong>Raise</strong> - Increase the current bet, other players must call your raise to stay in the game.</li>
          <li><strong>Call</strong> - Match another player&apos;s bet or raise.</li>
          <li><strong>Check</strong> - Remain in the hand without betting, only available if no other player has a placed a higher bet that round.</li>
          <li><strong>Fold</strong> - Withdraw from calling further bets on the hand; forfeit your chance at the pot.</li>
        </ul>

        <h2>Game Progression:<strong><span>&nbsp;</span></strong></h2>
        <ul>
          <li>The dealer is decided and the blind bets are paid.</li>
          <li>Each player is dealt 4 cards, and then the first betting round begins. As the blinds are considered to be a live bet, betting starts from the player left of the big blind.</li>
          <li>
            All remaining players are given the following options to replace their cards:
            <ul>
              <li><strong>Draw</strong> - Discard any of their cards to draw new ones from the deck.</li>
              <li><strong>Stand</strong> - Keep their current cards.</li>
            </ul>
          </li>
          <li>After the new cards are dealt (if any), a new betting round begins, starting left of the dealer.</li>
          <li>The players are then given a 2nd and 3rd opportunity to draw cards, each subsequently followed by a betting round.</li>
          <li>After the last betting round, the game ends with a showdown to decide the winner. If 2 players tie for the lowest hand, then the pot is split.</li>
        </ul>
      </PokerRulesItem>

      <PokerRulesItem heading='5 Card Draw'>
        <h2>Objective</h2>
        <p>Form the highest ranked poker hand from 5 cards in hand.</p>

        <h2>Alternative win condition:</h2>
        <p>All other players fold.</p>

        <h2>Betting Structure:</h2>
        <p><strong>Dealer</strong> - The player starting the hand, indicated by a small dealer icon next to his nickname.</p>
        <p><strong>Small blind</strong> - A forced bet imposed on the player left of the dealer. This player will be the dealer for the next round.</p>
        <p><strong>Big blind</strong> - The player left of the small blind pays a forced bet twice of the small blind. Players joining the table must start by posting a forced blind bet.</p>

        <h2>Betting options:</h2>
        <p><strong>Bet</strong> - Place a bet that other players must call to stay in the game.</p>
        <p><strong>Raise</strong> - Increase the current bet, other players must call your raise to stay in the game.</p>
        <p><strong>Call</strong> - Match another player&apos;s bet or raise.</p>
        <p><strong>Check</strong> - Remain in the hand without betting, only available if no other player has a placed a higher bet that round.</p>
        <p><strong>Fold</strong> - Withdraw from calling further bets on the hand; forfeit your chance at the pot.</p>

        <h2>Game Progression:</h2>
        <ul>
          <li>The dealer is decided and the blind bets are paid.</li>
          <li>Each player is dealt 5 cards, and then the first betting round begins. As the blinds are considered to be a live bet, betting starts from the player left of the big blind.</li>
          <li>
            All remaining players are given the following options to replace their cards:
            <ul>
              <li><strong>Draw</strong> - Discard any of their cards to draw new ones from the deck.</li>
              <li><strong>Stand</strong> - Keep their current cards.</li>
            </ul>
          </li>
          <li>After the new cards are dealt (if any), the next betting round begins, starting left of the dealer, after which the game goes to a showdown to decide the winner.</li>
        </ul>

        <h2>Wild Card</h2>
        <p>If Jokers are in use, it may be dealt to players as a wild card under the right conditions. A joker counts as an ace unless it is used to complete a straight, flush, or straight flush. If used to complete a flush, it will be the highest card of the flush not in hand.</p>
      </PokerRulesItem>

      <PokerRulesItem heading='Mixed Games'>
        <p>Along with a variety of poker games, we offer tables where different variants are rotated at intervals, such as after a predetermined number of hands.</p>
        <ul>
          <li><strong>H</strong> - Texas Hold&apos;em</li>
          <li><strong>O</strong> - Omaha hi-lo</li>
          <li><strong>A</strong> - Omaha hi</li>
          <li><strong>R</strong> - Razz (Seven card stud low)</li>
          <li><strong>S</strong> - Stud (Seven card stud high)</li>
          <li><strong>E</strong> - Eight-or-better (Seven card stud high-low)</li>
        </ul>
        <p>By using the above abbreviations, you will be able to find different combinations of games played on selected tables. Examples would include HORSE, HAR, SHOE and RASH.</p>
      </PokerRulesItem>

      <PokerRulesItem heading='Triple Draw'>
        <h2>Objective:</h2>
        <p>Obtain the lowest ranked 5 card poker hand.</p>
        <ul>
          <li><strong>Ace to 5 Lowball</strong> - straights and flushes are not ranked.</li>
          <li><strong>Deuce to 7 Lowball</strong> - straights and flushes are ranked.</li>
        </ul>

        <h2>Alternative win condition:</h2>
        <p>All other players fold.</p>

        <h2>Betting Structure:</h2>
        <p><strong>Dealer</strong> - The player starting the hand, indicated by a small dealer icon next to his nickname.</p>
        <p><strong>Small blind</strong> - A forced bet imposed on the player left of the dealer. This player will be the dealer for the next round.</p>
        <p><strong>Big blind</strong> - The player left of the small blind pays a forced bet twice that of the small blind. Players joining the table must start by posting a forced blind bet.</p>

        <h2>Betting options:</h2>
        <p><strong><span>Bet </span></strong>- Place a bet that other players must call to stay in the game.</p>
        <p><strong><span>Raise </span></strong>- Increase the current bet, other players must call your raise to stay in the game.</p>
        <p><strong><span>Call </span></strong>- Match another player&apos;s bet or raise.</p>
        <p><strong><span>Check </span></strong>- Remain in the hand without betting, only available if no other player has a placed a higher bet that round.</p>
        <p><strong><span>Fold </span></strong>- Withdraw from calling further bets on the hand; forfeit your chance at the pot.<strong><span> <br /></span></strong></p>

        <h2>Game Progression:</h2>
        <ul>
          <li>The dealer is decided and the blind bets are paid.</li>
          <li>Each player is dealt 5 cards, and then the first betting round begins. As the blinds are considered to be a live bet, betting starts from the player left of the big blind.</li>
          <li>
            All remaining players are given the following options to replace their cards:
            <ul>
              <li><strong>Draw</strong> - Discard any of their cards to draw new ones from the deck.</li>
              <li><strong>Stand</strong> - Keep their current cards.</li>
            </ul>
          </li>
          <li>After the new cards are dealt (if any), the next betting round begins, starting left of the dealer, after which the game goes to a showdown to decide the winner.</li>
        </ul>
      </PokerRulesItem>

      <PokerRulesItem heading='Omaha Hi/Lo'>
        <h2>Objective:</h2>
        <p>Form the highest ranked 5 card poker hand OR obtain the lowest hand.</p>
        <p>To form a low hand:<strong><span>&nbsp;</span></strong></p>
        <ul>
          <li>No card higher than an 8.</li>
          <li>Straights and flushes do not count towards the low hand. All other combinations disqualify the low hand, such as pairs.</li>
          <li>Aces can be counted as high or low cards, so the best low hand is A-2-3-4-5 of any suit. This hand may also count as a 5 high straight for the high hand.<strong><span>&nbsp;</span></strong></li>
          <li><strong><span>&nbsp;</span></strong>The player has 4 cards in hand of which 2 must be used and 5 community cards of which 3 must be used.</li>
        </ul>

        <h2>Alternative win condition:</h2>
        <p>All other players fold<strong>.<span> <br /></span></strong></p>

        <h2>Betting Structure:</h2>
        <p>Some games may have a starting ante imposed on all players.<strong><span> <br /></span></strong></p>
        <ul>
          <li><strong>Dealer</strong> - The player starting the hand, indicated by a small dealer icon next to his nickname.</li>
          <li><strong>Small blind</strong> - A forced bet imposed on the player left of the dealer. This player will be the dealer for the next round.</li>
          <li><strong>Big blind</strong> - The player left of the small blind pays a forced bet twice that of the small blind. Players joining the table must start by posting a forced blind bet.</li>
        </ul>

        <h2>Betting options:<strong><span>&nbsp;</span></strong></h2>
        <p><strong><span>Be</span>t</strong> - Place a bet that other players must call to stay in the game.</p>
        <p><strong><span>R</span>aise</strong> - Increase the current bet, other players must call your raise to stay in the game.</p>
        <p><strong>Call</strong> - Match another player&apos;s bet or raise.</p>
        <p><strong>Check</strong> - Remain in the hand without betting, only available if no other player has a placed a higher bet that round.</p>
        <p><strong>Fold</strong> - Withdraw from calling further bets on the hand; forfeit your chance at the pot.</p>

        <h2>Betting Limits:</h2>
        <p><strong>Fixed Limit</strong> - Each game has a specified betting limit imposed, and a player cannot raise beyond this.</p>
        <p><strong><span>Po</span>t Limit</strong> - A player may bet up to the current amount of money in the pot<strong>.<span> <br /></span></strong></p>

        <h2>Game Progression:<span>&nbsp;</span><span>&nbsp;</span></h2>
        <ul>
          <li><span>T</span>he dealer is decided and the blind bets are paid.</li>
          <li>Each player is dealt 4 cards, and then the first betting round begins. As the blinds are considered to be a live bet, betting starts from the player left of the big blind.</li>
          <li><strong>The flop</strong>, the first 3 community cards are dealt on the table, followed by a new betting round.</li>
          <li><strong>The turn</strong>, the fourth community card is dealt, followed by a new betting round.</li>
          <li><strong>The river</strong>, last community card is dealt, followed by the last betting round.</li>
          <li>After the last betting round, the game ends with a showdown. If there is a low hand, the pot is split equally between the highest and lowest hands.</li>
        </ul>
      </PokerRulesItem>

      <PokerRulesItem heading='Omaha'>
        <h2>Objective:</h2>
        <p>Form the highest ranked 5 card poker hand. The player has 4 cards in hand of which 2 must be used and 5 community cards of which 3 must be used.</p>

        <h2>Alternative win condition:</h2>
        <p>All other players fold.</p>

        <h2>Betting Structure:</h2>
        <p>Some games may have a starting ante imposed on all players.</p>
        <ul>
          <li><strong>Dealer</strong> - The player starting the hand, indicated by a small dealer icon next to his nickname.</li>
          <li><strong>Small blind</strong> - A forced bet imposed on the player left of the dealer. This player will be the dealer for the next round.</li>
          <li><strong>Big blind</strong> - The player left of the small blind pays a forced bet twice that of the small blind. Players joining the table must start by posting a forced blind bet.</li>
        </ul>

        <h2>Betting options:</h2>
        <p><strong><span>Bet</span></strong> - Place a bet that other players must call to stay in the game.</p>
        <p><strong>Raise</strong> - Increase the current bet, other players must call your raise to stay in the game.</p>
        <p><strong>Call</strong> - Match another player&apos;s bet or raise.</p>
        <p><strong>Check</strong> - Remain in the hand without betting, only available if no other player has a placed a higher bet that round.</p>
        <p><strong>Fold</strong> - Withdraw from calling further bets on the hand; forfeit your chance at the pot.</p>

        <h2>Betting Limits:</h2>
        <p><strong>Fixed Limit</strong> - Each game has a specified betting limit imposed, and a player cannot raise beyond this.</p>
        <p><strong>Pot Limit</strong> - A player may bet up to the current amount of money in the pot.</p>
        <p><strong>No Limit</strong> -&nbsp;There is no maximum, and you are allowed to bet your entire game wallet.</p>

        <h2>Game Progression:</h2>
        <ul>
          <li>The dealer is decided and the blind bets are paid.</li>
          <li>Each player is dealt 4 cards, and then the first betting round begins. As the blinds are considered to be a live bet, betting starts from the player left of the big blind.</li>
          <li><strong>The flop</strong>, the first 3 community cards are dealt on the table, followed by a new betting round.</li>
          <li><strong>The turn</strong>, the fourth community card is dealt, followed by a new betting round.</li>
          <li><strong>The river</strong>, last community card is dealt, followed by the last betting round.</li>
          <li>After the last betting round, the game ends with a showdown to decide the winner.</li>
        </ul>
      </PokerRulesItem>

      <PokerRulesItem heading='5 Card Stud'>
        <h2>Objective</h2>
        <p>Form the highest ranked 5 card poker hand. In the case that ties occur on the value of cards, suits will be taken into account in the following order: (Highest) Spades, Hearts, Clubs, and Diamond (Lowest).</p>
        <h2>Alternative win condition:</h2>
        <p>All other players fold.</p>

        <h2>Betting Structure:</h2>
        <ul>
          <li><strong>Dealer</strong> - The player starting the hand, indicated by a small dealer icon next to his nickname.</li>
          <li><strong>Ante</strong> - A forced bet imposed on all players at the start of each hand.</li>
          <li><strong>Bring in</strong> - A forced bet imposed at the start of the first betting round, paid by the player with the highest up card.</li>
        </ul>

        <h2>Betting options:</h2>
        <p><strong>Bet</strong> - Place a bet that other players must call to stay in the game. Each betting round starts with the player with the best combination of up cards, then moves in a clockwise direction around the table.</p>
        <p><strong>Raise</strong> - Increase the current bet, other players must call your raise to stay in the game. There is a limit of three re-raises in all betting rounds.</p>
        <p><strong>Call</strong> - Match another player&apos;s bet or raise.</p>
        <p><strong>Check</strong> - Remain in the hand without betting, only available if no other player has a placed a higher bet that round.</p>
        <p><strong>Fold</strong> - Withdraw from calling further bets on the hand; forfeit your chance at the pot.</p>

        <h2>Betting Limits:</h2>
        <p><strong>Fixed Limit</strong> - Each game has a specified betting limit imposed, and a player cannot raise beyond this.</p>
        <p><strong>Pot Limit</strong> - A player may bet up to the current amount of money in the pot.</p>

        <h2>Game Progression:</h2>
        <ul>
          <li>The dealer is decided and the blind bets are paid.</li>
          <li>Each player is dealt 1 down card and one up card. The first betting round begins, the bring in is imposed on the player with the highest up card, and moving in a clockwise direction each player calls, raises on the bring in, or folds.</li>
          <li>Once betting has concluded, the third card is dealt face up, followed by the next betting round.</li>
          <li>Then the fourth card is dealt face up, and another betting round starts.</li>
          <li>The fifth and last card is then dealt face up, followed by the last round of betting.</li>
          <li>The game then moves to a showdown, and the player with the highest ranked 5 card hand wins.</li>
        </ul>
      </PokerRulesItem>

      <PokerRulesItem heading='7 Card Stud Hi/Lo'>
        <h2>Objective</h2>
        <p>Form the highest ranked 5 card poker hand OR obtain the lowest hand.</p>

        <h2>To form a low hand:</h2>
        <ul>
          <li>No card higher than an 8.</li>
          <li>Straights and flushes do not count towards the low hand. All other combinations disqualify the low hand, such as pairs.</li>
          <li>Aces can be counted as high or low cards, so the best low hand is A-2-3-4-5 of any suit. This hand may also count as a 5 high straight for the high.</li>
        </ul>
        <p>In the case that ties occur on the value of cards, suits will be taken into account in the following order: (Highest) Spades, Hearts, Clubs, and Diamond (Lowest).<strong><span> <br /></span></strong></p>

        <h2>Alternative win condition:</h2>
        <p>All other players fold.</p>

        <h2>Betting Structure:</h2>
        <ul>
          <li><strong>Dealer</strong> - The player starting the hand, indicated by a small dealer icon next to his nickname.</li>
          <li><strong>Ante</strong> - A forced bet imposed on all players at the start of each hand.</li>
          <li><strong>Bring in</strong> - A forced bet imposed at the start of the first betting round, paid by the player with the highest up card.</li>
        </ul>

        <h2>Betting options:</h2>
        <p><strong><span>B</span>et</strong> - Place a bet that other players must call to stay in the game. Each betting round starts with the player with the best combination of up cards, then moves in a clockwise direction around the table.</p>
        <p><strong>Raise</strong> - Increase the current bet, other players must call your raise to stay in the game. There is a limit of three re-raises in all betting rounds.</p>
        <p><strong>Call</strong> - Match another player&apos;s bet or raise.</p>
        <p><strong>Check</strong> - Remain in the hand without betting, only available if no other player has a placed a higher bet that round.</p>
        <p><strong>Fold</strong> - Withdraw from calling further bets on the hand; forfeit your chance at the pot.</p>

        <h2>Betting Limits:</h2>
        <p><strong>Fixed Limit</strong> - Each game has a specified betting limit imposed, and a player cannot raise beyond this.</p>
        <p><strong>Pot Limit</strong> - A player may bet up to the current amount of money in the pot.</p>

        <h2>Game Progression:</h2>
        <ul>
          <li>The dealer is decided and the blind bets are paid.</li>
          <li>Each player is dealt 2 down cards and one up card. The first betting round begins, the bring in is imposed on the player with the highest up card, and moving in a clockwise direction each player calls, raises on the bring in, or folds.</li>
          <li>Once betting has concluded, the fourth card is dealt face up, followed by the next betting round.</li>
          <li>Then the fifth card is dealt face up, and another betting round starts.</li>
          <li>The sixth card is then dealt face up, followed again with a round of betting.</li>
          <li>The seventh and last card is then dealt face down, followed by the last round of betting.</li>
          <li>The game then moves to a showdown, where the pot is split between the highest hand and the lowest low hand. If there are no qualifying low hands, the pot is scooped by the highest hand.</li>
        </ul>
      </PokerRulesItem>

      <PokerRulesItem heading='7 Card Stud'>
        <h2>Objective</h2>
        <p>Form the highest ranked 5 card poker hand. In the case that ties occur on the value of cards, suits will be taken into account in the following order: (Highest) Spades, Hearts, Clubs, and Diamond (Lowest).</p>

        <h2>Alternative win condition:</h2>
        <p>All other players fold.</p>

        <h2>Betting Structure:</h2>
        <ul>
          <li><strong>Dealer</strong> - The player starting the hand, indicated by a small dealer icon next to his nickname.</li>
          <li><strong>Ante</strong> - A forced bet imposed on all players at the start of each hand.</li>
          <li><strong>Bring in</strong> - A forced bet imposed at the start of the first betting round, paid by the player with the highest up card.</li>
        </ul>

        <h2>Betting options:</h2>
        <p><strong>Bet</strong> - Place a bet that other players must call to stay in the game. Each betting round starts with the player with the best combination of up cards, then moves in a clockwise direction around the table.</p>
        <p><strong>Raise</strong> - Increase the current bet, other players must call your raise to stay in the game. There is a limit of three re-raises in all betting rounds.</p>
        <p><strong>Call</strong> - Match another player&apos;s bet or raise.</p>
        <p><strong>Check</strong> - Remain in the hand without betting, only available if no other player has a placed a higher bet that round.</p>
        <p><strong>Fold</strong> - Withdraw from calling further bets on the hand; forfeit your chance at the pot.</p>

        <h2>Betting Limits:</h2>
        <p><strong>Fixed Limit</strong> - Each game has a specified betting limit imposed, and a player cannot raise beyond this.</p>
        <p><strong>Pot Limit</strong> - A player may bet up to the current amount of money in the pot.</p>

        <h2>Game Progression:</h2>
        <ul>
          <li>The dealer is decided and the blind bets are paid.</li>
          <li>Each player is dealt 2 down cards and one up card. The first betting round begins, the bring in is imposed on the player with the highest up card, and moving in a clockwise direction each player calls, raises on the bring in, or folds.</li>
          <li>Once betting has concluded, the fourth card is dealt face up, followed by the next betting round.</li>
          <li>Then the fifth card is dealt face up, and another betting round starts.</li>
          <li>The sixth card is then dealt face up, followed again with a round of betting.</li>
          <li>The seventh and last card is then dealt face down, followed by the last round of betting.</li>
          <li>The game then moves to a showdown, and the player with the highest ranked 5 card hand formed from his 7 cards in possession wins.</li>
        </ul>
      </PokerRulesItem>

      <PokerRulesItem heading='Razz'>
        <h2>Objective</h2>
        <p>Form the lowest ranked 5 card poker hand, straights and flushes are not ranked. In the case that ties occur on the value of cards, suits will be taken into account in the following order: (Highest) Spades, Hearts, Clubs, and Diamond (Lowest).</p>

        <h2>Alternative win condition:</h2>
        <p>All other players fold.</p>

        <h2>Betting Structure:</h2>
        <p><strong>Dealer</strong> - The player starting the hand, indicated by a small dealer icon next to his nickname.</p>
        <p><strong>Ante</strong> - A forced bet imposed on all players at the start of each hand.</p>
        <p><strong>Bring in</strong> - A forced bet imposed at the start of the first betting round, paid by the player with the highest up card.</p>

        <h2>Betting options:</h2>
        <p><strong>Bet</strong> - Place a bet that other players must call to stay in the game. Each betting round starts with the player with the best combination of up cards, then moves in a clockwise direction around the table.</p>
        <p><strong>Raise</strong> - Increase the current bet, other players must call your raise to stay in the game. There is a limit of three re-raises in all betting rounds.</p>
        <p><strong>Call</strong> - Match another player&apos;s bet or raise.</p>
        <p><strong>Check</strong> - Remain in the hand without betting, only available if no other player has a placed a higher bet that round.</p>
        <p><strong>Fold</strong> - Withdraw from calling further bets on the hand; forfeit your chance at the pot.</p>

        <h2>Betting Limits:</h2>
        <p><strong>Fixed Limit</strong> - Each game has a specified betting limit imposed, and a player cannot raise beyond this.</p>
        <p><strong>Pot Limit</strong> - A player may bet up to the current amount of money in the pot.</p>

        <h2>Game Progression:</h2>
        <ul>
          <li>The dealer is decided and the blind bets are paid.</li>
          <li>Each player is dealt 2 down cards and one up card. The first betting round begins, the bring in is imposed on the player with the highest up card, and moving in a clockwise direction each player calls, raises on the bring in, or folds.</li>
          <li>Once betting has concluded, the fourth card is dealt face up, followed by the next betting round.</li>
          <li>Then the fifth card is dealt face up, and another betting round starts.</li>
          <li>The sixth card is then dealt face up, followed again with a round of betting.</li>
          <li>The seventh and last card is then dealt face down, followed by the last round of betting.</li>
          <li>The game then moves to a showdown, and the player with the lowest ranked 5 card hand formed from his 7 cards in possession wins.</li>
        </ul>
      </PokerRulesItem>
    </div>
  </div>
}

import React from 'react'

import styles from './Settings.module.css'

export default function Group (props) {
  const {children, heading} = props

  return <div className={styles.group}>
    <label className={styles.heading}>{heading}</label>
    {children}
  </div>
}

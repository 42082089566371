import invokeMap from 'lodash/invokeMap'

import Card from '../../../models/card.coffee'

export default ({signal, details, app, game, client, listing}) ->
  return unless game

  # ensure we don't re-trigger the river animation
  # unless cardString is game.communityString
  cCards = []
  for card in details.cards
    cCards.push new Card card
  game.community = cCards

  # hand description if present
  if details.description
    client.description = details.description

  unless game.currentState?.current is 'showdown'
    game.updateHandHistory
      state: signal.updates.currentState?.current or null
      message: "Community Cards: " + invokeMap(game.community, 'toJavaString').join(',')

  format = 'important'
  switch game.community.length
    when 3
      hand = invokeMap(game.community, 'toJavaString').join(',')
      message = "Dealing Flop (#{hand})"
      client.displayChatMessage {message, format}

    when 4
      hand = game.community[3].toJavaString()
      message = "Dealing Turn (#{hand})"
      client.displayChatMessage {message, format}

    when 5
      hand = game.community[4].toJavaString()
      message = "Dealing River (#{hand})"
      client.displayChatMessage {message, format}

export default (details) ->
  switch details.type
    when 'callRaiseAllInFold' then return ['fold', 'call', 'raise', 'allIn']
    when 'callRaiseFold' then return ['fold', 'call', 'raise']
    when 'callAllInFold' then return ['fold', 'call', 'allIn']
    when 'callFold' then return ['fold', 'call']

    when 'checkBetAllInFold' then return ['fold', 'check', 'bet', 'allIn']
    when 'checkRaiseAllInFold' then return ['fold', 'check', 'raise', 'allIn']
    when 'checkAllInFold' then return ['fold', 'check', 'allIn']
    when 'checkRaiseFold' then return ['fold', 'check', 'raise']
    when 'checkBetFold' then return ['fold', 'check', 'bet']

    when 'allInFold' then return ['fold', 'allIn']
    when 'allInSitout' then return ['allIn', 'sitOut']

    when 'showMuck' then return ['show', 'muck']
    when 'showMuckRabbit' then return ['show', 'muck', 'rabbit']
    when 'show' then return ['show']

    when 'smallBlind' then return ['postSb', 'sitOut']
    when 'bigBlind' then return ['postBb', 'sitOut']
    when 'initialBlind' then return ['postIb', 'waitIb']
    when 'returnBlind' then return ['postRb', 'waitRb']

    when 'sitOut' then return ['sitOut', 'rebuy']

  return []

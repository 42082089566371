import {FIXED_LIMIT} from '@pipehat/chronicle/constants/limit-type'
import {FAST} from '@pipehat/chronicle/constants/table-speed'

import {useBalance as useWalletBalance} from '../../wallet/react-hooks.js'
import {useAdvancedOptions} from '../context.js'
import {useOption, useService} from '../react-hooks.js'

export const useAutoRebuyAmount = () => useOption('autoRebuyAmount')
export const useAutoRebuyPoint = () => useOption('autoRebuyPoint')
export const useBalance = () => useWalletBalance(useSelector(({currency}) => currency))
export const useDisableAutoRebuy = () => useService().disableAutoRebuy
export const useEnableAutoRebuyWhenBelowTableMinimum = () => useService().enableAutoRebuyWhenBelowTableMinimum
export const useEnableAutoRebuyWhenOutOfChips = () => useService().enableAutoRebuyWhenOutOfChips
export const useInitialBuyIn = () => useSelector(({initialBuyIn}) => initialBuyIn)
export const useIsAutoMuck = () => useOption('isAutoMuck')
export const useIsAutoPostBlinds = () => useOption('isAutoPostBlinds')
export const useIsAutoRebuy = () => useOption('isAutoRebuy')
export const useIsFast = () => useSelector(({speed}) => speed === FAST)
export const useIsTournament = () => useSelector(({tournamentId}) => !!tournamentId)
export const useMinimumBuyIn = () => useSelector(({minimumBuyIn}) => minimumBuyIn)
export const useSetAutoRebuyAmount = () => useService().setAutoRebuyAmount
export const useShowInsufficientFundsForAutoRebuy = () => useService().showInsufficientFundsForAutoRebuy
export const useShowInvalidAmount = () => useService().showInvalidAmount
export const useShowLeaveConfirmation = () => useService().showLeaveConfirmation
export const useStakes = () => useSelector(({stakes}) => stakes)
export const useToggleAutoMuck = () => useService().toggleAutoMuck
export const useToggleAutoPostBlinds = () => useService().toggleAutoPostBlinds
export const useToggleAutoRebuy = () => useService().toggleAutoRebuy
export const useTournamentId = () => useSelector(({tournamentId}) => tournamentId)

export const useMaximumBuyIn = () => {
  const balance = useBalance()
  const isFixedLimit = useIsFixedLimit()
  const maximumBuyIn = useSelector(({maximumBuyIn}) => maximumBuyIn)

  // fixed limit has no maximum buy-in and is only limited by own balance
  if (isFixedLimit) return balance

  return maximumBuyIn
}

const useIsFixedLimit = () => useSelector(({stakes}) => stakes?.limitType === FIXED_LIMIT)

function useSelector (selector) {
  return selector(useAdvancedOptions())
}

export default ({signal, app, listing}) ->
  tournament = listing
  gameName = tournament?.gameName or signal.tournamentRegistration.gameName

  app.getRealMoneyBalance()
  app.getMyGames()

  if signal.success
    app.alreadyAutoPinned = (app.alreadyAutoPinned?.filter (entry) -> entry isnt tournament?.instanceId) or []
    # not REQUIRED, but can remove client from tournament player listing immediately
    player = tournament?.players.find {alias: app.user.nickname}
    if player?
      tournament.players.rem player
    # update UI
    tournament?.deregistration()
    tournament?.pinned = false
    app.tournamentDeregistration true, gameName
  else
    reason = signal.failReason
    switch signal.failReason
      when 'registrationClosed'
        reason = 'Registration Closed'
      when 'userNotRegistered'
        reason = 'User Not Registered'
        listing?.registered = false
    app.tournamentDeregistration false, gameName, reason

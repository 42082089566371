# AddonView
# Model: client

import BettingControlsView from './bettingControlsView.coffee'
import PaymentOptionsSelect from './paymentOptionsSelect.coffee'

export default class AddonView extends BettingControlsView

  constructor: (client, modalTarget) ->
    super()

    @model = client
    @modalTarget = modalTarget

    @dom = $ '<div class="rebuy-modal-content">'

    # ### Components - eg. DOM elements & views
    @components =
      cashierLink:  $ "<span class='buyin-cashier-link'>(Visit Cashier)</span>"
      select:       new PaymentOptionsSelect [], false, 'rebuy-payment-options'
      columns:      $ '<div><div class="details left"></div><div class="details right"></div></div>'
      userBalance:  $ "<div class='balance'>My Balance: <span class='value'></span></div>"
      currentLevel: $ "<div class='balance'>Current Level: <span class='value'></span></div>"
      nextLevel:    $ "<div class='balance'>Next Level: <span class='value'></span></div>"
      gameTimer:    $ "<div class='balance'>Time Remaining: <span class='value game-timer-value'></span></div>"

    # ### Events - eg. MouseClicks ###
    @components.cashierLink.on 'click', @model.visitCashier

    # ### Listeners - eg. auth:success
    @listeners =
      'show:addon': @show

    @model.on 'hide:addonViewModal', @hideCurrentModal

    # ### Changes - eg. Model.property = true
    # NOTE: individual components are not bound to variables since a refresh()
    # is called on show() which updates all components in one go
    @changes =
      'onBreak': @updateOnBreak

    # ### Binding ###
    @initializeBettingConstrolsView arguments...
    return this

  render: =>
    @appendComponents()
    @components.userBalance.appendTo @components.columns.find '.left'
    @components.currentLevel.appendTo @components.columns.find '.right'
    @components.nextLevel.appendTo @components.columns.find '.right'
    @components.gameTimer.appendTo @components.columns.find '.right'
    @components.cashierLink.appendTo @components.userBalance
    @components.cashierLink.appendTo @components.userBalance
    @dom.hide()
    return @dom

  refresh: =>
    # @updateSelect()
    @components.currentLevel.find('.value').html @model.game.currentLevelDescription
    @components.nextLevel.find('.value').html @model.game.nextLevelDescription
    @updateUserBalance()
    @dom.show()
    return @dom

  updateSelect: (paymentOptions) =>
    @components.select.model = paymentOptions
    @components.select.render()

  updateUserBalance: =>
    @components.userBalance.find('.value').html @model.userBalance.toMoney()

  show: ({paymentOptions, gameTimer}) =>
    clearInterval @gameTimerInterval

    if gameTimer
      @components.gameTimer.find('.value').html gameTimer
      @gameTimerInterval = setInterval =>
        @components.gameTimer.find('.value').html gameTimer-- # coffeelint: disable-line=check_scope
        if gameTimer <= 0
          clearInterval @gameTimerInterval
          @currentModal?.teardown()
      , @gameTimerTick or 1000
      @components.gameTimer.show()
    else
      @components.gameTimer.hide()

    if paymentOptions
      @updateSelect paymentOptions

    @refresh()

    if @currentModal?
      # console.warn 'already have modal for addon - aborting'
      return

    @currentModal = @modal
      title: 'Addon'
      content: @dom
      className: 'bettingControlsModal'
      target: @modalTarget
      stackOrder: 1 # always stack below rebuy
      detach: true
      choices: [
        {
          value: 'Cancel'
          key: 'escape'
          action: @decline
        }
        {
          value: 'Confirm'
          key: 'enter'
          action: @submit
        }
      ]
      teardown: =>
        @model.emit 'hide:addonViewModal'
        @currentModal = null

  hideCurrentModal: =>
    @currentModal?.quiteTeardown?()

  submit: =>
    @model.action 'addon',
      accept: true
      paymentId: @components.select.dom.val()

  decline: =>
    @model.action 'addon',
      accept: false

  updateOnBreak: =>
    unless @model.onBreak
      @currentModal?.teardown()

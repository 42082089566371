import {fromNumber, isGreaterThan, isLessThan, toNumber} from '@pipehat/money/plain-object'
import React from 'react'

import PresetAmountButton from './PresetAmountButton.js'
import {useActivePotAmount, useCurrency} from '../../../react-hooks.js'

export default function PresetAmountOptions (props) {
  const {handleSetAmount, max, min} = props
  const currency = useCurrency()
  const potAmount = useActivePotAmount()
  const isLessThanMin = isLessThan(min)
  const isGreaterThanMax = isGreaterThan(max)

  const potNumber = potAmount ? toNumber(potAmount) : 0
  const multiNumber = toNumber(min)

  const presets = [
    {label: '2/3', value: fromNumber(currency, (2 / 3) * potNumber)},
    {label: '3/4', value: fromNumber(currency, (3 / 4) * potNumber)},
    {label: 'POT', value: fromNumber(currency, potNumber)},
    {label: 'x2', value: fromNumber(currency, multiNumber * 2)},
    {label: 'x4', value: fromNumber(currency, multiNumber * 4)},
    {label: 'x6', value: fromNumber(currency, multiNumber * 6)},
    {label: 'x8', value: fromNumber(currency, multiNumber * 8)},
  ]

  return <>
    {presets.map(({label, value}) => {
      return <PresetAmountButton
        key={label}
        isDisabled={isLessThanMin(value) || isGreaterThanMax(value)}
        label={label}
        value={value}
        onClick={handleSetAmount}
      />
    })}
  </>
}

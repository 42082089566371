import React from 'react'

import DesktopSelectionButton from '../../components/DesktopSelectionButton.js'
import {useSetSpeed, useSpeed} from '../react-hooks.js'

export default function SpeedButton (props) {
  const {label, speed} = props
  const isSelected = useSpeed() === speed

  return <DesktopSelectionButton label={label} isSelected={isSelected} onClick={useSetSpeed(speed)} />
}

import {RAISE} from '@pipehat/chronicle/constants/action'
import {sum} from '@pipehat/money/plain-object'
import React, {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'

import CloseBetRaiseAmountButton from './components/CloseBetRaiseAmountButton.js'
import PresetAmountOptions from './components/PresetAmountOptions.js'
import MoneyStepper from '../../../../money/components/MoneyStepper.js'
import {useTableId} from '../../../context.js'
import styles from '../../Actions.module.css'
import {setBetRaiseAmount} from '../../actions-slice.js'
import {useBetRaiseAmount, useSelfActions} from '../../react-hooks.js'

export default function BetRaiseAmount () {
  const dispatch = useDispatch()
  const tableId = useTableId()
  const amount = useBetRaiseAmount()
  const selfActions = useSelfActions()

  const {actions, currentBet, maximumBet, maximumRaise, minimumBet, minimumRaise} = selfActions
  const isRaise = actions.includes(RAISE)
  const step = isRaise ? minimumRaise : minimumBet

  const min = useMemo(() => {
    return isRaise ? sum([currentBet, minimumRaise]) : minimumBet
  }, [currentBet, isRaise, minimumBet, minimumRaise])

  const max = useMemo(() => {
    return isRaise ? sum([currentBet, maximumRaise]) : maximumBet
  }, [currentBet, isRaise, maximumBet, maximumRaise])

  const handleSetAmount = useCallback(amount => {
    dispatch(setBetRaiseAmount({amount, tableId}))
  }, [dispatch, tableId])

  return <div className={styles.betRaiseAmount}>
    <div className={styles.presets}>
      <PresetAmountOptions handleSetAmount={handleSetAmount} min={min} max={max} />
      <CloseBetRaiseAmountButton />
    </div>

    <MoneyStepper slider min={min} max={max} step={step} value={amount} onChange={handleSetAmount} />
  </div>
}

import {BIG_BLIND, INITIAL_BLIND, RETURN_BLIND, SMALL_BLIND} from '@pipehat/chronicle/constants/action'
import React from 'react'

import PostBigBlindButton from './components/PostBigBlindButton.js'
import PostInitialBlindButton from './components/PostInitialBlindButton.js'
import PostReturnBlindButton from './components/PostReturnBlindButton.js'
import PostSmallBlindButton from './components/PostSmallBlindButton.js'
import SitOutButton from './components/SitOutButton.js'
import WaitForInitialBlindButton from './components/WaitForInitialBlindButton.js'
import WaitForReturnBlindButton from './components/WaitForReturnBlindButton.js'
import {useAvailableActions} from '../react-hooks.js'

export default function StartHand () {
  const actions = useAvailableActions()
  const shouldShowSmallBlind = actions.includes(SMALL_BLIND)
  const shouldShowBigBlind = actions.includes(BIG_BLIND)
  const shouldShowSitOut = shouldShowSmallBlind || shouldShowBigBlind
  const shouldShowInitialBlind = actions.includes(INITIAL_BLIND)
  const shouldShowReturnBlind = actions.includes(RETURN_BLIND)

  return <>
    {shouldShowSmallBlind && <PostSmallBlindButton />}
    {shouldShowBigBlind && <PostBigBlindButton />}
    {shouldShowSitOut && <SitOutButton />}

    {shouldShowInitialBlind && <>
      <PostInitialBlindButton />
      <WaitForInitialBlindButton />
    </>}

    {shouldShowReturnBlind && <>
      <PostReturnBlindButton />
      <WaitForReturnBlindButton />
    </>}
  </>
}

# 3:30:02 PM Dave Drewery: The function name is AUTO_REBUY with an argument of AUTO_REBUY
# 3:30:18 PM Dave Drewery: code1 is TRUE if auto rebuy is enable, FALSE if not
# 3:33:58 PM Dave Drewery: assuming code1 is TRUE, value1 is the point at which to
# rebuy (must be 1c or greater), code2 is set to DATA
# and value2 is the amount that you want to rebuy to, and if code3 is set to TRUE,
# auto rebuy will be disabled
import Request from './request.coffee'

import {AUTO_REBUY_POINT_TABLE_MIN} from '../../../../rooms/constants.js'

export default class AutoRebuyRequest extends Request
  constructor: ({client, message, autoRebuy}) ->
    super arguments...

    @function = 'AUTO_REBUY'

    @attributes =
      type: 'REQUEST'
      service: 'GAME'
      routerId: message.routerId
      serverId: client.serverId
      instanceId: client.instanceId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    # if you set the key and the value to be the same the serializer will
    # make it only a code
    if autoRebuy.enabled
      if autoRebuy.point is AUTO_REBUY_POINT_TABLE_MIN
        point = client.game.minBuyIn
        amount = autoRebuy.amount
      else
        point = '0.01'
        amount = autoRebuy.amount
      autoRebuyArg = {TRUE: point, data: amount}
    else
      autoRebuyArg = {'false': 'false'}

    @arguments =
      auto_rebuy: autoRebuyArg

import classnames from 'classnames'
import React from 'react'

import styles from './Selection.module.css'

export default function DesktopSelectionButton ({isSelected, label, onClick}) {
  return <button
    type='button'
    className={classnames({[styles.selected]: isSelected})}
    onClick={onClick}
  >
    {label}
  </button>
}

import React, {useCallback, useEffect, useState} from 'react'

import LabelRow from './LabelRow.js'
import styles from './Settings.module.css'

import {
  useNotificationMinutesBeforeTournamentStarts,
  useSetNotificationMinutesBeforeTournamentStarts,
} from '../react-hooks.js'

export default function TournamentStartsInMinutesNotifications () {
  const initialValue = useNotificationMinutesBeforeTournamentStarts()
  const setNotificationMinutesBeforeTournamentStarts = useSetNotificationMinutesBeforeTournamentStarts()
  const [state, setState] = useState(initialValue)

  useEffect(() => {
    setState(initialValue)
  }, [initialValue, setState])

  const handleBlur = useCallback(event => {
    const value = parseInt(event.target.value, 10)
    if (isNaN(value)) return

    if (value !== initialValue) setNotificationMinutesBeforeTournamentStarts(value)
  }, [initialValue, setNotificationMinutesBeforeTournamentStarts])

  const handleChange = useCallback(event => {
    setState(event.target.value)
  }, [setState])

  return <LabelRow
    name='Tournament starts notification'
    help='Will display a notification this many minutes before a tournament you have registered for starts. 0 is off.'
  >
    <input
      className={styles.numberInput}
      type='number'
      min='0'
      onBlur={handleBlur}
      onChange={handleChange}
      value={state}
    /> minutes before
  </LabelRow>
}

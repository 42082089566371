import React from 'react'

import DesktopSelectionButton from '../../components/DesktopSelectionButton.js'
import {useLimitType, useSetLimitType} from '../react-hooks.js'

export default function LimitTypeButton (props) {
  const {label, limitType} = props
  const isSelected = useLimitType() === limitType

  return <DesktopSelectionButton label={label} isSelected={isSelected} onClick={useSetLimitType(limitType)} />
}

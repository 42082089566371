# LoginData
#  long sessionId
#  String nickname
#  int defaultGroupId
#  String operatorHash
#  long lastNotificationId
#  Date serverTime
#  int classId
#  String token
#  boolean realMoney
#  String externalNickname
#  boolean externalNicknameValid
#  boolean nicknamePromptRequired

# untranslated:
#    { result: 'success',
#      serverTime: '20130313233729',
#      nickName: 'webclient4',
#      classId: '5',
#      userId: '230063',
#      lastEventId: '483652',
#      hash: 'b69b897ca42836f733e3055b22e15250',
#      defaultGroupId: '24' },

# <message id="0" service="CLIENT" type="RESPONSE" sessionId="615527" routerId="61" clientId="839" serverId="0" instanceId="0">
#   <function name="LOGINUSER">
#     <argument name="RESULT" code1="SUCCESS"/>
#     <argument name="SERVERTIME" code1="DATA" value1="20130313233729"/>
#     <argument name="NICKNAME" code1="DATA" value1="webclient4"/>
#     <argument name="CLASSID" code1="DATA" value1="5" code2="TRUE"/>
#     <argument name="USERID" code1="DATA" value1="230063"/>
#     <argument name="LASTEVENTID" code1="DATA" value1="483652"/>
#     <argument name="HASH" code1="DATA" value1="b69b897ca42836f733e3055b22e15250"/>
#     <argument name="DEFAULTGROUPID" code1="DATA" value1="24"/>
# </function>
# </message>

# loginUser function
# messageDetails =
#   session: signal.message.sessionId
#   router: signal.message.routerId
#   client: signal.message.clientId
#   server: signal.message.serverId
#   nickname: signal.untranslated.nickName
#   userId: signal.untranslated.userId
import Mixmaster from '../../../mixmaster.coffee'

export default class LoginData extends Mixmaster

  mixin: (object) ->
    object.nickName = (signal, name, values) ->
      signal.login.nickname = values

    object.nickNamePrompt = (signal, name, values) ->
      signal.login.updateNicknamePrompt = true

    object.externalNickName = (signal, name, values) ->
      signal.login.externalNickname = true

    object.nickNameValid = (signal, name, values) ->
      signal.login.nicknameValid = true

    object.defaultGroupId = (signal, name, values) ->
      signal.login.defaultGroupId = parseInt(values, 10)

    object.hash = (signal, name, values) ->
      signal.login.hash = values

    object.lastEventId = (signal, name, values) ->
      # long is an integer but parseInt has precision problems
      signal.login.lastEventId = values

    object.classId = (signal, name, values) ->
      # in different envs the classId is a different format -_-
      signal.login.classId = parseInt(values.value1,10) or parseInt(values,10)

    object.session = (signal, name, values) ->
      signal.login.session = values

    object.token = (signal, name, values) ->
      signal.login.token = values

    object.realMoney = (signal, name, values) ->
      signal.login.realMoney = values

    object.userId = (signal, name, values) ->
      signal.login.userId = values

    object.accolade = (signal, name, values) ->
      signal.login.accolade = values # bool

    object.hideMyGames = (signal, name, values) ->
      signal.login.hideMyGames = values # bool

    object.denyFriendRequests = (signal, name, values) ->
      signal.login.denyFriendRequests = values # bool

    object.securityToken = (signal, name, values) ->
      signal.login.securityToken = values.securityToken

    object.country = (signal, name, values) ->
      signal.login.country = values

    object.userOption = (signal, name, values) ->
      signal.login.userOptions ?= {}
      switch parseInt(values.value1)
        when 1
          signal.login.userOptions.activeTablesLimit = parseInt(values.value2, 10)
        when 2
          signal.login.userOptions.playMoneyGamesAvailable = values.value2 is 'true'

    object.tagList = (signal, name, values) ->
      # "3,4,5,8,334"
      # Pass straight to game lists requests and server
      # will filter for us.
      playerTags = values.value1.split(',')
      if playerTags[0] # will be ''
        playerTags = playerTags.map (tag) ->
          return parseInt(tag,10)
      else
        playerTags = []

      # "1,2"
      # 1 - Player box color (Allow customise colour)
      # 2 - Player tagging (Allow tagging)
      # 5 - *NEW Allow heads up ring games (handled on server side)
      features = values.value2.split(',')
      if features[0] # will be ''
        features = features.map (tag) ->
          return parseInt(tag,10)
      else
        features = []

      signal.login.tagList = {
        playerTags: playerTags
        features: features
        playerTagsString: values.value1
        featuresString: values.value2
      }

import React from 'react'
import {useSelector} from 'react-redux'

import {RoomProvider as Provider} from './context.js'

export default function RoomProvider ({children, tableId}) {
  const context = useSelector(state => state.rooms[tableId])

  if (!context || !context.tableId) return null

  return <Provider value={context}>
    {children}
  </Provider>
}

export function getLocalStorage (window) {
  return getStorage(window, 'localStorage')
}

export function getSessionStorage (window) {
  return getStorage(window, 'sessionStorage')
}

function getStorage (window, type) {
  try {
    const storage = window[type]
    const check = Math.random().toString(36).substring(7)

    storage.setItem(check, check)

    if (storage.getItem(check) === check) {
      storage.removeItem(check)

      return storage
    }
  } catch (error) {
    // storage full or disabled
  }
}

import React from 'react'
import {Link} from 'react-router5'

import {TOURNAMENT} from '../../../routes.js'
import {useTournamentId} from '../react-hooks.js'

export default function TournamentLobbyButton () {
  const tournamentId = useTournamentId()

  return <Link className='button' routeName={TOURNAMENT} routeParams={{tournamentId: String(tournamentId)}}>
    <span className='fa fa-sign-out button-icon' /> Tournament Lobby
  </Link>
}

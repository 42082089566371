import Request from './request.coffee'

export default class RemoveFromWaitlistMessage extends Request
  constructor: ({client, message, listing}) ->
    super arguments...

    @function = 'REMOVE_FROM_WAITLIST'

    if client?
      {instanceId, serverId} = client
    else
      {instanceId, serverId} = listing

    @attributes =
      type: 'REQUEST'
      service: 'GAME'
      routerId: message.routerId
      serverId: serverId
      instanceId: instanceId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

# Client's Wallet Balance.
# send a GET_BALANCE request with the AUTHENTICATION service to get the player's wallet balance
# need to provide a REAL_MONEY argument with the argument value1 attribute set to "true"
# if you want to get their play money balance, this argument can be omitted or you can put "false" as the value
# you will receive 3 arguments on the successful response, a BALANCE argument, a REAL_MONEY argument with the
#  value you specified (or false if it was omitted) and the (deprecated) user's VIP points balance.

import Request from './request.coffee'

export default class GetBalanceRequest extends Request
  constructor: ({message, realMoney}) ->
    super arguments...

    @function = 'GET_BALANCE'

    @attributes =
      type: 'REQUEST'
      service: 'AUTHENTICATION'
      serverId: 0
      routerId: message.routerId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @arguments =
      REAL_MONEY: realMoney

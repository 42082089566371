import Listing from '../../../models/listing.coffee'
import ListTypeEnum from '../../../enum/enums/listTypeEnum.coffee'

export default ({signal, app}) ->

  listTypeObj = Object.values(ListTypeEnum).find ({description}) -> description is signal.parent?.arguments?.list_type
  listTypeId = listTypeObj?.id

  for details in signal.games

    # # Filter out "deal it twice" games. They do not have a gamesListFlag,
    # #  so we need to match on name
    if /Deal It Twice/.test details.gameName
      continue

    # # WEB-1273 PLACEHOLDER - UNTESTED
    # if details.SEE510 not app.user.tagList.match 'SEE510'
    #   continue

    game = app.listings.find {instanceId: details.instanceId}

    if game?
      # update playerCount if it has changed, so lobby view stays up to date
      if game.playerCount isnt details.playerCount
        game.playerCount = details.playerCount
    else
      details.listType = listTypeId
      app.listings.add new Listing {details}

  # Clean up obsolete ring games
  existing = app.listings.where {listType: listTypeId}
  existing.forEach (listing) ->
    inSignal = signal.games.find ({instanceId}) -> instanceId is listing.instanceId
    unless inSignal
      app.listings.rem listing

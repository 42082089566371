export function createService (communicator) {
  return {
    loadExclusions () {
      communicator.message('getUserGameExclusions')
    },

    createExclusions (exclusions) {
      const {USD} = exclusions

      communicator.message('updateGameExclusions', {
        exclusions: [
          {type: 'REAL_MONEY_GAMES', hours: USD},
        ],
      })
    },
  }
}

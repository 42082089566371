import 'unfetch/polyfill/index.js'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat.js'
import duration from 'dayjs/plugin/duration.js'
import relativeTime from 'dayjs/plugin/relativeTime.js'
import {EventEmitter} from 'events'

import './legacy/modules/utils.coffee'
import {domready} from './dom.js'
import PopUpController from './legacy/modules/popupController.coffee'
import ViewManager from './legacy/views/viewManager.coffee'
import services from './services.js'

dayjs.extend(advancedFormat)
dayjs.extend(duration)
dayjs.extend(relativeTime)

EventEmitter.defaultMaxListeners = 100 // Try keep low to catch event listener leaks

const {userAgent, format, initSentry, isElectron, logger} = services
const {os: {name: osName}} = userAgent

initSentry()

// no need to wait until docready/config/etc to apply format class to body
const bodyClassList = document.body.classList
bodyClassList.add('format-' + format)
if (osName) bodyClassList.add(osName.toLowerCase().replace(/\s/g, '-')) // needed for Android CSS hacks
if (isElectron) bodyClassList.add('electron')

// Utility
window.services = services
window.logger = logger

// Commonly used MVC base classes
window.Webclient = {
  Version: window.VERSION,
  Time: null, // let server set it on first heartbeat
  Format: format,
  ViewManager: new ViewManager(format),
}
window.iOS = ['iPad', 'iPhone', 'iPod', 'iPhone Simulator'].includes(navigator.platform)

const {installationService, notifier, popupParam, settingsService, updatingService} = services

installationService.init()
settingsService.init()
updatingService.init()

domready(() => {
  notifier.init()
})

if (popupParam) {
  domready(function () {
    if (!window.opener) {
      window.close()
    } else if (popupParam === 'tab' || popupParam === 'window') {
      window.opener.popupMaster.setSetting(popupParam)
      window.close()
    }

    const {createPopupReactView, navigator, windowTitleManager} = services

    window.CUI_LOADED = true

    new PopUpController( // eslint-disable-line no-new
      navigator,
      parseInt(popupParam, 10),
      format,
      createPopupReactView,
      windowTitleManager,
    )
  })
} else {
  domready(function () {
    const {
      avatarUrl,
      navigator,
      sounds,
    } = services

    window.AVATAR_URL = avatarUrl
    window.DEBUG = false
    window.Navigator = navigator
    window.Sounds = sounds

    const {app, appView, receiver} = services

    window.CUI_LOADED = true

    receiver.init(app)
    app.start()
    appView.render()
  })
}

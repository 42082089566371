import React from 'react'

import styles from './Chips.module.css'
import Amount from './components/Amount.js'
import Icon from './components/Icon.js'

export default function Chips ({amount, symbol, precision}) {
  return <div className={styles.wrapper}>
    <Icon amount={amount} />
    <Amount amount={amount} symbol={symbol} precision={precision} />
  </div>
}

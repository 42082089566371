import classnames from 'classnames'
import React, {useMemo} from 'react'

import {useRootedSelector} from '../../../redux.js'
import {createIsDeregistrationAllowed, createIsSelfDeregistering, getRoot} from '../../index.js'
import {useDeregister} from '../../react-hooks.js'

export default function DeregisterButton (props) {
  const {tournamentId} = props
  const isDeregistrationAllowed = useRootedSelector(getRoot, useMemo(createIsDeregistrationAllowed, []), props)
  const isSelfDeregistering = useRootedSelector(getRoot, useMemo(createIsSelfDeregistering, []), props)
  const isEnabled = isDeregistrationAllowed && !isSelfDeregistering

  const className = classnames('button', {'button-disabled': !isEnabled})

  return <button type='button' onClick={useDeregister(tournamentId)} className={className} disabled={!isEnabled}>
    <span className='fa fa-ban button-icon' /> Deregister
  </button>
}

import React from 'react'

import styles from './GameDetails.module.css'
import {useShowHandHistory, useTournamentId} from './react-hooks.js'
import Table from './table/Table.js'
import Tournament from './tournament/Tournament.js'

export default function GameDetails () {
  const tournamentId = useTournamentId()

  return <div className={styles.gameDetails} onClick={useShowHandHistory()}>
    {tournamentId ? <Tournament /> : <Table />}
  </div>
}

import classnames from 'classnames'
import React from 'react'

export default function MenuItem (props) {
  const {extraClassName, icon, label, onClick} = props
  const isFontAwesome = icon.startsWith('fa-')
  const iconClassName = classnames({
    fa: isFontAwesome,
    [icon]: isFontAwesome,
    icon: !isFontAwesome,
    [`icon-${icon}`]: !isFontAwesome,
  })
  const labelClassName = classnames({nudge: isFontAwesome})
  const localClassName = classnames('iconLink', extraClassName)

  return <label className={localClassName} onClick={onClick}>
    <span className={iconClassName} />
    <span className={labelClassName}>{label}</span>
  </label>
}

import key from 'hotkeys-js'

# ModalView
# NOTE: this view does *NOT* extend Webcllient.View - it has no true model

export default class ModalView

  constructor: (modal = {}) ->
    @model = modal
    @modalKeyScope = if modal.keyScope then 'modal-' + modal.keyScope else 'modal'

    @dom = $ '<div class="modal"><div class="overlay"><div class="box"></div></div></div>'

    # ### Components - eg. DOM elements & views
    @components =
      title:   $ "<div class='title'>"
      content: $ "<div class='content'>"
      choices: $ "<div class='choices inline-buttons'>"

    # ### Events - eg. MouseClicks ###
    unless @model.dismissible is false
      @dom.find('.overlay').on 'click', @teardown

    @dom.find('.box').on 'click', (event) =>
      event.stopPropagation()

    @keyShortcuts = []

    return this

  render: =>
    target = @dom.find '.box'
    target.empty
    for own _, el of @components
      target.append el

    @components.title.html @model.title
    if @model.icon
      @components.title.prepend "<i class='fa fa-#{@model.icon} title-icon'></i>"
    @components.content.html @model.content

    if @model.className
      target.addClass @model.className
    target.addClass @model.title?.toCamelCase()

    if @model.opaque
      @dom.find('.overlay').addClass 'opaque'

    unless @model.choices
      @model.choices = [{value:'OK', key:'enter,space,escape', icon: 'check'}]

    if @model.minimal
      @components.choices.remove()
      @components.title.remove()
      @dom.addClass 'minimal'
    for choice in @model.choices then do (choice) =>
      btnEl = $ "<button class='button inline-button'>#{choice.value}</button>"
      if choice.disabled
        btnEl.addClass 'button-disabled'
      else
        if choice.key
          key choice.key, @modalKeyScope, =>
            @performChoice choice
          @keyShortcuts.push choice.key
        if choice.target
          btnEl = $ "<a class='button inline-button'>#{choice.value}</a>"
          btnEl.attr('target', '_blank')
          btnEl.attr('href', choice.target)
        if choice.icon
          btnEl.prepend "<i class='fa fa-#{choice.icon} button-icon'></i>"
        if choice.primary
          btnEl.addClass 'primary'
        btnEl.on 'click', =>
          @performChoice choice
      @components.choices.append btnEl

    return @dom

  performChoice: (choice) =>
    if choice.showLoading
      # experimenting with a more responsive UI for slow dialogs like Reset Settings
      @components.title.text 'Please Wait'
      @components.content.html '<div class="modal-loading">' + $('#loader-animation-template').html() + '</div>'
      @components.choices.remove()
      setTimeout =>
        choice.action?()
        @teardown()
      , 200
    else
      choice.action?()
      @teardown()

  # Helper method for rendering this modal over the entire app
  attachToBody: =>
    document.activeElement.blur()
    @render()
    if key.getScope() isnt @modalKeyScope
      @previousScope = key.getScope()
      key.setScope @modalKeyScope
    else if key.getScope() isnt 'modal'
      @previousScope = key.getScope()
      key.setScope 'modal'
    if @model.stackOrder # allow zIndex to be manually overridden
      @dom.css 'z-index', 10000 + @model.stackOrder
    # container to keep modals in correct stacking order
    container = @model.target or $ '#modals'
    unless container.length
      container = $ '<div id="modals">'
      container.prependTo $('body')
    @dom.appendTo container
    primary = $(@dom).find('button.primary')
    if primary.length > 0
      primary.focus()
    return this

  teardown: =>
    for k in @keyShortcuts
      key.unbind k, @modalKeyScope
    if @previousScope?
      key.setScope @previousScope
    @model.teardown?()
    @model.teardown = null
    delete @model.teardown
    if @model.detach
      @dom.detach()
    else
      @dom.remove()
      delete this

  quiteTeardown: =>
    for k in @keyShortcuts
      key.unbind k, @modalKeyScope
    if @previousScope?
      key.setScope @previousScope
    @model.teardown = null
    delete @model.teardown
    if @model.detach
      @dom.detach()
    else
      @dom.remove()
      # console.log "delete self"
      delete this

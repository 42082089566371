import React from 'react'

import TabButton from './TabButton.js'
import {useOpen} from '../../named-url/react-hooks.js'

export default function ExternalTabButton (props) {
  const {icon, label, urlName} = props

  return <TabButton label={label} icon={icon} onClick={useOpen(urlName)} />
}

# +--------------+--------------+------------+------------+------------+-------------+
# | game_type_id | game_name    | real_money | group_name | category   | max_players |
# +--------------+--------------+------------+------------+------------+-------------+
# |            1 | Texas Holdem | false      | Poker      | ring_game  |          10 |
# |            2 | Texas Holdem | true       | Poker      | ring_game  |          10 |
# |            9 | Omaha        | true       | Poker      | ring_game  |          10 |
# |           22 | Texas Holdem | false      | Poker      | tournament |          10 |
# |           23 | Texas Holdem | true       | Poker      | tournament |          10 |
# |           25 | Omaha        | true       | Poker      | tournament |          10 |
# |           35 | Omaha HiLo   | true       | Poker      | ring_game  |          10 |
# |           37 | Omaha HiLo   | true       | Poker      | tournament |          10 |
# +--------------+--------------+------------+------------+------------+-------------+

holdem = ->
  long: "Hold'em"
  short: 'HE'

omaha = ->
  long: 'Omaha'
  short: 'O'

omahaHiLo = ->
  long: 'Omaha Hi/Lo'
  short: 'O H/L'

export default
  1:  holdem()
  2:  holdem()
  22: holdem()
  23: holdem()
  9:  omaha()
  25: omaha()
  35: omahaHiLo()
  37: omahaHiLo()

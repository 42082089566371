import React from 'react'
import {useSelector} from 'react-redux'

import styles from './Signal.module.css'
import {getLatency, getStrength} from './selectors.js'

export default function Signal () {
  const latency = useSelector(getLatency)
  const strength = `bars-${getStrength(latency)}`

  return <>
    <span className={`${styles.row} ${styles.strength} ${styles[strength]}`} />
    <span className={`${styles.row} ${styles.latency}`}>
      {latency || '∞'}ms
    </span>
  </>
}

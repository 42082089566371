import Request from './request.coffee'

export default class GetMyGamesRequest extends Request
  constructor: ({message, casinoId}) ->
    super arguments...

    @function = 'GET_MY_GAMES'

    @attributes =
      type: 'REQUEST'
      service: 'GAMELIST'
      routerId: message.routerId
      serverId: 0
      instanceId: 0
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @arguments =
      casino_id: casinoId
      nick_name: message.nickname

import React, {useMemo} from 'react'

import ListingHeaders from './ListingHeaders.js'
import Caption from '../../../components/game-listing/table/Caption.js'
import RowGroup from '../../../components/game-listing/table/RowGroup.js'
import Table from '../../../components/game-listing/table/Table.js'
import {useRootedSelector} from '../../../redux.js'
import DesktopTournamentListingRow from '../../components/DesktopTournamentListingRow.js'
import Loading from '../../components/Loading.js'
import {getRoot} from '../../index.js'
import {usePinnedTournaments} from '../../react-hooks.js'
import {useCriteria, useSort} from '../react-hooks.js'
import {createGetListing, createIsLoaded} from '../selectors.js'

const EMPTY_ID = 'game-list-empty-sitngo-tournament'

export default function DesktopListing () {
  const criteria = useCriteria()
  const sort = useSort()
  const pinned = usePinnedTournaments()

  const isLoaded = useRootedSelector(getRoot, useMemo(() => createIsLoaded(), []), {criteria})
  const tournaments = useRootedSelector(getRoot, useMemo(() => createGetListing(), []), {criteria, pinned, sort})
  const hasEntries = tournaments.length > 0
  const captionId = !isLoaded || !hasEntries ? EMPTY_ID : undefined

  return <Table ariaLabelledby={captionId}>
    <ListingHeaders />

    {isLoaded && hasEntries && <RowGroup>
      {tournaments.map(tournament => {
        return <DesktopTournamentListingRow key={tournament.tournamentId} tournament={tournament} />
      })}
    </RowGroup>}

    {isLoaded && !hasEntries && <Caption id={captionId}>No games match filters</Caption>}
    {!isLoaded && <Loading id={captionId} />}
  </Table>
}

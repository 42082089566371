export default ({signal, details, app, game, client, listing}) ->
  return unless game

  for winner in details
    player = game.getPlayerByPosition winner.id
    continue unless player

    player.balance = winner.balance
    player.cssClass = ''
    player.bet = 0

    if player.isClient
      # todo: only play winning sound if won at showdown?
      # app.playSound 'playerWins'
      client.playSound 'playerWins', client.instanceId
      client.statistics.won++

    for potNumber, pot of winner.pots

      amount = pot.amount.toMoney(game.currency, game.precision)

      # WCL-1786
      if game.currentState.preflopFold
        game.betsToWinner(winner.id)
      else
        game.potToWinner potNumber, amount, player.viewPosition

      if pot.returned
        # uncalled bets
        message = "Uncalled bet of #{amount} returned to #{player.alias}"
      else
        # called bets
        player.label = 'WON'
        message = "#{player.alias} wins #{amount} from Pot #{potNumber}"

        # hand shown?
        if winner.hand
          message += " with: #{winner.hand.description}"
          game.winningCards = winner.hand.cards

      # send chat message
      client?.displayChatMessage
        message: message
        format: 'winner'

      game.updateHandHistory {message}

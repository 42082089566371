import {USD} from '@pipehat/chronicle/constants/currency'
import {HYPER_TURBO, TURBO} from '@pipehat/chronicle/constants/game-category'

import {
  TOURNAMENT_SITNGO_FULL_RING,
  TOURNAMENT_SITNGO_HEADSUP,
  TOURNAMENT_SITNGO_REGULAR,
  TOURNAMENT_SITNGO_SIX_MAX,
} from '@pipehat/chronicle/constants/game-list-type'

import {ANNOUNCED, CREATED, IN_PROGRESS, REGISTERING} from '@pipehat/chronicle/constants/tournament-status'
import {satisfiesAll} from '@pipehat/money'
import {createByDenominator, isLessThanOrEqual, isGreaterThan} from '@pipehat/money/plain-object'

import listTypeEnum from '../../legacy/enum/enums/listTypeEnum.coffee'
import {SIT_N_GO_SUB_ROUTE} from '../../routes.js'
import {ALL, ALL_STAKES_RANGES, REGULAR, STAKES_RANGE} from '../constants.js'

export const SEAT_COUNT_RANGE = {
  ALL,
  FULL_RING: 'FULL_RING',
  HEADS_UP: 'HEADS_UP',
  SIX_MAX: 'SIX_MAX',
}

export const SIT_N_GO_FILTER = {
  HYPER: 'HYPER',
  TURBO: 'TURBO',
  ALL: 'ALL',
}

export const SIT_N_GO_FILTER_MAP = {
  [SIT_N_GO_SUB_ROUTE.HYPER]: SIT_N_GO_FILTER.HYPER,
  [SIT_N_GO_SUB_ROUTE.TURBO]: SIT_N_GO_FILTER.TURBO,
  [SIT_N_GO_SUB_ROUTE.ALL]: SIT_N_GO_FILTER.ALL,
}

export const SIT_N_GO_FILTER_CRITERIA = {
  [SIT_N_GO_FILTER.HYPER]: {
    seatCountRange: SEAT_COUNT_RANGE.ALL,
    speed: HYPER_TURBO,
    stakesRange: ALL,
  },
  [SIT_N_GO_FILTER.TURBO]: {
    seatCountRange: SEAT_COUNT_RANGE.ALL,
    speed: TURBO,
    stakesRange: ALL,
  },
  [SIT_N_GO_FILTER.ALL]: {
    seatCountRange: SEAT_COUNT_RANGE.ALL,
    speed: ALL,
    stakesRange: ALL,
  },
}

// the first speed is the default, so order is important here
export const SPEED_MAP = {
  [ALL]: [ALL, REGULAR, TURBO, HYPER_TURBO],
  [SEAT_COUNT_RANGE.FULL_RING]: [ALL, REGULAR, TURBO],
  [SEAT_COUNT_RANGE.HEADS_UP]: [ALL, REGULAR, TURBO, HYPER_TURBO],
  [SEAT_COUNT_RANGE.SIX_MAX]: [ALL, TURBO, HYPER_TURBO],
}

const LIST_TYPE_FULL_RING = [
  TOURNAMENT_SITNGO_FULL_RING,
  listTypeEnum.TOURNAMENT_SITNGO_FULL_RING.description,
]

const LIST_TYPE_HEADS_UP = [
  TOURNAMENT_SITNGO_HEADSUP,
  listTypeEnum.TOURNAMENT_SITNGO_HEADSUP.description,
]

const LIST_TYPE_REGULAR = [
  TOURNAMENT_SITNGO_REGULAR,
  listTypeEnum.TOURNAMENT_SITNGO_REGULAR.description,
]

const LIST_TYPE_SIX_MAX = [
  TOURNAMENT_SITNGO_SIX_MAX,
  listTypeEnum.TOURNAMENT_SITNGO_SIX_MAX.description,
]

export const LIST_TYPE_MAP = {
  [ALL]: [LIST_TYPE_FULL_RING, LIST_TYPE_HEADS_UP, LIST_TYPE_REGULAR, LIST_TYPE_SIX_MAX],
  [SEAT_COUNT_RANGE.FULL_RING]: [LIST_TYPE_FULL_RING],
  [SEAT_COUNT_RANGE.HEADS_UP]: [LIST_TYPE_HEADS_UP],
  [SEAT_COUNT_RANGE.SIX_MAX]: [LIST_TYPE_SIX_MAX],
}

export const STAKES_RANGE_MAP = {
  [ALL]: {
    [ALL]: ALL_STAKES_RANGES,
    [REGULAR]: ALL_STAKES_RANGES,
    [TURBO]: ALL_STAKES_RANGES,
    [HYPER_TURBO]: ALL_STAKES_RANGES,
  },
  [SEAT_COUNT_RANGE.FULL_RING]: {
    [ALL]: ALL_STAKES_RANGES,
    [REGULAR]: [ALL, STAKES_RANGE.MICRO, STAKES_RANGE.LOW],
    [TURBO]: [ALL, STAKES_RANGE.MICRO, STAKES_RANGE.LOW, STAKES_RANGE.MEDIUM],
  },
  [SEAT_COUNT_RANGE.HEADS_UP]: {
    [ALL]: ALL_STAKES_RANGES,
    [REGULAR]: [ALL, STAKES_RANGE.MICRO, STAKES_RANGE.LOW, STAKES_RANGE.MEDIUM],
    [TURBO]: ALL_STAKES_RANGES,
    [HYPER_TURBO]: ALL_STAKES_RANGES,
  },
  [SEAT_COUNT_RANGE.SIX_MAX]: {
    [ALL]: ALL_STAKES_RANGES,
    [TURBO]: ALL_STAKES_RANGES,
    [HYPER_TURBO]: [ALL, STAKES_RANGE.MICRO, STAKES_RANGE.LOW, STAKES_RANGE.MEDIUM],
  },
}

const createFromUsCents = createByDenominator(USD, 100n)

const USD_5_AND_50_CENTS = createFromUsCents(550n)
const USD_20_AND_20_CENTS = createFromUsCents(2020n)
const USD_50_AND_50_CENTS = createFromUsCents(5050n)

export const STAKES_RANGE_FILTER_MAP = {
  [ALL]: isAny,
  [STAKES_RANGE.MICRO]: isLessThanOrEqual(USD_5_AND_50_CENTS),
  [STAKES_RANGE.LOW]: satisfiesAll([isGreaterThan(USD_5_AND_50_CENTS), isLessThanOrEqual(USD_20_AND_20_CENTS)]),
  [STAKES_RANGE.MEDIUM]: satisfiesAll([isGreaterThan(USD_20_AND_20_CENTS), isLessThanOrEqual(USD_50_AND_50_CENTS)]),
  [STAKES_RANGE.HIGH]: isGreaterThan(USD_50_AND_50_CENTS),
}

export const STATUS_FILTER = [
  ANNOUNCED,
  CREATED,
  IN_PROGRESS,
  REGISTERING,
]

function isAny () {
  return true
}

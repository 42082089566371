import {useContext, useEffect, useState} from 'react'

import {context} from '../react.js'

export function useUpdate () {
  return useService().update
}

export function useStatus () {
  const {status, subscribe} = useService()
  const [reactValue, setReactValue] = useState(status)

  useEffect(() => subscribe(setReactValue), [subscribe])

  return reactValue
}

function useService () {
  return useContext(context).updatingService
}

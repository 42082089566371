import {OMAHA_HOLDEM, OMAHA_HOLDEM_HILO, TEXAS_HOLDEM} from '@pipehat/chronicle/constants/game-variant'
import React from 'react'

import VariantButton from './VariantButton.js'
import {ALL} from '../../constants.js'
import {VARIANT_MAP} from '../constants.js'
import {useTournamentType} from '../react-hooks.js'

export default function VariantSelection () {
  const tournamentType = useTournamentType()
  const variants = VARIANT_MAP[tournamentType]

  const shouldShowAll = variants.includes(ALL)
  const shouldShowTexas = variants.includes(TEXAS_HOLDEM)
  const shouldShowOmaha = variants.includes(OMAHA_HOLDEM)
  const shouldShowOmahaHiLo = variants.includes(OMAHA_HOLDEM_HILO)

  return <ul>
    {shouldShowAll && <li><VariantButton label='All' variant={ALL} /></li>}
    {shouldShowTexas && <li><VariantButton label="Texas Hold'em" variant={TEXAS_HOLDEM} /></li>}
    {shouldShowOmaha && <li><VariantButton label='Omaha' variant={OMAHA_HOLDEM} /></li>}
    {shouldShowOmahaHiLo && <li><VariantButton label='Omaha Hi/Lo' variant={OMAHA_HOLDEM_HILO} /></li>}
  </ul>
}

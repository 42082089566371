import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# Data Types

export default class GetLevelStructureTranslator extends Mixmaster
  mixins = [
    DefaultMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal
    signal.tournamentLevels = []

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  tournamentLevels: (signal, attributes) ->
    signal.tournamentLevels = attributes

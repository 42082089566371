import {FINISHED, IN_PROGRESS, LATE_REGISTRATION} from '@pipehat/chronicle/constants/tournament-status'
import React, {useMemo} from 'react'

import Cell from '../../components/game-listing/table/Cell.js'
import LinkRow from '../../components/game-listing/table/LinkRow.js'
import styles from '../../components/game-listing/table/Table.module.css'
import {renderStartsWhen} from '../../game-list/sitngo-tournament/rendering.js'
import Money from '../../money/components/Money.js'
import {renderTournamentStatus} from '../../rendering.js'
import {TOURNAMENT, TOURNAMENTS} from '../../routes.js'
import DescribedTime from '../../time/components/DescribedTime.js'

export default function OwnTournamentsRow (props) {
  const {
    tournament: {
      entryCost,
      minimumRegistrants,
      name,
      place,
      registrantCount,
      stack,
      startTime,
      status,
      tournamentId,
    },
  } = props

  const routeParams = useMemo(() => ({tournamentId: String(tournamentId), closeTo: TOURNAMENTS}), [tournamentId])
  const remainingRegistrants = minimumRegistrants - registrantCount

  const isInProgress = status === IN_PROGRESS || status === LATE_REGISTRATION
  const isFinished = status === FINISHED

  const shouldShowStack = stack && isInProgress
  const shouldShowPlace = place && (isInProgress || isFinished)

  return <LinkRow routeName={TOURNAMENT} routeParams={routeParams}>
    <Cell>{startTime ? <DescribedTime value={startTime} /> : renderStartsWhen(remainingRegistrants)}</Cell>
    <Cell className={styles.primary}>{name}</Cell>
    <Cell>{tournamentId}</Cell>
    <Cell>{entryCost ? <Money value={entryCost} /> : '-'}</Cell>
    <Cell>{renderTournamentStatus(status)}</Cell>
    <Cell>{shouldShowStack ? <Money value={stack} /> : '-'}</Cell>
    <Cell>{shouldShowPlace ? place : '-'}</Cell>
  </LinkRow>
}

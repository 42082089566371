# Global static utility to assist with dom sorting
# Usage: where $list is a dom element like <ul> or <table>
#  fn = Sortable.getSortFunction {type: 'name', order: 'descending'}
#  $list.find('li').sort(fn).appendTo $list

export default do ->

  # Array sort wrapper: https://developer.mozilla.org/en-US/docs/JavaScript/Reference/GlobalObjects/Array/sort
  compare = (a, b) ->
    if typeof a is 'string'
      a = a.toLowerCase()
    if typeof b is 'string'
      b = b.toLowerCase()
    if a > b then return -1
    if a is b then return 0
    if a < b then return 1
    return -1

  return {
    getSortFunction: (sortObj) ->
      if Array.isArray(sortObj) # sequential sorts
        return (a, b) ->
          sortResult = 0
          i = 0
          while sortResult is 0 and i < sortObj.length
            sort = sortObj[i]
            if sort.order is 'ascending'
              sortResult = compare $(b).data(sort.type), $(a).data(sort.type)
            else
              sortResult = compare $(a).data(sort.type), $(b).data(sort.type)
            i++
          return sortResult
      else  # Single sort
        if sortObj.order is 'ascending'
          return (a, b) -> compare $(b).data(sortObj.type), $(a).data(sortObj.type)
        else
          return (a, b) -> compare $(a).data(sortObj.type), $(b).data(sortObj.type)

  }

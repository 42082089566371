import React, {useMemo} from 'react'

import Empty from './Empty.js'
import List from '../../../components/game-listing/list/List.js'
import Rows from '../../../components/game-listing/list/Rows.js'
import {useRootedSelector} from '../../../redux.js'
import MobileRingGameListingRow from '../../components/MobileRingGameListingRow.js'
import MobileRingGameSkeletonList from '../../components/MobileRingGameSkeletonList.js'
import {getRoot} from '../../index.js'
import {usePinnedRingGames} from '../../react-hooks.js'
import {useCriteria, useSort} from '../react-hooks.js'
import {createGetListing, createIsLoaded} from '../selectors.js'

const EMPTY_ID = 'game-list-empty-ring-games'

export default function MobileListing () {
  const criteria = useCriteria()
  const sort = useSort()
  const pinned = usePinnedRingGames()

  const isLoaded = useRootedSelector(getRoot, useMemo(() => createIsLoaded(), []), {criteria})
  const ringGames = useRootedSelector(getRoot, useMemo(() => createGetListing(), []), {criteria, pinned, sort})
  const hasEntries = ringGames.length > 0
  const captionId = !isLoaded || !hasEntries ? EMPTY_ID : undefined

  return <List ariaLabelledby={captionId}>
    {isLoaded && hasEntries && <Rows>
      {ringGames.map(ringGame => {
        return <MobileRingGameListingRow key={ringGame.tableId} ringGame={ringGame} />
      })}
    </Rows>}

    {isLoaded && !hasEntries && <Empty id={captionId} isList />}
    {!isLoaded && <MobileRingGameSkeletonList />}
  </List>
}

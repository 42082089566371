import React from 'react'

import ButtonGroup from './ButtonGroup.js'
import RingGameFilterButton from './RingGameFilterButton.js'
import {DESKTOP} from '../../device/constants.js'
import {useFormat} from '../../device/react-hooks.js'
import {RING_GAME_FILTER} from '../service.js'

const {
  FIXED_LIMIT_OMAHA_HOLDEM_HILO,
  FIXED_LIMIT_TEXAS_HOLDEM,
  NO_LIMIT_TEXAS_HOLDEM,
  POT_LIMIT_OMAHA_HOLDEM,
} = RING_GAME_FILTER

export default function RingGameFilterSelection () {
  const isDesktop = useFormat() === DESKTOP

  return <ButtonGroup label='Game' number={isDesktop ? '2' : '1'}>
    <RingGameFilterButton label="No Limit Hold'em" filter={NO_LIMIT_TEXAS_HOLDEM} />
    <RingGameFilterButton label="Fixed Limit Hold'em" filter={FIXED_LIMIT_TEXAS_HOLDEM} />
    <RingGameFilterButton label='Pot Limit Omaha' filter={POT_LIMIT_OMAHA_HOLDEM} />
    <RingGameFilterButton label='Limit Omaha Hi/Lo' filter={FIXED_LIMIT_OMAHA_HOLDEM_HILO} />
  </ButtonGroup>
}

import React, {useMemo} from 'react'

import Money from '../../../money/components/Money.js'
import {useOpen} from '../../../named-url/react-hooks.js'
import {useRootedSelector} from '../../../redux.js'
import {createGetSelfBalance, getRoot} from '../../index.js'
import {useShowCoupons} from '../../react-hooks.js'

const NO_BREAK_SPACE = '\u00A0'

export default function Balance (props) {
  const {tournamentId} = props
  const showCoupons = useShowCoupons(tournamentId)
  const balance = useRootedSelector(getRoot, useMemo(createGetSelfBalance, []), props)

  return <div className='pane pane__balances'>
    <div className='kvpair rightAlign'>
      <label>My Balance</label><span className='large'>{balance ? <Money value={balance} /> : NO_BREAK_SPACE}</span>
    </div>

    <div className='kvpair float rightAlign' onClick={useOpen('cashier.deposit')}>
      <span className='link'>Deposit Now</span>
    </div>

    <div className='kvpair float' onClick={showCoupons}>
      <span className='link'>Tournament Entry Coupons</span>
    </div>
  </div>
}

import classnames from 'classnames'
import React, {useCallback, useMemo} from 'react'

import Button from './Button.js'
import styles from './QuickSeat.module.css'
import Stakes from '../../money/components/Stakes.js'
import {useRootedSelector} from '../../redux.js'
import skeleton from '../../skeleton.module.css'
import {createGetRingGameFilterStakesCandidates, createGetRingGameFilterStakesCount, getRoot} from '../index.js'
import {useJoinRingGame, useRingGameFilter} from '../react-hooks.js'

export default function RingGameStakesButton (props) {
  const {minimumStake, maximumStake} = props
  const [filter] = useRingGameFilter()

  const criteria = {filter, maximumStake, minimumStake}
  const candidates = useRootedSelector(getRoot, useMemo(createGetRingGameFilterStakesCandidates, []), criteria)
  const count = useRootedSelector(getRoot, useMemo(createGetRingGameFilterStakesCount, []), criteria)

  const joinRingGame = useJoinRingGame()
  const handleClick = useCallback(() => {
    joinRingGame(candidates)
  }, [candidates, joinRingGame])

  return <Button type='button' onClick={handleClick} isStake>
    <span className={skeleton.light}><Stakes maximumStake={maximumStake} minimumStake={minimumStake} /></span>
    <span className={classnames(styles.tableCount, skeleton.light)}>({count} active)</span>
  </Button>
}

import React from 'react'

import Button from './Button.js'
import {useToggleOptions} from '../react-hooks.js'

export default function Options () {
  const toggleOptions = useToggleOptions()

  return <Button onClick={toggleOptions} icon='fa fa-cogs' />
}

import React from 'react'

import RoomProvider from '../../rooms/RoomProvider.js'
import BuyIn from '../../rooms/bank/BuyIn.js'

export default function BuyInView (props) {
  const {tableId} = props

  return <RoomProvider tableId={tableId}>
    <BuyIn />
  </RoomProvider>
}

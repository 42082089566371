import React, {useCallback, useEffect, useState} from 'react'

import AutoRebuyPercent from './AutoRebuyPercent.js'
import LabelRow from './LabelRow.js'
import styles from './Settings.module.css'
import Radio from '../../components/Radio.js'
import RadioGroup from '../../components/RadioGroup.js'

import {
  useAutoRebuyPercentage,
  useIsAutoRebuyAtInitialBuyIn,
  useSetAutoRebuyAtInitialBuyIn,
  useSetAutoRebuyPercentage,
} from '../react-hooks.js'

export default function AutoRebuyAmount () {
  const rebuyPercentage = useAutoRebuyPercentage()
  const isRebuyAtInitialBuyIn = useIsAutoRebuyAtInitialBuyIn()
  const setRebuyAtInitialBuyIn = useSetAutoRebuyAtInitialBuyIn()
  const setRebuyPercentage = useSetAutoRebuyPercentage()
  const [state, setState] = useState(isRebuyAtInitialBuyIn)

  useEffect(() => {
    setState(isRebuyAtInitialBuyIn)
  }, [isRebuyAtInitialBuyIn, setState])

  const handleChange = useCallback(value => {
    if (value === 'initial') setRebuyAtInitialBuyIn()
    else setRebuyPercentage(rebuyPercentage)
  }, [rebuyPercentage, setRebuyAtInitialBuyIn, setRebuyPercentage])

  return <>
    <RadioGroup onChange={handleChange} value={state ? 'initial' : 'percent'}>
      <LabelRow className={styles.subRow} name='Initial Buy-In Amount'>
        <Radio value='initial' />
      </LabelRow>
      <LabelRow className={styles.subRow} name={`${rebuyPercentage}% of Max Buy-In`}>
        <Radio value='percent' />
      </LabelRow>
    </RadioGroup>

    {!isRebuyAtInitialBuyIn && <AutoRebuyPercent />}
  </>
}

import Request from './request.coffee'

export default class RegisterMessage extends Request
  constructor: ({listing, message, paymentId, password}) ->
    super arguments...

    @function = 'REGISTER'

    @attributes =
      type: 'REQUEST'
      service: 'GAME'
      routerId: message.routerId
      serverId: listing.serverId
      instanceId: listing.instanceId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @arguments =
      game_number: 1 # ??? Dave C says this will probably always be 1
      entry_payment_id: paymentId

    if password
      @arguments.password = password

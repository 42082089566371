import {FIXED_LIMIT, NO_LIMIT, POT_LIMIT} from '@pipehat/chronicle/constants/limit-type'
import React from 'react'

import LimitTypeButton from './LimitTypeButton.js'
import {LIMIT_TYPE_MAP} from '../constants.js'
import {useVariant} from '../react-hooks.js'

export default function LimitTypeSelection () {
  const variant = useVariant()
  const variantLimitTypes = LIMIT_TYPE_MAP[variant]

  const shouldShowNoLimit = variantLimitTypes.includes(NO_LIMIT)
  const shouldShowFixedLimit = variantLimitTypes.includes(FIXED_LIMIT)
  const shouldShowPotLimit = variantLimitTypes.includes(POT_LIMIT)

  return <ul>
    {shouldShowNoLimit && <li><LimitTypeButton label='No Limit' limitType={NO_LIMIT} /></li>}
    {shouldShowFixedLimit && <li><LimitTypeButton label='Fixed Limit' limitType={FIXED_LIMIT} /></li>}
    {shouldShowPotLimit && <li><LimitTypeButton label='Pot Limit' limitType={POT_LIMIT} /></li>}
  </ul>
}

import React, {useMemo} from 'react'

import SngStakeButton from './SngStakeButton.js'
import {useRootedSelector} from '../../redux.js'
import {createGetSngFilterStakeList, getRoot} from '../index.js'
import {useSngFilter} from '../react-hooks.js'

export default function SngStakeButtonGroup () {
  const [filter] = useSngFilter()
  const stakeList = useRootedSelector(getRoot, useMemo(createGetSngFilterStakeList, []), {filter})

  return stakeList.map(({key, stake}) => {
    return <SngStakeButton key={key} stake={stake} />
  })
}

import React from 'react'

import MenuItem from './MenuItem.js'
import {useInstall} from '../../installation/react-hooks.js'

export default function InstallSubMenu () {
  return <>
    <div className='heading'>Install Available</div>
    <MenuItem icon='fa-arrow-circle-down' label='Install App' onClick={useInstall()} />
  </>
}

# <tournamentPlayers> <players> <item tournamentId="7990969" gameNumber="1" userNickname="dcampbell2" table="0" seat="0" chips="1000.00" eliminationOrder="0" cashWinnings="0.00" pointsWinnings="0.00" refund="false"> <bountyPrize /> <wonCoupons /> </item> </players> <results /> </tournamentPlayers>

import PlayerListing from '../../../models/playerListing.coffee'

export default ({signal, app}) ->

  tournament = app.listings.find instanceId: signal.parent.arguments.game_instance
  unless tournament?
    # console.warn 'could not get tournament players for', signal.parent.arguments.game_instance, 'because no listing.'
    return
  tournament.players.empty()
  for details, index in signal.tournamentPlayers
    # Tourneys have chips not $
    details.currency = ''
    if tournament.isSng and tournament.gameState isnt 'In Progress'
      if details.userNickname is app.user.nickname or tournament.hasStarted
        details.alias = details.userNickname
      else
        details.alias = 'Registered Player'
    else
      details.alias = details.userNickname

    player = new PlayerListing {details}
    player.position = index + 1
    tournament.players.add player
    if player.alias is app.user.nickname
      # if tournament.gameState is 'Registering'
      #   tournament.registration()
      if tournament.gameState is 'Late Registration' and player.chips > 0
        tournament.playing = true
        tournament.emit 'updateGameState'
      # else if tournament.gameState is 'In Progress'
      #   tournament.playing = true

  tournament?.emit 'tournamentPlayers'

# Inst`anceView
# Model: client

import key from 'hotkeys-js'

import GameView from './game/gameView.coffee'
import ClientView from './client/clientView.coffee'
import Table from '../../rooms/Room.js'
import View from './view.coffee'
import tableFontResizer from '../../table-font-resizer.js'
import styles from '../../rooms/Room.module.css'

export default class InstanceView extends View

  constructor: (navigator, client, createReactView, isDesktop, @windowTitleManager, popUp, popupController) ->
    super()

    @model = client
    @popupController = popupController

    @dom = $ '<div class="game-tile">'

    # ### Components - eg. DOM elements & views
    @components =
      clientView: new ClientView client, createReactView
      gameView: new GameView client.game, createReactView, client.instanceId
      tableView: createReactView(
        Table,
        {isPopOut: popUp, tableId: client.instanceId, isDesktop},
        {class: styles.tableView},
        'div'
      )

    # ### Events - eg. MouseClicks ###

    # ### Listeners - eg. auth:success
    client.game.on 'change:levelDetails', @setWindowTitle

    navigator.on 'nav', (location, table) =>
      isCurrentlyActive = location is 'table' and table.instanceId is client.instanceId

      return unless isCurrentlyActive

      @setKeyScope()
      @setWindowTitle()

    # ### Changes - eg. Model.property = true

    if not popUp
      @changes =
        current: @updateCurrent

    # cleanup to prevent leaks (especially with pop outs)
    window.onunload = => @destroy()

    unobserve = tableFontResizer @dom[0]

    @on 'destroy', unobserve

    @updateCurrent()

    # ### Binding ###
    @initializeView()
    return this

  setKeyScope: =>
    key.setScope("game-#{@model.instanceId}")

  setWindowTitle: =>
    game = @model.game
    tournament = game.tournament

    if tournament?
      title = "#{tournament.gameName} - #{game.gameName}"

      if game.levelDetails?
        {smallblind, bigblind} = game.levelDetails
        title = "#{title} - #{smallblind}/#{bigblind} - #{tournament.limitTypeDesc.short}"

      @windowTitleManager.set title
    else
      @windowTitleManager.set game.gameName

  updateCurrent: =>
    @dom.toggleClass 'current', @model.current

import classnames from 'classnames'
import React, {useCallback} from 'react'

import {useCanAddChips, useCanTopUp, useShowAddChips} from '../../add-chips/react-hooks.js'

export default function AddChipsButton (props) {
  const {onClick} = props
  const showAddChips = useShowAddChips()
  const canAddChips = useCanAddChips()
  const canTopUp = useCanTopUp()

  const handleClick = useCallback(() => {
    if (canAddChips || canTopUp) {
      showAddChips()
      if (onClick) onClick()
    }
  }, [canAddChips, canTopUp, onClick, showAddChips])

  const className = classnames('button', {'button-disabled': !(canAddChips || canTopUp)})

  return <button type='button' className={className} onClick={handleClick}>
    <span className='fa fa-credit-card button-icon' /> Add Chips
  </button>
}

export default ({signal, details, app, game, client, listing}) ->
  return unless client

  # note this data is duplicate of what is calculated in the tourney lobby
  # this is because that's what the JC does - due to GAMELIST vs GAME server issues
  client.statistics.chipsInPlay = details.sum
  client.statistics.addons = details.addons
  client.statistics.rebuys = details.rebuys
  client.statistics.highStack = details.maximum
  client.statistics.lowStack = details.minimum
  client.statistics.averageStack = details.average

import React from 'react'

import LinkMenuItem from './LinkMenuItem.js'
import NamedUrlMenuItem from './NamedUrlMenuItem.js'

import {
  QUICK_SEAT,
  RESPONSIBLE_GAMING,
  RING_GAMES,
  SETTINGS,
  SUPPORT,
  TOURNAMENTS,
} from '../../routes.js'

export default function OptionsSubMenu (props) {
  const {lobbyLabel, lobbyRoute} = props

  return <>
    <div className='heading'>Options</div>
    <NamedUrlMenuItem icon='cashier' label='Deposit' urlName='cashier.overview' />
    <LinkMenuItem routeName={QUICK_SEAT} icon='seat' label='Quick Seat' />
    <LinkMenuItem routeName={lobbyRoute} icon='fa-list-alt' label={lobbyLabel} />
    <LinkMenuItem routeName={RING_GAMES} icon='tables' label='My Games' />
    <LinkMenuItem routeName={TOURNAMENTS} icon='tables' label='My Tournaments' />
    <LinkMenuItem routeName={SUPPORT} icon='help' label='Support & Legal' />
    <NamedUrlMenuItem
      icon='football'
      label='Sportsbook'
      urlName='lobby.externalSportsBet'
      extraClassName='external-casino-link'
    />
    <NamedUrlMenuItem
      icon='casino'
      label='Casino'
      urlName='lobby.externalCasinoGames'
      extraClassName='external-casino-link external-casino-link-casino'
    />
    <LinkMenuItem routeName={SETTINGS} icon='settings' label='Settings' />
    <LinkMenuItem routeName={RESPONSIBLE_GAMING} icon='smiley' label='Responsible Gaming' />
  </>
}

export const STATUS_DEPRECATED = 'DEPRECATED'
export const STATUS_OK = 'OK'
export const STATUS_UNAVAILABLE = 'UNAVAILABLE'

const API_VERSION = 'v2' // This is need to be updated when there is api version change.

export function createClient (navigator, name) {
  const subscribers = new Set()
  let status = STATUS_OK

  return {
    get status () { return status },

    async maintenance () {
      return fetchEndpoint('maintenance')
    },

    async splashPromo () {
      return fetchEndpoint('splash-promo')
    },

    async sidebar () {
      return fetchEndpoint('sidebar')
    },

    subscriber (subscriber) {
      subscribers.add(subscriber)

      return function unsubscribe () {
        subscribers.delete(subscriber)
      }
    },
  }

  async function fetchEndpoint (path) {
    const response = await fetch(`/api/${API_VERSION}/${path}`)
    status = translateApiStatus(response.headers.get('X-Pipehat-Api-Status'))

    dispatchStatus(status)

    if (status === STATUS_UNAVAILABLE) navOffline()

    if (!response.ok) throw new Error(`API request failed with status ${response.status} ${response.statusText}`)

    return response.json()
  }

  function navOffline () {
    navigator.navOffline({
      title: 'Update required',
      reason: `The version of ${name} you're using is out of date, and must be updated to continue playing.`,
      buttonText: 'Reload to update',
    })
  }

  function translateApiStatus (status) {
    switch (status) {
      case 'deprecated': return STATUS_DEPRECATED
      case 'invalid': return STATUS_UNAVAILABLE
    }

    return STATUS_OK
  }

  function dispatchStatus (apiStatus) {
    for (const subscriber of subscribers) subscriber(apiStatus)
  }
}

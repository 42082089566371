import React from 'react'

import RoomProvider from '../../rooms/RoomProvider.js'
import RingGameRebuy from '../../rooms/bank/RingGameRebuy.js'

export default function RingGameRebuyView (props) {
  const {tableId} = props

  return <RoomProvider tableId={tableId}>
    <RingGameRebuy />
  </RoomProvider>
}

import {BET} from '@pipehat/chronicle/constants/action'
import React, {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import {renderMoney} from '../../../../money/rendering.js'
import {useTableId} from '../../../context.js'
import styles from '../../Actions.module.css'
import {hideBetRaiseAmount, selectBetRaiseAmount, showBetRaiseAmount} from '../../actions-slice.js'
import ActionButton from '../../components/ActionButton.js'

import {
  useBet,
  useBetRaiseAmount,
  useIntendedActionName,
  useIsBetRaiseVisible,
  useRemovePreaction,
  useShouldShowBetRaise,
} from '../../react-hooks.js'

export default function BetButton () {
  const dispatch = useDispatch()
  const tableId = useTableId()
  const isIntendedAction = useIntendedActionName() === BET
  const bet = useBet()
  const removePreaction = useRemovePreaction()
  const amount = useBetRaiseAmount()
  const amountSelectorVisible = useIsBetRaiseVisible()
  const shouldShowBetRaise = useShouldShowBetRaise()

  const handleClick = useCallback(() => {
    if (shouldShowBetRaise && !amountSelectorVisible) {
      removePreaction()

      if (amount) dispatch(showBetRaiseAmount({tableId}))
      else dispatch(selectBetRaiseAmount({tableId}))

      return
    }

    if (amount) {
      bet(amount)
      dispatch(hideBetRaiseAmount({tableId}))
    }
  }, [amount, amountSelectorVisible, bet, dispatch, removePreaction, shouldShowBetRaise, tableId])

  return <ActionButton className={styles.bet} onClick={handleClick} isIntendedAction={isIntendedAction}>
    {amount ? `Bet ${renderMoney(amount)}` : 'Bet...'}
  </ActionButton>
}

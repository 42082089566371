import dayjs from 'dayjs'

export default ({signal, details, app, game, client, listing}) ->
  endTime = dayjs().add(details.endTime, 'seconds').toDate()
  client.onBreak = true
  client.breakEndTime = endTime
  client.imReadyAllowed = details.imReadyAllowed
  client.imReady = false
  client.currentActions = []
  client.emit 'toggleWaitingForBreakNotice', false
  client.emit 'toggleBreakNotice', true, endTime
  game.clearTable()
  game.emit 'breakStarted'

  if listing?
    listing.nextBreakStartTime = null
    listing.currentBreakStopTime = endTime

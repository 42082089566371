import {TABLE} from '../routes.js'

export const TAB = 'tab'
export const WINDOW = 'window'

export function createService (clientList, popupMaster, router) {
  const findClient = tableId => clientList.find({instanceId: tableId})

  return {
    attach (tableId) {
      popupMaster.popIn(findClient(tableId))
    },

    detach (tableId) {
      popupMaster.popOut(findClient(tableId))
    },

    frameType () {
      return popupMaster.frameType()
    },

    open (tableId) {
      if (popupMaster.focusPopup(tableId)) return

      const params = {tableId: String(tableId)}

      if (!router.isActive(TABLE, params)) router.navigate(TABLE, params)
    },

    subscribeToFrameType (subscriber) {
      return popupMaster.subscribeToFrameType(subscriber)
    },
  }
}

# ## Settings Module

# Simple wrapper for localStorage
# Boolean values 'true' and 'false' are automatically parsed
# Silently fail for browsers private browsing mode that do not support localStorage
# Don't forget to set a default value

# todo: ensure each setting here is actually being used

import PersistentModel from './persistentModel.coffee'
import {AUTO_REBUY_POINT_OFF} from '../../rooms/constants.js'

export default class Settings extends PersistentModel

  constructor: (version, persister, casinoShortName, isPrivileged) ->
    super version, persister

    @namespace = 'settings:' + casinoShortName

    @properties =
      confirmAllIn: true
      showFastNotice: true
      autoBlinds: false
      autoMuck: false
      promptAutoBlinds: true
      promptAutoMuck: true
      autoTimebank: true
      defaultBuyin: 40
      ignoreStandalone: false
      sitOutType: 'nextHand'
      # pinnedListings: []  # don't persist (see below)
      fourColorDeck: false
      confirmLeaveGame: true
      tableSpeedPreference: 'all'
      checkFoldPreference: ''
      showTournamentPositionNotice: true
      defaultGameList:
        ringGames: 'nlHoldem'
        sitngo: 'all'
        tournaments: 'holdem'
      autoRebuy:
        point: AUTO_REBUY_POINT_OFF
        initialBuyIn: false #if auto rebuy is on and this is set to true will rebuy back to initial buyin amount
        amount: 50
      bringToFrontOnTurn: true
      preferredSeating:
        2: null
        3: null
        5: null
        6: null
        8: null
        9: null
      soundsEnabled: isPrivileged
      notifications: isPrivileged
      notificationBeforeGameStart: 0
      notificationAtGameStart: isPrivileged
      notificationPush: isPrivileged
      rememberUsername: false
      registerWarning: true
      showWaitlistNotification: true
      popup: 'off'

    # not persisted
    @pinnedListings = []

    @initializePersistentModel()

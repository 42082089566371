import React, {useMemo, useState} from 'react'

import {useRootedSelector} from '../../../redux.js'
import {useClock} from '../../../time/clock.js'
import {TEN_SECONDS} from '../../../time/time-amount.js'
import {useInterval} from '../../../time/timer.js'

import {
  createGetLateRegistration,
  createGetRegistrationCloseTime,
  createGetStatus,
  getRoot,
} from '../../index.js'

import {renderLateRegistration} from '../../rendering.js'

export default function LateRegistration (props) {
  const clock = useClock()
  const closeTime = useRootedSelector(getRoot, useMemo(createGetRegistrationCloseTime, []), props)
  const status = useRootedSelector(getRoot, useMemo(createGetStatus, []), props)

  const {
    isEnabled,
    isStopOnElimination,
    ttl,
  } = useRootedSelector(getRoot, useMemo(createGetLateRegistration, []), props)

  const [lateReg, setLateReg] = useState(
    renderLateRegistration(clock.now(), status, closeTime, isEnabled, isStopOnElimination, ttl),
  )

  useInterval(TEN_SECONDS, () => {
    setLateReg(renderLateRegistration(clock.now(), status, closeTime, isEnabled, isStopOnElimination, ttl))
  })

  return <div className='kvpair selfclear'>
    <label>Late Registration:</label>
    <span>{lateReg}</span>
  </div>
}

import classnames from 'classnames'
import React from 'react'

import styles from './Table.module.css'

export default function ButtonRow ({children, className, onClick}) {
  return <a role='row' className={classnames(styles.row, className)} onClick={onClick}>
    {children}
  </a>
}

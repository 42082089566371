import React from 'react'

import SeatCountRangeButton from './SeatCountRangeButton.js'
import {ALL} from '../../constants.js'
import {SEAT_COUNT_RANGE} from '../constants.js'

const {FULL_RING, HEADS_UP, SIX_MAX} = SEAT_COUNT_RANGE

export default function SeatCountRangeSelection () {
  return <ul>
    <li><SeatCountRangeButton label='All' seatCountRange={ALL} /></li>
    <li><SeatCountRangeButton label='Heads Up' seatCountRange={HEADS_UP} /></li>
    <li><SeatCountRangeButton label='6-Max' seatCountRange={SIX_MAX} /></li>
    <li><SeatCountRangeButton label='Full Ring' seatCountRange={FULL_RING} /></li>
  </ul>
}

import ModalView from '../legacy/views/modalView.coffee'
import {renderMoney} from '../money/rendering.js'

export function createServiceFactory (gameList, casinoSettings) {
  const services = {}

  return function createService (tournamentId) {
    if (!services[tournamentId]) services[tournamentId] = createServiceForTournament(gameList, casinoSettings, tournamentId)

    return services[tournamentId]
  }
}

function createServiceForTournament (gameList, casinoSettings, tournamentId) {
  const findListing = () => gameList.find({instanceId: tournamentId})

  return {
    confirmRegistration (isLateRegistration, registerCB) {
      const shouldWarn = isLateRegistration && casinoSettings.get('registerWarning')

      if (!shouldWarn) {
        registerCB()

        return
      }

      const message = '<p>This tournament is in progress, if you continue you will immediately be seated. Are you sure you want to register?</p>'
      const checkbox = '<input id="confirmRegistration" type="checkbox" class="css-checkbox">'
      const label = '<label for="confirmRegistration" class="css-checkbox-label">Don&rsquo;t ask me again</label>'

      const modalView = new ModalView({
        title: 'Confirm Registration',
        icon: 'ticket',
        content: `<div>${message}${checkbox}${label}</div>`,
        choices: [
          {
            value: 'Cancel',
            icon: 'times',
          },
          {
            value: 'Register',
            icon: 'check',
            primary: true,
            action: () => {
              const checkboxEl = document.getElementById('confirmRegistration')

              casinoSettings.set('registerWarning', !checkboxEl.checked)
              registerCB()
            },
          },
        ],
      })
      modalView.attachToBody()
    },

    deregister () {
      findListing().deregister()
    },

    load () {
      findListing()?.getDetails()
    },

    loadParticipants () {
      findListing()?.getPlayers()
    },

    register ({paymentId, password, suppressErrorMessage}, cb) {
      findListing().register({paymentId, password, suppressErrorMessage}, cb)
    },

    showCoupons () {
      findListing().getCoupons()
    },

    showBountyModal (participantBounties) {
      const rows = []

      for (const {cash, claimedBy, nickname} of participantBounties) {
        rows.push(`
          <tr>
            <td>${nickname}</td>
            <td>${cash ? renderMoney(cash) : '$0'}</td>
            <td>${claimedBy || ''}</td>
          </tr>`)
      }

      const table = `<table class='generic-modal-table'>
        <thead><tr><th>Player</th><th>Knockout</th><th>Claimed By</th></tr></thead>
        <tbody>${rows.join('')}</tbody>
      </table>`

      const modalView = new ModalView({
        title: 'Knockout Prizes',
        content: table,
      })
      modalView.attachToBody()
    },

    showLevelStructure () {
      findListing().getLevelStructure()
    },

    showSatelliteFollowOnTournaments () {
      findListing().getParents()
    },

    showSatellites () {
      findListing().getSatellites()
    },
  }
}

import {PASSWORD_PROTECTED} from '@pipehat/chronicle/constants/game-category'
import {createSelector} from '@reduxjs/toolkit'

import {LIST_TYPE_MAP, STAKES_RANGE_FILTER_MAP} from './constants.js'
import {getCriteriaProp, getPinnedProp, getOwnRingGames, getRingGames, getSortProp} from '../index.js'
import {sortRingGames} from '../sorting.js'

export function createGetListing () {
  return createSelector(
    [
      getOwnRingGames,
      getPinnedProp,
      getRingGames,
      getCriteriaProp,
      getSortProp,
    ],
    (ownRingGames, pinned, ringGames, criteria, sort) => {
      const {limitType, shouldShowEmpty, shouldShowFull, stakesRange, variant} = criteria
      const [listType] = LIST_TYPE_MAP[variant][limitType]
      const listTypeRingGames = ringGames[listType]

      if (!listTypeRingGames) return []

      const isWithinStakesRange = STAKES_RANGE_FILTER_MAP[limitType][stakesRange]

      const entries = Object.fromEntries(
        Object.entries(listTypeRingGames)
          .filter(([, ringGame]) => {
            if (ringGame.limitType !== limitType) return false

            const {categories, maximumStake, seatCount, seatedCount} = ringGame

            if (!shouldShowEmpty && seatedCount < 1) return false
            if (!shouldShowFull && seatedCount >= seatCount) return false
            if (categories.includes(PASSWORD_PROTECTED)) return false

            return isWithinStakesRange(maximumStake)
          })
          .map(([tableId, ringGame]) => {
            const ownRingGame = ownRingGames[tableId]

            return [
              tableId,
              {
                ...ringGame,
                isOwn: Boolean(ownRingGame),
                isPinned: Boolean(pinned[tableId]),
                seatingStatus: ownRingGame?.seatingStatus,
              },
            ]
          }),
      )

      return sortRingGames(sort, entries)
    },
  )
}

export function createIsLoaded () {
  return createSelector(
    [
      getRingGames,
      getCriteriaProp,
    ],
    (ringGames, criteria) => {
      const {limitType, variant} = criteria
      const [listType] = LIST_TYPE_MAP[variant][limitType]

      return Boolean(ringGames[listType])
    },
  )
}

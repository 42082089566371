import Collection from './collection.coffee'

export default class ClientList extends Collection
  constructor: () ->
    super()

    @on 'add', @addClient

  addClient: (client) =>

    client.game.on 'end', =>
      @emit 'endOfGame'

    client.on 'clientAction', (actionName, args = {}) =>
      args.client = client
      @emit 'clientAction', actionName, args

    client.on 'tryNavLobby', (actionName, args = {}) =>
      @emit 'clientListChanged'
      clients = @aliveClients()
      if clients.length <= 0 #  just in case of weirdness
        Navigator.navLobby()
      else
        for member in clients
          if member.instanceId isnt client.instanceId
            Navigator.navTable member.game
            break

    client.on 'reportProblem', =>
      @emit 'reportProblem'

    client.on 'addToInstances', (instanceId) =>
      @emit 'addToInstances', instanceId

    client.on 'visitCashier', =>
      @emit 'visitCashier', arguments...

    client.on 'updateRealMoneyBalance', =>
      @emit 'updateRealMoneyBalance'

    client.on 'change:isTurn', (isTurn) =>
      # console.log 'client change:isTurn', isTurn
      if isTurn and not client.current # don't notify current game
        @emit 'isTurn', client.game

    client.on 'change:current', (current) =>
      if current
        # there can be only one!
        @each (otherClient) ->
          unless otherClient is client
            otherClient.current = false

  aliveClients: =>
    return @reject (client) ->
      client.popup or client.left

  leave: =>
    @each (client) ->
      unless client.left
        client.leaveGame {force: true, lobby: false}

import React from 'react'

import LabelRow from './LabelRow.js'
import Checkbox from '../../components/Checkbox.js'
import {useIsOtherNotifications, useToggleOtherNotifications} from '../react-hooks.js'

export default function OtherNotifications () {
  return <LabelRow
    name='Other notifications'
    help='Will display game relevant push notifications.'
  >
    <Checkbox checked={useIsOtherNotifications()} onChange={useToggleOtherNotifications()} size='small' />
  </LabelRow>
}

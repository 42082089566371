import React from 'react'

import LabelRow from './LabelRow.js'
import Checkbox from '../../components/Checkbox.js'
import {useIsShowFastTableNotice, useToggleShowFastTableNotice} from '../react-hooks.js'

export default function ShowFastTableNotice () {
  return <LabelRow
    name='Show Fast Table Notice'
    help='Notifies you when entering a "Fast" table where blinds are automatically posted.'
  >
    <Checkbox checked={useIsShowFastTableNotice()} onChange={useToggleShowFastTableNotice()} size='small' />
  </LabelRow>
}

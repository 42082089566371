import {useContext, useEffect, useState} from 'react'

import {useTableId} from './context.js'
import {context} from '../react.js'

export function useOption (key) {
  const {options, subscribeToOptions} = useService()
  const [reactValue, setReactValue] = useState(options[key])

  useEffect(() => {
    return subscribeToOptions(options => {
      setReactValue(options[key])
    })
  }, [key, subscribeToOptions])

  return reactValue
}

export function useService () {
  return useContext(context).createTableService(useTableId())
}

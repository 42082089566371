
# ArcadeView
# Model: app

import Hammer from 'hammerjs'

import InstanceView from './instanceView.coffee'
import PreviewBarView from './previewBarView.coffee'
import View from './view.coffee'

export default class ArcadeView extends View

  constructor: (app, @createReactView, @isDesktop, @windowTitleManager) ->
    super()

    @model = app

    @dom = $ "<div id='arcade'>"

    # ### Components - eg. DOM elements & views
    @components =
      rotateNotice:         Webclient.ViewManager.returnFormatElement
        desktop: "<div id='rotateNotice' class='blanketNotice'><span class='blanketNotice-text'>Please increase your browser width to play <span class='blanketNotice-textLink'>or go back to Lobby</span></span></div>"
        tablet:  "<div id='rotateNotice' class='blanketNotice'><span class='blanketNotice-text'>Please rotate your screen to landscape position to play <span class='blanketNotice-textLink'>or go back to Lobby</span></span></div>"
        mobile:  "<div id='rotateNotice' class='blanketNotice'><span class='blanketNotice-text'>Please rotate your screen to landscape position to play <span class='blanketNotice-textLink'>or go back to Lobby</span></span></div>"
      previewBar: new PreviewBarView @model.clients

    # ### Events - eg. MouseClicks ###
    @components.previewBar.dom.on 'click', @togglePreviewBar
    @components.rotateNotice.find('.blanketNotice-textLink').on 'click', Navigator.navLobby

    # # ### Listeners - eg. auth:success
    @listeners =
      'spectateGame': @spectateGame

    # # ### Add swipe gestures to table
    manager = new Hammer.Manager(@dom.get()[0]);
    manager.add(new Hammer.Swipe());
    manager.on 'swipe', (e) =>
      if e.offsetDirection is 8 or e.offsetDirection is 2
        document.querySelector('.preview-table.current + .preview-table')?.click()

      else if e.offsetDirection is 16 or e.offsetDirection is 4
        $('.preview-table.current').prev()?.click()


    # # ### Changes - eg. Collection.property = true
    @model.settings.on 'change:fourColorDeck', @updateFourColorDeck
    @updateFourColorDeck()

    # ### Binding ###
    @initializeView()
    return this

  spectateGame: (game, client, inBackground) =>
    instanceView = new InstanceView @model.navigator, client, @createReactView, @isDesktop, @windowTitleManager
    @dom.append instanceView.render()

    if not inBackground
      Navigator.navTable game
      @model.clients.each (c) -> c.current = false
      client.current = true

  updateFourColorDeck: =>
    @dom.toggleClass 'fourColorDeck', @model.settings.fourColorDeck

import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'
import JoinGameMixin from './mixins/joinGameMixin.coffee'

# Data Types

# handleUpdatePlayerResponse(long gameInstanceId, PokerStatusData gameStatus)
# handleUpdatePlayerResponseFailure(long gameInstanceId, String reasonCode)

export default class UpdatePlayerTranslator extends Mixmaster
  mixins = [
    DefaultMixin
    JoinGameMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  # # warn 03-14 03:17 update event { code1: 'updatePlayer', playerId: '-1', reserve: '1' }

  # warn 03-14 03:17 no translation seat { seat: '1',
  # name: 'gordonfreeman ii',
  # gameTimer: '53',
  # value4: '2' }

  # warn 03-14 03:17 no translation minStake 0.50

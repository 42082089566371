import React from 'react'

import ClientVersion from './ClientVersion.js'
import Group from './Group.js'

export default function VersionInformationGroup () {
  return <Group heading='Version Information'>
    <ClientVersion />
  </Group>
}

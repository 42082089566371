import React from 'react'

import LabeledCheckbox from '../../../components/LabeledCheckbox.js'
import {useIsAutoPostBlinds, useIsFast, useIsTournament, useToggleAutoPostBlinds} from '../react-hooks.js'

export default function AutoPostBlindsOption (props) {
  const {className} = props
  const isAutoPostBlinds = useIsAutoPostBlinds()
  const toggleAutoPostBlinds = useToggleAutoPostBlinds()
  const isFast = useIsFast()
  const isTournament = useIsTournament()
  const isForceEnabled = isFast || isTournament

  return <LabeledCheckbox
    className={className}
    checked={isForceEnabled || isAutoPostBlinds}
    isDisabled={isForceEnabled}
    onClick={isForceEnabled ? undefined : toggleAutoPostBlinds}
    size='small'
  >
    Post Blinds
  </LabeledCheckbox>
}

# PlayerActionData
#   String actionCode
#   BigDecimal minBet
#   BigDecimal maxBet
#   BigDecimal minRaise
#   BigDecimal maxRaise
#   BigDecimal currentBet
#   boolean winningHand
#   boolean lastFoldShowAllowed
import Mixmaster from '../../../mixmaster.coffee'

export default class PlayerActionData extends Mixmaster
  mixin: (object) ->
    object.actions = (signal, name, values) ->
      signal.updates.action =
        type: values.code1
        minBet: if values.minBetAmount then parseFloat(values.minBetAmount) else 0
        maxBet: if values.maxBetAmount then parseFloat(values.maxBetAmount) else 0
        minRaise: if values.minRaiseAmount then parseFloat(values.minRaiseAmount) else 0
        maxRaise: if values.maxRaiseAmount then parseFloat(values.maxRaiseAmount) else 0
        currentBet: if values.value7 then parseFloat(values.value7) else 0

      if signal.untranslated.currentPlayer?
        signal.updates.action.currentPlayer = parseInt(signal.untranslated.currentPlayer.value1)

      if values.minBetAmount is values.maxBetAmount and values.minRaiseAmount is values.maxRaiseAmount
        signal.updates.action.betAmount = values.minBetAmount
        signal.updates.action.raiseAmount = signal.updates.action.currentBet + signal.updates.action.minRaise

export default
  NO_LIMIT:
    long: "No Limit"
    short: "NL"
  POT_LIMIT:
    long: "Pot Limit"
    short: "PL"
  FIXED_LIMIT:
    long: "Fixed Limit"
    short: "FL"
  HALF_POT_LIMIT:
    long: "Half Pot Limit"
    short: "HPL"

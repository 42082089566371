import React from 'react'

import DesktopSelectionButton from '../../components/DesktopSelectionButton.js'
import {useSetVariant, useVariant} from '../react-hooks.js'

export default function VariantButton (props) {
  const {label, variant} = props
  const isSelected = useVariant() === variant

  return <DesktopSelectionButton label={label} isSelected={isSelected} onClick={useSetVariant(variant)} />
}

import {Howl} from 'howler'

# ## Sounds Module

# Will only run if @app.settings.soundsEnabled is true

# NOTE !@#!$%# If you are inspecting and not hearing sounds run
# document.hasFocus = false

export default class Sounds
  constructor: (@settings, @popupMaster) ->
    @checkInit()
    # this stops us from having to check if sounds are enabled each time play is called
    @settings.on "change:soundsEnabled", @checkInit

  checkInit: =>
    if @settings.soundsEnabled
      @init()
      @play = @_play
    else
      @play = () ->

  init: () ->
    if @initialised
      return

    @initialised = true

    @howl = new Howl
      src: [document.getElementById('soundsprite').href]
      sprite:
        silence:     [0,     3000]
        cardDeal:    [3500,  4450 - 3500]
        cardShuffle: [5500,  6900 - 5500]
        check:       [7500,  8450 - 7500]
        chipMove:    [9500,  11000 - 9500]
        chips:       [11500, 12400 - 11500]
        chipsAllin:  [13500, 14950 - 13500]
        chipsRaise:  [15500, 16600 - 15500]
        chipsToPot:  [17500, 18600 - 17500]
        click:       [19900, 20450 - 19900]
        dealFlop:    [21500, 22600 - 21500]
        fold:        [23500, 24200 - 23500]
        online:      [25500, 26700 - 25500]
        playerTurn:  [27500, 28700 - 27500]
        timeout:     [29500, 35500 - 29500]
        timebank:    [36000, 37800 - 36000]
        playerWins:  [39500, 44000 - 39500]
        waitlist:    [43500, 45500 - 43500]
        login:       [47000, 48500 - 47000]
        EOF:         [50000, 52000 - 50000]

  _play: (sound, gameId, comeFromPopup) =>
    if !gameId or sound is "playerTurn" or sound is "timeout"
      @howl.play sound
      return
    # if the document supports hasFocus check it otherwise continue
    if (!document.hasFocus or document.hasFocus()) and (new RegExp("table"+gameId)).test(Navigator.location)
      @howl.play sound
      return
    popup = @popupMaster?.getClient(gameId)
    if popup?.hasFocus?()
      @howl.play sound
      return

  stop: =>
    return unless @howl

    @howl.stop()

import {useContext} from 'react'

import {context} from '../react.js'

export function useShowActive () {
  return useService().showActive
}

export function useShowClaim () {
  return useService().showClaim
}

function useService () {
  return useContext(context).bonusService
}

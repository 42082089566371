import {useAddChips} from '../context.js'
import {useService} from '../react-hooks.js'

export const useCanTopUp = () => useSelector(({tournament, isTopUpPending}) => !tournament && !isTopUpPending)
export const useShowAddChips = () => useService().showAddChips

export const useCanAddChips = () => useSelector(({tournament}) => {
  if (!tournament) return false

  const {areAddOnsAvailable, rebuys} = tournament
  const {areAvailable, isPending, remaining} = rebuys
  const rebuysRemaining = areAvailable && remaining > 0

  return !isPending && (rebuysRemaining || areAddOnsAvailable)
})

function useSelector (selector) {
  return selector(useAddChips())
}

import React from 'react'

import GameFilterSubMenu from './GameFilterSubMenu.js'
import MobileGameFormatSubMenu from './MobileGameFormatSubMenu.js'
import MobileGameSortSubMenu from './MobileGameSortSubMenu.js'
import {GAME_FORMAT} from '../../game-list/constants.js'

const {RING_GAME} = GAME_FORMAT

export default function MobileMenuGameListItems (props) {
  const {gameFormat} = props
  const isRingGame = gameFormat === RING_GAME

  return <>
    <MobileGameFormatSubMenu />
    <MobileGameSortSubMenu isRingGame={isRingGame} />
    {isRingGame && <GameFilterSubMenu />}
  </>
}

import React from 'react'

import TournamentTypeButton from './TournamentTypeButton.js'
import {ALL} from '../../constants.js'
import {TOURNAMENT_TYPE} from '../constants.js'

const {REGULAR, SATELLITE, SPECIAL} = TOURNAMENT_TYPE

export default function TournamentTypeSelection () {
  return <ul>
    <li><TournamentTypeButton label='All' tournamentType={ALL} /></li>
    <li><TournamentTypeButton label='Regular' tournamentType={REGULAR} /></li>
    <li><TournamentTypeButton label='Satellite' tournamentType={SATELLITE} /></li>
    <li><TournamentTypeButton label='Special' tournamentType={SPECIAL} /></li>
  </ul>
}

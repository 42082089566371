# PlayerView
# Model: player

import CardView from '../cardView.coffee'
import Chips from '../../../../rooms/chips/Chips.js'
import chipStyles from '../../../../rooms/chips/Chips.module.css'
import Chip from '../../../models/chip.coffee'
import emoticons from '../../../enum/enums/utils/emoticons.coffee'
import styles from '../../../../rooms/time-bank/TimeBank.module.css'
import Icon from '../../../../rooms/time-bank/components/Icon.js'
import View from '../../view.coffee'

export default class PlayerView extends View

  constructor: (player, @createReactView) ->
    super()
    @model = player

    @dom = $ "<div class='player'>"

    @position = @model.viewPosition

    # ### Components - eg. DOM elements & views
    @components =
      accolades:     $ "<div class='player-accolades'>"
      alias:         $ "<div class='alias'>"
      allInWinPct:   $ "<div class='allInWinPct'>"
      avatar:        $ "<div class='avatar'>"
      balance:       $ "<div class='balance'>"
      bet:           $ "<div class='bet'>"
      chatBubble:    $ "<div class='chatBubble'>"
      label:         $ "<div class='label'>"
      usingTimebank: $ "<div class='usingTimebank'>"
      aspectRatio:   $ "<div class='aspect'></div>"
      cards:         $ "<div class='cards'>"
      leftWrap:      $ "<div class='left'></div>"
      rightWrap:     $ "<div class='right'></div>"
      statusBar:     $ "<div class='statusBar'>"
      timer:         $ "<div class='timer'>"

    # ### Events - eg. MouseClicks ###
    @components.chatBubble.on 'click', @hideChatBubble
    @dom.on 'click', @togglePeek

    # ### Listeners - eg. auth:success
    @listeners =
      betToPot: @betToPot
      betToWinner: @betToWinner
      chatMessage: @chatMessage
      reset: @resetLabel

    # ### Changes - eg. Model.property = true
    @changes =
      # accoladeId:  @updateAccoladeId
      alias:       @updateAlias
      allInWinPct: @updateAllInWin
      balance:     @updateBalance
      bet:         @updateBet
      cards:       @updateCards
      cssClass:    @updateCssClass
      isTurn:      @updateIsTurn
      isClient:    @updateIsClient
      label:       @updateLabel
      status:      @updateStatus
      timer:       @updateTimer
      usingTimebank: @updateUsingTimebank
      preferredSeat: @updatePrefSeat

    # ### Binding ###
    @initializeView()
    return this

  updateAccoladeId: =>
    # disabled for release/0.5.1
    # @components.accolades.addClass 'accolade-' + @model.accoladeId

  updatePrefSeat: =>
    @dom.removeClass "player#{@position}"
    @components.chatBubble.removeClass "player#{@position}"
    @position = @model.viewPosition
    @dom.addClass "player#{@position}"
    @components.chatBubble.addClass "player#{@position}"

  updateAlias: =>
    encoded = $('<div/>').text(@model.alias).html()
    @components.alias.html encoded
    @updateAvatar @model.alias

  updateBet: =>
    # todo: could be more efficient to just leave the view as a component,
    #  and bind it to the bet amount somehow?
    if @model.bet
      chipView = @createReactView(
        Chips,
        {amount: @model.bet, symbol: @model.currency, precision: @model.precision},
        {class: "chip-container"},
        'div'
      )
      @model.once 'change:bet', chipView.destroy
      @components.bet.html(chipView.render())

  betToPot: =>
    unless @model.bet
      return
    chipView = @createReactView(
      Chips,
      {amount: @model.bet, symbol: @model.currency, precision: @model.precision},
      {class: "chip-container"},
      'div'
    )
    @components.bet.append(chipView.render())
    $(chipView.container()).addClass('hide-amount')
    to = @dom.closest('.game-container').find(".game-pots .chip-container").offset()
    # If no pot chips, just use the center of the screen as approximate pot location (condition, end of game preflop)
    unless to
      to = @dom.closest('.game-container').find('.game-pots').offset()
      return unless to
      to.left = parseInt(($('#arcade').width() / 2), 10) - 5
    from = $(chipView.container()).offset()
    x = to.left - from.left
    y = to.top - from.top
    $(chipView.container()).css({'transition': 'transform 300ms', 'transform': "translate(#{x}px, #{y}px)"})
    setTimeout chipView.destroy, 300

  betToWinner: (winnerPosition) =>
    unless @model.bet
      return
    chipView = @createReactView(
      Chips,
      {amount: @model.bet, symbol: @model.currency, precision: @model.precision},
      {class: "chip-container"},
      'div'
    )
    @components.bet.html chipView.render()
    $(chipView.container()).addClass('hide-amount')
    from = $(chipView.container()).offset()

    to = @dom.closest('.game-container').find(".player#{winnerPosition} .chip-container").offset()
    to ?= @dom.closest('.game-container').find(".player#{winnerPosition}").offset()
    to ?= from  # handle rare error case where UI element was not found (delayed event emit?)

    x = to.left - from.left
    y = to.top - from.top
    $(chipView.container()).css({transition: 'transform 300ms', transform: "translate(#{x}px, #{y}px)"})
    setTimeout chipView.destroy, 300

  updateStatus: (status) =>
    @dom.toggleClass 'sittingOut', status in ['out', 'sittingOut']

  updateAvatar: (alias) =>
    url = window.AVATAR_URL.replace '%NICK_NAME%', alias
    img = $ '<img>'
    img.attr 'src', url  # check image exists
    img.on 'load', =>
      # @emit 'avatar:loaded' # for unit tests (not currently used)
      @components.avatar.css 'background-image': "url(#{url})"

  updateBalance: (balance) =>
    if balance < 0 # Prevent rendering a negative amount on table from not covering ante
      balance = 0
    @components.balance.html balance.toMoney(@model.currency, @model.precision)

  updateIsTurn: =>
    @dom.toggleClass 'currentPlayer', @model.isTurn

  updateLabel: (label) =>
    unless label
      return
    @components.alias.hide()
    @components.balance.hide()
    @components.allInWinPct.hide()
    @components.label.show()
    @components.label.html label
    @labelTimeout = setTimeout @resetLabel, @labelSpeed or 2000

    clearTimeout @timeoutSound
    switch label
      when 'FOLDED'
        @dom.removeClass 'peek'
        @model.playSound 'fold'
      when 'CALLS'
        @model.playSound 'chips'
      when 'CHECKS'
        @model.playSound 'check'
      when 'BET'
        @model.playSound 'chips'
      when 'RAISES'
        @model.playSound 'chipsRaise'
      when 'ALL IN'
        @model.playSound 'chipsAllin'
      when 'POSTS SB'
        @model.playSound 'chips'
      when 'POSTS BB'
        @model.playSound 'chips'

  resetLabel: =>
    @components.alias.show()
    @components.balance.show()
    @components.label.hide()

  updateCssClass: (cssClass) =>
    @dom.removeClass 'fold bet call check raise allIn show'
    @dom.addClass cssClass

  updateAllInWin: (allInWin) =>
    clearTimeout @labelTimeout
    @resetLabel()
    html = if allInWin.pct is 100 then 'WIN' else allInWin.pct + '%'
    @components.balance.hide()
    @components.allInWinPct.show()
    @components.allInWinPct.html(html)
      .toggleClass('winning', allInWin.winning)
      .toggleClass('losing', not allInWin.winning)

  updateCards: (cards) =>
    for card in cards then do (card) =>
      cardView = new CardView card
      card.flippable = @model.isClient
      @components.cards.append cardView.render()
      @model.once 'change:cards', cardView.destroy

  updateEmot: (emot, frameIndex, repsRemaining) =>
    imageIndex = emot.frames[frameIndex].index
    img = @components.chatBubble.find('div')
    img.removeClass().addClass 'emoticon chatBubble-emoticon ' + emot.files[imageIndex]
    if emot.reps == undefined and emot.frames.length == 1
      @emotTimeout = setTimeout @hideChatBubble, 4000
    else if emot.frames[frameIndex + 1]
      # if there is a next frame, wait "reps" amount of time, then move on to it
      reps = emot.frames[frameIndex].reps or 1
      @emotTimeout = setTimeout =>
        @updateEmot emot, frameIndex + 1, repsRemaining
      , reps * 60
    else if repsRemaining? and repsRemaining > 0
      # back to frame 0, do entire animation again
      @emotTimeout = setTimeout =>
        @updateEmot emot, 0 , repsRemaining - 1
      , 60
    else
      # final frame, final repetition. remove chat bubble
      reps = emot.frames[frameIndex].reps or 1
      @emotTimeout = setTimeout @hideChatBubble, 60 * reps

  hideChatBubble: =>
    @components.chatBubble.empty().removeClass 'chatBubble-visible chatBubble-emoticon'
    @emit 'hideChatBubble'

  chatMessage: (message) =>
    @components.chatBubble.appendTo @dom.parent()
    emot = Object.values(emoticons).find((candidate) -> candidate.text is message)
    if emot
      if emot.style # alias for real emot
        emot = emoticons[emot.style]
      img = $('<div class="emoticon chatBubble-emoticon">')
      img.addClass emot.files[0]
      @components.chatBubble
        .html(img)
        .addClass('chatBubble-visible')
        if @emotTimeout
          clearTimeout @emotTimeout
        @updateEmot emot, 0, emot.reps
    else
      @components.chatBubble.text(message).addClass 'chatBubble-visible'
      setTimeout @hideChatBubble, @chatBubbleDuration or 4000

  updateTimer: =>
    # Reset any previous animations, quirk note: the setTimeout allows the animation to clear
    clearTimeout @timeoutSound # does this help
    @components.timer.removeAttr 'style'
    unless @model.timer
      return

    # # record time this animation changes so if user switches clients, it can be reset to the correct remaining amount
    # @model.startedTimer = new Date
    # setTimeout =>
    #   @model.startedTimer = null
    # , @model.timer * 1000

    value = @model.timer
    # Client player only sees their own timer count down in the final 5 seconds
    # Future: They also receive the "timeout" noise during the final 5 seconds
    if @model.isClient and value >= 6
      # do client player blink for 15secs or part thereof, then 5 second timer bar
      blink = value - 5
      setTimeout =>
        template = (engine) -> "#{ engine }animation: 1s linear 0s normal none #{ blink } blink, timer 5s linear #{ blink }s 1 normal; "
        animation = template('-webkit-') + template('-moz-') + template('')
        @components.timer.attr 'style', animation
      , 10
      @timeoutSound = setTimeout (=> @model.playSound('timeout')), blink * 1000
    else
      # Other players' timers count down continually
      setTimeout =>
        template = (engine) -> "#{ engine }animation: timer #{ value }s linear 0s 1 normal; "
        animation = template('-webkit-') + template('-moz-') + template('')
        @components.timer.attr 'style', animation
      , 10

  updateIsClient: =>
    @dom.toggleClass 'clientPlayer', @model.isClient

  updateUsingTimebank: =>
    unless @model.usingTimebank
      @components.usingTimebank.empty()
      return

    @components.usingTimebank.html(@createReactView(Icon, {
      start: "#{@model.usingTimebank}s"
      end: "0s"
    }, {
      class: styles.isEnabled
    }).render())

  togglePeek: =>
    @dom.toggleClass 'peek'

  render: =>
    # position = @model.preferredSeat or @model.position
    # @dom.addClass "player#{position}"
    # @components.chatBubble.addClass "player#{position}"
    @dom.addClass "player#{@model.viewPosition}"
    @components.chatBubble.addClass "player#{@model.viewPosition}"

    @updateAlias(@model.alias)
    @updateBalance(@model.balance)
    @updateStatus(@model.status)
    @updateBet(@model.bet)
    @updateCssClass(@model.cssClass)
    @updateCards(@model.cards)

    @updateIsClient()

    @updateIsTurn @model.isTurn
    @updateLabel @model.label
    @updateTimer @model.timer
    @updateUsingTimebank @model.usingTimebank

    @dom
      .append(@components.accolades)
      .append(@components.bet)
      .append(@components.cards)
      .append(@components.timer)
      .append(@components.statusBar)
      .append(@components.aspectRatio)
      .append(@components.leftWrap)
      .append(@components.rightWrap)

    @components.leftWrap
      .append(@components.avatar)
      .append(@components.usingTimebank)

    @components.rightWrap
      .append(@components.alias)
      .append(@components.balance)
      .append(@components.label)
      .append(@components.allInWinPct)

    return @dom

import React, {useMemo} from 'react'

import Money from '../../../money/components/Money.js'
import {useRootedSelector} from '../../../redux.js'
import {createGetAddOns, getRoot} from '../../index.js'

export default function AddOnPayments (props) {
  const addOns = useRootedSelector(getRoot, useMemo(createGetAddOns, []), props)

  const {areEnabled, chips, cost, perSeatLimit} = addOns
  const value = areEnabled ? <>{perSeatLimit} (<Money value={cost} /> for <Money value={chips} />)</> : <>No</>

  return <div className='kvpair selfclear'>
    <label>Addon Payments:</label><span>{value}</span>
  </div>
}

export default ({signal, details, app, game, player, client}) ->
  # Ensures the tournament gets set to not playing for multitable
  [, tournamentId] = details.eventText # 'Congratulations. You finished 1st in Tournament Zebra Room - Heads Up and you won |7988212|1|10^^|true'

  tournamentId = parseInt(tournamentId, 10)
  tournament = app.listings.find {instanceId: tournamentId}
  tournament?.playing = false

  # WARNING: This notification is actually handled by eliminatedPlayer update. See WEB-1336
  # app.tournamentPosition message, tournament

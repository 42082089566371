import UAParser from 'ua-parser-js'

import {DESKTOP, MOBILE, TABLET} from './constants.js'

export function detectFormat (userAgent) {
  const {device: {type: deviceType}, os: {name, version}} = userAgent

  if (deviceType && deviceType !== TABLET) return MOBILE

  // because old ipads are terrible
  if (deviceType === TABLET && name === 'iOS' && parseFloat(version) < 6) return MOBILE

  if (deviceType === TABLET) return TABLET

  // unrecognized android device. assume mobile
  if (name === 'Android') return MOBILE

  return DESKTOP
}

export function findFormat (detectedFormat, localStorage) {
  if (!localStorage) return detectedFormat

  const stored = JSON.parse(localStorage.getItem('global:format')) || 'auto'

  return stored === 'auto' ? detectedFormat : stored
}

export function findUserAgent (window) {
  const uaParser = new UAParser()
  uaParser.setUA(window.navigator.userAgent)

  return uaParser.getResult()
}

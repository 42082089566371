import intersection from 'lodash/intersection'

import Model from './model.coffee'
import {AUTO_REBUY_POINT_OFF} from '../../rooms/constants.js'
import Statistics from './statistics.coffee'
import Timebank from './timebank.coffee'

export default class Client extends Model

  constructor: ({user, game}) ->
    super()

    @game = game
    @statistics = new Statistics {game, details: {}}

    @user = user
    @settings = settings = user.settings
    @timebank = new Timebank details: {selected: settings.autoTimebank}
    @notifications = []

    @preactionExcluded = ['sitOut', 'sitIn', 'standUp', 'leave', 'rebuy']

    @properties =
      autoBlinds: game.speed is 'fast' or settings.autoBlinds
      autoMuck: settings.autoMuck
      autoRebuy: @initAutoRebuy()
      betValues: {}
      chatAllowed: true
      checkPossible: false
      cssClass: ''
      current: false
      currentActions: []
      description: ''
      instanceId: game.instanceId or 0
      tournamentId: game.tournamentId or 0
      intendedAction: null
      isTurn: false
      maxRebuy: game.maxBuyIn or 0
      minRebuy: game.minBuyIn or 0
      popup: false
      position: null
      previousActions: {}
      previousBalance: 0
      seated: false
      serverId: game.serverId or 0
      sittingOut: false
      showFoldToAny: false
      tableBalance: 0
      timer: null
      userBalance: if @game.realMoney then @user.realMoney else @user.playMoney
      activeSitOut: false
      rebuyAllowed: false
      addonAllowed: false
      onBreak: false
      breakEndTime: null
      imReadyAllowed: null
      imReady: false
      listType: null
      waitlistPosition: {}
      waiting: false
      centerInformation: {}
      buyInAmount: 0
      ringGameRebuyAmount: undefined

    @updateMinRebuy()
    @updateMaxRebuy()

    # @on 'change:seat', => console.log 'change:seat', @seat

    @timebank.on 'change:selected', (selected) =>
      @action 'timebank', {selected}

    @user.on 'change:realMoney', (v) =>
      if @game.realMoney
        @userBalance = v

    @user.on 'change:playMoney', (v) =>
      unless @game.realMoney
        @userBalance = v

    @game.on 'playSound', @playSound
    @game.on 'showHandHistory', => @emit 'showHandHistory', arguments...
    @game.on 'unreservation', (position) => @emit 'unreservation', position

    @game.on 'change:currentPlayer', (position) =>
      @isTurn = position is @player?.position
      if @isTurn
        @playSound 'playerTurn', @instanceId

    @game.on 'change:speed', (speed) =>
      if speed is 'fast'
        @emit 'lock:autoPostBlinds'

    @game.on 'show:statistics', =>
      @emit 'show:statistics'

    # todo: this is a hack for clearing rabbit hand
    @game.on 'new:game', =>
      @emit 'rabbit', false

    @on 'change:currentActions', (actions) ->
      @checkPossible = actions.includes('check')

    @on 'change:sittingOut', @sittingOutActions

    # todo: this is useful but the logic is slightly convoluted
    @on 'change:seated', (seated) =>
      # console.warn "Change Seated"
      unless seated
        @currentActions = []

      if seated
        # Timebank initial setting
        @action 'timebank', {selected: @timebank.selected}

      # when the client player sits down we want to calculate the prefSeatOffset
      @setClientPrefSeatOffSet()
      # basically toggles prefseating on and off
      @game.players.each @doPlayerPrefSeating
      @doDealerPrefSeating()

    # If the user changes there pref seat settings and is seated we regenerate offset and player prefSeat
    @settings.on 'change:preferredSeating', =>
      @setClientPrefSeatOffSet()
      if @seated #not needed saves iterating the entire player collection
        @game.players.each @doPlayerPrefSeating
        @doDealerPrefSeating()

    # remove listeners on leaving the game
    @game.on 'left', =>
      # console.log 'left game delete client', @id
      @removeAllListeners 'change:autoRebuy'

    # Rebuy
    @game.on 'change:minBet', @updateMinRebuy
    @game.on 'change:minBuyIn', @updateMinRebuy
    @on 'change:tableBalance', @updateMinRebuy

    @game.on 'change:maxBuyIn', @updateMaxRebuy
    @game.on 'change:minBet', @updateMaxRebuy
    @on 'change:tableBalance', @updateMaxRebuy
    @on 'change:userBalance', @updateMaxRebuy

    # Auto Rebuy
    @on 'change:autoRebuy', @sendAutoRebuy
    @game.on 'change:minBuyIn', @autoRebuyDefaults
    @game.on 'change:maxBuyIn', @autoRebuyDefaults
    @game.on 'change:initialBuyIn', @autoRebuyDefaults
    @game.on 'change:dealer', @doDealerPrefSeating
    # When a player is added we what to calculate his/her preferredSeat
    @game.players.on 'add', @doPlayerPrefSeating

    @initializeModel arguments...

  setClientPrefSeatOffSet: =>
    prefSeat = @settings.preferredSeating[@game.maxPlayers] # max players = 6
    if prefSeat?
      # if the users position is 5 (last seat) and pref seat is 0 they need to move forward 1 seat
      @prefSeatOffset = prefSeat - @position #-5
      if @prefSeatOffset < 0
        @prefSeatOffset += @game.maxPlayers # -5 + 6 = 1
        # this means players in positions 0, 1, 2, 3, 4, 5 move respectively to 1, 2, 3, 4, 5, 0
    else
      @prefSeatOffset = null

  doPlayerPrefSeating: (player) =>
    player.preferredSeat = @calcPrefSeat(player.position)

  doDealerPrefSeating: =>
    @game.preferredDealerPosition = @calcPrefSeat(@game.dealer)

  # we use this for seatsView as well
  calcPrefSeat: (actualPosition) =>
    calcedPosition = null
    if @seated and @prefSeatOffset?
      calcedPosition = (actualPosition + @prefSeatOffset) % @game.maxPlayers
      # users postion 5 moves forward 1 to position 6 (doesnt exist)
      # 6 mod 6 is 0
      # 5 mod 6 is 5
      # 4 mod 6 is 4
    return calcedPosition


  setPlayer: (player) =>
    @sittingOut = player.status in ['out', 'sittingOut']
    @player = player
    @position = player.position
    @tableBalance = player.balance
    @player.on 'change:balance', (balance) =>
      @tableBalance = balance
    @player.on 'change:timer', (timer) =>
      @timer = timer
      # without this next line, the event does not fire. with it, it fires twice.
      @emit 'change:timer', @player.timer

    # previewView needs to know about client player's cards
    @player.on 'change:cards', =>
      @emit 'change:cards', @player.cards

    @player.on 'change:cssClass', (cssClass) =>
      @cssClass = cssClass
      @emit 'change:cssClass', @player.cssClass

  reset: =>
    @playing = false
    @sittingOut = false
    @player = null
    @position = null
    @tableBalance = 0
    # @player.cards = []
    @chatAllowed = true
    @checkPossible = false
    # @current = false
    @currentActions = []
    @description = ''
    @isTurn = false
    @intendedAction = null
    @previousBalance = 0
    @previousActions = {}
    @seated = false
    @activeSitOut = false


  # ########
  # Actions
  # ########
  autoAction: =>
    actions = @currentActions
    blindMatches = intersection actions, ['postBb','postSb','postIb','postRb']

    # Auto Show
    if actions.length is 1 and actions.includes('show')
      @action 'show'

    # Auto Muck
    if actions.includes('muck') and @autoMuck
      @action 'muck'
      return true

    if actions.includes('postBb')
      @waitingForBigBlind = false

    if actions.includes('postIb') and @waitingForBigBlind
      @action 'waitIb'
      return true

    if actions.includes('postRb') and @waitingForBigBlind
      @action 'waitRb'
      return true

    # Auto Blind - there should only be one
    else if blindMatches.length is 1 and @autoBlinds
      @action blindMatches[0]
      return true

    else
      return false

  processAction: (actionName, args = {}) =>
    if actionName in @preactionExcluded
      @action actionName, args
    else
      if @isTurn
        @action actionName, args
      else
        @preaction actionName, args

  action: (actionName, args) =>
    # this stops the timeout sound if its running
    if @waitingOnAction
      window.Sounds.stop()
      @waitingOnAction = false
    # According to the java client
    if actionName in ['check','bet','raise','call']
      # Enables display of 'Fold to any bet' preaction
      @showFoldToAny = true
    else
      @showFoldToAny = false
    @emit 'clientAction', actionName, args

  preaction: (actionName, args) =>
    # toggle on/off
    if @intendedAction?.actionName is actionName
      @intendedAction = null
    else
      @intendedAction = {actionName, args}
      if actionName is 'foldToAnyBet'
        @intendedAction.multiRound = true

  removePreaction: =>
    if not @intendedAction?.multiRound
      @intendedAction = null

  sendPreaction: =>
    if @intendedAction
      @action @intendedAction.actionName, @intendedAction.args

  actionOutcome: (success) =>
    if success
      @currentActions = []
      @removePreaction()

  leaveGame: ({force, lobby}) =>
    if @user.settings.confirmLeaveGame and not force
      @emit 'confirmLeaveGame'
    else
      @action 'leaveGame'
      @left = true
      if lobby
        @emit 'tryNavLobby'

  removed: =>
    @left = true
    @emit 'tryNavLobby'


  # ########
  # Sit Out
  # ########

  # The kind of sit out where your client still makes moves.
  # ie. during a hand or in tournaments
  activeSitOutActions: =>
    unless @activeSitOut # can't set client.sittingOut = true because that offers Stand Up, Leave, etc
      @showFoldToAny = false
      @isTurn = @game.currentPlayer is @player?.position
      @activeSitOut = true
      @currentActions = ['sitIn']
      @emit 'activeSitOut'

  sittingOutActions: =>
    if @sittingOut and not @onBreak
      if @game.tournamentId
        @currentActions = ['sitIn', 'leave']
      else
        @currentActions = ['sitIn', 'rebuy', 'leave']

  sitInAction: =>
    if @sittingOut and not @onBreak
      @currentActions = ['sitIn']

  # ########
  # Rebuy / Buyin
  # ########

  buyin: =>
    @emit 'show:buyin'

  updateMinRebuy: =>
    @minRebuy = @game.minBet
    if @tableBalance < @game.minBuyIn
      @minRebuy = @game.minBuyIn - @tableBalance

  updateMaxRebuy: =>
    if @game.maxBuyIn > 0
      # todo: tidy this logic. see rebuyViewTest
      if @userBalance < (@game.maxBuyIn - @tableBalance)
        max = @userBalance
      else
        max = Math.min @game.maxBuyIn, @userBalance
        max -= @tableBalance
        max = Math.max max, @game.minBet # max can't be below min
    else # fixed limit games have no buyin
      max = @userBalance
    @maxRebuy = max

  sendAutoRebuy: =>
    unless @seated
      return
    # delay sending for 500ms to prevent spamming (throttle)
    clearTimeout @autoRebuyTimeout
    @autoRebuyTimeout = setTimeout =>
      autoRebuy =
        enabled: @autoRebuy.point isnt AUTO_REBUY_POINT_OFF
        point: @autoRebuy.point
        amount: @autoRebuy.amount
      @action 'autoRebuy', autoRebuy: autoRebuy
    , 500

  initAutoRebuy: =>
    ar = @user.settings.autoRebuy
    if ar.initialBuyIn
      amount = @game.initialBuyIn
    else
      amount = @game.maxBuyIn * (ar.amount / 100)

    autoRebuy =
      point: ar.point
      amount: Math.max amount, @game.minBuyIn  # can't rebuy below minBuyIn

    return autoRebuy

  autoRebuyDefaults: =>
    ar = @user.settings.autoRebuy
    if ar.initialBuyIn
      if @game.initialBuyIn?
        amount = @game.initialBuyIn
      else
        return false
    else
      amount = @game.maxBuyIn * (ar.amount / 100)
    amount = Math.max amount, @game.minBuyIn  # can't rebuy below minBuyIn
    @autoRebuy =
      point: ar.point
      amount: amount


  # ########
  # Waitlist
  # ########
  addToWaitlist: =>
    @action 'addToWaitlist', {}

  removeFromWaitlist: =>
    @action 'removeFromWaitlist', {}


  # ########
  # Chat
  # ########

  sendChatMessage: =>
    @action 'chatMessage', arguments...

  displayChatMessage: =>
    @emit 'chat:message', arguments...

  subscribeToChatMessages: (subscriber) =>
    @on 'chat:message', subscriber
    return () =>
      @off 'chat:message', subscriber

  # #######
  # Error Messages
  # #######
  error: (type) =>
    @emit "error:#{type}"

  reportProblem: =>
    @emit 'reportProblem'

  # ########
  # Misc
  # ########

  settingsWarning: =>
    @emit 'settingsWarning'

  rabbitHunt: (v) =>
    @emit 'rabbitHunt', v

  fold: =>
    # console.log "client fold() function called "
    if @checkPossible
      if @user.settings.checkFoldPreference is 'check'
        @processAction 'check'
      else if @user.settings.checkFoldPreference is 'fold'
        @processAction 'fold'
      else
        @emit 'showCheckFoldSelectModal'
    else
      @processAction 'fold'

  jackpotWin: (title, winners, community) =>
    @emit 'jackpotWin', title, winners, community

  promptRematch: =>
    @emit 'promptRematch', arguments...

  tournamentRematch: =>
    @emit 'tournamentRematch', arguments...
    # @emit 'action', 'getTournamentDetails'

  promptAutoMuck: =>
    @emit 'promptAutoMuck'

  promptRebuy: (details) =>
    @emit 'show:rebuy', details

  promptAddon: (details) =>
    @emit 'show:addon', details

  visitCashier: =>
    @emit 'visitCashier', arguments...

  addNotification: ({message, closable}) =>
    @notifications.push {message, closable}

  displayNotification: =>
    if @notifications.length
      notification = @notifications[0]
      if notification.closable
        @notifications.shift() # clear notification from queue
        @emit 'showNotification', notification
      else if !@notificationTimeout
        @notifications.shift() # clear notification from queue
        @notificationTimeout = setTimeout @hideNotification, 10 * 1000 # 10s
        @emit 'showNotification', notification
      # else if @notificationTimeout
      #   console.warn 'skipping displayNotification because one already displaying'

  hideNotification: =>
    @emit 'hideNotification'
    clearTimeout @notificationTimeout
    @notificationTimeout = null
    if @game.currentState?.current in ['waitingForPlayers']
      @displayNotification() # next queued

  autoBuyin: =>
    @emit 'seatedByWaitlist'
    # note: this logic copied from buyInView
    defaultVal = @game.minBet * parseInt(@user.settings.defaultBuyin)
    defaultVal = Math.max @game.minBuyIn, defaultVal
    if @game.maxBuyIn  # FL
      defaultVal = Math.min @game.maxBuyIn, defaultVal
    amount = @previousBalance or defaultVal
    if @userBalance < amount
      amount = @user.realMoney
    @action 'sit',
      seat: @position
      amount: amount

  promptReEnter: =>
    @emit 'promptReEnter', arguments...

  playSound: (soundID, instanceId) =>
    if soundID is "timeout"
      @waitingOnAction = true
    window.Sounds.play soundID, instanceId

  waitlistNotification: =>
    @emit 'waitlistNotification', arguments...

  toggleGameOptions: =>
    @emit 'toggleGameOptions'

  addChips: =>
    if @seated
      if @tournamentId
        if @rebuyAllowed
          @emit 'show:rebuy'
      else
        @emit 'show:ringGameRebuy'

  showInsufficientFundsForAutoRebuy: =>
    @emit 'showInsufficientFundsForAutoRebuy', arguments...

  showInsufficientFundsForBuyIn: =>
    @emit 'showInsufficientFundsForBuyIn', arguments...

  showInsufficientFundsForRingGameRebuy: =>
    @emit 'showInsufficientFundsForRingGameRebuy', arguments...

  showPreviousBalanceTooHighForBuyIn: =>
    @emit 'showPreviousBalanceTooHighForBuyIn', arguments...

  showTableBalanceTooHighForRingGameRebuy: =>
    @emit 'showTableBalanceTooHighForRingGameRebuy', arguments...

  showInvalidAmount: =>
    @emit 'showInvalidAmount', arguments...

  updateRealMoneyBalance: =>
    @emit 'updateRealMoneyBalance'

import Mixmaster from '../../mixmaster.coffee'
import HighCardMixin from './mixins/highCardMixin.coffee'

export default class HighCardTranslator extends Mixmaster
  mixins = [
    HighCardMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

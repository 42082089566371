import React from 'react'

export default function MenuItemContent (props) {
  const {children, icon = 'help'} = props

  return <label className='iconLink'>
    <span className={`icon icon-${icon}`} />
    <span>{children}</span>
  </label>
}

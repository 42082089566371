import React from 'react'

import MobileTournamentListingRow from './MobileTournamentListingRow.js'
import List from '../../components/game-listing/list/List.js'

export default function MobileTournamentSkeletonList () {
  const rows = []

  const tournament = {
    categories: [],
    gameTypes: [],
    isSng: true,
    limitType: 'NO_LIMIT',
    maximumRegistrants: 5000,
    name: 'sample tournament',
    registrantCount: 0,
  }

  for (let i = 0; i < 10; i++) {
    rows.push(<MobileTournamentListingRow key={i} tournament={tournament} />)
  }

  return <List isSkeleton>{rows.map(row => row)}</List>
}

import {OUT} from '@pipehat/chronicle/constants/seat-status'

import {useSeats} from '../context.js'

export const useIsSeated = () => useSelector(({ownSeat}) => ownSeat > 0)
export const useStack = () => useSelector(({ownSeat, seats}) => seats[ownSeat].stack)

export const useIsSittingOut = () => useSelector(({ownSeat, seats}) => {
  for (const seatNumber in seats) {
    if (seatNumber === String(ownSeat)) return seats[seatNumber].status === OUT
  }

  return false
})

function useSelector (selector) {
  return selector(useSeats())
}

import React from 'react'

import LimitTypeSelection from './LimitTypeSelection.js'
import TournamentTypeSelection from './TournamentTypeSelection.js'
import VariantSelection from './VariantSelection.js'

export default function DesktopSelection () {
  return <>
    <TournamentTypeSelection />
    <VariantSelection />
    <LimitTypeSelection />
  </>
}

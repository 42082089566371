import React from 'react'

import Close from './components/Close.js'
import Input from './components/Input.js'
import Log from './components/Log.js'
import UnavailableWarning from './components/UnavailableWarning.js'
import {useIsAvailable, useIsExpanded} from './react-hooks.js'

export default function Chat (props) {
  const {maximizedChat} = props
  const isAvailable = useIsAvailable()
  const isExpanded = useIsExpanded()

  return <>
    <Log maximizedChat={maximizedChat} />
    {!isAvailable && isExpanded && <UnavailableWarning />}
    <Input maximizedChat={maximizedChat} />
    {maximizedChat && isExpanded && <Close />}
  </>
}

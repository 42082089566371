import {isObject} from '../object.js'
import {THIRTY_DAYS} from '../time/time-amount.js'

export function createStore (createGenericStore, setting) {
  const store = createGenericStore(setting, normalize)

  return {
    ...store,

    toggle (id) {
      store.set(pinned => { toggle(id, pinned) })
    },
  }
}

function normalize (pinned) {
  if (!isObject(pinned)) return {}

  const cutoff = Date.now() - THIRTY_DAYS
  const normalized = {}

  for (const id in pinned) {
    try {
      const pinnedAt = pinned[id]

      if (new Date(pinnedAt).valueOf() > cutoff) normalized[id] = pinnedAt
    } catch (error) {
      // ignore any invalid dates
    }
  }

  return normalized
}

function toggle (id, pinned) {
  if (pinned[id]) {
    delete pinned[id]
  } else {
    pinned[id] = new Date().toISOString()
  }
}

import Model from './model.coffee'

export default class Statistics extends Model

  constructor: ({details, game}) ->
    super()

    @game = game

    @properties =
      dealt: details.dealt ?= 0
      won: details.won ?= 0
      wonAtPreflop: details.wonAtPreflop ?= 0
      wonAtShowdown: details.wonAtShowdown ?= 0
      flops: details.flops ?= 0
      stakes: game.stakesDescription ?= ''
      moneyType: if game.realMoney then 'Real Money' else 'Play Money'
      waiting: details.waiting ?= 0
      watching: details.watching ?= 0
      # tourney stats
      chipsInPlay: details.sum ?= 0
      addons: details.addons ?= 0
      rebuys: details.rebuys ?= 0
      highStack: details.maximum ?= 0
      lowStack: details.minimum ?= 0
      averageStack: details.average ?= 0

    @game.on 'change:numberPlayers', (numberPlayers) =>
      @watching = numberPlayers.watching
      @waiting = numberPlayers.waitListLength

    @game.on 'change:stakesDescription', (stakesDescription) =>
      @stakes = stakesDescription

    @initializeModel arguments...

export function compareNumber (original, other) {
  return original - other
}

export function compareString (original, other) {
  return original.localeCompare(other)
}

export function createCompareIsoDateTime () {
  const cache = {}

  function fromCached (isoDateString) {
    let date = cache[isoDateString]

    if (!date) {
      date = new Date(isoDateString)
      cache[isoDateString] = date.valueOf()
    }

    return date
  }

  return (original, other) => {
    return fromCached(original) - fromCached(other)
  }
}

import React from 'react'

import DesktopGameFormatButton from './DesktopGameFormatButton.js'
import {GAME_FORMAT} from '../constants.js'

const {RING_GAME, SCHEDULED_TOURNAMENT, SITNGO_TOURNAMENT} = GAME_FORMAT

export default function DesktopGameFormatSelection () {
  return <ul>
    <li><DesktopGameFormatButton label='Cash Games' gameFormat={RING_GAME} /></li>
    <li><DesktopGameFormatButton label='Sit & Go' gameFormat={SITNGO_TOURNAMENT} /></li>
    <li><DesktopGameFormatButton label='Tournaments' gameFormat={SCHEDULED_TOURNAMENT} /></li>
  </ul>
}

# the cb var is a callback that is executed once there has been
# a response to the request.
# don't try and do anything complicated with it
# it's more of a confirmation that the message was delivered

import {EventEmitter} from 'events'

export default class Request extends EventEmitter
  constructor: ({messageId, cb, cbTime}) ->
    super()
    @messageId = messageId
    @cb = cb
    @cbTime = cbTime
    @arguments ?= {}

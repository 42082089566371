import React, {useMemo} from 'react'

import ParticipantListingRow from './ParticipantListingRow.js'
import {useRootedSelector} from '../../redux.js'

import {
  createGetParticipants,
  createIsFinished,
  createIsRegistering,
  createIsSng,
  getRoot,
  getSelfNickname,
} from '../index.js'

export default function ParticipantListing (props) {
  const participants = useRootedSelector(getRoot, useMemo(createGetParticipants, []), props)
  const isFinished = useRootedSelector(getRoot, useMemo(createIsFinished, []), props)
  const isRegistering = useRootedSelector(getRoot, useMemo(createIsRegistering, []), props)
  const isSng = useRootedSelector(getRoot, useMemo(createIsSng, []), props)
  const selfNickname = useRootedSelector(getRoot, getSelfNickname)

  const participantRows = participants.map(participant => {
    const {nickname, place} = participant
    const shouldHideName = nickname !== selfNickname && isSng && isRegistering

    return <ParticipantListingRow
      key={place}
      isFinished={isFinished}
      displayName={shouldHideName ? 'Registered Player' : nickname}
      participant={participant}
    />
  })

  return <table className="playerList plainTable scrollable">
    <thead>
      <tr><th>Pos</th><th>Player</th><th>Chips/Win</th></tr>
    </thead>
    <tbody>
      {participantRows}
    </tbody>
  </table>
}

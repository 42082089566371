import React, {useMemo} from 'react'

import RingGameStakesButton from './RingGameStakesButton.js'
import {useRootedSelector} from '../../redux.js'
import {createGetRingGameFilterStakesList, getRoot} from '../index.js'
import {useRingGameFilter} from '../react-hooks.js'

export default function RingGameStakesButtonGroup () {
  const [filter] = useRingGameFilter()
  const stakesList = useRootedSelector(getRoot, useMemo(createGetRingGameFilterStakesList, []), {filter})

  return stakesList.map(({key, minimumStake, maximumStake}) => {
    return <RingGameStakesButton key={key} minimumStake={minimumStake} maximumStake={maximumStake} />
  })
}

import Request from './request.coffee'

export default class AddonRequest extends Request
  constructor: ({client, message, accept, paymentId}) ->
    super arguments...

    @function = 'ADDON'

    @attributes =
      type: 'REQUEST'
      service: 'GAME'
      routerId: message.routerId
      serverId: client.serverId
      instanceId: client.instanceId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    if accept
      argument = {'TRUE': paymentId}

    # see WEB-1032 for docs
    @arguments =
      ADDON_CHIPS: argument

import React from 'react'

import BreakCountdownTime from './components/BreakCountdownTime.js'
import LevelCountdown from './components/LevelCountdown.js'
import LevelDescription from './components/LevelDescription.js'
import Signal from '../../../signal/Signal.js'
import {useTableId} from '../../context.js'
import styles from '../GameDetails.module.css'
import JackpotAmount from '../components/JackpotAmount.js'
import Row from '../components/Row.js'

import {
  useBreakCountDown,
  useHandNumber,
  useIsOnBreak,
  useJackpotAmount,
  useLevel,
  useLevelCountdown,
  useTableName,
  useTournamentName,
} from '../react-hooks.js'

export default function Tournament () {
  const breakCountdown = useBreakCountDown()
  const handNumber = useHandNumber()
  const isOnBreak = useIsOnBreak()
  const jackpotAmount = useJackpotAmount()
  const level = useLevel()
  const levelCountdown = useLevelCountdown()
  const tableName = useTableName()
  const tournamentName = useTournamentName()
  const tableId = useTableId()

  return <>
    <Signal />
    <Row className={styles.spacer}>{tournamentName}</Row>
    <Row>{tableName} - {tableId}-{handNumber}</Row>
    {level && <LevelDescription level={level} />}
    {jackpotAmount && <JackpotAmount amount={jackpotAmount} />}
    {!isOnBreak && levelCountdown && <LevelCountdown countdown={levelCountdown} handNumber={handNumber} />}
    {!isOnBreak && breakCountdown && <BreakCountdownTime countdown={breakCountdown} />}
  </>
}

import dayjs from 'dayjs'

export default ({signal, app}) ->
  app.user.authAttempts.push
    type: 'response'
    time: dayjs(Webclient.Time).format('HH:mm:ss')
    success: signal.success
    failReason: signal.failReason

  if signal.success
    app.authSuccess signal
  else
    app.authFail signal.failReason

  if signal.login?.updateNicknamePrompt
    app.updateNicknamePrompt()

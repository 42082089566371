import {OMAHA_HOLDEM, OMAHA_HOLDEM_HILO, TEXAS_HOLDEM} from '@pipehat/chronicle/constants/game-variant'

const supportedVariants = [OMAHA_HOLDEM, OMAHA_HOLDEM_HILO, TEXAS_HOLDEM]

export function isTypeIdSupported (typeId) {
  switch (String(typeId)) {
    case '1': // Texas Holdem / ring game / play money
    case '2': // Texas Holdem / ring game / real money
    case '22': // Texas Holdem / tournament / play money
    case '23': // Texas Holdem / tournament / real money
    case '9': // Omaha / ring game / real money
    case '25': // Omaha / tournament / real money
    case '35': // Omaha HiLo / ring game / real money
    case '37': // Omaha HiLo / tournament / real money
      return true
  }

  return false
}

export function isTypeSequenceSupported (gameTypes) {
  if (gameTypes.length !== 1) return false

  for (const {variant} of gameTypes) {
    if (!supportedVariants.includes(variant)) return false
  }

  return true
}

import React from 'react'

import LabelRow from './LabelRow.js'
import Checkbox from '../../components/Checkbox.js'
import {useIsShowTournamentPositionNotice, useToggleShowTournamentPositionNotice} from '../react-hooks.js'

export default function ShowTournamentPosition () {
  return <LabelRow
    name='Show Tournament Position'
    help='Will show a modal containing your position/result at the end of a tournament.'
  >
    <Checkbox
      checked={useIsShowTournamentPositionNotice()}
      onChange={useToggleShowTournamentPositionNotice()}
      size='small'
    />
  </LabelRow>
}

import React, {useCallback} from 'react'

import LabelRow from './LabelRow.js'
import {useShowPreferredSeating} from '../react-hooks.js'

export default function PreferredSeating (props) {
  const {label, seats} = props
  const showSeatSelection = useShowPreferredSeating()

  const handleClick = useCallback(() => {
    showSeatSelection(seats)
  }, [seats, showSeatSelection])

  return <LabelRow name={label}>
    <button type='button' className='button' onClick={handleClick}>{seats} Player</button>
  </LabelRow>
}

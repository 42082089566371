import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

export default class GetBalanceTranslator extends Mixmaster
  mixins = [DefaultMixin]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

    signal.updates.balance = {}

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  balance: (signal, name, value, node) ->
    signal.updates.balance.balance = value

  realMoney: (signal, name, value, node) ->
    signal.updates.balance.realMoney = value

  vipPointsBalance: (signal, name, value, node) ->
    # deprecated, but still need this method or we get a console warning
    # signal.updates.balance.vipPoints = value

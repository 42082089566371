import reduce from 'lodash/reduce'

export default ({signal, details, app, game, client, listing}) ->
  return unless game

  #below array use to contain preflop but this means that pots wouldnt be displayed for all-ins preflop
  state = signal.updates?.currentState or game.currentState
  unless state?.current
    # console.warn 'no signal.updates.currentState - aborting updates/pot'
    return
  if state.current not in ['bigBlind', 'smallBlind', 'initialBlind', 'returnBlind', 'preflop'] or signal.updates.allInShowdown
    app.once 'signalFinished', ->
      game.pots = details
      game.potTotal = reduce details, ((memo, pot) -> return memo + pot.amount), 0

import React from 'react'

import MobileRingGameSortSubMenu from './MobileRingGameSortSubMenu.js'
import MobileSngSortSubMenu from './MobileSngSortSubMenu.js'
import MobileTournamentSortSubMenu from './MobileTournamentSortSubMenu.js'
import {GAME_FORMAT} from '../../game-list/constants.js'
import {useGameFormat} from '../../game-list/react-hooks.js'

// these sort "types" are based on the legacy data representation, and should be
// renamed when the lobby itself gets re-written
const {RING_GAME, SCHEDULED_TOURNAMENT, SITNGO_TOURNAMENT} = GAME_FORMAT

export default function MobileGameSortSubMenu () {
  const gameFormat = useGameFormat()

  return <>
    <div className='heading'>Sort By</div>
    {gameFormat === RING_GAME && <MobileRingGameSortSubMenu />}
    {gameFormat === SITNGO_TOURNAMENT && <MobileSngSortSubMenu />}
    {gameFormat === SCHEDULED_TOURNAMENT && <MobileTournamentSortSubMenu />}
  </>
}

export default ({signal, app}) ->
  couponCode = signal.parent.arguments.coupon_code
  choices = [{value:'OK'}]
  if signal.success
    title = 'Success'
    icon = 'check'
    content = "The Bonus code (#{couponCode}) was claimed"
  else
    title = 'Error'
    icon = 'warning'
    content = "You are not eligible for this bonus (#{couponCode})"

  app.notice {title, content, icon, choices}

import React from 'react'

import TimeBankComponent from './components/TimeBank.js'
import {useShouldShowTimeBank} from './react-hooks.js'

export default function TimeBank () {
  const shouldShowTimeBank = useShouldShowTimeBank()

  if (!shouldShowTimeBank) return null

  return <TimeBankComponent />
}

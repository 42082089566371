export default ({signal, details, app, game, client, listing}) ->
  return unless game

  player = game.getPlayerByPosition(details.position)
  game.currentPlayer = details.position

  game.players.each (p) -> p.isTurn = false

  # makes sure your time is set when
  # you perform an action and immediately it is your turn again
  # ie. big blind
  app.once 'signalFinished', ->
    return unless player
    player.isTurn = true
    player.timer = details.timer

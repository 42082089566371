import classnames from 'classnames'
import React from 'react'

import styles from './Indicators.module.css'

export default function Indicator (props) {
  const {className, icon, title} = props

  return <span className={classnames(styles.indicator, className, 'fa', `fa-${icon}`)} title={title} />
}

export default ({signal, app, game, client, listing}) ->
  if signal.success
    message = "Your money has been topped up by <strong>$#{signal.updates.getPlayMoney.amount}</strong> and is now <strong>$#{signal.updates.getPlayMoney.balance}</strong>"
  else
    # Map failReason code to plain english message
    failReasons =
      'tooManyTopUps': 'Too many top ups. Please try again later.'
      'balanceAboveThreshold': 'You already have the maximum balance for play money.'
    message = failReasons[signal.failReason] or "Play money topup failed. Reason given: #{signal.failReason}"

  # whether or not the topup was successful, send a notice
  app.emit 'notice',
    title: 'Play Money Topup'
    content: message

  # get latest actual balance
  app.message 'getBalance', realMoney: false

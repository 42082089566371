import React, {useCallback, useEffect, useState} from 'react'

import LabelRow from './LabelRow.js'
import styles from './Settings.module.css'
import {useDefaultBuyInBigBlindMultiplier, useSetDefaultBuyInBigBlindMultiplier} from '../react-hooks.js'

export default function DefaultBuyIn () {
  const initialValue = useDefaultBuyInBigBlindMultiplier()
  const setDefaultBuyInBigBlindMultiplier = useSetDefaultBuyInBigBlindMultiplier()
  const [state, setState] = useState(initialValue)

  useEffect(() => {
    setState(initialValue)
  }, [initialValue, setState])

  const handleBlur = useCallback(event => {
    const value = parseInt(event.target.value, 10)
    if (isNaN(value)) return

    if (value !== initialValue) setDefaultBuyInBigBlindMultiplier(value)
  }, [initialValue, setDefaultBuyInBigBlindMultiplier])

  const handleChange = useCallback(event => {
    setState(event.target.value)
  }, [setState])

  return <LabelRow name='Default Buy-In'>
    <input
      className={styles.numberInput}
      type='number'
      min='0'
      onBlur={handleBlur}
      onChange={handleChange}
      value={state}
    /> Big Blinds
  </LabelRow>
}

export default ({signal, details, app, game, player, client, closable}) ->
  closable ?= false

  # 0: "Alert"
  # 1: "A Bad Beat Jackpot was just awarded at table Gillian. Congratulations to player bob who just won $5!!"
  # 2: "showOnGameType"
  # 3: "RING_GAMES"
  # 4: "GENERIC"
  [, message, action, actionData] = details.eventText

  # todo: sometimes it is translucent, apparently
  # todo: what to do with playerNotificationType?

  displayIfWaiting = (c) ->
    if c?.game.currentState?.current in ['waitingForPlayers']
      c.displayNotification()

  switch action
    when 'showOnGame'
      client ?= app.clients.get(0)
      client?.addNotification {message, closable}
      displayIfWaiting client
    when 'showOnGameType'
      # note: we only support one client, but coding as if multitable
      # also, signal.message does not have instanceId so no client
      switch actionData
        when 'TOURNAMENTS'
          filterFn = ({listType}) -> listType in [14, 15, 16, 21, 17, 18, 28, 29]
        when 'MTTS'
          filterFn = ({listType}) -> listType in [14, 15, 16, 21]
        when 'SIT_N_GOS'
          filterFn = ({listType}) -> listType in [17, 18, 28, 29]
        when 'RING_GAMES'
          filterFn = ({listType}) -> listType in [1, 3]
        else
          filterFn = -> true
      app.clients
        .toArray()
        .filter filterFn
        .forEach (appClient) ->
          appClient.addNotification {message, closable}
          displayIfWaiting appClient
    else
      client ?= app.clients.get(0)
      client?.addNotification {message, closable}
      displayIfWaiting client

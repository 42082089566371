import React from 'react'

import {GAME_FORMAT} from '../constants.js'
import {useGameFormat} from '../react-hooks.js'
import DesktopRingGameListing from '../ring-game/components/DesktopListing.js'
import DesktopScheduledTournamentListing from '../scheduled-tournament/components/DesktopListing.js'
import DesktopSitngoTournamentListing from '../sitngo-tournament/components/DesktopListing.js'

const {RING_GAME, SCHEDULED_TOURNAMENT, SITNGO_TOURNAMENT} = GAME_FORMAT

export default function DesktopListing () {
  const gameFormat = useGameFormat()

  return <>
    {gameFormat === RING_GAME && <DesktopRingGameListing />}
    {gameFormat === SITNGO_TOURNAMENT && <DesktopSitngoTournamentListing />}
    {gameFormat === SCHEDULED_TOURNAMENT && <DesktopScheduledTournamentListing />}
  </>
}

export default ({signal, app, game, client, listing}) ->
  client?.waitlistPosition = {}
  if signal.success
    client?.waiting = false
    listing?.waiting = false
    # app.notice
    #   title: 'Removed from Waitlist'
    #   content: 'You have been successfully removed from the waitlist for ' + gameName
  else
    switch signal.failReason
      when 'clientNotWaitlisted'
        listing?.waiting = false
        client?.waiting = false

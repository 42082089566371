# TournamentPositionView
# Model: app

import PaymentOptionsSelect from './client/paymentOptionsSelect.coffee'
import View from './view.coffee'

export default class TournamentPositionView extends View

  constructor: (app, reRegisterListing) ->
    super()

    @model = app
    @listing = reRegisterListing
　
    @dom = $ '<div>'

    # ### Components - eg. DOM elements & views
    @components =
      icon:     $ "<div class='winner-icon'>"
      content:  $ "<div class='tournament-position-notice-content'>"
      message:  $ "<p class='message'>"
      winnings: $ "<p class='winnings'>"
      select:   new PaymentOptionsSelect @listing?.entryPayments or []
      link:     $ "<p class='link'><span>Play another #{@listing?.gameName}</span></p>"
      checkbox: $ '<input type="checkbox" id="tournamentPositionCheckbox" class="css-checkbox"/><label for="tournamentPositionCheckbox" class="css-checkbox-label">Don&rsquo;t show me this message again</label>'

    # ### Events - eg. MouseClicks ###
    @components.link.find('span').on 'click', =>
      @submitAction()
      @currentModal?.teardown()

    # ### Listeners - eg. auth:success

    # ### Changes - eg. Model.property = true

    if @listing?.entryPayments.length
      @components.select.render()

    # ### Binding ###
    @initializeView()
    return this

  render: (positionFinished, winnings) =>
    @appendComponents()
    @positionFinished = positionFinished

    # annoying shuffle for icon/content alignment
    @components.message.appendTo @components.content
    @components.winnings.appendTo @components.content
    @components.select.dom.appendTo @components.content
    @components.link.appendTo @components.content
    @components.checkbox.appendTo @components.content

    if winnings?.cash or winnings?.coupon
      @components.message.text "Congratulations, you have finished in #{positionFinished} place."
    else
      @components.message.text "You have finished in #{positionFinished} place."

    if @listing?
      @components.link.show()
    else
      @components.link.hide()

    if @listing?.entryPayments.length > 1
      @components.select.show()
    else
      @components.select.hide()

    @components.winnings.empty()
    if winnings
      if winnings.cash
        @components.winnings.append "<div>Your real money balance has been credited with #{winnings.cash.toMoney()}.</div>"
      if winnings.coupon
        coupons = $ "<ul class='winnings-coupon-list'>"
        for tokens in winnings.coupon.split('@')
          [description, value] = tokens.split ';'
          if value
            coupons.append "<li>#{value.toMoney()} (#{description})</li>"
          else
            coupons.append "<li>#{description}</li>"
        @components.winnings.append "<div>You have been awarded these coupons:</div>"
        @components.winnings.append coupons

    switch positionFinished
      when '1st'
        icon = 'gold'
      when '2nd'
        icon = 'silver'
      when '3rd'
        icon = 'bronze'
      else
        icon = 'flag'
    # 2nd/3rd only get a trophy if they actually won something
    unless winnings?.cash or winnings?.coupon
      icon = 'flag'

    @icon = icon # store for modal title

    # remove any existing classes
    classes = ['gold', 'silver', 'bronze', 'flag'].map((i) -> 'winner-icon-' + i).join(' ')
    @components.icon.removeClass classes
    @components.icon.addClass "winner-icon-#{icon}"

    return @dom

  submitAction: =>
    paymentId = @components.select?.dom?.val() or @listing.entryPayments[0].id
    @listing.register {paymentId}

  show: =>
    choices = [{value:'OK', icon: 'check'}]
    if @listing
      # button on the left so unshift instead of push
      choices.unshift
        value: 'Play Another...'
        icon: 'ticket'
        action: @submitAction

    title = if @positionFinished is '1st' then 'Winner' else 'Result'
    @currentModal = @modal
      title: 'Tournament ' + title
      icon: if @icon is 'flag' then 'flag-checkered' else 'trophy'
      content: @dom
      className: 'tournament-position-notice'
      choices: choices
      teardown: =>
        @model.settings.showTournamentPositionNotice = not @components.checkbox.prop 'checked'

  teardown: =>
    @currentModal?.teardown()

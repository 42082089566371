# checkBoxComponent

export default class CheckBoxComponent

  constructor: (labelText, classes) ->
    @id = Math.floor(Math.random() * (5000000 - 10000 + 1) + 10000);

    @checkBox = $ "<input id='checkbox#{@id}' class='css-checkbox' type='checkbox'/>"
    @label = $ "<label for='checkbox#{@id}' class='css-checkbox-label'>#{labelText}</label>"

    if classes
      @checkBox.addClass classes
      @label.addClass classes

  render: (asText) =>
    @dom = @checkbox.add @label
    return @dom

  renderAsText: =>
    return $('<div>').append([@checkBox, @label]).html()

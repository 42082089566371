import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# Data Types
import PokerStatusData from './dataTypes/pokerStatusData.coffee'
import PokerEventData from './dataTypes/pokerEventData.coffee'

export default class UnreserveTranslator extends Mixmaster
  mixins = [
    DefaultMixin
    PokerStatusData
    PokerEventData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal
    signal.unreserve = {}

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  # reason: (signal, name, values) ->
  #   signal.failReason = values
  #   if values is 'alreadyUnreserved'
  #     signal.success = true

  # event: (signal, name, values) ->
  #   if values.code1 is 'Unreserve'
  #     signal.Unreserve.playerPosition = values.playerId

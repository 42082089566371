import {useContext, useEffect, useState} from 'react'

import {context} from '../react.js'

export function useInstall () {
  return useService().install
}

export function useIsAvailable () {
  const {isAvailable, subscribe} = useService()
  const [reactValue, setReactValue] = useState(isAvailable)

  useEffect(() => subscribe(setReactValue), [subscribe])

  return reactValue
}

function useService () {
  return useContext(context).installationService
}

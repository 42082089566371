import {useContext, useEffect, useState} from 'react'

import {RING_GAME_FILTER, SNG_FILTER} from './service.js'
import {context} from '../react.js'
import {useGameFilterSetting} from '../settings/react-hooks.js'

const {NO_LIMIT_TEXAS_HOLDEM} = RING_GAME_FILTER
const {NO_LIMIT_TEXAS_HOLDEM_6_MAX_TURBO} = SNG_FILTER

export function useGameFormat () {
  const {gameFormat, setGameFormat, subscribeToGameFormat} = useService()
  const [reactValue, setReactValue] = useState(gameFormat)

  useEffect(() => subscribeToGameFormat(setReactValue), [subscribeToGameFormat])

  return [reactValue, setGameFormat]
}

export function useJoinRingGame () {
  return useService().joinRingGame
}

export function useJoinSng () {
  return useService().joinSng
}

export function useRingGameFilter () {
  let [filter, set] = useGameFilterSetting('quickSeatRingGameFilter')
  if (!RING_GAME_FILTER[filter]) filter = NO_LIMIT_TEXAS_HOLDEM

  return [filter, set]
}

export function useSngFilter () {
  let [filter, set] = useGameFilterSetting('quickSeatSngFilter')
  if (!SNG_FILTER[filter]) filter = NO_LIMIT_TEXAS_HOLDEM_6_MAX_TURBO

  return [filter, set]
}

function useService () {
  return useContext(context).quickSeatService
}

import classnames from 'classnames'
import React from 'react'

import HelpIcon from './HelpIcon.js'
import styles from './Settings.module.css'

export default function LabelRow (props) {
  const {children, className, name, help} = props

  return <label className={classnames(className, styles.row)}>
    <span className={styles.label}>{name}</span>
    <span className={styles.input}>
      {children}
    </span>
    {help && <HelpIcon message={help} title={name} />}
  </label>
}

import React from 'react'

import StakesDescription from './components/StakesDescription.js'
import Signal from '../../../signal/Signal.js'
import {useTableId} from '../../context.js'
import styles from '../GameDetails.module.css'
import JackpotAmount from '../components/JackpotAmount.js'
import Row from '../components/Row.js'
import {useHandNumber, useJackpotAmount, useStakes, useTableName} from '../react-hooks.js'

export default function Table () {
  const handNumber = useHandNumber()
  const jackpotAmount = useJackpotAmount()
  const stakes = useStakes()
  const tableName = useTableName()
  const tableId = useTableId()

  return <>
    <Signal />
    <Row className={styles.spacer}>{tableName}</Row>
    <Row>{tableId}-{handNumber}</Row>
    {stakes && <StakesDescription stakes={stakes} />}
    {jackpotAmount && <JackpotAmount amount={jackpotAmount} />}
  </>
}

# PopupArcadeView
# Model: app

import InstanceView from './instanceView.coffee'
import View from './view.coffee'

export default class PopupArcadeView extends View

  constructor: (@navigator, client, @createReactView, @isDesktop, @windowTitleManager, popupController) ->
    super()

    @model = client

    @dom = $ "<div id='arcade' class='popout'>"

    # ### Components - eg. DOM elements & views
    @components =
      rotateNotice:         Webclient.ViewManager.returnFormatElement
        desktop: "<div id='rotateNotice' class='blanketNotice'><span class='blanketNotice-text'>Please increase your browser width to play <span class='blanketNotice-textLink'>or go back to Lobby</span></span></div>"
        tablet:  "<div id='rotateNotice' class='blanketNotice'><span class='blanketNotice-text'>Please rotate your screen to landscape position to play <span class='blanketNotice-textLink'>or go back to Lobby</span></span></div>"
        mobile:  "<div id='rotateNotice' class='blanketNotice'><span class='blanketNotice-text'>Please rotate your screen to landscape position to play <span class='blanketNotice-textLink'>or go back to Lobby</span></span></div>"
      # previewBar: new PreviewBarView @model.clients

    # ### Events - eg. MouseClicks ###
    # @dom.on 'click', '.game-details, .toggle-preview-btn', @togglePreviewBar
    # @components.previewBar.dom.on 'click', @togglePreviewBar
    @components.rotateNotice.find('.blanketNotice-textLink').on 'click', @navigator.navLobby

    # # # ### Listeners - eg. auth:success
    # @listeners =
    #   'spectateGame': @spectateGame{}

    # # # ### Changes - eg. Collection.property = true
    @model.settings.on 'change:fourColorDeck', @updateFourColorDeck
    @updateFourColorDeck()

    # ### Binding ###
    @initializeView()
    @spectateGame popupController
    return this

  spectateGame: (popupController) =>
    instanceView = new InstanceView @navigator, @model, @createReactView, @isDesktop, @windowTitleManager, true, popupController
    @dom.append instanceView.render()
    $('body').append @dom

    # if inBackground
      #   instanceView.hide()
      # else
      #   Navigator.navTable game
      #   @model.clients.each (c) -> c.current = false
      #   client.current = true

  updateFourColorDeck: =>
    @dom.toggleClass 'fourColorDeck', @model.settings.fourColorDeck

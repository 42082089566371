import React from 'react'

import AutoRebuyAmount from './AutoRebuyAmount.js'
import AutoRebuyPoint from './AutoRebuyPoint.js'
import {useIsAutoRebuy} from '../react-hooks.js'

export default function AutoRebuy () {
  const isAutoRebuy = useIsAutoRebuy()

  return <>
    <AutoRebuyPoint />
    {isAutoRebuy && <AutoRebuyAmount />}
  </>
}

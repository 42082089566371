import Mixmaster from '../../../mixmaster.coffee'
import WclEnum from '../../../../enum/index.coffee'

export default class JackpotWinData extends Mixmaster
  mixins = [
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  mixin: (object) ->
    mixins.map((mixin) => (new mixin).mixin(object))

    object.jackpotName = (signal, name, values) ->
      signal.updates.jackpotName = values

    object.winner = (signal, name, values) ->
      signal.updates.jackpotWinner ?= []
      signal.updates.jackpotWinner.push {
        amount: parseFloat(values.amount)
        userId: parseInt(values.userId,10)
        cards: WclEnum.getCards(values.cards)
        gameResultId: parseInt(values.gameResultId,10)
        nickName: values.nickName
        handDescription: WclEnum.getHandDescription(values.handDescription)
      }

      if values.value7
        signal.updates.jackpotCommunityCards = WclEnum.getCards values.value7

import Request from './request.coffee'

# WARNING!
# Note the function GET_COUPONS is the same as GetCouponsMessage
# But the service is different (CREDIT in this case)

export default class ActiveBonusesMessage extends Request
  constructor: ({message}) ->
    super arguments...

    @function = 'GET_COUPONS'

    @attributes =
      type: 'REQUEST'
      service: 'CREDIT'
      routerId: message.routerId
      serverId: 0
      instanceId: 0
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

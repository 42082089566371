import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# Data Types
import JackpotData from './dataTypes/jackpotData.coffee'
import JackpotWinData from './dataTypes/jackpotWinData.coffee'

export default class JackpotTranslator extends Mixmaster
  mixins = [
    DefaultMixin
    JackpotData
    JackpotWinData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

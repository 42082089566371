export default ({signal, details, app, game, client, listing}) ->
  # find player
  position = signal.updates.currentPlayer?.position or game.currentPlayer
  player = game.players.find {position}

  # update ui to show opponent is using timebank
  if player
    player.usingTimebank = details.seconds  # todo: this is not used
    player.label = 'TIMEBANK'

    if player.isClient
      # client player is using timebank
      client.timebank.active = true
      client.timebank.balance = details.seconds

      client.playSound 'timebank', client.instanceId

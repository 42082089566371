export default
  SHORT_HANDED: 1
  PASSWORD_PROTECTED: 2
  REBUYS: 4
  REBUYS_AND_ADDONS: 8
  TURBO: 16
  GUARANTEED_PRIZEPOOL: 32
  HEADS_UP: 64
  BOUNTY: 128
  SHOOTOUT: 256
  NO_DISCONNECT_PROTECT: 512
  PRIORITY_LISTING: 1024
  BAD_BEAT: 2048
  DEEP_STACK: 4096
  FREEROLL: 8192
  VIP: 16384  # deprecated
  ALL_IN_OR_FOLD: 32768
  DOUBLE_UP: 65536
  LATE_REGISTRATION: 131072
  SUPER_TURBO: 262144
  FUN_STEP: 524288
  FAST: 1048576
  SATELLITE: 2097152
  COUPON_ENTRY: 4194304

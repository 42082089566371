import Card from '../../../models/card.coffee'

export default ({signal, details, app, game, client, listing}) ->
  return unless game

  client.displayChatMessage message: 'Dealing high cards...'
  for highCard in details
    player = game.players.find {position: highCard.position}
    continue unless player
    player.cards = [new Card highCard.card[0]]

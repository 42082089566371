import React from 'react'

import {useIsSittingOut} from '../../seats/react-hooks.js'
import styles from '../Actions.module.css'
import BreakTime from '../break-time/BreakTime.js'
import EndHand from '../end-hand/EndHand.js'
import PlayHand from '../play-hand/PlayHand.js'

import {
  useIsAutoMuck,
  useIsAutoPostBlinds,
  useIsFinishingHand,
  useIsOnBreak,
  useShouldShowActions,
} from '../react-hooks.js'

import SittingOut from '../sitting-out/SittingOut.js'
import StartHand from '../start-hand/StartHand.js'

export default function Actions () {
  const isAutoMuck = useIsAutoMuck()
  const isAutoPostBlinds = useIsAutoPostBlinds()
  const isFinishingHand = useIsFinishingHand()
  const isOnBreak = useIsOnBreak()
  const isSittingOut = useIsSittingOut()
  const shouldShowActions = useShouldShowActions()

  if (isOnBreak) {
    return <div className={styles.actions}>
      <BreakTime />
    </div>
  }

  return <div className={styles.actions}>
    {isSittingOut && <SittingOut />}
    {!isSittingOut && shouldShowActions && <>
      {!isAutoPostBlinds && <StartHand />}
      {!isFinishingHand && <PlayHand />}
      {isFinishingHand && !isAutoMuck && <EndHand />}
    </>}
  </div>
}

# ## Translator

# Default Translator reads incoming signals from the [MessageParser](../messageParser.html)
# and renames the properties into more meaningful and consistent names.

# These signals are then in turn passed as attributes to updates/functions by
# the [Scraper](../scraper.html)

import dayjs from 'dayjs'

import Mixmaster from '../../../mixmaster.coffee'
import {serverTimestampToIso} from '../../../../../time/index.js'

export default class DefaultMixin extends Mixmaster

  @init: (signal) ->
    signal.untranslated = {}
    signal.updates = {}
    signal.games = []

  constructor: () ->
    super()
    Mixmaster.construct this, []

  mixin: (object) ->
    object.function = (signal, name, node) ->
      signal.function = name

    object.argument = (signal, name, values) ->
      # cast boolean values
      if values in ['true', 'false']
        values = values is 'true'

      # translate argument
      if this[name]?
        this[name] signal, name, values
      else
        console.warn 'no translation', signal.function, name, values

      signal.untranslated[name] = values

    object.result = (signal, name, values) ->
      signal.success = values is 'success'

    object.reason = (signal, name, values) ->
      unless typeof values is 'object'
        signal.failReason = values
      else
        keys = Object.keys(values)
        signal.failReason = keys[0]
        signal.failDetails = values[keys[0]]

    object.serverTime = (signal, name, values) ->
      # serverTime timezone is GMT -5:00
      signal.updates.serverTime = @parseDate values

    object.parseDate = (str) ->
      if str
        dayjs(serverTimestampToIso(str)).toDate()
      else
        null

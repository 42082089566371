export default ({signal, details, app, game, player, client}) ->
  [amount, realMoney] = details.eventText

  amount = parseFloat(amount)
  realMoney = realMoney

  if realMoney
    app.user.realMoney = amount
  else
    app.user.playMoney = amount

import classnames from 'classnames'
import React from 'react'

import styles from './LabeledRadio.module.css'
import Radio from './Radio.js'

export default function LabeledRadio (props) {
  const {children, className, isDisabled, size, value} = props

  const radioClassName = classnames(className, styles.labeledRadio, styles[size], {
    [styles.disabled]: isDisabled,
  })

  return <label className={radioClassName}>
    <Radio isDisabled={isDisabled} value={value} />
    <span className={styles.text}>{children}</span>
  </label>
}

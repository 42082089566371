import React from 'react'

import LabelRow from './LabelRow.js'
import {useConfigurePopouts} from '../react-hooks.js'

export default function TablePopoutOptions () {
  return <LabelRow name='Table Popout Options'>
    <button type='button' className='button' onClick={useConfigurePopouts()}>Configure</button>
  </LabelRow>
}

import {CREATE_EXCLUSIONS, LOAD_EXCLUSIONS} from '@pipehat/chronicle/constants/command-type'
import {EXCLUSIONS_CREATED, EXCLUSIONS_LOADED} from '@pipehat/chronicle/constants/event-type'
import {failureType} from '@pipehat/chronicle/translation'
import {createReducer} from '@reduxjs/toolkit'

import {getRoot} from './ns.js'

export {getRoot} from './ns.js'

const CREATE_EXCLUSIONS_FAILED = failureType(CREATE_EXCLUSIONS)
const LOAD_EXCLUSIONS_FAILED = failureType(LOAD_EXCLUSIONS)

// reducer =====================================================================

const LOAD_STATUS_INIT = ''
const LOAD_STATUS_LOADED = 'LOADED'
const LOAD_STATUS_ERROR = 'ERROR'

const UPDATE_STATUS_INIT = ''
const UPDATE_STATUS_UPDATING = 'UPDATING'
const UPDATE_STATUS_SUCCESS = 'SUCCESS'
const UPDATE_STATUS_ERROR = 'ERROR'

const init = {
  loadStatus: LOAD_STATUS_INIT,
  updateStatus: UPDATE_STATUS_INIT,
  exclusions: {
    USD: undefined,
  },
}

export const reducer = createReducer(init, {
  [LOAD_EXCLUSIONS]: state => { state.loadStatus = LOAD_STATUS_INIT },
  [EXCLUSIONS_LOADED]: (state, {payload}) => {
    state.loadStatus = LOAD_STATUS_LOADED
    state.exclusions.USD = payload.exclusions.USD
  },
  [LOAD_EXCLUSIONS_FAILED]: state => { state.loadStatus = LOAD_STATUS_ERROR },

  [CREATE_EXCLUSIONS]: state => { state.updateStatus = UPDATE_STATUS_UPDATING },
  [EXCLUSIONS_CREATED]: state => { state.updateStatus = UPDATE_STATUS_SUCCESS },
  [CREATE_EXCLUSIONS_FAILED]: state => { state.updateStatus = UPDATE_STATUS_ERROR },
})

// selectors ===================================================================

export function isLoading (state) {
  return state.loadStatus === LOAD_STATUS_INIT
}

export function isLoaded (state) {
  return state.loadStatus === LOAD_STATUS_LOADED
}

export function isLoadError (state) {
  return state.loadStatus === LOAD_STATUS_ERROR
}

export function shouldShowUpdate (state) {
  return !hasExclusions(state)
}

export function canUpdate (state) {
  return shouldShowUpdate(state) && isLoaded(state) && !isUpdating(state)
}

export function isUpdating (state) {
  return state.updateStatus === UPDATE_STATUS_UPDATING
}

export function isUpdateSuccess (state) {
  return state.updateStatus === UPDATE_STATUS_SUCCESS
}

export function getExclusions (state) {
  return state.exclusions
}

export function hasExclusions (state) {
  return Object.values(state.exclusions).some(expiryTime => !!expiryTime)
}

// subscriptions ===============================================================

const reloadExclusionsOnUpdate = {
  mapStateToProps: state => {
    return {
      isUpdateSuccess: isUpdateSuccess(getRoot(state)),
    }
  },
  onChange: (dispatch, {isUpdateSuccess}, {responsibleGamingService}) => {
    if (isUpdateSuccess) responsibleGamingService.loadExclusions()
  },
}

export const subscriptions = [
  reloadExclusionsOnUpdate,
]

import Request from './request.coffee'

export default class SitOutNextBlindRequest extends Request
  constructor: ({client, message}) ->
    super arguments...

    @function = 'SIT_OUT'

    @attributes =
      type: 'REQUEST'
      service: 'GAME'
      routerId: message.routerId
      serverId: client.serverId
      instanceId: client.instanceId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @rawArguments = [
      {
        name: 'SIT_OUT_TYPE'
        'code1': 'NEXT_BLIND'
      }
    ]

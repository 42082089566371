# AllInShowdownPlayerData
#   int seat
#   String[] cards
import Mixmaster from '../../../mixmaster.coffee'
import WclEnum from '../../../../enum/index.coffee'

export default class AllInShowdownPlayerData extends Mixmaster
  mixin: (object) ->
    object.allInShowdown = (signal, name, values) ->
      signal.updates.allInShowdown = []
      splitCards = values.cards.split('|')
      for id, index in values.playerIdList.split('|')
        signal.updates.allInShowdown.push
          cards: WclEnum.getCards(splitCards[index])
          id: parseInt(id,10)

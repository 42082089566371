import * as actionTypes from '@pipehat/chronicle/constants/action'
import {BET_CALLED, BET_MADE, CHECKED, RAISED} from '@pipehat/chronicle/constants/event-type'
import {FIXED_LIMIT} from '@pipehat/chronicle/constants/limit-type'
import {END_OF_FOLDED_GAME, SHOWDOWN} from '@pipehat/chronicle/constants/table-status'
import {isGreaterThanOrEqual, sum} from '@pipehat/money/plain-object'
import {useEffect, useState} from 'react'

import {useActions} from '../context.js'
import {useOption, useService} from '../react-hooks.js'
import {useIsSeated, useStack} from '../seats/react-hooks.js'

export const useActivePotAmount = () => useSelector(({hand}) => hand.activePotAmount)
export const useAvailableActions = () => useSelector(({hand}) => hand.ownActions.actions)
export const useBet = () => useService().bet
export const useCallBet = () => useService().callBet
export const useCanForgoBreak = () => useSelector(({tournament}) => tournament?.canForgoBreak)
export const useCheck = () => useService().check
export const useCurrency = () => useSelector(({currency}) => currency)
export const useDeclareAllIn = () => useService().declareAllIn
export const useFold = () => useService().fold
export const useFoldToAnyBet = () => useService().foldToAnyBet
export const useForgoBreak = () => useService().forgoBreak
export const useIsActiveSitOut = () => useSelector(({hand}) => hand.isActiveSitOut)
export const useIsAutoMuck = () => useOption('isAutoMuck')
export const useIsAutoPostBlinds = () => useOption('isAutoPostBlinds')
export const useIsFinishingHand = () => useSelector(({status}) => finishingHandStatus.includes(status))
export const useIsFixedLimit = () => useSelector(({stakes}) => stakes?.limitType === FIXED_LIMIT)
export const useIsOnBreak = () => useSelector(({tournament}) => tournament?.isOnBreak)
export const useIsTournament = () => useSelector(({tournament}) => !!tournament)
export const useIsTurn = () => useSelector(({activeSeats, ownSeat}) => activeSeats.includes(ownSeat))
export const useLeaveGame = () => useService().leaveGame
export const useMuckCards = () => useService().muckCards
export const usePostBigBlind = () => useService().postBigBlind
export const usePostInitialBlind = () => useService().postInitialBlind
export const usePostReturnBlind = () => useService().postReturnBlind
export const usePostSmallBlind = () => useService().postSmallBlind
export const usePreviousActionResult = () => useSelector(({hand}) => hand.ownPreviousActionResult)
export const useRaise = () => useService().raise
export const useRemovePreaction = () => useService().removePreaction
export const useSelfActions = () => useSelector(({hand}) => hand.ownActions)
export const useSelfCurrentBet = () => useSelector(({hand}) => hand.ownActions?.currentBet)
export const useShowAddChips = () => useService().showAddChips
export const useShowCards = () => useService().showCards
export const useSitIn = () => useService().sitIn
export const useSitInOnRebuy = () => useService().sitInOnRebuy
export const useSitOut = () => useService().sitOut
export const useStartRabbitHunt = () => useService().startRabbitHunt
export const useStatus = () => useSelector(({status}) => status)
export const useWaitForInitialBlind = () => useService().waitForInitialBlind
export const useWaitForReturnBlind = () => useService().waitForReturnBlind

export const useBetRaiseAmount = () => {
  const {actions, currentBet, minimumBet, minimumRaise} = useSelfActions()
  const isFixedLimit = useIsFixedLimit()
  const ownBetRaiseAmount = useSelector(({hand}) => hand.ownBetRaiseAmount)

  // fixed limit bypasses the amount selection step
  if (isFixedLimit) {
    if (actions.includes(actionTypes.BET)) return minimumBet

    // need to add current bet to raise amount
    if (actions.includes(actionTypes.RAISE)) return sum([currentBet, minimumRaise])

    return undefined
  }

  return ownBetRaiseAmount
}

export const useCanSitIn = () => {
  const [isActiveSitOut, isTournament, minimumBet] = useSelector(
    ({hand, stakes, tournament}) => [hand.isActiveSitOut, !!tournament, stakes?.minimumBet],
  )
  const stack = useStack()

  if (isTournament || isActiveSitOut) return true
  if (!stack || !minimumBet) return false

  // stack >= minimumBet
  return isGreaterThanOrEqual(minimumBet)(stack)
}

export const useIntendedActionName = () => {
  const {intendedActionName, subscribeToIntendedAction} = useService()
  const [reactValue, setReactValue] = useState(intendedActionName())

  useEffect(() => {
    return subscribeToIntendedAction(actionName => {
      setReactValue(actionName)
    })
  }, [subscribeToIntendedAction])

  return reactValue
}

export const useIsBetRaiseVisible = () => {
  const isFixedLimit = useIsFixedLimit()
  const ownBetRaiseIsVisible = useSelector(({hand}) => hand.ownBetRaiseIsVisible)

  if (isFixedLimit) return false

  return ownBetRaiseIsVisible
}

export const useIsReadyToContinue = () => {
  const {isReadyToContinue, subscribeToIsReadyToContinue} = useService()
  const [reactValue, setReactValue] = useState(isReadyToContinue())

  useEffect(() => {
    return subscribeToIsReadyToContinue(isReady => {
      setReactValue(isReady)
    })
  }, [subscribeToIsReadyToContinue])

  return reactValue
}

export const useShouldShowActions = () => {
  const actions = useAvailableActions()
  const isActiveSitOut = useIsActiveSitOut()
  const previousAction = usePreviousActionResult()

  if (isActiveSitOut) return false

  return actions.length > 0 || foldToAnyActionEvents.includes(previousAction)
}

export const useShouldShowBetRaise = () => {
  const isFixedLimit = useIsFixedLimit()
  const actions = useAvailableActions()
  const isSeated = useIsSeated()

  if (isFixedLimit || !isSeated || !actions.length) {
    return false
  }

  return actions.includes(actionTypes.BET) || actions.includes(actionTypes.RAISE)
}

export const useShouldShowFoldToAny = () => {
  const isSeated = useIsSeated()
  const isTurn = useIsTurn()
  const previousActionResult = usePreviousActionResult()

  if (!isSeated || isTurn || !previousActionResult) return false

  return foldToAnyActionEvents.includes(previousActionResult)
}

function useSelector (selector) {
  return selector(useActions())
}

const finishingHandStatus = [END_OF_FOLDED_GAME, SHOWDOWN]
const foldToAnyActionEvents = [BET_CALLED, BET_MADE, CHECKED, RAISED]

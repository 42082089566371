import React from 'react'

import AddChips from './components/AddChips.js'
import Leave from './components/Leave.js'
import Options from './components/Options.js'
import TopUp from './components/TopUp.js'
import MultiTable from './multi-table/MultiTable.js'
import {useIsTournament} from './react-hooks.js'
import {useIsSeated} from '../seats/react-hooks.js'

export default function Controls (props) {
  const {isPopOut} = props
  const isSeated = useIsSeated()
  const isTournament = useIsTournament()

  return <span className='table-controls'>
    {isSeated && <Options />}
    {!isSeated && <Leave />}
    <MultiTable isPopOut={isPopOut} />
    {isSeated && isTournament && <AddChips />}
    {isSeated && !isTournament && <TopUp />}
  </span>
}

import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# Data Types
import PokerEventData from './dataTypes/pokerEventData.coffee'

export default class NotifyTranslator extends Mixmaster
  mixins = [
    DefaultMixin
    PokerEventData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

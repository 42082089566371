import Listing from '../../../models/listing.coffee'
import {isTypeIdSupported} from '../../../../game-type.js'

export default ({signal, app}) ->

  tournament = app.listings.find {instanceId: signal.message.instanceId}

  app.getRealMoneyBalance()
  app.getMyGames()

  if signal.failReason or signal.success is false

    if signal.failReason is 'code1'
      reason = signal.failDetails
    else
      reason = signal.failReason

    registered = false
    switch reason
      when 'noActiveCoupon'
        message = """
          You do not have the required coupon to register for this tournament.
          Please choose another buyin option.
        """
      when 'alreadyRegistered', 'userAlreadyRegistered'
        registered = true
        message = "You are already registered for this tournament."
      when 'notEligible'
        message = "You are not eligible to register for this tournament."
      when 'notEligibleForCoupon'
        if hasAlternativePaymentOption(tournament?.entryPayments)
          message = "You are not eligible to use the chosen buyin option to register for this tournament. Please choose another buyin option."
        else
          message = "You are not eligible to register for this tournament."
      when 'insufficientFunds'
        message = "You have insufficient funds to register for this tournament."
      when 'gameNotExist'
        message = "Tournament does not exist."
      when 'registrationClosed'
        message = "Registration for this tournament has closed."
      when 'ipAddressOnTable'
        message = 'Another user on your network is playing in this tournament. In the interest of fairness you are not able to take a seat.'
      when 'passwordIncorrect'
        message = "Incorrect Password"
      when 'emailVerificationRequired'
        message = 'Your account requires email verification. Please check your sign up email addresss and follow the instructions in the activation email.'
      when 'gameTypeExclusion'
        message = 'You cannot join because of existing game exclusions.'
      else
        # console.log 'registration failure', signal.failDetails
        message = 'Failed to register.'
    tournament?.failedRegistration reason
    tournament?.registered = registered
    unless signal.parent.suppressErrorMessage
      app.tournamentRegistration signal.success, message, tournament
  else if signal.tournamentRegistration?.tournamentRematch?
    # console.warn 'tourney does not exist so... rematch? ... what now?'
    # no translation register tournamentRematch Object {finalTableInstanceId: "8091663"}
    app.tournamentRegistration true, 'Your rematch will commence shortly'
  else
    doRegistration = (tournamentToRegister) ->
      unless isTypeIdSupported(tournamentToRegister.gameType)
        app.listings.rem(tournamentToRegister)
        return
      tournamentMessage = """
        You have been registered for tournament\n
        #{tournamentToRegister.gameName}.\n
        You will appear in the players list shortly and be notified when your seat is available.
      """
      # tournament.pinned = true #
      tournamentToRegister.registration()
      app.tournamentRegistration signal.success, tournamentMessage, tournamentToRegister
    if tournament?
      doRegistration tournament
    else
      tournament = new Listing details:
        instanceId: signal.message.instanceId
        serverId: signal.message.serverId
        isTournament: true
      app.listings.add tournament
      tournament.getDetails -> doRegistration tournament

hasAlternativePaymentOption = (entryPayments = []) ->
  seenAlternateName = null
  for {buyIn, entryFee, bounty} in entryPayments
    couponType = buyIn.coupon?.couponType or entryFee.coupon?.couponType or bounty.coupon?.couponType
    if couponType is 'ES'  # eligibility requirement
      eligibilityName = buyIn.coupon?.name or entryFee.coupon?.name or bounty.coupon?.name
      if seenAlternateName is null
        seenAlternateName = eligibilityName
      else if seenAlternateName isnt eligibilityName
        return true
    else  # cash or other coupon type (actual name not important)
      if seenAlternateName is null
        seenAlternateName = '*'
      else
        return true
  return false

import addonResponse from './responses/addonResponse.coffee'
import addToWaitlistResponse from './responses/addToWaitlistResponse.coffee'
import claimCouponResponse from './responses/claimCouponResponse.coffee'
import deregisterResponse from './responses/deregisterResponse.coffee'
import getBalanceResponse from './responses/getBalanceResponse.coffee'
import getClientDataFeedResponse from './responses/getClientDataFeedResponse.coffee'
import getCouponsResponse from './responses/getCouponsResponse.coffee'
import getLevelStructureResponse from './responses/getLevelStructureResponse.coffee'
import getMyGamesResponse from './responses/getMyGamesResponse.coffee'
import getPlayMoneyResponse from './responses/getPlayMoneyResponse.coffee'
import getRingGamesResponse from './responses/getRingGamesResponse.coffee'
import getTokenResponse from './responses/getTokenResponse.coffee'
import getTournamentDetailsResponse from './responses/getTournamentDetailsResponse.coffee'
import getTournamentParentsResponse from './responses/getTournamentParentsResponse.coffee'
import getTournamentPlayersResponse from './responses/getTournamentPlayersResponse.coffee'
import getTournamentSatellitesResponse from './responses/getTournamentSatellitesResponse.coffee'
import getTournamentsResponse from './responses/getTournamentsResponse.coffee'
import imReadyResponse from './responses/imReadyResponse.coffee'
import joinGameResponse from './responses/joinGameResponse.coffee'
import leaveGameResponse from './responses/leaveGameResponse.coffee'
import loginUserResponse from './responses/loginUserResponse.coffee'
import rebuyResponse from './responses/rebuyResponse.coffee'
import registerResponse from './responses/registerResponse.coffee'
import removeFromWaitlistResponse from './responses/removeFromWaitlistResponse.coffee'
import reserveResponse from './responses/reserveResponse.coffee'
import sitInResponse from './responses/sitInResponse.coffee'
import sitOutResponse from './responses/sitOutResponse.coffee'
import standUpResponse from './responses/standUpResponse.coffee'
import updateNicknameResponse from './responses/updateNicknameResponse.coffee'
import updatePlayerResponse from './responses/updatePlayerResponse.coffee'

import expireSessionNotification from './notifications/expireSessionNotification.coffee'
import gameInstanceShutdownNotification from './notifications/gameInstanceShutdownNotification.coffee'
import gameStatusNotification from './notifications/gameStatusNotification.coffee'
import leaveGameNotification from './notifications/leaveGameNotification.coffee'
import notifyNotification from './notifications/notifyNotification.coffee'
import reserveNotification from './notifications/reserveNotification.coffee'
import seatReservedNotification from './notifications/seatReservedNotification.coffee'
import startGameNotification from './notifications/startGameNotification.coffee'
import stopBreakNotification from './notifications/stopBreakNotification.coffee'
import tableBreakdownNotification from './notifications/tableBreakdownNotification.coffee'
import tournamentStartNotification from './notifications/tournamentStartNotification.coffee'
import transferNotification from './notifications/transferNotification.coffee'

import getCouponsError from './errors/getCouponsError.coffee'
import processMessageError from './errors/processMessageError.coffee'

import connectionCheckRequest from './requests/connectionCheckRequest.coffee'

import accoladesUpdate from './updates/accolades.coffee'
import actionUpdate from './updates/action.coffee'
import addonAllowedUpdate from './updates/addonAllowed.coffee'
import allBlindsUpdate from './updates/allBlinds.coffee'
import allInShowdownUpdate from './updates/allInShowdown.coffee'
import allInWinPctUpdate from './updates/allInWinPct.coffee'
import chatAllowedUpdate from './updates/chatAllowed.coffee'
import chatUpdate from './updates/chat.coffee'
import communityCardsUpdate from './updates/communityCards.coffee'
import currentBetUpdate from './updates/currentBet.coffee'
import currentPlayerUpdate from './updates/currentPlayer.coffee'
import currentStateUpdate from './updates/currentState.coffee'
import dealerUpdate from './updates/dealer.coffee'
import dealtPlayersUpdate from './updates/dealtPlayers.coffee'
import eliminatedPlayerUpdate from './updates/eliminatedPlayer.coffee'
import eventUpdate from './updates/event.coffee'
import gameIdUpdate from './updates/gameId.coffee'
import gameTypeIdUpdate from './updates/gameTypeId.coffee'
import handDescriptionUpdate from './updates/handDescription.coffee'
import initialBuyInUpdate from './updates/initialBuyIn.coffee'
import jackpotBalanceUpdate from './updates/jackpotBalance.coffee'
import jackpotNameUpdate from './updates/jackpotName.coffee'
import levelUpdate from './updates/level.coffee'
import maxPlayersUpdate from './updates/maxPlayers.coffee'
import minChipValueUpdate from './updates/minChipValue.coffee'
import nextBreakUpdate from './updates/nextBreak.coffee'
import numberPlayersUpdate from './updates/numberPlayers.coffee'
import playerCardsUpdate from './updates/playerCards.coffee'
import playerHighCardUpdate from './updates/playerHighCard.coffee'
import playerOutOfGameUpdate from './updates/playerOutOfGame.coffee'
import playerTimerDisconnectedUpdate from './updates/playerTimerDisconnected.coffee'
import potsUpdate from './updates/pots.coffee'
import potTotalUpdate from './updates/potTotal.coffee'
import previousBalanceUpdate from './updates/previousBalance.coffee'
import rakeUpdate from './updates/rake.coffee'
import rebuyAllowedUpdate from './updates/rebuyAllowed.coffee'
import seatReservedFromWaitlistMonitorUpdate from './updates/seatReservedFromWaitlistMonitor.coffee'
import seatReservedUpdate from './updates/seatReserved.coffee'
import seatUpdate from './updates/seat.coffee'
import shootoutRoundNumUpdate from './updates/shootoutRoundNum.coffee'
import startBreakUpdate from './updates/startBreak.coffee'
import timebankUpdate from './updates/timebank.coffee'
import tournamentRematchDecisionUpdate from './updates/tournamentRematchDecision.coffee'
import tournamentRematchUpdate from './updates/tournamentRematch.coffee'
import tournamentStatsUpdate from './updates/tournamentStats.coffee'
import updateNicknameUpdate from './updates/updateNickname.coffee'
import usingTimebankUpdate from './updates/usingTimebank.coffee'
import waitingForBreakUpdate from './updates/waitingForBreak.coffee'
import waitListPositionUpdate from './updates/waitListPosition.coffee'
import winnersUpdate from './updates/winners.coffee'

export default class Receiver
  constructor: ->
    # Note the following actions are NOT confirmable: 'sitOut', 'sitIn'
    @confirmableActions = ['check', 'call', 'bet', 'allIn', 'fold', 'raise', 'bigBlind', 'smallBlind', 'returnBlind', 'initialBlind', 'rabbitHunt', 'showCards', 'muckCards', 'standUp', 'leaveGame']

    @handlers =
      'response:addon':                   addonResponse
      'response:addToWaitlist':           addToWaitlistResponse
      'response:claimCoupon':             claimCouponResponse
      'response:deregister':              deregisterResponse
      'response:getToken':                getTokenResponse
      'response:updateNickname':          updateNicknameResponse
      'response:getBalance':              getBalanceResponse
      'response:getClientDataFeed':       getClientDataFeedResponse
      'response:getCoupons':              getCouponsResponse
      'response:getLevelStructure':       getLevelStructureResponse
      'response:getMyGames':              getMyGamesResponse
      'response:getPlayMoney':            getPlayMoneyResponse
      'response:getRingGames':            getRingGamesResponse
      'response:getTournaments':          getTournamentsResponse
      'response:getTournamentDetails':    getTournamentDetailsResponse
      'response:getTournamentPlayers':    getTournamentPlayersResponse
      'response:getTournamentParents':    getTournamentParentsResponse
      'response:getTournamentSatellites': getTournamentSatellitesResponse
      'response:imReady':                 imReadyResponse
      'response:joinGame':                joinGameResponse
      'response:leaveGame':               leaveGameResponse
      'response:loginUser':               loginUserResponse
      'response:reserve':                 reserveResponse
      'response:rebuy':                   rebuyResponse
      'response:register':                registerResponse
      'response:removeFromWaitlist':      removeFromWaitlistResponse
      'response:sitIn':                   sitInResponse
      'response:sitOut':                  sitOutResponse
      'response:standUp':                 standUpResponse
      'response:updatePlayer':            updatePlayerResponse

      'notify:gameStatus':           gameStatusNotification
      'notify:startGame':            startGameNotification
      'notify:gameInstanceShutdown': gameInstanceShutdownNotification
      'notify:notify':               notifyNotification
      'notify:expireSession':        expireSessionNotification
      'notify:leaveGame':            leaveGameNotification
      'notify:reserve':              reserveNotification
      'notify:seatReserved':         seatReservedNotification
      'notify:tournamentStart':      tournamentStartNotification
      'notify:tableBreakdown':       tableBreakdownNotification
      'notify:stopBreak':            stopBreakNotification # todo prob change to update when addonAllowed implemented
      'notify:transfer':             transferNotification

      'error:processMessage':        processMessageError
      'error:getCoupons':            getCouponsError

      'request:connectionCheck':     connectionCheckRequest

    # For GAMES only. If it does not directly relate to a specific game, it should go into a Function
    # updates are based of argument names and usually just update
    # one or two properties on a game or player object
    @updates =
      communityCards:   communityCardsUpdate # process before event because of handHistory
      accolades:        accoladesUpdate
      action:           actionUpdate
      addonAllowed:     addonAllowedUpdate
      allBlinds:        allBlindsUpdate
      allInShowdown:    allInShowdownUpdate
      allInWinPct:      allInWinPctUpdate
      chat:             chatUpdate
      chatAllowed:      chatAllowedUpdate
      currentBet:       currentBetUpdate
      currentPlayer:    currentPlayerUpdate
      currentState:     currentStateUpdate
      dealer:           dealerUpdate
      dealtPlayers:     dealtPlayersUpdate
      eliminatedPlayer: eliminatedPlayerUpdate
      event:            eventUpdate
      gameId:           gameIdUpdate
      gameTypeId:       gameTypeIdUpdate
      handDescription:  handDescriptionUpdate
      initialBuyIn:     initialBuyInUpdate
      jackpotName:      jackpotNameUpdate
      jackpotBalance:   jackpotBalanceUpdate
      level:            levelUpdate
      maxPlayers:       maxPlayersUpdate
      minChipValue:     minChipValueUpdate
      nextBreak:        nextBreakUpdate
      numberPlayers:    numberPlayersUpdate
      playerCards:      playerCardsUpdate
      playerHighCard:   playerHighCardUpdate
      playerOutOfGame:  playerOutOfGameUpdate
      playerTimerDisconnected:  playerTimerDisconnectedUpdate
      pots:             potsUpdate
      potTotal:         potTotalUpdate
      previousBalance:  previousBalanceUpdate
      rake:             rakeUpdate
      rebuyAllowed:     rebuyAllowedUpdate
      seat:             seatUpdate
      seatReservedFromWaitlistMonitor: seatReservedFromWaitlistMonitorUpdate
      seatReserved:     seatReservedUpdate
      shootoutRoundNum: shootoutRoundNumUpdate
      startBreak:       startBreakUpdate
      timebank:         timebankUpdate
      tournamentRematch: tournamentRematchUpdate
      tournamentRematchDecision: tournamentRematchDecisionUpdate
      tournamentStats:  tournamentStatsUpdate
      updateNickname:   updateNicknameUpdate
      usingTimebank:    usingTimebankUpdate
      waitlistPosition: waitListPositionUpdate
      waitingForBreak:  waitingForBreakUpdate
      winners:          winnersUpdate

  init: (@app) ->

  receive: (fnName, signal) =>
    # game    = @app.tables.find {instanceId: signal.message.instanceId}
    client  = @app.clients.find {instanceId: signal.message.instanceId}
    if client
      game = client.game

    listing = @app.listings.find({instanceId: signal.message.instanceId}) or
      @app.listings.find({instanceId: game?.tournamentId})

    # Confirm Actions
    if signal.function in @confirmableActions and signal.message.type is 'response'
      if client?
        client.actionOutcome signal.success
      else
        # console.warn 'can not confirm actionOutcome because client does not exist', fnName, signal.message.instanceId

    if @handlers[fnName]
      @handlers[fnName]
        signal: signal
        app: @app
        game: game
        client: client
        listing: listing

    for update, details of signal.updates
      if @updates[update]?
        @updates[update]
          details: details
          signal: signal
          app: @app
          listing: listing
          game: game or @app.clients.find({instanceId: signal.message.instanceId})?.game
          # game: game or @app.tables.find {instanceId: signal.message.instanceId}
          client: client or @app.clients.find {instanceId: signal.message.instanceId}
          #                     ^^ this is a sneaky check for when a client
          #                        gets made by joinGame but didn't exist at the
          #                        start of the scraping.
    @app.emit 'signalFinished'
    return

# PaymentOptionsSelect
# Not a true view. just a component
# Model: array of entryPayment objects

SEPARATOR = ' + '

export default class PaymentOptionsSelect

  constructor: (model, eligibilityRequired = false, className = '') ->
    @model = model
    @eligibilityRequired = eligibilityRequired
    @dom = $ "<select class='#{className}'>"

  render: =>
    @dom.empty()
    for entryPayment in @model
      if entryPayment.type is 'rebuy'
        text = @getDisplayTextForRebuyPaymentData entryPayment
      else
        text = @getDisplayTextForEntryPaymentData entryPayment, @eligibilityRequired
      option = "<option value='#{entryPayment.id}'>#{text}</option>"
      @dom.append option
    return @dom

  getDisplayTextForEntryPaymentData: (entryPayment, eligibilityRequired) =>
    {buyIn, entryFee, bounty} = entryPayment
    couponType = buyIn.coupon?.couponType or entryFee.coupon?.couponType or bounty.coupon?.couponType

    text = []

    ## Add eligibily requirement
    if eligibilityRequired or couponType is 'ES'
      text.push 'Meet Requirement'

    ## Add coupons
    if couponType isnt 'ES'
      coupons = buyIn.coupon?.name or entryFee.coupon?.name or bounty.coupon?.name
      if coupons? and coupons
        text.push coupons

    ## Add cash components as seperate items
    if buyIn.cashComponent > 0
      text.push buyIn.cashComponent.shortCurrency()

    ## Bounty
    if bounty.cashComponent > 0
      text.push bounty.cashComponent.shortCurrency() + " Knockout" # MSG_00881 / WEB-1555

    ## Fee
    if entryFee.cashComponent > 0
      text.push entryFee.cashComponent.shortCurrency() + " Fee" # MSG_00970

    if text.length is 0
      text.push 'Free'

    return text.join SEPARATOR

  getDisplayTextForRebuyPaymentData: (entryPayment) =>
    {cash, points, chips} = entryPayment
    text = []
    if cash
      text.push cash.shortCurrency()
    # if coupon
    #   text.push cash.shortCurrency()
    if points
      text.push points + ' Points'

    text = text.join(SEPARATOR)
    if chips
      text += " = #{chips} chips"
    return text

  show: => @dom.show()
  hide: => @dom.hide()
  toggle: => @dom.toggle arguments...

# PotData
#  int potNumber
#  String potType
#  PotWinnerData[] winners

# public static void addCurrentPotToMessage(Message message,
#                GamePotData currentPot, BigDecimal totalOfAllPots,
#                BigDecimal roundContributions) {
#           // Add current pot size
#           Argument curPot = new Argument();
#           curPot.setName(ArgumentNameEnum.CURRENTPOT);
#           curPot.setCode1(ArgumentCodeEnum.DATA);
#           curPot.setValue1(currentPot.getPotAmount().toString());
#           curPot.setCode2(ArgumentCodeEnum.DATA);
#           curPot.setValue2(Integer.toString(currentPot.getPotNumber()));
#           curPot.setCode3(ArgumentCodeEnum.DATA);
#           curPot.setValue3(totalOfAllPots.toString());
#           curPot.setCode4(ArgumentCodeEnum.DATA);
#           curPot.setValue4(roundContributions.toString());

import PotWinnerData from './potWinnerData.coffee'
import Mixmaster from '../../../mixmaster.coffee'

export default class PotData extends Mixmaster
  mixins = [
    PotWinnerData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  mixin: (object) ->
    mixins.map((mixin) => (new mixin).mixin(object))

    object.sidePot = (signal, name, values) ->
      signal.updates.pots ?= []
      signal.updates.pots.push
        amount: parseFloat(values.value1)
        number: parseInt(values.value2)

    object.mainPot = (signal, name, values) ->
      signal.updates.pots ?= []
      signal.updates.pots.push
        amount: parseFloat(values.value1)
        number: parseInt(values.value2)

    object.currentPot = (signal, name, values) ->
      signal.updates.potTotal = parseFloat(values.value3)
      # if values.value1 isnt '0.00'
      #   signal.updates.pots.push
      #     amount: parseFloat(values.value1)
      #     number: parseInt(values.value2)
      # signal.updates.pots.total = values.value3

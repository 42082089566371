import classnames from 'classnames'
import React, {useCallback, useEffect, useState} from 'react'

import styles from './Settings.module.css'
import {useAutoRebuyPercentage, useSetAutoRebuyPercentage} from '../react-hooks.js'

export default function AutoRebuyPercent () {
  const initialValue = useAutoRebuyPercentage()
  const setPercentage = useSetAutoRebuyPercentage()
  const [state, setState] = useState(initialValue)

  useEffect(() => {
    setState(initialValue)
  }, [initialValue, setState])

  const handleChange = useCallback(event => {
    const value = event.target.value
    if (value !== initialValue) setPercentage(value)
  }, [initialValue, setPercentage])

  return <label className={classnames(styles.row, styles.subRowInput)}>
    <input type='range' min='0' max='100' step='1' onChange={handleChange} value={state} />
  </label>
}

import Listing from '../../../models/listing.coffee'
import ListTypeEnum from '../../../enum/enums/listTypeEnum.coffee'

export default ({signal, app}) ->

  listTypeObj = Object.values(ListTypeEnum).find ({description}) -> description is signal.parent?.arguments?.list_type
  listTypeId = listTypeObj?.id

  signal.games.forEach (details) ->

    thisGameListId = listTypeId

    # Promotional SnGs and MTTs come bundled together
    switch thisGameListId
      when 26 # Regular
        if details.registrationStartTime
          thisGameListId = 14
        else
          thisGameListId = 17
      when 27 # Satellite
        if details.registrationStartTime
          thisGameListId = 15
        else
          thisGameListId = 19

    details.isTournament = true
    details.isMtt = thisGameListId in [14, 15, 16, 21]
    details.isSng = thisGameListId in [17, 18, 28, 29, 30]
    details.listType = listTypeId

    # # Filter out "deal it twice" games. They do not have a gamesListFlag,
    # #  so we need to match on name
    if /Deal It Twice/.test details.gameName
      return

    game = app.listings.find {instanceId: details.instanceId}

    # only show in progress SnGs that you are in.
    if details.gameState in ['Cancelled', 'Shutdown', 'Finished', 'Shutdown (Refunded)'] and !game?.lobbyOpen
      if game then app.listings.rem game
      return

    # once we have my games...
    else if details.gameState is 'In Progress'
      app.once 'myGamesProcessed', ->
        playing = details.instanceId in app.myInProgress
        processedGame = app.listings.find {instanceId: details.instanceId}
        # warning: do not add game we are already playing. done in app.processMyGames
        if playing and not processedGame # add games we are in
          app.listings.add new Listing {details}

        if not playing and processedGame # remove existing games we're not in
          app.listings.rem processedGame

        else if processedGame and playing # update the games we are in
          for key, value of details
            if processedGame[key] isnt value
              processedGame[key] = value

        return

    else if game?
      # update listing properties that have changed
      for k, v of details
        if game[k] isnt v
          game[k] = v
    else
      game = app.listings.add new Listing {details}

  # Clean up obsolete tournaments
  existing = app.listings.where {listType: listTypeId}
  existing.forEach (listing) ->
    inSignal = signal.games.find ({instanceId}) -> instanceId is listing.instanceId
    unless inSignal
      app.listings.rem listing

import React, {useMemo, useState} from 'react'

import {useRootedSelector} from '../../../redux.js'
import {useClock} from '../../../time/clock.js'
import {TEN_SECONDS} from '../../../time/time-amount.js'
import {useInterval} from '../../../time/timer.js'

import {
  createGetBreakEndTime,
  createGetContenderCount,
  createGetEndTime,
  createGetNextBreakTime,
  createGetRegistrationCount,
  createGetStartTime,
  createGetTableCount,
  createHasCompleted,
  createIsOnBreak,
  getRoot,
} from '../../index.js'

import {renderCurrentBreakEnds, renderNextBreakStarts, renderRunningTime} from '../../rendering.js'

export default function InProgress (props) {
  const clock = useClock()
  const breakEndTime = useRootedSelector(getRoot, useMemo(createGetBreakEndTime, []), props)
  const contenders = useRootedSelector(getRoot, useMemo(createGetContenderCount, []), props)
  const endTime = useRootedSelector(getRoot, useMemo(createGetEndTime, []), props)
  const hasCompleted = useRootedSelector(getRoot, useMemo(createHasCompleted, []), props)
  const isOnBreak = useRootedSelector(getRoot, useMemo(createIsOnBreak, []), props)
  const nextBreakTime = useRootedSelector(getRoot, useMemo(createGetNextBreakTime, []), props)
  const registrants = useRootedSelector(getRoot, useMemo(createGetRegistrationCount, []), props)
  const startTime = useRootedSelector(getRoot, useMemo(createGetStartTime, []), props)
  const tables = useRootedSelector(getRoot, useMemo(createGetTableCount, []), props)

  const now = clock.now()
  const [runningTime, setRunningTime] = useState(renderRunningTime(now, startTime, endTime))
  const [nextBreakStarts, setNextBreakStarts] = useState(renderNextBreakStarts(now, isOnBreak, nextBreakTime))
  const [currentBreakEnds, setCurrentBreakEnds] = useState(renderCurrentBreakEnds(now, isOnBreak, breakEndTime))

  useInterval(TEN_SECONDS, () => {
    const now = clock.now()
    setRunningTime(renderRunningTime(now, startTime, endTime))
    setNextBreakStarts(renderNextBreakStarts(now, isOnBreak, nextBreakTime))
    setCurrentBreakEnds(renderCurrentBreakEnds(now, isOnBreak, breakEndTime))
  })

  return <div className='pane pane__inProgress'>
    <div className='kvline rightAlign'>
      <label>Running Time</label>
      <span className='large nowrap'>{runningTime}</span>
    </div>

    <div className='kvline'>
      <label>Players Remaining</label>
      <span className='large'>{contenders} / {registrants}</span>
    </div>

    {!hasCompleted && !isOnBreak && <div className='kvpair float rightAlign'>
      <label>Next Break:</label>
      <span>{nextBreakStarts}</span>
    </div>}

    {!hasCompleted && isOnBreak && <div className='kvpair float rightAlign'>
      <label>Current Break Ends:</label>
      <span>{currentBreakEnds}</span>
    </div>}

    {!hasCompleted && <div className='kvpair float'>
      <label>Tables:</label>
      <span>{tables}</span>
    </div>}
  </div>
}

import React from 'react'

import DesktopClock from './DesktopClock.js'
import DesktopNavButtons from './DesktopNavButtons.js'
import styles from './DesktopToolbar.module.css'

export default function DesktopToolbar () {
  return <div className={styles.toolbar}>
    <DesktopNavButtons />
    <DesktopClock />
  </div>
}

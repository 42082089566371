import Collection from './collection.coffee'

export default class PlayerList extends Collection
  constructor: () ->
    super()

    @on 'add', @addPlayer

  addPlayer: (player) =>
    player.on 'playSound', (...args) => @emit 'playSound', ...args
    false

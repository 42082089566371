import classnames from 'classnames'
import React from 'react'

import RingGameIndicators from './RingGameIndicators.js'
import SeatingPreview from '../../components/game-listing/SeatingPreview.js'
import ButtonRow from '../../components/game-listing/table/ButtonRow.js'
import Cell from '../../components/game-listing/table/Cell.js'
import styles from '../../components/game-listing/table/Table.module.css'
import Money from '../../money/components/Money.js'
import Stakes from '../../money/components/Stakes.js'
import {useOpen} from '../../multi-table/react-hooks.js'
import {renderGameLimitAndVariantInitialism} from '../../rendering.js'

const OCCUPIED_SEATS = {
  0: [],
  1: [1],
  2: [1, 2],
  3: [1, 2, 3],
  4: [1, 2, 3, 4],
  5: [1, 2, 3, 4, 5],
  6: [1, 2, 3, 4, 5, 6],
  7: [1, 2, 3, 4, 5, 6, 7],
  8: [1, 2, 3, 4, 5, 6, 7, 8],
  9: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  10: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
}

export default function DesktopRingGameListingRow (props) {
  const {ringGame} = props

  const {
    averagePot,
    flopRatio,
    handsPerHour,
    maximumStake,
    minimumStake,
    name,
    seatCount,
    seatedCount,
    tableId,
  } = ringGame

  const occupiedSeats = OCCUPIED_SEATS[seatedCount]

  return <ButtonRow onClick={useOpen(tableId)}>
    <Cell className={styles.seatingPreview}>
      <SeatingPreview seatCount={seatCount} occupiedSeats={occupiedSeats} />
    </Cell>
    <Cell className={styles.primary}>{name}</Cell>
    <Cell>{renderGameLimitAndVariantInitialism(ringGame)}</Cell>
    <Cell><Stakes maximumStake={maximumStake} minimumStake={minimumStake} /></Cell>
    <Cell>{seatedCount} / {seatCount}</Cell>
    <Cell className={styles.expendable}>{averagePot ? <Money value={averagePot} /> : '-'}</Cell>
    <Cell className={styles.expendable}>
      {flopRatio ? flopRatio.toLocaleString('default', {style: 'percent'}) : '-'}
    </Cell>
    <Cell className={styles.expendable}>{handsPerHour || '-'}</Cell>
    <Cell className={classnames(styles.noPadding, styles.alignRight)}>
      <RingGameIndicators ringGame={ringGame} />
    </Cell>
  </ButtonRow>
}

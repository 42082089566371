import React, {useMemo, useState} from 'react'

import LevelBlinds from './LevelBlinds.js'
import {useRootedSelector} from '../../../redux.js'
import {useClock} from '../../../time/clock.js'
import {TEN_SECONDS} from '../../../time/time-amount.js'
import {useInterval} from '../../../time/timer.js'

import {
  createGetCurrentLevel,
  createGetEndTime,
  createGetLevelInterval,
  createGetMinimumRegistrants,
  createGetNextLevel,
  createGetNextLevelTime,
  createGetRegistrationCount,
  createGetRegistrationOpenTime,
  createGetStartTime,
  createIsAnnounced,
  createIsFixedLimit,
  createIsRegistering,
  getRoot,
} from '../../index.js'

import {useShowLevelStructure} from '../../react-hooks.js'
import {renderLevelsDescription, renderNextLevel, renderStartsIn} from '../../rendering.js'

export default function Levels (props) {
  const {tournamentId} = props
  const clock = useClock()
  const showLevelStructure = useShowLevelStructure(tournamentId)
  const currentLevel = useRootedSelector(getRoot, useMemo(createGetCurrentLevel, []), props)
  const isAnnounced = useRootedSelector(getRoot, useMemo(createIsAnnounced, []), props)
  const endTime = useRootedSelector(getRoot, useMemo(createGetEndTime, []), props)
  const isFixedLimit = useRootedSelector(getRoot, useMemo(createIsFixedLimit, []), props)
  const isRegistering = useRootedSelector(getRoot, useMemo(createIsRegistering, []), props)
  const levelInterval = useRootedSelector(getRoot, useMemo(createGetLevelInterval, []), props)
  const minRegistrants = useRootedSelector(getRoot, useMemo(createGetMinimumRegistrants, []), props)
  const nextLevel = useRootedSelector(getRoot, useMemo(createGetNextLevel, []), props)
  const nextLevelTime = useRootedSelector(getRoot, useMemo(createGetNextLevelTime, []), props)
  const registrationCount = useRootedSelector(getRoot, useMemo(createGetRegistrationCount, []), props)
  const registrationOpenTime = useRootedSelector(getRoot, useMemo(createGetRegistrationOpenTime, []), props)
  const startTime = useRootedSelector(getRoot, useMemo(createGetStartTime, []), props)

  const now = clock.now()
  const shouldShowStartsIn = isAnnounced || isRegistering
  const startsInTime = isAnnounced ? registrationOpenTime : startTime
  const [startsIn, setStartsIn] = useState(renderStartsIn(now, startsInTime, minRegistrants, registrationCount))
  const [nextLevelText, setNextLevelText] = useState(renderNextLevel(now, nextLevel, nextLevelTime, endTime))

  useInterval(TEN_SECONDS, () => {
    const now = clock.now()
    if (shouldShowStartsIn) setStartsIn(renderStartsIn(now, startsInTime, minRegistrants, registrationCount))
    setNextLevelText(renderNextLevel(now, nextLevel, nextLevelTime, endTime))
  })

  return <div className='pane pane__levels'>
    {shouldShowStartsIn && <div className='kvline startsIn'>
      {isAnnounced && <label>Registration Starts:</label>}
      {isRegistering && <label>Starts:</label>}
      <span className='large'>{startsIn}</span>
    </div>}

    <div className='kvpair float rightAlign'>
      <label>Current Level:</label>
      <span>{currentLevel ? currentLevel.number : 'N/A'}</span>
    </div>

    {currentLevel && <div className='kvpair float'>
      <LevelBlinds isFixedLimit={isFixedLimit} level={currentLevel} />
    </div>}

    <div className='kvpair float rightAlign'>
      <label>Next Level:</label>
      <span>{nextLevelText}</span>
    </div>

    {nextLevel && <div className='kvpair float'>
      <LevelBlinds isFixedLimit={isFixedLimit} level={nextLevel} />
    </div>}

    <div className='kvpair float rightAlign lvlStruct'>
      <span className='link small rightAlign' onClick={showLevelStructure}>
        Level Structure <span className='value nowrap'>({renderLevelsDescription(levelInterval)})</span>
      </span>
    </div>
  </div>
}

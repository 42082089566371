export default ({signal, details, app, game, client, listing}) ->
  return unless client

  # todo: refactor. this hacks a "chips" property onto each paymentOption
  if details.paymentOptions
    for item in details.paymentOptions
      item.chips = details.chips

  client.rebuyAllowed = details.allowed and details.rebuysRemaining isnt 0
  client.emit 'rebuyAllowed', details

  if client.rebuyAllowed and (details.showPrompt or details.finalRebuy)
    client.promptRebuy true

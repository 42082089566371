import Request from './request.coffee'

export default class UpdateCouponsMessage extends Request
  constructor: ({message, coupons}) ->
    super arguments...

    @function = 'UPDATE_COUPONS'

    @attributes =
      type: 'REQUEST'
      service: 'CREDIT'
      routerId: message.routerId
      serverId: 0
      instanceId: 0
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @rawArguments = []
    for couponId, playthroughOrder of coupons
      arg = {}
      arg.name = 'USER_COUPON_ID'
      arg.code1 = 'DATA'
      arg.value1 = couponId
      arg.code2 = 'DATA'
      arg.value2 = playthroughOrder
      @rawArguments.push arg

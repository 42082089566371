import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# Data Types
import PokerStatusData from './dataTypes/pokerStatusData.coffee'

# handleDeposit(long gameInstanceId, PokerStatusData gameStatus)
# handleDepositResponseFailure(long gameInstanceId, String reasonCode, boolean pendingDeposit)
# handleDepositResponseFailure(long gameInstanceId, String reasonCode, boolean pendingDeposit)

export default class DepositTranslator extends Mixmaster
  mixins = [
    DefaultMixin
    PokerStatusData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

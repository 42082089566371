export default ({signal, details, app, game, player, client}) ->
  return unless player

  # client requires cards to exist in dom still for hover/tap (hidden via css fold class)
  # however they can no longer be flipped
  if player.isClient
    for card in player.cards
      card.facedown = false
      card.flippable = false
  else
    player.cards = details.cards or []

  player.betToPot()

import {
  OCCUPIED_SEATS_LOADED,
  OWN_RING_GAMES_LOADED,
  OWN_SEAT_LOADED,
  OWN_TOURNAMENTS_LOADED,
  RING_GAME_TABLE_LOADED,
  SEAT_STACK_LOADED,
  SIGNED_IN,
  STAKES_LOADED,
  TABLE_SHUT_DOWN,
  TOURNAMENT_DEREGISTRATION_COMPLETED,
  TOURNAMENT_LOADED,
  TOURNAMENT_PARTICIPANTS_LOADED,
  TOURNAMENT_REGISTRATION_COMPLETED,
  TOURNAMENT_TABLE_LOADED,
} from '@pipehat/chronicle/constants/event-type'

import {REGISTERING} from '@pipehat/chronicle/constants/tournament-status'
import {unpackTabularRow} from '@pipehat/chronicle/translation'
import {createSelector} from '@reduxjs/toolkit'
import {produce} from 'immer'

import {createCompareIsoDateTime} from '../comparator.js'

export {getRoot} from './ns.js'

// reducer =====================================================================

const init = {
  ownNickname: '',
  ownRingGames: {},
  ownTournaments: {},
  tables: {},
  tournaments: {},
}

const initTable = {
  limitType: '',
  maximumStake: undefined,
  minimumStake: undefined,
  occupiedSeats: [],
  ownSeat: 0,
  seatCount: 0,
  stacks: {},
}

const initTournament = {
  participants: [],
  entryCost: undefined,
  isRegistered: false,
  minimumRegistrants: 0,
  registrantCount: 0,
  startTime: '',
  status: '',
}

export function reducer (state = init, action) {
  const {type, payload} = action

  switch (type) {
    case SIGNED_IN:
      return produce(state, state => {
        // needed to identify customer's own participant entry
        state.ownNickname = payload.nickname
      })

    case OWN_RING_GAMES_LOADED:
      return produce(state, state => {
        const ownRingGames = {}
        const {games, keys} = payload

        for (const row of games) {
          const game = unpackTabularRow(keys, row)
          const {gameTypes, name, seatingStatus, tableId} = game

          ownRingGames[tableId] = {gameTypes, name, seatingStatus}
        }

        state.ownRingGames = ownRingGames
      })

    case OWN_TOURNAMENTS_LOADED:
      return produce(state, state => {
        const {ownTournaments, tournaments} = state

        for (const row of payload.tournaments) {
          const tournament = unpackTabularRow(payload.keys, row)
          const {isSng, name, status, tableId, tournamentId} = tournament

          ownTournaments[tournamentId] = {isSng, name, status, tableId}
          tournaments[tournamentId] = tournamentReducer(tournaments[tournamentId], action)
        }
      })
  }

  if (!payload) return state

  const {tableId, tournamentId} = payload

  if (tournamentId) {
    state = produce(state, state => {
      const {tournaments} = state

      // tournaments are never deleted at the moment
      tournaments[tournamentId] = tournamentReducer(tournaments[tournamentId], action)
    })
  }

  if (tableId) {
    state = produce(state, state => {
      const {tables} = state

      const existingTable = tables[tableId]
      const table = tableReducer(existingTable, action)

      if (table) {
        tables[tableId] = table
      } else if (existingTable) {
        delete tables[tableId]
      }
    })
  }

  return state
}

function tableReducer (table = initTable, action) {
  const {type, payload} = action

  switch (type) {
    case TABLE_SHUT_DOWN: return undefined

    case RING_GAME_TABLE_LOADED:
    case TOURNAMENT_TABLE_LOADED:
      return produce(table, table => {
        table.seatCount = payload.seatCount
      })

    case STAKES_LOADED:
      return produce(table, table => {
        const {limitType, maximumStake, minimumStake} = payload

        Object.assign(table, {limitType, maximumStake, minimumStake})
      })

    case OCCUPIED_SEATS_LOADED:
      return produce(table, table => {
        table.occupiedSeats = payload.seatNumbers
      })

    case SEAT_STACK_LOADED:
      return produce(table, table => {
        const {seatNumber, stack} = payload

        table.stacks[seatNumber] = stack
      })

    case OWN_SEAT_LOADED:
      return produce(table, table => {
        table.ownSeat = payload.seatNumber
      })
  }

  return table
}

function tournamentReducer (tournament = initTournament, action) {
  const {type, payload} = action

  switch (type) {
    case TOURNAMENT_LOADED:
      return produce(tournament, tournament => {
        const {startTime, status} = payload
        const {minimumRegistrants, registrantCount} = payload.registration
        const entryCost = payload.entry.cost

        Object.assign(tournament, {entryCost, minimumRegistrants, registrantCount, startTime, status})
      })

    case TOURNAMENT_PARTICIPANTS_LOADED:
      return produce(tournament, tournament => {
        const entries = []
        const {keys, participants} = payload

        for (let i = 0; i < participants.length; ++i) {
          const {nickname, place, stack} = unpackTabularRow(keys, participants[i])

          entries.push({nickname, place, stack})
        }

        tournament.participants = entries
      })

    case OWN_TOURNAMENTS_LOADED:
    case TOURNAMENT_REGISTRATION_COMPLETED:
      return produce(tournament, tournament => {
        tournament.isRegistered = true
      })

    case TOURNAMENT_DEREGISTRATION_COMPLETED:
      return produce(tournament, tournament => {
        tournament.isRegistered = false
      })
  }

  return tournament
}

// selectors ===================================================================

export const getOwnRingGames = createSelector(
  [
    state => state.ownRingGames,
    state => state.tables,
  ],
  (ownRingGames, tables) => {
    const entries = []

    for (const tableId in ownRingGames) {
      const table = tables[tableId]

      if (!table) continue

      const {limitType, maximumStake, minimumStake, occupiedSeats, ownSeat, seatCount, stacks} = table
      const {gameTypes, name, seatingStatus} = ownRingGames[tableId]

      if (
        !limitType ||
        !seatCount ||
        !name ||
        !seatingStatus ||
        !occupiedSeats.length ||
        !gameTypes.length
      ) {
        continue
      }

      const stack = ownSeat ? stacks[ownSeat] : undefined

      entries.push({
        gameTypes,
        limitType,
        maximumStake,
        minimumStake,
        name,
        occupiedSeats,
        seatCount,
        seatingStatus,
        stack,
        tableId: parseInt(tableId, 10),
      })
    }

    return entries
  },
)

export const getOwnTournaments = createSelector(
  [
    state => state.ownNickname,
    state => state.ownTournaments,
    state => state.tables,
    state => state.tournaments,
  ],
  (ownNickname, ownTournaments, tables, tournaments) => {
    const entries = []

    for (const tournamentId in ownTournaments) {
      let place, stack

      const ownTournament = ownTournaments[tournamentId]
      const {gameTypes, name, tableId} = ownTournament
      const tournament = tournaments[tournamentId]
      const table = tables[tableId]

      if (!tournament?.isRegistered) continue

      const {entryCost, minimumRegistrants, participants, registrantCount, startTime} = tournament
      const status = tournament.status || ownTournament.status

      if (table) {
        const {ownSeat, stacks} = table

        stack = ownSeat ? stacks[ownSeat] : undefined
      }

      for (const participant of participants) {
        if (participant.nickname !== ownNickname) continue

        place = participant.place
        stack = stack || participant.stack

        break
      }

      entries.push({
        entryCost,
        gameTypes,
        minimumRegistrants,
        name,
        place,
        registrantCount,
        stack,
        startTime,
        status,
        tournamentId: parseInt(tournamentId, 10),
      })
    }

    return entries
  },
)

export const getUpcomingTournament = createSelector(
  [
    state => state.ownTournaments,
    state => state.tournaments,
  ],
  (ownTournaments, tournaments) => {
    const compareIsoDateTime = createCompareIsoDateTime()
    const upcoming = []

    for (const tournamentId in ownTournaments) {
      const ownTournament = ownTournaments[tournamentId]
      const tournament = tournaments[tournamentId]
      const {isSng, name} = ownTournament
      const {isRegistered, startTime} = tournament
      const status = tournament.status || ownTournament.status

      if (!isSng && status === REGISTERING && isRegistered && startTime) upcoming.push({name, startTime})
    }

    const [first] = upcoming.sort((a, b) => compareIsoDateTime(a.startTime, b.startTime))

    return first
  },
)

// routing hooks ===============================================================

export function createOwnGamesRouteHook (communicator, user) {
  function load () {
    if (user.authenticated) communicator.message('getMyGames')
  }

  return function onEnter () {
    load()
    const intervalId = setInterval(load, 5000)
    user.on('change:authenticated', load)

    return function onLeave () {
      user.removeListener('change:authenticated', load)
      clearInterval(intervalId)
    }
  }
}

import Request from './request.coffee'

export default class GetUserGameExclusionsMessage extends Request
  constructor: ({client, message}) ->
    super arguments...

    @function = 'GET_USER_GAME_EXCLUSIONS'

    @attributes =
      type: 'REQUEST'
      service: 'AUTHENTICATION'
      serverId: 0
      routerId: message.routerId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

import Request from './request.coffee'

export default class LoginUserRequest extends Request
  constructor: ({message, credentials, nickname}) ->
    super arguments...

    @function = 'UPDATE_NICKNAME'

    @attributes =
      type: 'REQUEST'
      service: 'AUTHENTICATION'
      routerId: 0
      serverId: 0
      instanceId: 0
      sessionId: message.sessionId
      clientId: 0
      requestTime: 0

    @arguments =
      nick_name: nickname

import React from 'react'

import Money from '../../money/components/Money.js'

export default function PrizeCoupon (props) {
  const {coupon: {name, value}} = props

  if (!value) return <>{name}</>

  return <><Money value={value} /> ({name})</>
}

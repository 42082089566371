import React from 'react'

import DesktopGameFormatSelection from './DesktopGameFormatSelection.js'
import MobileGameFormatSelection from './MobileGameFormatSelection.js'
import styles from './QuickSeat.module.css'
import RingGameSelection from './RingGameSelection.js'
import SngSelection from './SngSelection.js'
import {MOBILE} from '../../device/constants.js'
import {useFormat as useDeviceFormat} from '../../device/react-hooks.js'
import {useGameFormat} from '../react-hooks.js'
import {GAME_FORMAT} from '../service.js'

const {RING_GAME, SNG} = GAME_FORMAT

export default function Selection () {
  const deviceFormat = useDeviceFormat()
  const [gameFormat] = useGameFormat()

  return <div>
    {deviceFormat === MOBILE && <MobileGameFormatSelection />}
    <div className={styles.selection}>
      {deviceFormat !== MOBILE && <DesktopGameFormatSelection />}
      {gameFormat === RING_GAME && <RingGameSelection />}
      {gameFormat === SNG && <SngSelection />}
    </div>
  </div>
}

import {useGameDetails} from '../context.js'
import {useService} from '../react-hooks.js'

export const useBreakCountDown = () => useSelector(({tournament}) => tournament?.breakCountdown)
export const useHandNumber = () => useSelector(({handNumber}) => handNumber)
export const useIsOnBreak = () => useSelector(({tournament}) => tournament?.isOnBreak)
export const useJackpotAmount = () => useSelector(({isJackpotActive, jackpotAmount}) => isJackpotActive ? jackpotAmount : undefined)
export const useLevel = () => useSelector(({level}) => level)
export const useLevelCountdown = () => useSelector(({levelCountdown}) => levelCountdown)
export const useShowHandHistory = () => useService().showHandHistory
export const useStakes = () => useSelector(({stakes}) => stakes)
export const useTableName = () => useSelector(({name}) => name)
export const useTournamentId = () => useSelector(({tournament}) => tournament?.id)
export const useTournamentName = () => useSelector(({tournament}) => tournament?.name)

function useSelector (selector) {
  return selector(useGameDetails())
}

import React from 'react'

import Prize from './Prize.js'
import Money from '../../money/components/Money.js'
import {useOpen} from '../../multi-table/react-hooks.js'

export default function ParticipantListingRow (props) {
  const {displayName, participant: {place, stack, tableId, winnings}} = props

  let winningsOrStack

  if (winnings) {
    winningsOrStack = <Prize value={winnings} />
  } else if (stack) {
    winningsOrStack = <Money value={stack} />
  } else {
    winningsOrStack = '$0'
  }

  return <tr onClick={useOpen(tableId)}>
    <td>{place}</td>
    <td>{displayName}</td>
    <td>{winningsOrStack}</td>
  </tr>
}

import React from 'react'

import SeatCountRangeSelection from './SeatCountRangeSelection.js'
import SpeedSelection from './SpeedSelection.js'
import StakesRangeSelection from './StakesRangeSelection.js'

export default function DesktopSelection () {
  return <>
    <SeatCountRangeSelection />
    <SpeedSelection />
    <StakesRangeSelection />
  </>
}

import {FOLD} from '@pipehat/chronicle/constants/action'
import React from 'react'

import styles from '../../Actions.module.css'
import ActionButton from '../../components/ActionButton.js'
import {useFold, useFoldToAnyBet, useIntendedActionName, useShouldShowFoldToAny} from '../../react-hooks.js'

export default function FoldButton () {
  const shouldShowFoldToAny = useShouldShowFoldToAny()
  const isIntendedAction = useIntendedActionName() === FOLD
  const fold = useFold()
  const foldToAnyBet = useFoldToAnyBet()
  const onClick = shouldShowFoldToAny ? foldToAnyBet : fold

  return <ActionButton className={styles.fold} onClick={onClick} isIntendedAction={isIntendedAction}>
    {shouldShowFoldToAny ? 'Fold to any bet' : 'Fold'}
  </ActionButton>
}

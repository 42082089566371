import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# Data Types
import LoginData from './dataTypes/loginData.coffee'

# handleLoginResponse(LoginData login)

export default class LoginUserTranslator extends Mixmaster
  mixins = [
    DefaultMixin
    LoginData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal
    signal.login = {}

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

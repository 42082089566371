import React, {useCallback, useMemo, useState} from 'react'

import BuyInOptions from './BuyInOptions.js'
import CashierButton from './CashierButton.js'
import DeregisterButton from './DeregisterButton.js'
import PasswordInput from './PasswordInput.js'
import RegisterButton from './RegisterButton.js'
import {useRootedSelector} from '../../../redux.js'

import {
  createGetEntryPaymentOptions,
  createHasEntryEligibilityProfile,
  createIsAnnounced,
  createIsLateRegistration,
  createIsPasswordProtected,
  createIsRegistrationOpen,
  createIsSelfContending,
  createIsSelfDeregistering,
  createIsSelfRegistering,
  createIsSelfRegistrationAllowed,
  getRoot,
} from '../../index.js'

import {useConfirmRegistration, useRegister} from '../../react-hooks.js'

export default function Registration (props) {
  const {tournamentId} = props
  const confirmRegistration = useConfirmRegistration(tournamentId)
  const register = useRegister(tournamentId)
  const paymentOptions = useRootedSelector(getRoot, useMemo(createGetEntryPaymentOptions, []), props)
  const hasEligibility = useRootedSelector(getRoot, useMemo(createHasEntryEligibilityProfile, []), props)
  const isAnnounced = useRootedSelector(getRoot, useMemo(createIsAnnounced, []), props)
  const isLateRegistration = useRootedSelector(getRoot, useMemo(createIsLateRegistration, []), props)
  const isPasswordProtected = useRootedSelector(getRoot, useMemo(createIsPasswordProtected, []), props)
  const isRegistrationOpen = useRootedSelector(getRoot, useMemo(createIsRegistrationOpen, []), props)
  const isSelfContending = useRootedSelector(getRoot, useMemo(createIsSelfContending, []), props)
  const isSelfDeregistering = useRootedSelector(getRoot, useMemo(createIsSelfDeregistering, []), props)
  const isSelfRegistering = useRootedSelector(getRoot, useMemo(createIsSelfRegistering, []), props)
  const canRegister = useRootedSelector(getRoot, useMemo(createIsSelfRegistrationAllowed, []), props)

  const [paymentId, setPaymentId] = useState(paymentOptions?.[0]?.id)
  const [password, setPassword] = useState('')

  const shouldShowRegisterButton = isAnnounced || canRegister || isSelfRegistering
  const shouldShowDeregisterButton = isRegistrationOpen && (isSelfContending || isSelfDeregistering)

  const handlePaymentIdChange = useCallback(event => {
    setPaymentId(event.target.value)
  }, [setPaymentId])

  const handlePasswordChange = useCallback(event => {
    setPassword(event.target.value)
  }, [setPassword])

  const handleRegister = useCallback(event => {
    event.preventDefault()

    if (!canRegister) return

    confirmRegistration(isLateRegistration, () => register({paymentId, password}))
  }, [canRegister, confirmRegistration, isLateRegistration, paymentId, password, register])

  return <div className='pane pane__register'>
    <form onSubmit={handleRegister}>
      <BuyInOptions
        tournamentId={tournamentId}
        paymentOptions={paymentOptions}
        hasEligibility={hasEligibility}
        value={paymentId}
        onChange={handlePaymentIdChange}
      />
      {isPasswordProtected && <PasswordInput
        tournamentId={tournamentId}
        value={password}
        onChange={handlePasswordChange}
      />}
      {shouldShowRegisterButton && <RegisterButton tournamentId={tournamentId} />}
    </form>

    {shouldShowDeregisterButton && <DeregisterButton tournamentId={tournamentId} />}
    <CashierButton />
  </div>
}

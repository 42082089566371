import React, {useCallback} from 'react'

import {useRouteNode, useRouter} from 'react-router5'
import {GAME_LIST, RING_GAME_SUB_ROUTE, RING_GAMES} from '../../../routes.js'
import MobileSelectionButton from '../../components/MobileSelectionButton.js'
import {RING_GAME_FILTER} from '../constants.js'
import {useRingGameFilter} from '../react-hooks.js'

const {FL_HOLDEM, FL_OMAHA_HILO, NL_HOLDEM, PL_OMAHA} = RING_GAME_SUB_ROUTE

const {
  NO_LIMIT_TEXAS_HOLDEM: NO_LIMIT_TEXAS_HOLDEM_FILTER,
  FIXED_LIMIT_TEXAS_HOLDEM: FIXED_LIMIT_TEXAS_HOLDEM_FILTER,
  POT_LIMIT_OMAHA_HOLDEM: POT_LIMIT_OMAHA_HOLDEM_FILTER,
  FIXED_LIMIT_OMAHA_HOLDEM_HILO: FIXED_LIMIT_OMAHA_HOLDEM_HILO_FILTER,
} = RING_GAME_FILTER

export default function MobileSelection () {
  const {route} = useRouteNode('')
  const router = useRouter()
  const {params: {s2: selectedSubRoute}} = route
  const ringGameFilter = useRingGameFilter(selectedSubRoute)

  const handleClickNlHoldem = useCallback(() => {
    router.navigate(GAME_LIST, {s1: RING_GAMES, s2: NL_HOLDEM})
  }, [router])

  const handleClickFlHoldem = useCallback(() => {
    router.navigate(GAME_LIST, {s1: RING_GAMES, s2: FL_HOLDEM})
  }, [router])

  const handleClickPlOmaha = useCallback(() => {
    router.navigate(GAME_LIST, {s1: RING_GAMES, s2: PL_OMAHA})
  }, [router])

  const handleClickFlOmahaHiLo = useCallback(() => {
    router.navigate(GAME_LIST, {s1: RING_GAMES, s2: FL_OMAHA_HILO})
  }, [router])

  return <>
    <MobileSelectionButton isSelected={ringGameFilter === NO_LIMIT_TEXAS_HOLDEM_FILTER} onClick={handleClickNlHoldem}>
      <div>Cash Games</div><div>NL Hold'em</div>
    </MobileSelectionButton>
    <MobileSelectionButton isSelected={ringGameFilter === FIXED_LIMIT_TEXAS_HOLDEM_FILTER} onClick={handleClickFlHoldem}>
      <div>Cash Games</div><div>FL Hold'em</div>
    </MobileSelectionButton>
    <MobileSelectionButton isSelected={ringGameFilter === POT_LIMIT_OMAHA_HOLDEM_FILTER} onClick={handleClickPlOmaha}>
      <div>Cash Games</div><div>PL Omaha</div>
    </MobileSelectionButton>
    <MobileSelectionButton
      isSelected={ringGameFilter === FIXED_LIMIT_OMAHA_HOLDEM_HILO_FILTER}
      onClick={handleClickFlOmahaHiLo}
    >
      <div>Cash Games</div><div>FL Omaha Hi/Lo</div>
    </MobileSelectionButton>
  </>
}

import Model from './model.coffee'

export default class PlayerListing extends Model
  constructor: ({details, players}) ->
    super()

    @properties =
      alias: details.alias or '' # "webclient5" !!! alias is used by us
      userNickname: details.userNickname or '' # !!! this is what the java gives us, it gets converted to alias
      bountyPrize: details.bountyPrize
      cashWinnings: details.cashWinnings # "0.00"
      chips: details.chips # "1500.00"
      eliminationOrder: details.eliminationOrder # "0"
      gameNumber: details.gameNumber # "1"
      hasStarted: details.hasStarted
      pointsWinnings: details.pointsWinnings # "0.00"
      position: details.position or null
      refund: details.refund # "false"
      seat: details.seat # "0"
      table: details.table # "0"
      tournamentId: details.tournamentId # 7995318
    @initializeModel arguments...

  joinTable: =>
    @emit 'joinTable'

import Mixmaster from '../../mixmaster.coffee'
import JoinGameMixin from './mixins/joinGameMixin.coffee'

export default class JoinGameTranslator extends Mixmaster
  mixins = [
    JoinGameMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

import React, {useCallback, useEffect, useState} from 'react'

import LabelRow from './LabelRow.js'
import {useCheckFoldPreference, useSetCheckFoldPreference} from '../react-hooks.js'

export default function CheckFoldAction () {
  const initialValue = useCheckFoldPreference()
  const setCheckFoldPreference = useSetCheckFoldPreference()
  const [state, setState] = useState(initialValue)

  useEffect(() => {
    setState(initialValue)
  }, [initialValue, setState])

  const handleChange = useCallback(event => {
    const value = event.target.value
    if (value !== initialValue) setCheckFoldPreference(value)
  }, [initialValue, setCheckFoldPreference])

  return <LabelRow
    name='Check/Fold Action'
    help='If you attempt to Fold when a Check is possible, you will be prompted by default to ensure that is what you intended.'
  >
    <select onChange={handleChange} value={state}>
      <option value=''>Ask to Check</option>
      <option value='check'>Always Check</option>
      <option value='fold'>Never Check</option>
    </select>
  </LabelRow>
}

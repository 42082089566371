import React, {useCallback, useMemo} from 'react'
import {useRoute, useRouter} from 'react-router5'

import {useRootedSelector} from '../../redux.js'
import {renderLimitType, renderTournamentStatus} from '../../rendering.js'
import {LOBBY} from '../../routes.js'

import {
  createGetDescription,
  createGetLimitType,
  createGetName,
  createGetStatus,
  createIsRegistering,
  createIsSelfContending,
  getRoot,
} from '../index.js'

export default function HeaderPanel (props) {
  const {tournamentId} = props

  const description = useRootedSelector(getRoot, useMemo(createGetDescription, []), props)
  const limitType = useRootedSelector(getRoot, useMemo(createGetLimitType, []), props)
  const name = useRootedSelector(getRoot, useMemo(createGetName, []), props)
  const status = useRootedSelector(getRoot, useMemo(createGetStatus, []), props)
  const isRegistering = useRootedSelector(getRoot, useMemo(createIsRegistering, []), props)
  const isSelfContending = useRootedSelector(getRoot, useMemo(createIsSelfContending, []), props)

  const router = useRouter()
  const {route: {params: {closeTo = LOBBY}}} = useRoute()
  const handleClick = useCallback(e => {
    e.preventDefault()
    router.navigate(closeTo)
  }, [closeTo, router])

  return <>
    <div className='tournament__back__button'>
      <span className='back__button' onClick={handleClick}>
        <span className='fa fa-angle-left' /> Back to Lobby
      </span>
    </div>
    <div className='left'>
      <h1 className='tournament__title'>{name} ({tournamentId}) Tournament Lobby</h1>
      <h2 className='tournament__subtitle'>{description}</h2>
    </div>
    <span className='tournament__status'>
      {isRegistering && isSelfContending ? 'Registered' : renderTournamentStatus(status)}
    </span>

    <span className='tournament__status tournament__subtitle'>{renderLimitType(limitType)} Tournament</span>
  </>
}

import {addBreadcrumb, init as initSentry} from '@sentry/browser/esm/index.js'

export function createInit (persister, dsnString, version) {
  const launchTime = Date.now()

  return function init () {
    if (!dsnString) return

    const dsn = new URL(dsnString)
    const environment = dsn.searchParams.get('environment') || undefined
    dsn.search = ''

    initSentry({
      attachStacktrace: true,
      dsn: dsn.toString(),
      environment,
      release: `customer-ui@${version}`,
      beforeSend (event) {
        const {extra = {}} = event
        const {lastIncomingMessage, lastOutgoingMessage} = extra

        extra.millisSinceLaunch = Date.now() - launchTime
        extra.persistedData = persister.getAll()
        if (lastIncomingMessage) extra.lastIncomingMessage = scrubMessage(lastIncomingMessage)
        if (lastOutgoingMessage) extra.lastOutgoingMessage = scrubMessage(lastOutgoingMessage)

        return event
      },
    })

    addBreadcrumb({category: 'navigation', message: 'App launched'})
  }
}

function scrubMessage (serialized) {
  const message = JSON.parse(serialized)
  const {function: {argument: args = []} = {}} = message

  for (const arg of args) scrubArgument(arg)

  return JSON.stringify(message)
}

function scrubArgument (arg) {
  for (const property of propertiesToScrub(arg)) arg[property] = '*(redacted)*'
}

const ARGUMENT_REDACTION = {
  BALANCE: ['value1'],
  GUID: ['value1'],
  HASH: ['value1'],
  NICK_NAME: ['value1'],
  PASSWORD: ['value1'],
  PLAYER_DETAILS: ['value2'], // nickname
  SECURITY_TOKEN: ['value1'],
  TOKEN: ['value1'],
  USER_NAME: ['value1'],
}

const EVENT_REDACTION = {
  BALANCE_UPDATED: ['value3'], // real balance
  JOIN_GAME: ['value3'], // nickname
  TOKEN_UPDATED: ['value3'], // token
}

function propertiesToScrub (arg) {
  const {name, code1: type} = arg

  if (name === 'EVENT') return EVENT_REDACTION[type] || []

  return ARGUMENT_REDACTION[name] || []
}

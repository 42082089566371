export default
  allIn: (val) -> return 'allIn'
  bet: (val) -> return 'bet'
  bigBlind: (val) -> return 'bigBlind'
  call: (val) -> return 'call'
  code1: (val) -> return val
  friendDelete: (val) -> return 'friendDelete'
  friendRequest: (val) -> return 'friendRequest'
  initialBlind: (val) -> return 'initialBlind'
  raise: (val) -> return 'raise'
  rebuy: (val) -> 'rebuy'
  returnBlind: (val) -> return 'returnBlind'
  smallBlind: (val) -> return 'smallBlind'
  tournamentRematchFailure: (val) -> return 'tournamentRematchFailure'
  transferIn: (val) -> return 'transferIn'
  userFriend: (val) -> return 'userFriend'

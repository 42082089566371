import React from 'react'

import styles from './ResponsibleGaming.module.css'
import {useWindowTitle} from '../../global/react-hooks.js'
import {useRootedSelector} from '../../redux.js'
import Exclusions from '../components/Exclusions.js'
import ExclusionsForm from '../components/ExclusionsForm.js'
import {getRoot, shouldShowUpdate as shouldShowUpdateSelector} from '../index.js'

const windowTitle = 'Responsible Gaming'

export default function ResponsibleGaming () {
  const shouldShowUpdate = useRootedSelector(getRoot, shouldShowUpdateSelector)

  useWindowTitle(windowTitle)

  return <div className={styles.screen}>
    <h1>Responsible Gaming</h1>

    <p>
      We offer you the option to exclude yourself from some, or all, of the
      poker games offered on the poker site for a period ranging from hours to
      days. This exclusion will prevent you from playing any of the excluded
      games for the duration of the exclusion period.
    </p>
    <p>
      If you are registered for tournaments that fall within the exclusion
      period you will be automatically de-registered from these tournaments and
      your buy-in will be refunded to your account. If you are playing at cash
      game tables at the commencement of the exclusion period you will be
      removed from these tables and your balance refunded to your account. There
      will be no extension of time to earn or clear bonuses.
    </p>
    <p>
      Once you have set an exclusion period it cannot be reduce or removed from
      your account.
    </p>

    <h2>Current Exclusion Period</h2>

    <Exclusions />

    {shouldShowUpdate && <h2>Update Exclusions</h2>}

    {shouldShowUpdate && <ExclusionsForm />}
  </div>
}

# EmptySeatView
# Model: client

import View from '../../view.coffee'

export default class EmptySeatView extends View

  constructor: (client, position, displayPosition) ->
    super()

    @model = client
    @position = position
    @displayPosition = if displayPosition? then displayPosition else position

    #console.log "SeatView " + position + " with displayPosition: " + displayPosition

    @reserved = false

    @dom = $ "<div class='player empty player#{displayPosition}'>"

    # ### Components - eg. DOM elements & views
    @components =
      img:          $ '<div class="seat sprite-seat sprite">'
      text:         $ '<div class="button">Sit Down</div>'

    # ### Events - eg. MouseClicks ###
    @dom.on 'click', @takeSeat

    # ### Keyboard shortcuts
    @keyScope = 'game-' + @model.game.instanceId
    @keys = {}
    # @keys[(position + 1).toString()] = @takeSeat

    # ### Listeners - eg. auth:success

    # ### Changes - eg. Model.property = true

    # ### Binding ###
    @initializeView()
    return this

  reserve: =>
    @components.text.html 'Reserved'
    @reserved = true

  unreserve: =>
    @components.text.html 'Sit Down'
    @reserved = false

  takeSeat: =>
    if @reserved
      return
    @model.action 'reserve', {position: @position}

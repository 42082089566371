import React from 'react'

import LabeledCheckbox from '../../../../components/LabeledCheckbox.js'
import {useIsAutoRebuy, useToggleAutoRebuy} from '../../react-hooks.js'

export default function Toggle () {
  const isAutoRebuy = useIsAutoRebuy()
  const toggleAutoRebuy = useToggleAutoRebuy()

  return <LabeledCheckbox checked={isAutoRebuy} onClick={toggleAutoRebuy} size='small'>Rebuy</LabeledCheckbox>
}

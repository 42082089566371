import React from 'react'

import {renderTextForEntryPayment} from '../../rendering.js'

export default function BuyInOptionsSelect (props) {
  const {hasEligibilityProfile, onChange, paymentOptions, value} = props

  if (!paymentOptions) return <select><option>Free</option></select>

  const options = []

  for (const payment of paymentOptions) {
    const text = renderTextForEntryPayment(payment, hasEligibilityProfile)

    options.push(<option key={payment.id} value={payment.id}>{text}</option>)
  }

  return <select value={value} onChange={onChange}>
    {options}
  </select>
}

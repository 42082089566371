import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# Data Types
import PokerStatusData from './dataTypes/pokerStatusData.coffee'
import AllInShowdownPlayerData from './dataTypes/allInShowdownPlayerData.coffee'

# handleStartGame(long gameInstanceId, PokerStatusData gameStatus)

export default class StartGameTranslator extends Mixmaster
  mixins = [
    DefaultMixin
    PokerStatusData
    AllInShowdownPlayerData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

export function createCasinoSettings (persister, shortName) {
  return createSettings(persister, `settings:${shortName}`)
}

export function createGameFilterSettings (persister) {
  return createSettings(persister, 'gameFilter')
}

export function createGlobalSettings (persister) {
  return createSettings(persister, 'global')
}

function createSettings (persister, prefix) {
  if (!persister) return createNoopSettings()

  return createPersistedSettings(persister, prefix)
}

function createPersistedSettings (persister, prefix) {
  const {get, remove, set, subscribe} = persister

  return {
    get (key) {
      return get(`${prefix}:${key}`)
    },

    remove (key) {
      remove(`${prefix}:${key}`)
    },

    set (key, value) {
      set(`${prefix}:${key}`, value)
    },

    subscribe (key, subscriber) {
      return subscribe(`${prefix}:${key}`, subscriber)
    },
  }
}

function createNoopSettings () {
  return {
    delete () {},
    get () {},
    set () {},

    subscribe () {
      return function unsubscribe () {}
    },
  }
}

import Request from './request.coffee'

export default class ClaimCouponRequest extends Request
  constructor: ({message, credentials, couponCode}) ->
    super arguments...

    @function = 'CLAIM_COUPON'

    @attributes =
      type: 'REQUEST'
      service: 'CREDIT'
      routerId: message.routerId
      serverId: 0
      instanceId: 0
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @arguments =
      coupon_code: couponCode

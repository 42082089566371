import classnames from 'classnames'
import React from 'react'

import styles from './List.module.css'
import skeleton from '../../../skeleton.module.css'

export default function List ({ariaLabelledby, children, isSkeleton}) {
  return <div
    aria-labelledby={ariaLabelledby}
    role='list'
    className={classnames(styles.list, {[skeleton.skeletonize]: isSkeleton})}
  >{children}</div>
}

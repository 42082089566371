import React from 'react'

import LabeledCheckbox from '../../../components/LabeledCheckbox.js'
import styles from '../QuickOptions.module.css'
import {useSitIn} from '../react-hooks.js'

export default function SittingOut ({size}) {
  return <LabeledCheckbox
    className={styles.sitOutNextHand}
    checked='checked'
    onClick={useSitIn()}
    size={size}
  >
    Sitting Out
  </LabeledCheckbox>
}

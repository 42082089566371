import React from 'react'

export default function PasswordInput (props) {
  const {onChange, value} = props

  return <input
    className='password'
    type='text'
    placeholder='Tournament Password'
    value={value}
    onChange={onChange}
  />
}

import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# handleConnectionCheck(long gameInstanceId)

export default class ConnectionCheckTranslator extends Mixmaster
  mixins = [DefaultMixin]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

export default
  '2': handle: 'two',   index: 0,  code: '2'
  '3': handle: 'three', index: 1,  code: '3'
  '4': handle: 'four',  index: 2,  code: '4'
  '5': handle: 'five',  index: 3,  code: '5'
  '6': handle: 'six',   index: 4,  code: '6'
  '7': handle: 'seven', index: 5,  code: '7'
  '8': handle: 'eight', index: 6,  code: '8'
  '9': handle: 'nine',  index: 7,  code: '9'
  'T': handle: 'ten',   index: 8,  code: 't'
  'J': handle: 'jack',  index: 9,  code: 'j'
  'Q': handle: 'queen', index: 10, code: 'q'
  'K': handle: 'king',  index: 11, code: 'k'
  'A': handle: 'ace',   index: 12, code: 'a'
  'B': handle: 'back',  index: 13, code: 'b'

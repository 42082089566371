export default ({signal, details, app, game, client, listing}) ->

  unless (details.playerPosition? and details.message? and details.nickname?)
    # console.warn 'can not process incoming chat message', details, signal.failReason
    return

  details.format = 'chat'
  client?.displayChatMessage details # pipe signal directly through

  player = game?.getPlayerByPosition details.playerPosition
  if player
    player.chatMessage details.message

import React from 'react'

import styles from './Settings.module.css'
import {useFormat} from '../../device/react-hooks.js'
import {useSwitchFormat} from '../react-hooks.js'

export default function Format () {
  return <div className={styles.deviceFormat}>
    You are using the {useFormat()} version.
    To switch version, <span className='link' onClick={useSwitchFormat()}>click here</span>.
  </div>
}

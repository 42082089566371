import React from 'react'

import RoomProvider from '../../rooms/RoomProvider.js'
import AdvancedOptions from '../../rooms/advanced-options/AdvancedOptions.js'

export default function AdvancedOptionsView (props) {
  const {hideModal, tableId} = props

  return <RoomProvider tableId={tableId}>
    <AdvancedOptions hideModal={hideModal} />
  </RoomProvider>
}

import Player from '../../../models/player.coffee'

export default ({signal, details, app, game, player, client}) ->
  return unless game

  # stops players being created from spectators
  if details.playerId isnt -1
    playerDetails =
      position: details.playerId
      alias: details.playerName
      balance: details.playerBalance
      location: details.playerCity
      currency: game.currency
      precision: if game.minChipValue is 1 then 0 else 2
      label: 'TRANSFER'

    existingPlayer = game.players.find {position: details.playerId}

    if existingPlayer?
      game.players.rem existingPlayer

    game.players.add new Player {details: playerDetails}

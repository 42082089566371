import {ALL_IN, BET, CALL, CHECK, FOLD, RAISE} from '@pipehat/chronicle/constants/action'
import classnames from 'classnames'
import React from 'react'

import BetRaiseAmount from './bet-raise/BetRaiseAmount.js'
import AllInButton from './components/AllInButton.js'
import BetButton from './components/BetButton.js'
import CallBetButton from './components/CallBetButton.js'
import CheckButton from './components/CheckButton.js'
import FoldButton from './components/FoldButton.js'
import RaiseButton from './components/RaiseButton.js'
import styles from '../Actions.module.css'

import {
  useAvailableActions,
  useBetRaiseAmount,
  useIsBetRaiseVisible,
  useIsTurn,
  useShouldShowBetRaise,
} from '../react-hooks.js'

export default function PlayHand () {
  const actions = useAvailableActions()
  const amount = useBetRaiseAmount()
  const isTurn = useIsTurn()
  const amountSelectorVisible = useIsBetRaiseVisible()
  const shouldShowBetRaise = useShouldShowBetRaise()
  const shouldShowFold = actions.includes(FOLD) || (actions.length === 0 && !isTurn)

  return <>
    <div className={classnames(styles.playHand, {[styles.preactions]: !isTurn})}>
      {shouldShowFold && <FoldButton />}
      {actions.includes(CHECK) && <CheckButton />}
      {actions.includes(CALL) && <CallBetButton />}
      {actions.includes(BET) && <BetButton />}
      {actions.includes(RAISE) && <RaiseButton />}
      {actions.includes(ALL_IN) && <AllInButton />}
    </div>
    {shouldShowBetRaise && amount && amountSelectorVisible && <BetRaiseAmount />}
  </>
}

export function createService (user, windowTitleManager) {
  const isAuthenticatedSubscribers = new Set()
  user.on('change:authenticated', dispatchIsAuthenticated)

  return {
    setWindowTitle (title) {
      windowTitleManager.set(title)
    },

    isAuthenticated () {
      return user.authenticated
    },

    subscribeToIsAuthenticated (subscriber) {
      isAuthenticatedSubscribers.add(subscriber)

      return function unsubscribe () {
        isAuthenticatedSubscribers.delete(subscriber)
      }
    },
  }

  function dispatchIsAuthenticated () {
    for (const subscriber of isAuthenticatedSubscribers) subscriber(user.authenticated)
  }
}

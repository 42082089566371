import React from 'react'

import styles from './QuickOptions.module.css'
import AutoMuckOption from './components/AutoMuckOption.js'
import AutoPostBlindsOption from './components/AutoPostBlindsOption.js'
import SittingOut from './components/SittingOut.js'
import TableSitOut from './components/TableSitOut.js'
import TournamentSitOut from './components/TournamentSitOut.js'
import {useIsTournament} from './react-hooks.js'
import {useIsSeated, useIsSittingOut} from '../seats/react-hooks.js'

export default function QuickOptions () {
  const isSeated = useIsSeated()
  const isSittingOut = useIsSittingOut()
  const isTournament = useIsTournament()

  if (!isSeated) return null

  const size = 'small'

  return <span className={styles.container}>
    <div className={styles.auto}>
      {!isSittingOut && <div size={size} className={styles.heading}>Auto</div>}
      {isSeated && <AutoMuckOption className={styles.auto} />}
      {isSeated && <AutoPostBlindsOption className={styles.auto} />}
    </div>
    {isSittingOut && <SittingOut size={size} />}
    {!isSittingOut && <div size={size} className={styles.heading}>Sit Out Next</div>}
    {!isSittingOut && isTournament && <TournamentSitOut size={size} />}
    {!isSittingOut && !isTournament && <TableSitOut size={size} />}
  </span>
}

import React, {useCallback} from 'react'

import {useRouteNode, useRouter} from 'react-router5'
import {GAME_LIST, SIT_N_GO, SIT_N_GO_SUB_ROUTE} from '../../../routes.js'
import MobileSelectionButton from '../../components/MobileSelectionButton.js'
import {SIT_N_GO_FILTER} from '../constants.js'
import {useSngFilter} from '../react-hooks.js'

const {ALL, HYPER, TURBO} = SIT_N_GO_SUB_ROUTE
const {HYPER: HYPER_FILTER, TURBO: TURBO_FILTER, ALL: ALL_FILTER} = SIT_N_GO_FILTER

export default function MobileSelection () {
  const {route} = useRouteNode('')
  const router = useRouter()
  const {params: {s2: selectedSubRoute}} = route
  const sngFilter = useSngFilter(selectedSubRoute)

  const handleClickAll = useCallback(() => {
    router.navigate(GAME_LIST, {s1: SIT_N_GO, s2: ALL})
  }, [router])

  const handleClickHyper = useCallback(() => {
    router.navigate(GAME_LIST, {s1: SIT_N_GO, s2: HYPER})
  }, [router])

  const handleClickTurbo = useCallback(() => {
    router.navigate(GAME_LIST, {s1: SIT_N_GO, s2: TURBO})
  }, [router])

  return <>
    <MobileSelectionButton isSelected={sngFilter === HYPER_FILTER} onClick={handleClickHyper}>
      <div>Sit & Go</div><div>Hyper</div>
    </MobileSelectionButton>
    <MobileSelectionButton isSelected={sngFilter === TURBO_FILTER} onClick={handleClickTurbo}>
      <div>Sit & Go</div><div>Turbo</div>
    </MobileSelectionButton>
    <MobileSelectionButton isSelected={sngFilter === ALL_FILTER} onClick={handleClickAll}>
      <div>Sit & Go</div><div>All</div>
    </MobileSelectionButton>
  </>
}

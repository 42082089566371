import {CHECK} from '@pipehat/chronicle/constants/action'
import React from 'react'

import styles from '../../Actions.module.css'
import ActionButton from '../../components/ActionButton.js'
import {useCheck, useIntendedActionName} from '../../react-hooks.js'

export default function CheckButton () {
  const isIntendedAction = useIntendedActionName() === CHECK

  return <ActionButton className={styles.check} onClick={useCheck()} isIntendedAction={isIntendedAction}>
    Check
  </ActionButton>
}

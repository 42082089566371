import dayjs from 'dayjs'

import Collection from '../collections/collection.coffee'
import GameListFlags from '../enum/enums/gameListFlags.coffee'
import Model from './model.coffee'

export default class Listing extends Model
  constructor: ({details, players, tableList, parents, satellites}) ->
    super()

    @players = players or new Collection
    @tableList = tableList or new Collection

    @parents = parents or new Collection
    @satellites = satellites or new Collection

    @properties =
      ante: details.ante ?= 0
      averagePot: details.averagePot ?= 0
      addPrizePoolToBuyins: details.addPrizePoolToBuyins ?= false
      bigBlind: details.bigBlind ?= 0
      community: details.community ?= []
      currentBet: details.currentBet ?= 0
      currentPlayer: details.currentPlayer ?= null
      currentState: details.currentState ?= null
      dealer: details.dealer ?= 0
      entry: details.entry ?= 0
      entryPayments: details.entryPayments ?= []
      gameListFlags: details.gameListFlags ?= ''
      gameId: details.gameId ?= ''
      gameState: details.gameState ?= ''
      gameStateId: details.gameStateId ?= 0 # made this to sort listings. eg. cancelled to the bottom
      handsPerHour: details.handsPerHour ?= 0
      instanceId: details.instanceId ?= 0
      jackpotIsActive: details.jackpotIsActive ?= false
      jackpotBalance: details.jackpotBalance ?= 0
      limitType: details.limitType ?= ''
      limitTypeDesc: details.limitTypeDesc ?= { long: 'N/A', short: 'N/A' }
      maxBet: details.maxBet ?= 0
      maxBuyIn: details.maxBuyIn ?= null
      maxPlayers: details.maxPlayers ?= 2
      minBet: details.minBet ?= 0
      minBuyIn: details.minBuyIn ?= null
      minChipValue: details.minChipValue ?= 0.1
      gameName: details.gameName ?= ''
      numberPlayers: details.numberPlayers ?= { watching: 0, waitListLength: 0, playing: 0 }
      pinned: false
      playerCount: details.playerCount ?= 0
      playersInFlopPct: details.playersInFlopPct
      playing: details.playing or false
      realMoney: details.realMoney ?= false
      serverId: details.serverId ?= 0
      smallBlind: details.smallBlind ?= 0
      speed: details.speed ?= ''
      stakesDescription: details.stakesDescription ?= ''
      visible: true
      registered: details.registered ?= false
      currency: details.currency ?= '$'
      gameTypeId: details.gameTypeId ?= null
      gameTypeDesc: details.gameTypeDesc ?= { long: 'N/A', short: 'N/A'}
      isTournament: details.isTournament ?= false
      listType: details.listType ?= 0 # "18"
      minPlayers: details.minPlayers ?= 0 # "2"
      numPlayers: details.numPlayers ?= 0 # "2"
      waiting: details.waiting ?= false
      style: details.style ?= null

    if details.isTournament then Object.assign @properties,
      accoladeId: details.accoladeId ?= 0 # "0"
      addonAmount: details.addonAmount ?= 0
      addonChips: details.addonChips ?= 0
      addonCount: details.addonCount ?= 0
      addons: details.addons ?= 0
      advancingPlayers: details.advancingPlayers ?= 0 # "0"
      bountyAmount: details.bountyAmount ?= 0
      bountyPrizePool: details.bountyPrizePool ?= 0 # "0.00"
      buyIn: details.buyIn ?= 0
      couponEntry: details.couponEntry ?= false
      currentBreakStopTime: details.currentBreakStopTime ?= null # "null"
      currentLevel: details.currentLevel ?= {}
      doubleUp: details.doubleUp ?= false
      description: details.description ?= '' # "32 Up Quick Breakdown Test"
      detailsReceived: details.detailsReceived ?= false
      eligibilityRequired: details.eligibilityRequired ?= false
      endTime: details.endTime ?= null # "null"
      entryFee: details.entryFee ?= 0
      gameNumber: details.gameNumber ?= 0 # "1"
      handsUntilNextLevel: details.handsUntilNextLevel ?= '' # "3 Hands"
      isMtt: details.isMtt ?= false
      isSatellite: details.isSatellite ?= false
      isSng: details.isSng ?= false
      lateRegistrationOptions: details.lateRegistrationOptions ?= null # "null"
      levelId: details.levelId ?= 0 # ???
      levelIncrement: details.levelIncrement ?= '' # "10 Hands"
      maxPlayersPerTable: details.maxPlayersPerTable ?= 0 # "2"
      minPrizePool: details.minPrizePool ?= 0 # "0.00"
      multipleEntry: details.multipleEntry ?= null # "0"
      nextBreakStartTime: details.nextBreakStartTime ?= null # "null"
      nextLevel: details.nextLevel ?= {}
      nextLevelStartTime: details.nextLevelStartTime ?= null # "null"
      noDeregister: details.noDeregister ?= false
      numRegistered: details.numRegistered ?= 0 # "2"
      passwordRequired: details.passwordRequired ?= false
      priority: details.priority ?= false
      privateGame: details.privateGame ?= false
      prizePool: details.prizePool ?= 0 # "20.00"
      prizes: []
      rebuyAmount: details.rebuyAmount ?= 0
      rebuyChips: details.rebuyChips ?= 0 # "1500.00"
      rebuyCount: details.rebuyCount ?= null # "0"
      rebuys: details.rebuys ?= 0 # "0"
      registrationRequired: details.registrationRequired ?= false
      registrationStartTime: details.registrationStartTime ?= null # "null"
      registrationStopTime: details.registrationStopTime ?= null # "null"
      satelliteInfo: details.satelliteInfo ?= null
      scheduleType: details.scheduleType ?= '' # ringGames / sitAndGo / scheduled
      shootoutRoundNumber: details.shootoutRoundNumber ?= 0 # "0"
      shootoutTypeId: details.shootoutTypeId ?= 0 # "0"
      startingChips: details.startingChips ?= 0 # "1500.00"
      startTime: details.startTime ?= null
      tournamentStatus: details.tournamentStatus ?= null # "5~0~0" # levelid~onbreak~seating # dave c says they will translate these

    # Utility Properties
    Object.defineProperty this, 'hasStarted',
      enumerable: yes
      get: @hasStarted

    # if user requests to joinTable for a specific player, find that player's table
    # and issue a joinTable
    @players.on 'add', (player) =>
      player.on 'joinTable', =>
        @tableList.find({number: player.table})?.join()

      player.on 'destroy', -> player.removeAllListeners 'joinTable'

    @on 'tournamentPlayers', @updatePlayers

    @initializeModel arguments...

  action: =>
    @emit 'action', arguments...

  hasStarted: =>
    unless @startTime # Sngs that haven't started and Ring Games
      return false

    if @startTime < Date.now() # Mtts
      return true
    else
      return false

  updatePlayers: =>
    @playerCount = @players.count

  joinGame: =>
    # @emit 'joining' # for UI only. loading screens, etc
    Navigator.navTable @

  showLobby: =>
    @emit 'action', 'getTournamentDetails',
      cb: (err) =>
        unless err
          @emit 'action', 'showLobby'
          @getPlayers()

  showNotification: =>
    @emit 'action', 'showNotification'

  getDetails: (cb) =>
    @emit 'action', 'getTournamentDetails', {cb}

  getPlayers: =>
    @emit 'action', 'getTournamentPlayers'

  getParents: =>
    @emit 'action', 'getTournamentParents'

  getSatellites: =>
    @emit 'action', 'getTournamentSatellites'

  getLevelStructure: =>
    @emit 'action', 'getLevelStructure', {@levelId, @limitType, @instanceId}

  register: ({paymentId = 0, password, suppressErrorMessage}, cb) =>
    suppressErrorMessage ?= false
    @emit 'action', 'register', {paymentId, password, cb, suppressErrorMessage}

  deregister: =>
    @emit 'action', 'deregister'

  failedRegistration: (reason) =>
    @emit 'failedRegistration', reason

  deregistration: =>
    @registered = false
    @emit 'deregistration', arguments...
    clearTimeout @reminderTimeout

  registration: =>
    @registered = true
    @emit 'registration', arguments...
    @emit 'action', 'registered' #in app we use this to queue beforeStart notification

  qGameStartNotification: (timeBeforeGame) =>
    clearTimeout @reminderTimeout
    unless @startTime and
      timeBeforeGame > 0 and
      @registered and
      @gameState isnt 'Shutdown'
        return
    now = dayjs()
    alertTime = dayjs(@startTime).subtract(timeBeforeGame, 'minutes')
    alertTimeMilliFromNow = alertTime.diff(now)
    if alertTimeMilliFromNow > 0
      @reminderTimeout = setTimeout =>
        @showNotification()
      , alertTimeMilliFromNow

  getStakes: =>
    switch @limitType
      when 'noLimit'
        return "#{@smallBlind.toBuyinFormat()} / #{@minBet.toBuyinFormat()}"
      when 'fixedLimit'
        return "#{@minBet.toBuyinFormat()} / #{@maxBet.toBuyinFormat()}"
      when 'potLimit'
        return "#{@smallBlind.toBuyinFormat()} / #{@minBet.toBuyinFormat()}"

  getStakesComparator: =>
    switch @limitType
      when 'noLimit'
        return @smallBlind
      when 'fixedLimit'
        return @minBet
      when 'potLimit'
        return @smallBlind

  getToken: (keyId) =>
    @emit 'action', 'getToken', {keyId}

  getCoupons: =>
    @emit 'action', 'getCoupons'

  visitCashier: =>
    @emit 'action', 'visitCashier'

  addToWaitlist: =>
    @emit 'action', 'addToWaitlist'

  removeFromWaitlist: =>
    @emit 'action', 'removeFromWaitlist'

  checkFlag: (flag) =>
    return @gameListFlags & GameListFlags[flag]

  listFlags: =>
    # for k, v of GameListFlags
    #   if @checkFlag k
    #     console.log k

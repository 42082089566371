export function createService (window) {
  let deferredInstallPrompt
  let isAvailable = false
  const subscribers = new Set()

  return {
    get isAvailable () { return isAvailable },

    init () {
      window.addEventListener('beforeinstallprompt', handleBeforeInstall)
      window.addEventListener('appinstalled', handleAfterInstall)
    },

    install () {
      deferredInstallPrompt?.prompt()
    },

    subscribe (subscriber) {
      subscribers.add(subscriber)

      return function unsubscribe () {
        subscribers.delete(subscriber)
      }
    },
  }

  function setIsAvailable (value) {
    isAvailable = value
    for (const subscriber of subscribers) subscriber(isAvailable)
  }

  function handleBeforeInstall (event) {
    event.preventDefault()
    deferredInstallPrompt = event
    setIsAvailable(true)
  }

  function handleAfterInstall (event) {
    event.preventDefault()
    deferredInstallPrompt = null
    setIsAvailable(false)
  }
}

export default ({signal, details, app, game, client, listing}) ->
  if details.seat? # seat may be `0` so use a null/undefined check
    client?.position = details.seat
    if client?
      if signal.parent?.autoSeat
        client.autoBuyin()
      else if signal.function is 'seatReserved' # waitlist
        client.autoBuyin()
      else
        client.buyin()

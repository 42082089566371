import React from 'react'

import Row from './Row.js'
import Money from '../../../money/components/Money.js'
import styles from '../GameDetails.module.css'

export default function JackpotAmount (props) {
  const {amount} = props

  return <Row>
    BBJ: <span className={styles.badbeatBalance}><Money value={amount} /></span>
  </Row>
}

import classnames from 'classnames'
import React from 'react'

import RingGameIndicators from './RingGameIndicators.js'
import SeatingPreview from '../../components/game-listing/SeatingPreview.js'
import ButtonRow from '../../components/game-listing/list/ButtonRow.js'
import styles from '../../components/game-listing/list/List.module.css'
import Stakes from '../../money/components/Stakes.js'
import {useOpen} from '../../multi-table/react-hooks.js'
import {renderGameLimitAndVariant, renderGameLimitAndVariantInitialism} from '../../rendering.js'
import skeleton from '../../skeleton.module.css'

const OCCUPIED_SEATS = {
  0: [],
  1: [1],
  2: [1, 2],
  3: [1, 2, 3],
  4: [1, 2, 3, 4],
  5: [1, 2, 3, 4, 5],
  6: [1, 2, 3, 4, 5, 6],
  7: [1, 2, 3, 4, 5, 6, 7],
  8: [1, 2, 3, 4, 5, 6, 7, 8],
  9: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  10: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
}

export default function MobileRingGameListingRow (props) {
  const {ringGame} = props

  const {
    maximumStake,
    minimumStake,
    name,
    seatCount,
    seatedCount,
    tableId,
  } = ringGame

  const occupiedSeats = OCCUPIED_SEATS[seatedCount]

  return <ButtonRow onClick={useOpen(tableId)}>
    <div className={styles.seatIcon}>
      <span className={skeleton.light}>
        <SeatingPreview seatCount={seatCount} occupiedSeats={occupiedSeats} />
      </span>
    </div>
    <div className={styles.gameDetailsSection}>
      <div className={styles.name}>
        <span className={skeleton.light}>{name}</span>
      </div>
      <div className={styles.stakes}>
        <span className={skeleton.light}>
          <Stakes maximumStake={maximumStake} minimumStake={minimumStake} />
        </span>
      </div>
    </div>
    <div className={styles.gameTypeSection}>
      <div className={styles.gameType}>
        <span className={skeleton.light}>
          {renderGameLimitAndVariant(ringGame)}
        </span>
      </div>
      <div className={classnames(styles.gameType, styles.short)}>
        <span className={skeleton.light}>
          {renderGameLimitAndVariantInitialism(ringGame)}
        </span>
      </div>
      <div className={styles.seatCount}>
        <span className={skeleton.light}>
          Plrs: {seatedCount} / {seatCount}
        </span>
      </div>
    </div>
    <div className={styles.indicators}>
      <span className={skeleton.light}>
        <RingGameIndicators ringGame={ringGame} />
      </span>
    </div>
  </ButtonRow>
}

export default ({signal, app, game, client, listing}) ->

  # console.log 'rebuyResponse. success:', signal.success
  if signal.success
    # if signal.updates.pendingRebuy
    #   console.warn 'pending rebuy!'
  else
    switch signal.failReason
      when 'balanceAboveThreshold'
        content = 'Balance Above Threshold'
      when 'shutdown'
        content = 'Tournament has been Shutdown'
      when 'rebuyNotAllowed'
        content = 'Not Allowed'
      else
        content = signal.failReason
    app.notice
      title: 'Rebuy Fail'
      content: content

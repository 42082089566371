import invokeMap from 'lodash/invokeMap'

export default
  sittingOut:
    chat: ({name}) -> "#{name} is sitting out."
    handHistory: ({name}) -> "#{name} is sitting out."
    cssClass: 'sittingOut'
    label: 'SITTING OUT'
  returned:
    chat: ({name}) -> "#{name} has returned."
    handHistory: ({name}) -> "#{name} has returned."
    cssClass: 'in'
    label: 'RETURNS'
  muckCards:
    chat: ({name}) -> "#{name} mucks."
    handHistory: ({name}) -> "#{name} mucks."
    label: 'MUCKS'
  showCards:
    chat: ({name, cards}) -> "#{name} shows: (#{ invokeMap(cards, 'toJavaString').join(',') })."
    handHistory: ({name, cards}) -> "#{name} shows: (#{ invokeMap(cards, 'toJavaString').join(',') })."
    label: 'SHOWS'
  rabbitHunt:
    chat: ({name}) -> "#{name} rabbits."
    handHistory: ({name}) -> "#{name} rabbits."
    label: 'RABBITS'
  bigBlind:
    chat: ({name, amount}) -> "#{name} posts big blind #{amount}."
    handHistory: ({name, amount}) -> "#{name} posts big blind #{amount}."
    label: 'POSTS BB'
  smallBlind:
    chat: ({name, amount}) -> "#{name} posts small blind #{amount}."
    handHistory: ({name, amount}) -> "#{name} posts small blind #{amount}."
    label: 'POSTS SB'
  returnBlind:
    chat: ({name, amount}) -> "#{name} posts return blind #{amount}."
    handHistory: ({name, amount}) -> "#{name} posts return blind #{amount}."
    label: 'POSTS RB'
  initialBlind:
    chat: ({name, amount}) -> "#{name} posts initial blind #{amount}."
    handHistory: ({name, amount}) -> "#{name} posts initial blind #{amount}."
    label: 'POSTS IB'
  bet:
    chat: ({name, amount}) -> "#{name} bets #{amount}."
    handHistory: ({name, amount}) -> "#{name} bets #{amount}."
    label: 'BET'
    cssClass: 'bet'
  raise:
    chat: ({name, amount}) -> "#{name} raises #{amount}."
    handHistory: ({name, amount}) -> "#{name} raises #{amount}."
    label: 'RAISES'
    cssClass: 'raise'
  allIn:
    chat: ({name, amount}) -> "#{name} is all in."
    handHistory: ({name, amount}) -> "#{name} is all in."
    label: 'ALL IN'
    cssClass: 'allIn'
  call:
    chat: ({name, amount}) -> "#{name} calls #{amount}."
    handHistory: ({name, amount}) -> "#{name} calls #{amount}."
    label: 'CALLS'
    cssClass: 'call'
  check:
    chat: ({name}) -> "#{name} checks."
    handHistory: ({name}) -> "#{name} checks."
    label: 'CHECKS'
    cssClass: 'check'
  fold:
    chat: ({name}) -> "#{name} folds."
    handHistory: ({name}) -> "#{name} folds."
    label: 'FOLDED'
    cssClass: 'fold'
  waiting:
    chat: ({name}) -> "#{name} is waiting for Bb"
    handHistory: ({name}) -> "#{name} is waiting for Bb"
    label: 'WAITING'

import React from 'react'

import {useClock} from '../clock.js'
import {describeTime} from '../index.js'

export default function DescribedTime (props) {
  const {value} = props
  const clock = useClock()
  const date = new Date(value)

  return <time dateTime={value} title={value}>{describeTime(clock, date)}</time>
}

import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# Data Types
import BreakData from './dataTypes/breakData.coffee'
import RebuyData from './dataTypes/rebuyData.coffee'

# handleGameStatus(long gameInstanceId, PokerStatusData gameStatus)

export default class StartBreakTranslator extends Mixmaster
  mixins = [
    DefaultMixin
    BreakData
    RebuyData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

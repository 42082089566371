import React from 'react'

import RingGameFilterSelection from './RingGameFilterSelection.js'
import RingGameStakesSelection from './RingGameStakesSelection.js'
import {useRootedSelector} from '../../redux.js'
import {getIsRingGameLoading, getRoot} from '../index.js'

export default function RingGameSelection () {
  const isLoading = useRootedSelector(getRoot, getIsRingGameLoading)

  return <>
    <RingGameFilterSelection />
    <RingGameStakesSelection isSkeleton={isLoading} />
  </>
}

# GameListData
#  long gameInstanceId
#  int serverId
#  String gameName
#  boolean realMoney
#  int gameTypeId
#  int numPlayers
#  int maxPlayers
#  String limitType
#  BigDecimal ante
#  BigDecimal smallBlind
#  BigDecimal minBet
#  BigDecimal maxBet
#  BigDecimal averagePot
#  BigDecimal playersInFlopPct
#  BigDecimal handsPerHour
#  long gameListFlags
import Mixmaster from '../../../mixmaster.coffee'
import WclEnum from '../../../../enum/index.coffee'
import {isTypeIdSupported} from '../../../../../game-type.js'

export default class GameListData extends Mixmaster
  mixin: (object) ->
    object.game = (signal, game, node) ->
      return unless isTypeIdSupported(game.gameTypeId)

      if game.gameListFlags & 1048576
        speed = 'fast'
      else if game.gameListFlags & 16
        speed = 'turbo'
      else if game.gameListFlags & 262144
        speed = 'hyperTurbo'
      else if game.gameListFlags & 8388608
        speed = 'supersonic'
      else
        speed = 'regular'

      if game.gameListFlags & 1024
        priority = true
      else
        priority = false

      details =
        ante: parseFloat(game.ante)
        averagePot: parseFloat(game.averagePot)
        bigBlind: parseFloat(game.minBet)
        gameListFlags: game.gameListFlags
        handsPerHour: parseFloat(game.handsPerHour)
        instanceId: parseInt(game.instanceId)
        limitType: WclEnum.getLimitType(game.limitType)
        limitTypeDesc: WclEnum.getLimitTypeDesc(game.limitType)
        maxBet: parseFloat(game.maxBet)
        maxPlayers: parseInt(game.maxPlayers)
        minBet: parseFloat(game.minBet)
        gameName: game.gameName
        playerCount: parseInt(game.numPlayers)
        playersInFlopPct: game.playerInFlopPct
        realMoney: game.realMoney
        serverId: parseInt(game.serverId)
        smallBlind: parseFloat(game.smallBlind)
        speed: speed
        gameTypeId: parseInt(game.gameTypeId, 10)
        gameTypeDesc: WclEnum.getGameTypeDesc(parseInt(game.gameTypeId, 10))
        tournamentInstanceId: parseInt(game.tournamentInstanceId,10)
        currency: if game.tournamentInstanceId then '' else '$'
        style: game.style?.split '~'

      if game.gameState then Object.assign details,
        entry: parseFloat(game.entry)
        doubleUp: if game.gameListFlags & 65536 then true else false
        couponEntry: game.gameListFlags & 4194304
        gameState: game.gameState
        gameStateId: WclEnum.getGameStateId(game.gameState)
        registrationStartTime: @parseDate(game.registrationStartTime)
        startTime: @parseDate(game.startTime)
        isSatellite: Boolean(game.gameListFlags & 2097152)
        maxPlayersPerTable: parseInt(game.maxPlayersPerTable)
        priority: priority
        addPrizePoolToBuyins: game.addPrizePoolToBuyins

      # TEMP HACK: getTournament[Satellites/Parents]
      # When we don't have those listings (mtts or sngs) fetched yet
      # We need to create a listing for display in satellites modal
      if game.gameState then Object.assign details,
        isTournament: true
        isSng: if game.startTime then false else true
        isMtt: if game.startTime then true else false

      signal.games.push details

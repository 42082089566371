import React from 'react'

import AddChipsButton from './components/AddChipsButton.js'
import LeaveButton from './components/LeaveButton.js'
import SitInButton from './components/SitInButton.js'
import {useIsActiveSitOut, useIsTournament} from '../react-hooks.js'

export default function SittingOut () {
  const isActiveSitOut = useIsActiveSitOut()
  const isTournamentTable = useIsTournament()

  return <>
    <SitInButton />
    {!isActiveSitOut && !isTournamentTable && <>
      <AddChipsButton />
      <LeaveButton />
    </>}
  </>
}

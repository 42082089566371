export default ({signal, app, game, client, listing}) ->
  # if invalid username provided, re-prompt
  unless signal.success
    invalid = true
    app.updateNicknamePrompt(invalid)

 # debug 03-08 05:54 [outgoing] 000271<message type="REQUEST" service="AUTHENTICATION" routerId="0" serverId="0" instanceId="0" sessionId="218482162" clientId="0" requestTime="0" id="30"><function name="UPDATENICKNAME"><argument name="NICKNAME" code1="DATA" value1="SuperSaurus"/></function></message>

 # debug 03-08 05:54 [incoming] <message id="30" service="CLIENT" type="RESPONSE" sessionId="218482162" routerId="64" clientId="492988" serverId="0" instanceId="0"><function name="UPDATENICKNAME"><argument name="RESULT" code1="SUCCESS"/><argument name="NICKNAME" code1="DATA" value1="SuperSaurus"/></function></message>

 #  info 03-08 05:54 { message:
 #   { id: '30',
 #     service: 'client',
 #     type: 'response',
 #     session: '218482162',
 #     router: '64',
 #     client: '492988',
 #     server: '0',
 #     instanceId: 0,
 #     response: undefined },
 #  function: 'updateNickname',
 #  updates: { result: 'success', nickName: 'supersaurus' },
 #  games: [],
 #  players: [],
 #  parent:
 #   { messageId: 30,
 #     function: 'UPDATENICKNAME',
 #     attributes:
 #      { type: 'REQUEST',
 #        service: 'AUTHENTICATION',
 #        routerId: 0,
 #        serverId: 0,
 #        instanceId: 0,
 #        sessionId: '218482162',
 #        clientId: 0,
 #        requestTime: 0,
 #        id: 30 },
 #     arguments: { nickName: 'SuperSaurus' } },
 #  success: true }

 #  info 03-08 05:54 { message:
 #   { id: '30',
 #     service: 'client',
 #     type: 'response',
 #     session: '218482162',
 #     router: '64',
 #     client: '492988',
 #     server: '0',
 #     instanceId: 0,
 #     response: undefined },
 #  function: 'updateNickname',
 #  updates: { result: 'success', nickName: 'supersaurus' },
 #  games: [],
 #  players: [],
 #  parent:
 #   { messageId: 30,
 #     function: 'UPDATENICKNAME',
 #     attributes:
 #      { type: 'REQUEST',
 #        service: 'AUTHENTICATION',
 #        routerId: 0,
 #        serverId: 0,
 #        instanceId: 0,
 #        sessionId: '218482162',
 #        clientId: 0,
 #        requestTime: 0,
 #        id: 30 },
 #     arguments: { nickName: 'SuperSaurus' } },
 #  success: true }

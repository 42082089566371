import {
  BUY_IN_CONSTRAINTS_LOADED,
  RING_GAME_TABLE_LOADED,
  STAKES_LOADED,
} from '@pipehat/chronicle/constants/event-type'

import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  currency: '',
  maximumBuyIn: undefined,
  minimumBuyIn: undefined,
  stakes: undefined,
}

const bankSlice = createSlice({
  name: 'bank',
  initialState,
  extraReducers: {
    [STAKES_LOADED]: (state, {payload}) => {
      const {limitType, maximumStake} = payload
      state.stakes = {limitType, maximumStake}
    },

    [BUY_IN_CONSTRAINTS_LOADED]: (state, {payload}) => {
      state.minimumBuyIn = payload.minimumBuyIn
      state.maximumBuyIn = payload.maximumBuyIn
    },

    [RING_GAME_TABLE_LOADED]: (state, {payload}) => {
      state.currency = payload.tableCurrency
    },
  },
})

export default bankSlice.reducer

import React, {useCallback} from 'react'
import {useRouter} from 'react-router5'

import RadioOptionMenuItem from './RadioOptionMenuItem.js'
import {GAME_FORMAT} from '../../game-list/constants.js'
import {useGameFormat} from '../../game-list/react-hooks.js'
import {GAME_LIST} from '../../routes.js'

const {RING_GAME, SCHEDULED_TOURNAMENT, SITNGO_TOURNAMENT} = GAME_FORMAT

export default function MobileGameFormatSubMenu () {
  const router = useRouter()
  const gameFormat = useGameFormat()

  const handleNavRingGame = useCallback(() => {
    router.navigate(GAME_LIST, {s1: 'ringGames', s2: 'nlHoldem'})
  }, [router])

  const handleNavSNG = useCallback(() => {
    router.navigate(GAME_LIST, {s1: 'sitngo', s2: 'all'})
  }, [router])

  const handleNavScheduledTournament = useCallback(() => {
    router.navigate(GAME_LIST, {s1: 'tournaments', s2: 'holdem'})
  }, [router])

  return <>
    <div className='heading'>Game Type</div>

    <RadioOptionMenuItem
      isChecked={gameFormat === RING_GAME}
      label='Ring Games'
      onChange={handleNavRingGame}
    />
    <RadioOptionMenuItem
      isChecked={gameFormat === SITNGO_TOURNAMENT}
      label='Sit & Go'
      onChange={handleNavSNG}
    />
    <RadioOptionMenuItem
      isChecked={gameFormat === SCHEDULED_TOURNAMENT}
      label='Tournament'
      onChange={handleNavScheduledTournament}
    />
  </>
}

import Request from './request.coffee'

export default class ReserveRequest extends Request
  constructor: ({client, message, position}) ->
    super arguments...

    @function = 'RESERVE'

    @attributes =
      type: 'REQUEST'
      service: 'GAME'
      routerId: message.routerId
      serverId: client.serverId
      instanceId: client.instanceId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    if position?
      @arguments =
        seat: position

import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# handleGetPlayMoneyFailureResponse(String reasonCode)

export default class GetPlayMoneyTranslator extends Mixmaster
  mixins = [
    DefaultMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal
    signal.updates.getPlayMoney = {}

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  amount: (signal, name, value, node) ->
    signal.updates.getPlayMoney.amount = value

  balance: (signal, name, value, node) ->
    signal.updates.getPlayMoney.balance = value

import dayjs from 'dayjs'

import Model from './model.coffee'
import PlayerList from '../collections/playerList.coffee'

export default class Game extends Model
  constructor: ({details, players}) ->
    super()

    @properties =
      ante: details.ante ?= 0
      averagePot: details.averagePot ?= 0
      bigBlind: details.bigBlind ?= 0
      centerInformation: details.centerInformation ?= null
      community: details.community ?= []
      currentBet: details.currentBet ?= 0
      currentPlayer: details.currentPlayer ?= null
      currentState: details.currentState ?= null
      dealer: details.dealer ?= 0
      entry: details.entry ?= 0
      entryPayments: details.entryPayments ?= []
      emptySeats: details.emptySeats ?= []
      gameListFlags: details.gameListFlags ?= ''
      gameId: details.gameId ?= ''
      handsPerHour: details.handsPerHour ?= 0
      instanceId: details.instanceId ?= 0
      initialBuyIn: details.initialBuyIn ?= 0
      instance: details.instance ?= 0
      jackpotIsActive: details.jackpotIsActive ?= false
      jackpotBalance: details.jackpotBalance ?= null
      latency: details.latency ?= 0
      limitType: details.limitType ?= ''
      limitTypeDesc: details.limitTypeDesc ?= null
      maxBet: details.maxBet ?= 0
      maxBuyIn: details.maxBuyIn ?= null
      maxPlayers: details.maxPlayers ?= 2
      minBet: details.minBet ?= 0
      minBuyIn: details.minBuyIn ?= null
      minChipValue: details.minChipValue ?= 0.1
      gameName: details.gameName ?= ''
      numberPlayers: details.numberPlayers ?= { watching: 0, waitListLength: 0, playing: 0 }
      pinned: false
      playerCount: details.playerCount ?= 0
      playersInFlopPct: details.playersInFlopPct
      pots: details.pots ?= []
      potTotal: details.potTotal ?= 0
      precision: details.precision ?= 2 # decimals used in toMoney()
      rake: details.rake ?= 0
      realMoney: details.realMoney ?= false
      serverId: details.serverId ?= 0
      smallBlind: details.smallBlind ?= 0
      speed: details.speed ?= ''
      stakesDescription: details.stakesDescription ?= ''
      statistics: details.statistics ?= { dealt: 0, won: 0, flops: 0, wonAtShowdown: 0, wonAtPreflop: 0 }
      timebank: details.timebank ?= { enabled: false, available: false, balance: '0', minimum: '2' }
      tournamentId: details.tournamentId ?= 0
      visible: true
      winners: details.winners ?= null
      winningCards: details.winningCards ?= []
      currency: details.currency ?= '$'
      levelDetails: details.level?.details ?= null
      currentLevelDescription: details.level?.currentLevelDescription ?= null
      nextLevelDescription: details.level?.nextLevelDescription ?= null
      preferredDealerPosition: null
      gameTypeDesc: details.gameTypeDesc ?= { long: 'N/A', short: 'N/A'}
      reservations: details.reservations ?= 0
      nextLevelStartTime: details.nextLevelStartTime ?= null
      nextBreakStartTime: details.nextBreakStartTime ?= null

    # If preferred seating is on we set the dealers preferred
    Object.defineProperty this, 'dealerPosition',
      get: =>
        if @preferredDealerPosition?
          return @preferredDealerPosition
        else
          return @dealer

      # isTournament: details.isTournament ?= false
      # the following are directly the Tournament Details response (temp+static only)
      # note they are likely to change as the client proxy is updated and our own
      # translators are updates accordingly

    @handHistory = []

    @on 'change:pots', @betsToPot

    @players = players or new PlayerList
    @players.on 'playSound', @playSound

    # Modify playerCount property when ACTUAL players are added or removed
    @players.on 'add', =>
      @playerCount = @players.count
      if @reservations > 0 # don't go into negative
        @reservations--
    @players.on 'rem', => @playerCount = @players.count
    @players.on 'empty', => @playerCount = @players.count

    @tournament?.on 'change:nextLevelStartTime', (nlst) =>
      @nextLevelStartTime = nlst
    @tournament?.on 'change:nextBreakStartTime', (nlbt) =>
      @nextBreakStartTime = nlbt

    @initializeModel arguments...


  getPlayerByPosition: (position) =>
    return @players.find {position}

  joinGame: =>
    @emit 'action', 'joinGame'

  register: (paymentId) =>
    @emit 'action', 'register', {paymentId}

  # temp for ui testing
  registration: =>
    @emit 'registration', arguments...

  getDetails: =>
    @emit 'action', 'getTournamentDetails'

  clearTable: =>
    @players.each (player) ->
      player.cards = []
      player.cssClass = ''
      player.emit 'reset'

    @community = []
    @rake = 0
    @potTotal = 0
    @pots = []
    @winningCards = []

  start: =>
    @players.each (player) ->
      # Label and allInWin don't have to be changed because it only becomes
      # visisble when it is changed.
      player.cards = []
      player.cssClass = ''
      player.emit 'reset'

    @community = []
    @rake = 0
    @potTotal = 0
    @pots = []
    @winningCards = []
    @emit 'start'
    @gameInProgress = true

  end: =>
    @rake = 0
    @potTotal = 0
    @gameInProgress = false
    @emit 'end'
    history = @getCurrentHandHistory()
    history?.active = true

  breakdown: =>
    @players.empty()
    @community = []
    @rake = 0
    @potTotal = 0
    @pots = []
    @winningCards = []
    @emit 'breakdown'

  reservation: (position) =>
    @reservations++
    @emit 'reservation', position

  unreservation: (position) =>
    @reservations--
    @emit 'unreservation', position

  potToWinner: (potNumber, amount, position) =>
    @emit 'potToWinner', arguments...

  betsToPot: =>
    @players.each (player) ->
      player.betToPot()
      player.bet = 0

  betsToWinner: (winnerPosition) =>
    @players.each (player) ->
      player.betToWinner(winnerPosition)
      player.bet = 0

  getStakes: =>
    switch @limitType
      when 'noLimit'
        return "#{@smallBlind.toBuyinFormat()} / #{@minBet.toBuyinFormat()}"
      when 'fixedLimit'
        return "#{@minBet.toBuyinFormat()} / #{@maxBet.toBuyinFormat()}"
      when 'potLimit'
        return "#{@smallBlind.toBuyinFormat()} / #{@minBet.toBuyinFormat()}"

  full: =>
    return @playerCount is @maxPlayers

  playSound: (sound) =>
    @emit 'playSound', sound, @instanceId

  setupHandHistory: =>
    gameId = @gameId?.gameId or @gameId # tourneys vs ring games

    clientPlayer = @players.find {isClient:true}
    unless clientPlayer?.status is 'in'
      # console.warn 'ignoring handHistory for', gameId, 'because client is not seated/sitting-in'
      return

    item =
      gameId: gameId
      active: false
      history: []
    item.history.push message: gameId + ' - ' + @gameName
    item.history.push message: @gameTypeDesc.long + ' ' + @stakesDescription
    item.history.push message: dayjs(Webclient.Time).format('dddd D MMM YYYY h:mm:ss A')
    @handHistory.push item

    players = @players.toArray().sort ({position: left}, {position: right}) -> left > right ? 1 : left is right ? 0 : -1
    players.map (p, i) =>
      dealer = @dealer is p.position
      item.history.push
        message: (i+1) + '. ' + p.alias + (if dealer then ' (Dealer)' else '')

    # @handHistory = _.reject @handHistory, (i) -> i.active is false and i isnt item # clear inactive hands we weren't a part of
    # last 5 only (6 because current one is not displayed)
    if @handHistory.length > 6
      @handHistory.shift()

    @emit 'refreshHandHistory'

    return item

  updateHandHistory: ({message, format, state}) =>
    item = @getCurrentHandHistory()
    item ?= @setupHandHistory()
    return unless item

    if @currentState?.cards and @currentState?.previous
      state ?= @currentState.previous
    else
      state ?= @currentState?.current

    if state.toUpperCase() in ['SMALLBLIND', 'BIGBLIND', 'RETURNBLIND', 'INITIALBLIND', 'BLINDS']
      state = 'BLINDS'

    if state.toUpperCase() is 'ENDOFFOLDEDGAME'
      state = 'END OF FOLDED GAME'

    if state.toUpperCase() is 'ENDOFGAME'
      state = 'END OF GAME'

    if state.toUpperCase() is 'WAITINGFORPLAYERS'
      state = 'WAITING FOR PLAYERS'

    if state.toUpperCase() is 'GAMESTARTING'
      # console.warn 'dropping message from hand history because relevant game has not started yet:', message
      return

    if state and item
      item.history.push {message, format, state}

  updateHandHistoryBadBeat: (details, winners, community) =>

    unless @handHistory[@gameId]?
      return

    # ---Bad Beat Jackpot---
    # Winner: webclient4 (Two Pair Jacks and Fours) $0.00
    # Loser: webclient5 (Pair of Jacks) $0.00
    # Shared: botman2 $0.00
    # Shared: botman1 $0.00
    # Shared: botman3 $0.00

    state = @currentState?.current
    message = '---Bad Beat Jackpot---'
    format = 'badbeat'
    @handHistory[@gameId].push {message, format, state}

    format = 'event'
    winner = winners.find ({gameResultId}) -> gameResultId is 56
    message = "Winner: #{winner.nickName} (#{winner.handDescription}) #{winner.amount.toMoney()}"
    @handHistory[@gameId].push {message, format, state}

    loser =  winners.find ({gameResultId}) -> gameResultId is 57
    message = "Loser: #{loser.nickName} (#{loser.handDescription}) #{loser.amount.toMoney()}"
    @handHistory[@gameId].push {message, format, state}

    for winner in winners.filter ({gameResultId}) -> gameResultId is 58
      message = "Shared: #{winner.nickName} #{winner.amount.toMoney()}"
      @handHistory[@gameId].push {message, format, state}

  getCurrentHandHistory: =>
    gameId = @gameId?.gameId or @gameId # tourneys vs ring games
    return @handHistory.find (hand) -> hand.gameId is gameId

  showHandHistory: =>
    @emit 'showHandHistory', arguments...

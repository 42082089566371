import {useControls} from '../context.js'
import {useService} from '../react-hooks.js'

export const useIsTournament = () => useSelector(({isTournament}) => isTournament)
export const useLeaveGame = () => useService().leaveGame
export const useToggleOptions = () => useService().toggleOptions

function useSelector (selector) {
  return selector(useControls())
}

import React, {useCallback, useMemo} from 'react'

import {useRootedSelector} from '../../redux.js'
import {createGetSatelliteCount, getRoot} from '../index.js'
import {useShowSatellites} from '../react-hooks.js'

export default function Satellites (props) {
  const {tournamentId} = props

  const showSatellites = useShowSatellites(tournamentId)
  const satelliteCount = useRootedSelector(getRoot, useMemo(createGetSatelliteCount, []), props)

  const handleClick = useCallback(() => {
    if (satelliteCount) showSatellites()
  }, [satelliteCount, showSatellites])

  return <div className='pane pane__satellites'>
    <span className='icon-satellite' />
    <div>Satellite Tournaments</div>
    <span className='link small' onClick={handleClick}>Satellites to this tournament: <span>{satelliteCount}</span></span>
  </div>
}

import Request from './request.coffee'

export default class HeartbeatRequest extends Request
  constructor: ({message}) ->
    super arguments...
    @function = 'CHECK'

    @attributes =
      type: 'REQUEST'
      service: 'SYSTEM'
      routerId: message.routerId
      serverId: 0
      instanceId: 0
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

# Within clientProperties, for a token like say cashier.overview or cashier.mobile
# there is a property called browser.tokenname that has the following possible values:
# +------------------+
# | default_browser  |
# +------------------+
# | internal         |
# | external         |
# | lobby_browser    |
# | side_bar         |
# | internal|720|700 |
# | lobby_ticker     |
# | game_ticker      |
# +------------------+

export default ({signal, app, game, client, listing}) ->
  unless signal.success
    # console.warn 'getToken failed', signal.failReason
    return

  {token, keyId} = signal.updates.token

  # clear unused tokens. could potentially replace them with proper values in the future if required
  unusedTokens = [
    '%GAME_INSTANCE%'
    '%GAME_NUMBER%'
    '%GAME_TYPE_ID%'
    '%LANGUAGE_ID%'
    '%MACHINE_ID%'
  ]
  url = app.obUrl
  for t in unusedTokens
    url = url.replace t, ''
  url = url.replace '%TOKEN%', token
  url = url.replace '%URL%', keyId

  # if keyId in ['cashier.mobile', 'cashier.mobile.overview', 'cashier.mobile.deposit']
    # we don't respect the clientProperties browser for mobile because
    # opening in a new tab in iOS7 pinned app fails silently
    # Update 2014: iOS 7.0.3 respects <a target="_blank">. See http://stackoverflow.com/questions/18879631/how-to-open-safari-from-a-webapp-in-ios-7
  keys = [
      'cashier.mobile',
      'cashier.mobile.overview',
      'cashier.mobile.deposit',
      'lobby.externalSportsBet.mobile',
      'lobby.externalCasinoGames.mobile',
      'playerServices.support',
      'content.legal.eula',
      'content.legal.termsAndConditions',
      'content.legal.privacyPolicy',
      'content.legal.prohibitedSoftwarePolicy',
      'content.legal.licensingAndCertification',
    ]
  if keyId in keys
    # the target:url is a hack for window.open failing to work in pinned ios websites
    app.notice
      title: 'Notice'
      content: 'This link will open in a new window'
      choices: [
        {value:'Cancel', icon: 'times'}
        {
          value: 'OK'
          icon: 'check'
          target: url
        }
      ]
  else
    if keyId is 'lobby.sidebar'
      app.lobbySidebarUrl = url
    else
      window.open url

import {
  ACTIVE_SEATS_LOADED,
  TIME_BANK_AVAILABILITY_LOADED,
  TIME_BANK_LOADED,
  TIME_BANK_STATUS_LOADED,
  OWN_SEAT_LOADED,
} from '@pipehat/chronicle/constants/event-type'

import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  activeSeats: [],
  areAvailable: false,
  balance: 0,
  isEnabled: false,
  isRunning: false,
  isUsable: false,
  minimumBalance: 0,
  ownSeat: 0,
}

const timeBankSlice = createSlice({
  name: 'timeBank',
  initialState,
  extraReducers: {
    [TIME_BANK_AVAILABILITY_LOADED]: (state, {payload}) => {
      state.areAvailable = payload.areAvailable
    },

    [TIME_BANK_STATUS_LOADED]: (state, {payload}) => {
      state.isEnabled = payload.isEnabled
    },

    [TIME_BANK_LOADED]: (state, {payload}) => {
      state.balance = payload.balance
      state.isUsable = payload.isUsable
      state.minimumBalance = payload.minimumBalance
    },

    [ACTIVE_SEATS_LOADED]: (state, {payload}) => {
      state.isRunning = Boolean(payload.isTimeBank)
      state.activeSeats = payload.seatNumbers
    },

    [OWN_SEAT_LOADED]: (state, {payload}) => {
      state.ownSeat = payload.seatNumber
    },
  },
})

export default timeBankSlice.reducer

import {CALL} from '@pipehat/chronicle/constants/action'
import React from 'react'

import {renderMoney} from '../../../../money/rendering.js'
import styles from '../../Actions.module.css'
import ActionButton from '../../components/ActionButton.js'
import {useCallBet, useIntendedActionName, useSelfActions} from '../../react-hooks.js'

export default function CallBetButton () {
  const {minimumBet} = useSelfActions()
  const isIntendedAction = useIntendedActionName() === CALL

  return <ActionButton className={styles.call} onClick={useCallBet()} isIntendedAction={isIntendedAction}>
    Call {renderMoney(minimumBet)}
  </ActionButton>
}

import React from 'react'
import {Link} from 'react-router5'

import MenuItem from './MenuItem.js'

export default function LinkMenuItem (props) {
  const {icon, label, routeName} = props

  return <Link routeName={routeName}>
    <MenuItem label={label} icon={icon} />
  </Link>
}

import React from 'react'

import OwnRingGamesRow from './OwnRingGamesRow.js'
import Caption from '../../components/game-listing/table/Caption.js'
import Header from '../../components/game-listing/table/Header.js'
import HeaderGroup from '../../components/game-listing/table/HeaderGroup.js'
import Row from '../../components/game-listing/table/Row.js'
import RowGroup from '../../components/game-listing/table/RowGroup.js'
import Table from '../../components/game-listing/table/Table.js'
import styles from '../../components/game-listing/table/Table.module.css'

const EMPTY_ID = 'own-games-empty-ring-games'

export default function OwnRingGamesList (props) {
  const {ringGames} = props
  const hasEntries = ringGames.length > 0
  const captionId = !hasEntries ? EMPTY_ID : undefined

  return <span>
    <Table ariaLabelledby={captionId}>
      <HeaderGroup>
        <Row>
          <Header label='Seats' />
          <Header label='Room' className={styles.primary} />
          <Header label='Game' />
          <Header label='Stakes' />
          <Header label='Status' />
          <Header label='Balance' />
          <Header label='Plrs' />
        </Row>
      </HeaderGroup>

      {hasEntries && <RowGroup>
        {ringGames.map(ringGame => {
          return <OwnRingGamesRow key={ringGame.tableId} ringGame={ringGame} />
        })}
      </RowGroup>}

      {!hasEntries && <Caption id={captionId}>No games to show</Caption>}
    </Table>
  </span>
}

import React from 'react'

import DesktopSelectionButton from '../../components/DesktopSelectionButton.js'
import {useSetTournamentType, useTournamentType} from '../react-hooks.js'

export default function TournamentTypeButton (props) {
  const {label, tournamentType} = props
  const isSelected = useTournamentType() === tournamentType

  return <DesktopSelectionButton label={label} isSelected={isSelected} onClick={useSetTournamentType(tournamentType)} />
}

import classnames from 'classnames'
import React from 'react'

import LogNickname from './LogNickname.js'

export default function LogEntry (props) {
  const {entry: {format, message, nickname}} = props

  const chatClassName = classnames('chat-log-entry', {[format]: format})

  return <p className={chatClassName}>
    {nickname && <LogNickname nickname={nickname} />}
    {message}
  </p>
}

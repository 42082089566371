# ActiveBonusesView
# Model: app

import dayjs from 'dayjs'
import isDate from 'lodash/isDate'

import View from './view.coffee'
import {serverTimestampToIso} from '../../time/index.js'

export default class ActiveBonusesView extends View

  constructor: (app) ->
    super()

    @model = app
　
    @dom = $ '<div>'

    # ### Components - eg. DOM elements & views
    @components =
      playthroughHeading: $ "<h2>Playthrough Bonuses</h2>"
      playthroughInfo: $ "<p>Your playthrough bonuses are listed in the table below. Only one playthrough bonus can be active at a time. You can change the active bonus by using the up and down arrows to change the order of your bonus queue.</p>"
      playthroughTable: $ """<table class='generic-modal-table'><thead><tr>
        <th>Name</th>
        <th class='progress-bar-column'>Progress</th>
        <th>Issued</th>
        <th></th> </tr></thead><tbody class='active'></tbody><tbody class='complete'></tbody></table>"""
      timeBasedHeading: $ "<h2>Time-based Bonuses</h2>"
      timeBasedInfo: $ "<p>Your time-based bonuses are listed in the table below. All time-based bonuses are active.</p>"
      timeBasedTable: $ "<table class='generic-modal-table'><thead><tr> <th>Name</th> <th>Progress</th> <th>Issued</th></tr></thead><tbody></tbody></table>"

    @components.playthroughTable.on 'click', 'button.move-up', @moveUp
    @components.playthroughTable.on 'click', 'button.move-down', @moveDown

    @playThroughRowTemplate = (row) ->
      """
        <tr data-id="#{row.id}" class="#{row.status}">
          <td>
            <div>#{row.name}</div>
            <div class="subtext status">#{row.statusDisplay}</div>
          </td>
          <td class="progress-bar">
            <div class="progress-bar-meter" style="width: #{row.playthroughPercent}%"></div>
            <div class="progress-bar-label">
              #{row.playthroughPercent}% - #{row.playthroughValue}
              <br>
              <span class="subtext">#{row.awarded} of #{row.value} awarded</span>
            </div>
          </td>
          <td>
            <div class="date">#{row.date}</div>
            <div class="subtext">#{row.time}</div>
          </td>
          <td>
            <button class="button move-up"><i class="fa fa-angle-up"></i></button>
            <button class="button move-down"><i class="fa fa-angle-down"></i></button>
          </td>
        </tr>
      """

    @timeBasedRowTemplate = (row) ->
      """
        <tr>
          <td>
            <div>#{row.name}</div>
            <div class="subtext">#{row.status}</div>
          </td>
          <td>
            <div>#{row.completionDate} remain</div>
            <div class="subtext">#{row.awarded} of #{row.value} awarded</div>
          </td>
          <td>
            <div class="date">#{row.date}</div>
            <div class="subtext">#{row.time}</div>
          </td>
        </tr>
      """

    # ### Events - eg. MouseClicks ###

    # ### Listeners - eg. auth:success

    # ### Changes - eg. Model.property = true

    # ### Binding ###
    @initializeView()
    return this

  render: =>
    super()

    # id, code, name, value, awarded, issuedDate, expiresDate, status, playthroughStatus, playthroughType, playthroughValue, playthroughOrder, completionDate, playthroughPercent
    bonuses = @model.activeBonuses

    playThrough = bonuses.filter ({playthroughType}) -> playthroughType isnt "days"
    playThrough = playThrough.sort ({playthroughOrder: left}, {playthroughOrder: right}) -> left > right ? 1 : left is right ? 0 : -1
    playThrough = playThrough.forEach (bonus) =>
      [d, t...] = bonus.issuedDate.split ' '
      bonus.date = d
      bonus.time = t.join ' '
      if bonus.playthroughValue is 'null' # yes, string
        bonus.playthroughValue = 'Completed'
      else if bonus.playthroughType is 'raked_hands'
        bonus.playthroughValue = Math.round(bonus.playthroughValue) + ' raked games remaining'
      else
        bonus.playthroughValue += ' Points Remaining'
      bonus.awarded = bonus.awarded.toMoney()
      bonus.value = bonus.value.toMoney()

      # only one bonus can be active but we receive them all as being active.
      if bonus.status is 'active'
        if parseInt(bonus.playthroughOrder) is 1 #first
          bonus.statusDisplay = 'Active'
        else
          bonus.statusDisplay = 'Queued'
      else if bonus.status is 'complete'
        bonus.statusDisplay = 'Complete'
      else
        bonus.statusDisplay = bonus.status

      @components.playthroughTable.find('tbody.' + bonus.status)?.append @playThroughRowTemplate bonus

    timeBased = bonuses.filter ({playthroughType}) -> playthroughType is "days"
    timeBased = timeBased.map (bonus, i) ->
      [d, t...] = bonus.issuedDate.split ' '
      bonus.date = d
      bonus.time = t.join ' '
      if isDate bonus.completionDate
        m = dayjs(bonus.completionDate)
      else # translator has not parsed yet
        m = dayjs(serverTimestampToIso(bonus.completionDate))
      bonus.completionDate = m.fromNow()
        .replace(/in /gi, '')
        .replace(/hrs/gi, 'hours')
      bonus.order = m.toISOString()
      return bonus
    timeBased = timeBased.sort ({order: left}, {order: right}) -> left > right ? 1 : left is right ? 0 : -1
    @components.timeBasedTable.find('tbody').html timeBased.map(@timeBasedRowTemplate).join('')

    return @dom

  moveUp: (e) =>
    row = $(e.target).closest('tbody tr')
    row.after row.prev('tr')
    @updateOrder()

  moveDown: (e) =>
    row = $(e.target).closest('tbody tr')
    row.before row.next('tr')
    @updateOrder()

  updateOrder: =>
    coupons = {}
    @components.playthroughTable.find('tbody tr').each (i) -> # should be ordered hopefullyfa-arrow-down
      {id} = $(this).data()
      coupons[id] = i + 1
    # console.log coupons
    @model.message 'updateCoupons', {coupons}

import endOfGameState from '../states/endOfGameState.coffee'
import gameStartingState from '../states/gameStartingState.coffee'
import waitingForPlayersState from '../states/waitingForPlayersState.coffee'
import postFlopState from '../states/postFlopState.coffee'

States =
  endOfGame: endOfGameState
  gameStarting: gameStartingState
  waitingForPlayers: waitingForPlayersState
  postFlop: postFlopState

export default ({signal, details, app, game, client, listing, states}) ->
  states ?= States

  unless game?
    return

  # WCL-1786
  if game.currentState.current in ['preflop', 'bigBlind', 'smallBlind'] and details.current is 'endOfFoldedGame'
    details.preflopFold = true

  game.currentState = state = details

  switch state.current
    when 'waitingForTournamentStart'
      client.centerInformation = message: "Waiting for tournament to start"
    when 'waitingForPlayers'
      states.waitingForPlayers {signal, app, game, client, state}
    when 'gameStarting'
      states.gameStarting {signal, app, game, client, state}
    when 'endOfGame', 'endOfFoldedGame', 'gameCancelled'
      states.endOfGame {signal, app, game, client, state}
    when 'postFlop'
      states.postFlop {signal, app, game, client, state}
    else
      client.centerInformation = {message: ''}

import React, {useEffect} from 'react'

import RadioOptionMenuItem from './RadioOptionMenuItem.js'

import {
  useSortSeatAsc,
  useIsSeatAsc,
  useIsStakesAsc,
  useIsStakesDesc,
  useSetStakesAsc,
  useSetStakesDesc,
  useIsDirectionEmpty,
} from '../../game-list/ring-game/react-hooks.js'

export default function MobileRingGameSortSubMenu () {
  const isSeatAsc = useIsSeatAsc()
  const isStakesAsc = useIsStakesAsc()
  const isStakesDesc = useIsStakesDesc()
  const isDirectionEmpty = useIsDirectionEmpty()

  const isCustom = !isSeatAsc && !isStakesAsc && !isStakesDesc && !isDirectionEmpty
  const sortSeatAsc = useSortSeatAsc()

  useEffect(() => {
    if (isDirectionEmpty) sortSeatAsc()
  }, [isDirectionEmpty, sortSeatAsc])

  return <>
    <RadioOptionMenuItem
      isChecked={isSeatAsc}
      label='Available Seat'
      onChange={useSortSeatAsc()}
    />
    <RadioOptionMenuItem
      isChecked={isStakesAsc}
      label='Stakes: High to Low'
      onChange={useSetStakesAsc()}
    />
    <RadioOptionMenuItem
      isChecked={isStakesDesc}
      label='Stakes: Low to High'
      onChange={useSetStakesDesc()}
    />
    {isCustom && <RadioOptionMenuItem isChecked label='Custom' readOnly />}
  </>
}

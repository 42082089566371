import React from 'react'

import Indicator from './Indicator.js'
import styles from './Indicators.module.css'

export default function Pinned (props) {
  const {isPinned} = props
  const className = isPinned ? styles.pinned : styles.unpinned
  const title = isPinned ? 'Pinned' : undefined

  return <Indicator className={className} icon='thumb-tack' title={title} />
}

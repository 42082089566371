import dayjs from 'dayjs'

import Model from './model.coffee'

export default class User extends Model

  constructor: (@settings, obUrl) ->
    super()

    loginSupportLink = obUrl
      .replace '%TOKEN%', 'unauthenticated'
      .replace '%URL%', 'playerServices.account.resetPassword'

    @properties =
      authenticated: false
      username: undefined
      password: undefined
      clientDataFeed: {}
      realMoney: 0
      playMoney: 0
      nickname: ''
      tagList: {}
      loginSupportLink: loginSupportLink
      attemptsRemaining: 4
      authAttempts: []

    @initializeModel arguments...

  connected: =>
    @emit 'connected'

  auth: =>
    credentials =
      hash: @hash
      fingerprint: 'webclientFingerprintFailure'

    @authAttempts.push
      type: 'request'
      time: dayjs(Webclient.Time).format('HH:mm:ss')
      username: @username

    credentials.username = @username
    credentials.password = @password

    @emit 'auth', credentials

  setTags: (tagList) =>
    @tagList = tagList

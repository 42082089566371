import StatusMessageView from './statusMessageView.coffee'
import View from './view.coffee'

export default class MaintenanceView extends View
  constructor: (app) ->
    super()

    @model = app

    @dom = $ '<div id="maintenance" class="fullscreen-container">'

    @components =
      scroll:       $ '<div class="scroll-container">'
      logo:         $ '<div class="dynamic-logo medium">'
      status:       new StatusMessageView @model
      reload:       $ '<button class="button button-cta">Reload</button>'
      serviceLink:  $ '<div class="maint-service-link"><a href="https://twitter.com/MergeGaming" class="link">Public Service Status Page</a></div>'

    @listeners =
      'maintenance': @setMaintEndTime

    @components.status.title('Under Maintenance')
    @components.reload.on 'click', @restart

    @initializeView()
    return this

  render: ->
    @dom.empty()
    @dom.append @components.scroll

    @components.scroll.append [
      @components.logo,
      @components.status.render(),
      @components.reload,
      @components.serviceLink,
    ]

    @dom.hide()
    @dom

  setMaintEndTime: (endTime) =>
    endTime = "Estimated end time: #{endTime.toLocaleString()}" if endTime
    @components.status.message(endTime)

  restart: =>
    @model.leaveWaitlists()
    window.setTimeout (-> window.location.reload()), 10

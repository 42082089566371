import {OMAHA_HOLDEM, OMAHA_HOLDEM_HILO, TEXAS_HOLDEM} from '@pipehat/chronicle/constants/game-variant'
import React from 'react'

import VariantButton from './VariantButton.js'

export default function VariantSelection () {
  return <ul>
    <li><VariantButton label="Texas Hold'em" variant={TEXAS_HOLDEM} /></li>
    <li><VariantButton label='Omaha' variant={OMAHA_HOLDEM} /></li>
    <li><VariantButton label='Omaha Hi/Lo' variant={OMAHA_HOLDEM_HILO} /></li>
  </ul>
}

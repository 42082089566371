import {CHAT_AVAILABILITY_LOADED} from '@pipehat/chronicle/constants/event-type'
import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  isAvailable: false,
  isExpanded: false,
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    collapseChat (state) {
      state.isExpanded = false
    },
    expandChat (state) {
      state.isExpanded = true
    },
  },
  extraReducers: {
    [CHAT_AVAILABILITY_LOADED]: (state, {payload}) => {
      state.isAvailable = payload.isAvailable
    },
  },
})

export const {collapseChat, expandChat} = chatSlice.actions

export default chatSlice.reducer

import React from 'react'

import {useIsSeated} from '../../seats/react-hooks.js'

export default function UnavailableWarning () {
  const isSeated = useIsSeated()
  const description = isSeated ? 'Chat currently not available' : 'Chat not available until seated'

  return <div className="chat-unavailable-warning">{description}</div>
}

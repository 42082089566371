import {useContext, useEffect, useState} from 'react'

import {context} from '../react.js'

export function useWindowTitle (windowTitle) {
  const {setWindowTitle} = useService()

  useEffect(() => {
    setWindowTitle(windowTitle)
  }, [setWindowTitle, windowTitle])
}

export function useIsAuthenticated () {
  const {isAuthenticated, subscribeToIsAuthenticated} = useService()
  const [reactValue, setReactValue] = useState(isAuthenticated)

  useEffect(() => {
    return subscribeToIsAuthenticated(isAuthenticated => {
      setReactValue(isAuthenticated)
    })
  }, [subscribeToIsAuthenticated])

  return reactValue
}

function useService () {
  return useContext(context).globalService
}

# TournamentTableData
# gameName: "TestCase2"
# gameNumber: "1"
# gameTypeId: 23
# isMtt: false
# isSng: true
# registrationRequired: false
# tableInstanceId: 8278869
# tournamentId: 8278868
import {isTypeIdSupported} from '../../../../game-type.js'

export default ({signal, app}) ->
  return unless isTypeIdSupported(signal.tournamentTableData.gameTypeId)

  tournament = app.listings.find {instanceId: signal.tournamentTableData.tournamentId}
  tournament?.playing = true

  app.instances.push signal.tournamentTableData.tableInstanceId # if they choose not to join, don't force them

  signal.tournamentTableData.serverId = signal.message.serverId

  # app.tournamentStart signal.tournamentTableData
  if signal.tournamentTableData.lateRegistration
    # app.clients.leave()
    app.joinGame
      instanceId: signal.tournamentTableData.tableInstanceId
      serverId: signal.tournamentTableData.serverId
    , true
  else if tournament?
    #if we have the tournament its probably not a rematch
    app.tournamentStart signal.tournamentTableData
  else
    app.clients.leave() # force leave existing clients. because on rematch we still have old table open
    app.joinGame
      serverId: signal.message.serverId
      instanceId: signal.tournamentTableData.tableInstanceId

import classnames from 'classnames'
import React, {useCallback, useMemo} from 'react'

import Money from '../../money/components/Money.js'
import {useRootedSelector} from '../../redux.js'

import {
  createGetPlacesPaidCount,
  createGetPrizeAccoladeId,
  createGetPrizeOverlay,
  createGetPrizePool,
  createGetSatelliteFollowOnCount,
  getRoot,
} from '../index.js'

import {useShowSatelliteFollowOnTournaments} from '../react-hooks.js'

export default function PrizeSummary (props) {
  const {tournamentId} = props

  const showSatelliteFollowOnTournaments = useShowSatelliteFollowOnTournaments(tournamentId)
  const accoladeId = useRootedSelector(getRoot, useMemo(createGetPrizeAccoladeId, []), props)
  const placesPaid = useRootedSelector(getRoot, useMemo(createGetPlacesPaidCount, []), props)
  const satelliteFollowOnCount = useRootedSelector(getRoot, useMemo(createGetSatelliteFollowOnCount, []), props)
  const pool = useRootedSelector(getRoot, useMemo(createGetPrizePool, []), props)
  const overlay = useRootedSelector(getRoot, useMemo(createGetPrizeOverlay, []), props)

  const handleClick = useCallback(() => {
    if (satelliteFollowOnCount) showSatelliteFollowOnTournaments()
  }, [satelliteFollowOnCount, showSatelliteFollowOnTournaments])

  return <div className='pane pane__summary'>
    {accoladeId > 0 && <div className={classnames('tournament-accolade', `accolade-${accoladeId}`)} />}
    <div className='kvline'><label>Total Prizepool:</label>{pool && <Money value={pool} />}</div>
    <div className='small'>Overlay: {overlay && <Money value={overlay} />}</div>
    <div className='small'>{placesPaid} Places Paid</div>
    <span className='link small selfclear' onClick={handleClick}>
      Win Entry to <span>{satelliteFollowOnCount}</span> Tournaments
    </span>
  </div>
}

import React, {useMemo} from 'react'

import Money from '../../../money/components/Money.js'
import {useRootedSelector} from '../../../redux.js'
import {formatDateTime} from '../../../time/index.js'

import {
  createGetEndTime,
  createGetEntryCost,
  createGetMaximumRegistrants,
  createGetMinimumRegistrants,
  createGetStartTime,
  createHasStarted,
  createIsAnnounced,
  createIsFinished,
  createIsRegistering,
  getRoot,
} from '../../index.js'

export default function Main (props) {
  const entryCost = useRootedSelector(getRoot, useMemo(createGetEntryCost, []), props)
  const hasStarted = useRootedSelector(getRoot, useMemo(createHasStarted, []), props)
  const isAnnounced = useRootedSelector(getRoot, useMemo(createIsAnnounced, []), props)
  const isFinished = useRootedSelector(getRoot, useMemo(createIsFinished, []), props)
  const isRegistering = useRootedSelector(getRoot, useMemo(createIsRegistering, []), props)
  const endTime = useRootedSelector(getRoot, useMemo(createGetEndTime, []), props)
  const maximumRegistrants = useRootedSelector(getRoot, useMemo(createGetMaximumRegistrants, []), props)
  const minimumRegistrants = useRootedSelector(getRoot, useMemo(createGetMinimumRegistrants, []), props)
  const startTime = useRootedSelector(getRoot, useMemo(createGetStartTime, []), props)

  const shouldShowStarts = (isAnnounced || isRegistering) && startTime
  const shouldShowStarted = (hasStarted || isFinished) && startTime
  const shouldShowEnded = isFinished && endTime

  return <>
    {shouldShowStarts && <div className='kvpair rightAlign'>
      <label>Tournament Starts</label>
      <span>{formatDateTime(new Date(startTime))}</span>
    </div>}

    {shouldShowStarted && <div className='kvpair rightAlign'>
      <label>Tournament Started</label>
      <span>{formatDateTime(new Date(startTime))}</span>
    </div>}

    {shouldShowEnded && <div className='kvpair rightAlign'>
      <label>Tournament Ended</label>
      <span>{formatDateTime(new Date(endTime))}</span>
    </div>}

    <div className='kvpair rightAlign'>
      <label>Buy-In Amount</label>
      <span>{entryCost ? <Money value={entryCost} /> : '$0'}</span>
    </div>

    <div className='kvpair rightAlign'>
      <label>Min / Max Players</label>
      <span>{minimumRegistrants} / {maximumRegistrants}</span>
    </div>
  </>
}

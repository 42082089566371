import Request from './request.coffee'

export default class GetRingGamesRequest extends Request
  constructor: ({message, casinoId, listType, playMoney}) ->
    super arguments...

    @function = 'GET_RING_GAMES'

    @attributes =
      type: 'REQUEST'
      service: 'GAMELIST'
      routerId: message.routerId
      serverId: 0
      instanceId: 0
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @arguments =
      casino_id: casinoId
      class_id: message.classId
      list_type: listType #or 'Texas Holdem Fixed Limit,Texas Holdem No Limit'
      money_type: if playMoney then 'P' else 'R'
      tag_list: message.tagList.playerTagsString

import {useContext} from 'react'

import {context} from '../react.js'

export function useConfirmRegistration (tournamentId) {
  return useService(tournamentId).confirmRegistration
}

export function useDeregister (tournamentId) {
  return useService(tournamentId).deregister
}

export function useLoad (tournamentId) {
  return useService(tournamentId).load
}

export function useLoadParticipants (tournamentId) {
  return useService(tournamentId).loadParticipants
}

export function useRegister (tournamentId) {
  return useService(tournamentId).register
}

export function useShowBountyModal (tournamentId) {
  return useService(tournamentId).showBountyModal
}

export function useShowCoupons (tournamentId) {
  return useService(tournamentId).showCoupons
}

export function useShowLevelStructure (tournamentId) {
  return useService(tournamentId).showLevelStructure
}

export function useShowSatelliteFollowOnTournaments (tournamentId) {
  return useService(tournamentId).showSatelliteFollowOnTournaments
}

export function useShowSatellites (tournamentId) {
  return useService(tournamentId).showSatellites
}

function useService (tournamentId) {
  return useContext(context).createTournamentService(tournamentId)
}

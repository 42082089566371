import React from 'react'

import SortableHeader from '../../../components/game-listing/table/SortableHeader.js'
import {useSortDirection, useToggleSort} from '../react-hooks.js'

export default function ListingHeader (props) {
  const {className, label, sortKey} = props

  return <SortableHeader
    className={className}
    label={label}
    direction={useSortDirection(sortKey)}
    onClick={useToggleSort(sortKey)}
  />
}

import {HANDS, TIME} from '@pipehat/chronicle/constants/interval-unit'

import {
  CANCELED,
  FINISHED,
  IN_PROGRESS,
  LATE_REGISTRATION,
  SHUT_DOWN,
  SHUT_DOWN_REFUNDED,
} from '@pipehat/chronicle/constants/tournament-status'

import {renderMoney} from '../money/rendering.js'
import {formatCountdown, fuzzDuration} from '../time/index.js'
import {ONE_MINUTE} from '../time/time-amount.js'

const closedStatuses = [CANCELED, FINISHED, IN_PROGRESS, SHUT_DOWN, SHUT_DOWN_REFUNDED]

export function renderCurrentBreakEnds (now, isOnBreak, breakEndTime) {
  if (!isOnBreak || !breakEndTime) return ''

  return formatCountdown(fuzzDuration(new Date(breakEndTime).valueOf() - now.valueOf()))
}

export function renderLevelsDescription ({amount, unit}) {
  if (unit === HANDS) return `${amount} hands`
  if (unit === TIME) return `${Math.floor(amount / ONE_MINUTE)} minutes`

  return '???'
}

export function renderNextBreakStarts (now, isOnBreak, nextBreakTime) {
  if (isOnBreak || !nextBreakTime) return ''

  return formatCountdown(fuzzDuration(new Date(nextBreakTime).valueOf() - now.valueOf()))
}

export function renderLateRegistration (
  now,
  status,
  registrationCloseTime,
  isLateRegEnabled,
  isLateRegStopOnElimination,
  lateRegDuration,
) {
  if (!isLateRegEnabled) return 'Not Available'
  if (closedStatuses.includes(status)) return 'Closed'

  const isLateRegOpen = status === LATE_REGISTRATION

  if (lateRegDuration === 0 && !isLateRegStopOnElimination) {
    return isLateRegOpen ? 'Open' : 'Open upon start'
  }

  const parts = []

  if (lateRegDuration > 0) {
    const remaining = (() => {
      if (!isLateRegOpen) return lateRegDuration

      const lateRegCloseTime = new Date(new Date(registrationCloseTime).valueOf() + lateRegDuration)

      return lateRegCloseTime.valueOf() - now.valueOf()
    })()

    parts.push(formatCountdown(fuzzDuration(remaining)))

    if (isLateRegOpen) parts.push('left')
    if (isLateRegStopOnElimination) parts.push('OR')
  }

  if (isLateRegStopOnElimination) parts.push('Until first elimination')

  return parts.join(' ')
}

export function renderNextLevel (now, nextLevel, nextLevelTime, endTime) {
  if (!nextLevel) return 'N/A'
  if (!nextLevelTime) return String(nextLevel.number)

  const diffTimePoint = endTime ? new Date(endTime) : now

  return formatCountdown(fuzzDuration(new Date(nextLevelTime).valueOf() - diffTimePoint.valueOf()))
}

export function renderRunningTime (now, startTime, endTime) {
  const started = startTime ? new Date(startTime) : now
  const diffTimePoint = endTime ? new Date(endTime) : now

  return formatCountdown(fuzzDuration(diffTimePoint.valueOf() - started.valueOf()))
}

export function renderStartsIn (now, startTime, minParticipants, currentParticipants) {
  if (startTime) return formatCountdown(fuzzDuration(new Date(startTime).valueOf() - now.valueOf()))

  return currentParticipants < minParticipants ? `When ${minParticipants - currentParticipants} Players Join` : 'Now'
}

export function renderTextForEntryPayment (paymentData, hasEligibilityProfile) {
  const {bounty, buyIn, coupon, eligibilityProfile, fee} = paymentData
  const parts = []

  if (hasEligibilityProfile || eligibilityProfile) parts.push('Meet Requirement')
  if (coupon) parts.push(coupon.name)
  if (buyIn.cash) parts.push(renderMoney(buyIn.cash))
  if (bounty.cash) parts.push(`${renderMoney(bounty.cash)} Knockout`)
  if (fee.cash) parts.push(`${renderMoney(fee.cash)} Fee`)

  return parts.join(' + ')
}

import {ONE_DAY, ONE_HOUR, ONE_MINUTE, ONE_SECOND, TEN_SECONDS} from './time-amount.js'
import {renderOrdinal} from '../rendering.js'

const MONTH_NAMES_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export function describeDate (clock, date) {
  const now = clock.now()

  const [dateYear, dateMonth, dateDate] = dateComponents(date)
  const [todayYear, todayMonth, todayDate] = dateComponents(now)
  const isToday = dateYear === todayYear && dateMonth === todayMonth && dateDate === todayDate

  if (isToday) return 'Today'

  const tomorrow = new Date(now)
  tomorrow.setDate(todayDate + 1)

  const [tomorrowYear, tomorrowMonth, tomorrowDate] = dateComponents(tomorrow)
  const isTomorrow = dateYear === tomorrowYear && dateMonth === tomorrowMonth && dateDate === tomorrowDate

  if (isTomorrow) return 'Tmrw'

  return `${MONTH_NAMES_SHORT[dateMonth]} ${dateDate}`
}

export function describeTime (clock, date) {
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  return `${describeDate(clock, date)} ${hours}:${minutes}`
}

export function formatCountdown (value) {
  if (value <= TEN_SECONDS) return '<10s'

  const parts = []
  let remainder = value

  if (parts.length < 2 && value >= ONE_DAY) {
    const days = Math.floor(remainder / ONE_DAY)
    remainder -= days * ONE_DAY

    parts.push(`${days}d`)
  }

  if (parts.length < 2 && value >= ONE_HOUR) {
    const hours = Math.floor(remainder / ONE_HOUR)
    remainder -= hours * ONE_HOUR

    if (parts.length > 0) {
      parts.push(`${String(hours).padStart(2, '0')}h`)
    } else {
      parts.push(`${hours}h`)
    }
  }

  if (parts.length < 2 && value >= ONE_MINUTE) {
    const minutes = Math.floor(remainder / ONE_MINUTE)
    remainder -= minutes * ONE_MINUTE

    if (parts.length > 0) {
      parts.push(`${String(minutes).padStart(2, '0')}m`)
    } else {
      parts.push(`${minutes}m`)
    }
  }

  if (parts.length < 2) {
    const seconds = Math.floor(remainder / ONE_SECOND)

    parts.push(`${String(seconds).padStart(2, '0')}s`)
  }

  return parts.join('')
}

export function format24HourTime (date) {
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  return `${hours}:${minutes}`
}

export function formatDateTime (date) {
  const hour24 = date.getHours()
  const monthName = MONTH_NAMES_SHORT[date.getMonth()]
  const day = date.getDate()
  const hour = (hour24 % 12) || 12
  const minute = String(date.getMinutes()).padStart(2, '0')
  const period = hour24 < 12 ? 'am' : 'pm'

  return `${monthName} ${renderOrdinal(day)} ${hour}:${minute}${period}`
}

export function fuzzDuration (duration) {
  const mod = duration % TEN_SECONDS

  return Math.max(0, duration - mod) + (mod ? TEN_SECONDS : 0)
}

const LEGACY_TIMESTAMP_PATTERN = /^\d{14}$/

export function serverTimestampToIso (timestamp) {
  if (LEGACY_TIMESTAMP_PATTERN.test(timestamp)) {
    const year = timestamp.substring(0, 4)
    const month = timestamp.substring(4, 6)
    const day = timestamp.substring(6, 8)
    const hour = timestamp.substring(8, 10)
    const minute = timestamp.substring(10, 12)
    const second = timestamp.substring(12, 14)

    return `${year}-${month}-${day}T${hour}:${minute}:${second}-05:00`
  }

  return `${timestamp}-05:00`
}

function dateComponents (date) {
  return [
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
  ]
}

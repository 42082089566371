import {useEffect, useRef} from 'react'

export function useInterval (delay, callback) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick () {
      savedCallback.current()
    }

    const id = setInterval(tick, delay)

    return () => clearInterval(id)
  }, [delay])
}

import {LIST_TYPE_MAP, SEAT_COUNT_RANGE, SPEED_MAP, STAKES_RANGE_MAP} from './constants.js'
import {isObject} from '../../object.js'
import {ALL} from '../constants.js'

export function createStore (createGenericStore, setting) {
  const store = createGenericStore(setting, normalize, flatten)

  return {
    ...store,

    legacyListTypes () {
      const {seatCountRange} = store.get()

      return LIST_TYPE_MAP[seatCountRange].map(([, listType]) => listType)
    },

    setSeatCountRange (seatCountRange) {
      store.set(criteria => { criteria.seatCountRange = seatCountRange })
    },

    setSpeed (newSpeed) {
      store.set(({speed}, {seatCountRange}) => { speed[seatCountRange] = newSpeed })
    },

    setStakesRange (newStakesRange) {
      store.set(({stakesRange}, {speed, seatCountRange}) => {
        if (!stakesRange[seatCountRange]) stakesRange[seatCountRange] = {}
        stakesRange[seatCountRange][speed] = newStakesRange
      })
    },
  }
}

function flatten (criteria) {
  const {seatCountRange = ALL} = criteria

  const [defaultSpeed] = SPEED_MAP[seatCountRange]
  const {[seatCountRange]: speed = defaultSpeed} = criteria.speed

  const [defaultStakesRange] = STAKES_RANGE_MAP[seatCountRange][speed]
  const {[speed]: stakesRange = defaultStakesRange} = criteria.stakesRange?.[seatCountRange] || {}

  return {seatCountRange, speed, stakesRange}
}

function normalize (criteria) {
  if (!isObject(criteria)) return {speed: {}, stakesRange: {}}

  let {seatCountRange, speed, stakesRange} = criteria
  if (!SEAT_COUNT_RANGE[seatCountRange]) seatCountRange = undefined

  return {
    seatCountRange,
    speed: normalizeCriteriaSpeed(speed),
    stakesRange: normalizeCriteriaStakesRange(stakesRange),
  }
}

function normalizeCriteriaSpeed (speed) {
  if (!isObject(speed)) return {}

  const normalized = {}

  for (const seatCountRange in speed) {
    if (!SEAT_COUNT_RANGE[seatCountRange]) continue

    const seatCountRangeSpeed = speed[seatCountRange]
    if (SPEED_MAP[seatCountRange].includes(seatCountRangeSpeed)) normalized[seatCountRange] = seatCountRangeSpeed
  }

  return normalized
}

function normalizeCriteriaStakesRange (stakesRange) {
  if (!isObject(stakesRange)) return {}

  const normalized = {}

  for (const seatCountRange in stakesRange) {
    if (!SEAT_COUNT_RANGE[seatCountRange]) continue

    const normalizedSeatCountRange = {}

    for (const speed in stakesRange[seatCountRange]) {
      if (!SPEED_MAP[seatCountRange].includes(speed)) continue

      const speedStakesRange = stakesRange[seatCountRange][speed]

      if (STAKES_RANGE_MAP[seatCountRange][speed].includes(speedStakesRange)) {
        normalizedSeatCountRange[speed] = speedStakesRange
      }
    }

    normalized[seatCountRange] = normalizedSeatCountRange
  }

  return normalized
}

import Request from './request.coffee'

export default class UpdatePlayerRequest extends Request
  constructor: ({instanceId, serverId, message}) ->
    super arguments...

    @function = 'UPDATE_PLAYER'

    @attributes =
      type: 'REQUEST'
      service: 'GAME'
      routerId: message.routerId
      serverId: serverId
      instanceId: instanceId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

import React from 'react'

import ConfirmLeaveGame from './ConfirmLeaveGame.js'
import Group from './Group.js'
import ResetSettings from './ResetSettings.js'
import ShowFastTableNotice from './ShowFastTableNotice.js'
import ShowTournamentPosition from './ShowTournamentPosition.js'
import SoundsEnabled from './SoundsEnabled.js'
import VisitCashier from './VisitCashier.js'

export default function GeneralSettingsGroup () {
  return <Group heading='General Settings'>
    <ShowFastTableNotice />
    <ConfirmLeaveGame />
    <SoundsEnabled />
    <ShowTournamentPosition />
    <VisitCashier />
    <ResetSettings />
  </Group>
}

import React, {useCallback} from 'react'

import MenuItem from './MenuItem.js'
import {useUpdate} from '../../updating/react-hooks.js'
import {STATUS_RESTART_REQUIRED} from '../../updating/service.js'

export default function UpdateSubMenu (props) {
  const {status} = props
  const label = status === STATUS_RESTART_REQUIRED ? 'Restart to Update' : 'Reload to Update'
  const handleClick = useCallback(e => e.stopPropagation(), [])

  return <div onClick={handleClick}>
    <div className="heading" >Update Available</div>
    <MenuItem icon='fa-refresh' label={label} onClick={useUpdate()} extraClassName='update-link' />
  </div>
}

import Request from './request.coffee'

export default class AddToWaitlistMessage extends Request
  constructor: ({message, instanceId, serverId}) ->
    super arguments...

    @function = 'ADD_TO_WAITLIST'

    @attributes =
      type: 'REQUEST'
      service: 'GAME'
      routerId: message.routerId
      serverId: serverId
      instanceId: instanceId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    # warning: these are REQUIRED even though they're hard coded
    @arguments =
      MIN_PLAYERS: 2
      CRITERIA_WAITLIST_REQUEST: {'TRUE':'TRUE'}

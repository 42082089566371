import {
  OWN_SEAT_LOADED,
  SEAT_STACK_LOADED,
  SEAT_STATUS_LOADED,
  SEAT_VACATED,
} from '@pipehat/chronicle/constants/event-type'

import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  ownSeat: 0,
  seats: {},
}

const initSeat = {
  seatNumber: 0,
  stack: undefined,
  status: '',
}

const seatsSlice = createSlice({
  name: 'seats',
  initialState,
  extraReducers: {
    [OWN_SEAT_LOADED]: (state, {payload}) => {
      state.ownSeat = payload.seatNumber
    },

    [SEAT_STACK_LOADED]: (state, {payload}) => {
      const {seatNumber} = payload
      const seat = getSeat(state.seats[seatNumber])

      seat.stack = payload.stack
      state.seats[seatNumber] = seat
    },

    [SEAT_STATUS_LOADED]: (state, {payload}) => {
      const {seatNumber} = payload
      const seat = getSeat(state.seats[seatNumber])
      seat.status = payload.status

      state.seats[seatNumber] = seat
    },

    [SEAT_VACATED]: (state, {payload}) => {
      const {seatNumber} = payload

      if (seatNumber === state.ownSeat) state.ownSeat = 0

      delete state.seats[seatNumber]
    },
  },
})

function getSeat (seat) {
  return seat || {...initSeat}
}

export default seatsSlice.reducer

import React from 'react'

import AuthorizeNotifications from './AuthorizeNotifications.js'
import Group from './Group.js'
import OtherNotifications from './OtherNotifications.js'
import TournamentStartingNotifications from './TournamentStartingNotifications.js'
import TournamentStartsInMinutesNotifications from './TournamentStartsInMinutesNotifications.js'
import {useIsAuthorizedToShowNotifications} from '../react-hooks.js'

export default function NotificationsGroup () {
  const isEnabled = useIsAuthorizedToShowNotifications()

  return <Group heading='Notifications'>
    {isEnabled && <>
      <TournamentStartingNotifications />
      <TournamentStartsInMinutesNotifications />
      <OtherNotifications />
    </>}

    {!isEnabled && <AuthorizeNotifications />}
  </Group>
}

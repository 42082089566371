export default class Mixmaster
  @include: (mixins) ->
    for mixin in mixins
      for key, value of mixin.prototype
        if @::[key]?
          if key in ['constructor']
            # console.log 'Not overwriting reserved property:', key
            continue
          # console.log "Overwriting #{key}..."
        @::[key] = value
      this

  @construct: (object, mixins, args...) ->
    overrides = Object.getOwnPropertyNames(Object.getPrototypeOf(object))

    for mixin in mixins
      mixinMethods = {}
      mixinInstance = new mixin args...
      mixinInstance.mixin mixinMethods

      for property of mixinMethods
        continue if overrides.includes property
        object[property] = mixinMethods[property]

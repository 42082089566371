import classnames from 'classnames'
import React, {useEffect, useState} from 'react'

import Balance from './Balance.js'
import Icon from './Icon.js'
import {ONE_SECOND} from '../../../time/time-amount.js'
import {useInterval} from '../../../time/timer.js'
import styles from '../TimeBank.module.css'

import {
  useBalance,
  useDisableTimeBank,
  useEnableTimeBank,
  useIsActive,
  useIsEnabled,
  useMinimumBalance,
} from '../react-hooks.js'

export default function TimeBank () {
  const balance = useBalance()
  const disableTimeBank = useDisableTimeBank()
  const enableTimeBank = useEnableTimeBank()
  const isEnabled = useIsEnabled()
  const isRunning = useIsActive()
  const minimumBalance = useMinimumBalance()
  const [runningBalance, setRunningBalance] = useState(balance)
  const handleClick = isRunning ? undefined : isEnabled ? disableTimeBank : enableTimeBank

  useEffect(() => {
    if (!isRunning) {
      setRunningBalance(balance)
    }
  }, [balance, isRunning, runningBalance])

  useInterval(ONE_SECOND, () => {
    if (!isRunning) return

    setRunningBalance(runningBalance - ONE_SECOND)
  })

  const className = classnames(styles.timeBank, {
    [styles.isEnabled]: isEnabled,
  })

  return <div className={className} onClick={handleClick}>
    <Icon start='6s' max='10s' />
    <Balance balance={runningBalance} minimum={minimumBalance} />
  </div>
}

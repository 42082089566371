export default ({signal, app, game, client}) ->
  notice =
    title: 'Removed from Game'
  if game?.gameName
    notice.content = "You have been removed from game #{game.gameName} for being inactive."

    # This used to be handled by navLobby causing a leaveGame message. WEB-1737
    # You should only get these messages in ring games.
    game.playing = false
    game.pinned = false

    # UI stuff
    client.removed()
    # cleans up objects
    app.leaveGame game, client

  app.notice notice
  # Navigator.navLobby()

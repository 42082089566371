import {QUEUED} from '@pipehat/chronicle/constants/seating-status'
import React, {useCallback} from 'react'

import Indicators from './Indicators.js'
import Participating from './Participating.js'
import Pinned from './Pinned.js'
import Priority from './Priority.js'
import Queued from './Queued.js'
import {usePinnedRingGames, useTogglePinnedRingGame} from '../react-hooks.js'

export default function RingGameIndicators (props) {
  const {ringGame: {isOwn, isPriority, seatingStatus, tableId}} = props

  const pinned = usePinnedRingGames()
  const isPinned = pinned[tableId]

  const isQueued = isOwn && seatingStatus === QUEUED
  const isParticipating = isOwn && seatingStatus !== QUEUED

  const toggle = useTogglePinnedRingGame(tableId)
  const handleClick = useCallback(event => {
    event.preventDefault()
    event.stopPropagation()

    toggle()
  }, [toggle])

  return <Indicators onClick={handleClick}>
    {isQueued && <Queued />}
    {isParticipating && <Participating />}
    {isPriority && <Priority />}
    <Pinned isPinned={isPinned} />
  </Indicators>
}

# RematchView
# Model: client

import PaymentOptionsSelect from './paymentOptionsSelect.coffee'
import View from '../view.coffee'

export default class RematchView extends View

  constructor: (client, viewModalTarget) ->
    super()

    @model = client
    @viewModalTarget = viewModalTarget

    @dom = $ '<div class="rematch-content">'

    # ### Components - eg. DOM elements & views
    @components =
      label: $ "<div>Please choose a buy in option:</div>"
      select: new PaymentOptionsSelect([])
      countdown: $ "<div class='countdown-timer'>"
      vs:
        left: $ '<div class="rematch-vs-pane">'
        middle: $ '<div class="rematch-vs-pane">VS</div>'
        right: $ '<div class="rematch-vs-pane">'

    # ### Events - eg. MouseClicks ###

    # ### Listeners - eg. auth:success
    @listeners =
      'promptRematch': @promptRematch
      'tournamentRematch': @tournamentRematch

    @model.on 'hide:rematchViewModal', @hideCurrentModal

    # ### Changes - eg. Model.property = true

    # ### Binding ###
    @initializeView()
    return this

  render: =>
    super()
    @dom.hide()
    return @dom

  refresh: =>
    @updateVs()
    @startCountdown()
    @updatePaymentOptions()

  renderVs: (alias, target) ->
    avatar = $ '<div class="avatar">'
    url = window.AVATAR_URL.replace '%NICK_NAME%', alias
    img = $ '<img>'
    img.attr 'src', url  # check image exists
    img.on 'load', -> avatar.css 'background-image': "url(#{url})"
    target.html avatar
    target.append "<div class='alias'>#{alias}</div>"

  updateVs: =>
    # WARNING: players have been removed by this point because of playerOutOfGame
    # so we are using cached aliases
    playersOutOfGame = @model.game.playersOutOfGame
    if playersOutOfGame?.length is 2
      @renderVs playersOutOfGame[0], @components.vs.left
      @renderVs playersOutOfGame[1], @components.vs.right

  startCountdown: =>
    @components.countdown.html @timer
    @clearCountdown()
    @interval = setInterval =>
      @components.countdown.html --@timer
      if @timer <= 0
        @clearCountdown()
    , @countdownTick or 1000

  clearCountdown: =>
    clearInterval @interval

  getSelectedPaymentOptionId: =>
    return parseInt @components.select.dom.val(), 10

  promptRematch: ({paymentOptions, timer}) =>
    @timer = timer
    @paymentOptions = paymentOptions
    @refresh()
    @dom.show()
    @currentModal = @modal
      title: "Would you like a rematch?"
      content: @dom
      className: "rematch-modal"
      target: @viewModalTarget
      dismissible: false
      choices: [
        {
          value:'Accept'
          icon: 'check'
          action: =>
            paymentId = @getSelectedPaymentOptionId()
            @model.action 'tournamentRematch', {paymentId}
        }
        {
          value:'Reject'
          icon: 'times'
          action: =>
            @model.action 'tournamentRematch', {paymentId: false}
        }
      ]
      teardown: =>
        @model.emit 'hide:rematchViewModal'
        @clearCountdown

  hideCurrentModal: =>
    @currentModal?.quiteTeardown?()

  tournamentRematch: (rematchDecision) =>
    # note: rematchDecision true does not necessarily mean the rematch will be successful
    unless rematchDecision
      @currentModal?.teardown()
      @modal
        title: 'Rematch Declined'
        icon: 'info-circle'
        target: @viewModalTarget
        content: 'Rematch Declined'

  updatePaymentOptions: =>
    component = @components.select
    component.model = @paymentOptions
    component.render()

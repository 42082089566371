import Model from './model.coffee'

export default class Chip extends Model
  constructor: ({amount, currency, precision}) ->
    super()

    @properties =
      amount: amount ?= 0
      currency: currency ?= '$'
      precision: precision ?= 2
    @initializeModel arguments...

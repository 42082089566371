import {NEXT_HAND} from '@pipehat/chronicle/constants/sit-out-strategy'
import React, {useCallback} from 'react'

import LabeledCheckbox from '../../../components/LabeledCheckbox.js'
import {useAbandonSitOutStrategy, useIsAwaitingSitOut, useSelectSitOutStrategy} from '../react-hooks.js'

export default function TournamentSitOut (props) {
  const {size} = props
  const abandonSitOutStrategy = useAbandonSitOutStrategy()
  const selectSitOutStrategy = useSelectSitOutStrategy()
  const isAwaitingSitOut = useIsAwaitingSitOut()

  const handleClick = useCallback(event => {
    event.target.checked ? selectSitOutStrategy(NEXT_HAND) : abandonSitOutStrategy()
  }, [abandonSitOutStrategy, selectSitOutStrategy])

  return <LabeledCheckbox checked={isAwaitingSitOut} onClick={handleClick} size={size}>
    Sit Out Next Hand
  </LabeledCheckbox>
}

import React, {useMemo} from 'react'

import Balance from './overview/Balance.js'
import InProgress from './overview/InProgress.js'
import Levels from './overview/Levels.js'
import Main from './overview/Main.js'
import Position from './overview/Position.js'
import Registration from './overview/Registration.js'
import {useRootedSelector} from '../../redux.js'

import {
  createHasStarted,
  createIsAnnounced,
  createIsFinished,
  createIsInProgress,
  createIsLateRegistration,
  createIsRegistering,
  createIsSelfParticipant,
  createIsSelfRegistrationAllowed,
  getRoot,
} from '../index.js'

export default function Overview (props) {
  const {tournamentId} = props

  const hasStarted = useRootedSelector(getRoot, useMemo(createHasStarted, []), props)
  const isAnnounced = useRootedSelector(getRoot, useMemo(createIsAnnounced, []), props)
  const isFinished = useRootedSelector(getRoot, useMemo(createIsFinished, []), props)
  const isInProgress = useRootedSelector(getRoot, useMemo(createIsInProgress, []), props)
  const isLateRegistration = useRootedSelector(getRoot, useMemo(createIsLateRegistration, []), props)
  const isRegistering = useRootedSelector(getRoot, useMemo(createIsRegistering, []), props)
  const isSelfParticipant = useRootedSelector(getRoot, useMemo(createIsSelfParticipant, []), props)
  const isSelfRegistrationAllowed = useRootedSelector(getRoot, useMemo(createIsSelfRegistrationAllowed, []), props)

  const shouldShowInProgress = hasStarted || isFinished
  const shouldShowPosition = isInProgress || (isLateRegistration && isSelfParticipant) || isFinished
  const shouldShowBalance = isAnnounced || isRegistering || (isLateRegistration && isSelfRegistrationAllowed)
  const shouldShowRegistration = isAnnounced || isRegistering || (isLateRegistration && isSelfRegistrationAllowed)

  return <div className="tournament__overview selfclear">
    <Main tournamentId={tournamentId} />
    <Levels tournamentId={tournamentId} />
    {shouldShowInProgress && <InProgress tournamentId={tournamentId} />}
    {shouldShowPosition && <Position tournamentId={tournamentId} />}
    {shouldShowBalance && <Balance tournamentId={tournamentId} />}
    {shouldShowRegistration && <Registration tournamentId={tournamentId} />}
  </div>
}

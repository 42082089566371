export function createService (app, communicator) {
  return {
    showActive () {
      app.emit('getActiveBonuses')
      communicator.message('activeBonuses')
    },

    showClaim () {
      app.emit('promptClaimCoupon')
    },
  }
}

import React, {useMemo} from 'react'

import {useRootedSelector} from '../../../redux.js'
import {createIsReEntryEnabled, getRoot} from '../../index.js'

export default function ReEntry (props) {
  const isEnabled = useRootedSelector(getRoot, useMemo(createIsReEntryEnabled, []), props)

  return <div className='kvpair selfclear'>
    <label>Re-Entry:</label><span>{isEnabled ? 'Yes' : 'No'}</span>
  </div>
}

import Request from './request.coffee'

export default class GetTournamentDetailsMessage extends Request
  constructor: ({message, listing}) ->
    super arguments...

    @function = 'GET_TOURNAMENT_DETAILS'

    @attributes =
      type: 'REQUEST'
      service: 'GAMELIST'
      routerId: message.routerId
      serverId: 0
      instanceId: 0
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @arguments =
      game_instance: listing.instanceId
      game_number: 1  # todo: is this supposed to be hard coded? listing.gameNumber?

import cardParser from './utils/cardParser.coffee'
import handParser from './utils/handParser.coffee'

export default

  # ### Event Specific Overrides to the values and codes
  # ### Careful! These often get clobbered by the argumentUnpack
  # ### and are no longer codeN/valueN pairs.
  overrides:
    tournamentRematchFailure:
      tournamentRematchFailure:
        key: 'gameName'
        value: (val) -> return val
      value2:
        key: 'tournamentInstanceId'
        value: (val) -> return parseInt(val,10)
      value3:
        key: 'notSureWhatThisIs'
        value: (val) -> return parseInt(val,10)
      finalTableInstanceId:
        key: 'finalTableInstanceId'
        value: (val) -> return parseInt(val,10)
    userFriend:
      userFriend:
        key: 'alias'
        value: (val) -> return val
      code2:
        key: 'isOnline'
        value: (val) -> return val

  # ### Defaults

  playerBalance:
    key: 'playerBalance'
    value: (val) -> return parseFloat(val)

  betAmount:
    key: 'betAmount'
    value: (val) -> return parseFloat(val)

  amount:
    key: 'eventAmount'
    value: (val) -> return parseFloat(val)

  cards:
    key: 'cards'
    value: (val) -> return cardParser.parse(val)

  handDescription:
    key: 'handDescription'
    value: (val) -> return handParser.parse(val)

  playerId:
    key: 'playerId'
    value: (val) -> return parseInt(val)

  alias:
    key: 'alias'
    value: (val) -> return val

  playerName:
    key: 'playerName'
    value: (val) -> return val

  playerCity:
    key: 'playerCity'
    value: (val) -> return val

  location:
    key: 'location'
    value: (val) -> return val

  eventText:
    key: 'eventText'
    value: (val) ->
      return val.split '|'

  code3:
    key: 'activeSitOut'
    value: (val) -> return val is 'sittingOut'

  code4:
    key: 'morePlayersRequired'
    value: (val) -> return val is 'morePlayersRequired'

  code5:
    key: 'morePlayersRequired'
    value: (val) -> return val is 'morePlayersRequired'

  # Waiting for big blind
  code6:
    key: 'waiting'
    value: (val) -> return !val

  code8:
    key: 'isPromotional' # apparently
    value: (val) -> return val

  eventId:
    key: 'eventId'
    value: (val) -> return val

  eventUrl:
    key: 'eventUrl'
    value: (val) -> return val

  eventButtons:
    key: 'eventButtons'
    value: (val) -> return val

  # warning: value5 and value7 not used (yet)
  # all accolades currently handled by PokerStatusData and updates/accolades
  value7:
    key: 'accolades'
    value: (val) ->
      accolades = val.split ';'
      return accolades.map (accolade) ->
        [id, value, isPreferred] = accolade.split '^'
        return {
          id: parseInt id, 10
          value: value
          isPreferred: isPreferred is '1'
        }

  value5:
    key: 'accolades'
    value: (val) ->
      accolades = val.split ';'
      return accolades.map (accolade) ->
        [id, value, isPreferred] = accolade.split '^'
        return {
          id: parseInt id, 10
          value: value
          isPreferred: isPreferred is '1'
        }

  waitListPosition:
    key: 'waitListPosition'
    value: (val) -> return val

  friendRequest:
    key: 'friendRequest'
    value: (val) -> return val?.split(',')

  friendDelete:
    key: 'aliases'
    value: (val) -> return val?.split(',')

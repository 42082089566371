import classnames from 'classnames'
import React from 'react'

import styles from './TabButton.module.css'

export default function TabButton (props) {
  const {isActive, dropdown, icon, label, onClick} = props
  const isFontAwesome = icon.startsWith('fa-')

  const iconClassName = classnames({
    fa: isFontAwesome,
    [icon]: isFontAwesome,
    icon: !isFontAwesome,
    [`icon-${icon}`]: !isFontAwesome,
  })

  const localClassName = classnames({
    [styles.tabButton]: true,
    [styles.active]: isActive,
    [styles.hasDropdown]: dropdown,
  })

  const labelContent = label && <span className='label'>{label}</span>

  return <label className={localClassName} onClick={onClick}>
    <span className={iconClassName} />
    {labelContent}
  </label>
}

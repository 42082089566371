export default ({signal, app, game, client, listing}) ->
  unless signal.success
    switch signal.failReason
      when 'balanceAboveThreshold'
        content = 'Balance Above Threshold'
      when 'shutdown'
        content = 'Tournament has been Shutdown'
      else
        content = signal.failReason
    app.notice
      title: 'Addon Fail'
      content: content

import Request from './request.coffee'

export default class BetRequest extends Request
  constructor: ({client, message, amount}) ->
    super arguments...

    @function = 'DEAL_IT_TWICE'

    @attributes =
      type: 'REQUEST'
      service: 'GAME'
      routerId: message.routerId
      serverId: client.serverId
      instanceId: client.instanceId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @arguments =
      DEAL_IT_TWICE: false

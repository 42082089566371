import React, {useCallback} from 'react'

import {useOpen} from '../../../named-url/react-hooks.js'
import styles from '../Bank.module.css'

export default function VisitCashier () {
  const openCashier = useOpen('cashier.deposit')

  const handleClick = useCallback(event => {
    event.preventDefault()
    openCashier()
  }, [openCashier])

  return <a href='#' className={styles.cashierLink} onClick={handleClick}>(Visit Cashier)</a>
}

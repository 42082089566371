import React from 'react'

import LabelRow from './LabelRow.js'
import Checkbox from '../../components/Checkbox.js'
import {useIsAutoPostBlinds, useToggleAutoPostBlinds} from '../react-hooks.js'

export default function AutoPostBlinds () {
  return <LabelRow
    name='Auto Post Blinds'
    help='Always automatically post blinds by default. Can be overriden in individual game options. Changing this will not affect games you are currently playing in.'
  >
    <Checkbox checked={useIsAutoPostBlinds()} onChange={useToggleAutoPostBlinds()} size='small' />
  </LabelRow>
}

import {NEXT_BLIND, NEXT_HAND} from '@pipehat/chronicle/constants/sit-out-strategy'
import React, {useCallback} from 'react'

import LabeledCheckbox from '../../../components/LabeledCheckbox.js'
import styles from '../QuickOptions.module.css'

import {
  useAbandonSitOutStrategy,
  useIsNextBlindChecked,
  useIsNextHandChecked,
  useSelectSitOutStrategy,
} from '../react-hooks.js'

export default function TableSitOut (props) {
  const {size} = props
  const abandonSitOutStrategy = useAbandonSitOutStrategy()
  const selectSitOutStrategy = useSelectSitOutStrategy()
  const isNextBlindChecked = useIsNextBlindChecked()
  const isNextHandChecked = useIsNextHandChecked()

  const handleSitOutNextHand = useCallback(event => {
    event.target.checked ? selectSitOutStrategy(NEXT_HAND) : abandonSitOutStrategy()
  }, [abandonSitOutStrategy, selectSitOutStrategy])

  const handleSitOutNextBigBlind = useCallback(event => {
    event.target.checked ? selectSitOutStrategy(NEXT_BLIND) : abandonSitOutStrategy()
  }, [abandonSitOutStrategy, selectSitOutStrategy])

  return <>
    <LabeledCheckbox
      className={styles.sitOutNextHand}
      checked={isNextHandChecked}
      onClick={handleSitOutNextHand}
      size={size}
    >
      Hand
    </LabeledCheckbox>
    <LabeledCheckbox
      checked={isNextBlindChecked}
      onClick={handleSitOutNextBigBlind}
      size={size}
    >
      Big Blind
    </LabeledCheckbox>
  </>
}

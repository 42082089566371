import React from 'react'

import ButtonGroup from './ButtonGroup.js'
import SngStakeButtonGroup from './SngStakeButtonGroup.js'
import SngStakeButtonGroupSkeleton from './SngStakeButtonGroupSkeleton.js'
import {useFormat} from '../../device/react-hooks.js'

export default function SngStakeSelection ({isSkeleton}) {
  const device = useFormat()

  return <ButtonGroup number={device === 'desktop' ? '3' : '2'} label='Stake' isSkeleton={isSkeleton}>
    {isSkeleton ? <SngStakeButtonGroupSkeleton /> : <SngStakeButtonGroup />}
  </ButtonGroup>
}

import React from 'react'

import Button from './Button.js'
import {useCanTopUp, useShowAddChips} from '../../add-chips/react-hooks.js'

export default function TopUp (props) {
  const {children, className} = props
  const canTopUp = useCanTopUp()
  const showAddChips = useShowAddChips()

  return <Button className={className} onClick={canTopUp ? showAddChips : undefined} icon='fa fa-credit-card'>
    {children}
  </Button>
}

import {createContext, useContext} from 'react'

export const systemClock = {
  clearInterval,
  setInterval,
  now: () => new Date(),
}

const clockContext = createContext(systemClock)
export const ClockProvider = clockContext.Provider

export function useClock () {
  return useContext(clockContext)
}

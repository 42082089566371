import Card from '../../models/card.coffee'
import CardView from '../game/cardView.coffee'
import View from '../view.coffee'

export default class BadBeatView extends View

  constructor: (client, winners, community) ->
    super()

    @model = client
    @community = community

    @winners = winners

    @dom = $ '<div class="badbeat">'

    # ### Components - eg. DOM elements & views
    @components =
      loser:      $ "<div class='bb-loser'>"
      beaten:     $ "<div class='bb-beaten'>beaten by</div>"
      winner:     $ "<div class='bb-winner'>"
      list:       $ "<table class='bb-list generic-modal-table'>
                      <thead><tr>
                        <th>Name</th>
                        <th>Amount</th>
                      </tr></thead>
                    </table>"
      community:  $ "<div class='bb-community'>"

    @initializeView()
    return this

  render: =>
    @appendComponents()
    @winnersList()
    winner = @winners.find ({gameResultId}) -> gameResultId is 57
    @components.loser.append @renderCards(winner.cards)
    @components.loser.append $("<div>#{winner.nickName}</div>")
    @components.loser.append $("<div class='bb-hand'>#{winner.handDescription}</div>")

    winner = @winners.find ({gameResultId}) -> gameResultId is 56
    @components.winner.append @renderCards(winner.cards)
    @components.winner.append $("<div>#{winner.nickName}</div>")
    @components.winner.append $("<div class='bb-hand'>#{winner.handDescription}</div>")

    @components.community.append @renderCommunity(@community)
    return @dom

  winnersList: =>
    html = ''
    # sort high to low
    winners = @winners.sort ({amount: left}, {amount: right}) -> left > right ? -1 : left is right ? 0 : 1
    for winner in winners
      html += "<tr><td>#{winner.nickName}</td><td>#{winner.amount.toMoney()}</td>"
    @components.list.append html

  # returns a div with cards in it
  renderCards: (cards) =>
    div = $('<div class="cards">')
    for card in cards then do (card) =>
      card = new Card card
      cardView = new CardView card
      div.append cardView.render()
    return div

  renderCommunity: (cards) =>
    div = $('<div class="game-community">')
    for card in cards then do (card) =>
      card = new Card card
      cardView = new CardView card
      div.append cardView.render()
    return div

export default ({signal, details, app, game, player, client}) ->

  # Ensure that listings are set to not playing
  [, tournamentId] = details.eventText

  tournamentId = parseInt(tournamentId, 10)
  tournament = app.listings.find {instanceId: tournamentId}
  tournament?.playing = false

  # WARNING: This notification is actually handled by eliminatedPlayer update. See WEB-1336
  # app.tournamentPosition message, tournament

# ## MessageSerializer
export default class MessageSerializer
  constructor: ->

  build: (request) =>
    command = request.attributes
    command.function = {
      name: request.function
      argument: []
    }

    # e.g. loginUserMessage.metadata = {client_version: "0.6.6-7"}
    if request.metadata
      command.function.metaData =
        'source': 'webclient'
        'items': []
      for name, value of request.metadata
        command.function.metaData.items.push {name, value}

    # !! If you have rawArguments they replace ALL the other arguments
    # you might have added.
    if request.rawArguments instanceof Array
      command.function.argument = request.rawArguments

    for argument_key, argument of request.arguments

      node = {}

      node.name = argument_key.toUpperCase()

      if argument instanceof Array
        for value, i in argument
          node["code#{i + 1}"] = 'DATA'
          node["value#{i + 1}"] = @finalize(value)

      else if argument instanceof Object
        keys = Object.keys(argument)
        for key, i in keys
          if key is argument[key]
            node["code#{i + 1}"] = key.toUpperCase()
          else
            node["code#{i + 1}"] = keys[i].toUpperCase()
            node["value#{i + 1}"] = @finalize(argument[key])

      else
        node['code1'] = 'DATA'
        node['value1'] = @finalize(argument)

      command.function.argument.push node

    return command

  finalize: (value) ->
    if typeof value is 'boolean'
      value = value.toString().toUpperCase()
    return value

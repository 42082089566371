import {v4 as uuidv4} from 'uuid'
import PersistentModel from './persistentModel.coffee'

validv4 = (value) ->
  (value.search /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/) isnt -1

# Not namespaced by casino
export default class GlobalSettings extends PersistentModel

  constructor: (version, persister) ->
    super version, persister

    @namespace = 'global'

    @properties =
      uuid: uuidv4()
      format: 'auto'

    @initializePersistentModel()

  validateUuid: =>
    if not validv4(@uuid)
      @uuid = uuidv4()

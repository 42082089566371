import React, {useMemo} from 'react'

import MobileTournamentListingRow from './MobileTournamentListingRow.js'
import List from '../../components/game-listing/list/List.js'
import Rows from '../../components/game-listing/list/Rows.js'
import {useRootedSelector} from '../../redux.js'
import {getRoot} from '../index.js'
import {createGetSatelliteTournaments} from '../selectors.js'

export default function SatelliteTournament () {
  const tournaments = useRootedSelector(getRoot, useMemo(() => createGetSatelliteTournaments(), []))
  const hasEntries = tournaments.length > 0

  return <List>
    {hasEntries && <Rows>
      {tournaments.map(tournament => {
        return <MobileTournamentListingRow key={tournament.tournamentId} tournament={tournament} />
      })}
    </Rows>}
  </List>
}

import reject from 'lodash/reject'
import {EventEmitter} from 'events'

export default class Collection extends EventEmitter
  constructor: () ->
    super()

    @members = {}

    Object.defineProperty this, 'count', get: => Object.keys(@members).length

    Object.defineProperty this, 'hash', writable: yes, value: (member) -> member.id

    Object.defineProperty this, 'add', value: (member) =>
      id = member?.id
      return unless id
      unless @members[id]?
        @members[id] = member
        @emit 'add', member
        return member
      else
        # if id
        #   console.error 'member already in set'
        # else
        #   console.error 'member has no ID hash'

    Object.defineProperty this, 'rem', value: (member) =>
      id = member?.id
      return unless id
      if @members[id]?
        # caching so it doesn't get deleted before emits are finished
        @emit 'rem', member
        member.emit 'destroy'
        delete @members[@hash member]

    Object.defineProperty this, 'empty', value: () =>
      for _, member of @members
        if @members[member.id]?
          @rem @members[member.id]
      @emit 'empty'

    Object.defineProperty this, 'keys', get: => Object.keys @members

    Object.defineProperty this, 'where', value: (search) =>
      return @toArray().filter(search) if typeof search is 'function'

      @toArray().filter (entry) ->
        Object.keys(search).reduce (result, key) ->
          result and search[key] is entry[key]
        , true

    Object.defineProperty this, 'find', value: (search) =>
      return @toArray().find(search) if typeof search is 'function'

      @toArray().find (entry) ->
        Object.keys(search).reduce (result, key) ->
          result and search[key] is entry[key]
        , true

    Object.defineProperty this, 'each', value: (f) => @toArray().forEach f

    Object.defineProperty this, 'some', value: (f) => @toArray().some f

    Object.defineProperty this, 'every', value: (f) => @toArray().every f

    Object.defineProperty this, 'get', value: (i) =>
      key = Object.keys(@members)[i]
      return @members[key]

    Object.defineProperty this, 'toArray', value: => Object.values @members

    # map to commonly used underscore methods
    Object.defineProperty this, 'reject', value: (f) => reject @toArray(), f

import React from 'react'

import SngFilterSelection from './SngFilterSelection.js'
import SngStakeSelection from './SngStakeSelection.js'
import {useRootedSelector} from '../../redux.js'
import {getIsSngLoading, getRoot} from '../index.js'

export default function SngSelection () {
  const isLoading = useRootedSelector(getRoot, getIsSngLoading)

  return <>
    <SngFilterSelection />
    <SngStakeSelection isSkeleton={isLoading} />
  </>
}

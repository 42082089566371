import Request from './request.coffee'

export default class UpdateGameExclusionsRequest extends Request
  constructor: ({client, message, exclusions}) ->
    super arguments...

    @function = 'UPDATE_GAME_EXCLUSIONS'

    @attributes =
      type: 'REQUEST'
      service: 'AUTHENTICATION'
      serverId: 0
      routerId: message.routerId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @rawArguments = exclusions.map ({type, hours}) ->
      name: 'GAME_TYPE_ID',
      code1: 'DATA',
      value1: type,
      code2: 'DATA',
      value2: hours,

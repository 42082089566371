import Request from './request.coffee'

export default class GetTournamentsMessage extends Request
  constructor: ({message, casinoId, listType, realMoney, tagList}) ->
    super arguments...

    @function = 'GET_TOURNAMENTS'

    @attributes =
      type: 'REQUEST'
      service: 'GAMELIST'
      routerId: message.routerId
      serverId: 0
      instanceId: 0
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @arguments =
      casino_id: casinoId
      class_id: message.classId
      list_type: listType # or 'Tournament SitNGo HeadsUp,Tournament SitNGo Regular,Tournament Scheduled Regular'
      money_type: if realMoney then 'R' else 'P'
      tag_list: tagList

import Request from './request.coffee'

export default class GetTournamentRematchMessage extends Request
  constructor: ({message, client, paymentId}) ->
    super arguments...

    @function = 'TOURNAMENT_REMATCH'

    @attributes =
      type: 'REQUEST'
      service: 'GAME'
      routerId: message.routerId
      serverId: client.serverId
      instanceId: client.instanceId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    # you then reply with TOURNAMENT_REMATCH function, code1=true|false, value1={entry payment id you choose}
    if paymentId isnt false
      arg = {TRUE: paymentId}
    else
      arg = false

    @arguments =
      TOURNAMENT_REMATCH: arg

import React from 'react'

import Button from './Button.js'
import {useCanAddChips, useShowAddChips} from '../../add-chips/react-hooks.js'

export default function AddChips (props) {
  const {children, className} = props
  const showAddChips = useShowAddChips()
  const canAddChips = useCanAddChips()

  return <Button className={className} onClick={canAddChips ? showAddChips : undefined} icon='fa fa-credit-card'>
    {children}
  </Button>
}

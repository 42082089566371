import Request from './request.coffee'

export default class GetTokenRequest extends Request
  constructor: ({client, message, keyId}) ->
    super arguments...

    @function = 'GET_TOKEN'

    @attributes =
      type: 'REQUEST'
      service: 'AUTHENTICATION'
      routerId: message.routerId
      serverId: 0
      instanceId: 0
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @arguments =
      'KEY_ID': keyId

import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

export default class TournamentRematchTranslator extends Mixmaster
  mixins = [
    DefaultMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal
    signal.tournamentTableData = {}

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  tournamentRematchDecision: (signal, name, values) ->
    signal.updates.tournamentRematchDecision = values

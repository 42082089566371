import addToWaitlistTranslator from './addToWaitlistTranslator.coffee'
import buyInTranslator from './buyInTranslator.coffee'
import chatTranslator from './chatTranslator.coffee'
import connectionCheckTranslator from './connectionCheckTranslator.coffee'
import defaultTranslator from './defaultTranslator.coffee'
import depositTranslator from './depositTranslator.coffee'
import gameStatusTranslator from './gameStatusTranslator.coffee'
import genericActionTranslator from './genericActionTranslator.coffee'
import getBalanceTranslator from './getBalanceTranslator.coffee'
import getClientDataFeedTranslator from './getClientDataFeedTranslator.coffee'
import getCouponsTranslator from './getCouponsTranslator.coffee'
import getLevelStructureTranslator from './getLevelStructureTranslator.coffee'
import getMyGamesTranslator from './getMyGamesTranslator.coffee'
import getPlayMoneyTranslator from './getPlayMoneyTranslator.coffee'
import getRingGamesTranslator from './getRingGamesTranslator.coffee'
import getTokenTranslator from './getTokenTranslator.coffee'
import getTournamentDetailsTranslator from './getTournamentDetailsTranslator.coffee'
import getTournamentParentsTranslator from './getTournamentParentsTranslator.coffee'
import getTournamentPlayersTranslator from './getTournamentPlayersTranslator.coffee'
import getTournamentSatellitesTranslator from './getTournamentSatellitesTranslator.coffee'
import getTournamentsTranslator from './getTournamentsTranslator.coffee'
import getUserGameExclusionsTranslator from './getUserGameExclusionsTranslator.coffee'
import highCardTranslator from './highCardTranslator.coffee'
import jackpotTranslator from './jackpotTranslator.coffee'
import joinGameTranslator from './joinGameTranslator.coffee'
import leaveGameTranslator from './leaveGameTranslator.coffee'
import loginUserTranslator from './loginUserTranslator.coffee'
import moveGameTranslator from './moveGameTranslator.coffee'
import notifyTranslator from './notifyTranslator.coffee'
import pendingDepositTranslator from './pendingDepositTranslator.coffee'
import playerDisconnectedTranslator from './playerDisconnectedTranslator.coffee'
import processMessageTranslator from './processMessageTranslator.coffee'
import rebuyTranslator from './rebuyTranslator.coffee'
import registerTranslator from './registerTranslator.coffee'
import reserveTranslator from './reserveTranslator.coffee'
import startBreakTranslator from './startBreakTranslator.coffee'
import startGameTranslator from './startGameTranslator.coffee'
import timeBankSelectedTranslator from './timeBankSelectedTranslator.coffee'
import tournamentRematchTranslator from './tournamentRematchTranslator.coffee'
import tournamentStartTranslator from './tournamentStartTranslator.coffee'
import transferTranslator from './transferTranslator.coffee'
import unreserveTranslator from './unreserveTranslator.coffee'
import updateNicknameTranslator from './updateNicknameTranslator.coffee'
import updatePlayerTranslator from './updatePlayerTranslator.coffee'

CustomTranslators =
  addon:                  rebuyTranslator
  addToWaitlist:          addToWaitlistTranslator
  allIn:                  genericActionTranslator
  bet:                    genericActionTranslator
  bigBlind:               genericActionTranslator
  buyInUpdate:            buyInTranslator
  call:                   genericActionTranslator
  chat:                   chatTranslator
  check:                  genericActionTranslator
  connectionCheck:        connectionCheckTranslator
  default:                defaultTranslator
  deregister:             registerTranslator
  deposit:                depositTranslator
  fold:                   genericActionTranslator
  gameStatus:             gameStatusTranslator
  getBalance:             getBalanceTranslator
  getClientDataFeed:      getClientDataFeedTranslator
  getCoupons:             getCouponsTranslator
  getLevelStructure:      getLevelStructureTranslator
  getMyGames:             getMyGamesTranslator
  getPlayMoney:           getPlayMoneyTranslator
  getRingGames:           getRingGamesTranslator
  getTournaments:         getTournamentsTranslator
  getTournamentDetails:   getTournamentDetailsTranslator
  getTournamentParents:   getTournamentParentsTranslator
  getTournamentPlayers:   getTournamentPlayersTranslator
  getTournamentSatellites:getTournamentSatellitesTranslator
  getToken:               getTokenTranslator
  getUserGameExclusions:  getUserGameExclusionsTranslator
  highCard:               highCardTranslator
  initialBlind:           genericActionTranslator
  jackpotBalance:         jackpotTranslator
  jackpotIsActive:        jackpotTranslator
  jackpotWin:             jackpotTranslator
  joinGame:               joinGameTranslator
  leaveGame:              leaveGameTranslator
  loginUser:              loginUserTranslator
  moveGame:               moveGameTranslator
  muckCards:              genericActionTranslator
  notify:                 notifyTranslator
  pendingDeposit:         pendingDepositTranslator
  playerDisconnected:     playerDisconnectedTranslator
  processMessage:         processMessageTranslator
  rabbitHunt:             genericActionTranslator
  raise:                  genericActionTranslator
  rebuy:                  rebuyTranslator
  register:               registerTranslator
  removeFromWaitlist:     gameStatusTranslator # WARNING: gameStatus lazy
  reserve:                reserveTranslator
  returnBlind:            genericActionTranslator
  seatReserved:           reserveTranslator
  showCards:              genericActionTranslator
  sitIn:                  genericActionTranslator
  sitOut:                 genericActionTranslator
  smallBlind:             genericActionTranslator
  standUp:                genericActionTranslator
  startBreak:             startBreakTranslator
  startGame:              startGameTranslator
  stopBreak:              startBreakTranslator  # note same as startBreak
  timeBankSelected:       timeBankSelectedTranslator
  tournamentStart:        tournamentStartTranslator
  tournamentRematch:      tournamentRematchTranslator
  transfer:               transferTranslator
  unreserve:              unreserveTranslator
  updateNickname:         updateNicknameTranslator
  updatePlayer:           updatePlayerTranslator

export default class TranslatorFactory
  constructor: (translatorPrototypes) ->
    @translatorPrototypes
    @translatorPrototypes ?= CustomTranslators

    @translators = {}

    for name, translator of @translatorPrototypes
      @translators[name] = new translator

  getTranslator: (name) ->
    if @translators[name]?
      return @translators[name]
    else
      return @translators['default']

# SimpleCardView
# Model: card

import View from '../view.coffee'

export default class SimpleCardView extends View

  constructor: (card) ->
    super()

    @model = card

    @dom = $ "<div class='simple-card'>"

    # ### Components - eg. DOM elements & views
    @components =
      rank:  $ "<div class='rank'>"
      suit:  $ "<div class='suit'>"

    # ### Events - eg. MouseClicks ###

    # ### Listeners - eg. auth:success

    # ### Changes - eg. Model.property = true
    @changes =
      suit: @render
      rank: @render

    # ### Binding ###
    @initializeView()
    return this

  render: =>
    @appendComponents()
    @dom.addClass 'suit-' + @model.suit.code
    if @model.rank.code is 't'
      code = '10'
    else
      code = @model.rank.code
    @components.rank.text code
    @components.suit.text @model.suit.symbol
    return @dom

import {createSelector} from '@reduxjs/toolkit'

import {ASC} from './constants.js'
import {getSatelliteFollowOnTournaments, getSatelliteTournaments} from './index.js'
import {KEY_START_TIME, sortTournaments} from './sorting.js'

export function createGetSatelliteFollowOnTournaments () {
  return createSelector(
    [getSatelliteFollowOnTournaments],
    satelliteTournaments => {
      return sortTournaments({key: KEY_START_TIME, direction: ASC}, satelliteTournaments)
    },
  )
}

export function createGetSatelliteTournaments () {
  return createSelector(
    [getSatelliteTournaments],
    satelliteTournaments => {
      return sortTournaments({key: KEY_START_TIME, direction: ASC}, satelliteTournaments)
    },
  )
}

import React from 'react'

import {MOBILE} from '../../device/constants.js'
import {useFormat} from '../../device/react-hooks.js'
import {useIsAuthenticated} from '../../global/react-hooks.js'
import DesktopLobby from '../components/DesktopLobby.js'
import MobileLobby from '../components/MobileLobby.js'

export default function Lobby (props) {
  const {appName, isElectron, isPrivileged} = props
  const format = useFormat()
  const isAuthenticated = useIsAuthenticated()

  if (!isAuthenticated) return null

  if (format === MOBILE) return <MobileLobby appName={appName} isElectron={isElectron} isPrivileged={isPrivileged} />

  return <DesktopLobby appName={appName} isElectron={isElectron} isPrivileged={isPrivileged} />
}

import {
  BUY_IN_CONSTRAINTS_LOADED,
  INITIAL_BUY_IN_LOADED,
  RING_GAME_TABLE_LOADED,
  STAKES_LOADED,
  TOURNAMENT_TABLE_LOADED,
} from '@pipehat/chronicle/constants/event-type'

import {REGULAR} from '@pipehat/chronicle/constants/table-speed'
import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  currency: undefined,
  initialBuyIn: undefined,
  maximumBuyIn: undefined,
  minimumBuyIn: undefined,
  speed: REGULAR,
  stakes: undefined,
  tournamentId: undefined,
}

const advancedOptionsSlice = createSlice({
  name: 'advancedOptions',
  initialState,
  extraReducers: {
    [TOURNAMENT_TABLE_LOADED]: (state, {payload}) => {
      state.currency = payload.tableCurrency
      state.tournamentId = payload.tournamentId
    },

    [RING_GAME_TABLE_LOADED]: (state, {payload}) => {
      state.currency = payload.tableCurrency
      state.speed = payload.speed
    },

    [INITIAL_BUY_IN_LOADED]: (state, {payload}) => {
      state.initialBuyIn = payload.amount
    },

    [BUY_IN_CONSTRAINTS_LOADED]: (state, {payload}) => {
      state.maximumBuyIn = payload.maximumBuyIn
      state.minimumBuyIn = payload.minimumBuyIn
    },

    [STAKES_LOADED]: (state, {payload}) => {
      const {limitType, smallBlind} = payload

      state.stakes = {limitType, smallBlind}
    },
  },
})

export default advancedOptionsSlice.reducer

export default ({signal, details, app, game, player, client}) ->
  [message] = details.eventText
  app.notice
    title: 'Offer Tournament'
    content: message
    choices: [
      {value:'Cancel'}
      {
        value:'OK'
        action: =>
          # console.log 'todo: register/joinGame/whatever'
          # app.joinGame {instanceId, serverId}
      }
    ]

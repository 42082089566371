import React, {useMemo} from 'react'

import Caption from '../../../components/game-listing/list/Caption.js'
import List from '../../../components/game-listing/list/List.js'
import Rows from '../../../components/game-listing/list/Rows.js'
import {useRootedSelector} from '../../../redux.js'
import MobileRingGameSkeletonList from '../../components/MobileRingGameSkeletonList.js'
import MobileTournamentListingRow from '../../components/MobileTournamentListingRow.js'
import {getRoot} from '../../index.js'
import {usePinnedTournaments} from '../../react-hooks.js'
import {useCriteria, useSort} from '../react-hooks.js'
import {createGetListing, createIsLoaded} from '../selectors.js'

const EMPTY_ID = 'game-list-empty-sitngo-tournament'

export default function MobileListing () {
  const criteria = useCriteria()
  const sort = useSort()
  const pinned = usePinnedTournaments()

  const isLoaded = useRootedSelector(getRoot, useMemo(() => createIsLoaded(), []), {criteria})
  const tournaments = useRootedSelector(getRoot, useMemo(() => createGetListing(), []), {criteria, pinned, sort})
  const hasEntries = tournaments.length > 0
  const captionId = !isLoaded || !hasEntries ? EMPTY_ID : undefined

  return <List ariaLabelledby={captionId}>
    {isLoaded && hasEntries && <Rows>
      {tournaments.map(tournament => {
        return <MobileTournamentListingRow key={tournament.tournamentId} tournament={tournament} />
      })}
    </Rows>}

    {isLoaded && !hasEntries && <Caption id={captionId}>No games match filters</Caption>}
    {!isLoaded && <MobileRingGameSkeletonList />}
  </List>
}

import React, {useCallback, useEffect, useState} from 'react'

import LabelRow from './LabelRow.js'

import {
  AUTO_REBUY_POINT_OFF,
  AUTO_REBUY_POINT_OUT_OF_CHIPS,
  AUTO_REBUY_POINT_TABLE_MIN,
} from '../../rooms/constants.js'

import {
  useAutoRebuyPoint,
  useDisableAutoRebuy,
  useEnableAutoRebuyWhenBelowTableMinimum,
  useEnableAutoRebuyWhenOutOfChips,
} from '../react-hooks.js'

export default function AutoRebuyPoint () {
  const initialValue = useAutoRebuyPoint()
  const disableAutoRebuy = useDisableAutoRebuy()
  const enableAutoRebuyWhenBelowTableMinimum = useEnableAutoRebuyWhenBelowTableMinimum()
  const enableAutoRebuyWhenOutOfChips = useEnableAutoRebuyWhenOutOfChips()
  const [state, setState] = useState(initialValue)

  useEffect(() => {
    setState(initialValue)
  }, [initialValue, setState])

  const handleChange = useCallback(event => {
    const value = event.target.value
    if (value !== initialValue) {
      switch (value) {
        case AUTO_REBUY_POINT_OFF: return disableAutoRebuy()
        case AUTO_REBUY_POINT_TABLE_MIN: return enableAutoRebuyWhenBelowTableMinimum()
        case AUTO_REBUY_POINT_OUT_OF_CHIPS: return enableAutoRebuyWhenOutOfChips()
      }
    }
  }, [
    disableAutoRebuy,
    enableAutoRebuyWhenBelowTableMinimum,
    enableAutoRebuyWhenOutOfChips,
    initialValue,
  ])

  return <LabelRow
    name='Auto Rebuy'
    help='Automatically rebuy when your chips reach a certain level. Will not affect current games.'
  >
    <select onChange={handleChange} value={state}>
      <option value={AUTO_REBUY_POINT_OFF}>Off</option>
      <option value={AUTO_REBUY_POINT_OUT_OF_CHIPS}>When out of chips</option>
      <option value={AUTO_REBUY_POINT_TABLE_MIN}>When below table minimum buy-in</option>
    </select>
  </LabelRow>
}

import React from 'react'

import styles from '../TimeBank.module.css'
import {formatBalance} from '../rendering.js'

export default function Balance ({balance, minimum}) {
  const prefix = balance < minimum ? '< ' : ''

  return <span className={styles.balance}>
    {`${prefix}${formatBalance(Math.max(balance, minimum))}`}
  </span>
}

import React from 'react'

import styles from './Empty.module.css'
import ListCaption from '../../../components/game-listing/list/Caption.js'
import TableCaption from '../../../components/game-listing/table/Caption.js'
import {useShouldShowEmpty, useToggleShouldShowEmpty} from '../react-hooks.js'

export default function Empty ({id, isList}) {
  const shouldShowEmpty = useShouldShowEmpty()
  const toggleShouldShowEmpty = useToggleShouldShowEmpty()

  const content = <div>
    <div>No games match filters</div>
    <div>
      {!shouldShowEmpty && <button type='button' className='button' onClick={toggleShouldShowEmpty}>
        Try showing empty tables
      </button>}
    </div>
  </div>

  if (isList) return <ListCaption className={styles.empty} id={id}>{content}</ListCaption>

  return <TableCaption className={styles.empty} id={id}>{content}</TableCaption>
}

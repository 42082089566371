import classnames from 'classnames'
import React from 'react'

import styles from '../Bank.module.css'

export default function PresetButton (props) {
  const {label, onClick} = props
  const className = classnames('button', 'inline-button', styles.presetButton)

  return <button type='button' className={className} onClick={onClick}>
    {label}
  </button>
}

# Webclient.ViewManager
export default class ViewManager
  constructor: (format) ->
    # format must be either 'desktop', 'tablet' or 'mobile'
    Object.defineProperty @, "format",
      value: format
      writable: false

  ###
  # Methods
  ###

  # elements = { desktop, mobile }
  returnFormatElement: (elements) =>
    return $ elements[@format]

export default class popupMaster

  constructor: (@settings, @clients, @isPrivileged) ->
    @children = []
    $(window).on 'unload', @closeAllChildren

  getClient: (instanceId) =>
    client = @clients.find {instanceId}
    return client

  setSetting: (setting) =>
    @settings.set('popup', setting)

  frameType: =>
    return 'window' if @isPrivileged
    return @sanitizeFrameType @settings.get('popup')

  subscribeToFrameType: (subscriber) =>
    return (() ->) if @isPrivileged # frame type never changes
    return @settings.subscribe 'popup', (value) =>
      return subscriber @sanitizeFrameType value

  sanitizeFrameType: (value) ->
    return if value in ['tab', 'window'] then value else undefined

  popIn: (client) =>
    @removePopup client
    # Leave the game if a tournament has ended, else navigate back to the table
    if client.tournamentId > 0 and not client.seated
      client.leaveGame({force: true, lobby: true})
    else
      Navigator.navTable client.game

  removePopup: (client) =>
    client.popup = false
    @children = @children.filter (entry) ->
      entry.close() if entry.instanceId is client.instanceId
      return entry.instanceId isnt client.instanceId

  popOut: (client) =>
    url = "/popUp/#{client.instanceId}"
    if @frameType() is 'window'
      top = (@children.length+1) * 20 + 80
      left = (@children.length+1) * 20 + 280
      options = [
        'height=450',
        'width=650',
        "left=#{left}",
        "top=#{top}",
        'resizable=yes',
        'scrollbars=yes',
        'toolbar=yes',
        'menubar=no',
        'location=no',
        'directories=no',
        'status=yes'
      ].join(',')
    else
      options = []
    popup = window.open url, "#{client.instanceId}", options # comment out the following array to open in new tab
    if popup #if the popup is created succesfully
      popup.instanceId = client.instanceId
      popup.focus()
      @children.push popup
      client.popup = true
      client.emit 'tryNavLobby'
      return true
    else
      return false


  focusPopup: (instanceId) =>
    for popUp in @children
      if popUp?.instanceId is instanceId
        window.postMessage({type: 'FOCUS', payload: {windowName: "#{instanceId}"}})
        popUp.focus()

        return true
    return false

  closeAllChildren: () =>
    for popUp in @children
      popUp?.close()

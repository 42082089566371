# ClientView
# Model: Client

import {USD} from '@pipehat/chronicle/constants/currency'
import {fromNumber} from '@pipehat/money/plain-object'
import dayjs from 'dayjs'
import defer from 'lodash/defer'
import snarkdown from 'snarkdown'

import RebuyView from './rebuyView.coffee'
import AddonView from './addonView.coffee'
import SeatsView from './seat/seatsView.coffee'
import RematchView from './rematchView.coffee'
import BadBeatView from './badBeatView.coffee'
import CheckBoxComponent from './checkBoxComponent.coffee'
import HandHistoryView from '../game/handHistoryView.coffee'
import EmotModalView from './emotModalView.coffee'
import PaymentOptionsSelect from './paymentOptionsSelect.coffee'
import {renderMoney} from '../../../money/rendering.js'
import RingGameRebuyView from '../RingGameRebuyView.js'
import BuyInView from '../BuyInView.js'
import AdvancedOptionsView from '../AdvancedOptionsView.js'
import View from '../view.coffee'

export default class ClientView extends View

  constructor: (client, @createReactView) ->
    super()

    @model = client
    @game = client.game

    @dom = $ '<div class="clientActions">'
    @viewModalTarget = $ "<div class='clientModals'>"
    @emotModal = new EmotModalView client, @viewModalTarget
    @buyInModal = null
    @gameOptionsModal = null
    @previousBalanceTooHighModal = null
    @ringGameRebuyModal = null
    @unreserveSeat = false

    # ### Components - eg. DOM elements & views
    @components =
      modals:               @viewModalTarget
      handDescription:      $ "<div class='handDescription'></div>"
      leaveGameBtn:         $ '<div class="leaveGameBtn button"><i class="fa fa-sign-out button-icon"></i></div>'
      breakNotice:          $ '<div class="breakNotice"><p>TOURNAMENT BREAK<br><span class="breakResume">Resuming in <span class="endTime">0m0s</span></span></p></div>'
      waitingForBreakNotice:$ '<div class="breakNotice"><p>The break will start soon<br><span class="addonAllowed">Addons will be available once the break starts</span></p></div>'
      notification:         $ '<div class="table-notification-pane">'
      waitlist:             $ '<div class="waitlist">'
      centerInformation:    $ "<div class='centerInformation'>"
      rebuyView:            new RebuyView @model, @viewModalTarget
      addonView:            new AddonView @model, @viewModalTarget
      rematchView:          new RematchView @model, @viewModalTarget
      seatsView:            new SeatsView @model.game, @model

    # ### Events - eg. MouseClicks ###
    @components.leaveGameBtn.on 'click', @leaveAndNavigate # no need to confirm
    @components.waitlist.on 'click', @clickWaitlistButton

    # ### Keyboard shortcuts
    @keyScope = 'game-' + @game.instanceId
    @keys =
      'o': @showGameOptions

    # ### Listeners - eg. auth:success
    @listeners =
      # 'show:statistics': @showStatistics
      'activeSitOut': @activeSitOut
      'confirmLeaveGame': @confirmLeaveGame
      'error:emailVerificationRequired': @emailVerificationRequired
      'error:ipAddressOnTable': @ipAddressOnTable
      'error:tooManyGamesOfType': @tooManyGamesOfType
      'error:notEligible': @notEligible
      'error:positionUnavailable': @positionUnavailable
      'error:gameTypeExclusion': @gameTypeExclusion
      'error:joinFailed': @joinFailed
      'fold': @fold
      'hideNotification': @hideNotification
      'jackpotWin' : @jackpotWin
      'promptAutoMuck': @promptAutoMuck
      'promptReEnter': @promptReEnter
      'rabbitHunt': @rabbit
      'seatedByWaitlist': @seatedByWaitlist
      'settingsWarning': @settingsWarning
      'showCheckFoldSelectModal': @showCheckFoldModal
      'showNotification': @showNotification
      'showEmoji': @showEmoji
      'hideEmoji': @hideEmoji
      'toggleBreakNotice': @toggleBreakNotice
      'toggleWaitingForBreakNotice': @toggleWaitingForBreakNotice
      'showGameOptions': @showGameOptions
      'toggleGameOptions': @toggleGameOptions
      'showHandHistory': @showHandHistory
      'waitlistNotification': @waitlistNotification
      'showInsufficientFundsForAutoRebuy': @showInsufficientFundsForAutoRebuy
      'showInvalidAmount': @showInvalidAmount
      'show:buyin': @showBuyIn
      'show:ringGameRebuy': @showRingGameRebuy
      'showInsufficientFundsForBuyIn': @showInsufficientFundsForBuyIn
      'showPreviousBalanceTooHighForBuyIn': @showPreviousBalanceTooHighForBuyIn
      'showInsufficientFundsForRingGameRebuy': @showInsufficientFundsForRingGameRebuy
      'showTableBalanceTooHighForRingGameRebuy': @showTableBalanceTooHighForRingGameRebuy
      'unreservation' : @unreservation

    # ### Changes - eg. Model.property = true
    @changes =
      'description': @updateDescription
      'seated': @updateSeated
      'waiting': @updateWaitlist
      'waitlistPosition': @updateWaitlist
      'centerInformation': @updateCenterInformation
      'popup': @hideGameOptions
      'currentActions': @_doAutoActions
      'isTurn': @_doAutoActions

    @gameChanges =
      gameId: => @ignoreSitOutWarning = false
      playerCount: => defer @updateWaitlist

    @game.players.on 'rem', => defer @updateWaitlist
    @game.players.on 'add', => defer @updateWaitlist

    # Break controls not visible by default
    @components.waitingForBreakNotice.hide()
    @components.breakNotice.hide()

    @on 'destroy', =>
      @hideGameOptions()
      @emotModal.destroy()

    # ### Binding ###
    @initializeView()

    if client.popup and client.onBreak
      @toggleBreakNotice(client.onBreak, client.breakEndTime)

    return this

  _doAutoActions: =>
    @model.autoAction()

  render: =>
    @appendComponents()
    @updateSeated()
    setTimeout @updateSpeed, 500 # temp hack around modal centering bug
    @model.sittingOutActions()
    @components.waitingForBreakNotice.hide()
    @components.breakNotice.hide()
    # WEB-1331. hack to stop empty seats displaying in tournaments.
    if @game.tournamentId
      @components.seatsView.hide()
    return @dom

  showEmoji: =>
    @emotModal.show()

  hideEmoji: =>
    @emotModal.hide()

  rabbit: (v) =>
    @rabbitHunt = v
    @updateDescription()

  fold: (folded) =>
    @folded = folded
    @updateDescription()

  updateDescription: =>
    @model.centerInformation = message: ''
    if @rabbitHunt and @model.description
      description = "RH - (#{@model.description})"
    else if @folded
      description = "Folded - (#{@model.description})"
    else
      description = @model.description
    @components.handDescription.html description

  updateSeated: =>
    @updateWaitlist() # just calling from here for now
    @components.leaveGameBtn.toggle !@model.seated
    @components.notification.toggle @model.seated

    # note the weird nesting is actually necessary
    message = ''
    if @model.seated
      if @game.playerCount is 1
        message = 'More players needed'
    else
      unless @model.tournamentId or @game.full()
        message = 'Click on a seat to play'
    @model.centerInformation = {message}

    if @model.settings.promptAutoBlinds and
      @model.seated and
      @game.speed.toLowerCase() isnt 'fast' and
      not @model.settings.autoBlinds and
      not @model.tournamentId
        @promptAutoBlinds()

  updateWaitlist: =>
    if !@model.seated and @model.game.maxPlayers is @model.game.players.count and @model.listType in [1, 3, 4, 5]
      @components.waitlist.show()
    else if @model.waiting
      @components.waitlist.show()
    else
      @components.waitlist.hide()

    if @model.waitlistPosition.position
      @components.waitlist.html '<i class="fa fa-list-alt"></i> Leave Waitlist'
    else
      @components.waitlist.html '<i class="fa fa-list-alt"></i> Join Waitlist'

  showGameOptions: =>
    if @gameOptionsModal or not @model.seated
      return

    @model.updateRealMoneyBalance()

    reactView = @createReactView(AdvancedOptionsView, {tableId: @model.instanceId, hideModal: @hideGameOptions})

    @gameOptionsModal = @modal
      title: 'Table Options'
      content: reactView.render()
      target: @viewModalTarget
      keyScope: "game-options-#{@model.instanceId}"
      choices: [
        {value:'Close', key:'escape'}
      ]
      teardown: =>
        reactView.destroy()
        @gameOptionsModal = null

  hideGameOptions: =>
    if @gameOptionsModal
      @gameOptionsModal.teardown()
      @gameOptionsModal = null

  toggleGameOptions: =>
    return unless @model.seated

    if @gameOptionsModal
      @hideGameOptions()
    else
      @showGameOptions()

  showBuyIn: =>
    if @buyInModal or @model.seated
      return

    @model.updateRealMoneyBalance()

    if @model.userBalance < @game.minBuyIn
      @showInsufficientFundsForBuyIn(fromNumber(USD, @model.userBalance), fromNumber(USD, @game.minBuyIn))
      return
    else if @model.previousBalance and @model.previousBalance >= (@model.game.maxBuyIn - @model.game.minBet)
      @showPreviousBalanceTooHighForBuyIn()
      return

    reactView = @createReactView(BuyInView, {tableId: @model.instanceId})

    @unreserveSeat = true
    @buyInModal = @modal
      title: 'Buy-In'
      icon: 'ticket'
      content: reactView.render()
      className: 'bettingControlsModal'
      target: @viewModalTarget
      choices: [
        { value: 'Cancel', key: 'escape', icon: 'times' }
        {
          value: 'Confirm'
          key: 'enter'
          icon: 'check'
          action: =>
            @unreserveSeat = false
            @model.action 'sit',
              seat: @model.position
              amount: @model.buyInAmount
        }
      ]
      teardown: =>
        reactView.destroy()
        @buyInModal = null
        if @unreserveSeat
          @model.action 'unreserve'
          @unreserveSeat = false

  hideBuyIn: =>
    if @buyInModal
      @buyInModal.teardown()
      @buyInModal = null

  showRingGameRebuy: =>
    if @ringGameRebuyModal or !@model.seated
      return

    @model.updateRealMoneyBalance()

    if @model.userBalance < @model.minRebuy
      @showInsufficientFundsForRingGameRebuy(fromNumber(USD, @model.userBalance), fromNumber(USD, @game.minBuyIn))
      return
    else if @game.maxBuyIn && @model.tableBalance >= @game.maxBuyIn
      @showTableBalanceTooHighForRingGameRebuy()
      return

    reactView = @createReactView(RingGameRebuyView, {tableId: @model.instanceId})

    @unreserveSeat = true
    @ringGameRebuyModal = @modal
      title: 'Add Chips'
      icon: 'credit-card'
      content: reactView.render()
      className: 'bettingControlsModal'
      target: @viewModalTarget
      detach: true
      choices: [
        {value: 'Cancel', key: 'escape', icon: 'times'}
        {
          value: 'Confirm'
          key: 'enter'
          icon: 'check'
          action: =>
            if @model.ringGameRebuyAmount > 0
              @model.action 'deposit',
                amount: @model.ringGameRebuyAmount
        }
      ]
      teardown: =>
        reactView.destroy()
        @ringGameRebuyModal = null
        @model.ringGameRebuyAmount = undefined

  hideRingGameRebuy: =>
    if @ringGameRebuyModal
      @ringGameRebuyModal.teardown()
      @ringGameRebuyModal = null

  unreservation: (position) =>
    if position is @model.position
      @hideBuyIn()
      @hidePreviousBalanceTooHighForBuyIn()

  updateSpeed: =>
    if @game.speed.toLowerCase?() is 'fast' and @model.user.settings.showFastNotice and not @game.fastNoticeShown
      @game.fastNoticeShown = true

      updateSpeedCB = new CheckBoxComponent("Don&rsquo;t show me this message again")
      content =  $ '<div><p>Please note: you are joining a Fast Table</p><p>At a Fast Table your blinds will be automatically posted and you will be given less time to act.</p></div>'
      label =    updateSpeedCB.label
      checkbox = updateSpeedCB.checkBox
      content
        .append(checkbox)
        .append(label)

      @modal
        title: 'Fast Table Notice'
        icon: 'info-circle'
        content: content
        target: @components.modals
        teardown: =>
          @model.user.settings.showFastNotice = not checkbox.prop 'checked'

  leaveAndNavigate: =>
    @model.leaveGame {force: true, lobby: true}

  confirmLeaveGame: =>
    @hideGameOptions()

    confirmCB = new CheckBoxComponent("Don&rsquo;t ask me again")
    content =  $ '<div><p>Are you sure you want to leave this game?<p></div>'
    label =    confirmCB.label
    checkbox = confirmCB.checkBox
    content
      .append(checkbox)
      .append(label)

    @modal
      title: 'Leave Game'
      icon: 'sign-out'
      content: content
      target: @components.modals
      choices: [
        { value: 'Cancel', icon: 'times' }
        {
          value: 'Leave Game'
          icon: 'sign-out'
          action: =>
            @model.user.settings.confirmLeaveGame = not checkbox.prop 'checked'
            @leaveAndNavigate()
        }
      ]

  settingsWarning: =>
    @hideGameOptions()

    content =  $ '<div><p>Configuring your settings will cause you to leave this table. Continue?<p></div>'

    @modal
      title: 'Configure Settings'

      content: content
      target: @components.modals
      choices: [
        { value: 'No', icon: 'times' }
        {
          value: 'Yes'
          icon: 'check'
          action: =>
            # @leaveAndNavigate()
            Navigator.navSettings()
        }
      ]

  activeSitOut: =>
    if @ignoreSitOutWarning
      return
    @ignoreSitOutWarning = true
    @modal
      title: 'Sitting Out'
      icon: 'info-circle'
      content: 'You are sitting out. You will fold to any bet.'
      target: @components.modals
      keyScope: @game.instanceId
      choices: [
        { value: 'Close', key: 'escape', icon: 'times' }
        {
          value: 'Sit In'
          icon: 'sign-in'
          key: 'enter'
          action: => @model.action 'sitIn'
        }
      ]

  promptAutoBlinds: =>
    promptCB = new CheckBoxComponent("Don&rsquo;t ask me again.")
    content =  $ '<div><p>Would you like to turn on Auto Blinds for all new tables? This global setting will automatically post your blinds when it is your turn. Auto Blinds speeds up the gameplay and is appreciated by other players.<p></div>'
    label =    promptCB.label
    checkbox = promptCB.checkBox
    content
      .append(checkbox)
      .append(label)

    @modal
      title: 'Turn on Auto Blinds?'
      content: content
      target: @components.modals
      choices: [
          {
            value: 'No'
            key: 'escape'
            icon: 'times'
            action: =>
              if checkbox.prop 'checked'
                @model.settings.promptAutoBlinds = false

              @model.autoBlinds = false
              @model.settings.autoBlinds = false
              @model.action 'muck'
          }
          {
            value: 'Yes'
            key: 'enter'
            icon: 'check'
            action: =>
              if checkbox.prop 'checked'
                @model.settings.promptAutoBlinds = false

              @model.autoBlinds = true
              @model.settings.autoBlinds = true
              @model.action 'muck'
          }
      ]

  promptAutoMuck: =>
    promptCB = new CheckBoxComponent("Don&rsquo;t ask me again.")
    content =  $ '<div><p>Would you like to turn on Auto Muck for all new tables? This global setting will automatically muck your cards at the end of a hand (when possible).<p></div>'
    label =    promptCB.label
    checkbox = promptCB.checkBox
    content
      .append(checkbox)
      .append(label)

    @modal
      title: 'Turn on Auto Muck?'
      content: content
      target: @components.modals
      choices: [
          {
            value: 'No'
            key: 'escape'
            icon: 'times'
            action: =>
              if checkbox.prop 'checked'
                @model.settings.promptAutoMuck = false

              @model.autoMuck = false
              @model.settings.autoMuck = false
          }
          {
            value: 'Yes'
            key: 'enter'
            icon: 'check'
            action: =>
              if checkbox.prop 'checked'
                @model.settings.promptAutoMuck = false

              @model.autoMuck = true
              @model.settings.autoMuck = true
              @model.action 'muck'
          }
      ]

  # Errors
  ipAddressOnTable: =>
    setTimeout @hideBuyIn, 0
    @modal
      title: 'Cannot Sit At table'
      icon: 'warning'
      className: 'previousBalanceTooHigh'
      target: @components.modals
      content: 'Another user on your network is playing at this table. In the interest of fairness you are not able to take a seat.'

  tooManyGamesOfType: =>
    @modal
      title: 'Cannot Sit At Table'
      icon: 'warning'
      target: @components.modals
      content: 'You may only sit at a maximum of 6 inactive tables.'

  emailVerificationRequired: =>
    @modal
      title: 'Email Verification Required'
      icon: 'warning'
      className: 'previousBalanceTooHigh'
      target: @components.modals
      content: 'Your account requires email verification. Please check your sign up email addresss and follow the instructions in the activation email.'

  positionUnavailable: =>
    if (@game.playerCount + @game.reservations) < @game.maxPlayers
      seatOrGame = 'seat'
      teardown = -> # do nothing because seats are available
    else
      seatOrGame = 'game'
      teardown = @leaveAndNavigate
    @modal
      title: 'Seat Unavailable'
      icon: 'warning'
      content: 'The desired seat is no longer available. Please select another ' + seatOrGame + '.'
      target: @components.modals
      teardown: teardown

  notEligible: =>
    @modal
      title: 'Not Eligible'
      icon: 'warning'
      content: 'You are not eligible to sit at this table.'
      target: @components.modals
      # You must supply your contact details before you can sit at this table. Please click OK to update your contact details.

  gameTypeExclusion: =>
    @modal
      title: 'Unable To Join Table'
      icon: 'warning'
      target: @components.modals
      content: 'You cannot join because of existing game exclusions.'

  joinFailed: =>
    @modal
      title: 'Unable To Join Table'
      icon: 'warning'
      target: @components.modals
      content: 'Failed to join.'

  showCheckFoldModal: =>
    # console.log 'show check fold modal is called'
    promptCB = new CheckBoxComponent("Remember my decision")
    content =  $ '<div><p>You have chosen to fold when you can check. You can choose a default action in the lobby settings.<p></div>'
    label =    promptCB.label
    checkbox = promptCB.checkBox
    content
      .append(checkbox)
      .append(label)

    @modal
      title: 'Are you sure you want to fold?'
      content: content
      keyScope: @game.instanceId
      target: @viewModalTarget
      choices: [
        {
          value: 'Check'
          icon: 'check'
          action: =>
            @model.processAction 'check'
            if checkbox.prop 'checked'
              @model.settings.checkFoldPreference = 'check'
        }
        {
          value: 'Fold'
          icon: 'times'
          action: =>
            @model.processAction 'fold'
            if checkbox.prop 'checked'
              @model.settings.checkFoldPreference = 'fold'
        }
        {
          value: 'Return to Table'
          key: 'enter'
        }
      ]

  formatBreakEndTime: (endTime) =>
    diff = Math.round(dayjs(endTime).diff())
    mins = Math.floor(diff / 1000 / 60)
    secs = Math.floor(diff / 1000 % 60)
    return "#{mins}m#{secs}s"

  toggleBreakNotice: (v, endTime) =>
    clearInterval @breakNoticeInterval
    @components.breakNotice.toggle v
    @components.breakNotice.find('.endTime').text @formatBreakEndTime(endTime)
    @breakNoticeInterval = setInterval =>
      @components.breakNotice.find('.endTime').text @formatBreakEndTime(endTime)
    , 10000 # update every 10s so fairly accurate

  toggleWaitingForBreakNotice: (v, addonAllowed) =>
    @components.waitingForBreakNotice.toggle v
    @components.waitingForBreakNotice.find('.addonAllowed').toggle addonAllowed

  showNotification: ({message, closable}) =>
    message = $ "<p>#{snarkdown message}</p>"
    message.find('a').addClass 'link merge-link'
    div = $ "<div class='table-notification'>"
    div.html message
    @components.notification.append(div)
    if closable
      closeIcon = $ '<i class="fa fa-times">'
      closeIcon.one 'click', -> div.remove()
      div.prepend closeIcon
    else
      # note: use the timeout id to know which notification to clear (see hideNotification)
      div.addClass 'table-notification-' + @model.notificationTimeout

  hideNotification: =>
    className = '.table-notification-' + @model.notificationTimeout
    @components.notification.find(className).remove()

  clickWaitlistButton: =>
    if @model.waitlistPosition.position
      @promptRemoveFromWaitlist()
    else
      @promptAddToWaitlist()

  promptAddToWaitlist: =>
    @model.action 'findSimilarForWaitlists',
      matchSpeed: false
      addOrRemove: 'add'

  promptRemoveFromWaitlist: =>
    minBet = @model.game.minBet.toMoney()
    type = @model.game.limitTypeDesc.short + @model.game.gameTypeDesc.short

    content = $ "<div>Leave waiting list for:</div>"
    content.append """
    <div class="radio-wrapper">
      <input name="matchSpeed" id="matchSpeedFalse-#{@model.game.instanceId}" type="radio" value="false">
      <label for="matchSpeedFalse-#{@model.game.instanceId}">All #{type} #{minBet} Min. Bet Real Money games</label>
    </div>
    <div class="radio-wrapper">
      <input name="matchSpeed" id="matchSpeedAll-#{@model.game.instanceId}" type="radio" value="all" checked>
      <label for=""matchSpeedAll"-#{@model.game.instanceId}">All games</label>
    </div>
    """
    @modal
      title: 'Confirm'
      content: content
      target: @components.modals
      choices: [
        {value: 'Cancel'}
        {
          value: 'OK'
          action: =>
            switch content.find('input:checked').val()
              when 'false'
                @model.action 'leaveWaitlists', matchSpeed: false
              when 'all'
                @model.action 'leaveAllWaitlists'
        }
      ]

  promptReEnter: ({listing}) =>
    unless listing.gameState is 'Late Registration'
     return
    # todo: refactor this all into own view
    reEnterOptions = new PaymentOptionsSelect listing.entryPayments, listing.eligibilityRequired, 'entry-payment-options'
    content = $('<p>You have been knocked out of this tournament. To re-enter, choose a buy-in option below.</p>')
      .add reEnterOptions.render()
      .add """
        <div class='details left'>
          <div class='balance'>My Balance: <span class='value'>#{@model.user.realMoney.toMoney()}</span> <span class='buyin-cashier-link'>(Visit Cashier)</span></div>
        </div>
        """
    @modal
      title: 'Tournament Re-Entry'
      content: content
      icon: 'ticket'
      className: 'bettingControlsModal'
      target: @components.modals
      choices: [
        {value:'Cancel', icon: 'times'}
        {
          value:'Re-Enter'
          icon: 'ticket'
          action: =>
            paymentId = reEnterOptions.dom?.val() or listing.entryPayments[0].id
            @model.emit 'addToInstances', listing.instanceId
            listing.register {paymentId}
        }
      ]
    # todo: need to change this so we can return to this modal instead
    content.find('.buyin-cashier-link').on 'click', =>
      @model.visitCashier()
      # modal.teardown()

  seatedByWaitlist: =>
    @modal
      title: 'Notice'
      icon: 'info-circle'
      content: 'You have been seated from the waitlist'
      target: @components.modals

  updateCenterInformation: =>
    @components.centerInformation.html @model.centerInformation.message
    if @model.centerInformation.loading
      @components.centerInformation.append '<span class="loaddot1">.</span><span class="loaddot2">.</span><span class="loaddot3">.</span>'

  jackpotWin: (title, winners, community) =>
    if title is 'Bad Beat Jackpot'
      content = new BadBeatView(@model, winners, community)
    else
      message = ''
      for winner in winners
        message += "<div>#{winner.nickName} wins #{winner.amount.toMoney()} with #{winner.handDescription}.</div>"
      content = "<div>#{message}</div><div>Community Cards: #{community}</div>"
    @modal
      target: @components.modals
      title: title
      content: content.render()

  showHandHistory: =>
    handHistoryView = new HandHistoryView @model.game
    @modal
      title: 'Hand History'
      content: handHistoryView.render()
      className: 'hand-history-modal'
      target: @components.modals

  waitlistNotification: =>
    unless @model.user.settings.showWaitlistNotification
      return
    content = $ "<div><p>You have joined the waitlist for #{@model.game.stakesDescription} stakes</p></div>"
    checkbox = new CheckBoxComponent("Don&rsquo;t show me this again")
    content
      .append(checkbox.checkBox)
      .append(checkbox.label)
    @modal
      title: 'Waitlist Notification'
      content: content
      target: @components.modals
      teardown: =>
        @model.user.settings.showWaitlistNotification = not checkbox.checkBox.prop 'checked'

  showInsufficientFundsForAutoRebuy: (ownBalance, minimumBuyIn) =>
    @model.updateRealMoneyBalance()

    @modal
      title: 'Insufficient Funds',
      target: @viewModalTarget
      content: "Your wallet balance (#{renderMoney(ownBalance)}) is less than the table minimum (#{renderMoney(minimumBuyIn)}). Auto rebuy has been disabled."

  showInvalidAmount: (minimum, maximum) =>
    @modal
      title: 'Invalid Amount',
      target: @viewModalTarget
      content: "Ensure you enter a numeric value between the minimum (#{renderMoney(minimum)}) and maximum (#{renderMoney(maximum)}) values allowed."

  showInsufficientFundsForBuyIn: (ownBalance, minimumBuyIn) =>
    setTimeout @hideBuyIn, 0

    @model.updateRealMoneyBalance()

    @model.action 'unreserve'
    modal =
      title: 'Insufficient Funds'
      icon: 'warning'
      content: "You have insufficient funds (#{renderMoney(ownBalance)}) to join this game. Min buy in: #{renderMoney(minimumBuyIn)}"
      target: @viewModalTarget

    if @game.realMoney
      modal.choices = [
        { value: 'Cancel', icon: 'times' }
        { value: 'Visit Cashier', key: 'enter', icon: 'credit-card', action: @model.visitCashier}
      ]
    else
      modal.choices = [
        { value: 'Cancel', icon: 'times' }
        { value: 'Top up Play Money', key: 'enter', icon: 'dollar', action: => @model.action('getPlayMoney') }
      ]

    @modal modal

  showPreviousBalanceTooHighForBuyIn: =>
    @unreserveSeat = true
    @previousBalanceTooHighModal = @modal
      title: 'Notice'
      className: 'previousBalanceTooHigh'
      content: "To ensure poker etiquette is observed, you must re-enter this table with your previous balance of <strong>$#{@model.previousBalance}</strong>"
      target: @viewModalTarget
      choices: [
        { value: 'Cancel', key: 'escape' }
        {
          value: 'OK'
          key: 'enter'
          action: =>
            @unreserveSeat = false
            @model.action 'sit',
              seat: @model.position
              amount: @model.previousBalance
        }
      ]
      teardown: =>
        if @unreserveSeat
          @model.action 'unreserve'
          @unreserveSeat = false

  hidePreviousBalanceTooHighForBuyIn: =>
    if @previousBalanceTooHighModal
      @previousBalanceTooHighModal.teardown()
      @previousBalanceTooHighModal = null

  showInsufficientFundsForRingGameRebuy: (ownBalance, minimumBuyIn) =>
    setTimeout @hideRingGameRebuy, 0

    modal =
      title: 'Insufficient Funds'
      content: "You can not rebuy now because your wallet balance (#{renderMoney(ownBalance)}) is below the table minimum buy-in (#{renderMoney(minimumBuyIn)})."
      target: @viewModalTarget

    unless @game.realMoney
      modal.choices = [
        { value: 'Cancel', key: 'escape' }
        { value: 'Top up Play Money', key: 'enter', action: => @model.action('getPlayMoney') }
      ]

    @modal modal

  showTableBalanceTooHighForRingGameRebuy: =>
    @modal
      title: 'Unable to Add Chips'
      target: @viewModalTarget
      icon: 'warning'
      content: "You can not rebuy now because your balance (#{@model.tableBalance.toMoney()}) is at the table buy-in limit (#{@game.maxBuyIn?.toMoney()})"

import React from 'react'

import PopIn from './components/PopIn.js'
import PopOut from './components/PopOut.js'
import {useFrameType} from '../../../multi-table/react-hooks.js'
import {TAB, WINDOW} from '../../../multi-table/service.js'
import {useTableId} from '../../context.js'

export default function MultiTable (props) {
  const {isPopOut} = props
  const tableId = useTableId()

  const frameType = useFrameType()
  const popOutEnabled = frameType === WINDOW || frameType === TAB

  if (isPopOut) return <PopIn tableId={tableId} />
  if (popOutEnabled) return <PopOut tableId={tableId} />

  return null
}

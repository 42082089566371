import Mixmaster from '../../../mixmaster.coffee'
import WclEnum from '../../../../enum/index.coffee'

import DefaultMixin from './defaultMixin.coffee'

# Data Types
import PokerStatusData from '../dataTypes/pokerStatusData.coffee'

export default class HighCardMixin extends Mixmaster
  mixins = [
    DefaultMixin
    PokerStatusData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  mixin: (object) ->
    mixins.map((mixin) => (new mixin).mixin(object))

    object.playerHighCard = (signal, name, values) ->
      signal.updates.playerHighCard ?= []
      signal.updates.playerHighCard.push
        position: parseInt(values.playerId, 10)
        card:     WclEnum.getCards(values.cards)

import {useEffect, useState} from 'react'

import {useChat} from '../context.js'
import {useService} from '../react-hooks.js'

export const useIsAvailable = () => useSelector(({isAvailable}) => isAvailable)
export const useIsExpanded = () => useSelector(({isExpanded}) => isExpanded)
export const useSendChatMessage = () => useService().sendChatMessage
export const useShowStickers = () => useService().showStickers

export const useChatEntries = () => {
  const {subscribeToChatMessages} = useService()
  const [entries, setEntries] = useState([])

  useEffect(() => {
    return subscribeToChatMessages(message => {
      setEntries(previous => {
        const {id: previousId = 0} = previous[previous.length - 1] || {}

        return [...previous, {...message, id: previousId + 1}]
      })
    })
  }, [entries, subscribeToChatMessages])

  return entries
}

function useSelector (selector) {
  return selector(useChat())
}

# PotWinnerData
#   int seat
#   BigDecimal balance
#   BigDecimal winnings
#   String handDescription
#   String[] cards
import Mixmaster from '../../../mixmaster.coffee'
import WclEnum from '../../../../enum/index.coffee'

export default class PotWinnerData extends Mixmaster
  mixin: (object) ->
    object.winner = (signal, name, values) ->
      signal.updates.winners ?= []
      winningPlayers = values.playerIdList.split '|'
      playerBalances = values.playerBalanceList.split '|'
      playerWinnings = values.playerWinningsList.split '|'
      handDescriptions = values.handDescription?.split '|'
      cards = values.cards?.split '|'

      for id, i in winningPlayers
        winnerData =
          id: parseInt(id,10)
          pots: {}

        winnerData.balance = parseFloat(playerBalances[i])

        if cards?[i]
          winningCards = []
          cardStrings = cards[i].split(',')
          for str in cardStrings
            split = str.split('')
            rank = split[0].toLowerCase()
            suit = split[1]
            winningCards.push '.' + suit + rank

          if values.code7 is 'loPot'
            description = values.handDescription # raw value is correct. e.g. "6-5-4-2-A"
          else
            description = WclEnum.getHandDescription(handDescriptions?[i])

          winnerData.hand =
            cards: winningCards
            # for some reason this might be an array? :S
            description: description

        winnerData.pots[values.potNumber] = {
          amount: playerWinnings[i]
        }

        if values.code7?
          if values.code7 is 'hiPot'
            winnerData.hand?.description += ' (High)'
          else if values.code7 is 'loPot'
            winnerData.hand?.description += ' (Low)'
          else
            winnerData.pots[values.potNumber].returned = true

        signal.updates.winners.push winnerData

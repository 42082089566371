import React, {useCallback, useRef} from 'react'

export default function PokerRulesItem (props) {
  const {heading, children} = props
  const container = useRef()

  const handleClick = useCallback(() => {
    const {current: {style} = {}} = container

    if (style) style.display = style.display === 'block' ? 'none' : 'block'
  }, [container])

  return <>
    <label className='heading' onClick={handleClick}>{heading}</label>

    <label ref={container}>
      <h1>{heading}</h1>

      {children}
    </label>
  </>
}

export default ({signal, details, app, game, client, listing}) ->
  return unless game

  id = details.gameId or details
  currentId = game.gameId?.gameId or game.gameId

  unless id is currentId
    game.gameId = details
    client.displayChatMessage message: '--------------------------------------'
    client.displayChatMessage message: "Starting Game: ##{id}"
    client.playSound 'cardShuffle', client.instanceId

import Enum from '../../../enum/index.coffee'

export default ({signal, details, app, game, client, listing}) ->
  id = parseInt details, 10
  type = Enum.getGameTypeDesc(id)
  unless game?
    # console.warn 'gameTypeId update can not run because no game for', signal.message.instanceId
    return
  if type
    game.gameTypeDesc = type

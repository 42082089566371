import {SIT_N_GO_FILTER, SIT_N_GO_FILTER_CRITERIA, SIT_N_GO_FILTER_MAP} from './constants.js'
import {ASC, DESC} from '../constants.js'

import {
  buildUseSortStartTimeAsc,
  buildUseSortDirection,
  buildUseEntryCostSortAsc,
  buildUseEntryCostSortDesc,
  buildUseToggleSort,
  useCallbackWithArg,
  useService,
  useStore,
  useStoreKey,
} from '../react-hooks.js'

import {KEY_ENTRY_COST, KEY_START_TIME} from '../sorting.js'

export function useCriteria () {
  return useStore(useServiceCriteria())
}

export function useSeatCountRange () {
  return useStoreKey(useServiceCriteria(), 'seatCountRange')
}

export function useSetSeatCountRange (seatCountRange) {
  return useCallbackWithArg(useServiceCriteria().setSeatCountRange, seatCountRange)
}

export function useSpeed () {
  return useStoreKey(useServiceCriteria(), 'speed')
}

export function useSetSpeed (speed) {
  return useCallbackWithArg(useServiceCriteria().setSpeed, speed)
}

export function useStakesRange () {
  return useStoreKey(useServiceCriteria(), 'stakesRange')
}

export function useSetStakesRange (stakesRange) {
  return useCallbackWithArg(useServiceCriteria().setStakesRange, stakesRange)
}

export function useSort () {
  return useStore(useServiceSort())
}

export function useIsDirectionEmpty () {
  const {direction} = useSort()

  return direction === undefined
}

export function useIsStartTimeAsc () {
  const {direction, key} = useSort()

  return key === KEY_START_TIME && direction === ASC
}

export function useIsEntryCostSortAsc () {
  const {direction, key} = useSort()

  return key === KEY_ENTRY_COST && direction === DESC
}

export function useIsEntryCostSortDesc () {
  const {direction, key} = useSort()

  return key === KEY_ENTRY_COST && direction === ASC
}

export const useSortDirection = buildUseSortDirection(useServiceSort)

export const useSortStartTimeAsc = buildUseSortStartTimeAsc(useServiceSort)

export const useEntryCostSortAsc = buildUseEntryCostSortAsc(useServiceSort)

export const useEntryCostSortDesc = buildUseEntryCostSortDesc(useServiceSort)

export const useToggleSort = buildUseToggleSort(useServiceSort)

export function useSngFilter (s2) {
  const urlMatchedFilter = SIT_N_GO_FILTER_MAP[s2]

  const seatCountRange = useStoreKey(useServiceCriteria(), 'seatCountRange')
  const speed = useStoreKey(useServiceCriteria(), 'speed')
  const stakesRange = useStoreKey(useServiceCriteria(), 'stakesRange')

  if (urlMatchedFilter) return urlMatchedFilter

  return getMatchedFilter(seatCountRange, speed, stakesRange)
}

function getMatchedFilter (seatCountRange, speed, stakesRange) {
  const hyperCriteria = SIT_N_GO_FILTER_CRITERIA[SIT_N_GO_FILTER.HYPER]

  if (seatCountRange === hyperCriteria.seatCountRange &&
    speed === hyperCriteria.speed &&
    stakesRange === hyperCriteria.stakesRange
  ) {
    return SIT_N_GO_FILTER.HYPER
  }

  const turboCriteria = SIT_N_GO_FILTER_CRITERIA[SIT_N_GO_FILTER.TURBO]

  if (seatCountRange === turboCriteria.seatCountRange &&
    speed === turboCriteria.speed &&
    stakesRange === turboCriteria.stakesRange
  ) {
    return SIT_N_GO_FILTER.TURBO
  }

  const allCriteria = SIT_N_GO_FILTER_CRITERIA[SIT_N_GO_FILTER.ALL]

  if (seatCountRange === allCriteria.seatCountRange &&
    speed === allCriteria.speed &&
    stakesRange === allCriteria.stakesRange
  ) {
    return SIT_N_GO_FILTER.ALL
  }
}

function useServiceCriteria () {
  return useService().sitngoTournamentCriteria
}

function useServiceSort () {
  return useService().sitngoTournamentSort
}

import range from 'lodash/range'

import Card from '../../../models/card.coffee'

export default ({signal, details, app, game, client, listing}) ->
  return if not game

  # Deal card backs to those dealt in.
  for position in details then do (position) ->
    n = if /Omaha/.test game.gameTypeDesc.long then 4 else 2
    fdCards = range(0,n).map -> new Card
    game.getPlayerByPosition(position)?.cards = fdCards

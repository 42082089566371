export default
  ACCEPT_REQUIRED: 'acceptRequired'
  ACCOLADE: 'accolade'
  ACCOUNT_ID: 'accountId'
  ACCOUNT_NUMBER: 'accountNumber'
  ACCOUNT_TYPE: 'accountType'
  ACTIONS: 'actions'
  ADDON_ALLOWED: 'addonAllowed'
  ADDON_AMOUNT: 'addonAmount'
  ADDON_CHIPS: 'addonChips'
  ADDON_COUNT: 'addonCount'
  ADDON_PAYMENT_ID: 'addonPaymentId'
  ADDRESS: 'address'
  ADDRESS_LINE2: 'addressLine2'
  ADD_PRIZE_POOL_TO_BUYIN: 'addPrizePoolToBuyin'
  ADMIN_WATCHING: 'adminWatching'
  AFFILIATE_ID: 'affiliateId'
  ALL_BLINDS: 'allBlinds'
  ALL_IN_OR_FOLD: 'allInOrFold'
  ALL_IN_REFUND: 'allInRefund'
  ALL_IN_SHOWDOWN: 'allInShowdown'
  ALL_IN_WIN_PCT: 'allInWinPct'
  AMOUNT: 'amount'
  ANNOUNCED_LENGTH: 'announcedLength'
  ANNOUNCED_START_TIME: 'announcedStartTime'
  ANTE: 'ante'
  ANTE_AMOUNT: 'anteAmount'
  AUTOMATIC_DOUBLES_ENABLED: 'automaticDoublesEnabled'
  AUTO_PLAY: 'autoPlay'
  AUTO_REBUY: 'autoRebuy'
  AVERAGE_POT: 'averagePot'
  BALANCE: 'balance'
  BANK_ACCOUNT_ID: 'bankAccountId'
  BANK_NAME: 'bankName'
  BEAVER: 'beaver'
  BEAVER_ACCEPT_REQUIRED: 'beaverAcceptRequired'
  BEAVER_ALLOWED: 'beaverAllowed'
  BET_AMOUNT: 'betAmount'
  BIG_BLIND: 'bigBlind'
  BLINDS_OWING: 'blindsOwing'
  BONUS_BET_AMOUNT: 'bonusBetAmount'
  BONUS_ID: 'bonusId'
  BOUNTY_AMOUNT: 'bountyAmount'
  BOUNTY_WINNER: 'bountyWinner'
  BREAK_ENDS: 'breakEnds'
  BREAK_FREQUENCY: 'breakFrequency'
  BREAK_ID: 'breakId'
  BREAK_LENGTH: 'breakLength'
  BRING_IN: 'bringIn'
  BUY_IN: 'buyIn'
  CAMPAIGN_ID: 'campaignId'
  CARD: 'card'
  CARD_NUMBER: 'cardNumber'
  CASINO_ID: 'casinoId'
  CASINO_RAKE: 'casinoRake'
  CHAT_ALLOWED: 'chatAllowed'
  CHAT_DENIED_UNTIL: 'chatDeniedUntil'
  CHAT_DISABLED_UNTIL: 'chatDisabledUntil'
  CHAT_TEXT: 'chatText'
  CHECKED_FOR_BLACKJACK: 'checkedForBlackjack'
  CITY: 'city'
  CLASS_ID: 'classId'
  COIN_VALUES: 'coinValues'
  COMMUNITY_CARDS: 'communityCards'
  CONNECTED_IP_ADDRESS: 'connectedIpAddress'
  CONNECTED_IP_LIMIT: 'connectedIpLimit'
  CONTRIBUTED_PRIZE_POOL: 'contributedPrizePool'
  COUNTRY: 'country'
  COUPON_CODE: 'couponCode'
  CRAWFORD_RULE_ENABLED: 'crawfordRuleEnabled'
  CREDIT_CARD_EXPIRY_MONTH: 'creditCardExpiryMonth'
  CREDIT_CARD_EXPIRY_YEAR: 'creditCardExpiryYear'
  CREDIT_CARD_ISSUE_NUMBER: 'creditCardIssueNumber'
  CREDIT_CARD_NUMBER: 'creditCardNumber'
  CREDIT_CARD_START_MONTH: 'creditCardStartMonth'
  CREDIT_CARD_START_YEAR: 'creditCardStartYear'
  CRITERIA_WAITLIST_REQUEST: 'criteriaWaitlistRequest'
  CURRENT_BET: 'currentBet'
  CURRENT_PLAYER: 'currentPlayer'
  CURRENT_POT: 'currentPot'
  CURRENT_STATE: 'currentState'
  CURRENT_TRICK: 'currentTrick'
  CUSTOM_BG: 'customBg'
  CUSTOM_BG_FINAL_TABLE: 'customBgFinalTable'
  DATE_OF_BIRTH: 'dateOfBirth'
  DAYS: 'days'
  DEAD_SMALL_BLIND: 'deadSmallBlind'
  DEALER: 'dealer'
  DEAL_IT_TWICE: 'dealItTwice'
  DEAL_IT_TWICE_ENABLED: 'dealItTwiceEnabled'
  DEAL_IT_TWICE_SELECTED: 'dealItTwiceSelected'
  DEFAULT_GROUP_ID: 'defaultGroupId'
  DENY_FRIEND_REQUESTS: 'denyFriendRequests'
  DEPOSIT_ALLOWED: 'depositAllowed'
  DESCRIPTION: 'description'
  DIE_VALUES: 'dieValues'
  DISCONNECTION_ID: 'disconnectionId'
  DOUBLE_ACCEPT_REQUIRED: 'doubleAcceptRequired'
  DOUBLE_DOWN_BET_AMOUNT: 'doubleDownBetAmount'
  DOUBLE_STAKES: 'doubleStakes'
  DOUBLE_UP: 'doubleUp'
  DOUBLE_UP_ALLOWED: 'doubleUpAllowed'
  DOUBLE_UP_REDEALT_HAND: 'doubleUpRedealtHand'
  DOUBLING_ALLOWED: 'doublingAllowed'
  DRAWS_REMAINING: 'drawsRemaining'
  DRAW_CARDS: 'drawCards'
  DRIVERS_LICENSE: 'driversLicense'
  DRIVERS_STATE: 'driversState'
  ELIGIBLE_COUPON: 'eligibleCoupon'
  ELIMINATED_PLAYER: 'eliminatedPlayer'
  EMAIL_ADDRESS: 'emailAddress'
  END_OF_TRICK: 'endOfTrick'
  ENTRY_FEE: 'entryFee'
  ENTRY_FEE_ID: 'entryFeeId'
  ENTRY_PAYMENT_ID: 'entryPaymentId'
  EVENT: 'event'
  EWALLETXPRESS_BANK_ACCOUNT: 'ewalletxpressBankAccount'
  EWALLETXPRESS_USERNAME: 'ewalletxpressUsername'
  EXCEPTION_MESSAGE: 'exceptionMessage'
  EXCEPTION_SEVERITY: 'exceptionSeverity'
  EXCLUDED_INSTANCE_ID: 'excludedInstanceId'
  EXISTING_EXCLUSIONS: 'existingExclusions'
  EXISTING_RESTRICTIONS: 'existingRestrictions'
  EXTERNAL_LOGIN: 'externalLogin'
  EXTERNAL_NICK_NAME: 'externalNickName'
  EXTERNAL_PAYMENT_ID: 'externalPaymentId'
  EXTERNAL_SESSION_ID: 'externalSessionId'
  EXTERNAL_USER_ID: 'externalUserId'
  EXTERNAL_USER_PAYMENT: 'externalUserPayment'
  FACE_CARD: 'faceCard'
  FINGER_PRINT: 'fingerPrint'
  FINGER_PRINT_ERROR: 'fingerPrintError'
  FIRST_NAME: 'firstName'
  FLASH_SETTINGS: 'flashSettings'
  FRIEND_STATUS: 'friendStatus'
  FROM_SEAT_RESERVATION_MGR: 'fromSeatReservationMgr'
  FROM_TOURNAMENT_MONITOR: 'fromTournamentMonitor'
  GAME_CREATED_BY_WAITLIST_MONITOR: 'gameCreatedByWaitlistMonitor'
  GAME_DETAILS: 'gameDetails'
  GAME_ID: 'gameId'
  GAME_INSTANCE: 'gameInstance'
  GAME_LIMIT_TYPE: 'gameLimitType'
  GAME_LIST_FLAGS: 'gameListFlags'
  GAME_NAME: 'gameName'
  GAME_NUMBER: 'gameNumber'
  GAME_PAYTABLE: 'gamePaytable'
  GAME_START_TIMER: 'gameStartTimer'
  GAME_TIMER_POLICY_ID: 'gameTimerPolicyId'
  GAME_TYPE_ID: 'gameTypeId'
  GENDER: 'gender'
  GLOBAL_TIMER: 'globalTimer'
  GO_ALONE: 'goAlone'
  GROUP_ID: 'groupId'
  GUID: 'guid'
  HAND: 'hand'
  HANDS_PER_ROUND: 'handsPerRound'
  HANDS_REMAINING: 'handsRemaining'
  HAND_FOR_HAND_MODE: 'handForHandMode'
  HASH: 'hash'
  HELD_CARDS: 'heldCards'
  HIDE_MY_GAMES: 'hideMyGames'
  HISTORY: 'history'
  IM_READY_ALLOWED: 'imReadyAllowed'
  INITIAL_BLIND: 'initialBlind'
  INITIAL_BUY_IN: 'initialBuyIn'
  INSTANCE_ID: 'instanceId'
  INSURANCE: 'insurance'
  INSURANCE_WINNINGS: 'insuranceWinnings'
  JACKPOT_BALANCE: 'jackpotBalance'
  JACKPOT_BET: 'jackpotBet'
  JACKPOT_BET_POSTED: 'jackpotBetPosted'
  JACKPOT_ID: 'jackpotId'
  JACKPOT_IS_ACTIVE: 'jackpotIsActive'
  JACKPOT_NAME: 'jackpotName'
  JACKPOT_PAYTABLE: 'jackpotPaytable'
  JACKPOT_START_DATE: 'jackpotStartDate'
  JACKPOT_END_DATE: 'jackpotEndDate'
  JACKPOT_RAKE: 'jackpotRake'
  JACKPOT_WINNINGS: 'jackpotWinnings'
  JACOBY_RULE_ENABLED: 'jacobyRuleEnabled'
  KEY_ID: 'keyId'
  LANGUAGE_ID: 'languageId'
  LAST_EVENT_ID: 'lastEventId'
  LAST_LEVEL: 'lastLevel'
  LAST_NAME: 'lastName'
  LAST_TABLE: 'lastTable'
  LATE_REGISTRATION: 'lateRegistration'
  LATE_REGISTRATION_ID: 'lateRegistrationId'
  LATE_REGISTRATION_NUM_SEATS: 'lateRegistrationNumSeats'
  LEAD: 'lead'
  LEVEL: 'level'
  LIMIT_EXCEEDED: 'limitExceeded'
  LIST_LENGTH: 'listLength'
  LIST_TYPE: 'listType'
  LOCAL_IP_ADDRESS: 'localIpAddress'
  LOSING_BET: 'losingBet'
  MAC_ADDRESS: 'macAddress'
  MAIN_POT: 'mainPot'
  MAKER: 'maker'
  MANUAL: 'manual'
  MANUAL_REFUND_ONLY: 'manualRefundOnly'
  MAXBET: 'maxbet'
  MAX_BUY_IN: 'maxBuyIn'
  MAX_COINS: 'maxCoins'
  MAX_LENGTH: 'maxLength'
  MAX_PLAYERS: 'maxPlayers'
  MAX_PLAYERS_PER_TABLE: 'maxPlayersPerTable'
  MAX_RAISES: 'maxRaises'
  MAX_RANK: 'maxRank'
  MAX_RATING: 'maxRating'
  MAX_STAKE: 'maxStake'
  MAX_TABLE_BET: 'maxTableBet'
  MAX_WAITERS: 'maxWaiters'
  MAX_WATCHERS: 'maxWatchers'
  MESSAGE: 'message'
  MINBET: 'minbet'
  MIN_BUY_IN: 'minBuyIn'
  MIN_CHIP_VALUE: 'minChipValue'
  MIN_PLAYERS: 'minPlayers'
  MIN_PRIZE_POOL: 'minPrizePool'
  MIN_RANK: 'minRank'
  MIN_RATING: 'minRating'
  MIN_STAKE: 'minStake'
  MODULE_ID: 'moduleId'
  MONEY_TYPE: 'moneyType'
  MONITOR_ID: 'monitorId'
  MONITOR_SITNGO: 'monitorSitngo'
  MOVE_STONE: 'moveStone'
  MULTI_GAME_TYPE: 'multiGameType'
  MULTIPLE_ENTRY: 'multipleEntry'
  NETWORK_LATENCY_BUFFER: 'networkLatencyBuffer'
  NET_WINNINGS: 'netWinnings'
  NEWS_ID: 'newsId'
  NEWS_TEXT: 'newsText'
  NEW_PASSWORD: 'newPassword'
  NEXT_BREAK: 'nextBreak'
  NEXT_GAME_TIMER: 'nextGameTimer'
  NEXT_LEVEL_DESC: 'nextLevelDesc'
  NEXT_LEVEL_START: 'nextLevelStart'
  NICK_NAME: 'nickName'
  NICK_NAME_PROMPT: 'nickNamePrompt'
  NICK_NAME_VALID: 'nickNameValid'
  NOTIFICATION_TYPE: 'notificationType'
  NOTIFY_USERS: 'notifyUsers'
  NO_DEREGISTER: 'noDeregister'
  NO_IP_CHECK: 'noIpCheck'
  NUMBER_COINS: 'numberCoins'
  NUMBER_HANDS: 'numberHands'
  NUMBER_LINES: 'numberLines'
  NUMBER_OF_ADDONS: 'numberOfAddons'
  NUMBER_OF_REBUYS: 'numberOfRebuys'
  NUMBER_PLAYERS: 'numberPlayers'
  NUMBER_REGISTERED: 'numberRegistered'
  ONLINE: 'online'
  OPERATING_SYSTEM: 'operatingSystem'
  OPPONENT_CARDS: 'opponentCards'
  OPTION: 'option'
  OPTION_ID: 'optionId'
  OTHER_PLAYERS_LOSE: 'otherPlayersLose'
  OVERRIDE: 'override'
  PASSWORD: 'password'
  PASSWORD_CHANGE_PROMPT: 'passwordChangePrompt'
  PAYMENT_OPTION: 'paymentOption'
  PAYMENT_OPTION_ID: 'paymentOptionId'
  PAYMENT_SCHEME_ID: 'paymentSchemeId'
  PAYMENT_TYPE_ID: 'paymentTypeId'
  PAY_BOUNTY_AS_VIP_POINTS: 'payBountyAsVipPoints'  # deprecated
  PENDING_DEPOSIT: 'pendingDeposit'
  PENDING_REBUY: 'pendingRebuy'
  PHONE_NUMBER: 'phoneNumber'
  PHONE_NUMBER2: 'phoneNumber2'
  PING_REPLY_TIME: 'pingReplyTime'
  PING_REQUEST_TIME: 'pingRequestTime'
  PLAYER: 'player'
  PLAYER_BALANCE: 'playerBalance'
  PLAYER_CARDS: 'playerCards'
  PLAYER_DETAILS: 'playerDetails'
  PLAYER_HIGH_CARD: 'playerHighCard'
  PLAYER_ID: 'playerId'
  PLAYER_OUT_OF_GAME: 'playerOutOfGame'
  PLAYER_PLAY_BALANCE: 'playerPlayBalance'
  PLAYER_RANK: 'playerRank'
  PLAYER_REAL_BALANCE: 'playerRealBalance'
  PLAYER_SHOWDOWN_TIMER: 'playerShowdownTimer'
  PLAYER_TAG: 'playerTag'
  PLAYER_TIMER: 'playerTimer'
  PLAYER_TIMER_DISCONNECTED: 'playerTimerDisconnected'
  PLAYER_WINNINGS: 'playerWinnings'
  PLAY_TYPE: 'playType'
  POINTS: 'points'
  POSITION: 'position'
  POST_CODE: 'postCode'
  PREVIOUS_BALANCE: 'previousBalance'
  PRIVATE: 'private'
  PRIZE_POOL: 'prizePool'
  PROP_MAX: 'propMax'
  RAKE: 'rake'
  RATING: 'rating'
  REAL_MONEY: 'realMoney'
  REASON: 'reason'
  REBUY_ALLOWED: 'rebuyAllowed'
  REBUY_AMOUNT: 'rebuyAmount'
  REBUY_CHIPS: 'rebuyChips'
  REBUY_COUNT: 'rebuyCount'
  REBUY_PAYMENT_ID: 'rebuyPaymentId'
  REBUY_THRESHOLD: 'rebuyThreshold'
  RECOVER_LOGIN: 'recoverLogin'
  REDEALT_HAND: 'redealtHand'
  REFUND_BUY_IN_ONLY: 'refundBuyInOnly'
  REF_ID: 'refId'
  REGISTER_DELAY: 'registerDelay'
  REGISTRATION_FEE: 'registrationFee'
  REGISTRATION_LENGTH: 'registrationLength'
  REGISTRATION_REQUIRED: 'registrationRequired'
  REGISTRATION_START_TIME: 'registrationStartTime'
  REGISTRATION_STOP_TIME: 'registrationStopTime'
  RELOAD_CASINO_BOUNTIES: 'reloadCasinoBounties'
  RELOAD_CUSTOM_STARTING_CHIPS: 'reloadCustomStartingChips'
  REMATCH_RESPONSE_TIMER_LENGTH: 'rematchResponseTimerLength'
  REQUIRED_ARGUMENT_MISSING: 'requiredArgumentMissing'
  RESERVED_DURING_WAITLIST_OPERATION: 'reservedDuringWaitlistOperation'
  RESERVE_TIMER: 'reserveTimer'
  RESIGN_TYPE: 'resignType'
  RESULT: 'result'
  RETURN_BLIND: 'returnBlind'
  ROULETTE_BET: 'rouletteBet'
  ROULETTE_WINNING_BET_TYPES: 'rouletteWinningBetTypes'
  ROUND_ID: 'roundId'
  SCHEDULE_FROM: 'scheduleFrom'
  SCHEDULE_TYPE: 'scheduleType'
  SEAT: 'seat'
  SEAT_RESERVED: 'seatReserved'
  SEAT_RESERVED_FROM_WAITLIST_MONITOR: 'seatReservedFromWaitlistMonitor'
  SEAT_UNRESERVED: 'seatUnreserved'
  SECURITY_TOKEN: 'securityToken'
  SERVER_HOST: 'serverHost'
  SERVER_ID: 'serverId'
  SERVER_NAME: 'serverName'
  SERVER_PORT: 'serverPort'
  SERVER_SHUTDOWN: 'serverShutdown'
  SERVER_TIME: 'serverTime'
  SERVER_TYPE_ID: 'serverTypeId'
  SESSION_ID: 'sessionId'
  SESSION_STATE: 'sessionState'
  SHOOTOUT_END_OF_ROUND: 'shootoutEndOfRound'
  SHOOTOUT_ROUND_NUM: 'shootoutRoundNum'
  SHOOTOUT_TYPE_ID: 'shootoutTypeId'
  SHUFFLED: 'shuffled'
  SHUTDOWN_NOW: 'shutdownNow'
  SHUTDOWN_REQUIRED: 'shutdownRequired'
  SIDE_POT: 'sidePot'
  SIT_OUT_TYPE: 'sitOutType'
  SMALL_BLIND: 'smallBlind'
  SOCIAL_SECURITY: 'socialSecurity'
  SPEED: 'speed'
  SPIN_RESULT: 'spinResult'
  SSN_REQUIRED: 'ssnRequired'
  STAKES_DESCRIPTION: 'stakesDescription'
  STARTING_CHIPS: 'startingChips'
  START_GAME: 'startGame'
  START_LEAD_IN_LENGTH: 'startLeadInLength'
  START_TIME: 'startTime'
  START_TYPE: 'startType'
  STATE: 'state'
  STATE_DESC: 'stateDesc'
  STATUS: 'status'
  STAT_VALUE: 'statValue'
  STICK_THE_DEALER: 'stickTheDealer'
  STOP_AT_PLAYERS: 'stopAtPlayers'
  STOP_BREAK: 'stopBreak'
  STOP_ON_IDENTICAL_PRIZES: 'stopOnIdenticalPrizes'
  STRATEGY: 'strategy'
  STUD_ANTE: 'studAnte'
  STYLE: 'style'
  SYSTEM_RAKE: 'systemRake'
  TABLE_GAME_TYPE_ID: 'tableGameTypeId'
  TABLE_NUMBER: 'tableNumber'
  TABLE_STATUS: 'tableStatus'
  TAG_ID: 'tagId'
  TAG_LIST: 'tagList'
  TIMER_ID: 'timerId'
  TIMER_VALUE: 'timerValue'
  TIME_BANK_ACTIVE: 'timeBankActive'
  TIME_BANK_BALANCE: 'timeBankBalance'
  TIME_BANK_ENABLED: 'timeBankEnabled'
  TIME_BANK_SELECTED: 'timeBankSelected'
  TIME_BANK_ID: 'timeBankId'
  TIME_PER_ROUND: 'timePerRound'
  TIME_REMAINING: 'timeRemaining'
  TITLE: 'title'
  TOKEN: 'token'
  TOURNAMENT_BREAKDOWN_ID: 'tournamentBreakdownId'
  TOURNAMENT_FINISHED: 'tournamentFinished'
  TOURNAMENT_GAME_NUMBER: 'tournamentGameNumber'
  TOURNAMENT_GAME_TYPE_ID: 'tournamentGameTypeId'
  TOURNAMENT_HAND_ID: 'tournamentHandId'
  TOURNAMENT_ID: 'tournamentId'
  TOURNAMENT_LEVEL_ID: 'tournamentLevelId'
  TOURNAMENT_NAME: 'tournamentName'
  TOURNAMENT_PAYMENT_OPTIONS: 'tournamentPaymentOptions'
  TOURNAMENT_PAYOUT_ID: 'tournamentPayoutId'
  TOURNAMENT_REMATCH: 'tournamentRematch'
  TOURNAMENT_REMATCH_DECISION: 'tournamentRematchDecision'
  TOURNAMENT_SERVER_ID: 'tournamentServerId'
  TOURNAMENT_STATS: 'tournamentStats'
  TRANSACTION_ID: 'transactionId'
  TRANSACTION_PENDING: 'transactionPending'
  TRANSFER: 'transfer'
  TRANSFER_OPTION: 'transferOption'
  TRANSIT_NUMBER: 'transitNumber'
  TRUMP_SUIT: 'trumpSuit'
  TYPE: 'type'
  UNDO_MOVE_STONE: 'undoMoveStone'
  UNIQUE_ID: 'uniqueId'
  URL: 'url'
  USER_ID: 'userId'
  USER_COUPON_ID: 'userCouponId'
  USER_NAME: 'userName'
  USER_OPTION: 'userOption'
  USER_STATISTICS: 'userStatistics'
  USE_RATING: 'useRating'
  USING_TIME_BANK: 'usingTimeBank'
  VERIFICATION_CODE: 'verificationCode'
  VERSION: 'version'
  VIP_CLASS_ID: 'vipClassId'  # deprecated
  VIP_CLASS_NAME: 'vipClassName'  # deprecated
  VIP_CLASS_PERCENT: 'vipClassPercent'  # deprecated
  VIP_POINTS_BALANCE: 'vipPointsBalance'  # deprecated
  VIP_POINTS_DECAYED: 'vipPointsDecayed'  # deprecated
  VIP_POINTS_EARNED: 'vipPointsEarned'  # deprecated
  VIP_POINTS_PER_CENT: 'vipPointsPerCent'  # deprecated
  VIP_POINTS_TIL_NEXT_LEVEL: 'vipPointsTilNextLevel'  # deprecated
  WAITING_FOR_BREAK: 'waitingForBreak'
  WAITLISTED_PLAYERS: 'waitlistedPlayers'
  WAIT_LIST_POSITION: 'waitListPosition'
  WATCHERS: 'watchers'
  WINNER: 'winner'
  WINNING_BET: 'winningBet'
  WIN_COUPON: 'winCoupon'
  WIRE_TRANSFER_DESC: 'wireTransferDesc'
  WIRE_TRANSFER_NAME: 'wireTransferName'
  ILLEGAL_SOFTWARE: 'illegalSoftware'

export default
  ACCOUNT_ID: 'accountId'
  ACCOUNT_NUMBER: 'accountNumber'
  ACTIVE: 'active'
  ACTIVE_OPTION_EXISTS: 'activeOptionExists'
  ADDON: 'addon'
  ADDON_CHIPS: 'addonChips'
  ADDON_NOT_ALLOWED: 'addonNotAllowed'
  ADJUSTMENT_REQUIRED: 'adjustmentRequired'
  ADMIN: 'admin'
  ALL_IN: 'allIn'
  ALL_IN_DP: 'allInDp'
  ALL_IN_FOLD: 'allInFold'
  ALL_IN_IWAIT: 'allInIwait'
  ALL_IN_RWAIT: 'allInRwait'
  ALL_IN_SITOUT: 'allInSitout'
  ALREADY_PLAYING: 'alreadyPlaying'
  ALREADY_RESERVED: 'alreadyReserved'
  ALREADY_WAITING: 'alreadyWaiting'
  ALREADY_WATCHING: 'alreadyWatching'
  AMOUNT: 'amount'
  AMOUNT_TOO_HIGH: 'amountTooHigh'
  AMOUNT_TOO_LOW: 'amountTooLow'
  ANNOUNCED: 'announced'
  ANTE: 'ante'
  ANTES: 'antes'
  ANTE_LIST: 'anteList'
  ANTE_POTS: 'antePots'
  ANTE_SITOUT: 'anteSitout'
  AUTO_APPROVE_BANNED_MAC_ADDRESS: 'autoApproveBannedMacAddress'
  AUTO_APPROVE_MANUAL_APPROVAL_REQUIRED: 'autoApproveManualApprovalRequired'
  AUTO_APPROVE_VERIFICATION_REQUIRED: 'autoApproveVerificationRequired'
  AUTO_APPROVE_WITHDRAW_LIMIT_EXCEEDED: 'autoApproveWithdrawLimitExceeded'
  AUTO_REBUY_DEPOSIT: 'autoRebuyDeposit'
  BACKGAMMON: 'backgammon'
  BALANCE_ABOVE_THRESHOLD: 'balanceAboveThreshold'
  BALANCE_UPDATED: 'balanceUpdated'
  BANK_ACCOUNT_ID: 'bankAccountId'
  BANNED_GUID: 'bannedGuid'
  BANNED_MAC_ADDRESS: 'bannedMacAddress'
  BET: 'bet'
  BET_AMOUNT: 'betAmount'
  BIG_BLIND: 'bigBlind'
  BLACKJACK: 'blackjack'
  BLUE: 'blue'
  BONUS_BET_AMOUNT: 'bonusBetAmount'
  BONUS_WIN: 'bonusWin'
  BOUNTY_PRIZE: 'bountyPrize'
  BREAK_END_TIME: 'breakEndTime'
  BREAK_MINUTES: 'breakMinutes'
  BREAK_START_TIME: 'breakStartTime'
  BRING_IN: 'bringIn'
  BRING_IN_ALL_IN: 'bringInAllIn'
  BRING_IN_ALL_IN_FOLD: 'bringInAllInFold'
  BRING_IN_BET: 'bringInBet'
  BRING_IN_BET_FOLD: 'bringInBetFold'
  BUST: 'bust'
  CALL: 'call'
  CALL_ALL_IN_FOLD: 'callAllInFold'
  CALL_COMPLETE_ALL_IN_FOLD: 'callCompleteAllInFold'
  CALL_COMPLETE_FOLD: 'callCompleteFold'
  CALL_COMPLETE_OPCOMPLETE_FOLD: 'callCompleteOpcompleteFold'
  CALL_FOLD: 'callFold'
  CALL_RAISE_ALL_IN_FOLD: 'callRaiseAllInFold'
  CALL_RAISE_FOLD: 'callRaiseFold'
  CALL_RAISE_OPRAISE_FOLD: 'callRaiseOpraiseFold'
  CARDS: 'cards'
  CARDS_TO_HOLD: 'cardsToHold'
  CARD_NUMBER: 'cardNumber'
  CASHIER_TIMER: 'cashierTimer'
  CASINO_ID: 'casinoId'
  CASINO_NOT_AUTHORISED: 'casinoNotAuthorised'
  CENTS_PER_POINT: 'centsPerPoint'
  CHAT_BOX_NOTIFICATION: 'chatBoxNotification'
  CHECK: 'check'
  CHECK_ALL_IN_FOLD: 'checkAllInFold'
  CHECK_BET_ALL_IN_FOLD: 'checkBetAllInFold'
  CHECK_BET_FOLD: 'checkBetFold'
  CHECK_BET_OPBET_FOLD: 'checkBetOpbetFold'
  CHECK_COMPLETE_ALL_IN_FOLD: 'checkCompleteAllInFold'
  CHECK_COMPLETE_FOLD: 'checkCompleteFold'
  CHECK_RAISE_ALL_IN_FOLD: 'checkRaiseAllInFold'
  CHECK_RAISE_FOLD: 'checkRaiseFold'
  CHIPS: 'chips'
  CLASS_NOT_AUTHORISED: 'classNotAuthorised'
  CLIENT_NOT_JOINED: 'clientNotJoined'
  CLIENT_NOT_PLAYER: 'clientNotPlayer'
  CLIENT_NOT_RESERVED: 'clientNotReserved'
  CLIENT_NOT_WAITLISTED: 'clientNotWaitlisted'
  CLUBS: 'clubs'
  COMPLETE: 'complete'
  COMPLETED: 'completed'
  CONTAINS_PROFANITY: 'containsProfanity'
  COUPON_AWARDED: 'couponAwarded'
  COUPON_AWARDED_WITH_CREDIT: 'couponAwardedWithCredit'
  COUPON_NOT_EXIST: 'couponNotExist'
  COUPON_PLAYTHROUGH_COMPLETE: 'couponPlaythroughComplete'
  COUPON_PLAYTHROUGH_COMPLETE_WITH_CREDIT: 'couponPlaythroughCompleteWithCredit'
  COUPON_PLAYTHROUGH_NOT_MET: 'couponPlaythroughNotMet'
  COUPON_PLAYTHROUGH_PARTIAL_COMPLETE: 'couponPlaythroughPartialComplete'
  CREDIT_CARD_DETAILS_DIFFER: 'creditCardDetailsDiffer'
  CREDIT_CARD_EXPIRY_MONTH: 'creditCardExpiryMonth'
  CREDIT_CARD_EXPIRY_YEAR: 'creditCardExpiryYear'
  CREDIT_CARD_NUMBER: 'creditCardNumber'
  DAILY_LIMIT_EXCEEDED: 'dailyLimitExceeded'
  DATA: 'data'
  DATABASE_ERROR: 'databaseError'
  DEALT: 'dealt'
  DEALT_RANKS: 'dealtRanks'
  DEAL_IT_TWICE: 'dealItTwice'
  DEPOSIT: 'deposit'
  DIAMONDS: 'diamonds'
  DIE_NOT_AVAILABLE: 'dieNotAvailable'
  DIE_STATUS: 'dieStatus'
  DIE_USED: 'dieUsed'
  DIE_VALUE: 'dieValue'
  DIE_VALUES: 'dieValues'
  DISCONNECTED: 'disconnected'
  DOUBLE_BET_AMOUNT: 'doubleBetAmount'
  DOUBLE_REBUY: 'doubleRebuy'
  DOUBLE_UP_DEALT: 'doubleUpDealt'
  DOUBLE_UP_REDEALT: 'doubleUpRedealt'
  DOUBLING_CUBE_VALUE: 'doublingCubeValue'
  DRAW: 'draw'
  ELIMINATED: 'eliminated'
  EMAIL_ADDRESS: 'emailAddress'
  EMAIL_ALREADY_EXISTS: 'emailAlreadyExists'
  EMAIL_VERIFICATION_REQUIRED: 'emailVerificationRequired'
  END_OF_FOLDED_GAME: 'endOfFoldedGame'
  END_OF_GAME: 'endOfGame'
  END_OF_HAND: 'endOfHand'
  END_OF_TOURNAMENT: 'endOfTournament'
  ERROR: 'error'
  EUCHRE_WON_MONEY: 'euchreWonMoney'
  EVENT_BUTTONS: 'eventButtons'
  EVENT_ID: 'eventId'
  EVENT_TEXT: 'eventText'
  EVENT_URL: 'eventUrl'
  EXTERNAL_USER_ALREADY_EXISTS: 'externalUserAlreadyExists'
  EXTERNAL_USER_FIRST_LOGIN: 'externalUserFirstLogin'
  FACE_CARD: 'faceCard'
  FAILED: 'failed'
  FALSE: false
  FIFTH_STREET: 'fifthStreet'
  FILENAME: 'filename'
  FINAL_TABLE_INSTANCE_ID: 'finalTableInstanceId'
  FINGER_PRINT_RECEIPT: 'fingerPrintReceipt'
  FIRST_DRAW: 'firstDraw'
  FIVE_CARD_CHARLIE: 'fiveCardCharlie'
  FLOP: 'flop'
  FOLD: 'fold'
  FOLDED: 'folded'
  FOURTH_STREET: 'fourthStreet'
  FRIEND_ACCEPT: 'friendAccept'
  FRIEND_ALREADY_EXISTS: 'friendAlreadyExists'
  FRIEND_DELETE: 'friendDelete'
  FRIEND_DOES_NOT_EXIST: 'friendDoesNotExist'
  FRIEND_REJECT: 'friendReject'
  FRIEND_REQUEST: 'friendRequest'
  GAME_ALREADY_EXISTS: 'gameAlreadyExists'
  GAME_CANCELLED: 'gameCancelled'
  GAME_INSTANCE_DETAIL: 'gameInstanceDetail'
  GAME_INSTANCE_FAIL: 'gameInstanceFail'
  GAME_INSTANCE_FATAL_ERROR: 'gameInstanceFatalError'
  GAME_NOT_EXIST: 'gameNotExist'
  GAME_OVER: 'gameOver'
  GAME_PLAYERS_EXIST_ON_RESTART: 'gamePlayersExistOnRestart'
  GAME_RESULT_ID: 'gameResultId'
  GAME_STARTING: 'gameStarting'
  GAME_TIMER: 'gameTimer'
  GAME_TYPE_EXCLUSION: 'gameTypeExclusion'
  GAME_TYPE_RESTRICTION: 'gameTypeRestriction'
  GAMMON: 'gammon'
  HAND_DESCRIPTION: 'handDescription'
  HAND_FOR_HAND: 'handForHand'
  HAND_NUMBER: 'handNumber'
  HEARTS: 'hearts'
  HIGH_CARD: 'highCard'
  HIGHER_PRIORITY_SESSION_EXISTS: 'higherPrioritySessionExists'
  HI_POT: 'hiPot'
  HOME: 'home'
  ID: 'id'
  IN: 'in'
  INACTIVE: 'inactive'
  INACTIVE_CASINO_ACCOUNT: 'inactiveCasinoAccount'
  INACTIVE_OPTION_EXISTS: 'inactiveOptionExists'
  INACTIVE_USER_ACCOUNT: 'inactiveUserAccount'
  INCORRECT_PASSWORD: 'incorrectPassword'
  INITIAL_BLIND: 'initialBlind'
  INSUFFICIENT_FUNDS: 'insufficientFunds'
  INSUFFICIENT_FUNDS_DUE_TO_COUPON: 'insufficientFundsDueToCoupon'
  INSUFFICIENT_VIP_POINTS: 'insufficientVipPoints'  # deprecated
  INVALID_ACTION: 'invalidAction'
  INVALID_ARGUMENT_VALUE: 'invalidArgumentValue'
  INVALID_CHARACTER: 'invalidCharacter'
  INVALID_COUNTRY: 'invalidCountry'
  INVALID_DIE_VALUE: 'invalidDieValue'
  INVALID_EMAIL_ADDRESS: 'invalidEmailAddress'
  INVALID_LOGIN_TICKET: 'invalidLoginTicket'
  INVALID_MAX_PLAYERS: 'invalidMaxPlayers'
  INVALID_MESSAGE: 'invalidMessage'
  INVALID_MOVE: 'invalidMove'
  INVALID_NICKNAME: 'invalidNickname'
  INVALID_PASSWORD: 'invalidPassword'
  INVALID_PAYMENT_OPTION: 'invalidPaymentOption'
  INVALID_PAYOUT_ID: 'invalidPayoutId'
  INVALID_POINT_VALUE: 'invalidPointValue'
  INVALID_REAL_MONEY_INDICATOR: 'invalidRealMoneyIndicator'
  INVALID_SERVER_ID: 'invalidServerId'
  INVALID_SESSION_ID: 'invalidSessionId'
  INVALID_START_TIME: 'invalidStartTime'
  INVALID_TRANSACTION: 'invalidTransaction'
  INVALID_USERNAME: 'invalidUsername'
  INVALID_USER_ID: 'invalidUserId'
  INVALID_USER_OR_PASSWORD: 'invalidUserOrPassword'
  INVALID_VERIFICATION_CODE: 'invalidVerificationCode'
  INVALID_VERSION: 'invalidVersion'
  IN_PROGRESS: 'inProgress'
  IP_ADDRESS_ON_TABLE: 'ipAddressOnTable'
  JACKPOT_BET: 'jackpotBet'
  JACKPOT_WINNINGS: 'jackpotWinnings'
  JOIN_GAME: 'joinGame'
  LAST_ANTE_POT_NUMBER: 'lastAntePotNumber'
  LAST_FOLD_SHOW_ALLOWED: 'lastFoldShowAllowed'
  LEAVE_GAME: 'leaveGame'
  LEVEL_DETAILS: 'levelDetails'
  LOBBY_OPTIONS: 'lobbyOptions'
  TAG_OPTIONS: 'tagOptions'
  LOSS: 'loss'
  LO_POT: 'loPot'
  MACHINE_NOT_UNIQUE: 'machineNotUnique'
  MACHINE_VERIFICATION_REQUIRED: 'machineVerificationRequired'
  MARSHAL_ERROR: 'marshalError'
  MATCH: 'match'
  MAXIMUM_PLAYERS_REACHED: 'maximumPlayersReached'
  MAXIMUM_RAISES: 'maximumRaises'
  MAXIMUM_RESIGNS: 'maximumResigns'
  MAXIMUM_WATCHERS_REACHED: 'maximumWatchersReached'
  MAX_BET_AMOUNT: 'maxBetAmount'
  MAX_DEPOSIT: 'maxDeposit'
  MAX_NUM_PENDING_DEPOSIT_LIMIT_EXCEEDED: 'maxNumPendingDepositLimitExceeded'
  MAX_NUM_PENDING_DEPOSIT_PER_OPTION_LIMIT_EXCEEDED: 'maxNumPendingDepositPerOptionLimitExceeded'
  MAX_NUM_PENDING_DEPOSIT_PER_USER_LIMIT_EXCEEDED: 'maxNumPendingDepositPerUserLimitExceeded'
  MAX_NUM_PENDING_DEPOSIT_PRE_THRESHOLD_LIMIT_EXCEEDED: 'maxNumPendingDepositPreThresholdLimitExceeded'
  MAX_NUM_PENDING_WITHDRAWAL_LIMIT_EXCEEDED: 'maxNumPendingWithdrawalLimitExceeded'
  MAX_NUM_PENDING_WITHDRAWAL_PER_OPTION_LIMIT_EXCEEDED: 'maxNumPendingWithdrawalPerOptionLimitExceeded'
  MAX_NUM_PENDING_WITHDRAWAL_PER_USER_LIMIT_EXCEEDED: 'maxNumPendingWithdrawalPerUserLimitExceeded'
  MAX_NUM_PENDING_WITHDRAWAL_PRE_THRESHOLD_LIMIT_EXCEEDED: 'maxNumPendingWithdrawalPreThresholdLimitExceeded'
  MAX_RAISE_AMOUNT: 'maxRaiseAmount'
  MAX_TRANSFER: 'maxTransfer'
  MAX_WITHDRAW: 'maxWithdraw'
  MD5: 'md5'
  MIN_BET_AMOUNT: 'minBetAmount'
  MIN_DEPOSIT: 'minDeposit'
  MIN_RAISE_AMOUNT: 'minRaiseAmount'
  MIN_TRANSFER: 'minTransfer'
  MIN_WITHDRAW: 'minWithdraw'
  MONTHLY_LIMIT_EXCEEDED: 'monthlyLimitExceeded'
  MORE_PLAYERS_REQUIRED: 'morePlayersRequired'
  MOVE: 'move'
  MUCK_CARDS: 'muckCards'
  MULTIPLE_IP_ADDRESS_ON_TABLE: 'multipleIpAddressOnTable'
  NAME: 'name'
  NEGOTIATING: 'negotiating'
  NEXT_BLIND: 'nextBlind'
  NEXT_HAND: 'nextHand'
  NEXT_LEVEL_DESC: 'nextLevelDesc'
  NEXT_LEVEL_START: 'nextLevelStart'
  NEXT_LEVEL_START_HANDS: 'nextLevelStartHands'
  NEXT_LEVEL_START_TIME: 'nextLevelStartTime'
  NICKNAME_ALREADY_EXISTS: 'nicknameAlreadyExists'
  NICK_NAME: 'nickName'
  NONE: 'none'
  NOTIFY: 'notify'
  NOT_ACCEPTING_FRIENDS: 'notAcceptingFriends'
  NOT_ELIGIBLE: 'notEligible'
  NOT_ELIGIBLE_FOR_COUPON: 'notEligibleForCoupon'
  NOT_ELIGIBLE_FOR_COUPON_USER_ACTIVE: 'notEligibleForCouponUserActive'
  NOT_OLD_ENOUGH: 'notOldEnough'
  NOT_OPTED_IN: 'notOptedIn'
  NOT_PREVIOUS_BALANCE: 'notPreviousBalance'
  NOT_REAL_MONEY: 'notRealMoney'
  NOT_SUPPORTED: 'notSupported'
  NOT_WATCHING: 'notWatching'
  NO_ACTIVE_COUPON: 'noActiveCoupon'
  NO_ACTIVE_SERVERS: 'noActiveServers'
  NO_ADDONS: 'noAddons'
  NO_DEPOSIT: 'noDeposit'
  NO_DEREGISTER: 'noDeregister'
  NO_RATING: 'noRating'
  NO_REBUYS: 'noRebuys'
  NO_UNDO_AVAILABLE: 'noUndoAvailable'
  NO_WAIT_LIST: 'noWaitList'
  NO_WATCHERS_ALLOWED: 'noWatchersAllowed'
  NUMBER_OF_ADDONS: 'numberOfAddons'
  NUMBER_OF_CARDS: 'numberOfCards'
  NUMBER_OF_KO_REBUYS: 'numberOfKoRebuys'
  NUMBER_OF_REBUYS: 'numberOfRebuys'
  OFFER_RING_GAME: 'offerRingGame'
  OFFER_TOURNAMENT: 'offerTournament'
  ON_BREAK: 'onBreak'
  OPERATOR_CHAT: 'operatorChat'
  OUT_OF_TURN_ACTION: 'outOfTurnAction'
  OWN_BOUNTY_PRIZE: 'ownBountyPrize'
  PASSWORD_INCORRECT: 'passwordIncorrect'
  PAYMENT_OPTION_ID: 'paymentOptionId'
  PAYMENT_TYPE_ID: 'paymentTypeId'
  PENDING_DEPOSIT: 'pendingDeposit'
  PENDING_DEPOSIT_EXISTS: 'pendingDepositExists'
  PENDING_REBUY_EXISTS: 'pendingRebuyExists'
  PENDING_TRANSACTION_EXISTS: 'pendingTransactionExists'
  PLAYER_BALANCE: 'playerBalance'
  PLAYER_BALANCE_LIST: 'playerBalanceList'
  PLAYER_BET: 'playerBet'
  PLAYER_CITY: 'playerCity'
  PLAYER_FOLDED: 'playerFolded'
  PLAYER_ID: 'playerId'
  PLAYER_ID_LIST: 'playerIdList'
  PLAYER_JOINED: 'playerJoined'
  PLAYER_NAME: 'playerName'
  PLAYER_NOTIFICATION: 'playerNotification'
  PLAYER_RESTRICTION: 'playerRestriction'
  PLAYER_TIMER: 'playerTimer'
  PLAYER_WINNINGS: 'playerWinnings'
  PLAYER_WINNINGS_LIST: 'playerWinningsList'
  PLAYING: 'playing'
  PLAY_FUN: 'playFun'
  PLAY_REAL: 'playReal'
  POINT: 'point'
  POINTS: 'points'
  POINTS_WON: 'pointsWon'
  POSITION: 'position'
  POSITION_FINISHED: 'positionFinished'
  POSITION_UNAVAILABLE: 'positionUnavailable'
  POSTFLOP: 'postFlop'
  POSTRIVER: 'postRiver'
  POSTTURN: 'postTurn'
  POST_FIRST_DRAW: 'postFirstDraw'
  POST_SECOND_DRAW: 'postSecondDraw'
  POST_THIRD_DRAW: 'postThirdDraw'
  POT_NUMBER: 'potNumber'
  PREFLOP: 'preflop'
  PRE_FIRST_DRAW: 'preFirstDraw'
  PRIZE_LIST: 'prizeList'
  PRIZE_POOL: 'prizePool'
  PROCESSING_ERROR: 'processingError'
  PROMOTION_WON: 'promotionWon'
  PROP_NOT_ALLOWED: 'propNotAllowed'
  PROP_SEAT_NOT_AVAILABLE: 'propSeatNotAvailable'
  PUSH: 'push'
  RABBIT_HUNT: 'rabbitHunt'
  RAIL: 'rail'
  RAISE: 'raise'
  RANK: 'rank'
  RANK_TOO_HIGH: 'rankTooHigh'
  RANK_TOO_LOW: 'rankTooLow'
  RATING: 'rating'
  RATING_TOO_HIGH: 'ratingTooHigh'
  RATING_TOO_LOW: 'ratingTooLow'
  READY: 'ready'
  REAL_MONEY_FIELDS_MISSING: 'realMoneyFieldsMissing'
  REBUY: 'rebuy'
  REBUY_NOT_ALLOWED: 'rebuyNotAllowed'
  REBUYS_REMAINING: 'rebuysRemaining'
  RED: 'red'
  REDEALT: 'redealt'
  REGISTERED: 'registered'
  REGISTRATION_CLOSED: 'registrationClosed'
  REMAINING: 'remaining'
  REQUIRED_FIELD_MISSING: 'requiredFieldMissing'
  RESERVE: 'reserve'
  RETURNED: 'returned'
  RETURN_BLIND: 'returnBlind'
  RIVER: 'river'
  ROULETTE_BET_BLACK: 'rouletteBetBlack'
  ROULETTE_BET_BOXOFSIX: 'rouletteBetBoxofsix'
  ROULETTE_BET_COL1: 'rouletteBetCol1'
  ROULETTE_BET_COL2: 'rouletteBetCol2'
  ROULETTE_BET_COL3: 'rouletteBetCol3'
  ROULETTE_BET_CORNER: 'rouletteBetCorner'
  ROULETTE_BET_EVEN: 'rouletteBetEven'
  ROULETTE_BET_FIRST12: 'rouletteBetFirst12'
  ROULETTE_BET_FIRST4: 'rouletteBetFirst4'
  ROULETTE_BET_FIRST5: 'rouletteBetFirst5'
  ROULETTE_BET_HIGH18: 'rouletteBetHigh18'
  ROULETTE_BET_LOW18: 'rouletteBetLow18'
  ROULETTE_BET_ODD: 'rouletteBetOdd'
  ROULETTE_BET_RED: 'rouletteBetRed'
  ROULETTE_BET_SECOND12: 'rouletteBetSecond12'
  ROULETTE_BET_SPLIT: 'rouletteBetSplit'
  ROULETTE_BET_STRAIGHTUP: 'rouletteBetStraightup'
  ROULETTE_BET_STREET: 'rouletteBetStreet'
  ROULETTE_BET_THIRD12: 'rouletteBetThird12'
  SCORE: 'score'
  SEAT: 'seat'
  SECOND_DRAW: 'secondDraw'
  SECOND_STREET: 'secondStreet'
  SECURITY_TOKEN: 'securityToken'
  SESSION_STATISTICS: 'sessionStatistics'
  SEVENTH_STREET: 'seventhStreet'
  SHOOTOUT_PRIZE_COUPON: 'shootoutPrizeCoupon'
  SHOOTOUT_PRIZE_COUPON_AMOUNT: 'shootoutPrizeCouponAmount'
  SHOOTOUT_PRIZE_MONEY: 'shootoutPrizeMoney'
  SHOOTOUT_PRIZE_MONEY_COUPON: 'shootoutPrizeMoneyCoupon'
  SHOOTOUT_PRIZE_MONEY_COUPON_AMOUNT: 'shootoutPrizeMoneyCouponAmount'
  SHOW: 'show'
  SHOWDOWN: 'showdown'
  SHOW_CARDS: 'showCards'
  SHOW_MUCK: 'showMuck'
  SHOW_MUCK_RABBIT: 'showMuckRabbit'
  SHUTDOWN: 'shutdown'
  SHUTDOWN_PENDING: 'shutdownPending'
  SINGLE_GAME: 'singleGame'
  SIT_IN: 'sitIn'
  SITTINGOUT: 'sittingOut'
  SIXTH_STREET: 'sixthStreet'
  SMALL_BLIND: 'smallBlind'
  SOFT_HAND: 'softHand'
  SPADES: 'spades'
  SPIN_COMPLETED: 'spinCompleted'
  STAKES: 'stakes'
  STAND_UP: 'standUp'
  STOPPED: 'stopped'
  SUCCESS: 'success'
  SUCCESS_IMMEDIATE: 'successImmediate'
  SUIT: 'suit'
  SURRENDER: 'surrender'
  TABLE_FILTERS: 'tableFilters'
  TABLE_NOTIFICATION: 'tableNotification'
  TABLE_NOTIFICATION_CLOSABLE: 'tableNotificationClosable'
  THIRD_DRAW: 'thirdDraw'
  THIRD_STREET: 'thirdStreet'
  TICKET_NOTIFICATION: 'ticketNotification'
  TOKEN_UPDATED: 'tokenUpdated'
  TOO_MANY_ATTEMPTS: 'tooManyAttempts'
  TOO_MANY_CREDIT_CARDS: 'tooManyCreditCards'
  TOO_MANY_FAILED_LOGINS: 'tooManyFailedLogins'
  TOO_MANY_FRIENDS: 'tooManyFriends'
  TOO_MANY_GAMES: 'tooManyGames'
  TOO_MANY_GAMES_OF_TYPE: 'tooManyGamesOfType'
  TOO_MANY_MISSED_BLINDS: 'tooManyMissedBlinds'
  TOO_MANY_TOP_UPS: 'tooManyTopUps'
  TOTAL_SUM_PENDING_DEPOSIT_LIMIT_EXCEEDED: 'totalSumPendingDepositLimitExceeded'
  TOTAL_SUM_PENDING_DEPOSIT_PER_OPTION_LIMIT_EXCEEDED: 'totalSumPendingDepositPerOptionLimitExceeded'
  TOTAL_SUM_PENDING_DEPOSIT_PER_USER_LIMIT_EXCEEDED: 'totalSumPendingDepositPerUserLimitExceeded'
  TOTAL_SUM_PENDING_DEPOSIT_PRE_THRESHOLD_LIMIT_EXCEEDED: 'totalSumPendingDepositPreThresholdLimitExceeded'
  TOTAL_SUM_PENDING_WITHDRAWAL_LIMIT_EXCEEDED: 'totalSumPendingWithdrawalLimitExceeded'
  TOTAL_SUM_PENDING_WITHDRAWAL_PER_OPTION_LIMIT_EXCEEDED: 'totalSumPendingWithdrawalPerOptionLimitExceeded'
  TOTAL_SUM_PENDING_WITHDRAWAL_PER_USER_LIMIT_EXCEEDED: 'totalSumPendingWithdrawalPerUserLimitExceeded'
  TOTAL_SUM_PENDING_WITHDRAWAL_PRE_THRESHOLD_LIMIT_EXCEEDED: 'totalSumPendingWithdrawalPreThresholdLimitExceeded'
  TOURNAMENT_FULL: 'tournamentFull'
  TOURNAMENT_POSITION: 'tournamentPosition'
  TOURNAMENT_POSITION_COUPON: 'tournamentPositionCoupon'
  TOURNAMENT_POSITION_COUPON_AMOUNT: 'tournamentPositionCouponAmount'
  TOURNAMENT_POSITION_MONEY: 'tournamentPositionMoney'
  TOURNAMENT_POSITION_MONEY_COUPON: 'tournamentPositionMoneyCoupon'
  TOURNAMENT_POSITION_MONEY_COUPON_AMOUNT: 'tournamentPositionMoneyCouponAmount'
  TOURNAMENT_REMATCH_FAILURE: 'tournamentRematchFailure'
  TOURNAMENT_SHUTDOWN: 'tournamentShutdown'
  TOURNAMENT_SHUTDOWN_INSUFFICIENT_PLAYERS: 'tournamentShutdownInsufficientPlayers'
  TOURNAMENT_STARTED: 'tournamentStarted'
  TOURNAMENT_UPDATED: 'tournamentUpdated'
  TRANSACTION_APPROVED: 'transactionApproved'
  TRANSACTION_APPROVED_WITH_DESC: 'transactionApprovedWithDesc'
  TRANSACTION_DENIED: 'transactionDenied'
  TRANSACTION_DENIED_ENTROPAY: 'transactionDeniedEntropay'
  TRANSACTION_DENIED_NETELLER: 'transactionDeniedNeteller'
  TRANSACTION_DENIED_OPTION_HELD: 'transactionDeniedOptionHeld'
  TRANSFER: 'transfer'
  TRANSFER_APPROVED: 'transferApproved'
  TRANSFER_APPROVED_RECIPIENT: 'transferApprovedRecipient'
  TRANSFER_DENIED: 'transferDenied'
  TRANSFER_IN: 'transferIn'
  TRANSFER_OUT: 'transferOut'
  TRANSIT_NUMBER: 'transitNumber'
  TRUE: true
  TURN: 'turn'
  UNABLE_TO_PROCESS_CREDITCARD: 'unableToProcessCreditcard'
  UNMARSHAL_ERROR: 'unmarshalError'
  UNRESERVE: 'unreserve'
  UPDATE_PLAYER: 'updatePlayer'
  URL: 'url'
  USERNAME_ALREADY_EXISTS: 'usernameAlreadyExists'
  USER_ALREADY_REGISTERED: 'userAlreadyRegistered'
  USER_AUTO_REGISTERED: 'userAutoRegistered'
  USER_FEATURE_USAGE: 'userFeatureUsage'
  USER_FRIEND: 'userFriend'
  USER_ID: 'userId'
  USER_NOT_REGISTERED: 'userNotRegistered'
  USER_PAYMENT_OPTION_NOT_UNIQUE: 'userPaymentOptionNotUnique'
  USER_SETTINGS: 'userSettings'
  VBV_REQUIRED: 'vbvRequired'
  VERIFICATION_REQUIRED: 'verificationRequired'
  VERIFIED: 'verified'
  WAITING_FOR_BREAK: 'waitingForBreak'
  WAITING_FOR_DEAL: 'waitingForDeal'
  WAITING_FOR_DISCARD_CARD: 'waitingForDiscardCard'
  WAITING_FOR_FACE_CARD_BID: 'waitingForFaceCardBid'
  WAITING_FOR_PLAYERS: 'waitingForPlayers'
  WAITING_FOR_PLAYER_CARD: 'waitingForPlayerCard'
  WAITING_FOR_PROCEED: 'waitingForProceed'
  WAITING_FOR_TOURNAMENT_START: 'waitingForTournamentStart'
  WAITING_FOR_TRUMP_SUIT_BID: 'waitingForTrumpSuitBid'
  WAIT_LIST_LENGTH: 'waitListLength'
  WAIT_LIST_POSITION: 'waitListPosition'
  WALLET: 'wallet'
  WATCHING: 'watching'
  WEEKLY_LIMIT_EXCEEDED: 'weeklyLimitExceeded'
  WIN: 'win'
  WINNING_HAND: 'winningHand'
  WIN_PCT_LIST: 'winPctList'
  WIRE_TRANSFER_DESC: 'wireTransferDesc'
  WIRE_TRANSFER_NAME: 'wireTransferName'
  WITHDRAW: 'withdraw'
  WON_JACKPOT: 'wonJackpot'
  ILLEGAL_SOFTWARE_NAME: 'illegalSoftwareName'
  ILLEGAL_SOFTWARE_ID: 'illegalSoftwareId'

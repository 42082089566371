import classnames from 'classnames'
import React, {useCallback} from 'react'

import styles from './QuickSeat.module.css'
import {useGameFormat} from '../react-hooks.js'

export default function MobileGameFormatButton (props) {
  const {gameFormat, label} = props
  const [currentGameFormat, setGameFormat] = useGameFormat()

  const labelClassName = classnames(styles.mobileTab, {[styles.active]: gameFormat === currentGameFormat})

  const handleClick = useCallback(() => {
    setGameFormat(gameFormat)
  }, [gameFormat, setGameFormat])

  return <div className={labelClassName} onClick={handleClick} >
    <div>Quick Seat</div>
    <div>{label}</div>
  </div>
}

import {EventEmitter} from 'events'

import {
  FAQ,
  GAME_LIST,
  LOBBY,
  POKER_RULES,
  QUICK_SEAT,
  RESPONSIBLE_GAMING,
  RING_GAMES,
  SELECTOR_LIST,
  SETTINGS,
  SUPPORT,
  TABLE,
  TOURNAMENT,
  TOURNAMENTS,
} from '../../routes.js'

export default class Navigator extends EventEmitter {
  constructor (popupMaster, redirectRoute, router, routesByName, user) {
    super()

    const emit = this.emit.bind(this)

    this.start = () => {
      router.subscribe(({route}) => {
        if (!redirectRoute(route)) emitAll(buildRouteEvents(route))
      })

      user.on('change:authenticated', () => { emitAll(buildRouteEvents(router.getState())) })
      router.start()
    }

    // interstitial nav methods (ones that do not change the route)
    this.navOffline = ({title, reason, message}) => { emitAll(buildNavEvents('offline', {title, reason, message})) }
    this.navMaintenance = () => { emitAll(buildNavEvents('maintenance')) }

    // nav methods that change the route
    this.navFaq = buildNavMethod(FAQ)
    this.navPokerRules = buildNavMethod(POKER_RULES)
    this.navQuickSeat = buildNavMethod(QUICK_SEAT)
    this.navResponsibleGaming = buildNavMethod(RESPONSIBLE_GAMING)
    this.navRingGames = buildNavMethod(RING_GAMES)
    this.navSelectorList = buildNavMethod(SELECTOR_LIST)
    this.navSettings = buildNavMethod(SETTINGS)
    this.navSupport = buildNavMethod(SUPPORT)
    this.navTournaments = buildNavMethod(TOURNAMENTS)

    const {s1: defaultS1, s2: defaultS2} = routesByName[GAME_LIST].defaultParams

    this.navGameList = (s1 = defaultS1, s2 = defaultS2) => {
      router.navigate(GAME_LIST, {s1, s2})
    }

    // no need to redirect via /lobby, just go straight to the preferred lobby
    this.navLobby = navigateToLobby

    // triggers for opening games - the listing will be located, then passed back to one of the handlers below
    this.openTableLink = tableId => { router.navigate(TABLE, {tableId: String(tableId)}) }
    this.openTournamentLink = tournamentId => { router.navigate(TOURNAMENT, {tournamentId: String(tournamentId)}) }

    // handlers for opening games
    this.navPopup = ({instanceId}) => popupMaster?.focusPopup(instanceId)
    this.navTable = table => {
      if (this.navPopup(table)) return

      const params = {tableId: String(table.instanceId)}

      if (router.isActive(TABLE, params)) {
        // route has already been changed, we can now emit events
        emitAll(buildNavEvents('table', table))
      } else {
        // navTable was called directly, without changing the route. So we
        // trigger a navigation, which triggers openTableLink, which causes
        // navTable to be called again after the route has changed.
        router.navigate(TABLE, params)
      }
    }
    this.navTournament = tournament => {
      if (this.navPopup(tournament)) return

      const params = {tournamentId: String(tournament.instanceId)}

      if (router.isActive(TOURNAMENT, params)) {
        // route has already been changed, we can now emit events
        emitAll(buildNavEvents('tournament', tournament))
      } else {
        // navTournament was called directly, without changing the route. So we
        // trigger a navigation, which triggers openTableLink, which causes
        // navTournament to be called again after the route has changed.
        router.navigate(TOURNAMENT, params)
      }
    }
    this.closeTournamentLobbyModal = () => {
      const {params: {closeTo}} = router.getState()

      if (closeTo) {
        router.navigate(closeTo)
      } else {
        navigateToLobby()
      }
    }

    // support the old "location" property
    Object.defineProperty(this, 'location', {
      get () {
        const {name, params} = router.getState()
        const navName = findNavName(name)

        switch (name) {
          case TABLE: return `${navName}${params.tableId}`
          case TOURNAMENT: return `${navName}${params.tournamentId}`
        }

        return navName
      },
    })

    function buildRouteEvents ({name, params}) {
      if (!user.authenticated) return buildNavEvents('login')

      const navName = findNavName(name)

      switch (name) {
        case FAQ: return buildNavEvents(navName)
        case GAME_LIST: return buildNavEvents(navName, params.s1, params.s2)
        case POKER_RULES: return buildNavEvents(navName)
        case QUICK_SEAT: return buildNavEvents(navName)
        case RESPONSIBLE_GAMING: return buildNavEvents(navName)
        case RING_GAMES: return buildNavEvents(navName)
        case SELECTOR_LIST: return buildNavEvents(navName)
        case SETTINGS: return buildNavEvents(navName)
        case SUPPORT: return buildNavEvents(navName)
        case TOURNAMENTS: return buildNavEvents(navName)

        case TABLE: return [['open:tableLink', parseInt(params.tableId, 10)]]
        case TOURNAMENT: return [['open:tournamentLink', parseInt(params.tournamentId, 10)]]
      }

      return buildNavEvents('notFound')
    }

    function buildNavEvents (name, ...args) {
      return [
        ['nav', name, ...args],
        [`nav:${name}`, ...args],
      ]
    }

    function buildNavMethod (name) {
      return () => { router.navigate(name) }
    }

    function findNavName (name) {
      return routesByName[name]?.navName ?? ''
    }

    function navigateToLobby () {
      const lobbyRoute = redirectRoute({name: LOBBY})
      router.navigate(lobbyRoute.name, lobbyRoute.params)
    }

    function emitAll (events) {
      for (const event of events) emit(...event)
    }
  }
}

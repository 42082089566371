import ResizeObserver from 'resize-observer-polyfill'

const observer = new ResizeObserver(handleResize)

export default function tableFontResizer (container) {
  observer.observe(container)

  return () => observer.unobserve(container)
}

function handleResize (entries) {
  window.requestAnimationFrame(() => {
    for (const entry of entries) {
      const {
        contentRect: {width, height},
      } = entry

      if (width === 0 || height === 0) return

      const ratio = 10 / 6 // table height is 60% of its width
      const fontSizeRatio = 0.012 // for every 1000px the font wil increase by 12px

      if (width < height * ratio) {
        entry.target.style.fontSize = `${Math.max(4, width * fontSizeRatio)}px`
      } else {
        entry.target.style.fontSize = `${Math.max(4, height * fontSizeRatio * ratio)}px`
      }
    }
  })
}

import {combineReducers} from 'redux'
import {router5Reducer as router} from 'redux-router5'

import {reducer as gameList} from './game-list/index.js'
import {NAME as gameListNs} from './game-list/ns.js'
import {reducer as ownGames} from './own-games/index.js'
import {NAME as ownGamesNs} from './own-games/ns.js'
import {reducer as quickSeat} from './quick-seat/index.js'
import {NAME as quickSeatNs} from './quick-seat/ns.js'
import {reducer as responsibleGaming} from './responsible-gaming/index.js'
import {NAME as responsibleGamingNs} from './responsible-gaming/ns.js'
import {reducer as rooms} from './rooms/index.js'
import signal from './signal/signal-slice.js'
import {reducer as tournament} from './tournament/index.js'
import {NAME as tournamentNs} from './tournament/ns.js'
import wallet from './wallet/wallet-slice.js'

export default combineReducers({
  router,

  [gameListNs]: gameList,
  [ownGamesNs]: ownGames,
  [quickSeatNs]: quickSeat,
  [responsibleGamingNs]: responsibleGaming,
  rooms,
  signal,
  [tournamentNs]: tournament,
  wallet,
})

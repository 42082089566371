import classnames from 'classnames'
import React from 'react'

import styles from './QuickSeat.module.css'
import skeleton from '../../skeleton.module.css'

export default function Button (props) {
  const {children, isSelected, isStake, onClick} = props

  const labelClassName = classnames(styles.button, styles.label, {[styles.selected]: isSelected})
  const iconClassName = classnames(styles.icon, skeleton.dark, {
    [styles.stake]: isStake,
    [styles.selected]: isSelected,
  })

  return <label className={labelClassName} onClick={onClick} >
    <span className={iconClassName}>
      <span className='fa fa-arrow-right' />
    </span>
    {children}
  </label>
}

import Collection from './collection.coffee'
import {GAME_FORMAT} from '../../game-list/constants.js'

{RING_GAME, SCHEDULED_TOURNAMENT, SITNGO_TOURNAMENT} = GAME_FORMAT

export default class GameList extends Collection
  constructor: (@settings, @gameFilter) ->
    super()
    @filterStrings = {}
    @lastCount = null
    @gameListReceived = false

    if not @gameFilter
      throw Error("Gamelist must be passed a filter")

    @gameFilter.on 'filter', @filter
    @gameFilter.on 'change:menuSort', => @emit 'sort'

    @on 'add', @addListing

    @on 'gameListReturned', =>
      # note: we cannot wire up the Navigator events in here becaues we need them to continue
      # changing the active filter while wc navigates before the game list to loads
      # once we received the games we then do the filter
      @gameListReceived = true
      @cleanUp()
      @filter(@gameFilter)

  cleanUp: =>
    @each (listing) =>
      if listing.gameState in ['Cancelled', 'Shutdown', 'Shutdown (Refunded)', 'Finished'] and !listing.lobbyOpen
        @rem listing

  refresh: =>
    @emit 'requestGameLists', @gameFilter.listTypes

  addListing: (listing) =>

    if listing.instanceId in @settings.pinnedListings
      listing.pinned = true

    listing.on 'action', (actionName, args = {}) =>
      args.listing = listing
      args.serverId = listing.serverId
      args.instanceId = listing.instanceId
      @emit 'action', actionName, args

    listing.on 'change:pinned', =>
      pinned = @settings.pinnedListings
      if listing.pinned
        pinned.push listing.instanceId
      else
        pinned = pinned.filter (name) -> name isnt listing.instanceId
      @settings.pinnedListings = pinned

  filter: (filter) =>
    # if the gameslist is empty there's not point filtering or setting up filter change checking
    if @gameListReceived is false
      return false

    filter ?= @gameFilter

    # Retrieve all the criteria from the filter once
    # to stop looking up local storage.
    # Then assign it to a local object
    {
      entry
      gameListGameFormat = RING_GAME
      gameListRingGameCriteria = {}
      gameTypeIds
      limitTypes
      listTypes
      speeds
      stakes
      tables
    } = filter

    {
      shouldShowEmpty: showEmpty = true
      shouldShowFull: showFull = true
    } = gameListRingGameCriteria

    switch gameListGameFormat
      when RING_GAME
        criteria = {listTypes, showEmpty, showFull, limitTypes}
      when SITNGO_TOURNAMENT
        criteria = {listTypes, speeds}
      when SCHEDULED_TOURNAMENT
        criteria = {listTypes, gameTypeIds}

    # This is done in a convoluted way because coffee doesn't let you break
    # out of nested loops, however, you can however return from a function
    # to get the same result.
    for _, listing of @members
      #
      if @testCriteria(listing, criteria)
        # Passed all the filters
        listing.visible = true

    tables = @where(visible: true).length
    # When all done
    @emit 'onlineStats', {tables}
    @emit 'sort'

  # For each criteria that is passed in, run the test with that key.
  # Each test returns true if the listing should stay visible
  # or false if it should be hidden. Once hidden the loop breaks
  # and the next listing is tested.
  testCriteria: (listing, criteria) =>
    if not @showPlaying(listing)
      for test, restriction of criteria
        if not this[test]
          # console.warn 'no filter for', test
          return false
        unless this[test] listing, restriction
          listing.visible = false
          # console.log 'hidden cause of', test
          return false
    return true

  # scheduleType
  listTypes: (listing, listTypes) ->
    if listing.listType not in listTypes
      return false
    else
      return true

  # limit money type (note comes before pinned)
  realMoney: (listing, realMoney) ->
    if listing.realMoney is realMoney
      return true
    else
      return false

  # Stakes - only for ring games.
  stakes: (listing, stakes) ->
    if listing.limitType is 'fixedLimit' and !(stakes.min < listing.maxBet <= stakes.max)
      return false

    if listing.limitType in ['noLimit', 'potLimit'] and !(stakes.min < listing.minBet <= stakes.max)
      return false

    return true

  # Entry Fee - only for tournaments
  entry: (listing, entry) ->
    if entry.min < listing.entry <= entry.max
      return true
    else
      return false

  # limit type
  limitTypes: (listing, limitTypes) ->
    if listing.limitType in limitTypes
      return true
    else
      return false

  # maxPlayers
  maxPlayers: (listing, maxPlayers) ->
    if maxPlayers and listing.maxPlayers not in maxPlayers
      return false
    else
      return true

  maxPlayersPerTable: (listing, maxPlayersPerTable) ->
    if maxPlayersPerTable and listing.maxPlayersPerTable not in maxPlayersPerTable
      return false
    else
      return true

  # selectorfilter tables
  tables: (listing, tables) ->
    switch tables
      when 'all'
        return true
      when 'single'
        if listing.maxPlayers is listing.maxPlayersPerTable
          return true
      when 'headsUp'
        if listing.maxPlayers is 2
          return true
      when 'multi'
        if listing.maxPlayers > listing.maxPlayersPerTable
          return true
      when 'sixMax'
        if listing.maxPlayers is 6
          return true
      when 'fullRing'
        if listing.maxPlayers in [9, 10]
          return true
      when 'satellite'
        if listing.isSatellite
          return true
    return false

  playersInFlopPct: (listing, playersInFlopPct) ->
    if playersInFlopPct and playersInFlopPct > listing.playersInFlopPct
      return false
    else
      return true

  # averagePot
  averagePot: (listing, averagePot) ->
    if averagePot and averagePot > Math.floor(listing.averagePot / listing.minBet)
      return false
    else
      return true

  # handsPerHour
  handsPerHour: (listing, handsPerHour) ->
    if handsPerHour and handsPerHour > listing.handsPerHour
      return false
    else
      return true

  # speed
  speeds: (listing, speeds) ->
    if listing.speed in speeds
      return true
    else
      return false

  gameStates:  (listing, gameStates) ->
    if listing.gameState in gameStates
      return true
    else
      return false

  # empty/full tables
  showEmpty: (listing, showEmpty) ->
    if not showEmpty and listing.playerCount is 0
      return false
    else
      return true

  showFull: (listing, showFull) ->
    if not showFull and listing.playerCount is listing.maxPlayers
      return false
    else
      return true

  # pinned listings and playing are always visible (not anymore as of QA-2919)
  showPlaying: (listing) ->
    if listing.pinned #or listing.playing or listing.registered
      listing.visible = true
      return true
    else
      return false

  minPlayersSeated: (listing, minPlayersSeated) ->
    if minPlayersSeated and minPlayersSeated > listing.playerCount
      return false
    else
      return true

  startTime: (listing, startTime) ->
    if startTime and (listing.startTime - new Date) > (startTime * 60 * 60 * 1000) # startTime is in hours
      return false
    else
      return true

  gameTypeIds: (listing, gameTypeIds) ->
    if listing.gameTypeId not in gameTypeIds
      return false
    else
      return true

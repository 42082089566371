import StatusMessageView from './statusMessageView.coffee'
import View from './view.coffee'

import {STATUS_RELOAD_REQUIRED, STATUS_RESTART_REQUIRED} from '../../updating/service.js'

export default class OfflineView extends View
  constructor: (app, @updatingService) ->
    super()

    @model = app

    @dom = $ '<div class="fullscreen-container">'

    @components =
      scroll:  $ '<div class="scroll-container">'
      logo:    $ '<div class="dynamic-logo medium">'
      status:  new StatusMessageView @model
      reload:  $ '<button class="button button-cta">Reload</button>'

    @components.reload.on 'click', @restart

    @initializeView()
    return this

  render: ->
    @dom.append @components.scroll
    @components.scroll.append [
      @components.logo,
      @components.status.render(),
      @components.reload
    ]

    @dom.hide()
    @dom

  title: (title) => @components.status.title(title)

  reason: (reason) => @components.status.reason(reason)

  message: (message) => @components.status.message(message)

  buttonText: (text) => @components.reload.text text

  restart: =>
    @model.leaveWaitlists()
    status = @updatingService.status

    if status is STATUS_RELOAD_REQUIRED or status is STATUS_RESTART_REQUIRED
      @updatingService.update()
    else
      window.location.reload()

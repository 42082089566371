# PlayerDetailsData
#  int position
#  String nickname
#  BigDecimal balance
#  String[] cards
#  String stateCode
#  String location
#  boolean chatIgnored
#  BigDecimal betAmount
#  String playerBoxColor
#  int preferredSeat
#  boolean promotionalPlayer
#  boolean avatarHidden
import Mixmaster from '../../../mixmaster.coffee'
import WclEnum from '../../../../enum/index.coffee'

export default class PlayerDetailsData extends Mixmaster
  mixins = []
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

    signal.playerDetails = []

  mixin: (object) ->
    mixins.map((mixin) => (new mixin).mixin(object))

    object.playerDetails = (signal, name, values) ->
      # WEB-1544
      # If players join a tournament and are folded
      # their player cards would remain hidden because
      # their status doesn't change until they forcibly
      # sit out and sit back in.
      status = values.code5
      if status is 'folded'
        cssClass = 'fold'
        status = 'in'

      unless values.playerId is '-1'
        details =
          position: parseInt(values.playerId)
          alias: values.playerName
          balance: parseFloat(values.playerBalance)
          location: values.playerCity
          status: status
          cssClass: cssClass or ''
          instanceId: parseInt(signal.message.instanceId)
          cards: WclEnum.getCards(values.cards)
          bet: parseFloat(values.betAmount)

        signal.playerDetails.push details


# <message id="0" service="CLIENT" type="NOTIFY" sessionId="627928" routerId="61" clientId="173" serverId="161" instanceId="7641905" responseId="4">
# <function name="GAME_STATUS">
# <argument name="CURRENT_STATE" code1="WAITING_FOR_PLAYERS"/>
# <argument name="NUMBER_PLAYERS" code1="WATCHING" value1="1" code2="WAIT_LIST_LENGTH" value2="0" code3="PLAYING" value3="1"/>
# <argument name="EVENT" code1="JOIN_GAME" code2="PLAYER_ID" value2="0" code3="PLAYER_NAME" value3="webclient3" code4="PLAYER_BALANCE" value4="1.60" code5="MORE_PLAYERS_REQUIRED" code6="PLAYER_CITY" value6="United States" code7="DATA" code8="FALSE"/>
# <argument name="ACCOLADE" code1="DATA" value1=""/>
# </function>
# </message>

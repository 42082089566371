import React from 'react'

import ListingHeader from './ListingHeader.js'
import EmptyHeader from '../../../components/game-listing/table/EmptyHeader.js'
import HeaderGroup from '../../../components/game-listing/table/HeaderGroup.js'
import Row from '../../../components/game-listing/table/Row.js'
import styles from '../../../components/game-listing/table/Table.module.css'
import {KEY_ENTRY_COST, KEY_FULLNESS, KEY_GAME, KEY_NAME, KEY_REGISTRANTS, KEY_STATUS} from '../../sorting.js'

export default function ListingHeaders () {
  return <HeaderGroup>
    <Row>
      <ListingHeader label='Starts' sortKey={KEY_FULLNESS} />
      <ListingHeader label='Name' sortKey={KEY_NAME} className={styles.primary} />
      <ListingHeader label='Game' sortKey={KEY_GAME} />
      <ListingHeader label='Buy-In' sortKey={KEY_ENTRY_COST} />
      <ListingHeader label='Plrs' sortKey={KEY_REGISTRANTS} />
      <ListingHeader label='Status' sortKey={KEY_STATUS} />
      <EmptyHeader />
    </Row>
  </HeaderGroup>
}

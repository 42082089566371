import {isZero} from '@pipehat/money/plain-object'
import React from 'react'

import Money from '../../../../money/components/Money.js'
import Row from '../../components/Row.js'

export default function LevelDescription (props) {
  const {level} = props
  const {ante, number, smallBlind, bigBlind} = level

  return <Row>
    Lvl: {number}{' '}
    {ante && !isZero(ante) && <>Ant: <Money value={ante} /> </>}
    B: <Money value={smallBlind} />/<Money value={bigBlind} />
  </Row>
}

import classnames from 'classnames'
import React, {useMemo} from 'react'

import {useRootedSelector} from '../../../redux.js'
import {createIsSelfRegistering, createIsSelfRegistrationAllowed, getRoot} from '../../index.js'

export default function RegisterButton (props) {
  const canRegister = useRootedSelector(getRoot, useMemo(createIsSelfRegistrationAllowed, []), props)
  const isSelfRegistering = useRootedSelector(getRoot, useMemo(createIsSelfRegistering, []), props)
  const isEnabled = canRegister && !isSelfRegistering

  const className = classnames('button', {'button-disabled': !isEnabled})

  return <button type='submit' className={className} disabled={!isEnabled}>
    <span className='fa fa-ticket button-icon' /> Register Now
  </button>
}

import {BALANCE_LOADED} from '@pipehat/chronicle/constants/event-type'
import {createSlice} from '@reduxjs/toolkit'

const walletSlice = createSlice({
  name: 'wallet',
  initialState: {}, // Map of currency to money-js
  extraReducers: {
    [BALANCE_LOADED]: (state, {payload}) => {
      state[payload.currencyCode] = payload
    },
  },
})

export default walletSlice.reducer

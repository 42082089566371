import classnames from 'classnames'
import React, {useCallback} from 'react'
import {useRouter} from 'react-router5'

import DesktopTabButtons from './DesktopTabButtons.js'
import ExternalTabButton from './ExternalTabButton.js'
import styles from './Header.module.css'
import MobileTabButtons from './MobileTabButtons.js'
import TabButton from './TabButton.js'
import {DESKTOP} from '../../device/constants.js'
import {useFormat} from '../../device/react-hooks.js'
import {useToggle} from '../../menu/react-hooks.js'
import {QUICK_SEAT} from '../../routes.js'
import {useStatus} from '../../updating/react-hooks.js'
import {STATUS_RELOAD_REQUIRED, STATUS_RESTART_REQUIRED} from '../../updating/service.js'

export default function Header () {
  const router = useRouter()
  const updateStatus = useStatus()
  const isUpdateAvailable = updateStatus === STATUS_RELOAD_REQUIRED || updateStatus === STATUS_RESTART_REQUIRED
  const isDesktop = useFormat() === DESKTOP

  const icon = classnames('icon', 'icon-menu', {
    [styles.iconMenuUpdate]: isUpdateAvailable,
  })

  const handleClickLogo = useCallback(() => {
    router.navigate(QUICK_SEAT)
  }, [router])

  return <div className={styles.lobbyHeader}>
    <TabButton icon={icon} onClick={useToggle()} />
    <div className={styles.logo} onClick={handleClickLogo} />
    {isDesktop && <DesktopTabButtons />}
    <ExternalTabButton icon='cashier' label='Cashier' urlName='cashier.overview' />
    {!isDesktop && <MobileTabButtons />}
  </div>
}

import React from 'react'

import LimitTypeSelection from './LimitTypeSelection.js'
import StakesRangeSelection from './StakesRangeSelection.js'
import VariantSelection from './VariantSelection.js'

export default function DesktopSelection () {
  return <>
    <VariantSelection />
    <LimitTypeSelection />
    <StakesRangeSelection />
  </>
}

import React, {useEffect} from 'react'
import {useRouteNode, useRouter} from 'react-router5'
import {startsWithSegment} from 'router5-helpers'

import GameListScreen from '../../game-list/screens/GameList.js'
import Header from '../../header/components/Header.js'
import MobileMenu from '../../menu/components/MobileMenu.js'
import OwnRingGamesScreen from '../../own-games/screens/OwnRingGames.js'
import OwnTournamentsScreen from '../../own-games/screens/OwnTournaments.js'
import QuickSeatScreen from '../../quick-seat/screens/QuickSeat.js'
import ResponsibleGaming from '../../responsible-gaming/screens/ResponsibleGaming.js'

import {
  FAQ,
  GAME_LIST,
  LOBBY,
  POKER_RULES,
  QUICK_SEAT,
  RESPONSIBLE_GAMING,
  RING_GAMES,
  SETTINGS,
  SUPPORT,
  TABLE,
  TOURNAMENT,
  TOURNAMENTS,
} from '../../routes.js'

import SettingsScreen from '../../settings/screens/Settings.js'
import FaqScreen from '../../support/screens/Faq.js'
import PokerRulesScreen from '../../support/screens/PokerRules.js'
import SupportScreen from '../../support/screens/Support.js'
import TournamentLobby from '../../tournament/components/TournamentLobby.js'

const availableRoute = [
  FAQ,
  GAME_LIST,
  LOBBY,
  POKER_RULES,
  QUICK_SEAT,
  RESPONSIBLE_GAMING,
  RING_GAMES,
  SETTINGS,
  SUPPORT,
  TABLE,
  TOURNAMENT,
  TOURNAMENTS,
]

export default function MobileLobby (props) {
  const {isElectron, isPrivileged, appName} = props
  const {route} = useRouteNode('')
  const router = useRouter()
  const testRoute = startsWithSegment(route)

  const {name: routeName, params: {tournamentId}} = router.getState() || {}

  useEffect(() => {
    if (!availableRoute.includes(routeName)) {
      router.navigate(QUICK_SEAT)
    }
  }, [routeName, router])

  const shouldShowGameList = routeName === GAME_LIST
  const shouldShowQuickSeat = routeName === QUICK_SEAT
  const shouldShowOwnTournaments = testRoute(TOURNAMENTS)

  return <>
    <Header />
    <MobileMenu />
    {testRoute(SETTINGS) && <SettingsScreen isElectron={isElectron} isPrivileged={isPrivileged} />}
    {shouldShowGameList && <GameListScreen />}
    {shouldShowQuickSeat && <QuickSeatScreen />}
    {testRoute(RING_GAMES) && <OwnRingGamesScreen />}
    {shouldShowOwnTournaments && <OwnTournamentsScreen />}
    {testRoute(SUPPORT) && <SupportScreen />}
    {testRoute(FAQ) && <FaqScreen site={appName} />}
    {testRoute(POKER_RULES) && <PokerRulesScreen />}
    {testRoute(RESPONSIBLE_GAMING) && <ResponsibleGaming />}
    {testRoute(TOURNAMENT) && tournamentId && <TournamentLobby tournamentId={parseInt(tournamentId, 10)} />}
  </>
}

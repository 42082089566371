import React from 'react'

import BuyInOptionsSelect from './BuyInOptionsSelect.js'

export default function BuyInOptions (props) {
  const {hasEligibility, onChange, paymentOptions, value} = props

  return <>
    <div className='small light'>Buy-In Options</div>
    <BuyInOptionsSelect
      hasEligibilityProfile={hasEligibility}
      paymentOptions={paymentOptions}
      value={value}
      onChange={onChange}
    />
  </>
}

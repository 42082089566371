import React from 'react'

import ButtonGroup from './ButtonGroup.js'
import RingGameStakesButtonGroup from './RingGameStakesButtonGroup.js'
import RingGameStakesButtonGroupSkeleton from './RingGameStakesButtonGroupSkeleton.js'
import {DESKTOP} from '../../device/constants.js'
import {useFormat} from '../../device/react-hooks.js'

export default function RingGameStakesSelection ({isSkeleton}) {
  const device = useFormat()

  return <ButtonGroup isSkeleton={isSkeleton} label='Stake' number={device === DESKTOP ? '3' : '2'}>
    {isSkeleton ? <RingGameStakesButtonGroupSkeleton /> : <RingGameStakesButtonGroup />}
  </ButtonGroup>
}

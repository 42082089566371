import {OMAHA_HOLDEM, OMAHA_HOLDEM_HILO, TEXAS_HOLDEM} from '@pipehat/chronicle/constants/game-variant'
import {FIXED_LIMIT, HALF_POT_LIMIT, NO_LIMIT, POT_LIMIT} from '@pipehat/chronicle/constants/limit-type'

import {
  ANNOUNCED,
  CANCELED,
  CREATED,
  DEREGISTERING,
  FINISHED,
  IN_PROGRESS,
  LATE_REGISTRATION,
  REGISTERING,
  SHUT_DOWN,
  SHUT_DOWN_REFUNDED,
} from '@pipehat/chronicle/constants/tournament-status'

export function renderGameLimitAndVariantInitialism ({gameTypes, limitType}) {
  return `${renderLimitTypeInitialism(limitType)}${renderGameTypesInitialism(gameTypes)}`
}

export function renderGameLimitAndVariant ({gameTypes, limitType}) {
  return `${renderLimitType(limitType)} ${renderGameType(gameTypes)}`
}

export function renderGameType (gameTypes) {
  if (gameTypes.length !== 1) return '???'

  const [{variant}] = gameTypes

  switch (variant) {
    case OMAHA_HOLDEM: return 'Omaha'
    case OMAHA_HOLDEM_HILO: return 'Omaha Hi/Lo'
    case TEXAS_HOLDEM: return 'Hold\'em'
  }

  return '???'
}

export function renderGameTypesInitialism (gameTypes) {
  if (gameTypes.length !== 1) return '???'

  const [{variant}] = gameTypes

  switch (variant) {
    case OMAHA_HOLDEM: return 'O'
    case OMAHA_HOLDEM_HILO: return 'O H/L'
    case TEXAS_HOLDEM: return 'HE'
  }

  return '???'
}

export function renderLimitType (limitType) {
  switch (limitType) {
    case FIXED_LIMIT: return 'Fixed Limit'
    case HALF_POT_LIMIT: return 'Half Pot Limit'
    case NO_LIMIT: return 'No Limit'
    case POT_LIMIT: return 'Pot Limit'
  }

  return '???'
}

export function renderLimitTypeInitialism (limitType) {
  switch (limitType) {
    case FIXED_LIMIT: return 'FL'
    case HALF_POT_LIMIT: return 'HPL'
    case NO_LIMIT: return 'NL'
    case POT_LIMIT: return 'PL'
  }

  return '???'
}

export function renderOrdinal (number) {
  if (!Number.isFinite(number) || number < 1) {
    throw new Error(`Unable to determine ordinal for ${JSON.stringify(number)}`)
  }

  const j = number % 10
  const k = number % 100

  if (j === 1 && k !== 11) return `${number}st`
  if (j === 2 && k !== 12) return `${number}nd`
  if (j === 3 && k !== 13) return `${number}rd`

  return `${number}th`
}

export function renderTournamentStatus (status) {
  switch (status) {
    case ANNOUNCED: return 'Announced'
    case CANCELED: return 'Canceled'
    case CREATED: return 'Created'
    case DEREGISTERING: return 'De-registering'
    case FINISHED: return 'Finished'
    case IN_PROGRESS: return 'In Progress'
    case LATE_REGISTRATION: return 'Late Registration'
    case REGISTERING: return 'Registering'
    case SHUT_DOWN: return 'Shut Down'
    case SHUT_DOWN_REFUNDED: return 'Shut Down (Refunded)'
  }

  return '???'
}

import {FIXED_LIMIT} from '@pipehat/chronicle/constants/limit-type'
import {useEffect, useState} from 'react'

import {useBalance as useWalletBalance} from '../../wallet/react-hooks.js'
import {useBank} from '../context.js'
import {useService} from '../react-hooks.js'

export const useBalance = () => useWalletBalance(useSelector(({currency}) => currency))
export const useIsAvailable = () => useSelector(({isAvailable}) => isAvailable)
export const useIsExpanded = () => useSelector(({isExpanded}) => isExpanded)
export const useIsFixedLimit = () => useSelector(({stakes}) => stakes?.limitType === FIXED_LIMIT)
export const useMinimumBuyIn = () => useSelector(({minimumBuyIn}) => minimumBuyIn)
export const useSetBuyInAmount = () => useService().setBuyInAmount
export const useSetRingGameRebuyAmount = () => useService().setRingGameRebuyAmount
export const useShowInsufficientFundsForBuyIn = () => useService().showInsufficientFundsForBuyIn
export const useShowInsufficientFundsForRingGameRebuy = () => useService().showInsufficientFundsForRingGameRebuy
export const useStakes = () => useSelector(({stakes}) => stakes)

export const useBuyInAmount = () => {
  const {buyInAmount, subscribeToBuyInAmount} = useService()
  const [reactValue, setReactValue] = useState(buyInAmount())

  useEffect(() => {
    return subscribeToBuyInAmount(buyInAmount => {
      setReactValue(buyInAmount)
    })
  }, [subscribeToBuyInAmount])

  return reactValue
}

export const useMaximumBuyIn = () => {
  const balance = useBalance()
  const isFixedLimit = useIsFixedLimit()
  const maximumBuyIn = useSelector(({maximumBuyIn}) => maximumBuyIn)

  // fixed limit has no maximum buy-in and is only limited by own balance
  if (isFixedLimit) return balance

  return maximumBuyIn
}

export const usePreviousBalance = () => {
  const {previousBalance, subscribeToPreviousBalance} = useService()
  const [reactValue, setReactValue] = useState(previousBalance())

  useEffect(() => {
    return subscribeToPreviousBalance(previousBalance => {
      setReactValue(previousBalance)
    })
  }, [subscribeToPreviousBalance])

  return reactValue
}

export const useRingGameRebuyAmount = () => {
  const {ringGameRebuyAmount, subscribeToRingGameRebuyAmount} = useService()
  const [reactValue, setReactValue] = useState(ringGameRebuyAmount())

  useEffect(() => {
    return subscribeToRingGameRebuyAmount(ringGameRebuyAmount => {
      setReactValue(ringGameRebuyAmount)
    })
  }, [subscribeToRingGameRebuyAmount])

  return reactValue
}

function useSelector (selector) {
  return selector(useBank())
}

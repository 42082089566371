import React, {useMemo} from 'react'

import Money from '../../../money/components/Money.js'
import {useRootedSelector} from '../../../redux.js'
import {createGetStartingStack, getRoot} from '../../index.js'

export default function StartingChips (props) {
  const startingStack = useRootedSelector(getRoot, useMemo(createGetStartingStack, []), props)

  return <div className='kvpair selfclear'>
    <label>Starting Chips:</label><span>
      {startingStack && <Money value={startingStack} />}
    </span>
  </div>
}

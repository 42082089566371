import React from 'react'

import {useWindowTitle} from '../../global/react-hooks.js'
import Format from '../components/Format.js'
import GameSettingsGroup from '../components/GameSettingsGroup.js'
import GeneralSettingsGroup from '../components/GeneralSettingsGroup.js'
import NotificationsGroup from '../components/NotificationsGroup.js'
import PreferredSeatingGroup from '../components/PreferredSeatingGroup.js'
import VersionInformationGroup from '../components/VersionInformationGroup.js'

const windowTitle = 'Settings'

export default function Settings (props) {
  const {isElectron, isPrivileged} = props

  useWindowTitle(windowTitle)

  return <div>
    {!isElectron && <Format />}
    <GeneralSettingsGroup />
    <GameSettingsGroup isPrivileged={isPrivileged} />
    {'Notification' in window && <NotificationsGroup />}
    <PreferredSeatingGroup />
    <VersionInformationGroup />
  </div>
}

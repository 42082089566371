import React, {useCallback} from 'react'

import MenuItemContent from './MenuItemContent.js'
import {useOpen} from '../../named-url/react-hooks.js'

export default function NamedUrlMenuItem (props) {
  const {children, icon, urlName} = props
  const open = useOpen(urlName)

  const handleClick = useCallback(event => {
    event.preventDefault()
    open()
  }, [open])

  return <a onClick={handleClick}>
    <MenuItemContent icon={icon}>{children}</MenuItemContent>
  </a>
}

import React from 'react'
import {Link} from 'react-router5'

import styles from './AdvancedOptions.module.css'
import AutoRebuy from './auto-rebuy/AutoRebuy.js'
import Toggle from './auto-rebuy/components/Toggle.js'
import AddChipsButton from './components/AddChipsButton.js'
import AutoMuckOption from './components/AutoMuckOption.js'
import AutoPostBlindsOption from './components/AutoPostBlindsOption.js'
import ContactSupport from './components/ContactSupport.js'
import LeaveGameButton from './components/LeaveGameButton.js'
import TournamentLobbyButton from './components/TournamentLobbyButton.js'
import {useIsAutoRebuy, useIsTournament} from './react-hooks.js'
import {SETTINGS} from '../../routes.js'

export default function AdvancedOptions (props) {
  const {hideModal} = props
  const isAutoRebuy = useIsAutoRebuy()
  const isTournament = useIsTournament()

  return <div className={styles.gameOptions}>
    <div className={styles.leftSide}>
      <div>Auto</div>
      <AutoMuckOption />
      <AutoPostBlindsOption />
      {!isTournament && <Toggle />}
    </div>

    <div className={styles.rightSide}>
      <div>
        <ContactSupport onClick={hideModal} />
        <Link routeName={SETTINGS}>Settings <span className='fa fa-cog' /></Link>
      </div>

      <div>
        <AddChipsButton onClick={hideModal} />
        {!isTournament && <LeaveGameButton />}
        {isTournament && <TournamentLobbyButton />}
      </div>
    </div>

    {!isTournament && isAutoRebuy && <AutoRebuy />}
  </div>
}

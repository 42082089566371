import Request from './request.coffee'

export default class LoginUserRequest extends Request
  constructor: ({message, credentials, casinoId}) ->
    super arguments...

    @function = 'LOGIN_USER'

    @attributes =
      type: 'REQUEST'
      service: 'AUTHENTICATION'
      routerId: 0
      serverId: 0
      instanceId: 0
      sessionId: 0
      clientId: 0
      requestTime: 0

    @arguments =
      casino_id: casinoId
      guid: credentials.uuid.replace(/-/g,'',)
      password: credentials.password
      user_name: credentials.username

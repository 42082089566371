import Bottle from 'bottlejs'

import {createClient as createApiClient} from './api/client.js'
import {createService as createAuthService} from './auth/service.js'
import {createService as createBonusService} from './bonus/service.js'
import {detectFormat, findFormat, findUserAgent} from './device/functions.js'
import {createService as createDeviceService} from './device/service.js'
import {createWindowTitleManager} from './dom.js'
import {findSocketUrl} from './environment.js'
import {createService as createGameListService} from './game-list/service.js'
import {createService as createGlobalService} from './global/service.js'
import {createService as createInstallationService} from './installation/service.js'
import ClientList from './legacy/collections/clientList.coffee'
import GameList from './legacy/collections/gameList.coffee'
import FunctionNameMapper from './legacy/communicator/incoming/functionNameMapper.coffee'
import SignalFactory from './legacy/communicator/incoming/signalFactory.coffee'
import TranslatorFactory from './legacy/communicator/incoming/translators/translatorFactory.coffee'
import Communicator from './legacy/communicator/index.coffee'
import Dispatcher from './legacy/communicator/outgoing/dispatcher.coffee'
import App from './legacy/models/app.coffee'
import GameFilter from './legacy/models/gameFilter.coffee'
import GlobalSettings from './legacy/models/globalSettings.coffee'
import Settings from './legacy/models/settings.coffee'
import User from './legacy/models/user.coffee'
import Receiver from './legacy/modules/incoming/receiver.coffee'
import Navigator from './legacy/modules/navigator.js'
import PopupMaster from './legacy/modules/popupMaster.coffee'
import Sortable from './legacy/modules/sortable.coffee'
import Sounds from './legacy/modules/sounds.coffee'
import Socket from './legacy/socket/index.coffee'
import AppView from './legacy/views/appView.coffee'
import {createPopupReactViewFactory, createReactViewFactory} from './legacy/views/react.js'
import {createService as createLobbyService} from './lobby/service.js'
import {createLogger} from './logging.js'
import {createService as createMenuService} from './menu/service.js'
import {createService as createModalService} from './modal/service.js'
import {createService as createMultiTableService} from './multi-table/service.js'
import {createService as createNamedUrlService} from './named-url/service.js'
import {createNotifier} from './notifier.js'
import {createOwnGamesRouteHook} from './own-games/index.js'
import {createLocalStoragePersister} from './persistence.js'
import {createQuickSeatRouteHook} from './quick-seat/index.js'
import {createService as createQuickSeatService} from './quick-seat/service.js'
import {createStore} from './redux.js'
import {createService as createResponsibleGamingService} from './responsible-gaming/service.js'
import {createServiceFactory as createTableServiceFactory} from './rooms/service.js'
import routes, {createRouteHooks} from './routes.js'
import {createRouter, createRouteRedirector, findPopupParam, mapRoutesByName} from './routing.js'
import {createInit as createInitSentry} from './sentry.js'
import {createService as createSettingsService} from './settings/service.js'
import {createCasinoSettings, createGameFilterSettings, createGlobalSettings} from './settings.js'
import {getLocalStorage, getSessionStorage} from './storage.js'
import {createServiceFactory as createTournamentServiceFactory} from './tournament/service.js'
import {createService as createUpdatingService} from './updating/service.js'

const bottle = new Bottle()

bottle.constant('Sortable', Sortable)
bottle.constant('window', window)
bottle.factory('avatarUrl', ({globalConf}) => globalConf.avatarUrl)
bottle.factory('casinoId', ({globalConf}) => globalConf.id)

bottle.factory('createPopupReactView', ({
  router,
  modalService,
  namedUrlService,
  parentWindowServices,
  settingsService,
  windowTitleManager,
}) => {
  const {createTableService, multiTableService, store} = parentWindowServices

  return createPopupReactViewFactory(
    router,
    store,
    windowTitleManager,
    createTableService,
    modalService,
    multiTableService,
    namedUrlService,
    settingsService,
  )
})

bottle.factory('dispatch', ({store}) => store.dispatch)
bottle.factory('globalConf', ({window}) => window.globalConf)
bottle.factory('isElectron', ({userAgent}) => userAgent.ua.includes('Electron'))
bottle.factory('isPrivileged', ({isElectron}) => isElectron)
bottle.factory('location', ({window}) => window.location)
bottle.factory('log', ({logger}) => logger.log)
bottle.factory('name', ({globalConf}) => globalConf.name)
bottle.factory('obUrl', ({globalConf}) => globalConf.obUrl)
bottle.factory('parentWindowServices', ({window}) => window.opener?.services)
bottle.factory('routesByName', () => mapRoutesByName(routes))
bottle.factory('sentryDsn', ({globalConf}) => globalConf.sentryDsn)
bottle.factory('shortName', ({globalConf}) => globalConf.shortName)
bottle.factory('store', createStore)
bottle.factory('updateCheckInterval', ({globalConf}) => globalConf.updateCheckInterval)
bottle.factory('version', ({window}) => window.VERSION)
bottle.service('app', App, 'communicator', 'format', 'legacyGameFilter', 'legacyGlobalSettings', 'gameList', 'log', 'name', 'navigator', 'obUrl', 'receiver', 'legacySettings', 'socket', 'sounds', 'user', 'clientList', 'popupMaster', 'responsibleGamingService', 'autoDetectedFormat', 'apiClient')
bottle.service('appView', AppView, 'app', 'createReactView', 'isElectron', 'isPrivileged', 'Sortable', 'notifier', 'apiClient', 'menuService', 'modalService', 'updatingService', 'windowTitleManager', 'gameFilterSettings')
bottle.service('clientList', ClientList)
bottle.service('communicator', Communicator, 'casinoId', 'socket', 'signalFactory', 'functionNameMapper', 'dispatcher')
bottle.service('dispatcher', Dispatcher, 'socket')
bottle.service('functionNameMapper', FunctionNameMapper)
bottle.service('gameList', GameList, 'legacySettings', 'legacyGameFilter')
bottle.service('legacyGameFilter', GameFilter, 'version', 'persister')
bottle.service('legacyGlobalSettings', GlobalSettings, 'version', 'persister')
bottle.service('legacySettings', Settings, 'version', 'persister', 'shortName', 'isPrivileged')
bottle.service('navigator', Navigator, 'popupMaster', 'redirectRoute', 'router', 'routesByName', 'user')
bottle.service('popupMaster', PopupMaster, 'casinoSettings', 'clientList', 'isPrivileged')
bottle.service('receiver', Receiver)
bottle.service('signalFactory', SignalFactory, 'translatorFactory', 'log')
bottle.service('socket', Socket, 'socketUrl', 'dispatch')
bottle.service('sounds', Sounds, 'legacySettings', 'popupMaster')
bottle.service('translatorFactory', TranslatorFactory)
bottle.service('user', User, 'legacySettings', 'obUrl')
bottle.serviceFactory('apiClient', createApiClient, 'navigator', 'name')
bottle.serviceFactory('authService', createAuthService, 'app')
bottle.serviceFactory('autoDetectedFormat', detectFormat, 'userAgent')
bottle.serviceFactory('bonusService', createBonusService, 'app', 'communicator')
bottle.serviceFactory('casinoSettings', createCasinoSettings, 'persister', 'shortName')
bottle.serviceFactory('createReactView', createReactViewFactory, 'router', 'store', 'windowTitleManager', 'createTableService', 'createTournamentService', 'authService', 'bonusService', 'deviceService', 'gameListService', 'globalService', 'installationService', 'lobbyService', 'menuService', 'modalService', 'multiTableService', 'namedUrlService', 'quickSeatService', 'responsibleGamingService', 'settingsService', 'updatingService')
bottle.serviceFactory('createTableService', createTableServiceFactory, 'casinoSettings', 'clientList')
bottle.serviceFactory('createTournamentService', createTournamentServiceFactory, 'gameList', 'casinoSettings')
bottle.serviceFactory('deviceService', createDeviceService, 'format')
bottle.serviceFactory('format', findFormat, 'autoDetectedFormat', 'localStorage')
bottle.serviceFactory('gameFilterSettings', createGameFilterSettings, 'persister')
bottle.serviceFactory('gameListService', createGameListService, 'communicator', 'gameFilterSettings', 'router', 'user')
bottle.serviceFactory('globalService', createGlobalService, 'user', 'windowTitleManager')
bottle.serviceFactory('globalSettings', createGlobalSettings, 'persister')
bottle.serviceFactory('initSentry', createInitSentry, 'persister', 'sentryDsn', 'version')
bottle.serviceFactory('installationService', createInstallationService, 'window')
bottle.serviceFactory('lobbyService', createLobbyService, 'app', 'legacyGameFilter')
bottle.serviceFactory('localStorage', getLocalStorage, 'window')
bottle.serviceFactory('logger', createLogger, 'localStorage', 'sessionStorage')
bottle.serviceFactory('menuService', createMenuService)
bottle.serviceFactory('modalService', createModalService)
bottle.serviceFactory('namedUrlService', createNamedUrlService, 'communicator')
bottle.serviceFactory('notifier', createNotifier, 'casinoSettings', 'isElectron', 'window')
bottle.serviceFactory('multiTableService', createMultiTableService, 'clientList', 'popupMaster', 'router')
bottle.serviceFactory('ownGamesRouteHook', createOwnGamesRouteHook, 'communicator', 'user')
bottle.serviceFactory('persister', createLocalStoragePersister, 'localStorage')
bottle.serviceFactory('popupParam', findPopupParam, 'location')
bottle.serviceFactory('quickSeatRouteHook', createQuickSeatRouteHook, 'communicator', 'quickSeatService', 'user')
bottle.serviceFactory('quickSeatService', createQuickSeatService, 'gameFilterSettings', 'gameList')
bottle.serviceFactory('redirectRoute', createRouteRedirector, 'casinoSettings', 'format', 'routesByName')
bottle.serviceFactory('responsibleGamingService', createResponsibleGamingService, 'communicator', 'dispatch')
bottle.serviceFactory('routeHooks', createRouteHooks, 'ownGamesRouteHook', 'quickSeatRouteHook')
bottle.serviceFactory('router', createRouter, 'casinoSettings', 'logger', 'redirectRoute', 'routeHooks')
bottle.serviceFactory('sessionStorage', getSessionStorage, 'window')
bottle.serviceFactory('settingsService', createSettingsService, 'casinoSettings', 'gameFilterSettings', 'globalSettings', 'legacySettings', 'modalService', 'router')
bottle.serviceFactory('socketUrl', findSocketUrl, 'location')
bottle.serviceFactory('updatingService', createUpdatingService, 'casinoSettings', 'log', 'logger', 'notifier', 'shortName', 'updateCheckInterval', 'user', 'window')
bottle.serviceFactory('userAgent', findUserAgent, 'window')
bottle.serviceFactory('windowTitleManager', createWindowTitleManager, 'name', 'window')

export default bottle.container

# All Blinds update seems to only be fired during fast games or tournaments where blinds are automatically posted
# This update needs to send the standard blind events such as chat messages and updating player aciton frame

import eventMap from '../utils/eventMap.coffee'

export default ({signal, details, app, game, client, listing}) ->
  return if not game

  for blindType, fields of details then do (blindType, fields) ->
    switch blindType
      when 'anteList'
        game.players.each (p) ->
          p.balance -= fields.amount
          game?.updateHandHistory state: 'blinds', message: p.alias + ' posts ante ' + fields.amount

      when 'antePots'
        client?.displayChatMessage message: 'Antes Posted'
        game.pots = fields

      else
        player = game.players.find position: fields.position
        if player? and eventMap[blindType]?
          app.once 'signalFinished', ->
            player.label = eventMap[blindType].label  # was previously frame property, which doesn't exist?
            player.bet = fields.amount
            player.balance = fields.balance
            client?.displayChatMessage
              message: (eventMap[blindType].chat {name: player.alias, amount: fields.amount})

           game?.updateHandHistory
              state: 'blinds'
              message: (eventMap[blindType].handHistory {name: player.alias, amount: fields.amount})

import React from 'react'

import AddOnPayments from './details/AddOnPayments.js'
import KnockoutPrizes from './details/KnockoutPrizes.js'
import LateRegistration from './details/LateRegistration.js'
import ReEntry from './details/ReEntry.js'
import RebuyPayments from './details/RebuyPayments.js'
import SeatsPerTable from './details/SeatsPerTable.js'
import StackStats from './details/StackStats.js'
import StartingChips from './details/StartingChips.js'
import TotalUsedRebuysAddOns from './details/TotalUsedRebuysAddOns.js'

export default function Details (props) {
  const {tournamentId} = props

  return <div className='pane pane__details'>
    <LateRegistration tournamentId={tournamentId} />
    <ReEntry tournamentId={tournamentId} />
    <RebuyPayments tournamentId={tournamentId} />
    <AddOnPayments tournamentId={tournamentId} />
    <KnockoutPrizes tournamentId={tournamentId} />
    <StartingChips tournamentId={tournamentId} />
    <SeatsPerTable tournamentId={tournamentId} />
    <StackStats tournamentId={tournamentId} />
    <TotalUsedRebuysAddOns tournamentId={tournamentId} />
  </div>
}

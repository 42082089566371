import React from 'react'
import {Link} from 'react-router5'

import MenuItemContent from './MenuItemContent.js'

export default function RouteMenuItem (props) {
  const {children, icon, routeName} = props

  return <Link routeName={routeName}>
    <MenuItemContent icon={icon}>{children}</MenuItemContent>
  </Link>
}

import Mixmaster from '../../mixmaster.coffee'
import WclEnum from '../../../enum/index.coffee'

import DefaultMixin from './mixins/defaultMixin.coffee'

# Data Types
import PokerStatusData from './dataTypes/pokerStatusData.coffee'
import PokerEventData from './dataTypes/pokerEventData.coffee'
import SeatReservedData from './dataTypes/seatReservedData.coffee'

# handleReserve(long gameInstanceId, PokerStatusData gameStatus)
# handleReserveResponseFailure(long gameInstanceId, String reasonCode, int seat)

export default class ReserveTranslator extends Mixmaster
  mixins = [
    DefaultMixin
    PokerStatusData
    PokerEventData
    SeatReservedData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

    signal.reserve = {}

  reason: (signal, name, values) ->
    signal.failReason = values

  constructor: () ->
    super()
    Mixmaster.construct this, mixins


  # This is necessary because there is no distiction between
  # a normal reserve event and the client's reservation
  # so the event argument mustbe overridden for this
  # function.
  event: (signal, name, values) ->
    tr = {}
    for key, value of values
      enumObj = WclEnum.getEventValue(key, value)
      if enumObj?
        tr[enumObj.key] = enumObj.value

    signal.updates.event = tr
    signal.reserve.playerPosition = parseInt(values.playerId, 10)

import {useCallback, useContext, useEffect, useState} from 'react'

import {KEY_ENTRY_COST, KEY_SEATS, KEY_STAKES, KEY_START_TIME} from './sorting.js'
import {context} from '../react.js'

export function buildUseSortDirection (useSortStore) {
  return sortKey => {
    const store = useSortStore()
    const [reactValue, setReactValue] = useState(directionForSortKey(sortKey, store.get()))

    useEffect(() => {
      return store.subscribe(sort => {
        setReactValue(directionForSortKey(sortKey, sort))
      })
    }, [store, setReactValue, sortKey])

    return reactValue
  }
}

export function buildUseToggleSort (useSortStore) {
  return sortKey => {
    const store = useSortStore()

    return useCallbackWithArg(store.toggle, sortKey)
  }
}

export function buildUseSortSeatAsc (useSortStore) {
  return () => {
    const store = useSortStore()

    return useCallbackWithArg(store.set, {key: KEY_SEATS, direction: 1})
  }
}

export function buildUseSortStartTimeAsc (useSortStore) {
  return () => {
    const store = useSortStore()

    return useCallbackWithArg(store.set, {key: KEY_START_TIME, direction: 1})
  }
}

export function buildUseStakesSortAsc (useSortStore) {
  return () => {
    const store = useSortStore()

    return useCallbackWithArg(store.set, {key: KEY_STAKES, direction: -1})
  }
}

export function buildUseStakesSortDesc (useSortStore) {
  return () => {
    const store = useSortStore()

    return useCallbackWithArg(store.set, {key: KEY_STAKES, direction: 1})
  }
}

export function buildUseEntryCostSortAsc (useSortStore) {
  return () => {
    const store = useSortStore()

    return useCallbackWithArg(store.set, {key: KEY_ENTRY_COST, direction: -1})
  }
}

export function buildUseEntryCostSortDesc (useSortStore) {
  return () => {
    const store = useSortStore()

    return useCallbackWithArg(store.set, {key: KEY_ENTRY_COST, direction: 1})
  }
}

export function useCallbackWithArg (fn, arg) {
  return useCallback(() => { fn(arg) }, [fn, arg])
}

export function useStore (store) {
  const [reactValue, setReactValue] = useState(store.get())
  useEffect(() => store.subscribe(setReactValue), [store, setReactValue])

  return reactValue
}

export function useStoreKey (store, key) {
  const [reactValue, setReactValue] = useState(store.get()[key])

  useEffect(() => {
    return store.subscribe(criteria => { setReactValue(criteria[key]) })
  }, [store, key, setReactValue])

  return reactValue
}

export function useGameFormat () {
  return useStore(useService().gameFormat)
}

export function usePinnedRingGames () {
  return useStore(useService().pinnedRingGames)
}

export function usePinnedTournaments () {
  return useStore(useService().pinnedTournaments)
}

export function useService () {
  return useContext(context).gameListService
}

export function useSetGameFormat (gameFormat) {
  return useCallbackWithArg(useService().gameFormat.set, gameFormat)
}

export function useTogglePinnedRingGame (tableId) {
  return useCallbackWithArg(useService().pinnedRingGames.toggle, tableId)
}

export function useTogglePinnedTournament (tournamentId) {
  return useCallbackWithArg(useService().pinnedTournaments.toggle, tournamentId)
}

function directionForSortKey (sortKey, {key, direction}) {
  return key === sortKey ? direction : undefined
}

import Request from './request.coffee'

export default class GetLevelStructureMessage extends Request
  constructor: ({message, levelId, limitType, instanceId}) ->
    super arguments...

    @function = 'GET_LEVEL_STRUCTURE'

    @attributes =
      type: 'REQUEST'
      service: 'GAMELIST'
      routerId: message.routerId
      serverId: 0
      instanceId: 0
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @limitType = limitType # hacked on for response: WEB-1007
    @instanceId = instanceId

    @arguments =
      level: levelId

import React, {useMemo} from 'react'

import Money from '../../../money/components/Money.js'
import {useRootedSelector} from '../../../redux.js'
import {createGetRebuys, getRoot} from '../../index.js'

export default function RebuyPayments (props) {
  const rebuys = useRootedSelector(getRoot, useMemo(createGetRebuys, []), props)

  const {areEnabled, chips, cost, perSeatLimit} = rebuys
  const perSeatLimitDisplay = perSeatLimit || 'Unlimited'
  const value = areEnabled ? <>{perSeatLimitDisplay} (<Money value={cost} /> for <Money value={chips} />)</> : <>No</>

  return <div className='kvpair selfclear'>
    <label>Rebuy Payments:</label><span>{value}</span>
  </div>
}

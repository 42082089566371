import React from 'react'

import MenuItem from './MenuItem.js'
import {useShowActive, useShowClaim} from '../../bonus/react-hooks.js'

export default function PromotionSubMenu () {
  return <>
    <div className='heading'>Promotions</div>
    <MenuItem icon='fa-tags' label='Claim a Bonus' onClick={useShowClaim()} />
    <MenuItem icon='fa-tasks' label='Active Bonuses' onClick={useShowActive()} />
  </>
}

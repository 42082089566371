import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# Data Types
import TournamentTableData from './dataTypes/tournamentTableData.coffee'

export default class TransferTranslator extends Mixmaster
  mixins = [
    DefaultMixin
    TournamentTableData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal
    signal.tournamentTableData = {}

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

import Request from './request.coffee'

export default class JoinGameRequest extends Request
  constructor: ({serverId, instanceId, message, autoSeat, inBackground}) ->
    super arguments...

    @function = 'JOIN_GAME'

    @attributes =
      type: 'REQUEST'
      service: 'GAME'
      routerId: message.routerId
      serverId: serverId
      instanceId: instanceId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    # we use this for waitlist stuff
    @autoSeat = autoSeat
    @inBackground = inBackground

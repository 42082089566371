import classnames from 'classnames'
import React, {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import {useTableId} from '../../../../context.js'
import styles from '../../../Actions.module.css'
import {hideBetRaiseAmount} from '../../../actions-slice.js'

export default function CloseBetRaiseAmountButton () {
  const dispatch = useDispatch()
  const tableId = useTableId()

  const handleClick = useCallback(() => {
    dispatch(hideBetRaiseAmount({tableId}))
  }, [dispatch, tableId])

  return <button type='button' className={classnames('button', styles.closeButton)} onClick={handleClick}>
    <span className='fa fa-times' />
  </button>
}

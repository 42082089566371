export default
  '1': 'st'
  '2': 'nd'
  '3': 'rd'
  '4': 'th'
  '5': 'th'
  '6': 'th'
  '7': 'th'
  '8': 'th'
  '9': 'th'
  '0': 'th'
  '11': 'th'
  '12': 'th'
  '13': 'th'

import classnames from 'classnames'
import React from 'react'
import {Link} from 'react-router5'

import styles from './DesktopToolbar.module.css'

export default function DesktopNavButton (props) {
  const {active, icon, label, routeName} = props

  return <Link routeName={routeName}>
    <div className={classnames(styles.tab, {[styles.active]: active})}>
      <span className={`icon icon-${icon}`} />
      <span>{label}</span>
    </div>
  </Link>
}

import Card from '../../../models/card.coffee'
import Client from '../../../models/client.coffee'
import Game from '../../../models/game.coffee'
import Listing from '../../../models/listing.coffee'
import Player from '../../../models/player.coffee'

export default ({signal, app, game, client, listing}) ->
  tableId = signal.message.instanceId

  if signal.success
    # First time join
    unless client and game
      details = Object.assign({}, signal.updates, signal.message) # makes a copy of signal.updates so the defaults don't affect scraping

      if details.tournamentId
        details.currency = ''
        details.precision = 0

      if listing
        details.limitTypeDesc = listing.limitTypeDesc
        details.gameTypeDesc = listing.gameTypeDesc

      game   = new Game {details}
      client = new Client {game, user: app.user}
      client.listType = listing?.listType

      # This property is used for printing Tournament name in gameDetailsView
      # and determining whether add chips button should show rebuy or deposit
      if game.tournamentId

        game.tournament = app.listings.find {instanceId: game.tournamentId}

        # If there is no listing for the tournament
        # (because you joined from getMyGames or something)
        # make a shell of one so the utility functions are available
        # for the table details and breaks etc.
        unless game.tournament
          listingDetails = Object.assign({}, details)
          listingDetails.instanceId = details.tournamentId
          listingDetails.serverId = details.serverId
          listingDetails.gameName = details.tournamentName
          listingDetails.isTournament = true
          listing = new Listing {details: listingDetails}
          app.listings.add listing
          game.tournament = listing

        game.tournament.getDetails ->
          client.listType = game.tournament.listType

      # app.tables.add game
      app.clients.add client
      app.spectate game, client, signal.parent?.inBackground

      # # ### Populating game players.
      for playerDetails in signal.playerDetails
        playerDetails.currency = game.currency
        playerDetails.precision = if game.minChipValue is 1 then 0 else 2
        existingPlayer = game.players.find {position: playerDetails.position}
        if playerDetails.cards
          cards = []
          for card in playerDetails.cards
            cards.push new Card card
          playerDetails.cards = cards
        if existingPlayer?
          # console.log 'todo, modifyPlayer'
        else
          game.players.add new Player {details: playerDetails}
      # hax for setting the client player on a tournament join
      if signal.updates.event?
        event = signal.updates.event
        if event.eventName is 'joinGame' and clientPlayer = game.players.find {position: event.playerId}
          client.setPlayer clientPlayer
          client.seated = true
          clientPlayer.isClient = true

      if listing?.reserveOnJoin
        listing.reserveOnJoin = false
        client.action 'reserve'

    # This is for when you sit down, not for when you join
    else if listing
      app.message 'updatePlayer',
        serverId: signal.message.serverId
        instanceId: tableId
      listing.playing = true
      listing.pinned = true

    # join after coming in via table permalink (see WEB-1597)
    else
      app.message 'updatePlayer',
        serverId: signal.message.serverId
        instanceId: tableId

  else
    app.message 'updatePlayer',
      serverId: signal.message.serverId
      instanceId: tableId


  if signal.failReason in ['gameNotExist', 'invalidServerId']
    # invalid server id games won't exist because they come from links
    if listing
      app.listings.rem listing
    app.listings.emit 'hideLoading'
    app.notice
      title: 'Game does not exist.'
      content: 'Please select another'

    Navigator.navLobby()

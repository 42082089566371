# handleGetMyGamesResponse(MyGameListData[] games)

import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# Data Types
import MyGameListData from './dataTypes/myGameListData.coffee'


export default class GetMyGamesTranslator extends Mixmaster
  mixins = [
    DefaultMixin
    MyGameListData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal
    signal.games = []

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

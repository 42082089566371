import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# handleBuyInUpdate(long gameInstanceId, BigDecimal minBuyIn, BigDecimal maxBuyIn)

export default class BuyInTranslator extends Mixmaster
  mixins = [
    DefaultMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

    signal.updates.buyIn = {}

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  minBuyIn: (signal, name, values) ->
    signal.updates.buyIn.min = parseFloat values

  maxBuyIn: (signal, name, values) ->
    signal.updates.buyIn.max = parseFloat values

export default [
  // Filter stuff that should be reset
  /^gameFilter:/,

  // Old stuff
  /^mobileFilter:/,
  /^quickFilter:/,
  /check_fold_preference/,
  /settings:multitable/,
  /settings:autoLogin/,

  // Settings that are likely to change
  /settings:defaultNavMethod/,
  /settings:defaultNavParams/,
]

import React from 'react'

import LabelRow from './LabelRow.js'
import Checkbox from '../../components/Checkbox.js'
import {useIsTournamentStartingNotifications, useToggleTournamentStartingNotifications} from '../react-hooks.js'

export default function TournamentStartingNotifications () {
  return <LabelRow
    name='Tournament starting notifications'
    help='Will display a notification when a tournament you have registered for is starting.'
  >
    <Checkbox
      checked={useIsTournamentStartingNotifications()}
      onChange={useToggleTournamentStartingNotifications()}
      size='small'
    />
  </LabelRow>
}

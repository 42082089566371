import classnames from 'classnames'
import React, {useEffect, useRef} from 'react'

import LogEntry from './LogEntry.js'
import {useIsAvailable, useChatEntries, useIsExpanded} from '../react-hooks.js'

export default function Log (props) {
  const {maximizedChat} = props
  const scrollReference = useRef(null)
  const isAvailable = useIsAvailable()
  const isExpanded = useIsExpanded()
  const entries = useChatEntries()

  useEffect(() => {
    if (!scrollReference.current) return
    scrollReference.current.scrollTop = scrollReference.current.scrollHeight
  }, [entries])

  if (maximizedChat && !isExpanded) return null

  const chatClassName = classnames('chat-log', {
    'maximized-chat': maximizedChat,
    'chat-expanded': isExpanded,
    'chat-unavailable': !isAvailable,
  })

  return <div ref={scrollReference} className={chatClassName}>
    {entries.map(entry => <LogEntry key={entry.id} entry={entry} />)}
  </div>
}

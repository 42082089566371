// multi-purpose enum members
export const ALL = 'ALL'
export const REGULAR = 'REGULAR'

export const GAME_FORMAT = {
  RING_GAME: 'RING_GAME',
  SCHEDULED_TOURNAMENT: 'SCHEDULED_TOURNAMENT',
  SITNGO_TOURNAMENT: 'SITNGO_TOURNAMENT',
}

export const STAKES_RANGE = {
  ALL,
  HIGH: 'HIGH',
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  MICRO: 'MICRO',
}

export const ALL_STAKES_RANGES = [
  ALL,
  STAKES_RANGE.MICRO,
  STAKES_RANGE.LOW,
  STAKES_RANGE.MEDIUM,
  STAKES_RANGE.HIGH,
]

export const ASC = 1
export const DESC = -1

import React from 'react'

import {useWindowTitle} from '../../global/react-hooks.js'
import {useRootedSelector} from '../../redux.js'
import OwnTournamentsList from '../components/OwnTournamentsList.js'
import {getRoot, getOwnTournaments} from '../index.js'

const windowTitle = 'My Tournaments'

export default function OwnTournaments () {
  const tournaments = useRootedSelector(getRoot, getOwnTournaments)

  useWindowTitle(windowTitle)

  return <OwnTournamentsList tournaments={tournaments} />
}

import classnames from 'classnames'
import React from 'react'

import styles from '../Actions.module.css'
import {useIsOnBreak, useIsTurn} from '../react-hooks.js'

export default function ActionButton (props) {
  const {children, className, isIntendedAction, onClick} = props
  const isTurn = useIsTurn()
  const isOnBreak = useIsOnBreak()
  const shouldShowIntentedAction = !isTurn || isOnBreak

  return <button type='button' className={classnames(className, styles.action)} onClick={onClick}>
    {shouldShowIntentedAction && <div className={classnames({[styles.intendedAction]: isIntendedAction})} />}
    {children}
  </button>
}

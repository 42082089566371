import React from 'react'

import DesktopSelectionButton from '../../components/DesktopSelectionButton.js'
import {useSeatCountRange, useSetSeatCountRange} from '../react-hooks.js'

export default function SeatCountRangeButton (props) {
  const {label, seatCountRange} = props
  const isSelected = useSeatCountRange() === seatCountRange

  return <DesktopSelectionButton label={label} isSelected={isSelected} onClick={useSetSeatCountRange(seatCountRange)} />
}

import classnames from 'classnames'
import React from 'react'

export default function Button (props) {
  const {children, className, icon, onClick} = props
  const classNames = classnames('button', 'table-control', {
    [className]: !!className,
    disabled: !onClick,
  })

  return <button type='button' className={classNames} onClick={onClick}>
    <span className={icon} />
    {children}
  </button>
}

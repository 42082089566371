# TableListing is different to a regular Listing
# in that it has very minimal information attached to it
# These are supplied within a GetTournamentDetails response

import Model from './model.coffee'

export default class TableListing extends Model
  constructor: ({details}) ->
    super()

    @properties =
      number: details.number
      instanceId: details.instanceId
      serverId: details.serverId
      gameTypeId: details.gameTypeId
      numPlayers: details.numPlayers
      tournamentId: details.tournamentId
      averageStack: details.averageStack
      highStack: details.highStack
      lowStack: details.lowStack
    @initializeModel arguments...

  join: =>
    @emit 'joinTable'

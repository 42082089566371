import {USD} from '@pipehat/chronicle/constants/currency'

import {
  OWN_RING_GAMES_LOADED,
  OWN_TOURNAMENTS_LOADED,
  RING_GAMES_LOADED,
  TOURNAMENTS_LOADED,
} from '@pipehat/chronicle/constants/event-type'

import {
  ALL_IN_OR_FOLD,
  DEEP_STACK,
  FREEROLL,
  KNOCKOUT,
  N_UP,
  PASSWORD_PROTECTED,
  SATELLITE,
  SHOOTOUT,
} from '@pipehat/chronicle/constants/game-category'

import {REGISTERING} from '@pipehat/chronicle/constants/tournament-status'
import {unpackTabularRow} from '@pipehat/chronicle/translation'
import {satisfiesAll} from '@pipehat/money'

import {
  createByDenominator,
  createCompare,
  isEqual,
  isGreaterThanOrEqual,
  isLessThanOrEqual,
  toString,
} from '@pipehat/money/plain-object'

import {createSelector} from '@reduxjs/toolkit'
import {produce} from 'immer'

import {GAME_FORMAT, RING_GAME_FILTER_CRITERIA, SNG_FILTER_CRITERIA} from './service.js'
import listTypeEnum from '../legacy/enum/enums/listTypeEnum.coffee'

export {getRoot} from './ns.js'

// reducer =====================================================================

const init = {
  ownRingGames: {},
  ownTournaments: {},
  ringGames: {},
  tournaments: {},
}

export function reducer (state = init, action) {
  const {type, payload} = action

  switch (type) {
    case OWN_RING_GAMES_LOADED:
      return produce(state, state => {
        const ownRingGames = {}
        const {games, keys} = payload

        for (const row of games) {
          const {tableId} = unpackTabularRow(keys, row)
          ownRingGames[tableId] = tableId
        }

        state.ownRingGames = ownRingGames
      })

    case OWN_TOURNAMENTS_LOADED:
      return produce(state, state => {
        const ownTournaments = {}
        const {keys, tournaments} = payload

        for (const row of tournaments) {
          const {tournamentId} = unpackTabularRow(keys, row)
          ownTournaments[tournamentId] = tournamentId
        }

        state.ownTournaments = ownTournaments
      })

    case RING_GAMES_LOADED:
      return produce(state, state => {
        const {games, keys, listType} = payload
        const ringGames = {}

        for (const row of games) {
          const game = unpackTabularRow(keys, row)

          const {
            gameTypes,
            limitType,
            maximumStake,
            minimumStake,
            seatCount,
            seatedCount,
            tableId,
            tableCurrency,
          } = game

          ringGames[tableId] = {
            gameTypes,
            limitType,
            maximumStake,
            minimumStake,
            seatCount,
            seatedCount,
            tableCurrency,
          }
        }

        state.ringGames[listType] = ringGames
      })

    case TOURNAMENTS_LOADED:
      return produce(state, state => {
        const {games, keys, listType} = payload
        const tournaments = {}

        for (const row of games) {
          const game = unpackTabularRow(keys, row)

          const {
            categories,
            entryCost,
            gameTypes,
            isSng,
            limitType,
            maximumRegistrants,
            registrantCount,
            seatCount,
            status,
            tournamentCurrency,
            tournamentId,
          } = game

          tournaments[tournamentId] = {
            categories,
            entryCost,
            gameTypes,
            isSng,
            limitType,
            maximumRegistrants,
            registrantCount,
            seatCount,
            status,
            tournamentCurrency,
          }
        }

        state.tournaments[listType] = tournaments
      })
  }

  return state
}

// selectors ===================================================================

const getRingGames = createSelector(
  [state => state.ringGames],
  ringGamesByListType => {
    const ringGames = {}
    for (const listType in ringGamesByListType) Object.assign(ringGames, ringGamesByListType[listType])

    return ringGames
  },
)

const getTournaments = createSelector(
  [state => state.tournaments],
  tournamentsByListType => {
    const tournaments = {}
    for (const listType in tournamentsByListType) Object.assign(tournaments, tournamentsByListType[listType])

    return tournaments
  },
)

export function createGetRingGameFilterCount () {
  return createSelector(
    [createGetFilteredRingGames(), getOwnRingGames],
    (ringGames, ownRingGames) => countActiveRingGames(ringGames, ownRingGames),
  )
}

export function createGetRingGameFilterStakesCandidates () {
  return createSelector(
    [createGetFilteredRingGamesByStakes(), getOwnRingGames],
    (ringGames, ownRingGames) => {
      const ringGamesByTier = {}

      for (const [tableId, ringGame] of Object.entries(ringGames)) {
        const {seatCount, seatedCount} = ringGame

        if (ownRingGames[tableId]) continue // skip already joined
        if (seatedCount >= seatCount) continue // skip full tables

        const tier = JSON.stringify([seatedCount, seatCount])

        if (!ringGamesByTier[tier]) ringGamesByTier[tier] = []
        ringGamesByTier[tier].push(parseInt(tableId, 10))
      }

      const tiers = Object.keys(ringGamesByTier).map(json => JSON.parse(json)).sort(compareCandidateTier)
      const candidates = []

      for (const [seatedCount, seatCount] of tiers) {
        const tier = JSON.stringify([seatedCount, seatCount])

        candidates.push(ringGamesByTier[tier])
      }

      return candidates
    },
  )
}

export function createGetRingGameFilterStakesCount () {
  return createSelector(
    [createGetFilteredRingGamesByStakes(), getOwnRingGames],
    (ringGames, ownRingGames) => countActiveRingGames(ringGames, ownRingGames),
  )
}

export function createGetRingGameFilterStakesList () {
  return createSelector(
    [createGetFilteredRingGames()],
    ringGames => {
      const uniqueStakes = {}

      for (const {minimumStake, maximumStake} of Object.values(ringGames)) {
        const key = JSON.stringify([toString(minimumStake), toString(maximumStake)])

        uniqueStakes[key] = {key, minimumStake, maximumStake}
      }

      return Object.values(uniqueStakes).sort(createCompareRingGameStakes())
    },
  )
}

export function createGetSngFilterCount () {
  return createSelector(
    [createGetFilteredSngs(), getOwnTournaments],
    (sngs, ownTournaments) => countActiveSngs(sngs, ownTournaments),
  )
}

export function createGetSngFilterStakeCandidates () {
  return createSelector(
    [createGetFilteredSngsByStake(), getOwnTournaments],
    (sngs, ownTournaments) => {
      const sngsByTier = {}

      for (const [tournamentId, sng] of Object.entries(sngs)) {
        const {maximumRegistrants, registrantCount} = sng

        if (ownTournaments[tournamentId]) continue // skip already registered
        if (registrantCount >= maximumRegistrants) continue // skip full tournaments

        const tier = JSON.stringify([registrantCount, maximumRegistrants])

        if (!sngsByTier[tier]) sngsByTier[tier] = []
        sngsByTier[tier].push(parseInt(tournamentId, 10))
      }

      const tiers = Object.keys(sngsByTier).map(json => JSON.parse(json)).sort(compareCandidateTier)
      const candidates = []

      for (const [registrantCount, maximumRegistrants] of tiers) {
        const tier = JSON.stringify([registrantCount, maximumRegistrants])

        candidates.push(sngsByTier[tier])
      }

      return candidates
    },
  )
}

export function createGetSngFilterStakeCount () {
  return createSelector(
    [createGetFilteredSngsByStake(), getOwnTournaments],
    (sngs, ownTournaments) => countActiveSngs(sngs, ownTournaments),
  )
}

export function createGetSngFilterStakeList () {
  return createSelector(
    [createGetFilteredSngs()],
    sngs => {
      const uniqueStakes = {}

      for (const {entryCost} of Object.values(sngs)) {
        const key = toString(entryCost)
        uniqueStakes[key] = {key, stake: entryCost}
      }

      return Object.values(uniqueStakes).sort(createCompareSngStakes())
    },
  )
}

export function getIsRingGameLoading (state) {
  return Object.keys(state.ringGames).length === 0
}

export function getIsSngLoading (state) {
  return Object.keys(state.tournaments).length === 0
}

function compareCandidateTier (original, other) {
  return (other[0] - original[0]) || (original[1] - other[1])
}

function countActiveRingGames (ringGames, ownRingGames) {
  let count = 0

  for (const [tableId, {seatCount, seatedCount}] of Object.entries(ringGames)) {
    if (seatedCount > 0 && seatedCount < seatCount && !ownRingGames[tableId]) ++count
  }

  return count
}

function countActiveSngs (sngs, ownTournaments) {
  let count = 0

  for (const [tournamentId, {maximumRegistrants, registrantCount}] of Object.entries(sngs)) {
    if (registrantCount > 0 && registrantCount < maximumRegistrants && !ownTournaments[tournamentId]) ++count
  }

  return count
}

function createCompareRingGameStakes () {
  const compare = createCompare()

  return (original, other) => {
    return (
      compare(original.minimumStake, other.minimumStake) ||
      compare(original.maximumStake, other.maximumStake)
    )
  }
}

function createCompareSngStakes () {
  const compare = createCompare()

  return (original, other) => compare(original.stake, other.stake)
}

function createGetFilteredRingGames () {
  return createSelector(
    [
      getRingGames,
      getFilterProp,
    ],
    (ringGames, filter) => {
      const {limitType, variant} = RING_GAME_FILTER_CRITERIA[filter]

      return Object.fromEntries(
        Object.entries(ringGames).filter(([, ringGame]) => {
          return (
            ringGame.tableCurrency === USD &&
            ringGame.seatCount > 5 &&
            ringGame.limitType === limitType &&
            ringGame.gameTypes.length === 1 &&
            ringGame.gameTypes[0].variant === variant
          )
        }),
      )
    },
  )
}

function createGetFilteredRingGamesByStakes () {
  return createSelector(
    [
      createGetFilteredRingGames(),
      getMinimumStakeProp,
      getMaximumStakeProp,
    ],
    (ringGames, minimumStake, maximumStake) => {
      const isMinimumStake = isEqual(minimumStake)
      const isMaximumStake = isEqual(maximumStake)

      return Object.fromEntries(
        Object.entries(ringGames).filter(([, ringGame]) => {
          const {minimumStake, maximumStake} = ringGame

          return isMinimumStake(minimumStake) && isMaximumStake(maximumStake)
        }),
      )
    },
  )
}

const EXCLUDED_SNG_CATEGORIES = [
  ALL_IN_OR_FOLD,
  DEEP_STACK,
  FREEROLL,
  KNOCKOUT,
  N_UP,
  PASSWORD_PROTECTED,
  SATELLITE,
  SHOOTOUT,
]

const createFromUsCents = createByDenominator(USD, 100n)
const isValidSngEntryCost = satisfiesAll([
  isGreaterThanOrEqual(createFromUsCents(110n)), // >= $1.10
  isLessThanOrEqual(createFromUsCents(100000n)), // <= $1000
])

function createGetFilteredSngs () {
  return createSelector(
    [
      getTournaments,
      getFilterProp,
    ],
    (tournaments, filter) => {
      const {category, limitType, maximumSeatCount, minimumSeatCount, variant} = SNG_FILTER_CRITERIA[filter]

      // don't exclude the primary category
      const excludedCategories = EXCLUDED_SNG_CATEGORIES.filter(excluded => excluded !== category)

      return Object.fromEntries(
        Object.entries(tournaments).filter(([, tournament]) => {
          return (
            tournament.isSng &&
            tournament.status === REGISTERING &&
            tournament.tournamentCurrency === USD &&
            tournament.seatCount >= minimumSeatCount &&
            tournament.seatCount <= maximumSeatCount &&
            tournament.limitType === limitType &&
            tournament.gameTypes.length === 1 &&
            tournament.gameTypes[0].variant === variant &&
            tournament.categories.includes(category) &&
            !tournament.categories.some(category => excludedCategories.includes(category)) &&
            isValidSngEntryCost(tournament.entryCost)
          )
        }),
      )
    },
  )
}

function createGetFilteredSngsByStake () {
  return createSelector(
    [
      createGetFilteredSngs(),
      getStakeProp,
    ],
    (sngs, stake) => {
      const isStake = isEqual(stake)

      return Object.fromEntries(
        Object.entries(sngs).filter(([, {entryCost}]) => isStake(entryCost)),
      )
    },
  )
}

function getFilterProp (_, {filter}) {
  return filter
}

function getMinimumStakeProp (_, {minimumStake}) {
  return minimumStake
}

function getMaximumStakeProp (_, {maximumStake}) {
  return maximumStake
}

function getOwnRingGames (state) {
  return state.ownRingGames
}

function getOwnTournaments (state) {
  return state.ownTournaments
}

function getStakeProp (_, {stake}) {
  return stake
}

// routing hooks ===============================================================

const {RING_GAME, SNG} = GAME_FORMAT

const LIST_TYPES = {
  [RING_GAME]: [
    listTypeEnum.TEXAS_HOLDEM_NO_LIMIT.description,
    listTypeEnum.TEXAS_HOLDEM_FIXED_LIMIT.description,
    listTypeEnum.OMAHA.description,
    listTypeEnum.OMAHA_HILO.description,
  ],
  [SNG]: [
    listTypeEnum.TOURNAMENT_SITNGO_REGULAR.description,
    listTypeEnum.TOURNAMENT_SITNGO_HEADSUP.description,
    listTypeEnum.TOURNAMENT_SITNGO_SIX_MAX.description,
    listTypeEnum.TOURNAMENT_SITNGO_FULL_RING.description,
  ],
}

export function createQuickSeatRouteHook (communicator, quickSeatService, user) {
  function load () {
    if (!user.authenticated) return

    communicator.message('getMyGames')

    const tagList = user.tagList.playerTagsString

    const {gameFormat} = quickSeatService
    const messageName = gameFormat === RING_GAME ? 'getRingGames' : 'getTournaments'
    const listTypes = LIST_TYPES[gameFormat]

    if (listTypes) {
      for (const listType of listTypes) {
        communicator.message(messageName, {listType, realMoney: true, tagList})
      }
    }
  }

  return function onEnter () {
    load()
    const intervalId = setInterval(load, 5000)
    user.on('change:authenticated', load)
    const unsubscribeFromGameFormat = quickSeatService.subscribeToGameFormat(load)

    return function onLeave () {
      unsubscribeFromGameFormat()
      user.removeListener('change:authenticated', load)
      clearInterval(intervalId)
    }
  }
}

import React from 'react'

import Prize from './Prize.js'

export default function PrizeListingRow (props) {
  const {place, prize} = props

  return <tr>
    <td>{place}</td>
    <td><Prize value={prize} /></td>
  </tr>
}

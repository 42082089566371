import * as actionTypes from '@pipehat/chronicle/constants/action'

import {
  BET,
  CALL_BET,
  CHECK,
  DECLARE_ALL_IN,
  DECLINE_INITIAL_BLIND,
  DECLINE_RETURN_BLIND,
  FOLD,
  MUCK_CARDS,
  POST_BIG_BLIND,
  POST_INITIAL_BLIND,
  POST_RETURN_BLIND,
  POST_SMALL_BLIND,
  RAISE,
  SHOW_CARDS,
  START_RABBIT_HUNT,
} from '@pipehat/chronicle/constants/command-type'

import {
  ACTIONS_LOADED,
  ACTIVE_POT_LOADED,
  ACTIVE_SEATS_LOADED,
  ALL_IN_DECLARED,
  BET_CALLED,
  BET_MADE,
  BIG_BLIND_POSTED,
  BREAK_ENDED,
  BREAK_LOADED,
  CARDS_MUCKED,
  CARDS_SHOWN,
  CHECKED,
  FOLDED,
  INITIAL_BLIND_DECLINED,
  INITIAL_BLIND_POSTED,
  OWN_SEAT_LOADED,
  RABBIT_HUNT_STARTED,
  RAISED,
  RETURN_BLIND_DECLINED,
  RETURN_BLIND_POSTED,
  RING_GAME_TABLE_LOADED,
  SEAT_STATUS_LOADED,
  SMALL_BLIND_POSTED,
  STAKES_LOADED,
  TABLE_STATUS_LOADED,
  TOURNAMENT_TABLE_LOADED,
} from '@pipehat/chronicle/constants/event-type'

import {IN, OUT} from '@pipehat/chronicle/constants/seat-status'
import {sum} from '@pipehat/money/plain-object'
import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  activeSeats: [],
  currency: '',
  hand: {
    activePotAmount: undefined,
    ownActions: {
      actions: [],
      currentBet: undefined,
      maximumBet: undefined,
      maximumRaise: undefined,
      minimumBet: undefined,
      minimumRaise: undefined,
    },
    ownBetRaiseAmount: undefined,
    ownBetRaiseIsVisible: false,
    ownPreviousActionResult: '',
    ownRequestedAction: '',
    isActiveSitOut: false,
  },
  ownSeat: 0,
  stakes: undefined,
  status: '',
  tournament: undefined,
}

const initTournament = {
  canForgoBreak: false,
  isOnBreak: false,
}

const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    clearBetRaiseAmount (state) {
      state.hand.ownBetRaiseAmount = undefined
      state.hand.ownBetRaiseIsVisible = false
    },

    hideBetRaiseAmount (state) {
      state.hand.ownBetRaiseIsVisible = false
    },

    selectBetRaiseAmount (state) {
      const {actions, currentBet, minimumBet, minimumRaise} = state.hand.ownActions
      const isRaise = actions.includes(actionTypes.RAISE)

      // need to add current bet to raise amount
      state.hand.ownBetRaiseAmount = isRaise ? sum([currentBet, minimumRaise]) : minimumBet
      state.hand.ownBetRaiseIsVisible = true
    },

    setBetRaiseAmount (state, {payload}) {
      state.hand.ownBetRaiseAmount = payload.amount
      state.hand.ownBetRaiseIsVisible = true
    },

    showBetRaiseAmount (state) {
      state.hand.ownBetRaiseIsVisible = true
    },
  },
  extraReducers: {
    [TOURNAMENT_TABLE_LOADED]: (state, {payload}) => {
      state.currency = payload.tableCurrency

      if (!state.tournament) state.tournament = {...initTournament}
    },

    [RING_GAME_TABLE_LOADED]: (state, {payload}) => {
      state.currency = payload.tableCurrency
    },

    [TABLE_STATUS_LOADED]: (state, {payload}) => {
      state.status = payload.status
    },

    [OWN_SEAT_LOADED]: (state, {payload}) => {
      state.ownSeat = payload.seatNumber
    },

    [BREAK_LOADED]: (state, {payload}) => {
      state.tournament.isOnBreak = true
      state.tournament.canForgoBreak = payload.canForgo
    },

    [BREAK_ENDED]: state => {
      state.tournament.isOnBreak = false
      state.tournament.canForgoBreak = false
    },

    [SEAT_STATUS_LOADED]: (state, {payload}) => {
      const {hand, ownSeat} = state
      const {seatNumber, status} = payload

      if (seatNumber !== ownSeat) return

      if (hand.isActiveSitOut && status === IN) hand.isActiveSitOut = false

      // clear stale actions
      if (status === OUT) hand.ownActions.actions = []
    },

    [STAKES_LOADED]: (state, {payload}) => {
      const {limitType, minimumBet} = payload
      state.stakes = {limitType, minimumBet}
    },

    [ACTIVE_SEATS_LOADED]: (state, {payload}) => {
      state.activeSeats = payload.seatNumbers
    },

    [ACTIVE_POT_LOADED]: (state, {payload}) => {
      state.hand.activePotAmount = payload.amount
    },

    [ACTIONS_LOADED]: (state, {payload}) => {
      const {actions, currentBet, maximumBet, maximumRaise, minimumBet, minimumRaise} = payload

      Object.assign(state.hand.ownActions, {actions, currentBet, maximumBet, maximumRaise, minimumBet, minimumRaise})
    },

    // own action commands ...
    [BET]: ownHandCommands,
    [CALL_BET]: ownHandCommands,
    [CHECK]: ownHandCommands,
    [DECLARE_ALL_IN]: ownHandCommands,
    [DECLINE_INITIAL_BLIND]: ownHandCommands,
    [DECLINE_RETURN_BLIND]: ownHandCommands,
    [FOLD]: ownHandCommands,
    [MUCK_CARDS]: ownHandCommands,
    [POST_BIG_BLIND]: ownHandCommands,
    [POST_INITIAL_BLIND]: ownHandCommands,
    [POST_RETURN_BLIND]: ownHandCommands,
    [POST_SMALL_BLIND]: ownHandCommands,
    [RAISE]: ownHandCommands,
    [SHOW_CARDS]: ownHandCommands,
    [START_RABBIT_HUNT]: ownHandCommands,

    // everyones action events ...
    [ALL_IN_DECLARED]: everyonesHandEvents,
    [BET_CALLED]: everyonesHandEvents,
    [BET_MADE]: everyonesHandEvents,
    [BIG_BLIND_POSTED]: everyonesHandEvents,
    [CARDS_MUCKED]: everyonesHandEvents,
    [CARDS_SHOWN]: everyonesHandEvents,
    [CHECKED]: everyonesHandEvents,
    [FOLDED]: everyonesHandEvents,
    [INITIAL_BLIND_DECLINED]: everyonesHandEvents,
    [INITIAL_BLIND_POSTED]: everyonesHandEvents,
    [RABBIT_HUNT_STARTED]: everyonesHandEvents,
    [RAISED]: everyonesHandEvents,
    [RETURN_BLIND_DECLINED]: everyonesHandEvents,
    [RETURN_BLIND_POSTED]: everyonesHandEvents,
    [SMALL_BLIND_POSTED]: everyonesHandEvents,
  },
})

export const {
  clearBetRaiseAmount,
  hideBetRaiseAmount,
  selectBetRaiseAmount,
  setBetRaiseAmount,
  showBetRaiseAmount,
} = actionsSlice.actions

export default actionsSlice.reducer

function ownHandCommands (state, {type}) {
  const {hand} = state

  hand.ownPreviousActionResult = ''
  hand.ownRequestedAction = type
}

function everyonesHandEvents (state, {type, payload}) {
  const {hand, ownSeat} = state
  const {seatNumber} = payload

  if (seatNumber === ownSeat) {
    if (hand.ownRequestedAction === '' && type === CHECKED) {
      hand.isActiveSitOut = true
    }

    hand.ownBetRaiseAmount = undefined
    hand.ownBetRaiseIsVisible = false
    hand.ownPreviousActionResult = type
    hand.ownRequestedAction = ''

    // clear stale actions
    hand.ownActions.actions = []
  } else if (type === BET_MADE || type === RAISED || type === ALL_IN_DECLARED) {
    hand.ownBetRaiseAmount = undefined
    hand.ownBetRaiseIsVisible = false
  }
}

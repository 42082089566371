# APP VIEW
# Model: APP

import dayjs from 'dayjs'
import key from 'hotkeys-js'

import ActiveBonusesView from './activeBonusesView.coffee'
import ArcadeView from './arcadeView.coffee'
import Listing from '../models/listing.coffee'
import LoginView from './loginView.coffee'
import MaintenanceView from './maintenanceView.coffee'
import OfflineView from './offlineView.coffee'
import PaymentOptionsSelect from './client/paymentOptionsSelect.coffee'
import TournamentPositionView from './tournamentPositionView.coffee'
import PreferredSeatsView from './client/seat/preferredSeatsView.coffee'
import View from './view.coffee'
import Lobby from '../../lobby/screens/Lobby.js'
import FollowOnSatelliteTournament from '../../game-list/components/FollowOnSatelliteTournament.js'
import SatelliteTournament from '../../game-list/components/SatelliteTournament.js'
import {STATUS_UNAVAILABLE as API_STATUS_UNAVAILABLE} from '../../api/client.js'

import {
  STATUS_RELOAD_REQUIRED,
  STATUS_RESTART_REQUIRED,
  STATUS_UPDATING
} from '../../updating/service.js'

export default class AppView extends View
  constructor: (
    app,
    @createReactView,
    isElectron,
    isPrivileged,
    Sortable,
    @notifier,
    @apiClient,
    menuService,
    @modalService,
    updatingService,
    @windowTitleManager,
    gameFilterSettings,
  ) ->
    super()

    @model = app
    @navigator = app.navigator

    @prefSeatingView = new PreferredSeatsView app.settings, @createReactView

    @dom = $ 'body'

    # ### Components ###
    @components =
      offline: new OfflineView @model, updatingService
      maintenance: new MaintenanceView @model
      login: new LoginView @model.user
      lobby: @createReactView(Lobby, {
        appName: @model.name,
        isElectron,
        isPrivileged,
        Sortable,
        @createReactView,
        menuService,
        gameFilterSettings
      }, {id: 'lobby'}, 'div')

      arcade: new ArcadeView @model, @createReactView, @model.format is 'desktop', @windowTitleManager

    # ### Events - eg. MouseClicks... ###
    @dom.on 'click', '.merge-link', @handleMergeLink

    # ### Listeners - eg. auth:success... ###
    @listeners =
      'auth:success': @authSuccess
      'invalidSession': @invalidSession
      'invalidVersion': @invalidVersion
      'promptClaimCoupon': @promptClaimCoupon
      'logout': @logout
      'activeTableLimitWarning': @activeTableLimitWarning
      'notice': @modal
      'restart': @restart
      'restartRequired': @restartRequired
      'tournamentPosition': @tournamentPosition
      'tournamentRegistration': @tournamentRegistration
      'tournamentDeregistration': @tournamentDeregistration
      'tournamentStart': @tournamentStart
      'tournamentShutdown': @tournamentShutdown
      'getActiveBonuses': @getActiveBonuses
      'showActiveBonuses': @showActiveBonuses
      'activeBonusesError': @activeBonusesError
      'showLevelStructure': @showLevelStructure
      'showEntryCoupons': @showEntryCoupons
      'updateNicknamePrompt': @updateNicknamePrompt
      'iframe': @iframe
      'notification': @notification
      'bountyMessage': @bountyMessage
      'switchFormat': @switchFormat
      'maintenance': @handleMaintenance
      'showSatellites': @showSatellites
      'showSatelliteFollowOnTournaments': @showSatelliteFollowOnTournaments
      'showPreferredSeating': @showPreferredSeating
      'showSwitchTableNotice': @showSwitchTableNotice

    updatingService.subscribe (status) =>
      switch status
        when STATUS_RELOAD_REQUIRED, STATUS_RESTART_REQUIRED then @dom.addClass 'update-available'
        when STATUS_UPDATING then @dom.addClass 'update-requested'
        else @dom.removeClass 'update-available'

    @navigator.on 'nav:login', @navLogin
    @navigator.on 'nav:maintenance', @navMaintenance
    @navigator.on 'nav:offline', @navOffline

    @model.on 'connectionTimeout', @connectionTimeout

    navMethods =
      'gameList': @showLobby
      'lobby': @showLobby
      'quickSeat': @showLobby
      'responsibleGaming': @showLobby
      'ringGames': @showLobby
      'selectorList': @showLobby
      'settings': @showLobby
      'table': @navTable
      'tournament': @showLobby
      'tournaments': @showLobby

    @navigator.on 'nav', (location) =>
      navMethods[location]?()

    @navigator.on 'open:tableLink', @openTableLink
    @navigator.on 'open:tournamentLink', @openTournamentLink

    # DC / RC
    @model.socket.on 'disconnect', =>
      # delay so firefox doesn't fire on page reload
      setTimeout @disconnected, 200

    # Appcache require refresh? (see entry applicationCache on updateReady)
    if window.restartRequired
      @restartRequired()

    # ### Binding ###
    @initializeView()
    return this

  render: =>
    @dom.append @components.offline.render()
    @dom.append @components.maintenance.render()
    @dom.append @components.login.render()
    @dom.append @components.lobby.render()
    @dom.append @components.arcade.render()

    @navLogin()
    @model.maintenanceCheck()

    return @dom

  authSuccess: =>
    @showLobby()

    @apiClient.splashPromo()
      .catch ->
      .then (data) => @promoPopup data if data
      .catch (error) -> throw new Error "Unable to process splash promo: #{error.message}"

  openTableLink: (tableId) =>
    game = @model.listings.find({instanceId: tableId}) or @model.clients.find({instanceId: tableId})

    if game
      @navigator.navTable game
    else
      @model.joinGame {instanceId: tableId, serverId: 0}

  openTournamentLink: (tournamentId) =>
    tournament = @model.listings.find {instanceId: tournamentId}
    unless tournament
      details =
        instanceId: tournamentId
        isTournament: true
        isRingGame: false
        realMoney: true # WARNING - hardcoded real money
      tournament = new Listing {details}
      @model.listings.add tournament
      @model.getMyGames()

    tournament.showLobby()
    @showLobby()

  showLobby: =>
    key.setScope('lobby')
    @components.arcade.hide()
    @components.login.hide()
    @components.offline.hide()
    @components.lobby.show()

    @model.startPollingGames()
    @navigator.once 'nav:table', @model.stopPollingGames # warning, this gets added multiple times

  navLogin: =>
    key.setScope('login')
    @windowTitleManager.set 'Login'

    @components.offline.hide()
    @components.arcade.hide()
    @components.lobby.hide()
    @components.maintenance.hide()
    @components.login.show()

  navTable: =>
    return if @isOffline
    @components.offline.hide()
    @components.lobby.hide()
    @components.arcade.show()

  navOffline: ({title, reason, message, buttonText}) =>
    @isOffline = true

    @windowTitleManager.set title

    @components.offline.title title
    @components.offline.reason reason
    @components.offline.message message
    @components.offline.buttonText buttonText

    @components.arcade.hide()
    @components.login.hide()
    @components.lobby.hide()
    @components.offline.show()

  navMaintenance: =>
    @windowTitleManager.set 'Maintenance'

    @components.offline.hide()
    @components.arcade.hide()
    @components.login.hide()
    @components.lobby.hide()
    @components.maintenance.show()

  restartRequired: =>
    @modal
      title: 'Restart required'
      icon: 'info-circle'
      content: 'An updated version has been downloaded and a restart is required to ensure the game is up to date.'
      teardown: @restart

  restart: =>
    @model.leaveWaitlists()
    @dom.html '<div class="loggingOut"></div>' # makes reload appear cleander
    window.setTimeout (-> window.location.reload()), 10

  disconnected: =>
    return if @sessionIsInvalid or @isMaintenance
    needAuth = if @model.user.authenticated then '<br>To continue playing, login again.' else ''
    @navigator.navOffline
      title: "Disconnected"
      reason: "Connection to the server was lost.#{needAuth}"
    @model.popupMaster?.closeAllChildren()
    @model.maintenanceCheck()

  handleMaintenance: =>
    @isMaintenance = true

  invalidSession: =>
    @sessionIsInvalid = true # stop disconnect message
    @navigator.navOffline
      title: "Invalid Session",
      reason: "This usually happens because you have connected using this account from a different device. (Computer/Phone)"

  connectionTimeout: =>
    @navigator.navOffline title: "Connection Timed Out"

  invalidVersion: =>
    @navigator.navOffline
      title: "Invalid Server Version.",
      reason: "The game servers are refusing connections from this older version of the webclient. Click RELOAD to automatically update to the newest version. If you continue to get this message after restarting, please contact support."

  promptClaimCoupon: =>
    input = $ '<input class="large-coupon-input" placeholder="Enter Bonus Code Here">'
    content = $ '<div>'
    content.append input
    @modal
      title: 'Enter Bonus Code'
      icon: 'tags'
      content: content.html()
      choices: [
        {value: 'Cancel', icon:'times'}
        {
          value: 'Submit'
          icon: 'check'
          action: =>
            @model.communicator.send 'claimCoupon', couponCode: $('.large-coupon-input').val()
        }
      ]

  logout: =>
    @modal
      title: 'Confirm Logout'
      icon: 'sign-out'
      content: 'Are you sure you would like to log out?'
      choices: [
        { value: 'Cancel', key: 'escape', icon: 'times' }
        {
          value: 'Logout'
          icon: 'sign-out'
          key: 'enter'
          action: @restart
        }
      ]

  tournamentDeregistration: (success, gameName, failReason) =>
    if success
      @modal
        title: 'Tournament De-registration Success'
        icon: 'check'
        content: "You have been removed from the tournament #{gameName}."
    else
      @modal
        title: 'Tournament De-registration Failed'
        icon: 'warning'
        content: "You could not be de-registered from the tournament #{gameName}.<br><br>#{failReason}"

  tournamentRegistration: (success, message, listing) =>
    # don't show modal if re-enter MTT
    abort = @model.clients.some (client) ->
      return client.game?.tournament?.instanceId is listing?.instanceId
    if abort
      return

    icon = 'check'
    choices = [{value:'Close', icon: 'check', primary: true}]
    if success
      title = 'Tournament Registration Success'
      if listing? and @navigator.location isnt 'tournament' + listing.instanceId # todo, what if location/label changes?
        choices.push
          value: 'Show Lobby'
          icon: 'list-alt'
          action: listing.showLobby
    else
      title = 'Tournament Registration Failed'
      icon = 'warning'

    @tournamentRegistrationModal = @modal({
      title: title,
      icon: icon,
      content: message,
      choices: choices
    })

  tournamentStart: (tournamentDetails) =>
    displayNotification = =>
      # hide tournament registration result modal if still open
      @tournamentRegistrationModal?.teardown()
      @tournamentRegistrationModal = null

      @tournamentStartModal = @modal
        title: 'Tournament Starting'
        icon: 'info-circle'
        content: "#{tournamentDetails.gameName} is starting. A seat has been reserved for you. Would you like to play now, or join the in-progress tournament later?"
        dismissible: false
        choices: [
          {
            value: 'Join Later'
            key: 'escape'
            icon: 'times'
            action: =>
              @model.joinGame
                serverId: tournamentDetails.serverId
                instanceId: tournamentDetails.tableInstanceId
              , null, true #inBackground
          }
          {
            value: 'Play Now'
            key: 'enter'
            icon: 'sign-in'
            action: =>
              @tournamentPositionView?.teardown()
              # @model.clients.leave()
              @model.joinGame
                serverId: tournamentDetails.serverId
                instanceId: tournamentDetails.tableInstanceId
          }
        ]
      if @model.settings.notificationAtGameStart
        @notification
          title:    'Tournament ' + tournamentDetails.gameName
          message:  'Is starting now'

    # delay the notification if there is a game in progress
    if @model.gameInProgress()
      # @model.tables.once 'endOfGame', displayNotification
      @model.clients.once 'endOfGame', displayNotification
    else
      displayNotification()

  tournamentPosition: (positionFinished, winnings, reRegisterListing) =>
    @tournamentPositionView = view = new TournamentPositionView @model, reRegisterListing
    view.render positionFinished, winnings
    view.show()

  tournamentShutdown: (message, listing) =>
    teardown = ->
    if listing? and @navigator.location is "table#{listing.instanceId}"
      # I could not repro this case - I was always booted from the table long before the shutdown message arrived
      message += "<p>Table will be closed</p>"
      teardown = @navigator.navLobby
    @modal
      title: 'Tournament Shutdown'
      icon: 'warning'
      content: message
      teardown: teardown

  showLevelStructure: (levels, limitType, levelIncrement) =>
    table = """
      <table class="generic-modal-table #{limitType}"><thead><tr>
        <th>Level</th>
        <th>Blinds</th>
        <th>Ante</th>
        <th class="maxBet">Max Bet</th>
        <th>Duration</th>
      </tr></thead><tbody>
      """
    for level, i in levels
      table += """
      <tr>
        <td>#{level.level or i + 1}</td>
        <td>#{level.smallBlind.toMoney('', 0)} / #{level.bigBlind.toMoney('', 0)}</td>
        <td>#{level.ante}</td>
        <td class="maxBet">#{level.maxBet.toMoney('', 0)}</td>
        <td>#{levelIncrement}</td>
      </tr>
      """
    table += "</tbody></table>"

    @modal
      title: 'Level Structure'
      icon: 'list'
      content: table

  getActiveBonuses: =>
    @activeBonusesModal = @modal
      title: 'Active Bonuses'
      icon: 'tasks'
      content: '<div><div class="modal-loading">' + $('#loader-animation-template').html() + '</div></div>'

  showActiveBonuses: =>
    view = new ActiveBonusesView @model
    @activeBonusesModal?.dom.find('.content').html view.render()

  activeBonusesError: =>
    @activeBonusesModal?.teardown()
    @modal
      title: 'Active Bonuses'
      icon: 'warning'
      content: 'Error: Could not process Active Bonuses request at this time'

  showEntryCoupons: (coupons) =>
    # todo: refactor into own view?
    table = """
      <table class="generic-modal-table"><thead><tr>
        <th>Date Issued</th>
        <th>Name</th>
        <th>Value</th>
        <th>Expires</th>
      </tr></thead><tbody>
      """
    for coupon in coupons
      dateFormat = 'DD/MM/YYYY'
      issued = dayjs(coupon.issued).format(dateFormat)
      if coupon.expiration
        expires = dayjs(coupon.expiration).from(Webclient.Time)
        expires += '<br>' + dayjs(coupon.expiration).format(dateFormat)
      else
        expires = ''
      table += """
      <tr>
        <td>#{issued}</td>
        <td>#{coupon.name}</td>
        <td>#{coupon.value.toMoney()}</td>
        <td>#{expires}</td>
      </tr>
      """
    table += "</tbody></table>"

    @modal
      title: 'Tournament Entry Coupons'
      icon: 'ticket'
      content: table

  updateNicknamePrompt: (invalid) =>
    content = $ '<div>'
    input =   $ '<input name="username">'
    message = $ "<div>Please enter a new nickname between 6 & 20 characters.<br>Your nickname is what you will be known as at the poker tables. It can't be changed later.<br>Characters allowed: A-Z, a-z, 0-9.</div>"
    label =   $ '<label>Nickname:</label>'
    label.append input
    if invalid
      invalidMessage = $ '<div class="warningText">The nickname you entered was invalid, please try again. Note: your nickname cannot be the same as your username.</div>'
      content.append invalidMessage
    content.append message
    content.append label
    @modal
      title: 'Enter Nickname'
      icon: 'info-circle'
      content: content
      choices: [{value: 'Submit'}]
      teardown: =>
        nickname = input.val().trim()
        if nickname
          @model.updateNickname nickname
        else
          @updateNicknamePrompt()

  # open external link in iframe in modal because ios7 hates external links from webapps
  iframe: (url, title) =>
    # e.preventDefault()
    iframe = $ '<iframe sandbox="allow-same-origin allow-scripts allow-forms">'
    iframe.attr src: url
    iframe.css width: '100%', height: '280px', background: 'white'
    @modal
      title: title
      content: iframe
      choices: [{value:'Done', key:'enter,space,escape'}]

  notification: ({title, message}) =>
    if @model.settings.notifications
      @notifier.notify title || @name, body: message
        .catch (error) ->

  bountyMessage: (content) =>
    if @bountyModal
      div = $('<div>').text(content)
      @bountyModal.components.content.append div
    else
      @bountyModal = @modal
        title: 'Bounty Prize'
        icon: 'trophy'
        content: content
        teardown: =>
          @bountyModal = null

  promptRegister: ({listing}) =>
    paymentOptionsSelect = new PaymentOptionsSelect listing.entryPayments, listing.eligibilityRequired, 'entry-payment-options'
    content = $("<p>To register for #{listing.gameName}, choose a buy-in option below.</p>")
      .add paymentOptionsSelect.render()
    @modal
      title: listing.gameName + ' Registration'
      icon: 'ticket'
      content: content
      choices: [
        {value:'Cancel'}
        {
          value:'Register'
          action: =>
            paymentId = paymentOptionsSelect.dom?.val() or listing.entryPayments[0].id
            listing.register {paymentId}
        }
      ]

  # see: https://wiki.codeworx.com.au/display/SOFT/Client+Notifications
  handleMergeLink: (e) =>
    e.preventDefault()
    href = $(e.target).attr 'href'
    [protocol, components] = href.split '://'
    if protocol is 'merge'
      [top, tokens...] = components.split '/'
      switch top
        when 'TOURNAMENT'
          [instanceId, action] = tokens
          instanceId = parseInt instanceId, 10
          listing = @model.listings.find {instanceId}
          unless listing?
            listing = new Listing details:
              instanceId: instanceId
              isTournament: true
            @model.listings.add listing
          switch action
            when 'lobby'
              if @navigator.location is "table#{instanceId}"
                @modal
                  title: 'Leave Game?'
                  content: 'Viewing the tournament lobby will leave this game. Are you sure?'
                  choices: [
                    { value: 'Cancel' }
                    { value: 'View Tournament', action: listing.showLobby }
                  ]
              else
                listing.showLobby()
            when 'register'
              # warning: see WEB-1420. allows to register while playing a game
              listing.getDetails => @promptRegister {listing}
        when 'URL'
          [keyId] = tokens
          @model.getToken {keyId}
    else if protocol in ['http', 'https']
      @modal
        title: 'Warning'
        content: 'You\'re leaving poker to visit an external site. Continue?'
        choices: [
          {value:'Cancel'}
          {
            value: 'OK'
            target: href
          }
        ]

  switchFormat: =>
    needAuth = if @model.user.authenticated then ' and you will need to login again' else ''
    content = """<div>
      <p>You are currently using #{@model.format}.</p>
      <p>Changing will restart the app#{needAuth}.</p>
      <p>* = recommended</p>
      </div>"""
    choices = []
    ['mobile', 'tablet', 'desktop'].forEach (format) =>
      icon = format
      # label = 'Use ' + format
      label = format
      if format is @model.autoDetectedFormat
        format = 'auto'
        label += '*'
      choices.push
        value: label
        icon: icon
        action: =>
          if @model.globalSettings.format isnt format
            restartRequired = true
          @model.globalSettings.format = format
          if restartRequired
            @model.gameFilter.resetAll()
            @restart()

    @modal
      title: "Change version?"
      className: 'switch-format-modal'
      icon: 'warning'
      content: content
      choices: choices

  activeTableLimitWarning: =>
    limit = @model.user.activeTablesLimit
    @modal
      title: 'Active Table Limit'
      content: """
        You are already seated at #{limit} tables. You cannot sit at more than #{limit} at one time. Please note that pre-registering for an MTT or SnG counts as sitting at a table.
      """

  # Example config:
  # "webclient.splashPromo.url": "https://www.carbonpoker.ag/client_web_content/promos/side.html",
  # "webclient.splashPromo.width": "250px",
  # "webclient.splashPromo.height": "411px"
  promoPopup: ({url, width, height}) =>
    return unless url
    iframe = $ "<iframe src='#{url}' sandbox='allow-scripts allow-popups'>"
    # once the iframe is completely loaded we fade it in.
    content = $ '<div class="promo-popup-content-container">'
    content.css
      width: width
      height: height
      margin: '0 auto'
    content.html '<div class="modal-loading">' + $('#loader-animation-template').html() + '</div>'
    content.append iframe
    iframe.css
      height: 0
      opacity: 0
      transition: 'opacity 1s ease-in'
      border: 'none'
    iframe.on 'load', (e) =>
      iframe.css 'height', '100%'
      iframe.css 'opacity', 1
      content.find('.modal-loading').remove()
    @modal
      title: 'News'
      content: content
      choices: [{value:'Close'}]

  showSatellites: (listing) =>
    unless listing.satellites.count
      return

    reactView = @createReactView(SatelliteTournament)
    content = reactView.render()

    diff = listing.satelliteInfo.satellites - listing.satellites.count
    if diff > 0
      content.prepend "<p>#{diff} Satellites hidden because they are not available on mobile client.</p>"

    modal = @modal
      title: 'Satellite Tournaments'
      content: content
    Navigator.once 'nav', => modal.teardown()

  showSatelliteFollowOnTournaments: (listing) =>
    unless listing.parents.count
      return

    reactView = @createReactView(FollowOnSatelliteTournament)
    content = reactView.render()

    diff = listing.satelliteInfo.parents - listing.parents.count
    if diff > 0
      content.prepend "<p>#{diff} Satellites hidden because they are not available on mobile client.</p>"

    modal = @modal
      title: 'Satellite Tournaments'
      content: content
    Navigator.once 'nav', => modal.teardown()

  showPreferredSeating: (seats) =>
    @prefSeatingView.seatConfiguration seats
    @modal
      title: "Preferred Seating"
      className: 'pref-seat-modal'
      content: @prefSeatingView.dom
      choices: [
        {value: 'OK'}
      ],
      teardown: @prefSeatingView.reset

  showSwitchTableNotice: ({instanceId, serverId, tournamentId}) =>
    # hide tournament start modal if still open
    @tournamentStartModal?.teardown()
    @tournamentStartModal = null

    # ensure modal is not visible if another switch happens while already showing the modal
    @hideSwitchTableNotice()

    handleSwitch = =>
      @hideSwitchTableNotice()
      @model.clients.toArray().forEach (client) ->
        if client.tournamentId is tournamentId
          client.leaveGame({force: true, lobby: false})
      @model.joinGame({serverId, instanceId}, true)

    @switchTimeSeconds = 10
    content = $("<p class='centerText'>You have been transferred to a new table.</p><p class='centerText'>Time Remaining: <span class='switchTime'>#{@switchTimeSeconds}</span></p>")
    @switchTableInterval = setInterval =>
      if @switchTimeSeconds > 1
        @switchTimeSeconds--
        content.find('.switchTime').text @switchTimeSeconds
      else
        handleSwitch()
    , 1000

    @switchTableNoticeModal = @modal
      title: "Switch Table Notice"
      className: 'switch-table-modal'
      content: content
      choices: [
        {
          value: 'OK'
          action: =>
            handleSwitch()
        }
      ]

  hideSwitchTableNotice: =>
    clearInterval(@switchTableInterval)
    if @switchTableNoticeModal
      @switchTableNoticeModal.teardown()
      @switchTableNoticeModal = null

import React, {useEffect, useState} from 'react'

import Row from '../../components/Row.js'
import {formatHands} from '../rendering.js'

export default function LevelCountdownHands (props) {
  const {countdown, handNumber} = props
  const remaining = countdown.remaining || 0
  const [localCount, setLocalCount] = useState(countdown.updateCount)
  const [startHand, setStartHand] = useState(handNumber)

  useEffect(() => {
    if (localCount < countdown.updateCount) {
      setLocalCount(countdown.updateCount)
      setStartHand(handNumber)
    }
  }, [countdown, handNumber, localCount])

  return <Row>
    Next level in {formatHands(Math.max(remaining + startHand - handNumber, 0))}
  </Row>
}

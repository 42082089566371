import React from 'react'
import {useRoute} from 'react-router5'

import DesktopNavButton from './DesktopNavButton.js'
import styles from './DesktopToolbar.module.css'
import {QUICK_SEAT, SELECTOR_LIST, RING_GAMES, TOURNAMENTS} from '../../routes.js'

export default function DesktopNavButtons () {
  const {route: {name}} = useRoute()

  return <div className={styles.toolbar}>
    <DesktopNavButton routeName={QUICK_SEAT} icon='seat' label='Quick Seat' active={name === 'quickSeat'} />
    <DesktopNavButton routeName={SELECTOR_LIST} icon='lobby' label='Selectors' active={name === 'selectorList'} />
    <DesktopNavButton routeName={RING_GAMES} icon='tables' label='My Games' active={name === 'ringGames'} />
    <DesktopNavButton routeName={TOURNAMENTS} icon='tables' label='My Tournaments' active={name === 'tournaments'} />
  </div>
}

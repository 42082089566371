import Mixmaster from '../../../mixmaster.coffee'

export default class TournamentTableData extends Mixmaster
  mixin: (object) ->
    object.gameTypeId = (signal, name, values) ->
      signal.tournamentTableData.gameTypeId = parseInt(values,10)

    object.tournamentId = (signal, name, values) ->
      signal.tournamentTableData.tournamentId = parseInt(values,10)
      signal.tournamentTableData.tableInstanceId = parseInt(signal.message.instanceId,10)

    object.gameName = (signal, name, values) ->
      signal.tournamentTableData.gameName = values

    object.gameNumber = (signal, name, values) ->
      signal.tournamentTableData.gameNumber = values

    object.instanceId = (signal, name, values) ->
      signal.tournamentTableData.instanceId = parseInt(values,10)

    object.serverId = (signal, name, values) ->
      signal.tournamentTableData.serverId = parseInt(values,10)

    object.registrationRequired = (signal, name, values) ->
      signal.tournamentTableData.isMtt = values
      signal.tournamentTableData.isSng = !values

    object.lateRegistration = (signal, name, values) ->
      signal.tournamentTableData.lateRegistration = values

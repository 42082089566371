import {
  BREAK_COUNTDOWN_LOADED,
  BREAK_ENDED,
  BREAK_LOADED,
  HAND_NUMBER_LOADED,
  JACKPOT_AMOUNT_LOADED,
  JACKPOT_STATUS_LOADED,
  LEVEL_COUNTDOWN_LOADED,
  RING_GAME_TABLE_LOADED,
  STAKES_LOADED,
  TABLE_LEVEL_LOADED,
  TOURNAMENT_TABLE_LOADED,
} from '@pipehat/chronicle/constants/event-type'

import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  handNumber: 0,
  isJackpotActive: false,
  jackpotAmount: undefined,
  level: undefined,
  levelCountdown: undefined,
  name: '',
  stakes: undefined,
  tournament: undefined,
}

const initTournament = {
  id: 0,
  breakCountdown: undefined,
  isOnBreak: false,
  name: '',
}

const gameDetailsSlice = createSlice({
  name: 'gameDetails',
  initialState,
  extraReducers: {
    [RING_GAME_TABLE_LOADED]: (state, {payload}) => {
      state.name = payload.name
    },

    [TOURNAMENT_TABLE_LOADED]: (state, {payload}) => {
      state.name = payload.name

      if (!state.tournament) {
        state.tournament = {
          ...initTournament,
          id: payload.tournamentId,
          name: payload.tournamentName,
        }
      }
    },

    [STAKES_LOADED]: (state, {payload}) => {
      const {limitType, maximumStake, minimumStake} = payload

      state.stakes = {limitType, maximumStake, minimumStake}
    },

    [HAND_NUMBER_LOADED]: (state, {payload}) => {
      state.handNumber = payload.number
    },

    [JACKPOT_AMOUNT_LOADED]: (state, {payload}) => {
      state.jackpotAmount = payload.amount
    },

    [JACKPOT_STATUS_LOADED]: (state, {payload}) => {
      state.isJackpotActive = payload.isActive
    },

    [TABLE_LEVEL_LOADED]: (state, {payload}) => {
      const {current: {ante, bigBlind, smallBlind, number}} = payload

      state.level = {ante, bigBlind, smallBlind, number}
    },

    [LEVEL_COUNTDOWN_LOADED]: (state, {payload}) => {
      const {levelCountdown: {updateCount = 0} = {}} = state
      const {interval: {amount, unit} = {}} = payload

      state.levelCountdown = {remaining: amount, unit, updateCount: updateCount + 1}
    },

    [BREAK_COUNTDOWN_LOADED]: (state, {payload}) => {
      const {breakCountdown: {updateCount = 0} = {}} = state
      state.breakCountdown = {remaining: payload.remainingDuration, updateCount: updateCount + 1}
    },

    [BREAK_LOADED]: state => {
      state.isOnBreak = true
    },

    [BREAK_ENDED]: state => {
      state.isOnBreak = false
    },
  },
})

export default gameDetailsSlice.reducer

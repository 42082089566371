import React from 'react'

import styles from './Room.module.css'
import RoomProvider from './RoomProvider.js'
import Actions from './actions/Actions.js'
import Chat from './chat/Chat.js'
import Controls from './controls/Controls.js'
import TableBackground from './felt/Felt.js'
import GameDetails from './game-details/GameDetails.js'
import QuickOptions from './quick-options/QuickOptions.js'
import TimeBank from './time-bank/TimeBank.js'

export default function Room ({tableId, isDesktop, isPopOut}) {
  return <RoomProvider tableId={tableId}>
    <span className={styles.tableViewGrid}>
      <TableBackground />
      <QuickOptions />
      <Controls isPopOut={isPopOut} />
      <Chat maximizedChat={!isDesktop} />
      <TimeBank />
      <Actions />
      <GameDetails />
    </span>
  </RoomProvider>
}

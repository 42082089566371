import React, {useEffect, useState} from 'react'

import {formatCountdown, fuzzDuration} from '../../../../time/index.js'
import {ONE_SECOND} from '../../../../time/time-amount.js'
import Row from '../../components/Row.js'

export default function LevelCountdownTime (props) {
  const {countdown} = props
  const [remaining, setRemaining] = useState(fuzzDuration(countdown.remaining))

  useEffect(() => {
    let remainingLocal = countdown.remaining
    setRemaining(fuzzDuration(remainingLocal))

    const intervalId = setInterval(() => {
      remainingLocal -= ONE_SECOND
      setRemaining(fuzzDuration(remainingLocal))
    }, ONE_SECOND)

    return () => { clearInterval(intervalId) }
  }, [countdown])

  return <Row>Next level in {formatCountdown(remaining)}</Row>
}

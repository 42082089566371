# The dispatcher is responsible for sending JSON messages to the java servers.
# To do this it builds messages
# which compile into JSON and are piped over the connection.
import {EventEmitter} from 'events'

import MessageSerializer from './messageSerializer.coffee'

import activeBonusesMessage from './messages/activeBonusesMessage.coffee'
import addonMessage from './messages/addonMessage.coffee'
import addToWaitlistMessage from './messages/addToWaitlistMessage.coffee'
import allInMessage from './messages/allInMessage.coffee'
import autoRebuyMessage from './messages/autoRebuyMessage.coffee'
import betMessage from './messages/betMessage.coffee'
import callMessage from './messages/callMessage.coffee'
import chatMessageMessage from './messages/chatMessageMessage.coffee'
import checkMessage from './messages/checkMessage.coffee'
import claimCouponMessage from './messages/claimCouponMessage.coffee'
import connectionCheckMessage from './messages/connectionCheckMessage.coffee'
import depositMessage from './messages/depositMessage.coffee'
import deregisterMessage from './messages/deregisterMessage.coffee'
import foldMessage from './messages/foldMessage.coffee'
import getBalanceMessage from './messages/getBalanceMessage.coffee'
import getClientDataFeedMessage from './messages/getClientDataFeedMessage.coffee'
import getCouponsMessage from './messages/getCouponsMessage.coffee'
import getLevelStructureMessage from './messages/getLevelStructureMessage.coffee'
import getMyGamesMessage from './messages/getMyGamesMessage.coffee'
import getPlayMoneyMessage from './messages/getPlayMoneyMessage.coffee'
import getRingGamesMessage from './messages/getRingGamesMessage.coffee'
import getTokenMessage from './messages/getTokenMessage.coffee'
import getTournamentDetailsMessage from './messages/getTournamentDetailsMessage.coffee'
import getTournamentParentsMessage from './messages/getTournamentParentsMessage.coffee'
import getTournamentPlayersMessage from './messages/getTournamentPlayersMessage.coffee'
import getTournamentSatellitesMessage from './messages/getTournamentSatellitesMessage.coffee'
import getTournamentsMessage from './messages/getTournamentsMessage.coffee'
import getUserGameExclusionsMessage from './messages/getUserGameExclusionsMessage.coffee'
import heartbeatMessage from './messages/heartbeatMessage.coffee'
import imReadyMessage from './messages/imReadyMessage.coffee'
import joinGameMessage from './messages/joinGameMessage.coffee'
import leaveGameMessage from './messages/leaveGameMessage.coffee'
import loginUserMessage from './messages/loginUserMessage.coffee'
import logoutUserMessage from './messages/logoutUserMessage.coffee'
import muckMessage from './messages/muckMessage.coffee'
import postBigBlindMessage from './messages/postBigBlindMessage.coffee'
import postInitialBlindMessage from './messages/postInitialBlindMessage.coffee'
import postReturnBlindMessage from './messages/postReturnBlindMessage.coffee'
import postSmallBlindMessage from './messages/postSmallBlindMessage.coffee'
import rabbitMessage from './messages/rabbitMessage.coffee'
import raiseMessage from './messages/raiseMessage.coffee'
import rebuyMessage from './messages/rebuyMessage.coffee'
import refuseDealItTwiceMessage from './messages/refuseDealItTwiceMessage.coffee'
import registerMessage from './messages/registerMessage.coffee'
import removeFromWaitlistMessage from './messages/removeFromWaitlistMessage.coffee'
import reserveMessage from './messages/reserveMessage.coffee'
import showMessage from './messages/showMessage.coffee'
import sitInMessage from './messages/sitInMessage.coffee'
import sitMessage from './messages/sitMessage.coffee'
import sitOutClearMessage from './messages/sitOutClearMessage.coffee'
import sitOutNextBlindMessage from './messages/sitOutNextBlindMessage.coffee'
import sitOutNextHandMessage from './messages/sitOutNextHandMessage.coffee'
import standUpMessage from './messages/standUpMessage.coffee'
import timebankMessage from './messages/timebankMessage.coffee'
import tournamentRematchMessage from './messages/tournamentRematchMessage.coffee'
import unreserveMessage from './messages/unreserveMessage.coffee'
import updateCouponsMessage from './messages/updateCouponsMessage.coffee'
import updateGameExclusionsMessage from './messages/updateGameExclusionsMessage.coffee'
import updateNicknameMessage from './messages/updateNicknameMessage.coffee'
import updatePlayerMessage from './messages/updatePlayerMessage.coffee'
import waitInitialBlindMessage from './messages/waitInitialBlindMessage.coffee'
import waitReturnBlindMessage from './messages/waitReturnBlindMessage.coffee'

export default class Dispatcher extends EventEmitter
  constructor: (socket) ->
    super()

    @socket = socket

    @index = 0
    @messageDetails = {}

    @serializer = new MessageSerializer

    @messages =
      activeBonuses: activeBonusesMessage
      addon: addonMessage
      addToWaitlist: addToWaitlistMessage
      allIn: allInMessage
      autoRebuy: autoRebuyMessage
      bet: betMessage
      call: callMessage
      chatMessage: chatMessageMessage
      check: checkMessage
      claimCoupon: claimCouponMessage
      connectionCheck: connectionCheckMessage
      deposit: depositMessage
      deregister: deregisterMessage
      fold: foldMessage
      getBalance: getBalanceMessage
      getClientDataFeed: getClientDataFeedMessage
      getCoupons: getCouponsMessage
      getLevelStructure: getLevelStructureMessage
      getMyGames: getMyGamesMessage
      getPlayMoney: getPlayMoneyMessage
      getRingGames: getRingGamesMessage
      getToken: getTokenMessage
      getTournamentDetails: getTournamentDetailsMessage
      getTournamentParents: getTournamentParentsMessage
      getTournamentPlayers: getTournamentPlayersMessage
      getTournaments: getTournamentsMessage
      getTournamentSatellites: getTournamentSatellitesMessage
      getUserGameExclusions: getUserGameExclusionsMessage
      heartbeat: heartbeatMessage
      imReady: imReadyMessage
      joinGame: joinGameMessage
      leaveGame: leaveGameMessage
      loginUser: loginUserMessage
      logoutUser: logoutUserMessage
      muck: muckMessage
      postBb: postBigBlindMessage
      postIb: postInitialBlindMessage
      postRb: postReturnBlindMessage
      postSb: postSmallBlindMessage
      rabbit: rabbitMessage
      raise: raiseMessage
      rebuy: rebuyMessage
      refuseDealItTwice: refuseDealItTwiceMessage
      register: registerMessage
      removeFromWaitlist: removeFromWaitlistMessage
      reserve: reserveMessage
      show: showMessage
      sit: sitMessage
      sitIn: sitInMessage
      sitOutClear: sitOutClearMessage
      sitOutNextBlind: sitOutNextBlindMessage
      sitOutNextHand: sitOutNextHandMessage
      standUp: standUpMessage
      timebank: timebankMessage
      tournamentRematch: tournamentRematchMessage
      unreserve: unreserveMessage
      updateCoupons: updateCouponsMessage
      updateGameExclusions: updateGameExclusionsMessage
      updateNickname: updateNicknameMessage
      updatePlayer: updatePlayerMessage
      waitIb: waitInitialBlindMessage
      waitRb: waitReturnBlindMessage

  command: (commandKey, args = {}) =>
    args.message = @messageDetails
    args.messageId = @index

    request = new @messages[commandKey] args

    if @messageDetails.securityToken and request.attributes.service is 'GAMELIST'
      request.arguments.security_token = @messageDetails.securityToken

    return @enqueue request

  enqueue: (request) =>
    request.attributes.id = @index
    json = @serializer.build request
    @socket.send json
    @index++
    return request

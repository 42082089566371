export const ONE_HUNDRED_MILLIS = 100
export const ONE_SECOND = 1000
export const ONE_MINUTE = ONE_SECOND * 60
export const FIVE_SECONDS = ONE_SECOND * 5
export const TEN_SECONDS = ONE_SECOND * 10
export const THREE_SECONDS = ONE_SECOND * 3
export const FIFTEEN_SECONDS = ONE_SECOND * 15
export const TWO_MINUTES = ONE_MINUTE * 2
export const ONE_HOUR = ONE_MINUTE * 60
export const ONE_DAY = ONE_HOUR * 24
export const THIRTY_DAYS = ONE_DAY * 30

import React from 'react'

import Money from '../../money/components/Money.js'
import {useOpen} from '../../multi-table/react-hooks.js'

export default function TableListingRow (props) {
  const {table: {tableId, biggestStack, contenderCount, number, smallestStack}} = props

  return <tr onClick={useOpen(tableId)}>
    <td>{number}</td>
    <td>{contenderCount}</td>
    <td><Money value={biggestStack} /></td>
    <td><Money value={smallestStack} /></td>
  </tr>
}

import {
  OWN_RING_GAMES_LOADED,
  OWN_TOURNAMENTS_LOADED,
  RING_GAMES_LOADED,
  SATELLITE_FOLLOW_ON_TOURNAMENTS_LOADED,
  TOURNAMENTS_LOADED,
  TOURNAMENT_SATELLITES_LOADED,
} from '@pipehat/chronicle/constants/event-type'

import {PRIORITY_LISTING} from '@pipehat/chronicle/constants/game-category'
import {unpackTabularRow} from '@pipehat/chronicle/translation'
import {produce} from 'immer'

export {getRoot} from './ns.js'

// reducer =====================================================================

const init = {
  ownRingGames: {},
  ownTournaments: {},
  ringGames: {},
  satelliteFollowOnTournaments: {},
  satelliteTournaments: {},
  tournaments: {},
}

export function reducer (state = init, action) {
  const {type, payload} = action

  switch (type) {
    case OWN_RING_GAMES_LOADED:
      return produce(state, state => {
        const ownRingGames = {}
        const {games, keys} = payload

        for (const row of games) {
          const game = unpackTabularRow(keys, row)
          const {seatingStatus, tableId} = game

          ownRingGames[tableId] = {seatingStatus}
        }

        state.ownRingGames = ownRingGames
      })

    case OWN_TOURNAMENTS_LOADED:
      return produce(state, state => {
        const ownTournaments = {}
        const {tournaments, keys} = payload

        for (const row of tournaments) {
          const tournament = unpackTabularRow(keys, row)
          const {tournamentId} = tournament

          ownTournaments[tournamentId] = true
        }

        state.ownTournaments = ownTournaments
      })

    case RING_GAMES_LOADED:
      return produce(state, state => {
        const {games, keys, listType} = payload
        const ringGames = {}

        for (const row of games) {
          const game = unpackTabularRow(keys, row)
          const {
            averagePot,
            categories,
            flopRatio,
            gameTypes,
            handsPerHour,
            limitType,
            maximumStake,
            minimumStake,
            name,
            seatCount,
            seatedCount,
            tableId,
          } = game

          const isPriority = categories.includes(PRIORITY_LISTING)

          ringGames[tableId] = {
            categories,
            averagePot,
            flopRatio,
            gameTypes,
            handsPerHour,
            isPriority,
            limitType,
            maximumStake,
            minimumStake,
            name,
            seatCount,
            seatedCount,
            tableId,
          }
        }

        state.ringGames[listType] = ringGames
      })

    case SATELLITE_FOLLOW_ON_TOURNAMENTS_LOADED:
      return produce(state, state => {
        state.satelliteFollowOnTournaments = {}
        const {keys, games} = payload

        for (const game of games) {
          const tournament = unpackTabularRow(keys, game)
          const {tournamentId} = tournament

          state.satelliteFollowOnTournaments[tournamentId] = tournament
        }
      })

    case TOURNAMENT_SATELLITES_LOADED:
      return produce(state, state => {
        state.satelliteTournaments = {}
        const {keys, games} = payload

        for (const game of games) {
          const tournament = unpackTabularRow(keys, game)
          const {tournamentId} = tournament

          state.satelliteTournaments[tournamentId] = tournament
        }
      })

    case TOURNAMENTS_LOADED:
      return produce(state, state => {
        const {games, keys, listType} = payload
        const tournaments = {}

        for (const row of games) {
          const game = unpackTabularRow(keys, row)
          const {
            categories,
            entryCost,
            gameTypes,
            highlightColor,
            isSng,
            limitType,
            maximumRegistrants,
            name,
            registrantCount,
            startTime,
            status,
            tournamentId,
          } = game

          const isPriority = categories.includes(PRIORITY_LISTING)

          tournaments[tournamentId] = {
            categories,
            entryCost,
            gameTypes,
            highlightColor,
            isPriority,
            isSng,
            limitType,
            maximumRegistrants,
            name,
            registrantCount,
            startTime,
            status,
            tournamentId,
          }
        }

        state.tournaments[listType] = tournaments
      })
  }

  return state
}

// selectors ===================================================================

export function getCriteriaProp (_, {criteria}) {
  return criteria
}

export function getOwnRingGames (state) {
  return state.ownRingGames
}

export function getOwnTournaments (state) {
  return state.ownTournaments
}

export function getPinnedProp (_, {pinned}) {
  return pinned
}

export function getRingGames (state) {
  return state.ringGames
}

export function getSortProp (_, {sort}) {
  return sort
}

export function getTournaments (state) {
  return state.tournaments
}

export function getSatelliteFollowOnTournaments (state) {
  return state.satelliteFollowOnTournaments
}

export function getSatelliteTournaments (state) {
  return state.satelliteTournaments
}

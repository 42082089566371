import Mixmaster from '../../../mixmaster.coffee'

export default class TimebankData extends Mixmaster
  mixin: (object) ->
    object.timeBankEnabled = (signal, name, values) ->
      signal.updates.timebank ?= {}
      signal.updates.timebank.enabled = values

    object.timeBankSelected = (signal, name, values) ->
      signal.updates.timebank ?= {}
      signal.updates.timebank.selected = values.timeBankSelected

    object.timeBankBalance = (signal, name, values) ->
      signal.updates.timebank ?= {}
      if values.true?
        signal.updates.timebank.available = true
        signal.updates.timebank.balance = parseInt values.true, 10
      else
        signal.updates.timebank.available = false
        signal.updates.timebank.balance = parseInt values.false, 10

      signal.updates.timebank.minimum = parseInt values.value2, 10

    object.usingTimeBank = (signal, name, values) ->
      signal.updates.timebank ?= {}
      signal.updates.usingTimebank = {}
      if values.true
        signal.updates.usingTimebank.seconds = parseInt values.true, 10 # todo: values.true!?

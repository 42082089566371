# BettingControlsView (ABSTRACT)
# Model: ?

# Provides methods common to Buyin, Rebuy, Bet and Raise dialogs

import numberInput from './numberInput.coffee'
import View from '../view.coffee'

export default class BettingControlsView extends View

  initializeBettingConstrolsView: (client, targetViewElement) ->
    @model = client
    @modalTarget = targetViewElement

    # ### Events - eg. MouseClicks ###
    input = @inputComponent
    if input
      input.on 'focus', numberInput.select
      input.on 'keypress', numberInput.restrictInput
      input.on 'keypress', @checkForEnter
      input.on 'blur', numberInput.snapToStep

    @initializeView()
    return this

  checkForEnter: (e) =>
    if e.keyCode is 13
      @inputComponent.trigger('blur')

  # overridden by bet/raise/rebuy
  createPresets: =>
    @components.presets.empty()
    game = @game

    min = parseFloat @inputComponent.attr 'min'
    max = parseFloat @inputComponent.attr 'max'
    defaultVal = @game.minBet * parseInt(@model.user.settings.defaultBuyin)
    defaultVal = Math.max min, defaultVal
    defaultVal = Math.min max, defaultVal
    presets = [
      { name: 'Min', amount: min }
      { name: 'Default', amount: defaultVal }
      { name: 'Max', amount: max }
    ]
    for preset in presets then do (preset, game) =>
      button = $ "<div class='button button-disabled inline-button'>#{preset.name}</div>"
      if preset.action
        button.removeClass 'button-disabled'
        button.on 'click', preset.action
      else
        amount = preset.amount
        if amount >= min && amount <= max
          amount = amount.toFixed 2
          button.removeClass 'button-disabled'
          button.on 'click', () =>
            @inputComponent.val(amount).trigger 'change'
      @components.presets.append button

  validate: =>
    if numberInput.validate @inputComponent
      precision = if @game.minChipValue is 1 then 0 else 2 # tourneys 1 chip, cash games 0.01 dollars
      v = parseFloat @inputComponent.val()
      s = parseFloat v.toFixed(precision)
      if v is s
        return true
      else
        @modal
          title: 'Invalid Amount'
          target: @modalTarget
          stackOrder: 1
          content: 'Minimum chip value is ' + @game.minChipValue
        return false
    else
      min = parseFloat(@inputComponent.attr('min'))
      max = parseFloat(@inputComponent.attr('max'))
      @modal
        title: 'Invalid Amount'
        target: @modalTarget
        stackOrder: 1
        content: "Ensure you enter a numeric value between the minimum (#{min.toMoney()}) and maximum (#{max.toMoney()}) values allowed."
      return false

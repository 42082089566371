import classnames from 'classnames'
import React from 'react'

import styles from '../GameDetails.module.css'

export default function Row ({children, className}) {
  return <span className={classnames(styles.row, className)}>
    {children}
  </span>
}

import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# handleTokenUpdateEvent(String token)

export default class GetTokenTranslator extends Mixmaster
  mixins = [
    DefaultMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal
    signal.updates.token ?= {}

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  token: (signal, name, values) ->
    signal.updates.token.token = values

  keyId: (signal, name, values) ->
    signal.updates.token.keyId = values

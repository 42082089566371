import ModalView from '../legacy/views/modalView.coffee'

export function createService () {
  return {
    showModal (args) {
      const modalView = new ModalView(args)
      modalView.attachToBody()

      return function close (quite = false) {
        if (quite) modalView.quiteTeardown()
        else modalView.teardown()
      }
    },
  }
}

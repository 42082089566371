import React, {useEffect} from 'react'

import AutoMuck from './AutoMuck.js'
import AutoPostBlinds from './AutoPostBlinds.js'
import AutoRebuy from './AutoRebuy.js'
import AutoTimebank from './AutoTimebank.js'
import CheckFoldAction from './CheckFoldAction.js'
import ConfirmAllIn from './ConfirmAllIn.js'
import DefaultBuyIn from './DefaultBuyIn.js'
import FourColorDeck from './FourColorDeck.js'
import Group from './Group.js'
import TablePopoutOptions from './TablePopoutOptions.js'
import {DESKTOP} from '../../device/constants.js'
import {useFormat} from '../../device/react-hooks.js'
import {useDisablePopouts, usePopoutConfiguration} from '../react-hooks.js'

export default function GameSettingsGroup (props) {
  const {isPrivileged} = props
  const isDesktop = useFormat() === DESKTOP
  const popoutConfig = usePopoutConfiguration()
  const disablePopouts = useDisablePopouts()

  useEffect(() => {
    if (!isDesktop && popoutConfig !== 'off') disablePopouts()
  }, [disablePopouts, isDesktop, popoutConfig])

  return <Group heading='Game Settings'>
    {!isPrivileged && isDesktop && <TablePopoutOptions />}
    <ConfirmAllIn />
    <AutoPostBlinds />
    <AutoMuck />
    <AutoTimebank />
    <DefaultBuyIn />
    <CheckFoldAction />
    <FourColorDeck />
    <AutoRebuy />
  </Group>
}

import {createSlice} from '@reduxjs/toolkit'

const signalSlice = createSlice({
  name: 'signal',
  initialState: 0,
  reducers: {
    latencyCalculated (state, {payload}) {
      return payload.latency
    },
  },
})

export default signalSlice.reducer

export const {latencyCalculated} = signalSlice.actions

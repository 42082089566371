import {createSelector} from '@reduxjs/toolkit'

import {LIST_TYPE_MAP} from './constants.js'
import {isTypeSequenceSupported} from '../../game-type.js'
import {ALL} from '../constants.js'
import {getCriteriaProp, getOwnTournaments, getPinnedProp, getSortProp, getTournaments} from '../index.js'
import {sortTournaments} from '../sorting.js'

export function createGetListing () {
  return createSelector(
    [
      getOwnTournaments,
      getPinnedProp,
      getTournaments,
      getCriteriaProp,
      getSortProp,
    ],
    (ownTournaments, pinned, tournaments, criteria, sort) => {
      const {limitType, tournamentType, variant} = criteria
      const listTypes = LIST_TYPE_MAP[tournamentType]

      const entries = {}

      for (const [listType] of listTypes) {
        const listTypeTournaments = tournaments[listType]

        if (!listTypeTournaments) continue

        Object.assign(entries, Object.fromEntries(
          Object.entries(listTypeTournaments)
            .filter(([, tournament]) => {
              const {gameTypes} = tournament
              const tournamentVariant = gameTypes[0].variant

              if (!isTypeSequenceSupported(gameTypes)) return false
              if (limitType !== ALL && tournament.limitType !== limitType) return false
              if (variant !== ALL && tournamentVariant !== variant) return false

              return true
            })
            .map(([tournamentId, tournament]) => {
              return [
                tournamentId,
                {
                  ...tournament,
                  isOwn: Boolean(ownTournaments[tournamentId]),
                  isPinned: Boolean(pinned[tournamentId]),
                },
              ]
            }),
        ))
      }

      return sortTournaments(sort, entries)
    },
  )
}

export function createIsLoaded () {
  return createSelector(
    [
      getTournaments,
      getCriteriaProp,
    ],
    (tournaments, criteria) => {
      const {tournamentType} = criteria
      const listTypes = LIST_TYPE_MAP[tournamentType]

      return listTypes.every(([listType]) => tournaments[listType])
    },
  )
}

export default
  "internal.smiley0":
    text: ":)"
    width: "76"
    height: "76"
    files: ["sml001"]
    frames: [
      index: "0"
      reps: "99"
    ]

  "internal.smiley1":
    text: ":-)"
    style: "internal.smiley0"

  "internal.smiley2":
    text: ":|"
    width: "76"
    height: "76"
    files: ["sml002"]
    frames: [
      index: "0"
      reps: "99"
    ]

  "internal.smiley3":
    text: ":-|"
    style: "internal.smiley2"

  "internal.smiley4":
    text: ":D"
    width: "76"
    height: "76"
    files: ["sml003"]
    frames: [
      index: "0"
      reps: "99"
    ]

  "internal.smiley5":
    text: "crazy"
    reps: "5"
    width: "76"
    height: "76"
    files: ["sml004-1", "sml004-2", "sml004-3", "sml004-4"]
    frames: [
      index: "0"
      reps: "1"
    ,
      index: "1"
      reps: "1"
    ,
      index: "2"
      reps: "1"
    ,
      index: "3"
      reps: "1"
    ]

  "internal.smiley6":
    text: "eek"
    width: "76"
    height: "76"
    files: ["sml005-1", "sml005-2", "sml005-3"]
    frames: [
      index: "0"
      reps: "9"
    ,
      index: "1"
      reps: "1"
    ,
      index: "2"
      reps: "39"
    ]

  "internal.smiley7":
    text: ";)"
    width: "76"
    height: "76"
    files: ["sml006-1", "sml006-2", "sml006-3"]
    frames: [
      index: "0"
      reps: "11"
    ,
      index: "1"
      reps: "2"
    ,
      index: "2"
      reps: "11"
    ,
      index: "1"
      reps: "2"
    ,
      index: "0"
      reps: "24"
    ]

  "internal.smiley8":
    text: "8)"
    width: "76"
    height: "76"
    files: ["sml007"]
    frames: [
      index: "0"
      reps: "59"
    ]

  "internal.smiley9":
    text: "8-)"
    style: "internal.smiley8"

  "internal.smiley10":
    text: "lol"
    reps: "6"
    width: "76"
    height: "76"
    files: ["sml008-1", "sml008-2", "sml008-3"]
    frames: [
      index: "0"
      reps: "1"
    ,
      index: "1"
      reps: "1"
    ,
      index: "2"
      reps: "1"
    ,
      index: "1"
      reps: "1"
    ]

  "internal.smiley11":
    text: "LOL"
    style: "internal.smiley10"

  "internal.smiley12":
    text: "doh"
    width: "76"
    height: "76"
    files: ["sml009"]
    frames: [
      index: "0"
      reps: "59"
    ]

  "internal.smiley13":
    text: "DOH"
    style: "internal.smiley12"

  "internal.smiley14":
    text: ":("
    width: "76"
    height: "76"
    files: ["sml010"]
    frames: [
      index: "0"
      reps: "59"
    ]

  "internal.smiley15":
    text: ":-("
    style: "internal.smiley14"

  "internal.smiley16":
    text: "cry"
    reps: "7"
    width: "76"
    height: "76"
    files: ["sml011-1", "sml011-2", "sml011-3", "sml011-4"]
    frames: [
      index: "0"
    ,
      index: "1"
    ,
      index: "2"
    ,
      index: "3"
    ]

  "internal.smiley17":
    text: "thumbsup"
    width: "104"
    height: "76"
    files: ["sml012"]
    frames: [
      index: "0"
      reps: "99"
    ]

  "internal.smiley18":
    text: "hi"
    reps: "3"
    width: "114"
    height: "76"
    files: ["sml013-1", "sml013-2", "sml013-3"]
    frames: [
      index: "0"
      reps: "3"
    ,
      index: "1"
      reps: "1"
    ,
      index: "2"
      reps: "3"
    ,
      index: "1"
      reps: "1"
    ]

  "internal.smiley19":
    text: "bye"
    style: "internal.smiley18"

  "internal.smiley20":
    text: "punch"
    reps: "5"
    width: "129"
    height: "76"
    files: ["sml014-1", "sml014-2", "sml014-3", "sml014-4", "sml014-5"]
    frames: [
      index: "0"
      reps: "1"
    ,
      index: "1"
      reps: "1"
    ,
      index: "2"
      reps: "1"
    ,
      index: "3"
      reps: "1"
    ,
      index: "4"
      reps: "1"
    ]

  "internal.smiley21":
    text: "zzz"
    reps: "2"
    width: "114"
    height: "76"
    files: ["sml015-01", "sml015-02", "sml015-03", "sml015-04", "sml015-05", "sml015-06", "sml015-07", "sml015-08", "sml015-09", "sml015-10"]
    frames: [
      index: "0"
      reps: "24"
    ,
      index: "1"
      reps: "2"
    ,
      index: "2"
      reps: "2"
    ,
      index: "3"
      reps: "2"
    ,
      index: "4"
      reps: "2"
    ,
      index: "5"
      reps: "2"
    ,
      index: "6"
      reps: "2"
    ,
      index: "7"
      reps: "2"
    ,
      index: "8"
      reps: "2"
    ,
      index: "9"
      reps: "2"
    ]

  "internal.smiley22":
    text: "mad"
    width: "76"
    height: "76"
    files: ["sml016-1", "sml016-2"]
    frames: [
      index: "0"
      reps: "6"
    ,
      index: "1"
      reps: "3"
    ,
      index: "0"
      reps: "7"
    ,
      index: "1"
      reps: "1"
    ,
      index: "0"
      reps: "2"
    ,
      index: "1"
      reps: "2"
    ,
      index: "0"
      reps: "7"
    ,
      index: "1"
      reps: "1"
    ,
      index: "0"
      reps: "3"
    ,
      index: "1"
      reps: "2"
    ]

  "internal.smiley23":
    text: "drunk"
    width: "94"
    height: "76"
    files: ["sml017"]
    frames: [
      index: "0"
      reps: "59"
    ]

  "internal.smiley24":
    text: ":p"
    reps: "7"
    width: "76"
    height: "76"
    files: ["sml018-01", "sml018-02", "sml018-03"]
    frames: [
      index: "0"
    ,
      index: "1"
    ,
      index: "2"
    ,
      index: "1"
    ]

  "internal.smiley25":
    text: "evil"
    width: "76"
    height: "76"
    files: ["sml019-1", "sml019-2", "sml019-3"]
    frames: [
      index: "0"
      reps: "4"
    ,
      index: "1"
    ,
      index: "2"
    ,
      index: "1"
    ,
      index: "0"
      reps: "9"
    ,
      index: "1"
    ,
      index: "2"
    ,
      index: "1"
    ,
      index: "0"
      reps: "9"
    ,
      index: "1"
    ,
      index: "2"
    ,
      index: "1"
    ,
      index: "0"
      reps: "29"
    ]

  "internal.smiley26":
    text: "roll"
    width: "76"
    height: "76"
    files: ["sml020-1", "sml020-2", "sml020-3", "sml020-4", "sml020-5", "sml020-6"]
    frames: [
      index: "0"
      reps: "8"
    ,
      index: "1"
      reps: "2"
    ,
      index: "2"
      reps: "2"
    ,
      index: "3"
      reps: "2"
    ,
      index: "4"
      reps: "2"
    ,
      index: "5"
      reps: "2"
    ,
      index: "0"
      reps: "29"
    ]

  "internal.smiley27":
    text: "blush"
    reps: "4"
    width: "76"
    height: "76"
    files: ["sml021-01", "sml021-02", "sml021-03", "sml021-04"]
    frames: [
      index: "0"
      reps: "2"
    ,
      index: "1"
      reps: "2"
    ,
      index: "2"
      reps: "2"
    ,
      index: "3"
      reps: "2"
    ,
      index: "2"
      reps: "2"
    ,
      index: "1"
      reps: "2"
    ]

  "internal.smiley28":
    text: "cigar"
    reps: "2"
    width: "134"
    height: "76"
    files: ["sml022-01", "sml022-02", "sml022-03", "sml022-04", "sml022-05", "sml022-06", "sml022-07", "sml022-08", "sml022-09", "sml022-10"]
    frames: [
      index: "0"
      reps: "1"
    ,
      index: "1"
      reps: "1"
    ,
      index: "2"
      reps: "1"
    ,
      index: "3"
      reps: "1"
    ,
      index: "4"
      reps: "1"
    ,
      index: "5"
      reps: "1"
    ,
      index: "6"
      reps: "1"
    ,
      index: "7"
      reps: "1"
    ,
      index: "8"
      reps: "1"
    ,
      index: "9"
      reps: "1"
    ]

  "internal.smiley29":
    text: "clap"
    reps: "7"
    width: "80"
    height: "76"
    files: ["sml023-1", "sml023-2", "sml023-3"]
    frames: [
      index: "0"
      reps: "2"
    ,
      index: "1"
      reps: "2"
    ,
      index: "2"
      reps: "2"
    ,
      index: "1"
      reps: "2"
    ]

  "internal.smiley30":
    text: "phew"
    width: "130"
    height: "76"
    files: ["sml024-1", "sml024-2", "sml024-3", "sml024-4", "sml024-5", "sml024-6", "sml024-7"]
    frames: [
      index: "0"
      reps: "11"
    ,
      index: "1"
      reps: "1"
    ,
      index: "2"
      reps: "1"
    ,
      index: "3"
      reps: "1"
    ,
      index: "4"
      reps: "1"
    ,
      index: "5"
      reps: "1"
    ,
      index: "6"
      reps: "29"
    ]

  # "internal.smiley31":
  #   text: "oops"
  #   width: "76"
  #   height: "76"
  #   files: ["sml025-1", "sml025-2", "sml025-3", "sml025-4"]
  #   frames: [
  #     index: "0"
  #     reps: "4"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "29"
  #   ]

  # "internal.smiley32":
  #   text: "violin"
  #   reps: "2"
  #   width: "120"
  #   height: "76"
  #   files: ["sml026-1", "sml026-2", "sml026-3", "sml026-4", "sml026-5"]
  #   frames: [
  #     index: "0"
  #     reps: "3"
  #   ,
  #     index: "1"
  #     reps: "3"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "3"
  #     reps: "3"
  #   ,
  #     index: "4"
  #     reps: "3"
  #   ,
  #     index: "3"
  #     reps: "3"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "1"
  #     reps: "3"
  #   ]

  # "internal.smiley33":
  #   text: "loser"
  #   width: "76"
  #   height: "76"
  #   files: ["sml027-1", "sml027-2", "sml027-3", "sml027-4"]
  #   frames: [
  #     index: "0"
  #     reps: "8"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "51"
  #   ]

  # "internal.smiley34":
  #   text: "kiss"
  #   width: "76"
  #   height: "76"
  #   files: ["sml028-1", "sml028-2", "sml028-3"]
  #   frames: [
  #     index: "0"
  #     reps: "11"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "11"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "0"
  #     reps: "49"
  #   ]

  # "internal.smiley35":
  #   text: "flutter"
  #   width: "76"
  #   height: "76"
  #   files: ["sml029-1", "sml029-2"]
  #   frames: [
  #     index: "0"
  #     reps: "11"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "0"
  #     reps: "24"
  #   ]

  # "internal.smiley36":
  #   text: "teehee"
  #   reps: "3"
  #   width: "76"
  #   height: "76"
  #   files: ["sml030-1", "sml030-2", "sml030-3"]
  #   frames: [
  #     index: "0"
  #     reps: "2"
  #   ,
  #     index: "1"
  #   ,
  #     index: "2"
  #     reps: "2"
  #   ,
  #     index: "1"
  #   ]

  # "internal.smiley37":
  #   text: "tilt"
  #   width: "124"
  #   height: "76"
  #   files: ["sml031-01", "sml031-02", "sml031-03", "sml031-04", "sml031-05", "sml031-06", "sml031-07", "sml031-08", "sml031-09", "sml031-10", "sml031-11", "sml031-12", "sml031-13", "sml031-14", "sml031-15", "sml031-16", "sml031-17", "sml031-18"]
  #   frames: [
  #     index: "0"
  #     reps: "24"
  #   ,
  #     index: "1"
  #   ,
  #     index: "2"
  #   ,
  #     index: "3"
  #   ,
  #     index: "4"
  #   ,
  #     index: "5"
  #   ,
  #     index: "6"
  #   ,
  #     index: "7"
  #   ,
  #     index: "8"
  #   ,
  #     index: "9"
  #   ,
  #     index: "10"
  #   ,
  #     index: "11"
  #   ,
  #     index: "12"
  #   ,
  #     index: "13"
  #   ,
  #     index: "14"
  #   ,
  #     index: "15"
  #   ,
  #     index: "16"
  #   ,
  #     index: "17"
  #   ]

  # "internal.smiley38":
  #   text: "fish"
  #   reps: "4"
  #   width: "76"
  #   height: "76"
  #   files: ["sml032-1", "sml032-2", "sml032-3", "sml032-4", "sml032-5"]
  #   frames: [
  #     index: "0"
  #     reps: "2"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "2"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "4"
  #     reps: "2"
  #   ]

  # "internal.smiley39":
  #   text: "rabbit"
  #   width: "76"
  #   height: "76"
  #   files: ["sml033-1", "sml033-2", "sml033-3", "sml033-4"]
  #   frames: [
  #     index: "0"
  #     reps: "8"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "29"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "0"
  #     reps: "8"
  #   ]

  # "internal.smiley40":
  #   text: "shark"
  #   width: "150"
  #   height: "76"
  #   files: ["sml034-01", "sml034-02", "sml034-03", "sml034-04", "sml034-05", "sml034-06", "sml034-07", "sml034-08", "sml034-09", "sml034-10", "sml034-11", "sml034-12", "sml034-13", "sml034-14", "sml034-15", "sml034-16", "sml034-17", "sml034-18"]
  #   frames: [
  #     index: "0"
  #     reps: "2"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "2"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "0"
  #     reps: "2"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "2"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "4"
  #     reps: "2"
  #   ,
  #     index: "5"
  #     reps: "2"
  #   ,
  #     index: "6"
  #     reps: "2"
  #   ,
  #     index: "7"
  #     reps: "2"
  #   ,
  #     index: "8"
  #     reps: "2"
  #   ,
  #     index: "9"
  #     reps: "2"
  #   ,
  #     index: "10"
  #     reps: "2"
  #   ,
  #     index: "11"
  #     reps: "2"
  #   ,
  #     index: "12"
  #     reps: "2"
  #   ,
  #     index: "13"
  #     reps: "2"
  #   ,
  #     index: "14"
  #     reps: "2"
  #   ,
  #     index: "15"
  #     reps: "2"
  #   ,
  #     index: "16"
  #     reps: "2"
  #   ,
  #     index: "17"
  #     reps: "2"
  #   ,
  #     index: "13"
  #     reps: "2"
  #   ,
  #     index: "14"
  #     reps: "2"
  #   ,
  #     index: "15"
  #     reps: "2"
  #   ,
  #     index: "16"
  #     reps: "2"
  #   ,
  #     index: "17"
  #     reps: "2"
  #   ,
  #     index: "13"
  #     reps: "2"
  #   ,
  #     index: "14"
  #     reps: "2"
  #   ,
  #     index: "15"
  #     reps: "2"
  #   ,
  #     index: "16"
  #     reps: "2"
  #   ,
  #     index: "17"
  #     reps: "2"
  #   ]

  # "internal.smiley41":
  #   text: "donkey"
  #   width: "76"
  #   height: "76"
  #   files: ["sml035-01", "sml035-02", "sml035-03", "sml035-04", "sml035-05", "sml035-06", "sml035-07", "sml035-08", "sml035-09", "sml035-10"]
  #   frames: [
  #     index: "0"
  #     reps: "11"
  #   ,
  #     index: "1"
  #   ,
  #     index: "2"
  #   ,
  #     index: "3"
  #   ,
  #     index: "4"
  #   ,
  #     index: "5"
  #   ,
  #     index: "6"
  #   ,
  #     index: "7"
  #   ,
  #     index: "8"
  #   ,
  #     index: "9"
  #     reps: "5"
  #   ,
  #     index: "8"
  #   ,
  #     index: "7"
  #   ,
  #     index: "6"
  #   ,
  #     index: "5"
  #   ,
  #     index: "4"
  #   ,
  #     index: "3"
  #   ,
  #     index: "2"
  #   ,
  #     index: "1"
  #   ,
  #     index: "0"
  #     reps: "11"
  #   ]

  # "internal.smiley42":
  #   text: "fart"
  #   width: "150"
  #   height: "76"
  #   files: ["sml036-01", "sml036-02", "sml036-03", "sml036-04", "sml036-05", "sml036-06", "sml036-07", "sml036-08", "sml036-09", "sml036-10", "sml036-11"]
  #   frames: [
  #     index: "0"
  #     reps: "11"
  #   ,
  #     index: "1"
  #     reps: "11"
  #   ,
  #     index: "2"
  #     reps: "2"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "5"
  #     reps: "1"
  #   ,
  #     index: "6"
  #     reps: "1"
  #   ,
  #     index: "7"
  #     reps: "1"
  #   ,
  #     index: "8"
  #     reps: "1"
  #   ,
  #     index: "9"
  #     reps: "1"
  #   ,
  #     index: "10"
  #     reps: "8"
  #   ]

  # "internal.smiley43":
  #   text: "sick"
  #   width: "76"
  #   height: "76"
  #   files: ["sml037-01", "sml037-02", "sml037-03", "sml037-04", "sml037-05", "sml037-06", "sml037-07", "sml037-08", "sml037-09", "sml037-10", "sml037-11", "sml037-12", "sml037-13", "sml037-14", "sml037-15", "sml037-16", "sml037-17", "sml037-18", "sml037-19"]
  #   frames: [
  #     index: "0"
  #     reps: "24"
  #   ,
  #     index: "1"
  #     reps: "3"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "3"
  #     reps: "3"
  #   ,
  #     index: "4"
  #     reps: "7"
  #   ,
  #     index: "5"
  #     reps: "1"
  #   ,
  #     index: "6"
  #     reps: "1"
  #   ,
  #     index: "7"
  #     reps: "1"
  #   ,
  #     index: "8"
  #     reps: "1"
  #   ,
  #     index: "9"
  #     reps: "1"
  #   ,
  #     index: "8"
  #     reps: "1"
  #   ,
  #     index: "9"
  #     reps: "1"
  #   ,
  #     index: "10"
  #     reps: "1"
  #   ,
  #     index: "11"
  #     reps: "1"
  #   ,
  #     index: "12"
  #     reps: "1"
  #   ,
  #     index: "13"
  #     reps: "1"
  #   ,
  #     index: "14"
  #     reps: "1"
  #   ,
  #     index: "15"
  #     reps: "1"
  #   ,
  #     index: "16"
  #     reps: "1"
  #   ,
  #     index: "17"
  #     reps: "1"
  #   ,
  #     index: "18"
  #     reps: "11"
  #   ]

  # "internal.smiley44":
  #   text: "river"
  #   reps: "4"
  #   width: "212"
  #   height: "76"
  #   files: ["sml038-01", "sml038-02", "sml038-03", "sml038-04", "sml038-05", "sml038-06", "sml038-07", "sml038-08", "sml038-09", "sml038-10"]
  #   frames: [
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "5"
  #     reps: "1"
  #   ,
  #     index: "6"
  #     reps: "1"
  #   ,
  #     index: "7"
  #     reps: "1"
  #   ,
  #     index: "8"
  #     reps: "1"
  #   ,
  #     index: "9"
  #     reps: "1"
  #   ]

  # "internal.smiley45":
  #   text: "all in"
  #   width: "120"
  #   height: "76"
  #   files: ["sml039-01", "sml039-02", "sml039-03", "sml039-04", "sml039-05", "sml039-06", "sml039-07"]
  #   frames: [
  #     index: "0"
  #     reps: "11"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "5"
  #     reps: "1"
  #   ,
  #     index: "6"
  #     reps: "19"
  #   ]

  # "internal.smiley46":
  #   text: "laugh"
  #   style: "internal.smiley10"

  # "internal.smiley47":
  #   text: "ouch"
  #   style: "internal.smiley20"

  # "internal.smiley48":
  #   text: "hello"
  #   style: "internal.smiley18"

  # "internal.smiley49":
  #   text: "angry"
  #   style: "internal.smiley22"

  # "internal.smiley50":
  #   text: "shocked"
  #   style: "internal.smiley6"

  # "internal.smiley51":
  #   text: "streaker"
  #   reps: "3"
  #   width: "76"
  #   height: "76"
  #   files: ["sml040-01", "sml040-02", "sml040-03", "sml040-04", "sml040-05", "sml040-06", "sml040-07", "sml040-08"]
  #   frames: [
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "5"
  #     reps: "1"
  #   ,
  #     index: "6"
  #     reps: "1"
  #   ,
  #     index: "7"
  #     reps: "1"
  #   ]

  # "internal.smiley52":
  #   text: "dance"
  #   reps: "3"
  #   width: "160"
  #   height: "76"
  #   files: ["sml041-01", "sml041-02", "sml041-03", "sml041-04", "sml041-05", "sml041-06", "sml041-07", "sml041-08", "sml041-09"]
  #   frames: [
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "5"
  #     reps: "1"
  #   ,
  #     index: "6"
  #     reps: "1"
  #   ,
  #     index: "7"
  #     reps: "1"
  #   ,
  #     index: "8"
  #     reps: "1"
  #   ]

  # "internal.smiley53":
  #   text: "shrug"
  #   width: "150"
  #   height: "76"
  #   files: ["sml042-01", "sml042-02", "sml042-03", "sml042-04"]
  #   frames: [
  #     index: "0"
  #     reps: "5"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "11"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "0"
  #     reps: "5"
  #   ]

  # "internal.smiley54":
  #   text: "pray"
  #   width: "84"
  #   height: "76"
  #   files: ["sml043"]
  #   frames: [
  #     index: "0"
  #     reps: "60"
  #   ]

  # "internal.smiley55":
  #   text: "rockon"
  #   reps: "3"
  #   width: "84"
  #   height: "76"
  #   files: ["sml044-01", "sml044-02", "sml044-03", "sml044-04"]
  #   frames: [
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ]

  # "internal.smiley56":
  #   text: "whosyourdaddy"
  #   reps: "2"
  #   width: "136"
  #   height: "76"
  #   files: ["sml045-01", "sml045-02", "sml045-03", "sml045-04"]
  #   frames: [
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ]

  # "internal.smiley57":
  #   text: "heater"
  #   reps: "3"
  #   width: "76"
  #   height: "76"
  #   files: ["sml046-01", "sml046-02", "sml046-03", "sml046-04", "sml046-05"]
  #   frames: [
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "2"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "4"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "4"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "3"
  #   ,
  #     index: "0"
  #     reps: "1"
  #   ]

  # "internal.smiley58":
  #   text: "cooler"
  #   width: "120"
  #   height: "76"
  #   files: ["sml047-01", "sml047-02", "sml047-03", "sml047-04", "sml047-05", "sml047-06", "sml047-07", "sml047-08", "sml047-09", "sml047-10", "sml047-11", "sml047-12", "sml047-13", "sml047-14"]
  #   frames: [
  #     index: "0"
  #     reps: "15"
  #   ,
  #     index: "1"
  #     reps: "3"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "3"
  #     reps: "3"
  #   ,
  #     index: "4"
  #     reps: "17"
  #   ,
  #     index: "5"
  #     reps: "2"
  #   ,
  #     index: "6"
  #     reps: "2"
  #   ,
  #     index: "7"
  #     reps: "11"
  #   ,
  #     index: "8"
  #     reps: "1"
  #   ,
  #     index: "9"
  #     reps: "1"
  #   ,
  #     index: "8"
  #     reps: "1"
  #   ,
  #     index: "9"
  #     reps: "1"
  #   ,
  #     index: "10"
  #     reps: "1"
  #   ,
  #     index: "11"
  #     reps: "1"
  #   ,
  #     index: "12"
  #     reps: "1"
  #   ,
  #     index: "13"
  #     reps: "15"
  #   ]

  # "internal.smiley59":
  #   text: "knockout"
  #   reps: "1"
  #   width: "212"
  #   height: "76"
  #   files: ["sml048-01", "sml048-02", "sml048-03", "sml048-04", "sml048-05", "sml048-06", "sml048-07", "sml048-08", "sml048-09", "sml048-10", "sml048-11", "sml048-12", "sml048-13", "sml048-14", "sml048-15", "sml048-16", "sml048-17", "sml048-18", "sml048-19", "sml048-20", "sml048-21", "sml048-22", "sml048-23", "sml048-24", "sml048-25", "sml048-26", "sml048-27", "sml048-28", "sml048-29", "sml048-30", "sml048-31", "sml048-32"]
  #   frames: [
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "5"
  #     reps: "1"
  #   ,
  #     index: "6"
  #     reps: "1"
  #   ,
  #     index: "7"
  #     reps: "1"
  #   ,
  #     index: "8"
  #     reps: "1"
  #   ,
  #     index: "9"
  #     reps: "1"
  #   ,
  #     index: "10"
  #     reps: "1"
  #   ,
  #     index: "11"
  #     reps: "1"
  #   ,
  #     index: "12"
  #     reps: "1"
  #   ,
  #     index: "13"
  #     reps: "1"
  #   ,
  #     index: "14"
  #     reps: "1"
  #   ,
  #     index: "15"
  #     reps: "1"
  #   ,
  #     index: "16"
  #     reps: "1"
  #   ,
  #     index: "17"
  #     reps: "1"
  #   ,
  #     index: "18"
  #     reps: "1"
  #   ,
  #     index: "19"
  #     reps: "1"
  #   ,
  #     index: "20"
  #     reps: "1"
  #   ,
  #     index: "21"
  #     reps: "1"
  #   ,
  #     index: "22"
  #     reps: "6"
  #   ,
  #     index: "23"
  #   ,
  #     index: "24"
  #   ,
  #     index: "25"
  #   ,
  #     index: "26"
  #   ,
  #     index: "27"
  #   ,
  #     index: "28"
  #   ,
  #     index: "29"
  #   ,
  #     index: "30"
  #   ,
  #     index: "31"
  #   ,
  #     index: "30"
  #   ,
  #     index: "29"
  #   ,
  #     index: "28"
  #   ,
  #     index: "27"
  #   ,
  #     index: "26"
  #   ,
  #     index: "25"
  #   ,
  #     index: "24"
  #   ,
  #     index: "23"
  #   ,
  #     index: "24"
  #   ,
  #     index: "25"
  #   ,
  #     index: "26"
  #   ,
  #     index: "27"
  #   ,
  #     index: "28"
  #   ,
  #     index: "29"
  #   ,
  #     index: "30"
  #   ,
  #     index: "31"
  #   ,
  #     index: "30"
  #   ,
  #     index: "29"
  #   ,
  #     index: "28"
  #   ,
  #     index: "27"
  #   ,
  #     index: "26"
  #   ,
  #     index: "25"
  #   ,
  #     index: "24"
  #   ,
  #     index: "23"
  #   ]

  # "internal.smiley60":
  #   text: "card dead"
  #   width: "138"
  #   height: "76"
  #   files: ["sml049-01", "sml049-02", "sml049-03", "sml049-04", "sml049-05", "sml049-06", "sml049-07", "sml049-08", "sml049-09", "sml049-10", "sml049-11", "sml049-12", "sml049-13", "sml049-14", "sml049-15", "sml049-16", "sml049-17", "sml049-18", "sml049-19", "sml049-20", "sml049-21", "sml049-22", "sml049-23", "sml049-24", "sml049-25", "sml049-26", "sml049-27", "sml049-28", "sml049-29", "sml049-30", "sml049-31", "sml049-32", "sml049-33", "sml049-34", "sml049-35", "sml049-36", "sml049-37", "sml049-38", "sml049-39", "sml049-40", "sml049-41", "sml049-42", "sml049-43", "sml049-44", "sml049-45", "sml049-46", "sml049-47", "sml049-48", "sml049-49", "sml049-50", "sml049-51", "sml049-52", "sml049-53", "sml049-54", "sml049-55"]
  #   frames: [
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "5"
  #     reps: "1"
  #   ,
  #     index: "6"
  #     reps: "1"
  #   ,
  #     index: "7"
  #     reps: "1"
  #   ,
  #     index: "8"
  #     reps: "1"
  #   ,
  #     index: "9"
  #     reps: "1"
  #   ,
  #     index: "10"
  #     reps: "1"
  #   ,
  #     index: "11"
  #     reps: "1"
  #   ,
  #     index: "12"
  #     reps: "1"
  #   ,
  #     index: "13"
  #     reps: "1"
  #   ,
  #     index: "14"
  #     reps: "1"
  #   ,
  #     index: "15"
  #     reps: "1"
  #   ,
  #     index: "16"
  #     reps: "1"
  #   ,
  #     index: "17"
  #     reps: "1"
  #   ,
  #     index: "18"
  #     reps: "1"
  #   ,
  #     index: "19"
  #     reps: "1"
  #   ,
  #     index: "20"
  #     reps: "1"
  #   ,
  #     index: "21"
  #     reps: "1"
  #   ,
  #     index: "22"
  #     reps: "1"
  #   ,
  #     index: "23"
  #     reps: "1"
  #   ,
  #     index: "24"
  #     reps: "1"
  #   ,
  #     index: "25"
  #     reps: "1"
  #   ,
  #     index: "26"
  #     reps: "1"
  #   ,
  #     index: "27"
  #     reps: "1"
  #   ,
  #     index: "28"
  #     reps: "1"
  #   ,
  #     index: "29"
  #     reps: "1"
  #   ,
  #     index: "30"
  #     reps: "1"
  #   ,
  #     index: "31"
  #     reps: "1"
  #   ,
  #     index: "32"
  #     reps: "1"
  #   ,
  #     index: "33"
  #     reps: "1"
  #   ,
  #     index: "34"
  #     reps: "1"
  #   ,
  #     index: "35"
  #     reps: "1"
  #   ,
  #     index: "36"
  #     reps: "1"
  #   ,
  #     index: "37"
  #     reps: "1"
  #   ,
  #     index: "38"
  #     reps: "1"
  #   ,
  #     index: "39"
  #     reps: "1"
  #   ,
  #     index: "40"
  #     reps: "1"
  #   ,
  #     index: "41"
  #     reps: "1"
  #   ,
  #     index: "42"
  #     reps: "1"
  #   ,
  #     index: "43"
  #     reps: "1"
  #   ,
  #     index: "44"
  #     reps: "1"
  #   ,
  #     index: "45"
  #     reps: "1"
  #   ,
  #     index: "46"
  #     reps: "1"
  #   ,
  #     index: "47"
  #     reps: "1"
  #   ,
  #     index: "48"
  #     reps: "1"
  #   ,
  #     index: "49"
  #     reps: "1"
  #   ,
  #     index: "50"
  #     reps: "1"
  #   ,
  #     index: "51"
  #     reps: "1"
  #   ,
  #     index: "52"
  #     reps: "1"
  #   ,
  #     index: "53"
  #     reps: "1"
  #   ,
  #     index: "54"
  #     reps: "1"
  #   ]

  # "internal.smiley61":
  #   text: "friend"
  #   width: "170"
  #   height: "76"
  #   files: ["sml050-01", "sml050-02", "sml050-03", "sml050-04", "sml050-05", "sml050-06", "sml050-07"]
  #   frames: [
  #     index: "0"
  #     reps: "24"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "5"
  #     reps: "1"
  #   ,
  #     index: "6"
  #     reps: "24"
  #   ]

  # "internal.smiley62":
  #   text: "sigh"
  #   width: "76"
  #   height: "76"
  #   files: ["sml051-01", "sml051-02", "sml051-03", "sml051-04", "sml051-05"]
  #   frames: [
  #     index: "0"
  #     reps: "24"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "8"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "4"
  #     reps: "24"
  #   ]

  # "internal.smiley63":
  #   text: "applaud"
  #   style: "internal.smiley29"

  # "internal.smiley64":
  #   text: "It's a trap"
  #   width: "212"
  #   height: "76"
  #   files: ["sml052"]
  #   frames: [
  #     index: "0"
  #     reps: "76"
  #   ]

  # "internal.smiley65":
  #   text: "rofl"
  #   reps: "1"
  #   width: "100"
  #   height: "76"
  #   files: ["sml053-01", "sml053-02", "sml053-03", "sml053-04", "sml053-05", "sml053-06", "sml053-07", "sml053-08", "sml053-09", "sml053-10", "sml053-11", "sml053-12", "sml053-13", "sml053-14", "sml053-15", "sml053-16", "sml053-17", "sml053-18"]
  #   frames: [
  #     index: "0"
  #     reps: "4"
  #   ,
  #     index: "1"
  #     reps: "4"
  #   ,
  #     index: "2"
  #     reps: "4"
  #   ,
  #     index: "3"
  #     reps: "4"
  #   ,
  #     index: "4"
  #     reps: "4"
  #   ,
  #     index: "5"
  #     reps: "4"
  #   ,
  #     index: "6"
  #     reps: "4"
  #   ,
  #     index: "7"
  #     reps: "4"
  #   ,
  #     index: "8"
  #     reps: "4"
  #   ,
  #     index: "9"
  #     reps: "4"
  #   ,
  #     index: "10"
  #     reps: "4"
  #   ,
  #     index: "11"
  #     reps: "4"
  #   ,
  #     index: "12"
  #     reps: "4"
  #   ,
  #     index: "13"
  #     reps: "4"
  #   ,
  #     index: "14"
  #     reps: "4"
  #   ,
  #     index: "15"
  #     reps: "4"
  #   ,
  #     index: "16"
  #     reps: "4"
  #   ,
  #     index: "17"
  #     reps: "4"
  #   ]

  # "internal.smiley66":
  #   text: "donk"
  #   reps: "5"
  #   width: "212"
  #   height: "76"
  #   files: ["sml054-01", "sml054-02", "sml054-03", "sml054-04", "sml054-05", "sml054-06", "sml054-07", "sml054-08"]
  #   frames: [
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "5"
  #     reps: "1"
  #   ,
  #     index: "6"
  #     reps: "1"
  #   ,
  #     index: "7"
  #     reps: "1"
  #   ]

  # "internal.smiley67":
  #   text: "hypnocall"
  #   reps: "5"
  #   width: "212"
  #   height: "76"
  #   files: ["sml055-01", "sml055-02", "sml055-03", "sml055-04", "sml055-05", "sml055-06"]
  #   frames: [
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "5"
  #     reps: "1"
  #   ]

  # "internal.smiley68":
  #   text: "hypnofold"
  #   reps: "5"
  #   width: "212"
  #   height: "76"
  #   files: ["sml056-01", "sml056-02", "sml056-03", "sml056-04", "sml056-05", "sml056-06"]
  #   frames: [
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "5"
  #     reps: "1"
  #   ]

  # "internal.smiley69":
  #   text: "unsubscribe"
  #   width: "212"
  #   height: "76"
  #   files: ["sml057-01", "sml057-02", "sml057-03", "sml057-04"]
  #   frames: [
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "50"
  #   ]

  # "internal.smiley70":
  #   text: "devil"
  #   width: "212"
  #   height: "76"
  #   files: ["sml058-01", "sml058-02", "sml058-03"]
  #   frames: [
  #     index: "0"
  #     reps: "36"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "48"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "0"
  #     reps: "12"
  #   ]

  # "internal.smiley71":
  #   text: "this is poker"
  #   width: "215"
  #   height: "76"
  #   files: ["sml059-01", "sml059-02", "sml059-03", "sml059-04", "sml059-05", "sml059-06"]
  #   frames: [
  #     index: "0"
  #     reps: "14"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "10"
  #   ,
  #     index: "3"
  #     reps: "15"
  #   ,
  #     index: "4"
  #     reps: "15"
  #   ,
  #     index: "5"
  #     reps: "15"
  #   ]

  # "internal.smiley72":
  #   text: "this is poker!"
  #   style: "internal.smiley71"

  # "internal.smiley73":
  #   text: "thisispoker"
  #   style: "internal.smiley71"

  # "internal.smiley74":
  #   text: "its a trap"
  #   style: "internal.smiley64"

  # "internal.smiley75":
  #   text: "fuuu"
  #   width: "127"
  #   height: "76"
  #   files: ["sml060-01", "sml060-02"]
  #   frames: [
  #     index: "0"
  #     reps: "20"
  #   ,
  #     index: "1"
  #     reps: "80"
  #   ]

  # "internal.smiley76":
  #   text: "fuuuu"
  #   style: "internal.smiley75"

  # "internal.smiley77":
  #   text: "fuuuuu"
  #   style: "internal.smiley75"

  # "internal.smiley78":
  #   text: "fuuuuuu"
  #   style: "internal.smiley75"

  # "internal.smiley79":
  #   text: "fuuuuuuu"
  #   style: "internal.smiley75"

  # "internal.smiley80":
  #   text: "fuuuuuuuu"
  #   style: "internal.smiley75"

  # "internal.smiley81":
  #   text: "fuuuuuuuuu"
  #   style: "internal.smiley75"

  # "internal.smiley82":
  #   text: "fuuuuuuuuuu"
  #   style: "internal.smiley75"

  # "internal.smiley83":
  #   text: "fuuuuuuuuuuu"
  #   style: "internal.smiley75"

  # "internal.smiley84":
  #   text: "fuuuuuuuuuuuu"
  #   style: "internal.smiley75"

  # "internal.smiley85":
  #   text: "popcorn"
  #   width: "120"
  #   height: "76"
  #   files: ["sml061-01", "sml061-02", "sml061-03", "sml061-04", "sml061-05", "sml061-06", "sml061-07", "sml061-08", "sml061-09", "sml061-10", "sml061-11", "sml061-12"]
  #   frames: [
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "2"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "4"
  #     reps: "2"
  #   ,
  #     index: "5"
  #     reps: "1"
  #   ,
  #     index: "6"
  #     reps: "8"
  #   ,
  #     index: "7"
  #     reps: "4"
  #   ,
  #     index: "8"
  #     reps: "2"
  #   ,
  #     index: "9"
  #     reps: "1"
  #   ,
  #     index: "10"
  #     reps: "2"
  #   ,
  #     index: "11"
  #     reps: "2"
  #   ,
  #     index: "8"
  #     reps: "1"
  #   ,
  #     index: "9"
  #     reps: "1"
  #   ,
  #     index: "10"
  #     reps: "1"
  #   ,
  #     index: "11"
  #     reps: "2"
  #   ,
  #     index: "8"
  #     reps: "1"
  #   ,
  #     index: "9"
  #     reps: "2"
  #   ,
  #     index: "10"
  #     reps: "1"
  #   ,
  #     index: "11"
  #     reps: "1"
  #   ,
  #     index: "8"
  #     reps: "2"
  #   ,
  #     index: "9"
  #     reps: "1"
  #   ,
  #     index: "10"
  #     reps: "2"
  #   ,
  #     index: "11"
  #     reps: "2"
  #   ,
  #     index: "1"
  #     reps: "20"
  #   ]

  # "internal.smiley86":
  #   text: "troll"
  #   width: "77"
  #   height: "76"
  #   files: ["sml062-01", "sml062-02", "sml062-03", "sml062-04", "sml062-05"]
  #   frames: [
  #     index: "0"
  #     reps: "5"
  #   ,
  #     index: "1"
  #     reps: "1"
  #   ,
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "2"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "50"
  #   ]

  # "internal.smiley87":
  #   text: "u mad"
  #   style: "internal.smiley86"

  # "internal.smiley88":
  #   text: "u mad bro"
  #   style: "internal.smiley86"

  # "internal.smiley89":
  #   text: "u mad bro?"
  #   style: "internal.smiley86"

  # "internal.smiley90":
  #   text: "umad"
  #   style: "internal.smiley86"

  # "internal.smiley91":
  #   text: "umadbro"
  #   style: "internal.smiley86"

  # "internal.smiley92":
  #   text: "umadbro?"
  #   style: "internal.smiley86"

  # "internal.smiley93":
  #   text: "haters"
  #   width: "172"
  #   height: "76"
  #   files: ["sml063-01", "sml063-02", "sml063-03", "sml063-04", "sml063-05"]
  #   frames: [
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "0"
  #     reps: "1"
  #   ]

  # "internal.smiley94":
  #   text: "hater"
  #   style: "internal.smiley93"

  # "internal.smiley95":
  #   text: "haters gonna hate"
  #   style: "internal.smiley93"

  # "internal.smiley96":
  #   text: "hatersgonnahate"
  #   style: "internal.smiley93"

  # "internal.smiley97":
  #   text: "rage"
  #   style: "internal.smiley75"

  # "internal.smiley98":
  #   text: "stayin alive"
  #   width: "215"
  #   height: "76"
  #   files: ["sml064-01", "sml064-02", "sml064-03", "sml064-04", "sml064-05"]
  #   frames: [
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "0"
  #     reps: "1"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "4"
  #     reps: "1"
  #   ,
  #     index: "3"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "3"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "0"
  #     reps: "1"
  #   ]

  # "internal.smiley99":
  #   text: "bling"
  #   width: "130"
  #   height: "76"
  #   files: ["sml065-01", "sml065-02", "sml065-03"]
  #   frames: [
  #     index: "0"
  #     reps: "2"
  #   ,
  #     index: "1"
  #     reps: "2"
  #   ,
  #     index: "2"
  #     reps: "50"
  #   ]

  # "internal.smiley100":
  #   text: "staying alive"
  #   style: "internal.smiley98"

  # "internal.smiley101":
  #   text: "saturday night fever"
  #   style: "internal.smiley98"

  # "internal.smiley102":
  #   text: "baller"
  #   style: "internal.smiley99"

  # "internal.smiley103":
  #   text: "gangsta"
  #   style: "internal.smiley99"

  # "internal.smiley104":
  #   text: "come at me bro"
  #   width: "215"
  #   height: "76"
  #   files: ["sml066-01", "sml066-02"]
  #   frames: [
  #     index: "0"
  #     reps: "10"
  #   ,
  #     index: "1"
  #     reps: "50"
  #   ]

  # "internal.smiley105":
  #   text: "come at me bro!"
  #   style: "internal.smiley104"

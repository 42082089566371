import Request from './request.coffee'

export default class GetPlayMoney extends Request
  constructor: ({message}) ->
    super arguments...

    @function = 'GET_PLAY_MONEY'

    @attributes =
      type: 'REQUEST'
      service: 'CREDIT'
      routerId: message.routerId
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

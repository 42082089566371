import Request from './request.coffee'

export default class GetTournamentParentsMessage extends Request
  constructor: ({message, casinoId, listing}) ->
    super arguments...

    @function = 'GET_TOURNAMENT_PARENTS'

    @attributes =
      type: 'REQUEST'
      service: 'GAMELIST'
      routerId: message.routerId
      serverId: 0
      instanceId: 0
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0

    @arguments =
      casino_id: casinoId
      class_id: message.classId
      game_instance: listing.instanceId

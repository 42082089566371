import React from 'react'

import DesktopListing from './DesktopListing.js'
import MobileListing from './MobileListing.js'
import {MOBILE} from '../../device/constants.js'
import {useFormat as useDeviceFormat} from '../../device/react-hooks.js'

export default function Listing () {
  const deviceFormat = useDeviceFormat()

  return deviceFormat === MOBILE ? <MobileListing /> : <DesktopListing />
}

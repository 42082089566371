# HandHistoryView
# Model: game

import groupBy from 'lodash/groupBy'

import View from '../view.coffee'

export default class HandHistoryView extends View

  constructor: (game) ->
    super()

    @model = game
　
    @dom = $ '<div>'

    # ### Components - eg. DOM elements & views
    @components =
      toggleDisplay: $ '<span class="link copy-hand-history-link">Copy History</span>'
      gameList:    $ '<div class="hand-history-game-list">'
      displayArea: $ '<div class="hand-history-display-area">'
      textArea:    $ '<textarea class="hand-history-text-area">'

    # ### Events - eg. MouseClicks ###
    @components.gameList.on 'click', '.link', @showHand
    @components.toggleDisplay.on 'click', @toggleDisplay

    # ### Listeners - eg. auth:success
    @listeners =
      'refreshHandHistory': @buildList

    # ### Changes - eg. Model.property = true

    # ### Binding ###

    @buildList()

    @initializeView()
    return this

  toggleDisplay: =>
    @components.textArea.toggle()
    @components.displayArea.toggle()
    if @components.textArea.is ':visible'
      @components.textArea.select()
      @components.toggleDisplay.text 'View History'
    else
      @components.toggleDisplay.text 'Copy History'

  buildList: =>
    @components.gameList.empty()
    handHistory = @model.handHistory.filter ({active}) -> active is true
    for item in handHistory
      k = item.gameId
      link = $ "<div class='link'>#{k}</div>"
      link.data 'id', k
      @components.gameList.append link

  showHand: (e) =>
    @components.gameList.find('.active').removeClass 'active'
    link = $(e.target)
    link.addClass 'active'
    {id} = link.data()
    @components.displayArea.empty()
    item = @model.handHistory.find ({gameId}) -> gameId is id
    # Use custom groupBy iteratee to make sure groupBy works with rows that have no 'state' property (TL;DR safe 'undefined')
    history = groupBy item.history, (row) -> return row.state
    for state, items of history
      if state isnt 'undefined'
        @components.displayArea.append '<div>*** ' + state.toUpperCase() + ' ***</div>\n'

      antes = items.filter ({message}) -> message.indexOf(' posts ante ') > 0
      s = antes.map @formatter
      @components.displayArea.append s.join ''

      notAntes = items.filter ({message}) -> message.indexOf(' posts ante ') is -1
      s = notAntes.map @formatter
      @components.displayArea.append s.join ''

    @components.textArea.val @components.displayArea.text()
    @components.textArea.get(0).scrollTop = 0
    @components.displayArea.get(0).scrollTop = 0

  formatter: ({message, format}) ->
    format ?= 'event'
    return "<div class='#{format}'>#{message}</div>\n"

const chipValues = {
  M500: 500000000,
  M100: 100000000,
  M25: 25000000,
  M5: 5000000,
  M1: 1000000,
  K500: 500000,
  K100: 100000,
  K25: 25000,
  K5: 5000,
  K1: 1000,
  D500: 500,
  D100: 100,
  D25: 25,
  D5: 5,
  D1: 1,
  C25: 0.25,
  C5: 0.5,
  C1: 0.01,
}

// Returns the correct chipValue classname for look up in Chips.module.css
export function chipValue (amount) {
  for (const suffix in chipValues) {
    const chip = chipValues[suffix]
    if (amount >= chip) {
      return `chipValue${suffix}`
    }
  }

  return undefined
}

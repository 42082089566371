import {EventEmitter} from 'events'
import {SEND_PING} from '@pipehat/chronicle/constants/command-type'
import {PONG_RECEIVED} from '@pipehat/chronicle/constants/event-type'
import {createTranslate} from '@pipehat/chronicle/translation'
import {configureScope} from '@sentry/browser/esm/index.js'

import {latencyCalculated} from '../../signal/signal-slice.js'

export default class Socket extends EventEmitter
  constructor: (@url, @dispatch) ->
    super()

    unless @url
      throw new Error 'no websocket url supplied'

    @translate = createTranslate()
    @ws = null
    @ended = false
    @lastPingSent = 0

  connect: =>
    try
      unless @ended
        @ws = new WebSocket @url
        @ws.onopen = =>
          @emit 'connect'
          @ws.onmessage = @receive

        @ws.onerror = @error
        @ws.onclose = @end

  send: (message) =>
    unless @ended
      @translateAndDispatch message
      serialized = JSON.stringify(message)

      configureScope (scope) ->
        # sensitive data will be redacted in sentryInit beforeSend()
        scope.setExtra 'lastOutgoingMessage', serialized
        return

      @ws.send serialized

  end: =>
    @ws = null
    @ended = true
    @emit 'disconnect'

  receive: (data) =>
    message = JSON.parse(data.data)
    @translateAndDispatch message

    configureScope (scope) ->
      # sensitive data will be redacted in sentryInit beforeSend()
      scope.setExtra 'lastIncomingMessage', data.data
      return

    @emit 'message', message

  error: (e) =>
    console.error e

  translateAndDispatch: (message) =>
    actions = @translate message

    if actions.length > 0
      for {type, payload} in actions
        if type is SEND_PING
          @lastPingSent = Date.now()
        else if type is PONG_RECEIVED
          @dispatch latencyCalculated latency: Date.now() - @lastPingSent
          @lastPingSent = 0

      @dispatch (dispatch) -> actions.forEach((action) => dispatch action)

import React from 'react'

import MobileRingGameListingRow from './MobileRingGameListingRow.js'
import List from '../../components/game-listing/list/List.js'

export default function MobileRingGameSkeletonList () {
  const rows = []

  const ringGame = {
    gameTypes: [],
    minimumStake: {currencyCode: 'USD', units: '1', nanos: 0},
    maximumStake: {currencyCode: 'USD', units: '1000', nanos: 0},
    name: 'Sample Ring Game',
    seatCount: 6,
    seatedCount: 1,
  }

  for (let i = 0; i < 10; i++) {
    rows.push(<MobileRingGameListingRow key={i} ringGame={ringGame} />)
  }

  return <List isSkeleton>{rows.map(row => row)}</List>
}

import React, {useState, useCallback} from 'react'
import {useRouteNode, useRouter} from 'react-router5'
import {startsWithSegment} from 'router5-helpers'

import LobbyDropdownMenuItem from './LobbyDropdownMenuItem.js'
import styles from './MobileLobbyMenu.module.css'
import TabButton from './TabButton.js'
import {GAME_LIST, RING_GAMES, TOURNAMENTS} from '../../routes.js'

export default function MobileLobbyMenu () {
  const router = useRouter()
  const {route} = useRouteNode('')
  const testRoute = startsWithSegment(route)
  const [isOpen, setMenuIsOpen] = useState(false)
  const isActive = testRoute(GAME_LIST) || testRoute(RING_GAMES) || testRoute(TOURNAMENTS)

  const handleToggle = useCallback(() => {
    setMenuIsOpen(!isOpen)
  }, [isOpen, setMenuIsOpen])

  const handleClickCashGames = useCallback(() => {
    setMenuIsOpen(!isOpen)
    router.navigate(GAME_LIST, {s1: 'ringGames', s2: 'nlHoldem'})
  }, [isOpen, router, setMenuIsOpen])

  const handleClickSng = useCallback(() => {
    setMenuIsOpen(!isOpen)
    router.navigate(GAME_LIST, {s1: 'sitngo', s2: 'all'})
  }, [isOpen, router, setMenuIsOpen])

  const handleClickTournament = useCallback(() => {
    setMenuIsOpen(!isOpen)
    router.navigate(GAME_LIST, {s1: 'tournaments', s2: 'holdem'})
  }, [isOpen, router, setMenuIsOpen])

  const handleClickMyGame = useCallback(() => {
    setMenuIsOpen(!isOpen)
    router.navigate(RING_GAMES)
  }, [isOpen, router, setMenuIsOpen])

  const handleClickMyTournament = useCallback(() => {
    setMenuIsOpen(!isOpen)
    router.navigate(TOURNAMENTS)
  }, [isOpen, router, setMenuIsOpen])

  const dropdownList = (
    <div className={styles.gameTypeDropdown}>
      <LobbyDropdownMenuItem onClick={handleClickCashGames} label='Cash Games' />
      <LobbyDropdownMenuItem onClick={handleClickSng} label='Sit & Go' />
      <LobbyDropdownMenuItem onClick={handleClickTournament} label='Tournaments' />
      <LobbyDropdownMenuItem onClick={handleClickMyGame} label='My Games' />
      <LobbyDropdownMenuItem onClick={handleClickMyTournament} label='My Tournaments' />
    </div>
  )

  return <>
    <TabButton
      icon='fa-list-alt'
      dropdown
      label='Lobby'
      isActive={isActive}
      onClick={handleToggle}
    />
    {isOpen && dropdownList}
  </>
}

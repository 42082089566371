import {subscribe} from '@codeworx/reductive'
import {SEND_PING} from '@pipehat/chronicle/constants/command-type'
import {PONG_RECEIVED, SERVER_TIME_LOADED} from '@pipehat/chronicle/constants/event-type'
import {configureStore} from '@reduxjs/toolkit'
import {useSelector} from 'react-redux'
import thunk from 'redux-thunk'

import reducer from './reducer.js'
import {latencyCalculated} from './signal/signal-slice.js'
import subscriptions from './subscriptions.js'

export function createStore (container) {
  const {logger} = container

  const store = configureStore({
    reducer,
    middleware: [
      thunk.withExtraArgument(container),
    ],
    devTools: {
      maxAge: 100,
      predicate: (_, action) => {
        if (logger.topics.pingpongs) return true

        const {type} = action

        switch (type) {
          case latencyCalculated.type:
          case PONG_RECEIVED:
          case SEND_PING:
          case SERVER_TIME_LOADED:
            return false
        }

        return true
      },
    },
  })
  subscribe(store, subscriptions, container)

  return store
}

export function useRootedSelector (getRoot, selector, props) {
  return useSelector(state => selector(getRoot(state), props))
}

export default ({signal, app, game, client, listing}) ->
  return unless game

  gameName = listing?.gameName or game.gameName
  reason = signal.failReason
  displayNotification = true

  if reason is 'shutdown' and !listing.isTournament
    msg = "Game #{gameName} has been shutdown by an administrator.<br>Your table balance has been returned."
  else if reason is 'shutdown'
    msg = "Game #{gameName} has been shutdown by an administrator."
  else if reason is 'inactive'
    msg = "Game #{gameName} has been shutdown by an administrator due to inactivity."
  else if reason is 'stopped'
    msg = "Game #{gameName} has been shutdown as it is no longer in use."
  else if reason is 'endOfGame'
    msg = "Game #{gameName} has been shutdown as it is no longer in use."
    if listing?.isTournament
      displayNotification = false
  else if listing.isTournament
    msg = "Game #{gameName} has experienced a major problem and has been shutdown."
  else
    msg = "Game #{gameName} has experienced a major problem and has been shutdown.<br>Any placed bets for the game have been refunded and your table balance returned."

  if displayNotification
    app.notice
      title: 'Game Instance Shutdown'
      content: msg

  app.leaveGame game, client

  clientList = app.clients.toArray()
  if clientList.length > 0
    app.joinGame {
      serverId: clientList[0].serverId
      instanceId: clientList[0].instanceId
    }
  else
    Navigator.navLobby()

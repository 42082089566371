import React, {useEffect} from 'react'
import {useRouteNode, useRouter} from 'react-router5'
import {startsWithSegment} from 'router5-helpers'

import AdBar from './AdBar.js'
import styles from './DesktopLobby.module.css'
import DesktopToolbar from './DesktopToolbar.js'
import GameListScreen from '../../game-list/screens/GameList.js'
import Header from '../../header/components/Header.js'
import DesktopMenu from '../../menu/components/DesktopMenu.js'
import {useIsOpen} from '../../menu/react-hooks.js'
import OwnRingGamesScreen from '../../own-games/screens/OwnRingGames.js'
import OwnTournamentsScreen from '../../own-games/screens/OwnTournaments.js'
import QuickSeatScreen from '../../quick-seat/screens/QuickSeat.js'
import ResponsibleGaming from '../../responsible-gaming/screens/ResponsibleGaming.js'

import {
  FAQ,
  LOBBY,
  POKER_RULES,
  QUICK_SEAT,
  RESPONSIBLE_GAMING,
  RING_GAMES,
  SELECTOR_LIST,
  SETTINGS,
  SUPPORT,
  TABLE,
  TOURNAMENT,
  TOURNAMENTS,
} from '../../routes.js'

import SettingsScreen from '../../settings/screens/Settings.js'
import FaqScreen from '../../support/screens/Faq.js'
import PokerRulesScreen from '../../support/screens/PokerRules.js'
import SupportScreen from '../../support/screens/Support.js'
import TournamentLobby from '../../tournament/components/TournamentLobby.js'

const availableRoute = [
  FAQ,
  LOBBY,
  POKER_RULES,
  QUICK_SEAT,
  RESPONSIBLE_GAMING,
  RING_GAMES,
  SELECTOR_LIST,
  SETTINGS,
  SUPPORT,
  TABLE,
  TOURNAMENT,
  TOURNAMENTS,
]

export default function DesktopLobby (props) {
  const {isElectron, isPrivileged, appName} = props
  const isMenuOpen = useIsOpen()
  const router = useRouter()
  const {route} = useRouteNode('')
  const testRoute = startsWithSegment(route)

  const {name: routeName, params: {tournamentId}} = router.getState() || {}

  useEffect(() => {
    if (!availableRoute.includes(routeName)) {
      router.navigate(QUICK_SEAT)
    }
  }, [routeName, router])

  const shouldShowGameList = routeName === SELECTOR_LIST
  const shouldShowQuickSeat = routeName === QUICK_SEAT
  const shouldShowOwnTournaments = testRoute(TOURNAMENTS)

  return <>
    <Header />
    {isMenuOpen && <DesktopMenu />}
    <AdBar />
    <div className={styles.lobbyMain}>
      <DesktopToolbar />
      {testRoute(SETTINGS) && <SettingsScreen isElectron={isElectron} isPrivileged={isPrivileged} />}
      {testRoute(SUPPORT) && <SupportScreen />}
      {testRoute(FAQ) && <FaqScreen site={appName} />}
      {shouldShowGameList && <GameListScreen />}
      {shouldShowQuickSeat && <QuickSeatScreen />}
      {testRoute(RESPONSIBLE_GAMING) && <ResponsibleGaming />}
      {testRoute(RING_GAMES) && <OwnRingGamesScreen />}
      {shouldShowOwnTournaments && <OwnTournamentsScreen />}
      {testRoute(TOURNAMENT) && tournamentId && <TournamentLobby tournamentId={parseInt(tournamentId, 10)} />}
      {testRoute(POKER_RULES) && <PokerRulesScreen />}
    </div>
  </>
}

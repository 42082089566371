import {HYPER_TURBO, TURBO} from '@pipehat/chronicle/constants/game-category'
import {createSelector} from '@reduxjs/toolkit'

import {LIST_TYPE_MAP, STAKES_RANGE_FILTER_MAP, STATUS_FILTER} from './constants.js'
import {isTypeSequenceSupported} from '../../game-type.js'
import {ALL, REGULAR} from '../constants.js'
import {getCriteriaProp, getOwnTournaments, getPinnedProp, getSortProp, getTournaments} from '../index.js'
import {sortTournaments} from '../sorting.js'

export function createGetListing () {
  return createSelector(
    [
      getOwnTournaments,
      getPinnedProp,
      getTournaments,
      getCriteriaProp,
      getSortProp,
    ],
    (ownTournaments, pinned, tournaments, criteria, sort) => {
      const {seatCountRange, speed, stakesRange} = criteria
      const listTypes = LIST_TYPE_MAP[seatCountRange]

      const isWithinStakesRange = STAKES_RANGE_FILTER_MAP[stakesRange]
      const entries = {}

      for (const [listType] of listTypes) {
        const listTypeTournaments = tournaments[listType]

        if (!listTypeTournaments) continue

        Object.assign(entries, Object.fromEntries(
          Object.entries(listTypeTournaments)
            .filter(([tournamentId, tournament]) => {
              const {categories, entryCost, gameTypes, maximumRegistrants, registrantCount, status} = tournament

              if (!ownTournaments[tournamentId] && registrantCount >= maximumRegistrants) return false
              if (!STATUS_FILTER.includes(status) || !isTypeSequenceSupported(gameTypes)) return false

              if (categories.includes(TURBO)) {
                if (speed === REGULAR || speed === HYPER_TURBO) return false
              } else if (categories.includes(HYPER_TURBO)) {
                if (speed === REGULAR || speed === TURBO) return false
              } else {
                if (speed === TURBO || speed === HYPER_TURBO) return false
              }

              if (!entryCost && stakesRange !== ALL) return false

              return isWithinStakesRange(entryCost)
            })
            .map(([tournamentId, tournament]) => {
              return [
                tournamentId,
                {
                  ...tournament,
                  isOwn: Boolean(ownTournaments[tournamentId]),
                  isPinned: Boolean(pinned[tournamentId]),
                },
              ]
            }),
        ))
      }

      return sortTournaments(sort, entries)
    },
  )
}

export function createIsLoaded () {
  return createSelector(
    [
      getTournaments,
      getCriteriaProp,
    ],
    (tournaments, criteria) => {
      const {seatCountRange} = criteria
      const listTypes = LIST_TYPE_MAP[seatCountRange]

      return listTypes.every(([listType]) => tournaments[listType])
    },
  )
}

import classnames from 'classnames'
import React, {useCallback} from 'react'

import styles from './ExclusionsForm.module.css'
import {useRootedSelector} from '../../redux.js'
import {canUpdate as canUpdateSelector, getRoot} from '../index.js'
import {useCreateExclusions} from '../react-hooks.js'

export default function ExclusionsForm () {
  const canUpdate = useRootedSelector(getRoot, canUpdateSelector)
  const createExclusions = useCreateExclusions()

  const handleSubmit = useCallback(event => {
    event.preventDefault()
    if (!canUpdate) return

    const data = new FormData(event.target)
    const period = parseInt(data.get('period'), 10)

    createExclusions({USD: period})
  }, [canUpdate, createExclusions])

  const buttonClass = classnames('button', {
    'button-disabled': !canUpdate,
  })

  return <form className={styles.form} onSubmit={handleSubmit}>
    <label htmlFor='responsible-gaming-period'>
      By clicking the submit button below, I voluntarily exclude myself from
      playing all real money poker games for a period of:
    </label>

    <select id='responsible-gaming-period' name='period' disabled={!canUpdate}>
      <option value='12'>12 hours</option>
      <option value='24'>1 day</option>
      <option value='168'>7 days</option>
      <option value='720'>30 days</option>
      <option value='2160'>90 days</option>
    </select>

    <button type='submit' disabled={!canUpdate} className={buttonClass}>Submit</button>
  </form>
}

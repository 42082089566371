import {USD} from '@pipehat/chronicle/constants/currency'

import {
  OMAHA_HOLDEM as OMAHA_HOLDEM_LIST_TYPE,
  OMAHA_HOLDEM_HILO as OMAHA_HOLDEM_HILO_LIST_TYPE,
  TEXAS_HOLDEM_FIXED_LIMIT,
  TEXAS_HOLDEM_NO_LIMIT,
} from '@pipehat/chronicle/constants/game-list-type'

import {OMAHA_HOLDEM, OMAHA_HOLDEM_HILO, TEXAS_HOLDEM} from '@pipehat/chronicle/constants/game-variant'
import {FIXED_LIMIT, POT_LIMIT, NO_LIMIT} from '@pipehat/chronicle/constants/limit-type'
import {satisfiesAll} from '@pipehat/money'
import {createByDenominator, isLessThanOrEqual, isGreaterThan} from '@pipehat/money/plain-object'

import listTypeEnum from '../../legacy/enum/enums/listTypeEnum.coffee'
import {RING_GAME_SUB_ROUTE} from '../../routes.js'
import {ALL, ALL_STAKES_RANGES, STAKES_RANGE} from '../constants.js'

export const RING_GAME_FILTER = {
  FIXED_LIMIT_TEXAS_HOLDEM: 'FIXED_LIMIT_TEXAS_HOLDEM',
  FIXED_LIMIT_OMAHA_HOLDEM_HILO: 'FIXED_LIMIT_OMAHA_HOLDEM_HILO',
  NO_LIMIT_TEXAS_HOLDEM: 'NO_LIMIT_TEXAS_HOLDEM',
  POT_LIMIT_OMAHA_HOLDEM: 'POT_LIMIT_OMAHA_HOLDEM',
}

export const RING_GAME_FILTER_MAP = {
  [RING_GAME_SUB_ROUTE.NL_HOLDEM]: RING_GAME_FILTER.NO_LIMIT_TEXAS_HOLDEM,
  [RING_GAME_SUB_ROUTE.FL_HOLDEM]: RING_GAME_FILTER.FIXED_LIMIT_TEXAS_HOLDEM,
  [RING_GAME_SUB_ROUTE.PL_OMAHA]: RING_GAME_FILTER.POT_LIMIT_OMAHA_HOLDEM,
  [RING_GAME_SUB_ROUTE.FL_OMAHA_HILO]: RING_GAME_FILTER.FIXED_LIMIT_OMAHA_HOLDEM_HILO,
}

export const RING_GAME_FILTER_CRITERIA = {
  [RING_GAME_FILTER.FIXED_LIMIT_TEXAS_HOLDEM]: {
    limitType: FIXED_LIMIT,
    variant: TEXAS_HOLDEM,
  },
  [RING_GAME_FILTER.FIXED_LIMIT_OMAHA_HOLDEM_HILO]: {
    limitType: FIXED_LIMIT,
    variant: OMAHA_HOLDEM_HILO,
  },
  [RING_GAME_FILTER.NO_LIMIT_TEXAS_HOLDEM]: {
    limitType: NO_LIMIT,
    variant: TEXAS_HOLDEM,
  },
  [RING_GAME_FILTER.POT_LIMIT_OMAHA_HOLDEM]: {
    limitType: POT_LIMIT,
    variant: OMAHA_HOLDEM,
  },
}

export const VARIANT = {
  OMAHA_HOLDEM,
  OMAHA_HOLDEM_HILO,
  TEXAS_HOLDEM,
}

export const LIMIT_TYPE_MAP = {
  [OMAHA_HOLDEM]: [POT_LIMIT],
  [OMAHA_HOLDEM_HILO]: [FIXED_LIMIT],
  [TEXAS_HOLDEM]: [NO_LIMIT, FIXED_LIMIT],
}

// the first stakes range is the default, so order is important here
export const STAKES_RANGE_MAP = {
  [OMAHA_HOLDEM]: ALL_STAKES_RANGES,
  [OMAHA_HOLDEM_HILO]: [ALL, STAKES_RANGE.LOW, STAKES_RANGE.MEDIUM, STAKES_RANGE.HIGH],
  [TEXAS_HOLDEM]: ALL_STAKES_RANGES,
}

const createFromUsCents = createByDenominator(USD, 100n)
const createFromUsDollars = createByDenominator(USD, 1n)

const USD_25_CENTS = createFromUsCents(25n)
const USD_50_CENTS = createFromUsCents(50n)

const USD_1 = createFromUsDollars(1n)
const USD_4 = createFromUsDollars(4n)
const USD_6 = createFromUsDollars(6n)
const USD_20 = createFromUsDollars(20n)

const STAKES_RANGE_FILTER_MAP_FIXED_LIMIT = {
  [ALL]: isAny,
  [STAKES_RANGE.MICRO]: isLessThanOrEqual(USD_50_CENTS),
  [STAKES_RANGE.LOW]: satisfiesAll([isGreaterThan(USD_50_CENTS), isLessThanOrEqual(USD_6)]),
  [STAKES_RANGE.MEDIUM]: satisfiesAll([isGreaterThan(USD_6), isLessThanOrEqual(USD_20)]),
  [STAKES_RANGE.HIGH]: isGreaterThan(USD_20),
}

const STAKES_RANGE_FILTER_MAP_NON_FIXED_LIMIT = {
  [ALL]: isAny,
  [STAKES_RANGE.MICRO]: isLessThanOrEqual(USD_25_CENTS),
  [STAKES_RANGE.LOW]: satisfiesAll([isGreaterThan(USD_25_CENTS), isLessThanOrEqual(USD_1)]),
  [STAKES_RANGE.MEDIUM]: satisfiesAll([isGreaterThan(USD_1), isLessThanOrEqual(USD_4)]),
  [STAKES_RANGE.HIGH]: isGreaterThan(USD_4),
}

export const STAKES_RANGE_FILTER_MAP = {
  [FIXED_LIMIT]: STAKES_RANGE_FILTER_MAP_FIXED_LIMIT,
  [NO_LIMIT]: STAKES_RANGE_FILTER_MAP_NON_FIXED_LIMIT,
  [POT_LIMIT]: STAKES_RANGE_FILTER_MAP_NON_FIXED_LIMIT,
}

export const LIST_TYPE_MAP = {
  [OMAHA_HOLDEM]: {
    [POT_LIMIT]: [OMAHA_HOLDEM_LIST_TYPE, listTypeEnum.OMAHA.description],
  },
  [OMAHA_HOLDEM_HILO]: {
    [FIXED_LIMIT]: [OMAHA_HOLDEM_HILO_LIST_TYPE, listTypeEnum.OMAHA_HILO.description],
  },
  [TEXAS_HOLDEM]: {
    [FIXED_LIMIT]: [TEXAS_HOLDEM_FIXED_LIMIT, listTypeEnum.TEXAS_HOLDEM_FIXED_LIMIT.description],
    [NO_LIMIT]: [TEXAS_HOLDEM_NO_LIMIT, listTypeEnum.TEXAS_HOLDEM_NO_LIMIT.description],
  },
}

function isAny () {
  return true
}

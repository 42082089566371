export default ({signal, app, game, client, listing}) ->

  app.leaveGame game, client

  if listing?.isTournament
    listing.registered = false
    # i.e. Shutdown, Finished, etc
    unless listing.gameState in ['In Progress', 'Late Registration']
      listing.playing = false
  else
    listing?.playing = false
    listing?.pinned = false

# PokerEvent
#   String code
#   BigDecimal currentBet
#   int position
#   BigDecimal balance
#   BigDecimal betAmount
#   BigDecimal contribution
#   String location
#   String nickname
#   String[] cards
#   int numCards
#   String handDescription
#   String waitingStatus
#   String playerStatus
#   String gameStatus
#   boolean morePlayersRequired
#   boolean foldTimeout
#   boolean operationSuccess
#   boolean response
#   boolean promotionalPlayer
import Mixmaster from '../../../mixmaster.coffee'
import WclEnum from '../../../../enum/index.coffee'

import CurrentBetData from './currentBetData.coffee'

export default class PokerEventData extends Mixmaster
  mixins = [
    CurrentBetData
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  mixin: (object) ->
    mixins.map((mixin) => (new mixin).mixin(object))

    object.event = (signal, name, values) ->
      tr = {}
      tr.eventName = WclEnum.getEventName values
      for key, value of values
        enumObj = WclEnum.getEventValue(key, value, tr.eventName)
        if enumObj?
          tr[enumObj.key] = enumObj.value

      signal.updates.event = tr

import uniq from 'lodash/uniq'

import eventMap from '../utils/eventMap.coffee'

import balanceUpdatedEvent from '../events/balanceUpdatedEvent.coffee'
import bountyPrizeEvent from '../events/bountyPrizeEvent.coffee'
import chatBoxNotificationEvent from '../events/chatBoxNotificationEvent.coffee'
import depositEvent from '../events/depositEvent.coffee'
import foldEvent from '../events/foldEvent.coffee'
import friendDeleteEvent from '../events/friendDeleteEvent.coffee'
import friendRequestEvent from '../events/friendRequestEvent.coffee'
import joinGameEvent from '../events/joinGameEvent.coffee'
import leaveGameEvent from '../events/leaveGameEvent.coffee'
import muckEvent from '../events/muckEvent.coffee'
import offerTournamentEvent from '../events/offerTournamentEvent.coffee'
import ownBountyPrizeEvent from '../events/ownBountyPrizeEvent.coffee'
import playerNotification from '../events/playerNotification.coffee'
import rabbitHuntEvent from '../events/rabbitHuntEvent.coffee'
import reserveEvent from '../events/reserveEvent.coffee'
import returnEvent from '../events/returnEvent.coffee'
import showCardsEvent from '../events/showCardsEvent.coffee'
import sitOutEvent from '../events/sitOutEvent.coffee'
import standUpEvent from '../events/standUpEvent.coffee'
import tableNotificationClosableEvent from '../events/tableNotificationClosableEvent.coffee'
import tableNotificationEvent from '../events/tableNotificationEvent.coffee'
import tournamentPositionEvent from '../events/tournamentPositionEvent.coffee'
import tournamentPositionMoneyEvent from '../events/tournamentPositionMoneyEvent.coffee'
import tournamentRematchFailureEvent from '../events/tournamentRematchFailureEvent.coffee'
import tournamentShutdownEvent from '../events/tournamentShutdownEvent.coffee'
import tournamentShutdownInsufficientPlayers from '../events/tournamentShutdownInsufficientPlayers.coffee'
import transferInEvent from '../events/transferInEvent.coffee'
import transferOutEvent from '../events/transferOutEvent.coffee'
import unreserveEvent from '../events/unreserveEvent.coffee'
import updatePlayerEvent from '../events/updatePlayerEvent.coffee'
import userFriendEvent from '../events/userFriendEvent.coffee'
import Card from '../../../models/card.coffee'

Events =
  deposit:                   depositEvent
  fold:                      foldEvent
  joinGame:                  joinGameEvent
  leaveGame:                 leaveGameEvent
  muckCards:                 muckEvent
  rabbitHunt:                rabbitHuntEvent
  reserve:                   reserveEvent
  returned:                  returnEvent
  showCards:                 showCardsEvent
  sittingOut:                sitOutEvent
  standUp:                   standUpEvent
  transferIn:                transferInEvent
  transferOut:               transferOutEvent
  unreserve:                 unreserveEvent
  updatePlayer:              updatePlayerEvent

NonGameEvents =
  balanceUpdated:            balanceUpdatedEvent
  bountyPrize:               bountyPrizeEvent
  chatBoxNotification:       chatBoxNotificationEvent
  friendDelete:              friendDeleteEvent
  friendRequest:             friendRequestEvent
  offerTournament:           offerTournamentEvent
  ownBountyPrize:            ownBountyPrizeEvent
  playerNotification:        playerNotification
  tableNotification:         tableNotificationEvent
  tableNotificationClosable: tableNotificationClosableEvent
  tournamentPosition:        tournamentPositionEvent
  tournamentPositionMoney:   tournamentPositionMoneyEvent
  tournamentRematchFailure:  tournamentRematchFailureEvent
  tournamentShutdown:        tournamentShutdownEvent
  tournamentShutdownInsufficientPlayers: tournamentShutdownInsufficientPlayers
  userFriend:                userFriendEvent

export default ({signal, details, app, game, client, listing, events}) ->
  events ?= Events
  eventName = details.eventName

  NonGameEvents[eventName]? {signal, details, app, game, client, listing}

  player = game?.getPlayerByPosition(details.playerId)

  if player?

    player.timer = 0
    player.usingTimebank = false

    if player.isClient and details.activeSitOut
      client.activeSitOutActions()

    if details.playerBalance?
      player.balance = details.playerBalance

    if details.eventAmount
      player.bet = details.eventAmount

    if details.betAmount
      # fix called bets not clearing caused by this running after currentBet update
      if signal.updates.currentBet is 0
        player.betToPot()

    if details.waiting
      eventName = 'waiting'

    if details.cards
      details.cards = details.cards.map (card) -> new Card card

    if eventMap[eventName]?
      player.isTurn = false # WARNING SCARY SHIT
      player.label = eventMap[eventName].label
      player.cssClass = eventMap[eventName].cssClass or ''
      player.emit 'change:cssClass', player.cssClass # force update
      # show chat message
      args =
        name: player.alias
        amount: details.eventAmount?.toMoney(game.currency, game.precision)
        cards: details.cards
      client.displayChatMessage message: eventMap[eventName].chat args

      # Update Hand History
      # This block is because sometimes we get say a BIGBLIND message in PREFLOP state, but the Hand History
      #  is supposed to display it in a special BLINDS section.
      # Similarly fold/call/check/allIn can't occur in showdown or endOfFoldedGame (must happen in state before)
      # Similarly show/muck can't occur in endOfGame
      # Fortunately currentState.cards/currentState.previous handles this issue for most of the hand
      finalState = do ->
        blinds = ['SMALLBLIND', 'BIGBLIND', 'RETURNBLIND', 'INITIALBLIND']
        if eventName.toUpperCase() in blinds
          return 'BLINDS'
        if game.currentState?.current in ['showdown', 'endOfFoldedGame'] and eventName in ['fold', 'call', 'check', 'allIn']
          item = game.getCurrentHandHistory()
          if item?
            states = uniq item.history.map ({state}) -> state
            return states.slice(-2)[0] # previous state
          # console.log eventName, 'on', game.currentState.current, 'so getting most recent state from game history for hand history:', state
        if game.currentState?.current in ['endOfGame'] and eventName in ['showCards', 'muckCards']
          # technically this branch could be merged with the previous one, but they are separate cases, so leaving them distinct for now
          item = game.getCurrentHandHistory()
          if item?
            states = uniq item.history.map ({state}) -> state
            return states.slice(-2)[0] # previous state
      message = eventMap[eventName].handHistory args
      game.updateHandHistory {state: finalState, message}

  events[eventName]? {signal, details, app, game, player, client}

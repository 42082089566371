# Game View
# Model: Game

import defer from 'lodash/defer'

import Chips from '../../../rooms/chips/Chips.js'
import GamePlayersView from './player/gamePlayersView.coffee'
import CardView from './cardView.coffee'
import View from '../view.coffee'

export default class GameView extends View
  constructor: (game, @createReactView, tableId) ->
    super()
    @model = game

    @dom = Webclient.ViewManager.returnFormatElement
      desktop: '<div class="game-container game-container-desktop"></div>'
      tablet:  '<div class="game-container game-container-desktop"></div>'
      mobile:  '<div class="game-container"></div>'

    # ### Components - eg. DOM elements & views
    @components =
      modals:             $ "<div>"
      community:          $ "<div class='game-community'>"
      pots:               $ "<div class='game-pots'>"
      potTotal:           $ "<div class='potTotal'>"
      rake:               $ "<div class='rake'>"
      playersView:        new GamePlayersView(@model, @createReactView)

    # ### Events - eg. MouseClicks ###
    @components.potTotal.on 'click',    @togglePotRake
    @components.rake.on 'click',        @togglePotRake

    # ### Listeners - eg. auth:success
    @listeners =
      'potToWinner': @potToWinner

    # ### Changes - eg. Model.property = true
    @changes =
      potTotal: @updatePotTotal
      community: @updateCommunity
      rake: @updateRake
      pots: @updatePots
      winningCards: @updateWinningCards
      updateMaxPlayers: @updateMaxPlayers
      gameTypeDesc: @updateGameTypeDesc

    @initializeView()
    return this

  # only playersView has their player count set here because
  # its own renderer doesn't have access to the maxPlayers
  render: =>
    @updateMaxPlayers()
    @appendComponents()
    @components.rake.hide() # for rake/total toggle

    for _, change of @changes
      change()

    return @dom

  updateGameTypeDesc: =>
    @dom.addClass 'type-' + @model.gameTypeDesc.short.replace /\W/g, ''

  updateMaxPlayers: =>
    @dom.addClass "seats#{@model.maxPlayers}"

  togglePotRake: =>
    if @model.rake
      @components.rake.toggle()
      @components.potTotal.toggle()

  updatePots: =>
    @chipViews = {}
    innerPots = $ '<div class="game-pots-inner">'
    @components.pots.html innerPots
    for pot in @model.pots
      chipView = @createReactView(
        Chips,
        {amount: pot.amount, symbol: @model.currency, precision: @model.precision},
        {class: 'chip-container'},
        'div'
      )
      @chipViews[pot.number] = chipView
      @model.once 'change:pots', chipView.destroy
      innerPots.append(chipView.render())

  updatePotTotal: =>
    if @model.potTotal > 0
      @components.potTotal.html "Total Pot: #{@model.potTotal.toMoney(@model.currency, @model.precision)}"
    else
      @components.potTotal.html ''

  updateWinningCards: =>
    for cardClass in @model.winningCards
      @dom.find(cardClass).addClass 'win'

  updateRake: =>
    if @model.rake is 0
      @components.rake.hide()
      @components.potTotal.show()
    else
      @components.rake.html "Rake: #{@model.rake.toMoney()}"

  updateCommunity: =>
    for card in @model.community then do (card) =>
      cardView = new CardView card
      @components.community.append cardView.render()
      @model.once 'change:community', cardView.destroy

    @components.community.removeClass('flop turn river')
    switch @model.community.length
      when 3
        @model.playSound 'dealFlop'
        # hack fix for WEB-1319 stacked flop issue caused by animation not completing (intermittently) on some old low power devices
        # we pretty much just defer the animation until the js thread stops getting gb'd by signals and other things
        defer => @components.community.addClass 'flop'
      when 4
        @model.playSound 'cardDeal'
        @components.community.addClass 'turn'
      when 5
        @model.playSound 'cardDeal'
        @components.community.addClass 'river'

  potToWinner: (potNumber, amount, position) =>
    # wait for bets to finish animating to pot
    # @model.playSound 'chipMove'
    setTimeout =>
      chipView = @chipViews?[potNumber]
      unless chipView
        # chip = new Chip amount: pot.amount
        # chipView = new ChipView chip
        # chipView.addClass 'pot'
        # console.warn 'could not find pot', potNumber, 'to animate to winner'
        return
      $(chipView.container()).addClass('hide-amount')
      to = @components.playersView.dom.find(".player#{position} .chip-container").offset()
      #empty seats and preferred seatings use player#{position} classes only look in #players >
      to ?= @components.playersView.dom.find(".player#{position}").offset()
      from = $(chipView.container()).offset()
      if to and from
        x = to.left - from.left
        y = to.top - from.top
        $(chipView.container()).css({'transition': 'transform 300ms', 'transform': "translate(#{x}px, #{y}px)"})

      setTimeout chipView.destroy, 300
    , 300

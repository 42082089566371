import React from 'react'

import Button from './Button.js'
import {useLeaveGame} from '../react-hooks.js'

export default function Leave () {
  const leaveGame = useLeaveGame()

  return <Button onClick={leaveGame} icon='fa fa-sign-out' />
}

import React from 'react'

import ListingHeader from './ListingHeader.js'
import EmptyHeader from '../../../components/game-listing/table/EmptyHeader.js'
import HeaderGroup from '../../../components/game-listing/table/HeaderGroup.js'
import Row from '../../../components/game-listing/table/Row.js'
import styles from '../../../components/game-listing/table/Table.module.css'

import {
  KEY_AVERAGE_POT,
  KEY_FLOP_RATIO,
  KEY_GAME,
  KEY_HANDS_PER_HOUR,
  KEY_NAME,
  KEY_SEATED,
  KEY_SEATS,
  KEY_STAKES,
} from '../../sorting.js'

export default function ListingHeaders () {
  return <HeaderGroup>
    <Row>
      <ListingHeader label='Seats' sortKey={KEY_SEATS} />
      <ListingHeader label='Room' sortKey={KEY_NAME} className={styles.primary} />
      <ListingHeader label='Game' sortKey={KEY_GAME} />
      <ListingHeader label='Stakes' sortKey={KEY_STAKES} />
      <ListingHeader label='Plrs' sortKey={KEY_SEATED} />
      <ListingHeader label='Avg Pot' sortKey={KEY_AVERAGE_POT} className={styles.expendable} />
      <ListingHeader label='Plrs/Flop' sortKey={KEY_FLOP_RATIO} className={styles.expendable} />
      <ListingHeader label='H/Hr' sortKey={KEY_HANDS_PER_HOUR} className={styles.expendable} />
      <EmptyHeader />
    </Row>
  </HeaderGroup>
}

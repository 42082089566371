# Reserve Function
# Confirms reservation request to client

export default ({signal, app, game, client, listing}) ->
  return unless client

  if signal.success
    # console.log 'great success'
    client.position = signal.reserve.playerPosition
    client.buyin()
    if listing?.waiting
      client.action 'leaveWaitlists'
    game?.reservation client.position

  else
    switch signal.failReason
      when 'emailVerificationRequired'
        client.error 'emailVerificationRequired'
      when 'ipAddressOnTable'
        client.error 'ipAddressOnTable'
      when 'positionUnavailable'
        client.error 'positionUnavailable'
      when 'notEligible', 'classNotAuthorised'
        client.error 'notEligible'
      when 'tooManyGamesOfType'
        client.error 'tooManyGamesOfType'
      when 'gameTypeExclusion'
        client.error 'gameTypeExclusion'
      else
        client.error 'joinFailed'

import React from 'react'

import ExternalTabButton from './ExternalTabButton.js'

export default function DesktopTabButtons () {
  return <>
    <ExternalTabButton icon='casino' label='Casino' urlName='lobby.externalCasinoGames' />
    <ExternalTabButton icon='football' label='Sports' urlName='lobby.externalSportsBet' />
  </>
}

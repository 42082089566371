import TableListing from '../../../models/tableListing.coffee'

export default ({signal, app}) ->

  tournament = app.listings.find {instanceId: signal.tournamentDetails.gameDetails.instanceId}

  unless tournament?
    # signal.success = false
    # app.listings.rem app.listings.find {instanceId: signal.parent.arguments.game_instance}
    # todo: this should actually be in listing.showLobby callback
    # app.notice
    #   title: 'Error'
    #   content: 'Could not open tournament lobby'
    # Navigator.navLobby()
    return

  for key, value of signal.tournamentDetails.gameDetails
    tournament[key] = value

  # force the listing row to re-render.
  # fixes issue where openTournamentLink on desktop results in broken listing row view
  tournament.emit 'refresh'

  tournament.detailsReceived = true
  tournament.entryPayments = signal.tournamentDetails.entryPayments
  tournament.prizes = signal.tournamentDetails.prizes
  tournament.breaks = signal.tournamentDetails.breaks
  tournament.satelliteInfo = signal.tournamentDetails.satelliteInfo
  tournament.currentLevel = signal.tournamentDetails.currentLevel
  tournament.nextLevel = signal.tournamentDetails.nextLevel

  tournament.entry = tournament.buyIn + tournament.entryFee # for the listing

  tournament.tableList.empty()
  for details in signal.tournamentDetails.tables then do (details) ->
    tableListing = new TableListing {details}
    tableListing.on 'joinTable', -> Navigator.navTable tableListing  # {instanceId, serverId}
    tournament.tableList.add tableListing

  app.tournamentDetails tournament, signal.tournamentDetails

import {HYPER_TURBO, TURBO} from '@pipehat/chronicle/constants/game-category'
import React from 'react'

import SpeedButton from './SpeedButton.js'
import {ALL, REGULAR} from '../../constants.js'
import {SPEED_MAP} from '../constants.js'
import {useSeatCountRange} from '../react-hooks.js'

export default function SpeedSelection () {
  const seatCountRange = useSeatCountRange()
  const speeds = SPEED_MAP[seatCountRange]

  const shouldShowAll = speeds.includes(ALL)
  const shouldShowRegular = speeds.includes(REGULAR)
  const shouldShowTurbo = speeds.includes(TURBO)
  const shouldShowHyperTurbo = speeds.includes(HYPER_TURBO)

  return <ul>
    {shouldShowAll && <li><SpeedButton label='All' speed={ALL} /></li>}
    {shouldShowRegular && <li><SpeedButton label='Regular' speed={REGULAR} /></li>}
    {shouldShowTurbo && <li><SpeedButton label='Turbo' speed={TURBO} /></li>}
    {shouldShowHyperTurbo && <li><SpeedButton label='Hyper Turbo' speed={HYPER_TURBO} /></li>}
  </ul>
}

import {EventEmitter} from 'events'

const STORAGE_KEY = 'logging:topics'

export function createLogger (localStorage, sessionStorage) {
  const init = {
    logging: true,

    pingpongs: false,
    routing: false,
    signals: false,
    update: false,
    workbox: false,
  }

  const events = new EventEmitter()
  const topics = {...init}
  let isPersistent = false

  const logger = {
    events,

    firehose () {
      for (const topic in topics) {
        topics[topic] = true
        events.emit(`topic.${topic}`, true)
      }

      persist(true)
      log('logging', 'All logging topics have been enabled')
    },

    log,

    get persist () {
      return isPersistent
    },

    set persist (shouldPersist) {
      isPersistent = shouldPersist
      persist(true)

      log('logging', `Logging persistence ${shouldPersist ? 'enabled' : 'disabled'}`)
    },

    reset () {
      Object.assign(topics, init)
      for (const topic in topics) events.emit(`topic.${topic}`, false)
      persist(true)

      log('logging', 'Logging topics have been reset to their defaults')
    },

    get status () {
      return Object.entries(topics)
        .sort(([left], [right]) => left.localeCompare(right))
        .reduce((rendered, [topic, isEnabled]) => {
          return `${rendered}${isEnabled ? '\u2705' : '\u274C'} ${topic}\n`
        }, '\n')
    },

    topics: {},
  }

  for (const topic in topics) {
    Object.defineProperty(logger.topics, topic, {
      get () {
        return topics[topic]
      },

      set (isEnabled) {
        topics[topic] = !!isEnabled
        events.emit(`topic.${topic}`, topics[topic])
        persist(true)

        log('logging', `Logging topic ${topic} ${isEnabled ? 'enabled' : 'disabled'}`)
      },
    })
  }

  try {
    const localStorageTopics = localStorage && localStorage.getItem(STORAGE_KEY)
    if (localStorageTopics) {
      isPersistent = true
      rehydrateTopics(localStorageTopics)
    }
  } catch (error) {}

  try {
    const sessionStorageTopics = sessionStorage && sessionStorage.getItem(STORAGE_KEY)
    if (sessionStorageTopics) rehydrateTopics(sessionStorageTopics)
  } catch (error) {}

  persist(true)

  window.addEventListener('storage', event => {
    if (event.key !== STORAGE_KEY) return

    if (event.newValue) {
      isPersistent = true
      Object.assign(topics, init)
      rehydrateTopics(event.newValue)
      persist(false)

      log('logging', 'Persistent logging topics updated by another window')
    } else {
      isPersistent = false
      log('logging', 'Logging persistence disabled by another window')
    }
  })

  return logger

  function log (topic, ...args) {
    if (topics[topic]) console.log(...args) // eslint-disable-line no-console
  }

  function persist (useLocalStorage) {
    const serialized = JSON.stringify(
      Object.entries(topics)
        .filter(([, isEnabled]) => isEnabled)
        .map(([topic]) => topic),
    )

    if (sessionStorage) {
      try {
        setItem(sessionStorage, STORAGE_KEY, serialized)
      } catch (error) {
        log('logging', 'Unable to store logging topics in sessionStorage:', error)
      }
    }

    if (localStorage && useLocalStorage) {
      try {
        if (isPersistent) {
          setItem(localStorage, STORAGE_KEY, serialized)
        } else {
          localStorage.removeItem(STORAGE_KEY)
        }
      } catch (error) {
        log('logging', 'Unable to store logging topics in localStorage:', error)
      }
    }
  }

  function rehydrateTopics (serialized) {
    try {
      JSON.parse(serialized).forEach(topic => {
        if (!topics.hasOwnProperty(topic)) return

        topics[topic] = true
        events.emit(`topic.${topic}`, true)
      })
    } catch (error) {
      log('logging', 'Unable to restore logging topics:', error)
    }
  }

  function setItem (storage, key, value) {
    if (storage.getItem(key) === value) return

    storage.setItem(key, value)
  }
}

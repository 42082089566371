export const HOME = 'home'
export const LOBBY = 'lobby'
export const TOURNAMENT = 'tournament'
export const TABLE = 'table'
export const GAME_LIST = 'gameList'
export const QUICK_SEAT = 'quickSeat'
export const FULL_LIST = 'fullList'
export const SELECTOR_LIST = 'selectorList'
export const FAQ = 'faq'
export const SETTINGS = 'settings'
export const SUPPORT = 'support'
export const POP_UP = 'popUp'
export const POKER_RULES = 'pokerRules'
export const RING_GAMES = 'ringGames'
export const TOURNAMENTS = 'tournaments'
export const RESPONSIBLE_GAMING = 'responsibleGaming'
export const SIT_N_GO = 'sitngo'

export const DEFAULT_LOBBY_ROUTE = {name: QUICK_SEAT, params: {}}

export const RING_GAME_SUB_ROUTE = {
  FL_HOLDEM: 'flHoldem',
  FL_OMAHA_HILO: 'flOmahaHiLo',
  NL_HOLDEM: 'nlHoldem',
  PL_OMAHA: 'plOmaha',
}

export const SIT_N_GO_SUB_ROUTE = {
  HYPER: 'hyper',
  TURBO: 'turbo',
  ALL: 'all',
}

export const TOURNAMENTS_SUB_ROUTE = {
  HOLDEM: 'holdem',
  OMAHA: 'omaha',
  SATELLITES: 'satellites',
}

export default [
  {name: HOME, path: '/'},

  {name: FAQ, path: '/faq', navName: 'faq'},
  {name: FULL_LIST, path: '/fullList', forwardTo: SELECTOR_LIST},
  {name: LOBBY, path: '/lobby'},
  {name: POKER_RULES, path: '/pokerRules', navName: 'pokerRules'},
  {name: POP_UP, path: '/popUp/:instanceId'},
  {name: QUICK_SEAT, path: '/quickSeat', navName: 'quickSeat', isLobby: true},
  {name: RESPONSIBLE_GAMING, path: '/responsibleGaming', navName: 'responsibleGaming'},
  {name: RING_GAMES, path: '/ringGames', navName: 'ringGames'},
  {name: SETTINGS, path: '/settings', navName: 'settings'},
  {name: SUPPORT, path: '/support', navName: 'support'},
  {name: TABLE, path: '/table/:tableId', navName: 'table'},
  {name: TOURNAMENT, path: '/tournament/:tournamentId', navName: 'tournament'},
  {name: TOURNAMENTS, path: '/tournaments', navName: 'tournaments'},

  {
    name: GAME_LIST,
    path: '/gameList/:s1/:s2',
    navName: 'gameList',
    isLobby: true,
    defaultParams: {s1: 'ringGames', s2: 'nlHoldem'},
    formatRedirect: {
      desktop: {name: SELECTOR_LIST},
      tablet: {name: SELECTOR_LIST},
    },
  },
  {
    name: SELECTOR_LIST,
    path: '/selectorList',
    navName: 'selectorList',
    isLobby: true,
    formatRedirect: {
      mobile: {name: GAME_LIST},
    },
  },
]

export function createRouteHooks (ownGamesRouteHook, quickSeatRouteHook) {
  return {
    [QUICK_SEAT]: quickSeatRouteHook,
    [RING_GAMES]: ownGamesRouteHook,
    [TOURNAMENTS]: ownGamesRouteHook,
  }
}

# Card Parser converts a string like "kh,8s" and to an object with
#  rank and suit properties from the rankMap and suitMap

import rankMap from './rankMap.coffee'
import suitMap from './suitMap.coffee'

export default
  rankMap: rankMap
  suitMap: suitMap
  parse: (value) ->
    if value and typeof value is 'string'
      cards = []
      for _, str of value.split ','
        if str isnt ''
          rank = rankMap[str[0]]
          suit = suitMap[str[1]]
          if rank? and suit?
            cards.push {rank, suit, facedown: false}
          else
            throw new Error 'unknown rank or suit for card string: ' + str
        else
          cards.push {}
      return cards
    return {}

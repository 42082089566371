export default ({signal, details, app, game, client, listing}) ->
  client?.waiting = true
  client?.waitlistPosition = details

  # because the client gets wiped when navving back to lobby, we need to
  # remember not to show this modal a second time on re-joining
  app.shownWaitlistNotifications ?= {}

  gameName = game?.gameName
  instanceId = signal.message.instanceId

  nameSeen = gameName and app.shownWaitlistNotifications[gameName]
  instanceSeen = instanceId and app.shownWaitlistNotifications[instanceId]

  client?.waitlistNotification() unless nameSeen or instanceSeen
  app.shownWaitlistNotifications[gameName] = true if gameName
  app.shownWaitlistNotifications[instanceId] = true if instanceId

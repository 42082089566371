import React from 'react'

import ActionButton from '../../components/ActionButton.js'
import {useForgoBreak, useIsReadyToContinue} from '../../react-hooks.js'

export default function ImReadyButton () {
  return <ActionButton onClick={useForgoBreak()} isIntendedAction={useIsReadyToContinue()}>
    I'm Ready
  </ActionButton>
}

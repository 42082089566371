import React, {useCallback, useMemo} from 'react'

import Money from '../../../money/components/Money.js'
import {useRootedSelector} from '../../../redux.js'
import {createGetEntryBounty, createGetParticipantBounties, getRoot} from '../../index.js'
import {useShowBountyModal} from '../../react-hooks.js'

export default function KnockoutPrizes (props) {
  const {tournamentId} = props
  const showBountyModal = useShowBountyModal(tournamentId)
  const entryBounty = useRootedSelector(getRoot, useMemo(createGetEntryBounty, []), props)
  const participantBounties = useRootedSelector(getRoot, useMemo(createGetParticipantBounties, []), props)

  const handleClick = useCallback(() => {
    showBountyModal(participantBounties)
  }, [participantBounties, showBountyModal])

  return <div className='kvpair selfclear'>
    <label>Knockout Prizes:</label>
    <span className='link' onClick={handleClick}>
      {entryBounty ? <Money value={entryBounty} /> : '$0'}
    </span>
  </div>
}

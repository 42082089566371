import {HYPER_TURBO, N_UP, TURBO} from '@pipehat/chronicle/constants/game-category'
import {OMAHA_HOLDEM, OMAHA_HOLDEM_HILO, TEXAS_HOLDEM} from '@pipehat/chronicle/constants/game-variant'
import {FIXED_LIMIT, NO_LIMIT, POT_LIMIT} from '@pipehat/chronicle/constants/limit-type'
import {knuthShuffle} from 'knuth-shuffle'

import ModalView from '../legacy/views/modalView.coffee'

export const GAME_FORMAT = {
  RING_GAME: 'RING_GAME',
  SNG: 'SNG',
}

export const RING_GAME_FILTER = {
  FIXED_LIMIT_TEXAS_HOLDEM: 'FIXED_LIMIT_TEXAS_HOLDEM',
  FIXED_LIMIT_OMAHA_HOLDEM_HILO: 'FIXED_LIMIT_OMAHA_HOLDEM_HILO',
  NO_LIMIT_TEXAS_HOLDEM: 'NO_LIMIT_TEXAS_HOLDEM',
  POT_LIMIT_OMAHA_HOLDEM: 'POT_LIMIT_OMAHA_HOLDEM',
}

export const RING_GAME_FILTER_CRITERIA = {
  [RING_GAME_FILTER.FIXED_LIMIT_TEXAS_HOLDEM]: {
    limitType: FIXED_LIMIT,
    variant: TEXAS_HOLDEM,
  },
  [RING_GAME_FILTER.FIXED_LIMIT_OMAHA_HOLDEM_HILO]: {
    limitType: FIXED_LIMIT,
    variant: OMAHA_HOLDEM_HILO,
  },
  [RING_GAME_FILTER.NO_LIMIT_TEXAS_HOLDEM]: {
    limitType: NO_LIMIT,
    variant: TEXAS_HOLDEM,
  },
  [RING_GAME_FILTER.POT_LIMIT_OMAHA_HOLDEM]: {
    limitType: POT_LIMIT,
    variant: OMAHA_HOLDEM,
  },
}

export const SNG_FILTER = {
  NO_LIMIT_TEXAS_HOLDEM_6_MAX_HYPER_TURBO: 'NO_LIMIT_TEXAS_HOLDEM_6_MAX_HYPER_TURBO',
  NO_LIMIT_TEXAS_HOLDEM_6_MAX_TURBO: 'NO_LIMIT_TEXAS_HOLDEM_6_MAX_TURBO',
  NO_LIMIT_TEXAS_HOLDEM_DOUBLE_UP: 'NO_LIMIT_TEXAS_HOLDEM_DOUBLE_UP',
  NO_LIMIT_TEXAS_HOLDEM_HEADS_UP_HYPER_TURBO: 'NO_LIMIT_TEXAS_HOLDEM_HEADS_UP_HYPER_TURBO',
}

export const SNG_FILTER_CRITERIA = {
  [SNG_FILTER.NO_LIMIT_TEXAS_HOLDEM_6_MAX_HYPER_TURBO]: {
    category: HYPER_TURBO,
    limitType: NO_LIMIT,
    maximumSeatCount: 6,
    minimumSeatCount: 3,
    variant: TEXAS_HOLDEM,
  },
  [SNG_FILTER.NO_LIMIT_TEXAS_HOLDEM_6_MAX_TURBO]: {
    category: TURBO,
    limitType: NO_LIMIT,
    maximumSeatCount: 6,
    minimumSeatCount: 3,
    variant: TEXAS_HOLDEM,
  },
  [SNG_FILTER.NO_LIMIT_TEXAS_HOLDEM_DOUBLE_UP]: {
    category: N_UP,
    limitType: NO_LIMIT,
    maximumSeatCount: 10,
    minimumSeatCount: 9,
    variant: TEXAS_HOLDEM,
  },
  [SNG_FILTER.NO_LIMIT_TEXAS_HOLDEM_HEADS_UP_HYPER_TURBO]: {
    category: HYPER_TURBO,
    limitType: NO_LIMIT,
    maximumSeatCount: 2,
    minimumSeatCount: 2,
    variant: TEXAS_HOLDEM,
  },
}

const GAME_FORMAT_SETTING = 'quickSeatGameFormat'

export function createService (gameFilterSettings, gameList) {
  const gameFormatSubscribers = new Set()

  let gameFormat = normalizeGameFormat(gameFilterSettings.get(GAME_FORMAT_SETTING))

  gameFilterSettings.subscribe(GAME_FORMAT_SETTING, newFormat => {
    newFormat = normalizeGameFormat(newFormat)

    if (newFormat !== gameFormat) dispatchGameFormat(newFormat)
  })

  return {
    get gameFormat () { return gameFormat },

    async joinRingGame (candidates) {
      try {
        let game

        for (const tier of candidates) {
          if (game) continue

          for (const instanceId of knuthShuffle([...tier])) {
            if (game) continue

            game = gameList.find({instanceId})
          }
        }

        if (!game) {
          new ModalView({
            title: 'No Games Found',
            icon: 'warning',
            content: 'There are no games available for your quick seat criteria.',
          }).attachToBody()

          return
        }

        const join = quickJoin.bind(null, game)

        if (game.playerCount > 0) {
          join()

          return
        }

        new ModalView({
          title: 'No Active Games Found',
          icon: 'warning',
          content: 'There are no active games available, would you like to be the first player seated?',
          choices: [
            {value: 'Cancel', icon: 'times'},
            {value: 'OK', icon: 'check', action: join},
          ],
        }).attachToBody()
      } catch (error) {
        new ModalView({
          title: 'Unable to quick seat',
          icon: 'error',
          content: 'Sorry, something went wrong.',
        }).attachToBody()
      }
    },

    async joinSng (candidates) {
      try {
        // pre-load tournament details
        for (const tier of candidates) {
          for (const instanceId of tier) {
            const game = gameList.find({instanceId})

            if (game && !game.detailsReceived) game.getDetails()
          }
        }

        let game, paymentOption

        for (const tier of candidates) {
          if (paymentOption) continue

          for (const instanceId of knuthShuffle([...tier])) {
            if (paymentOption) continue

            game = gameList.find({instanceId})
            paymentOption = undefined

            if (!game) continue

            if (!game.detailsReceived) {
              await new Promise(resolve => {
                game.once('change:detailsReceived', () => { resolve() })
              })
            }

            for (const {bounty, buyIn, entryFee, id} of game.entryPayments) {
              if (paymentOption) continue
              if (!isCashOnly(bounty) || !isCashOnly(buyIn) || !isCashOnly(entryFee)) continue

              paymentOption = id
            }
          }
        }

        if (!paymentOption) {
          new ModalView({
            title: 'No Games Found',
            icon: 'warning',
            content: 'There are no games available for your quick seat criteria.',
          }).attachToBody()

          return
        }

        const register = game.register.bind(game, {paymentId: paymentOption})

        if (game.playerCount > 0) {
          register()

          return
        }

        new ModalView({
          title: 'No Active Games Found',
          icon: 'warning',
          content: 'There are no active games available, would you like to be the first player seated?',
          choices: [
            {value: 'Cancel', icon: 'times'},
            {value: 'OK', icon: 'check', action: register},
          ],
        }).attachToBody()
      } catch (error) {
        new ModalView({
          title: 'Unable to quick seat',
          icon: 'error',
          content: 'Sorry, something went wrong.',
        }).attachToBody()
      }
    },

    setGameFormat (gameFormat) {
      gameFilterSettings.set(GAME_FORMAT_SETTING, gameFormat)
    },

    subscribeToGameFormat (subscriber) {
      gameFormatSubscribers.add(subscriber)

      return function unsubscribe () {
        gameFormatSubscribers.delete(subscriber)
      }
    },
  }

  function dispatchGameFormat (newFormat) {
    gameFormat = newFormat
    for (const subscriber of gameFormatSubscribers) subscriber(newFormat)
  }

  function isCashOnly ({coupon, couponComponent, pointsComponent}) {
    return !coupon && couponComponent === 0 && pointsComponent === 0
  }

  function normalizeGameFormat (gameFormat) {
    return GAME_FORMAT[gameFormat] ? gameFormat : GAME_FORMAT.RING_GAME
  }

  function quickJoin (game) {
    game.reserveOnJoin = true
    game.joinGame()
  }
}

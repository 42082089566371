import React, {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import {useTableId} from '../../context.js'
import Control from '../../controls/components/Button.js'
import {collapseChat} from '../chat-slice.js'

export default function Close () {
  const dispatch = useDispatch()
  const tableId = useTableId()

  const handleClick = useCallback(() => {
    dispatch(collapseChat({tableId}))
  }, [dispatch, tableId])

  return <Control className='collapse-chat' icon='fa fa-times' onClick={handleClick} />
}

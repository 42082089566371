export default ({signal, app, game, client, state}) ->
  game.end()
  client.description = ''
  client.displayNotification()
  # clears multiRound Intended actions
  client.intendedAction = null
  unless client.sittingOut or client.activeSitOut
    client.currentActions = []

  if state.current is 'gameCancelled'
    game.players.each (player) ->
      if player.bet > 0
        player.balance += player.bet

    client.displayChatMessage message: 'Game was cancelled - any blinds have been refunded'
    game.clearTable()

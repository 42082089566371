# warn 03-14 03:17 no translation seatReserved { value1: '1', name: 'webclient' }
import Mixmaster from '../../../mixmaster.coffee'

export default class SeatReservedData extends Mixmaster
  mixin: (object) ->
    object.seatReserved = (signal, name, values) ->
      signal.updates.seatReserved =
        position: values.value1
        name: values.name

    object.seat = (signal, name, values) -> # { seat: '1', name: 'gordonfreeman ii', gameTimer: '53', value4: '2' }
      signal.updates.seat = {
        seat: parseInt values.seat, 10
        gameName: values.name
        gameTimer: parseInt values.gameTimer, 10
        gameTypeId: values.value4
      }

    object.minStake = (signal, name, values) ->
      signal.updates.minStake = values # 0.04

    object.reservedDuringWaitlistOperation = (signal, name, values) ->
      signal.updates.reservedDuringWaitlistOperation = values # true
      if values # hack alias according to JHayne these are all the same thing
        signal.updates.seatReservedFromWaitlistMonitor = true

    object.nickName = (signal, name, values) ->
      signal.updates.nickName = values # BALLLERRRRRR

    object.gameCreatedByWaitlistMonitor = (signal, name, values) ->
      signal.updates.gameCreatedByWaitlistMonitor = values # true
      if values # hack alias according to JHayne these are all the same thing
        signal.updates.seatReservedFromWaitlistMonitor = true

    object.seatReservedFromWaitlistMonitor = (signal, name, values) ->
      signal.updates.seatReservedFromWaitlistMonitor = values

import {isLessThan, maximum, minimum, multiply} from '@pipehat/money/plain-object'
import React, {useEffect, useMemo} from 'react'

import styles from './Bank.module.css'
import Cell from './components/Cell.js'
import Column from './components/Column.js'
import Money from './components/Money.js'
import Panel from './components/Panel.js'
import PresetButtonsPanel from './components/PresetButtonsPanel.js'
import Value from './components/Value.js'
import VisitCashier from './components/VisitCashier.js'

import {
  useBalance,
  useBuyInAmount,
  useIsFixedLimit,
  useMaximumBuyIn,
  useMinimumBuyIn,
  usePreviousBalance,
  useSetBuyInAmount,
  useShowInsufficientFundsForBuyIn,
  useStakes,
} from './react-hooks.js'

import {roundStepAmount} from './rendering.js'
import MoneyStepper from '../../money/components/MoneyStepper.js'
import {useDefaultBuyInBigBlindMultiplier} from '../../settings/react-hooks.js'

export default function BuyIn () {
  const showInsufficientFundsForBuyIn = useShowInsufficientFundsForBuyIn()
  const defaultBuyInBigBlindMultiplier = useDefaultBuyInBigBlindMultiplier()
  const buyInAmount = useBuyInAmount()
  const setBuyInAmount = useSetBuyInAmount()
  const previousBalance = usePreviousBalance()
  const balance = useBalance()
  const isFixedLimit = useIsFixedLimit()
  const maximumBuyIn = useMaximumBuyIn()
  const minimumBuyIn = useMinimumBuyIn()
  const stakes = useStakes()

  const hasTableData = balance && maximumBuyIn && minimumBuyIn && stakes && true
  const {maximumStake: bigBlind} = hasTableData ? stakes : {}

  const minimumAmount = useMemo(() => {
    if (!hasTableData) return undefined

    return previousBalance ? maximum([minimumBuyIn, previousBalance]) : minimumBuyIn
  }, [hasTableData, minimumBuyIn, previousBalance])

  const maximumAmount = useMemo(() => {
    if (!hasTableData) return undefined

    return minimum([balance, maximumBuyIn])
  }, [balance, hasTableData, maximumBuyIn])

  const defaultAmount = useMemo(() => {
    if (!hasTableData) return undefined

    const defaultBuyIn = multiply(Number(defaultBuyInBigBlindMultiplier), bigBlind)

    return maximum([minimumAmount, minimum([balance, maximumBuyIn, defaultBuyIn])])
  }, [balance, bigBlind, defaultBuyInBigBlindMultiplier, hasTableData, maximumBuyIn, minimumAmount])

  const stepAmount = useMemo(() => {
    if (!hasTableData) return undefined

    return roundStepAmount(multiply(10, bigBlind))
  }, [hasTableData, bigBlind])

  useEffect(() => {
    if (!buyInAmount && defaultAmount) setBuyInAmount(defaultAmount)
  }, [buyInAmount, defaultAmount, setBuyInAmount])

  if (!hasTableData || !buyInAmount) return null // still loading

  const isLessThanMinimumAmount = isLessThan(minimumAmount)
  if (isLessThanMinimumAmount(balance)) {
    showInsufficientFundsForBuyIn(balance, minimumAmount)

    return null
  }

  return <div>
    <MoneyStepper
      min={minimumAmount}
      max={maximumAmount}
      step={stepAmount}
      value={buyInAmount}
      onChange={setBuyInAmount}
    />

    <PresetButtonsPanel
      min={minimumAmount}
      max={maximumAmount}
      defaultAmount={defaultAmount}
      setAmount={setBuyInAmount}
    />

    {previousBalance && <div className={styles.etiquetteNotice}>
      To ensure poker etiquette is observed, you must re-enter this table with at least your previous balance of
      <Money value={previousBalance} />
    </div>}

    <Panel>
      <Column>
        <Cell>
          My Balance: <Money value={balance} />
          <VisitCashier />
        </Cell>
      </Column>

      <Column>
        <Cell>Table Min Buy In: <Money value={minimumBuyIn} /></Cell>
        <Cell>
          Table Max Buy In:
          {isFixedLimit ? <Value value='Unlimited' /> : <Money value={maximumBuyIn} />}
        </Cell>
      </Column>
    </Panel>
  </div>
}

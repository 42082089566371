map =
  type:
    '1': description: (cards) -> "#{map.singular[cards[0]]} High"
    '2': description: (cards) -> "Pair of #{map.plural[cards[0]]}"
    '3': description: (cards) -> "Two pair, #{map.plural[cards[0]]} and #{map.plural[cards[1]]}"
    '4': description: (cards) -> "Three of a kind #{map.plural[cards[0]]}"
    '5': description: (cards) -> "Straight, #{map.singular[cards[0]]} High"
    '6': description: (cards) -> "Flush, #{map.singular[cards[0]]} High"
    '7': description: (cards) -> "Full House, #{map.plural[cards[0]]} over #{map.plural[cards[1]]}"
    '8': description: (cards) -> "Four of a kind, #{map.plural[cards[0]]}"
    '9': description: (cards) -> "Straight Flush, #{map.singular[cards[0]]} High"
    '11':description: (cards) -> "Royal Flush"
  singular:
    'TWO': 'Two'
    'THREE': 'Three'
    'FOUR': 'Four'
    'FIVE': 'Five'
    'SIX': 'Six'
    'SEVEN': 'Seven'
    'EIGHT': 'Eight'
    'NINE': 'Nine'
    'TEN': 'Ten'
    'JACK': 'Jack'
    'QUEEN': 'Queen'
    'KING': 'King'
    'ACE': 'Ace'
  plural:
    'TWO': 'Twos'
    'THREE': 'Threes'
    'FOUR': 'Fours'
    'FIVE': 'Fives'
    'SIX': 'Sixes'
    'SEVEN': 'Sevens'
    'EIGHT': 'Eights'
    'NINE': 'Nines'
    'TEN': 'Tens'
    'JACK': 'Jacks'
    'QUEEN': 'Queens'
    'KING': 'Kings'
    'ACE': 'Aces'

  parse: (str) ->
    unless str
      return null

    # console.log 'parse', str

    text = ''

    [hi, lo] = str.split '^'

    description = hi.split ','
    if description.length
      type = description[0]
      cards = description[1...]
      if map.type[type]?
        text += map.type[type].description(cards)

    if lo
      text += " - Lo (#{lo})"

    return text

export default map

import {MUCK, RABBIT, SHOW} from '@pipehat/chronicle/constants/action'
import React from 'react'

import MuckButton from './components/MuckButton.js'
import RabbitButton from './components/RabbitButton.js'
import ShowCardsButton from './components/ShowCardsButton.js'
import {useAvailableActions} from '../react-hooks.js'

export default function EndHand () {
  const actions = useAvailableActions()

  return <>
    {actions.includes(SHOW) && <ShowCardsButton />}
    {actions.includes(MUCK) && <MuckButton />}
    {actions.includes(RABBIT) && <RabbitButton />}
  </>
}

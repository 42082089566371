import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# handleMoveGameNotify(long gameInstanceId, int serverId)
# handleMoveGameSuccess(long gameInstanceId, int serverId)

export default class MoveGameTranslator extends Mixmaster
  mixins = [
    DefaultMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

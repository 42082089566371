export default
  ACCEPT_DOUBLE: 'acceptDouble'
  ACCEPT_FACE_CARD: 'acceptFaceCard'
  ACCEPT_FRIEND: 'acceptFriend'
  ACCEPT_RESIGN: 'acceptResign'
  ACCEPT_STAKES: 'acceptStakes'
  ACKNOWLEDGE_HAND_FOR_HAND: 'acknowledgeHandForHand'
  ACKNOWLEDGE_BREAK_NOTIFY: 'acknowledgeBreakNotify'
  ACKNOWLEDGE_FINAL_REBUY: 'acknowledgeFinalRebuy'
  ACTIVATE_TOURNAMENT: 'activateTournament'
  ADDON: 'addon'
  ADD_EVENT: 'addEvent'
  ADD_FRIEND: 'addFriend'
  ADD_TAG: 'addTag'
  ADD_TO_WAITLIST: 'addToWaitlist'
  ADMIN_UPDATE: 'adminUpdate'
  ALERT: 'alert'
  ALL_IN: 'allIn'
  ANTE: 'ante'
  AUTO_REBUY: 'autoRebuy'
  AUTO_REBUY_DEPOSIT: 'autoRebuyDeposit'
  BET: 'bet'
  BIG_BLIND: 'bigBlind'
  BRING_IN: 'bringIn'
  BUY_IN_UPDATE: 'buyInUpdate'
  CACHE_RELOAD: 'cacheReload'
  CALL: 'call'
  CANCEL_TRANSACTION: 'cancelTransaction'
  CANCEL_TRANSFER: 'cancelTransfer'
  CHANGE_STAKES: 'changeStakes'
  CHAT: 'chat'
  CHAT_UPDATE: 'chatUpdate'
  CHECK: 'check'
  CHOOSE_TRUMP_SUIT: 'chooseTrumpSuit'
  CLAIM_COUPON: 'claimCoupon'
  COMPLETE: 'complete'
  CONNECTION_CHECK: 'connectionCheck'
  CREATE_GAME: 'createGame'
  CREATE_TOURNAMENT_GAME: 'createTournamentGame'
  DEACTIVATE_TOURNAMENT: 'deactivateTournament'
  DEAL: 'deal'
  DEAL_IT_TWICE: 'dealItTwice'
  DELETE_FRIEND: 'deleteFriend'
  DELETE_OLD_TAGS: 'deleteOldTags'
  DEPOSIT: 'deposit'
  DEREGISTER: 'deregister'
  DEREGISTER_TABLE: 'deregisterTable'
  DISCARD_CARD: 'discardCard'
  DOUBLE: 'double'
  DOUBLE_DOWN: 'doubleDown'
  DOUBLE_UP_DEAL: 'doubleUpDeal'
  DOUBLE_UP_REFUSE: 'doubleUpRefuse'
  DOUBLE_UP_SELECT: 'doubleUpSelect'
  DRAW: 'draw'
  ENABLE_CHAT: 'enableChat'
  EUCHRE_MOVE: 'euchreMove'
  EUCHRE_SHUTDOWN: 'euchreShutdown'
  EXCHANGE_POINTS: 'exchangePoints'
  EXPIRE_SESSION: 'expireSession'
  FOLD: 'fold'
  GAME_ENDED: 'gameEnded'
  GAME_EXCLUSION: 'gameExclusion'
  GAME_INSTANCE_SHUTDOWN: 'gameInstanceShutdown'
  GAME_STATUS: 'gameStatus'
  GAME_TYPE_CHANGE: 'gameTypeChange'
  GAME_VARIABLES: 'gameVariables'
  GET_BALANCE: 'getBalance'
  GET_CLIENT_DATA_FEED: 'getClientDataFeed'
  GET_COUPONS: 'getCoupons'
  GET_EXCHANGE_POINTS_OPTIONS: 'getExchangePointsOptions'
  GET_GAME_LIST: 'getGameList'
  GET_LEVEL_STRUCTURE: 'getLevelStructure'
  GET_MY_GAMES: 'getMyGames'
  GET_NOTIFICATIONS: 'getNotifications'
  GET_OBJECT_CACHE: 'getObjectCache'
  GET_PLAY_MONEY: 'getPlayMoney'
  GET_POINTS: 'getPoints'
  GET_RING_GAMES: 'getRingGames'
  GET_RING_GAME_PLAYERS: 'getRingGamePlayers'
  GET_SERVER_ID: 'getServerId'
  GET_STATISTICS: 'getStatistics'
  GET_TOKEN: 'getToken'
  GET_TOURNAMENTS: 'getTournaments'
  GET_TOURNAMENT_DETAILS: 'getTournamentDetails'
  GET_TOURNAMENT_PARENTS: 'getTournamentParents'
  GET_TOURNAMENT_PLAYERS: 'getTournamentPlayers'
  GET_TOURNAMENT_SATELLITES: 'getTournamentSatellites'
  GET_TRANSACTION_HISTORY: 'getTransactionHistory'
  GET_TRANSFER_OPTIONS: 'getTransferOptions'
  GET_USER: 'getUser'
  GET_VISIBLE_USER_ACCOLADES: 'getVisibleUserAccolades'
  GET_USER_ACCOLADES: 'getUserAccolades'
  GET_USER_GAME_EXCLUSIONS: 'getUserGameExclusions'
  GLOBAL_TIMER: 'globalTimer'
  HIGH_CARD: 'highCard'
  HIT: 'hit'
  IGNORE_FRIEND: 'ignoreFriend'
  IGNORE_SHOOTOUT_ROUND: 'ignoreShootoutRound'
  IM_READY: 'imReady'
  INITIAL_BLIND: 'initialBlind'
  INSURANCE: 'insurance'
  IS_REAL_MONEY_USER: 'isRealMoneyUser'
  JOIN_GAME: 'joinGame'
  JACKPOT_BALANCE: 'jackpotBalance'
  JACKPOT_IS_ACTIVE: 'jackpotIsActive'
  JACKPOT_WIN: 'jackpotWin'
  LAST_TABLE: 'lastTable'
  LEAVE_EUCHRE_GAME: 'leaveEuchreGame'
  LEAVE_GAME: 'leaveGame'
  LOGIN_USER: 'loginUser'
  LOGOUT_USER: 'logoutUser'
  MONITOR_SITNGO: 'monitorSitngo'
  MOVE: 'move'
  MOVE_ALL_GAMES: 'moveAllGames'
  MOVE_ALL_GAMES_STATUS: 'moveAllGamesStatus'
  MOVE_GAME: 'moveGame'
  MOVE_GAME_DETAILS: 'moveGameDetails'
  MOVE_GAME_NOTIFY: 'moveGameNotify'
  MOVE_GAME_RESULT: 'moveGameResult'
  MOVE_GAME_SUCCESS: 'moveGameSuccess'
  MUCK_CARDS: 'muckCards'
  NEXT_LEVEL: 'nextLevel'
  NOTIFICATION_STATS: 'notificationStats'
  NOTIFY: 'notify'
  NOTIFY_BREAK: 'notifyBreak'
  OPT_IN: 'optIn'
  OPT_OUT: 'optOut'
  PASS_FACE_CARD: 'passFaceCard'
  PASS_TRUMP_SUIT: 'passTrumpSuit'
  PENDING_DEPOSIT: 'pendingDeposit'
  PING: 'ping'
  PLAY_CARD: 'playCard'
  PLAY_EUCHRE_GAME: 'playEuchreGame'
  PLAYER_DISCONNECTED: 'playerDisconnected'
  PLAYER_RANKINGS: 'playerRankings'
  PLAYER_RECONNECTED: 'playerReconnected'
  PREFERRED_ACCOLADE: 'preferredAccolade'
  PROCEED_HAND_FOR_HAND: 'proceedHandForHand'
  PROCESS_MESSAGE: 'processMessage'
  RABBIT_HUNT: 'rabbitHunt'
  RAISE: 'raise'
  REBUY: 'rebuy'
  REDEAL: 'redeal'
  REGISTER: 'register'
  REGISTER_TABLE: 'registerTable'
  REJECT_FRIEND: 'rejectFriend'
  REJECT_DOUBLE: 'rejectDouble'
  REJECT_RESIGN: 'rejectResign'
  REMATCH: 'rematch'
  REMOVE_FROM_WAITLIST: 'removeFromWaitlist'
  RESERVE: 'reserve'
  RESIGN: 'resign'
  RETURN_BLIND: 'returnBlind'
  ROLL: 'roll'
  SAVE_OPTIONS: 'saveOptions'
  SEAT_RESERVED: 'seatReserved'
  SEND_EMAIL: 'sendEmail'
  SERVER_STATUS: 'serverStatus'
  SHOOTOUT_ADVANCE: 'shootoutAdvance'
  SHOW_CARDS: 'showCards'
  SHUTDOWN: 'shutdown'
  SHUTDOWN_GAME: 'shutdownGame'
  SHUTDOWN_NOTIFY: 'shutdownNotify'
  SHUTDOWN_TOURNAMENT: 'shutdownTournament'
  SIT_IN: 'sitIn'
  SIT_OUT: 'sitOut'
  SMALL_BLIND: 'smallBlind'
  SPIN: 'spin'
  SPLIT: 'split'
  START_BREAK: 'startBreak'
  START_FINAL_REBUYS: 'startFinalRebuys'
  START_GAME: 'startGame'
  START_HAND_FOR_HAND: 'startHandForHand'
  START_SHOOTOUT_ROUND: 'startShootoutRound'
  STAND: 'stand'
  STAND_UP: 'standUp'
  START_SERVING_GAMES: 'startServingGames'
  STOP_MOVE_ALL_GAMES: 'stopMoveAllGames'
  STOP_SERVING_GAMES: 'stopServingGames'
  STOP_BREAK: 'stopBreak'
  STOP_GAME: 'stopGame'
  STOP_HAND_FOR_HAND: 'stopHandForHand'
  SURRENDER: 'surrender'
  TIME_BANK_SELECTED: 'timeBankSelected'
  TIMEOUT: 'timeout'
  TABLE_RESULT: 'tableResult'
  TABLE_BREAKDOWN: 'tableBreakdown'
  THREADPOOL_CHECK: 'threadpoolCheck'
  TIME_BANK_AVAILABLE: 'timeBankAvailable'
  TOURNAMENT_REMATCH: 'tournamentRematch'
  TOURNAMENT_START: 'tournamentStart'
  TOURNAMENT_UPDATE: 'tournamentUpdate'
  TRANSFER: 'transfer'
  TRANSFER_IN: 'transferIn'
  TRANSFER_OUT: 'transferOut'
  UNDO: 'undo'
  UNRESERVE: 'unreserve'
  UPDATE_COUPONS: 'updateCoupons'
  UPDATE_GAME_EXCLUSIONS: 'updateGameExclusions'
  UPDATE_LANGUAGE: 'updateLanguage'
  UPDATE_NICKNAME: 'updateNickname'
  UPDATE_PLAYER: 'updatePlayer'
  UPDATE_REBUY_ADDON_ALLOWED: 'updateRebuyAddonAllowed'
  UPDATE_SCHEDULE_FROM: 'updateScheduleFrom'
  UPDATE_STOP_AT_PLAYERS: 'updateStopAtPlayers'
  UPDATE_TABLE_SERVER: 'updateTableServer'
  UPDATE_TOURNAMENT_SERVER: 'updateTournamentServer'
  UPDATE_USER_PASSWORD: 'updateUserPassword'
  VERIFY_EMAIL: 'verifyEmail'
  WAITLIST_PLAYER_RESTRICTION: 'waitlistPlayerRestriction'
  WITHDRAW: 'withdraw'

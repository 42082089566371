import React, {useCallback} from 'react'

import {useOpen} from '../../../named-url/react-hooks.js'

export default function ContactSupport (props) {
  const {onClick} = props
  const openSupport = useOpen('playerServices.support')

  const handleClick = useCallback(event => {
    event.preventDefault()
    openSupport()
    if (onClick) onClick()
  }, [onClick, openSupport])

  return <a href='#' onClick={handleClick}>Contact Support <span className='fa fa-exclamation-circle' /></a>
}

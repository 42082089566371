import {createContext, useContext} from 'react'

const roomContext = createContext()
export const RoomProvider = roomContext.Provider

export function useActions () {
  return useRoom().actions
}

export function useAddChips () {
  return useRoom().addChips
}

export function useAdvancedOptions () {
  return useRoom().advancedOptions
}

export function useBank () {
  return useRoom().bank
}

export function useChat () {
  return useRoom().chat
}

export function useControls () {
  return useRoom().controls
}

export function useGameDetails () {
  return useRoom().gameDetails
}

export function useQuickOptions () {
  return useRoom().quickOptions
}

export function useSeats () {
  return useRoom().seats
}

export function useTimeBank () {
  return useRoom().timeBank
}

export function useTableId () {
  return useRoom().tableId
}

function useRoom () {
  return useContext(roomContext)
}

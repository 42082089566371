# RebuyView
# Model: client

import BettingControlsView from './bettingControlsView.coffee'
import PaymentOptionsSelect from './paymentOptionsSelect.coffee'

export default class RebuyView extends BettingControlsView

  constructor: (client, modalTarget) ->
    super()

    @model = client
    @modalTarget = modalTarget

    @dom = $ '<div class="rebuy-modal-content">'

    # ### Components - eg. DOM elements & views
    @components =
      cashierLink:  $ "<span class='buyin-cashier-link'>(Visit Cashier)</span>"
      select:       new PaymentOptionsSelect [], false, 'rebuy-payment-options'
      doubleRebuy:  $ '<label><input type="checkbox"> Double</label>'
      columns:      $ '<div><div class="details left"></div><div class="details right"></div></div>'
      userBalance:  $ "<div class='balance'>My Balance: <span class='value'></span></div>"
      currentLevel: $ "<div class='balance'>Current Level: <span class='value'></span></div>"
      nextLevel:    $ "<div class='balance'>Next Level: <span class='value'></span></div>"
      gameTimer:    $ "<div class='balance'>Time Remaining: <span class='value'></span></div>"
      rebuysRemaining: $ "<div class='balance'>Rebuys Remaining: <span class='value'></span></div>"
      pendingRebuy: $ "<div class='pending-rebuy'>Rebuys are processed when you fold or at the end of a hand</div>"

    # ### Events - eg. MouseClicks ###
    @components.cashierLink.on 'click', @model.visitCashier

    # ### Listeners - eg. auth:success
    @listeners =
      'show:rebuy': @showRebuy
      'rebuyAllowed': @setup

    @model.on 'hide:rebuyViewModal', @hideCurrentModal

    # ### Changes - eg. Model.property = true
    # NOTE: individual components are not bound to variables since a refresh()
    # is called on show() which updates all components in one go

    # ### Binding ###
    @initializeBettingConstrolsView arguments...
    return this

  render: =>
    @appendComponents()
    @components.userBalance.appendTo @components.columns.find '.left'
    @components.rebuysRemaining.appendTo @components.columns.find '.left'
    @components.currentLevel.appendTo @components.columns.find '.right'
    @components.nextLevel.appendTo @components.columns.find '.right'
    @components.gameTimer.appendTo @components.columns.find '.right'
    @components.cashierLink.appendTo @components.userBalance
    @components.cashierLink.appendTo @components.userBalance
    @dom.hide()
    return @dom

  refresh: =>
    # @updateSelect()
    @components.currentLevel.find('.value').html @model.game.currentLevelDescription
    @components.nextLevel.find('.value').html @model.game.nextLevelDescription
    @components.doubleRebuy.find('input').prop 'checked', false
    @updateUserBalance()
    # todo: REFACTOR
    @components.pendingRebuy.toggle not (@model.sittingOut or @model.onBreak or @model.player?.label is 'FOLDED')
    if @answerRequired
      @components.pendingRebuy.hide()
    @dom.show()
    return @dom

  setup: ({paymentOptions, gameTimer, rebuysRemaining}) =>
    clearInterval @gameTimerInterval

    @components.doubleRebuy.show()
    if rebuysRemaining > 0
      @components.rebuysRemaining.find('.value').text rebuysRemaining
      @components.rebuysRemaining.show()
      if rebuysRemaining is 1
        @components.doubleRebuy.hide()
    else
      @components.rebuysRemaining.hide()

    if @model.tableBalance > 0
      @components.doubleRebuy.hide()

    if gameTimer
      @components.gameTimer.find('.value').html gameTimer
      @gameTimerInterval = setInterval =>
        @components.gameTimer.find('.value').html gameTimer-- # coffeelint: disable-line=check_scope
        if gameTimer <= 0
          clearInterval @gameTimerInterval
          @currentModal?.teardown()
      , @gameTimerTick or 1000
      @components.gameTimer.show()
    else
      @components.gameTimer.hide()

    if paymentOptions and paymentOptions.length
      @updateSelect paymentOptions
    else # HAX for WEB-1434 and WEB-1477 !
      @currentModal?.teardown()

  updateSelect: (paymentOptions) =>
    @components.select.model = paymentOptions
    @components.select.render()

  updateUserBalance: =>
    @components.userBalance.find('.value').html @model.userBalance.toMoney()

  showRebuy: (answerRequired) =>
    @answerRequired = answerRequired
    @showModal()

  hideCurrentModal: =>
    @currentModal?.quiteTeardown?()

  showModal: =>
    @refresh()
    if @currentModal?
      # console.warn 'already have modal for rebuy - aborting'
      return
    @currentModal = @modal
      title: 'Rebuy'
      icon: 'credit-card'
      content: @dom
      className: 'bettingControlsModal'
      target: @modalTarget
      stackOrder: 2 # always stack above addon
      detach: true
      choices: [
        {
          value: 'Cancel'
          icon: 'times'
          key: 'escape'
          action: @decline
        }
        {
          value: 'Confirm'
          icon: 'check'
          key: 'enter'
          action: @submit
        }
      ]
      teardown: =>
        @model.emit 'hide:rebuyViewModal'
        @currentModal = null

  submit: =>
    @model.action 'rebuy',
      accept: true
      paymentId: @components.select.dom.val()
      double: @components.doubleRebuy.find('input').prop('checked')

  decline: =>
    if @answerRequired
      @model.action 'rebuy',
        accept: false

import React from 'react'

import LabelRow from './LabelRow.js'
import Checkbox from '../../components/Checkbox.js'
import {useIsAutoMuck, useToggleAutoMuck} from '../react-hooks.js'

export default function AutoMuck () {
  return <LabelRow
    name='Auto Muck'
    help='Always automatically muck hands by default. Can be overriden in individual game options. Changing this will not affect games you are currently playing in.'
  >
    <Checkbox checked={useIsAutoMuck()} onChange={useToggleAutoMuck()} size='small' />
  </LabelRow>
}

import actionMap from '../utils/actionMap.coffee'

export default ({signal, details, app, game, client, listing}) ->
  return unless game

  details.currency = game.currency
  details.precision = if game.minChipValue is 1 then 0 else 2

  client.currentActions = actionMap details

  if client.currentActions.includes('muck')
    if client.settings.promptAutoMuck and not client.settings.autoMuck
      client.promptAutoMuck()

  type = details.type
  betValues =
    minBet: details.minBet
    maxBet: details.maxBet
    minRaise: details.minRaise
    maxRaise: details.maxRaise
  client.betValues = betValues

  # # Preaction Check
  if client.position? and details.currentPlayer isnt client.position
    preaction = true
  else
    preaction = false

  previousBetValues = client.previousActions.betValues
  isMinBetSame = previousBetValues?.minBet is betValues.minBet
  isMinRaiseSame = previousBetValues?.minRaise is betValues.minRaise
  if client.previousActions.type is type and isMinBetSame and isMinRaiseSame
    same = true
  else
    same = false

  # Whether player intends to fold
  if client.intendedAction?.actionName is 'fold'
    willFold = true

  # raise any
  if client.currentActions.includes('raise') and preaction
    client.currentActions.push('raiseAny')
    client.currentActions = client.currentActions

  if client.intendedAction?.actionName is 'raiseAny'
    willRaise = true
    if not preaction
      client.intendedAction = {
        actionName: 'raise'
        args:
          amount: betValues.minRaise
      }

  # if fold to any bet
  if client.intendedAction?.actionName is 'foldToAnyBet' and not preaction
    if client.currentActions.includes('check')
      # these PURPOSEFULLY change the property actionName instead of
      # setting a intendedAction object
      # i.e dont do this client.intendedAction = {actionName: 'check'}
      # because it fires intendedaction change events
      client.intendedAction.actionName = 'check'
      client.sendPreaction()
      client.intendedAction.actionName = 'foldToAnyBet'
      return
    else if client.currentActions.includes('fold')
      client.intendedAction.actionName = 'fold'
      client.sendPreaction()
      client.intendedAction.actionName = 'foldToAnyBet'
      return

  # WEB-1610 All In / Call Preactions
  if client.intendedAction?.actionName is 'allIn'
    willAllIn = true

    if client.currentActions.includes('allIn')
      if not preaction
        client.sendPreaction()
        return

    else if client.currentActions.includes('call')
      client.intendedAction = {actionName: 'call'}
      if not preaction
        client.sendPreaction()
        return


  # # Decision tree
  if preaction
    if same or willFold or willRaise or willAllIn
      return false
    else
      client.removePreaction()
    client.previousActions = {type, betValues}
  else
    if same or willFold or willRaise or willAllIn
      client.sendPreaction()
    else
      client.removePreaction()

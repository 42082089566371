import {produce} from 'immer'

import {GAME_FORMAT} from './constants.js'
import {createStore as createPinStore} from './pinning.js'
import {RING_GAME_FILTER_MAP, RING_GAME_FILTER_CRITERIA} from './ring-game/constants.js'
import {createStore as createRingGameCriteria} from './ring-game/criteria.js'
import {SCHEDULED_TOURNAMENT_FILTER_MAP, SCHEDULED_TOURNAMENT_FILTER_CRITERIA} from './scheduled-tournament/constants.js'
import {createStore as createScheduledTournamentCriteria} from './scheduled-tournament/criteria.js'
import {SIT_N_GO_FILTER_MAP, SIT_N_GO_FILTER_CRITERIA} from './sitngo-tournament/constants.js'
import {createStore as createSitngoTournamentCriteria} from './sitngo-tournament/criteria.js'
import {createStore as createSortStore, normalizeRingGameSort, normalizeTournamentSort} from './sorting.js'
import {GAME_LIST, SELECTOR_LIST} from '../routes.js'
import {FIVE_SECONDS} from '../time/time-amount.js'

const {RING_GAME, SCHEDULED_TOURNAMENT, SITNGO_TOURNAMENT} = GAME_FORMAT

export function createService (communicator, gameFilterSettings, router, user) {
  const gameFormat = createStore('gameListGameFormat', normalizeGameFormat)
  const pinnedRingGames = createPinStore(createStore, 'gameListPinnedRingGames')
  const pinnedTournaments = createPinStore(createStore, 'gameListPinnedTournaments')
  const ringGameCriteria = createRingGameCriteria(createStore, 'gameListRingGameCriteria')
  const ringGameSort = createSortStore(createStore, 'gameListRingGameSort', normalizeRingGameSort)
  const scheduledTournamentCriteria =
    createScheduledTournamentCriteria(createStore, 'gameListScheduledTournamentCriteria')
  const scheduledTournamentSort =
    createSortStore(createStore, 'gameListScheduledTournamentSort', normalizeTournamentSort)
  const sitngoTournamentCriteria = createSitngoTournamentCriteria(createStore, 'gameListSitngoTournamentCriteria')
  const sitngoTournamentSort = createSortStore(createStore, 'gameListSitngoTournamentSort', normalizeTournamentSort)

  const handleLoadGames = () => { loadGames() }
  router.subscribe(handleLoadGames)

  const handleRouterChange = () => {
    const {name: routeName, params: {s2}} = router.getState() || {}

    if (routeName === GAME_LIST) {
      switch (gameFormat.get()) {
        case RING_GAME: return readRingGameSubRoute(s2)
        case SCHEDULED_TOURNAMENT: return readScheduledTournamentSubRoute(s2)
        case SITNGO_TOURNAMENT: return readSitngoTournamentSubRoute(s2)
      }
    }
  }

  router.subscribe(handleRouterChange)
  user.on('change:authenticated', handleLoadGames)
  gameFormat.subscribe(handleLoadGames)
  ringGameCriteria.subscribe(handleLoadGames)
  scheduledTournamentCriteria.subscribe(handleLoadGames)
  sitngoTournamentCriteria.subscribe(handleLoadGames)
  setInterval(handleLoadGames, FIVE_SECONDS)

  loadGames()

  return {
    gameFormat,
    pinnedRingGames,
    pinnedTournaments,
    ringGameCriteria,
    ringGameSort,
    scheduledTournamentCriteria,
    scheduledTournamentSort,
    sitngoTournamentCriteria,
    sitngoTournamentSort,
  }

  function createStore (setting, normalize, flatten) {
    const subscribers = new Set()
    let currentValue = normalize(gameFilterSettings.get(setting))
    let currentFlatValue = flatten ? flatten(currentValue) : currentValue

    gameFilterSettings.subscribe(setting, newValue => {
      currentValue = normalize(newValue)
      const newFlatValue = flatten ? flatten(currentValue) : currentValue

      if (newFlatValue !== currentFlatValue) dispatch(newFlatValue)
    })

    return {
      get () {
        return currentFlatValue
      },

      set (fnOrValue) {
        let newValue

        if (typeof fnOrValue === 'function') {
          newValue = produce(currentValue, draft => {
            fnOrValue(draft, currentFlatValue)
          })
        } else {
          newValue = fnOrValue
        }

        gameFilterSettings.set(setting, newValue)
      },

      subscribe (subscriber) {
        subscribers.add(subscriber)

        return function unsubscribe () {
          subscribers.delete(subscriber)
        }
      },
    }

    function dispatch (newFlatValue) {
      currentFlatValue = newFlatValue
      for (const subscriber of subscribers) subscriber(newFlatValue)
    }
  }

  function readRingGameSubRoute (path) {
    const ringGameFilter = RING_GAME_FILTER_MAP[path]

    if (!ringGameFilter) return

    const {limitType, variant} = RING_GAME_FILTER_CRITERIA[ringGameFilter]
    const {setLimitType, setVariant} = ringGameCriteria

    setVariant(variant)
    setLimitType(limitType)
  }

  function readScheduledTournamentSubRoute (path) {
    const scheduledTournamentFilter = SCHEDULED_TOURNAMENT_FILTER_MAP[path]

    if (!scheduledTournamentFilter) return

    const {limitType, variant, tournamentType} = SCHEDULED_TOURNAMENT_FILTER_CRITERIA[scheduledTournamentFilter]
    const {setLimitType, setVariant, setTournamentType} = scheduledTournamentCriteria

    setTournamentType(tournamentType)
    setVariant(variant)
    setLimitType(limitType)
  }

  function readSitngoTournamentSubRoute (path) {
    const sitngoFilter = SIT_N_GO_FILTER_MAP[path]

    if (!sitngoFilter) return

    const {seatCountRange, speed, stakesRange} = SIT_N_GO_FILTER_CRITERIA[sitngoFilter]
    const {setSeatCountRange, setSpeed, setStakesRange} = sitngoTournamentCriteria

    setSeatCountRange(seatCountRange)
    setSpeed(speed)
    setStakesRange(stakesRange)
  }

  function determineLoadGamesParameters () {
    if (!user.authenticated) return undefined

    const {name: routeName} = router.getState() || {}

    if (routeName !== SELECTOR_LIST && routeName !== GAME_LIST) return undefined

    switch (gameFormat.get()) {
      case RING_GAME: return ['getRingGames', ringGameCriteria.legacyListTypes()]
      case SCHEDULED_TOURNAMENT: return ['getTournaments', scheduledTournamentCriteria.legacyListTypes()]
      case SITNGO_TOURNAMENT: return ['getTournaments', sitngoTournamentCriteria.legacyListTypes()]
    }

    return undefined
  }

  function loadGames () {
    const parameters = determineLoadGamesParameters()

    if (!parameters) return

    const [messageName, listTypes] = parameters
    const tagList = user.tagList.playerTagsString

    for (const listType of listTypes) communicator.message(messageName, {listType, realMoney: true, tagList})

    communicator.message('getMyGames')
  }

  function normalizeGameFormat (gameFormat) {
    return GAME_FORMAT[gameFormat] ? gameFormat : RING_GAME
  }
}

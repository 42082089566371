import React from 'react'

import LabelRow from './LabelRow.js'
import Checkbox from '../../components/Checkbox.js'
import {useIsAutoTimebank, useToggleAutoTimebank} from '../react-hooks.js'

export default function AutoTimebank () {
  return <LabelRow
    name='Auto Timebank'
    help='Use Timebank by default. Can be overridden in individual games. Changing this will not affect games you are currently playing in.'
  >
    <Checkbox checked={useIsAutoTimebank()} onChange={useToggleAutoTimebank()} size='small' />
  </LabelRow>
}

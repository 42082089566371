export default class Signal
  constructor: (attributes) ->
    @message =
      id: parseInt(attributes.id)
      service: attributes.service.toLowerCase()
      type: attributes.type.toLowerCase()
      sessionId: parseInt(attributes.sessionId)
      routerId: parseInt(attributes.routerId)
      clientId: parseInt(attributes.clientId)
      serverId: parseInt(attributes.serverId)
      instanceId: parseInt(attributes.instanceId)
      responseId: parseInt(attributes.responseId)

    @function = attributes.function

    @json = attributes

import React, {useMemo} from 'react'

import Empty from './Empty.js'
import ListingHeaders from './ListingHeaders.js'
import RowGroup from '../../../components/game-listing/table/RowGroup.js'
import Table from '../../../components/game-listing/table/Table.js'
import {useRootedSelector} from '../../../redux.js'
import DesktopRingGameListingRow from '../../components/DesktopRingGameListingRow.js'
import Loading from '../../components/Loading.js'
import {getRoot} from '../../index.js'
import {usePinnedRingGames} from '../../react-hooks.js'
import {useCriteria, useSort} from '../react-hooks.js'
import {createGetListing, createIsLoaded} from '../selectors.js'

const EMPTY_ID = 'game-list-empty-ring-games'

export default function DesktopListing () {
  const criteria = useCriteria()
  const sort = useSort()
  const pinned = usePinnedRingGames()

  const isLoaded = useRootedSelector(getRoot, useMemo(() => createIsLoaded(), []), {criteria})
  const ringGames = useRootedSelector(getRoot, useMemo(() => createGetListing(), []), {criteria, pinned, sort})
  const hasEntries = ringGames.length > 0
  const captionId = !isLoaded || !hasEntries ? EMPTY_ID : undefined

  return <Table ariaLabelledby={captionId}>
    <ListingHeaders />

    {isLoaded && hasEntries && <RowGroup>
      {ringGames.map(ringGame => {
        return <DesktopRingGameListingRow key={ringGame.tableId} ringGame={ringGame} />
      })}
    </RowGroup>}

    {isLoaded && !hasEntries && <Empty id={captionId} />}
    {!isLoaded && <Loading id={captionId} />}
  </Table>
}

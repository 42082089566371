export const CATEGORY_OTHER = 'OTHER'
export const CATEGORY_TOURNAMENT_STARTING = 'TOURNAMENT_STARTING'
export const CATEGORY_UPCOMING_TOURNAMENT = 'UPCOMING_TOURNAMENT'

export function createNotifier (casinoSettings, isElectron, window) {
  const {document, navigator, Notification} = window
  let badge, icon

  return {
    init () {
      badge = document.getElementById('notification-badge').getAttribute('href')
      icon = document.getElementById('notification-icon').getAttribute('href')
    },

    async notify (title, options) {
      await showNotification(title, options)
    },

    async notifyCategorized (category, title, options) {
      if (casinoSettings.get(categorySetting(category))) await showNotification(title, options)
    },
  }

  function categorySetting (category) {
    switch (category) {
      case CATEGORY_OTHER: return 'notificationPush'
      case CATEGORY_TOURNAMENT_STARTING: return 'notificationAtGameStart'
      case CATEGORY_UPCOMING_TOURNAMENT: return 'notificationBeforeGameStart'
    }
  }

  async function getServiceWorkerRegistration () {
    if ('serviceWorker' in navigator) return navigator.serviceWorker?.getRegistration()
  }

  function shouldShowNotification () {
    if (!Notification) return false // no notification support
    if (!casinoSettings.get('notifications')) return false // notifications completely disabled
    if (Notification.permission !== 'granted') return false // permission denied

    return true
  }

  async function showNotification (title, options = {}) {
    if (!shouldShowNotification()) return

    const augmentedOptions = {badge, icon, ...options}

    // see https://github.com/electron/electron/issues/13041
    if (!isElectron) {
      const registration = await getServiceWorkerRegistration()

      if (registration?.showNotification) {
        registration.showNotification(title, augmentedOptions)

        return
      }
    }

    const notification = new Notification(title, augmentedOptions)
    notification.onclick = () => {
      window.postMessage({type: 'FOCUS'}) // Electron handles this to improve focusing under Windows
      window.focus()
    }
  }
}

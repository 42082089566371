import PersistentModel from './persistentModel.coffee'

export default class GameFilter extends PersistentModel

  constructor: (version, persister) ->
    super version, persister

    @namespace = 'gameFilter'

    @properties =

      # Hax to get this thing to dispatch change events for "new" settings
      gameListGameFormat: undefined
      gameListRingGameCriteria: undefined

      # QuickSeat
      quickSeatType: 'ringGames'

      # Menu View
      menuLimitType: 'noLimit'
      menuSort: [{"type":"priority","order":"descending"},{"type":"startTime","order":"ascending"},{"type":"gameStateId","order":"ascending"},{"type":"playerCount","order":"descending"},{"type":"headsUp","order":"ascending"},{"type":"stakes","order":"ascending"},{"type":"maxPlayers","order":"ascending"},{"type":"name","order":"ascending"}]

      # Selectors
      selectorScheduledTables: 'all'
      selectorSngTables: 'all'
      selectorRingGameLimitType: 'noLimit'
      selectorSpeeds: 'all'

      # used by filters
      sorts:
        cash:
          type: 'stakes'
          order: 'ascending'
        sng:
          type: 'playerCount'
          order: 'descending'
        mtt:
          type: 'starts'
          order: 'ascending'
      realMoney: true
      limitTypes: ['noLimit']
      listTypes: [1]
      speeds: ['regular', 'fast']
      gameStates: ['Registering', 'In Progress', 'Announced']
      maxPlayers: [2..10]
      maxPlayersPerTable: null
      tables: 'all'
      averagePot: null
      playersInFlopPct: null # given by gameList:
      handsHr: null # given by gameList:
      antes: null # true/false:
      badBeat: null
      stakes: {min: 0, max: Number.MAX_VALUE}
      entry: {min: 0, max: Number.MAX_VALUE}
      minPlayersSeated: 0
      startTime: null
      gameTypeIds: [1, 2, 23, 22]
      variant: 'holdem' # helper only, not used directly for filtering

      defaultSelector: ["Cash Games", "Texas Hold'em", "Fixed Limit", "All"]

    @initializePersistentModel()

  filter: =>
    @emit 'filter'

import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# Data Types

export default class PlayerDisconnectedTranslator extends Mixmaster
  mixins = [
    DefaultMixin
  ]
  @include mixins

  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

  playerTimerDisconnected: (signal, name, values) ->
    signal.updates.playerTimerDisconnected ?= values

import classnames from 'classnames'
import React, {useCallback, useState} from 'react'

import styles from './AdBar.module.css'
import {useLobbySidebarUrl} from '../react-hooks.js'

export default function AdBar () {
  const lobbySideBar = useLobbySidebarUrl()
  const [isLoaded, setIsLoaded] = useState(false)

  const handleLoaded = useCallback(() => {
    setIsLoaded(true)
  }, [setIsLoaded])

  return <div className={styles.sideBar}>
    {lobbySideBar && <iframe
      id='ad-bar'
      src={lobbySideBar}
      className={classnames(styles.loadStart, styles.adbarIframe, {[styles.loaded]: isLoaded})}
      sandbox='allow-scripts allow-popups'
      onLoad={handleLoaded}
    />}
  </div>
}

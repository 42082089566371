import classnames from 'classnames'
import React, {useCallback} from 'react'

import styles from './Slider.module.css'

export default function Slider (props) {
  const {className, max, min, rangeRef, setValue, step, value} = props

  const handleChange = useCallback(event => {
    setValue(Number(event.target.value))
  }, [setValue])

  return <div className={classnames(className, styles.slider)}>
    <input
      ref={rangeRef}
      type='range'
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={handleChange}
    />
  </div>
}

export default ({signal, details, app, game, client, listing}) ->

  if signal.updates.event?.eventName is 'reserve'
    if signal.updates.nickName is app.user.nickname
      client.position = signal.updates.event.playerId
    else
      # console.warn 'reserve event for diff player, aborting updates/seatReservedFromWaitlistMonitor'
      return

  # clear all waitlists for matching clients
  if listing
    criteria =
      minBet: listing.minBet
      limitType: listing.limitType
      gameTypeId: listing.gameTypeId
      maxPlayers: listing.maxPlayers
    app.leaveWaitlists criteria

  autoSeat = details

  if client and autoSeat
    # already in table
    # seat: signal.updates.event.playerId # TODO: This is not good enough. surely we can't rely on this?
    client.autoBuyin()
  else
    # not at table. Must joinGame
    app.joinGame
      instanceId: signal.message.instanceId
      serverId: signal.message.serverId
      autoSeat: autoSeat

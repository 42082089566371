/* eslint-disable react/forbid-dom-props */
import classnames from 'classnames'
import React, {useMemo} from 'react'

import styles from './SeatingPreview.module.css'
import skeleton from '../../skeleton.module.css'

export default function SeatingPreview (props) {
  const {seatCount, occupiedSeats} = props

  const style = useMemo(() => {
    const style = {}
    for (const seat of occupiedSeats) style[`--seat${seat}`] = '1'

    return style
  }, [occupiedSeats])

  return <svg className={classnames(styles.preview, skeleton.light)} width='46' height='30'>
    <use href={`#seatingPreview${seatCount}`} style={style} className={skeleton.hide} />
  </svg>
}

import Mixmaster from '../../mixmaster.coffee'
import DefaultMixin from './mixins/defaultMixin.coffee'

# Data Types


# handleLoginResponse(LoginData login)

export default class ProcessMessageTranslator extends Mixmaster
  mixins = [
    DefaultMixin
  ]
  @include mixins


  @init: (signal) ->
    for mixin in mixins
      mixin.init? signal

  constructor: () ->
    super()
    Mixmaster.construct this, mixins

export function createLocalStoragePersister (localStorage) {
  if (!localStorage) return undefined

  const keySubscribers = {}

  window.addEventListener('storage', handleStorage)

  return {
    get,
    getAll,
    keys,
    remove,
    set,
    subscribe,
  }

  function dispatchKeyUpdate (key, serialized) {
    const subscribers = keySubscribers[key]

    if (!subscribers) return

    try {
      for (const subscriber of subscribers) {
        // each subscriber gets their own copy of the value
        subscriber(serialized && JSON.parse(serialized))
      }
    } catch (error) {
      // do nothing, maybe send a Sentry report?
    }
  }

  function get (key) {
    const serialized = localStorage.getItem(key)

    if (!serialized) return undefined

    try {
      return JSON.parse(serialized)
    } catch (error) {
      localStorage.removeItem(key)
    }
  }

  function getAll () {
    const entries = {}
    for (const key of keys()) entries[key] = get(key)

    return entries
  }

  function handleStorage (event) {
    const {key, newValue} = event
    dispatchKeyUpdate(key, newValue)
  }

  function keys () {
    return Object.keys(localStorage)
  }

  function remove (key) {
    localStorage.removeItem(key)
    dispatchKeyUpdate(key, undefined)
  }

  function set (key, value) {
    const serialized = JSON.stringify(value)

    localStorage.setItem(key, serialized)
    dispatchKeyUpdate(key, serialized)
  }

  function subscribe (key, subscriber) {
    if (!keySubscribers[key]) keySubscribers[key] = new Set()

    keySubscribers[key].add(subscriber)

    return function unsubscribe () {
      keySubscribers[key].delete(subscriber)
    }
  }
}

import {useTimeBank} from '../context.js'
import {useService} from '../react-hooks.js'

export const useBalance = () => useSelector(({balance}) => balance)
export const useDisableTimeBank = () => useService().disableTimeBank
export const useEnableTimeBank = () => useService().enableTimeBank
export const useIsEnabled = () => useSelector(({isEnabled}) => isEnabled)
export const useMinimumBalance = () => useSelector(({minimumBalance}) => minimumBalance)
export const useShouldShowTimeBank = () => useSelector(({areAvailable, ownSeat}) => ownSeat > 0 && areAvailable)

export const useIsActive = () => useSelector(({activeSeats, isRunning, ownSeat}) => {
  return isRunning && activeSeats.includes(ownSeat)
})

function useSelector (selector) {
  return selector(useTimeBank())
}

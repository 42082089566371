import React from 'react'

import LabelRow from './LabelRow.js'
import Checkbox from '../../components/Checkbox.js'
import {useIsConfirmLeaveGame, useToggleConfirmLeaveGame} from '../react-hooks.js'

export default function ConfirmLeaveGame () {
  return <LabelRow name='Confirm Leave Game'>
    <Checkbox checked={useIsConfirmLeaveGame()} onChange={useToggleConfirmLeaveGame()} size='small' />
  </LabelRow>
}

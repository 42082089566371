import {useCallback, useContext, useEffect, useState} from 'react'

import {context} from '../react.js'

export function useAttach (tableId) {
  const {attach} = useService()

  return useCallback(() => {
    if (tableId) attach(tableId)
  }, [attach, tableId])
}

export function useDetach (tableId) {
  const {detach} = useService()

  return useCallback(() => {
    if (tableId) detach(tableId)
  }, [detach, tableId])
}

export function useFrameType () {
  const {frameType, subscribeToFrameType} = useService()
  const [reactValue, setReactValue] = useState(frameType)

  useEffect(() => {
    return subscribeToFrameType(value => {
      setReactValue(value)
    })
  }, [subscribeToFrameType])

  return reactValue
}

export function useOpen (tableId) {
  const {open} = useService()

  return useCallback(() => {
    if (tableId) open(tableId)
  }, [open, tableId])
}

function useService () {
  return useContext(context).multiTableService
}

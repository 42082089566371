import {LIMIT_TYPE_MAP, LIST_TYPE_MAP, TOURNAMENT_TYPE, VARIANT_MAP} from './constants.js'
import {isObject} from '../../object.js'
import {ALL} from '../constants.js'

export function createStore (createGenericStore, setting) {
  const store = createGenericStore(setting, normalize, flatten)

  return {
    ...store,

    legacyListTypes () {
      const {tournamentType} = store.get()

      return LIST_TYPE_MAP[tournamentType].map(([, listType]) => listType)
    },

    setLimitType (newLimitType) {
      store.set(({limitType}, {tournamentType, variant}) => {
        if (!limitType[tournamentType]) limitType[tournamentType] = {}
        limitType[tournamentType][variant] = newLimitType
      })
    },

    setTournamentType (tournamentType) {
      store.set(criteria => { criteria.tournamentType = tournamentType })
    },

    setVariant (newVariant) {
      store.set(({variant}, {tournamentType}) => { variant[tournamentType] = newVariant })
    },
  }
}

function flatten (criteria) {
  const {tournamentType = ALL} = criteria

  const [defaultVariant] = VARIANT_MAP[tournamentType]
  const {[tournamentType]: variant = defaultVariant} = criteria.variant

  const [defaultLimitType] = LIMIT_TYPE_MAP[variant]
  const {[variant]: limitType = defaultLimitType} = criteria.limitType?.[tournamentType] || {}

  return {limitType, tournamentType, variant}
}

function normalize (criteria) {
  if (!isObject(criteria)) return {limitType: {}, variant: {}}

  let {limitType, tournamentType, variant} = criteria
  if (!TOURNAMENT_TYPE[tournamentType]) tournamentType = undefined

  return {
    limitType: normalizeLimitType(limitType),
    tournamentType,
    variant: normalizeVariant(variant),
  }
}

function normalizeLimitType (limitType) {
  if (!isObject(limitType)) return {}

  const normalized = {}

  for (const tournamentType in limitType) {
    if (!TOURNAMENT_TYPE[tournamentType]) continue

    const normalizedType = {}

    for (const variant in limitType[tournamentType]) {
      if (!VARIANT_MAP[tournamentType].includes(variant)) continue

      const variantLimitType = limitType[tournamentType][variant]
      if (LIMIT_TYPE_MAP[variant].includes(variantLimitType)) normalizedType[variant] = variantLimitType
    }

    normalized[tournamentType] = normalizedType
  }

  return normalized
}

function normalizeVariant (variant) {
  if (!isObject(variant)) return {}

  const normalized = {}

  for (const tournamentType in variant) {
    if (!TOURNAMENT_TYPE[tournamentType]) continue

    const typeVariant = variant[tournamentType]
    if (VARIANT_MAP[tournamentType].includes(typeVariant)) normalized[tournamentType] = typeVariant
  }

  return normalized
}

import {HANDS, TIME} from '@pipehat/chronicle/constants/interval-unit'
import React from 'react'

import LevelCountdownHands from './LevelCountdownHands.js'
import LevelCountdownTime from './LevelCountdownTime.js'

export default function LevelCountdown (props) {
  const {countdown, handNumber} = props
  const {unit} = countdown

  return <>
    {unit === HANDS && <LevelCountdownHands countdown={countdown} handNumber={handNumber} />}
    {unit === TIME && <LevelCountdownTime countdown={countdown} />}
  </>
}

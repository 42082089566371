import {NEXT_BLIND, NEXT_HAND} from '@pipehat/chronicle/constants/sit-out-strategy'
import {FAST} from '@pipehat/chronicle/constants/table-speed'

import {useQuickOptions} from '../context.js'
import {useOption, useService} from '../react-hooks.js'

export const useAbandonSitOutStrategy = () => useService().abandonSitOutStrategy
export const useIsAutoMuck = () => useOption('isAutoMuck')
export const useIsAutoPostBlinds = () => useOption('isAutoPostBlinds')
export const useIsAwaitingSitOut = () => useSelector(({isAwaitingSitOut}) => isAwaitingSitOut)
export const useIsFast = () => useSelector(({speed}) => speed === FAST)
export const useIsTournament = () => useSelector(({isTournament}) => isTournament)
export const useSelectSitOutStrategy = () => useService().selectSitOutStrategy
export const useSitIn = () => useService().sitIn
export const useToggleAutoMuck = () => useService().toggleAutoMuck
export const useToggleAutoPostBlinds = () => useService().toggleAutoPostBlinds

export const useIsNextHandChecked = () => useSelector(({isAwaitingSitOut, sitOutStrategy}) => {
  return isAwaitingSitOut && sitOutStrategy === NEXT_HAND
})

export const useIsNextBlindChecked = () => useSelector(({isAwaitingSitOut, sitOutStrategy}) => {
  return isAwaitingSitOut && sitOutStrategy === NEXT_BLIND
})

function useSelector (selector) {
  return selector(useQuickOptions())
}

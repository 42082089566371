import some from 'lodash/some'

import Model from './model.coffee'
import obsoleteStorage from '../../obsolete-storage.js'
import {v4 as uuidv4} from 'uuid'

export default class PersistentModel extends Model

  constructor: (@version, @persister) ->
    super()

  initializePersistentModel: () ->
    @properties ?= {}

    # lazy copy. for use in resetAll
    @defaults = JSON.parse JSON.stringify @properties

    # NOTE: NO CALL TO SUPER.
    # Define own get/set for persistence

    @clearObsolete()

    id = id or uuidv4()
    Object.defineProperty this, 'id', value: id

    for key, value of @properties then do (key, value) =>
      Object.defineProperty this, key,
        enumerable: yes
        get: =>
          if @persister
            persisted = @persister.get @namespace + ':' + key

            return if persisted is undefined then @properties[key] else persisted
          else
            return @properties[key]

        set: (val) =>
          if @persister
            @persister.set @namespace + ':' + key, val
          else
            @properties[key] = val
          @emit 'change', key, val
          @emit 'change:' + key, val  # backbone style

      # apply defaults
      if @persister and not @persister.get(@namespace + ':' + key)?
        # console.log 'setting default for', @namespace, key
        @[key] = value

      # subscribe to updates from other windows
      if @persister
        @persister.subscribe key, (val) =>
          @emit 'change', key, val
          @emit 'change:' + key, val

  clearObsolete: =>
    if @persister
      if @persister.get('webclient:version') isnt @version
        for key in @persister.keys()
          if some(obsoleteStorage, (pattern) -> pattern.test(key))
            @persister.remove(key)
        @persister.set('webclient:version', @version)

  resetAll: =>
    for key, value of @defaults
      @[key] = value

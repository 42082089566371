import {TEXAS_HOLDEM} from '@pipehat/chronicle/constants/game-variant'
import {LIST_TYPE_MAP, LIMIT_TYPE_MAP, STAKES_RANGE_MAP, VARIANT} from './constants.js'
import {isObject} from '../../object.js'

export function createStore (createGenericStore, setting) {
  const store = createGenericStore(setting, normalize, flatten)

  return {
    ...store,

    legacyListTypes () {
      const {limitType, variant} = store.get()
      const [, listType] = LIST_TYPE_MAP[variant][limitType]

      return [listType]
    },

    setLimitType (newLimitType) {
      store.set(({limitType}, {variant}) => { limitType[variant] = newLimitType })
    },

    setStakesRange (newStakesRange) {
      store.set(({stakesRange}, {limitType, variant}) => {
        if (!stakesRange[variant]) stakesRange[variant] = {}
        stakesRange[variant][limitType] = newStakesRange
      })
    },

    setVariant (variant) {
      store.set(criteria => { criteria.variant = variant })
    },

    toggleShouldShowEmpty () {
      store.set((criteria, flat) => { criteria.shouldShowEmpty = !flat.shouldShowEmpty })
    },

    toggleShouldShowFull () {
      store.set((criteria, flat) => { criteria.shouldShowFull = !flat.shouldShowFull })
    },
  }
}

function flatten (criteria) {
  const {shouldShowEmpty = true, shouldShowFull = true, variant = TEXAS_HOLDEM} = criteria

  const [defaultLimitType] = LIMIT_TYPE_MAP[variant]
  const {[variant]: limitType = defaultLimitType} = criteria.limitType

  const [defaultStakesRange] = STAKES_RANGE_MAP[variant]
  const {[limitType]: stakesRange = defaultStakesRange} = criteria.stakesRange?.[variant] || {}

  return {limitType, shouldShowEmpty, shouldShowFull, stakesRange, variant}
}

function normalize (criteria) {
  if (!isObject(criteria)) return {limitType: {}, stakesRange: {}}

  let {limitType, shouldShowEmpty, shouldShowFull, stakesRange, variant} = criteria

  if (typeof shouldShowEmpty !== 'boolean') shouldShowEmpty = undefined
  if (typeof shouldShowFull !== 'boolean') shouldShowFull = undefined
  if (!VARIANT[variant]) variant = undefined

  return {
    limitType: normalizeCriteriaLimitType(limitType),
    shouldShowEmpty,
    shouldShowFull,
    stakesRange: normalizeCriteriaStakesRange(stakesRange),
    variant,
  }
}

function normalizeCriteriaLimitType (limitType) {
  if (!isObject(limitType)) return {}

  const normalized = {}

  for (const variant in limitType) {
    if (!VARIANT[variant]) continue

    const validLimitTypes = LIMIT_TYPE_MAP[variant]
    const variantLimitType = limitType[variant]

    if (!validLimitTypes.includes(variantLimitType)) continue

    normalized[variant] = variantLimitType
  }

  return normalized
}

function normalizeCriteriaStakesRange (stakesRange) {
  if (!isObject(stakesRange)) return {}

  const normalized = {}

  for (const variant in stakesRange) {
    if (!VARIANT[variant]) continue

    const validLimitTypes = LIMIT_TYPE_MAP[variant]
    const validStakesRanges = STAKES_RANGE_MAP[variant]
    const normalizedVariant = {}

    for (const limitType in stakesRange[variant]) {
      if (!validLimitTypes.includes(limitType)) continue

      const limitTypeStakesRange = stakesRange[variant][limitType]

      if (!validStakesRanges.includes(limitTypeStakesRange)) continue

      normalizedVariant[limitType] = limitTypeStakesRange
    }

    normalized[variant] = normalizedVariant
  }

  return normalized
}

import classnames from 'classnames'
import React, {useCallback} from 'react'

export default function PresetAmountButton (props) {
  const {isDisabled, label, onClick, value} = props
  const classNames = classnames('button', {'button-disabled': isDisabled})

  const handleClick = useCallback(() => {
    onClick(value)
  }, [onClick, value])

  return <button type='button' className={classNames} disabled={isDisabled} onClick={handleClick}>{label}</button>
}

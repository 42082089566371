import Request from './request.coffee'

export default class LogoutUserRequest extends Request
  constructor: ({message}) ->
    super arguments...

    @function = 'LOGOUT_USER'

    @attributes =
      type: 'REQUEST'
      service: 'AUTHENTICATION'
      routerId: message.routerId
      serverId: 0
      sessionId: message.sessionId
      clientId: message.clientId
      requestTime: 0


import {fromNumber, isLessThanOrEqual} from '@pipehat/money/plain-object'

export function roundStepAmount (amount) {
  const currencyCode = amount.currencyCode
  const stepIncrements = [1, 5, 10, 25, 50, 100, 250, 500]

  for (const inc of stepIncrements) {
    const step = fromNumber(currencyCode, inc)
    const isLessThanInc = isLessThanOrEqual(step)

    if (isLessThanInc(amount)) {
      return step
    }
  }

  return fromNumber(currencyCode, 1000)
}

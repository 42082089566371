import classnames from 'classnames'
import React from 'react'

import styles from './Table.module.css'

export default function SortableHeader ({className, direction, label, onClick}) {
  const ariaSort = direction ? direction > 0 ? 'ascending' : 'descending' : 'none'

  const classes = classnames(styles.header, styles.sortable, className, {
    [styles.ascending]: direction > 0,
    [styles.descending]: direction < 0,
  })

  return <div role='columnheader' className={classes} onClick={onClick} aria-sort={ariaSort}>{label}</div>
}

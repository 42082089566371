import React from 'react'

import LabeledCheckbox from '../../../components/LabeledCheckbox.js'
import {useIsAutoMuck, useToggleAutoMuck} from '../react-hooks.js'

export default function AutoMuckOption (props) {
  const {className} = props
  const isAutoMuck = useIsAutoMuck()
  const toggleAutoMuck = useToggleAutoMuck()

  return <LabeledCheckbox
    className={className}
    checked={isAutoMuck}
    onClick={toggleAutoMuck}
    size='small'
  >
    Muck
  </LabeledCheckbox>
}

import {RING_GAME_FILTER, RING_GAME_FILTER_CRITERIA, RING_GAME_FILTER_MAP} from './constants.js'
import {ASC, DESC} from '../constants.js'

import {
  buildUseSortSeatAsc,
  buildUseSortDirection,
  buildUseStakesSortAsc,
  buildUseStakesSortDesc,
  buildUseToggleSort,
  useCallbackWithArg,
  useService,
  useStore,
  useStoreKey,
} from '../react-hooks.js'

import {KEY_SEATS, KEY_STAKES} from '../sorting.js'

export function useCriteria () {
  return useStore(useServiceCriteria())
}

export function useLimitType () {
  return useStoreKey(useServiceCriteria(), 'limitType')
}

export function useSetLimitType (limitType) {
  return useCallbackWithArg(useServiceCriteria().setLimitType, limitType)
}

export function useStakesRange () {
  return useStoreKey(useServiceCriteria(), 'stakesRange')
}

export function useSetStakesRange (stakesRange) {
  return useCallbackWithArg(useServiceCriteria().setStakesRange, stakesRange)
}

export function useVariant () {
  return useStoreKey(useServiceCriteria(), 'variant')
}

export function useSetVariant (variant) {
  return useCallbackWithArg(useServiceCriteria().setVariant, variant)
}

export function useShouldShowEmpty () {
  return useStoreKey(useServiceCriteria(), 'shouldShowEmpty')
}

export function useShouldShowFull () {
  return useStoreKey(useServiceCriteria(), 'shouldShowFull')
}

export function useIsDirectionEmpty () {
  const {direction} = useSort()

  return direction === undefined
}

export function useIsSeatAsc () {
  const {direction, key} = useSort()

  return key === KEY_SEATS && direction === ASC
}

export function useIsStakesAsc () {
  const {direction, key} = useSort()

  return key === KEY_STAKES && direction === DESC
}

export function useIsStakesDesc () {
  const {direction, key} = useSort()

  return key === KEY_STAKES && direction === ASC
}

export function useSort () {
  return useStore(useServiceSort())
}

export const useSortSeatAsc = buildUseSortSeatAsc(useServiceSort)

export const useSortDirection = buildUseSortDirection(useServiceSort)

export const useSetStakesAsc = buildUseStakesSortAsc(useServiceSort)

export const useSetStakesDesc = buildUseStakesSortDesc(useServiceSort)

export function useToggleShouldShowEmpty () {
  return useServiceCriteria().toggleShouldShowEmpty
}

export function useToggleShouldShowFull () {
  return useServiceCriteria().toggleShouldShowFull
}

export const useToggleSort = buildUseToggleSort(useServiceSort)

export function useRingGameFilter (s2) {
  const urlMatchedFilter = RING_GAME_FILTER_MAP[s2]

  const limitType = useStoreKey(useServiceCriteria(), 'limitType')
  const variant = useStoreKey(useServiceCriteria(), 'variant')

  if (urlMatchedFilter) return urlMatchedFilter

  return getMatchedFilter(limitType, variant)
}

function getMatchedFilter (limitType, variant) {
  const flHoldemCriteria = RING_GAME_FILTER_CRITERIA[RING_GAME_FILTER.FIXED_LIMIT_TEXAS_HOLDEM]

  if (limitType === flHoldemCriteria.limitType && variant === flHoldemCriteria.variant) {
    return RING_GAME_FILTER.FIXED_LIMIT_TEXAS_HOLDEM
  }

  const flOmahaHiloCriteria = RING_GAME_FILTER_CRITERIA[RING_GAME_FILTER.FIXED_LIMIT_OMAHA_HOLDEM_HILO]

  if (limitType === flOmahaHiloCriteria.limitType && variant === flOmahaHiloCriteria.variant) {
    return RING_GAME_FILTER.FIXED_LIMIT_OMAHA_HOLDEM_HILO
  }

  const nlHoldemCriteria = RING_GAME_FILTER_CRITERIA[RING_GAME_FILTER.NO_LIMIT_TEXAS_HOLDEM]

  if (limitType === nlHoldemCriteria.limitType && variant === nlHoldemCriteria.variant) {
    return RING_GAME_FILTER.NO_LIMIT_TEXAS_HOLDEM
  }

  const plHoldemCriteria = RING_GAME_FILTER_CRITERIA[RING_GAME_FILTER.POT_LIMIT_OMAHA_HOLDEM]

  if (limitType === plHoldemCriteria.limitType && variant === plHoldemCriteria.variant) {
    return RING_GAME_FILTER.POT_LIMIT_OMAHA_HOLDEM
  }
}

function useServiceCriteria () {
  return useService().ringGameCriteria
}

function useServiceSort () {
  return useService().ringGameSort
}

import React from 'react'

import LabelRow from './LabelRow.js'
import {useOpen} from '../../named-url/react-hooks.js'

export default function VisitCashier () {
  return <LabelRow name='Visit Cashier'>
    <button type='button' className='button' onClick={useOpen('cashier.overview')}>
      <span className='fa fa-credit-card button-icon' /> Visit Cashier
    </button>
  </LabelRow>
}

# ## SignalFactory

import isEqual from 'lodash/isEqual'

import WclEnum from '../../enum/index.coffee'
import Signal from './signal.coffee'

export default class SignalFactory
  constructor: (@translatorFactory, @log) ->

  createSignal: (message, request = {}) =>
    currentSignal = new Signal message

    # Function
    functionName = WclEnum.getFunctionName(message.function.name)
    currentTranslator = @translatorFactory.getTranslator functionName
    currentTranslator.constructor.init currentSignal
    currentTranslator.function currentSignal, functionName, message

    # Parent
    currentSignal.parent = request

    # Arguments
    if message.function.argument?
      for argument in message.function.argument
        argumentName = WclEnum.getArgumentName(argument.name)
        unpack = @unpackArgument argument
        currentTranslator.argument currentSignal, argumentName, unpack, argument

    # Games
    if message.function.games?
      for game in message.function.games
        currentTranslator.game currentSignal, game

    if message.function.client?
      currentTranslator.client currentSignal, message.function.client

    if message.function.tournamentPlayers?
      currentTranslator.tournamentPlayers currentSignal, message.function.tournamentPlayers

    if message.function.tournamentLevels?
      currentTranslator.tournamentLevels currentSignal, message.function.tournamentLevels

    if message.function.userEntryCoupons?
      currentTranslator.userEntryCoupons currentSignal, message.function.userEntryCoupons

    @log 'signals', currentSignal if currentSignal.function isnt 'expireSession'

    return currentSignal

  unpackArgument: (attributes) =>
    result = {}
    # <argument name="CLASSID" code1="DATA" value1="5" code2="TRUE"/>

    keys = Object.keys(attributes)

    for key, value of attributes
      num = key.match /^code(\d+)$/

      if num?
        code = num[0]
        number = num[1]

        # Code + value combinations
        if value is 'DATA'

          if attributes['value' + number]?

            # Simple
            if isEqual keys, [ 'name', 'code1', 'value1' ]
              result = attributes['value' + number]

            # Multiples - drop the code and just leave the value
            else
              result['value' + number] = attributes['value' + number] #

        else

          # Codes

          # Code with name argument and value
          if attributes['value' + number]?
            result[WclEnum.getArgumentCode(value)] = attributes['value' + number]

          else
            # Single code with a value stored in it
            if isEqual keys, [ 'name', 'code1' ]
              result = WclEnum.getArgumentCode(value)

            # Multiple codes
            else
              result[code] = WclEnum.getArgumentCode(value)


    return result
